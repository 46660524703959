import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  Router,
  NavigationEnd,
  RouterEvent,
  ActivatedRoute,
  Params,
  NavigationStart
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { PssService } from '../pss.service';
import {
  MatDialog,
  MatDialogConfig
} from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { CertDateChangeAlertComponent } from '../cert-date-change-alert/cert-date-change-alert.component';
import { ShareContentPopupService } from '../../shared/card-feature/share-content/share-content-popup/share-content-popup.service';
import { PssDownloadPopupComponent } from './pss-download-popup/pss-download-popup.component';
import { PssUploadPopupComponent } from './pss-upload-popup/pss-upload-popup.component';
import { PssFiltersService } from './pss-filters.service';
import { CoursePropAndStatusesFilterService } from './course-prop-and-statuses-filter/course-prop-and-statuses-filter.service';
import { PssStatusMessageComponent } from '../pss-status-message/pss-status-message.component';
import { Subscriber } from 'rxjs';
import { PssClassroomPopupComponent } from './pss-classroom-popup/pss-classroom-popup.component';
@Component({
  selector: 'yorbit-pss-filters',
  templateUrl: './pss-filters.component.html',
  styleUrls: ['./pss-filters.component.scss']
})
export class PssFiltersComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() selectedTab: string;

  pssFiltersSub: any;
  filtersApplied: boolean;
  //selected page in pss
  showPreloaders: boolean;
  resultsLoaded: boolean;
  coursesLoaded: boolean;
  certStatusesloaded: boolean;
  courseProperties: Array<string>;
  noDataFound: boolean;
  pssFiltersLoaded: boolean;
  activeTab: string;
  courseFiltersProperties: Array<string>;
  anyCourseFiltersSelected: boolean;
  //Pss filters consists of date from and to,reset btn,Course filters and search
  //Download and upload buttons, apply and serach button
  filters: any;
  //filters required for course requests
  courseRequestsFilters: any;
  //filters required for cert requests
  certificationRequestsFilters: any;
  //filters required for ExternalCertificate
  ExternalcertificationRequestsFilters:any;
  //filters required for evaluation requests
  evaluationRequestsFilters: any;
  //variables declarations for date filters
  minDate = new Date(2015, 0, 1);
  maxDate = new Date();
  startDate: { value: any; date: any; month: any; apiFormat: any };
  endDateForm: FormControl;
  startDateForm: FormControl;
  endDate: { value: any; date: any; month: any; apiFormat: any };
  //reset button varaibles
  //course filters variables
  //search variables declaration
  MidRegExp = new RegExp('^[0-9]+');
  reqIdRegExp = new RegExp('^[0-9]+$');
  nameRegExp = new RegExp('^[a-zA-Z]+');
  searchModel: any;
  searchBy: string; //search by can have mid,requestid,name as values to differentiate type of search
  searched: boolean;
  SearchMid: Array<string>;
  SearchRequestId: Array<number>;
  suggestionList: any;
  mailNickname: string;
  usersListLoaded: boolean;
  incorrectMIdFormat: boolean;
  incorrectReqIDFormat: boolean;
  mixedFormatEntries: boolean;
  showSearchErrorByName: boolean;
  //download upload variables declaration
  downloadAllSelected: boolean;
  downloadAccepted: boolean;
  isFailed: boolean;
  //order by variables declaration
  @ViewChild('orderByToggleBtn', { static: false }) orderByToggleBtn: MatSlideToggle;
  orderBy: any;
  //pagination filter variables declaration
  paginateFilter: {
    pageSizeLimit: number;
    pageLength: number;
    pageIndex: number;
    PaginationContentIndex: number;
  };
  previouslySelectedValue: number;
  pageSizeOptions: number[];
  pageSize: any;
  pageIndex: number;
  @ViewChild(MatPaginator, { static: false }) pssPaginater: MatPaginator;
  @ViewChild(MatSelect, { static: false }) matSelect: MatSelect;
  previousSearchBy: string;


  constructor(
    private _pssService: PssService,
    private dialog: MatDialog,
    private _shareContentPopupService: ShareContentPopupService,
    private _pssFiltersService: PssFiltersService,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private _coursePropAndStatusesFilterService: CoursePropAndStatusesFilterService
  ) {
    this.pssFiltersSub = {};
    this.selectedTab = '';
    this.showPreloaders = true;
    this.courseProperties = [];
    this.noDataFound = false;
    this.activeTab = '';
    this.resultsLoaded = false;
    this.filtersApplied = false;
    this.courseFiltersProperties = [
      'ApprovalStatus',
      'Academy',
      'CourseName',
      'CourseType',
      'Genre',
      'Skill',
      'Expertise',
      'Vendor',
      'LearningOPMSPOC',
      'CertificationStatus',
      'CertificationSubStatus'
    ];
    this.anyCourseFiltersSelected = false;
    //set selectedTab value which is dependent on the pss page tab selected
    this.setValueForSelectedTab(this.router.url);
    this.getCourseFiltersFromAPI();
    this.getResultsLoadedStatus();
    this.getExternalCoursesListSub();
    this.getCourseFiltersListSub();
  }
  
  getResultsLoadedStatus() {
    this.pssFiltersSub.getResultsLoadedStatusSub = this._pssService
      .getResultsLoadedStatus()
      .subscribe((res: any) => {
        if (res.status && res.count != 0) {
          this.resultsLoaded = true;
        } else {
          this.resultsLoaded = false;
        }
      });
  }
  getCourseFiltersFromAPI() {
    this._coursePropAndStatusesFilterService.getAllExternalCourses(
      this.selectedTab
    );
    this._coursePropAndStatusesFilterService.getPssFilters(this.selectedTab);
    this.getCertStatusesList();
  }

  getExternalCoursesListSub() {
    this._coursePropAndStatusesFilterService
      .getcoursesListSubscription()
      .subscribe(data => {
        if (data.loaded) {
          let res = data.res;
          this.coursesLoaded = true;

          if (
            this.coursesLoaded &&
            this.certStatusesloaded &&
            this.pssFiltersLoaded
          ) {
            if (res == null || res.length == 0) {
              this.noDataFound = true;
            } else {
              this.noDataFound = false;
            }
            this.showPreloaders = false;
          }
        }
      });
  }
  getCourseFiltersListSub() {
    this._coursePropAndStatusesFilterService
      .getcourseFiltersSubscription()
      .subscribe(data => {
        if (data.loaded) {
          let res = data.res;
          this.pssFiltersLoaded = true;

          if (
            this.coursesLoaded &&
            this.certStatusesloaded &&
            this.pssFiltersLoaded
          ) {
            this.showPreloaders = false;
          }
        }
      });
  }
  getCertStatusesList() {
    this._coursePropAndStatusesFilterService
      .getCertificationStatuses(this.selectedTab)
      .then(res => {
        this.certStatusesloaded = true;
        if (
          this.coursesLoaded &&
          this.certStatusesloaded &&
          this.pssFiltersLoaded
        ) {
          this.showPreloaders = false;
        }
      });
  }
  ngAfterViewInit() {
    if(this.pssPaginater)
    this.pssPaginater.pageSize = this.paginateFilter.pageSizeLimit;
  }
  ngOnInit() {
    //subscribe to router events to identify the selected tab in pss
    this.subscribeToRouter();
    //initialise all independent variables
    this.initialiseVariables();
    //filters selected for api call
    this.filtersInit();
  }
  subscribeToRouter() {
    this.pssFiltersSub.routerEventSub = this.router.events
      .pipe(filter(events => events instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        this.showPreloaders = true;
        this.coursesLoaded = false;
        this.certStatusesloaded = false;
        this.pssFiltersLoaded = false;
        this.resultsLoaded = false;
        this.noDataFound = false;
        this.setValueForSelectedTab(event.url);
        this.getCourseFiltersFromAPI();
        this.resetFilter();
      });
  }
  //set values of selected tab using the current page route
  setValueForSelectedTab(url) {
    if (url.indexOf('externalCertificate/pending')!= -1) {
     this.selectedTab = 'externalrequest-pending';
     this.downloadAllSelected = false;
   } else if (url.indexOf('externalCertificate/actioned')!= -1) {
     this.selectedTab = 'externalrequest-actioned';
     this.downloadAccepted = true;
   }else if (url.indexOf('course-requests') != -1) {
     this.selectedTab = 'course-requests';
     this.downloadAllSelected = false;
   }  else if (url.indexOf('certification/pending') != -1) {
    this.selectedTab = 'certification-pending';
    this.downloadAllSelected = false
  } else if (url.indexOf('certification/actioned') != -1) {
    this.selectedTab = 'certification-actioned';
    this.downloadAccepted = true;
    this.isFailed =false;
  } else if (url.indexOf('evaluation-requests') != -1) {
     this.selectedTab = 'evaluation-requests';
   }
 }

  //initalise function def
  initialiseVariables() {
    //date vairables init
    this.dateVariablesInit();
    //search init
    this.searchVariablesInit();
    //download and upload filters init
    this.downloadVariablesInit();
    //orderby variables init
    this.orderByVariablesInit();
    //pagination varibales init
    this.paginationVariablesInit();
  }
  //initialise selected filters list funciton def
  filtersInit() {
    //overall filter options is listed in variable filters
    this.filters = {
      Academy: [],
      ApprovalStatus: [],
      AssignmentRequest: false,
      CertificationStatus: [],
      CertificationSubStatus: [],
      CourseName: [],
      Genre: [],
      Skill: [],
      CourseType: [],
      DueForPublish: false,
      EndDate: this.endDate.value,
      EvaluatorVendor: [],
      EvaluationType: '',
      Expertise: [],
      IsArrangeByDate: this.orderBy.type == 'date',
      Location: [],
      ProjectRequest: false,
      SLAHitRequest: false,
      LearningOPMSPOC: [],
      SearchMid: [],
      SearchRequestId: [],
      StartDate: this.startDate.value,
      Vendor: []
    };
    this.anyCourseFiltersSelected = false;
  }
  //date filter functionalities def
  //def of date init function
  dateVariablesInit() {
    // const today = new Date();
    // this.endDateForm = new FormControl(new Date(today));
    // this.setDateValue(this.endDateForm.value, 'end');
    // this.startDateForm = new FormControl(
    //   new Date(today.setDate(today.getDate() - 1)).toISOString()
    // );
    // this.setDateValue(new Date(this.startDateForm.value), 'start');
    this.endDateForm = new FormControl('');
    this.startDateForm = new FormControl('');
    this.startDate = {
      value: '',
      date: '',
      month: '',
      apiFormat: ''
    };
    this.endDate = {
      value: '',
      date: '',
      month: '',
      apiFormat: ''
    };
  }
  //set dates chosen in specific format
  setDateValue(value, dateType) {
    if ('start' === dateType) {
      this.startDate = {
        value: value,
        date: value.getDate(),
        month: value.toLocaleString('en-us', { month: 'short' }),
        apiFormat:
          value.getMonth() +
          1 +
          '-' +
          value.getDate() +
          '-' +
          value.getFullYear()
      };
    } else if ('end' === dateType) {
      let nextDay = new Date(value);
      nextDay.setDate(value.getDate() + 1);
      this.endDate = {
        value: value,
        date: value.getDate(),
        month: value.toLocaleString('en-us', { month: 'short' }),
        apiFormat:
          nextDay.getMonth() +
          1 +
          '-' +
          nextDay.getDate() +
          '-' +
          nextDay.getFullYear()
      };
    }
  }
  //on date change event triggered by date picker
  onDateChangeEvent(event, dateType) {
    this.setDateValue(event.value, dateType);
    this.filters = {
      ...this.filters,
      StartDate: this.startDate.apiFormat,
      EndDate: this.endDate.apiFormat
    };
  }
  //reset button function def
  resetFilter() {
    if (this._pssService.certDateChangesList.length != 0) {
      //show popup
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //pssMessageDialogContainer
      dialogConfig.data = {};
      const popup = this.dialog.open(
        CertDateChangeAlertComponent,
        dialogConfig
      );
      this.pssFiltersSub.CertChangeAlertPopupCloseSub1 = popup
        .afterClosed()
        .subscribe(status => {
          //status - if yes is chosen
          if (status) {
            this._pssService.certDateChangesList = [];
            this._pssService.updateCertDateChangesStatus({ status: false });
            this.orderBy = {
              type: 'date',
              text: 'Arrange by Course'
            };
            //initialise all independent variables
            this.initialiseVariables();
            this.filtersInit();
            this._coursePropAndStatusesFilterService.resetFilters();
            this._pssFiltersService.updateCertificateRequestsFilters(null);
            this._pssFiltersService.updateCourseRequestsFilters(null);
            this._pssFiltersService.updateExternalCertificateRequestsFilters(null);
            this._pssFiltersService.updateEvaluationRequestsFilters(null);
            this._pssService.updateResultsLoadedStatus({
              status: false,
              count: 0
            });
            //this.filterCourseName.courseNameInit();
            // this.filterdate.dateInit();
            // this.filtertype.typeInit();
            //courseNameINIt()
            //this.resetFilterEvent.emit('Type');
            //dateinit()
            //coursefiltersinit()
          } else {
          }
        });
    } else {
      //add inside the popup close event
      this.orderBy = {
        type: 'date',
        text: 'Arrange by Course'
      };
      //initialise all independent variables
      this.initialiseVariables();
      this.filtersInit();
      this._coursePropAndStatusesFilterService.resetFilters();
      this._pssFiltersService.updateCertificateRequestsFilters(null);
      this._pssFiltersService.updateCourseRequestsFilters(null);
      this._pssFiltersService.updateExternalCertificateRequestsFilters(null);
      this._pssFiltersService.updateEvaluationRequestsFilters(null);
      this._pssService.updateResultsLoadedStatus({
        status: false,
        count: 0
      });
      this.filtersApplied = false;
    }
  }
  //course filters updation func degf
  updateCourseFilters(event) {
    this.filters = { ...this.filters, ...event.data };
    let count = 0;
    this.courseFiltersProperties.forEach(prop => {
      if (this.selectedTab == 'course-requests') {
        if (this.filters[prop].length > 0) {
          count++;
        }
      } else {
        if (prop != 'ApprovalStatus' && this.filters[prop].length > 0) {
          count++;
        }
      }
    });
    if (count > 0) {
      this.anyCourseFiltersSelected = true;
    } else {
      this.anyCourseFiltersSelected = false;
    }
    ////console.log('filters updated', this.filters);
  }
  //search variables init func def
  searchVariablesInit() {
    this.searchBy = 'mid';
    this.SearchMid = [];
    this.SearchRequestId = [];
    this.searchModel = '';
    this.searched = false;
    this.usersListLoaded = false;
    this.suggestionList = [];
    this.incorrectMIdFormat = true;
    this.incorrectReqIDFormat = true;
    this.mixedFormatEntries = true;
    this.showSearchErrorByName = false;
  }
  //searchuser will be used to show auto suggestions when search with name
  // if searching with mid or requestId no auto suggestions will be called
  // searchUser() {
  //   if (this.searchBy == 'name') {
  //     this.searched = true;
  //     this.suggestionList = [];
  //     this.usersListLoaded = false;
  //     this._shareContentPopupService
  //       .getSuggestionList(this.searchModel)
  //       .then((response: any) => {
  //         this.usersListLoaded = true;
  //         this.suggestionList = response.value;
  //       });
  //   }
  // }
  onSearchModelChange() {
    if (this.previousSearchBy && this.previousSearchBy !== this.searchBy) {
      this.searchModel = '';
  }
  this.previousSearchBy = this.searchBy;
    this.incorrectMIdFormat = false;
    this.incorrectReqIDFormat = false;
    this.mixedFormatEntries = false;
    this.showSearchErrorByName = false;
    this.SearchMid = [];
    this.SearchRequestId = [];
    if (this.searchModel.length == 0) {
      this.clearSearchModel();
    } else {
      if (this.searchBy == 'name') {
        if (this.searchModel.length > 2) {
          this.searched = true;
          this.suggestionList = [];
          this.usersListLoaded = false;
          this._shareContentPopupService
            .getSuggestionList(this.searchModel)
            .then((response: any) => {
              this.usersListLoaded = true;
              this.suggestionList = response.value;
            });
        }
      } else if (this.searchBy == 'mid') {
        this.searchModel = this.searchModel.toUpperCase();
        this.SearchMid = this.searchModel.split(/[ ,]+/);
        this.SearchMid.forEach((mid, key) => {
          if (mid.length == 0 || this.SearchMid.indexOf(mid) != key) {
            this.SearchMid.splice(key, 1);
          }
        });
        this.searchModel = this.SearchMid.join(',');
        this.filters = {
          ...this.filters,
          SearchMid: this.SearchMid,
          SearchRequestId: []
        };
        this.validateSearchInput();
      } else if (this.searchBy == 'requestid') {
        this.SearchRequestId = this.searchModel.split(/[ ,]+/);
        this.searchModel = this.SearchRequestId.join(',');
        this.filters = {
          ...this.filters,
          SearchRequestId: this.SearchRequestId,
          SearchMid: []
        };
        this.validateSearchInput();
      }
    }
  }
  setSearchByValue(value) {
    this.searchBy = value;
    this.SearchMid = [];
    this.SearchRequestId = [];
    this.incorrectMIdFormat = true;
    this.incorrectReqIDFormat = true;
    this.mixedFormatEntries = true;
    this.showSearchErrorByName = false;
    this.onSearchModelChange();
  }
  //on selection of user, userSelectHandler will fetch mid and update search event
  userSelectHandler(event) {
    if (event.option.viewValue.toLowerCase() == 'no results found') {
      this.searchModel = '';
    }
  }
  //get mid and update search event
  getMid(user) {
    this.searchModel = user.displayName;
    this.SearchRequestId = [];
    this.SearchMid = [];
    this.mailNickname = user.userPrincipalName.split("@")[0];
    this.SearchMid.push(this.mailNickname);
    this.suggestionList = [];
    this.usersListLoaded = false;
    this.filters = {
      ...this.filters,
      SearchMid: this.SearchMid,
      SearchRequestId: this.SearchRequestId
    };
    this.searched = false;
    // this.searchUpdateEvent.emit({
    //   SearchMid: this.SearchMid,
    //   SearchRequestId: this.SearchRequestId
    // });
  }
  //to update search event by emitting 'searchUpdateEvent' event
  clearSearchModel() {
    this.searchModel = '';
    this.searched = false;
    this.SearchMid = [];
    this.SearchRequestId = [];
    this.suggestionList = [];
    this.usersListLoaded = false;
    this.filters = {
      ...this.filters,
      SearchMid: this.SearchMid,
      SearchRequestId: this.SearchRequestId
    };
    this.incorrectReqIDFormat = true;
    this.incorrectMIdFormat = true;
    this.mixedFormatEntries = true;
    this.showSearchErrorByName = false;
  }
  //upload-download func def
  downloadVariablesInit() {
    this.downloadAllSelected = false;
  }
  changeSubselection(subSelection) {
    if (subSelection == 'accepted') {
      this.downloadAccepted = true;
      this.isFailed = false;
    } else if (subSelection == 'not cleared'){
      this.isFailed = true;
      this.downloadAccepted = true;
    }else  {
      this.downloadAccepted = false;
      this.isFailed =false;
    }
    this.resetFilter();
  }
  changeDownloadOptions() {
    this.downloadAllSelected = !this.downloadAllSelected;
  }
  downloadSelected() {
    if (
      !this.downloadAllSelected &&
      (!this.anyCourseFiltersSelected &&
        (this.startDate.value == '' || this.endDate.value == '') &&
        this.SearchMid.length == 0 &&
        this.SearchRequestId.length == 0)
    ) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = {
        message: 'Please apply valid filters to download filtered data.'
      };
      this.dialog.open(PssStatusMessageComponent, dialogConfig);
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      let filterOptions;
      let subSelection = 'pending';
      //if course requests tab selected
      if (this.selectedTab == 'course-requests') {
        //filters for course requests
        this.courseRequestsFilters = {
          Academy: this.filters.Academy,
          ApprovalStatus: this.filters.ApprovalStatus,
          CertificationStatus: this.filters.CertificationStatus,
          CertificationSubStatus: this.filters.CertificationSubStatus,
          CourseName: this.filters.CourseName,
          CourseType: this.filters.CourseType,
          EndDate: this.filters.EndDate,
          IsArrangeByDate: this.filters.IsArrangeByDate,
          Location: this.filters.Location,
          SearchMid: this.filters.SearchMid,
          SearchRequestId: this.filters.SearchRequestId,
          StartDate: this.filters.StartDate,
          Vendor: this.filters.Vendor,
          LearningOPMSPOC: this.filters.LearningOPMSPOC
        };
        filterOptions = this.courseRequestsFilters;
      } else if (this.selectedTab.indexOf('certification') != -1) {
        //else if Externalcert tab selected
        this.certificationRequestsFilters = {
          Academy: this.filters.Academy,
          ApprovalStatus: this.filters.ApprovalStatus,
          CourseName: this.filters.CourseName,
          Expertise: this.filters.Expertise,
          Vendor: this.filters.Vendor,
          EndDate: this.filters.EndDate,
          IsArrangeByDate: this.filters.IsArrangeByDate,
          SearchMid: this.filters.SearchMid,
          SearchRequestId: this.filters.SearchRequestId,
          StartDate: this.filters.StartDate,
          LearningOPMSPOC: this.filters.LearningOPMSPOC
        };
        filterOptions = this.certificationRequestsFilters;
        if (this.selectedTab.indexOf('pending') != -1) {
          subSelection = 'pending';
        } else if (this.selectedTab.indexOf('actioned') != -1) {
          //if sub tab selected is actioned
          if (this.downloadAccepted) {
            subSelection = 'accepted';
          } else {
            subSelection = 'denied';
          }
        }
      }  else if (this.selectedTab.indexOf('externalrequest') != -1) {
        //else if Externalcert tab selected
        this.ExternalcertificationRequestsFilters = {
          Academy: this.filters.Academy,
          ApprovalStatus: this.filters.ApprovalStatus,
          CourseName: this.filters.CourseName,
          Genre: this.filters.Genre,
          Skill: this.filters.skill,
          EndDate: this.filters.EndDate,
          IsArrangeByDate: this.filters.IsArrangeByDate,
          SearchMid: this.filters.SearchMid,
          SearchRequestId: this.filters.SearchRequestId,
          StartDate: this.filters.StartDate,
          LearningOPMSPOC: this.filters.LearningOPMSPOC
        };
        filterOptions = this.ExternalcertificationRequestsFilters;
        if (this.selectedTab.indexOf('pending') != -1) {
          subSelection = 'pending';
        } else if (this.selectedTab.indexOf('actioned') != -1) {
          //if sub tab selected is actioned
          if (this.downloadAccepted) {
            subSelection = 'accepted';
          } else {
            subSelection = 'denied';
          }
        }
      }
       else if (this.selectedTab == 'evaluation-requests') {
        //else if evaluation tab selected
        this.evaluationRequestsFilters = {
          Academy: this.filters.Academy,
          AssignmentRequest: this.filters.AssignmentRequest,
          CertificationSubStatus: this.filters.CertificationSubStatus,
          CourseName: this.filters.CourseName,
          CourseType: this.filters.CourseType,
          DueForPublish: this.filters.DueForPublish,
          EndDate: this.filters.EndDate,
          EvaluatorVendor: this.filters.EvaluatorVendor,
          EvaluationType: this.filters.EvaluationType,
          LearningOPMSPOC: this.filters.LearningOPMSPOC,
          IsArrangeByDate: this.filters.IsArrangeByDate,
          ProjectRequest: this.filters.ProjectRequest,
          SLAHitRequest: this.filters.SLAHitRequest,
          SPOC: this.filters.SPOC,
          SearchMid: this.filters.SearchMid,
          SearchRequestId: this.filters.SearchRequestId,
          StartDate: this.filters.StartDate,
          Vendor: this.filters.Vendor
        };
        filterOptions = this.evaluationRequestsFilters;
      }
      dialogConfig.data = {
        selectedTab: this.selectedTab,
        getAll: this.downloadAllSelected,
        pssFilterRange: filterOptions,
        subSelection: subSelection
      };
      this.dialog.open(PssDownloadPopupComponent, dialogConfig);
    }
  }

  uploadPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //expertDialogContainer
    this.dialog.open(PssUploadPopupComponent, dialogConfig);
  }

  classRoomUploadPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //expertDialogContainer
    this.dialog.open(PssClassroomPopupComponent, dialogConfig);
  }
  //rch func def
  applyFilters() {
    if (this._pssService.certDateChangesList.length != 0) {
      //show popup and ask for the confirmation
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //pssMessageDialogContainer
      dialogConfig.data = {};
      const popup = this.dialog.open(
        CertDateChangeAlertComponent,
        dialogConfig
      );
      this.pssFiltersSub.CertChangeAlertPopupCloseSub2 = popup
        .afterClosed()
        .subscribe(status => {
          //status - if yes is chosen to clear unsaved data
          if (status) {
            this._pssService.certDateChangesList = [];
            //update pss filters and get data from api
            this.paginateFilter = {
              pageSizeLimit: 20,
              pageLength: 0,
              pageIndex: 0,
              PaginationContentIndex: 0
            };
            this.updateFilterOptions();
          } else {
            //do nothing
          }
        });
    } else {
      if (
        !this.anyCourseFiltersSelected &&
        (this.startDate.value == '' && this.endDate.value == '') &&
        this.searchModel.length == 0
      ) {
        //if none of the three sections(dates or course filters or search) are selected
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'popupDialogContainer';
        dialogConfig.data = {
          message: 'Please apply valid filters to fetch learners requests.'
        };
        this.dialog.open(PssStatusMessageComponent, dialogConfig);
      } else {
        let dateValidationSuccess = false;
        let searchValidationSuccess = false;
        //if any course filters selected
        //check dates and search validation
        if (
          (this.startDate.value != '' && this.endDate.value == '') ||
          (this.startDate.value == '' && this.endDate.value != '')
        ) {
          //dates validation
          dateValidationSuccess = false;
        } else {
          dateValidationSuccess = true;
        }
        if (this.searchModel.length != 0) {
          if (this.searchBy == 'name') {
            if (this.SearchMid.length == 0) {
              this.showSearchErrorByName = true;
              searchValidationSuccess = false;
            } else {
              this.showSearchErrorByName = false;
              searchValidationSuccess = true;
            }
          } else if (this.searchBy == 'mid') {
            if (this.incorrectMIdFormat || this.mixedFormatEntries) {
              searchValidationSuccess = false;
            } else {
              searchValidationSuccess = true;
            }
          } else if (this.searchBy == 'requestid') {
            if (this.incorrectReqIDFormat || this.mixedFormatEntries) {
              searchValidationSuccess = false;
            } else {
              searchValidationSuccess = true;
            }
          }
        } else {
          searchValidationSuccess = true;
        }

        if (!dateValidationSuccess && !searchValidationSuccess) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'popupDialogContainer';
          dialogConfig.data = {
            message:
              'Please apply valid Start date, End date and PSID(s)/RequestID(s) to fetch learners requests.'
          };
          this.dialog.open(PssStatusMessageComponent, dialogConfig);
        } else if (!dateValidationSuccess && searchValidationSuccess) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'popupDialogContainer';
          dialogConfig.data = {
            message:
              'Please apply valid StartDate and EndDate to fetch learners requests.'
          };
          this.dialog.open(PssStatusMessageComponent, dialogConfig);
        } else if (dateValidationSuccess && !searchValidationSuccess) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'popupDialogContainer';
          if (this.showSearchErrorByName) {
            dialogConfig.data = {
              message: 'Please enter valid name to fetch learners requests.'
            };
          } else {
            let searchInput =
              this.incorrectMIdFormat == true
                ? 'PSID(s)'
                : this.incorrectReqIDFormat == true
                  ? 'Request ID(s)'
                  : 'PSID(s)/Request ID(s)';
            dialogConfig.data = {
              message:
                'Please enter valid ' +
                searchInput +
                ' to fetch learners requests.'
            };
          }
          this.dialog.open(PssStatusMessageComponent, dialogConfig);
        } else {
          this.paginateFilter = {
            pageSizeLimit: 20,
            pageLength: 0,
            pageIndex: 0,
            PaginationContentIndex: 0
          };
          //update pss filters and get data from api
          this.updateFilterOptions();
        }
      }
    }
  }
  updateFilterOptions() {
    const urlParams = {
      limit: this.paginateFilter.pageSizeLimit,
      count: this.paginateFilter.PaginationContentIndex,
      nextOrPrevious: 'next'
    };
    this.filtersApplied = true;
    if (this.selectedTab == 'course-requests') {
      //filters for course requests
      this.courseRequestsFilters = {
        Academy: this.filters.Academy,
        ApprovalStatus: this.filters.ApprovalStatus,
        CertificationStatus: this.filters.CertificationStatus,
        CertificationSubStatus: this.filters.CertificationSubStatus,
        CourseName: this.filters.CourseName,
        CourseType: this.filters.CourseType,
        EndDate: this.filters.EndDate,
        IsArrangeByDate: this.filters.IsArrangeByDate,
        Location: this.filters.Location,
        SearchMid: this.filters.SearchMid,
        SearchRequestId: this.filters.SearchRequestId,
        StartDate: this.filters.StartDate,
        Vendor: this.filters.Vendor,
        LearningOPMSPOC: this.filters.LearningOPMSPOC
      };
      this._pssFiltersService.updateCourseRequestsFilters({
        filters: this.courseRequestsFilters,
        urlParams: urlParams
      });
    } else if (this.selectedTab.indexOf('certification') != -1) {
      //else if selected tab is cert requests
      //filters for certification requests
      this.certificationRequestsFilters = {
        Academy: this.filters.Academy,
        CertificateStatus: this.filters.ApprovalStatus,
        CourseName: this.filters.CourseName,
        CourseType: this.filters.CourseType,
        EndDate: this.filters.EndDate,
        Expertise: this.filters.Expertise,
        IsArrangeByDate: this.filters.IsArrangeByDate,
        SearchMid: this.filters.SearchMid,
        SearchRequestId: this.filters.SearchRequestId,
        StartDate: this.filters.StartDate,
        Vendor: this.filters.Vendor,
        LearningOPMSPOC: this.filters.LearningOPMSPOC
      };
      let subSelection;
      if (this.selectedTab == 'certification-pending') {
        subSelection = 'pending';
      } else if (this.selectedTab == 'certification-actioned') {
        //if sub tab selected is actioned
        if (this.downloadAccepted && !this.isFailed) {
          subSelection = 'accepted';
        }else if (this.downloadAccepted && this.isFailed){
          subSelection='not cleared';
        }else {
          subSelection = 'denied';
        }
      }
      this._pssFiltersService.updateCertificateRequestsFilters({
        filters: this.certificationRequestsFilters,
        urlParams: urlParams,
        subSelection: subSelection
      });
    }
    else if (this.selectedTab.indexOf('externalrequest') != -1) {
      //else if selected tab is cert requests
      //filters for certification requests
      this.ExternalcertificationRequestsFilters = {
        Academy: this.filters.Academy,
        CertificateStatus: this.filters.ApprovalStatus,
        CourseName: this.filters.CourseName,
        EndDate: this.filters.EndDate,
        Genre: this.filters.Genre,
        IsArrangeByDate: this.filters.IsArrangeByDate,
        SearchMid: this.filters.SearchMid,
        SearchRequestId: this.filters.SearchRequestId,
        StartDate: this.filters.StartDate,
        skill: this.filters.Skill,
        LearningOPMSPOC: this.filters.LearningOPMSPOC
      };
      let subSelection;
      if (this.selectedTab == 'externalrequest-pending') {
        subSelection = 'pending';
      } else if (this.selectedTab == 'externalrequest-actioned') {
        //if sub tab selected is actioned
        if (this.downloadAccepted) {
          subSelection = 'accepted';
        } else {
          subSelection = 'denied';
        }
      }
      this._pssFiltersService.updateExternalCertificateRequestsFilters({
        filters: this.ExternalcertificationRequestsFilters,
        urlParams: urlParams,
        subSelection: subSelection
      });
    } else if (this.selectedTab == 'evaluation-requests') {
      //else if selected tab is evaluation requests
      //filters for evaluation requests
      this.evaluationRequestsFilters = {
        Academy: this.filters.Academy,
        AssignmentRequest: this.filters.AssignmentRequest,
        CertificationSubStatus: this.filters.CertificationSubStatus,
        CourseName: this.filters.CourseName,
        CourseType: this.filters.CourseType,
        DueForPublish: this.filters.DueForPublish,
        EndDate: this.filters.EndDate,
        EvaluatorVendor: this.filters.EvaluatorVendor,
        EvaluationType: this.filters.EvaluationType,
        ProjectRequest: this.filters.ProjectRequest,
        SLAHitRequest: this.filters.SLAHitRequest,
        LearningOPMSPOC: this.filters.LearningOPMSPOC,
        SearchMid: this.filters.SearchMid,
        SearchRequestId: this.filters.SearchRequestId,
        StartDate: this.filters.StartDate,
        Vendor: this.filters.Vendor
      };
      this._pssFiltersService.updateEvaluationRequestsFilters({
        filters: this.evaluationRequestsFilters,
        urlParams: urlParams
      });
    }
  }
  //orderby func defs
  orderByVariablesInit() {
    this.orderBy = {
      type: 'date'
    };
  }
  orderRequestsBy(event) {
    if (this._pssService.certDateChangesList.length != 0) {
      //show popup
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //pssMessageDialogContainer
      dialogConfig.data = {};
      const popup = this.dialog.open(
        CertDateChangeAlertComponent,
        dialogConfig
      );
      this.pssFiltersSub.CertChangeAlertPopupCloseSub3 = popup
        .afterClosed()
        .subscribe(status => {
          //status - if yes is chosen to clear unsaved data
          if (status) {
            this._pssService.certDateChangesList = [];
            if (event.checked) {
              this.orderBy = {
                type: 'date'
              };
            } else {
              this.orderBy = {
                type: 'course name'
              };
            }
            this.filters = {
              ...this.filters,
              IsArrangeByDate: this.orderBy.type == 'date'
            };
            //this.updateFilter(this.orderBy.type);
            this.paginateFilter = {
              pageSizeLimit: 20,
              pageLength: 0,
              pageIndex: 0,
              PaginationContentIndex: 0
            };
            this.updateFilterOptions();
          } else {
            this.orderByToggleBtn.toggle();
          }
        });
    } else {
      if (event.checked) {
        this.orderBy = {
          type: 'date'
        };
      } else {
        this.orderBy = {
          type: 'course name'
        };
      }
      //this.updateFilter(this.orderBy.type);
      this.filters = {
        ...this.filters,
        IsArrangeByDate: this.orderBy.type == 'date'
      };
      this.paginateFilter = {
        pageSizeLimit: 20,
        pageLength: 0,
        pageIndex: 0,
        PaginationContentIndex: 0
      };
      this.updateFilterOptions();
    }
  }
  //pagination filter func defs
  paginationVariablesInit() {
    console.log("Pagination: ", this.paginateFilter);
    this.pageSizeOptions = [20, 50];
    this.pageIndex = 0;
    this.paginateFilter = {
      pageSizeLimit: 20,
      pageLength: 0,
      pageIndex: 0,
      PaginationContentIndex: 0
    };
    this.subscribeToRequestsCount();
  }
  openedChangeMatSelect(event) {
    this.previouslySelectedValue = this.paginateFilter.pageSizeLimit;
  }
  onPageSizeLimitChange(event) {
    if (this._pssService.certDateChangesList.length != 0) {
      //show popup
      const previousValue = this.previouslySelectedValue;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //pssMessageDialogContainer
      dialogConfig.data = {};
      const popup = this.dialog.open(
        CertDateChangeAlertComponent,
        dialogConfig
      );
      this.pssFiltersSub.CertChangeAlertPopupCloseSub4 = popup
        .afterClosed()
        .subscribe(status => {
          //status - if yes is chosen to clear unsaved data
          if (status) {
            this._pssService.certDateChangesList = [];
            this.pssPaginater.pageSize = this.paginateFilter.pageSizeLimit;
            this.pssPaginater.firstPage();
            this.onPaginate(null);
          } else {
            this.paginateFilter.pageSizeLimit = previousValue;
            this.pssPaginater.pageSize = previousValue;
          }
        });
    } else {
      this.pssPaginater.pageSize = this.paginateFilter.pageSizeLimit;
      this.pssPaginater.firstPage();
      this.onPaginate(null);
    }
  }
  onPaginate(event) {
    if (this._pssService.certDateChangesList.length != 0) {
      //show popup
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //pssMessageDialogContainer
      dialogConfig.data = {};
      const popup = this.dialog.open(
        CertDateChangeAlertComponent,
        dialogConfig
      );
      this.pssFiltersSub.CertChangeAlertPopupCloseSub5 = popup
        .afterClosed()
        .subscribe(status => {
          //status - if yes is chosen to clear unsaved data
          if (status) {
            this._pssService.certDateChangesList = [];
            this.paginateFilter = {
              pageSizeLimit: this.pssPaginater.pageSize,
              pageLength: this.pssPaginater.length,
              pageIndex: this.pssPaginater.pageIndex,
              PaginationContentIndex:
                this.pssPaginater.pageIndex * this.pssPaginater.pageSize
            };
            //call update filters which triggers the fetch api call for respective tab opened
            this.updateFilterOptions();
          } else {
            if (event != null) {
              this.pssPaginater.pageIndex = event.previousPageIndex;
            }
          }
        });
    } else {
      this.paginateFilter = {
        pageSizeLimit: this.pssPaginater.pageSize,
        pageLength: this.pssPaginater.length,
        pageIndex: this.pssPaginater.pageIndex,
        PaginationContentIndex:
          this.pssPaginater.pageIndex * this.pssPaginater.pageSize
      };
      //call update filters which triggers the fetch api call for respective tab opened
      this.updateFilterOptions();
    }
  }
  subscribeToRequestsCount() {
    this.pssFiltersSub.getPaginationCountSub = this._pssFiltersService
      .getTotalPaginationCount()
      .subscribe(res => {
        this.paginateFilter.pageLength = res;
      });
  }
  validateSearchInput() {
    if (this.searchBy == 'mid') {
      this.incorrectMIdFormat = !this.SearchMid.every(value => {
        //regex to check the mid format
        return this.reqIdRegExp.test(value);
      });
      if (this.SearchMid.length > 1) {
        this.mixedFormatEntries = !this.SearchMid.some(value => {
          return this.reqIdRegExp.test(value.toString());
        });
      } else {
        this.mixedFormatEntries = false;
      }
    } else if (this.searchBy == 'requestid') {
      this.incorrectReqIDFormat = !this.SearchRequestId.every(value => {
        //regex to check the requestid format
        return this.reqIdRegExp.test(value.toString());
      });
      if (this.SearchRequestId.length > 1) {
        this.mixedFormatEntries = this.SearchMid.some(value => {
          //return this.MidRegExp.test(value.toString());
          return true;
        });
      } else {
        this.mixedFormatEntries = false;
      }
    }
  }
  ngOnDestroy() {
    this.resetFilter();
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.pssFiltersSub) {
      let subscriber = this.pssFiltersSub[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
}


