<div class="role-based-learning-explore" fxLayout="column" fxFlexFill>
    <!-- Bread Crumbs -->
    <div class="header"  fxLayout="column">
     <div class="bread-crumbs" fxLayout="row">
        <div class="first-half" fxLayout="column" fxLayoutAlign="center start">
            <div class="title-role-based-learning">Role Based Learning</div>
            <div  class="bread-crumb-title" fxLayout="row" fxLayoutAlign="center center">
                <div class="family">Family</div>
                <div class="line line-family"></div>
            </div>
            <div class="bread-crumbs-value" fxLayout="row" fxLayoutAlign="start center">
                <div class="family" *ngIf="(empRoleTagging&&(families[selectedFamilyIndex] == empRoleTagging.Family))">{{families[selectedFamilyIndex]}}</div>
            </div>
        </div>
        <div class="second-half" fxLayout="column" fxLayoutAlign="center start">
            <div class="empty-title-div"></div>
            <div fxLayout="row" fxLayoutGap="100px">
                <div class="bread-crumb-title" fxLayout="row" fxLayoutAlign="center center">
                    <div class="role" fxLayout="row">Role</div>
                    <div class="line line-role"></div>
                </div>
                <div class="bread-crumb-title" [ngClass]="{'active-level': !isLevelSelected}" fxLayout="row"
                    fxLayoutAlign="center center">
                    <div class="level mousePointer" fxLayout="row" (click)="showLevels()">Level</div>
                    <div class="line line-level" *ngIf="isLevelSelected"></div>
                </div>
                <div class="bread-crumb-title" fxLayout="row" fxLayoutAlign="center center" *ngIf="isLevelSelected">
                    <div class="spl-grp" fxLayout="row">Specialization Group</div>
                    <div class="line line-spl-grp"></div>
                </div>
                <div class="bread-crumb-title Packages" fxLayout="row" fxLayoutAlign="center center" *ngIf="isLevelSelected">
                    <div class="packages" fxLayout="row">Packages</div>
                </div>
            </div>
            <div fxLayout="row">
                <div class="bread-crumbs-value role" fxLayout="row" fxLayoutAlign="start center">
                    <div class="overflow-ellipsis" *ngIf="(empRoleTagging&&(selectedRoleName == empRoleTagging.Role))">{{selectedRoleName}}</div>
                </div>
                <div class="bread-crumbs-value level" fxLayout="row" fxLayoutAlign="start center">
                    <div class="overflow-ellipsis" *ngIf="(empRoleTagging&&(selectedLevel.LevelName == empRoleTagging.Level)&&(selectedRoleName == empRoleTagging.Role))">{{selectedLevel.LevelName}}</div>
                </div>
                <div class="bread-crumbs-value spl-grp" fxLayout="row" fxLayoutAlign="start center"
                    *ngIf="isLevelSelected">
                    <div class="overflow-ellipsis" *ngIf="(empRoleTagging&&(selectedSpl.SpecializationName == empRoleTagging.Specialization)&&(selectedLevel.LevelName == empRoleTagging.Level)&&(selectedRoleName == empRoleTagging.Role))">{{selectedSpl ? selectedSpl.SpecializationName : ''}}</div>
                </div>
                <div class="bread-crumbs-value packages" fxLayout="row" fxLayoutAlign="start center"
                    *ngIf="isLevelSelected">
                    <div class="overflow-ellipsis">Basic + Advanced</div>
                </div>
            </div>
        </div>
    </div>

         <!-- Show Competencies-->
        <div class="competency" fxLayout="row">
            <div fxLayout="row" class="competency-levels" fxLayoutAlign="start start" *ngIf="!isLevelSelected">
                <div class="competency-label" *ngFor="let competency of competencies; let comp_index = index">
                    {{competency}}
                </div>
            </div>
            <div class="dummy-competency-strip" *ngIf="isLevelSelected"></div>
        </div>
    </div>

    <!-- Family Role Level Data -->
    <div class="family-role-level-data" fxLayout="column">
        <div fxLayout="row">
            <!-- Show Family-->
            <div class="family" fxLayout="column">
                <div class="family-label mousePointer" [ngClass]="{
                  'minds-active-family-label': (empRoleTagging&&(family == empRoleTagging.Family)),
                  'selected-family-label':isFamilySelected && selectedFamilyIndex == familyIndex
                }" *ngFor="let family of families; let familyIndex = index"
                    (click)="familySelected(familyIndex, family)" title="{{family}}">
                    <div class="label">{{family}}</div>
                    <div class="yorbitSprite icon-arrow-right-white display-inline float-right"
                        *ngIf="isFamilySelected && selectedFamilyIndex == familyIndex"></div>
                </div>
            </div>
            <div class="data-pane-second-half" fxLayout="column">
              

                <div fxLayout="row" class="role-level-data" fxFlex="1 1 auto">
                    <!-- Show Roles-->
                    <div class="role" fxLayout="column" fxLayoutAlign="start center">
                        <div *ngFor="let role of roles; let roleIndex = index" class="role-name roleLevelRow" [ngClass]="{
                          'minds-active-role-name': (empRoleTagging&&(role.RoleName == empRoleTagging.Role)),
                          'selected-role-name': selectedRoleIndex == roleIndex
                      }" fxLayout="row" fxLayoutAlign="center center">
                            <div class="mousePointer" (click)="roleSelected(roleIndex, role)" title="{{role.RoleName}}">
                                <div class="label">
                                    <div>{{role.RoleName}}</div>
                                </div>
                            </div>
                            <div *ngIf="selectedRoleIndex == roleIndex" class="yorbitSprite icon-arrow-right-blue"></div>
                        </div>
                    </div>
                    <!-- Show All levels-->
                    <div class="level" fxLayout="column" *ngIf="!isLevelSelected" fxFlex="1 1 auto">
                        <div *ngFor="let role of roles" fxLayout="row" class="level-row roleLevelRow">
                            <div *ngFor="let competency of competencies" fxLayout="row">
                                <div class="level-name" fxLayout="row" fxLayoutAlign="center end">
                                    <div *ngFor="let level of levels; let levelIndex = index" [ngClass]="{
                                      'minds-active-level-name': empRoleTagging&&(level.LevelSalaryGrade == empRoleTagging.LevelSalaryGrade)
                                  }">
                                        <div class="label mousePointer" title="{{level.LevelSalaryGrade}}"
                                            (click)="levelSelected(levelIndex, level)"
                                            *ngIf="selectedRoleName == role.RoleName && level.Competency == competency">

                                            {{level.LevelSalaryGrade}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Show Selected level, Specialization Group and Packages -->
                    <div fxLayout="row" *ngIf="isLevelSelected" fxFlex="1 1 auto" style="width:100%">
                        <!-- Selected Level-->
                        <div class="selected-level" fxLayout="column" fxLayoutAlign="start start">
                          <!--
                            <div class="level-name selected-level-name"
                                [ngClass]="{'minds-active-level-name' : empRoleTagging.LevelSalaryGrade == selectedLevel.LevelSalaryGrade}"
                                fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="label mousePointer" title="{{selectedLevel.LevelSalaryGrade}}">
                                    {{selectedLevel.LevelSalaryGrade}}</div>
                                <div *ngIf="empRoleTagging.LevelSalaryGrade == selectedLevel.LevelSalaryGrade"
                                    class="yorbitSprite icon-arrow-right-blue"></div>
                            </div>
                          -->
                          <div class="level-name level-name1"
                          *ngFor="let level of levels; let levelIndex = index"
                                [ngClass]="{
                                  'minds-active-level-name' : empRoleTagging&&(empRoleTagging.LevelSalaryGrade == level.LevelSalaryGrade),
                                  'selected-level-name':selectedLevel.LevelSalaryGrade==level.LevelSalaryGrade
                                }"
                                fxLayout="row" fxLayoutAlign="space-between center"
                                (click)="levelSelected(levelIndex, level)"
                                >
                                <div class="label mousePointer" title="{{level.LevelSalaryGrade}}">
                                    {{level.LevelSalaryGrade}}
                                  </div>
                                <div *ngIf="level.LevelSalaryGrade == selectedLevel.LevelSalaryGrade"
                                    class="yorbitSprite icon-arrow-right-blue"></div>
                            </div>

                        </div>
                        <div fxLayout="row" *ngIf="splGroups.length > 0" fxFlex="1 1 auto">
                            <!-- Specialization Groups -->
                            <div class="spl-grp" fxLayout="column">
                                <div *ngFor="let splGrp of splGroups; let splGrpIndex = index">
                                    <div *ngIf="splGrp.GroupName" fxLayout="column" fxLayoutAlign="center start"
                                        class="spl-grp-data"
                                        [ngClass]="{'minds-active-spl-grp': splGrpIndex == mindsSplGrpIndex,
                                                    'selected-spl':splGrp.GroupId==selectedSplGroupData.GroupId}">

                                        <div class="spl-grp-name " fxLayout="row" fxLayoutAlign="space-between center">
                                            <div class="spl-grp-name-text-overflow" title="{{splGrp.GroupName}}">
                                                {{splGrp.GroupName}}</div>
                                                <div *ngIf="splGrp.GroupId==selectedSplGroupData.GroupId" class="yorbitSprite icon-arrow-right-blue"></div>
                                        </div>
                                        <div *ngFor="let spl of splGrp.SpecializationWithPackageDetails; let splIndex = index"
                                            fxLayout="column">
                                            <div class="spl-name-container mousePointer"
                                            (click)="splSelected(splGrp, spl)">
                                                <div class="spl-name" title="{{spl.SpecializationName}}"
                                                [ngClass]="{'minds-active-spl':(empRoleTagging&&(spl.SpecializationName == empRoleTagging.Specialization)&&(selectedLevel.LevelName == empRoleTagging.Level)&&(selectedRoleName == empRoleTagging.Role)),'selected-spl-text': selectedSpl.SpecializationName == spl.SpecializationName}">
                                                
                                                {{spl.SpecializationName}}
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngFor="let splGrp of splGroups; let splGrpIndex = index">
                                    <div *ngIf="!splGrp.GroupName">
                                        <div *ngFor="let spl of splGrp.SpecializationWithPackageDetails; let splIndex = index"
                                            fxLayout="column" fxLayoutAlign="center start" class="spl-grp-data"
                                            [ngClass]="{'selected-spl': selectedSpl.SpecializationName == spl.SpecializationName}">

                                            <div class="spl-grp-name " fxLayout="row"
                                                fxLayoutAlign="space-between center">
                                                <div class="spl-grp-name-text-overflow" title="{{splGrp.GroupName}}">
                                                    {{spl.SpecializationName}}</div>
                                                    <div *ngIf="selectedSpl.SpecializationName == spl.SpecializationName" class="yorbitSprite icon-arrow-right-blue"></div>
                                            </div>
                                            <div fxLayout="column">
                                              <div class="spl-name-container mousePointer"
                                            (click)="splSelected(splGrp, spl)"
                                            [ngClass]="{'selected-spl-text': selectedSpl.SpecializationName == spl.SpecializationName}">
                                                <div class="spl-name" title="{{spl.SpecializationName}}"
                                                [ngClass]="{'minds-active-spl': empRoleTagging&&((empRoleTagging.Specialization == spl.SpecializationName)&&(empRoleTagging.LevelSalaryGrade == selectedLevel.LevelSalaryGrade))}">
                                                    {{spl.SpecializationName}}</div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <!-- Packages -->
                            <div class="packages" fxLayout="column" fxLayoutAlign="start start">
                                <div fxLayout="row" class="rolesDiv" *ngIf="0!=selectedSpl.PackageForSpecialization.length">
                                    <div class="roleIndication">F</div>
                                    <div class="roleExpansion">Family</div>
                                    <div class="roleIndication">R</div>
                                    <div class="roleExpansion">Role</div>
                                    <div class="roleIndication">L</div>
                                    <div class="roleExpansion">Level</div>
                                </div>
                                <div fxLayout="row" class="rblPackageHolder" *ngIf="0!=selectedSpl.PackageForSpecialization.length">
                                    <div *ngFor="let package of selectedSpl.PackageForSpecialization|orderBy:{'property': 'IsAdvanced','order':'ascending'};let indexOfelement=index;"
                              fxFlex="280px" style="margin-top: 15px;">
                              <yorbit-rbl-package [contentData]="package"
                              [contentLocation]="'Browse'"
                              [splData]="{
                                Specialization:selectedSpl.SpecializationName,
                                Role: selectedRoleName,
                                Level: selectedLevel.LevelName,
                                Family: families[selectedFamilyIndex],
                                LevelSalaryGrade: selectedLevel.LevelSalaryGrade,
                                Id: package.PackageId,
                                SpecializationGroup: selectedSplGroupData.GroupName,
                                packageIndex:indexOfelement
                              }"></yorbit-rbl-package>
                                        <!--Id: this.splData.Id,-->
                                      </div>
                                    </div>

                                    <div class="notFoundMessage" *ngIf="0==selectedSpl.PackageForSpecialization.length">
                                      <span  *ngIf="(empRoleTagging&&(selectedSpl.SpecializationName == empRoleTagging.Specialization)&&(selectedLevel.LevelName == empRoleTagging.Level)&&(selectedRoleName == empRoleTagging.Role))">Please contact your Learning Business Partner</span>
                                      <span  *ngIf="!(empRoleTagging&&(selectedSpl.SpecializationName == empRoleTagging.Specialization)&&(selectedLevel.LevelName == empRoleTagging.Level)&&(selectedRoleName == empRoleTagging.Role))">The Role Based Learning Package for this specialization will be updated soon in the coming days.</span>
                                    </div>

                               
                                <!--
                                <div class="mousePointer" (click)="packageClicked(true)">Mandatory</div>
                                <div class="mousePointer" (click)="packageClicked(false)">Advanced</div>
                              -->

                            </div>
                        </div>
                        <div fxLayout="row" *ngIf="!preloadLoading&&splGroups.length == 0" fxLayoutAlign="center center" class="no-spl">
                            No results found
                        </div>
                        <div fxLayout="row" *ngIf="preloadLoading" fxLayoutAlign="center center" class="no-spl">
                          <app-preloader></app-preloader>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
