<mat-dialog-content class="videoPopupInfo">
  <div class="close">
    <button mat-button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title">
    <div class="title" title="{{data.title}}">{{ data.title }}</div>
  </div>
  <!-- message -->
  <div class="message" *ngIf="!data.courseCompletion">
    Content Completed. 
  </div>
  <div class="message" *ngIf="data.courseCompletion">
    Course Completed. <br />
    Thank you for taking this course.
  </div>
  <mat-divider></mat-divider>
  <div style="text-align: center">
    <button class="okButton mousePointer" (click)="close()">
      OK
    </button>
  </div>
  <mat-divider></mat-divider>
</mat-dialog-content>
