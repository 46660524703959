<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="searchDiv">
        <input [(ngModel)]="searchText" type="text" class="textBoxStyle" placeholder="Search by Learning OPM SPOC">
    </div>
    <div class="font12 scrollHorizontalContainer">
    <div class="font12 scrollHorizontal row floatLeft" fxLayout="column wrap" fxLayoutAlign="start start"></div>
<div class="opmspocList font12" *ngFor='let opmspoc of displayList | searchFilter: searchText'>
    <div fxLayout="row" fxFlex>
    <input type="checkbox" [disabled]="!opmspoc.isEnabled" [checked]="opmspoc.isSelected" (change)="updateCheckedOptions(opmspoc.value, $event)">
    <div>{{opmspoc.value}}</div>
     </div>
</div>
</div>