<!-- <div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">DocumentType Download</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="content">
  <select (change)="selectdocumentType($event)">
      <h2><span>Document Type:</span></h2>
    <option value="ScoreCard">ScoreCard</option>
    <option value="EvaluationSummarySheet">EvaluationSummarySheet</option>
    <option value="ProblemStatement">Problem Statement</option>
    <option value="Solutions">Solutions</option>
    <option value="ErrorFile">ErrorFile</option>
  </select>
    <button type="button" (click)="Download()" >Download</button>
  </div>

</div> -->

<!-- 
<mat-dialog-content class="popupDialog" style="width:400px;"> -->

  
<mat-dialog-content class="popupDialog">
    <!--Close Popup Icon-->
    <!-- <div class="closePopupDialog">
      <button class="mat-button mat-icon-button" (click)="closePopup()">
        <mat-icon>close</mat-icon>
      </button>
    </div> -->
    
   <!--  <div class="popup-title" >
      <div class="title" style="padding: 0; width: 100%; max-height: 1400px; color: white;font-weight: bold;
      background-color: #6c737a;text-align: center">  Document Download <span class="mat-button-wrapper" style="
        float: right;
    "><mat-icon _ngcontent-cpk-c44="" (click)="closePopup()" class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon></span></div>
    </div>
     -->

     <div class="closePopupDialog">
      <button mat-button mat-icon-button (click)="closePopup()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="popupHeader">Document download</div>

       
    <!--Mat Divider-->
    <!-- <mat-divider></mat-divider> -->

    

    <div class= "messageText">
    <label class="col-md-3 col-lg-3" style="width:60px;">Document Type : </label>

            
  
        <select [(ngModel)]="documentType" (change)="selectdocumentType($event)" style="width: 230px;padding: 5px; font-size: 14px;text-indent: 10px;">
          <option value="" hidden>Select document type</option>
          <option value="ScoreCard">Scorecard</option>
          <option value="EvaluationSummarySheet">Evaluation Sheet</option>
          <option value="ProblemStatement">Problem Statement</option>
          <option value="Solutions">Solutions</option>
          <option value="ErrorFile">Error File</option>
        </select>
    </div>
 
    
  <mat-divider></mat-divider>
  
    <div class="actionButtonContainer">
      <div>
        <button mat-raised-button class="actionButton upload-btn"
        [disabled]="isDownloadDisabled"
        (click)="Download()">Download</button>
      </div>
    </div>
  </mat-dialog-content>
    
