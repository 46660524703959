import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/internal/operators/catchError';

@Injectable({
  providedIn: 'root'
})
export class Conentupload101Service {
  _baseURL: string;

  constructor(private _http: HttpClient,
    private _envSvc: EnvironmentService) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
  }

  updateQuizDataFor101(formData, header, courseUniqueId) {
    const URL = 'UpdateQuizDatafor101Courses/' + courseUniqueId;
    return this._http.post<any>(this._baseURL + URL, formData, header);
  }

  updateUnitsAndTopics(formData, header, courseUniqueId) {
    const URL = 'UnitsandContentsInsertorUpdateFor101/' + courseUniqueId;
    return this._http.post<any>(this._baseURL + URL, formData, header);
  }

  validateYoutubeLinks(urlList) {
    const URL = 'youtube/validation';
    return this._http.post<any[]>(this._baseURL + URL, urlList);
  }
  validateAzureLinks(urlList) {
    const URL = 'AMS/AzureMediaLinkValidation';
    return this._http.post<any[]>(this._baseURL + URL, urlList);
  }
  getVendor() {
    return this._http
      .get<any>(this._baseURL + 'GetVendorList');
      //.pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getTaxanomy()
  {
    return this._http.get<any>(this._baseURL+'Course/GetTaxonomy');
 //.pipe(catchError((error: any) => Observable.throw(error.json())));;
  }
  getCourseTypeList(expertise) {
    return this._http
      .get<any>(this._baseURL + 'GetCourseType/'+expertise);
      //.pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getAllYorbitcourses() {
    return this._http
      .get<any>(this._baseURL + 'GetAllCourses');
      //.pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  insertContentFormFor101(formData,courseUniqueId, header) {
    const URL = 'CourseInsertorUpdateFor101/';
    return this._http.post<any>(this._baseURL + URL+courseUniqueId, formData, header);
  }
  submitCourse(courseUniqueId, header) {
    const URL = 'UpdateCourseStatusToSubmitted/';
    return this._http.post<any>(this._baseURL + URL + courseUniqueId, header);
  }
  checkIfCourseNameExists101(courseName) {
    let payload = {
      CourseName: courseName
    };
    let res= this._http
      .post<any>(this._baseURL + 'CheckCourseNameExists', payload);
      //.pipe(catchError((error: any) => Observable.throw(error.json())));
      ////console.log(res);
      return res;
  }
  UploadCourseMaterialFromUI(file,courseUniqueId,courseName) {
    const fd = new FormData();
    fd.append('file', file);
    const URL = 'AzureBlob/Upload/101CourseMaterialFromUI/'+courseUniqueId+'/'+courseName;
    return this._http.post<any>(this._baseURL + URL,fd) .toPromise();;
  }
  getAccountProjectCategories()
  {
    return this._http.get<any>(this._baseURL+'Browse/AccountsProjectsCategories');
  }

  getCourseDetailsFor101(courseUniqueId) {
    const Url = "GetCourseFor101/" + courseUniqueId;
    return this._http.get<any>(this._baseURL + Url);
  }

  getQuizDataFor101(courseUniqueId) {
    const Url = "GetQuizDataFor101Courses/" + courseUniqueId;
    return this._http.get<any>(this._baseURL + Url);
  }
  getUnitsAndContentsFor101(courseUniqueId) {
    const Url = "GetUnitsandContentsFor101/" + courseUniqueId;
    return this._http.get<any>(this._baseURL + Url);
  }
  checkCourseNameExistsForEdit(courseName, courseUniqueId) {
    let payload = {
      CourseName: courseName
    };
    const URL = 'CheckCourseNameExistsForEdit/' + courseUniqueId;
    return this._http
      .post<any>(this._baseURL + URL, payload);
      //.pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getBadgeDetails(badgeId) {
    const URL = 'GetBadgeDetail/' + parseInt(badgeId);
    return this._http
      .get<any>(this._baseURL + URL);
      //.pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getCourseProficiencyList() {
    return this._http
      .get<any>(this._baseURL + 'CourseProficiency');
      //.pipe(catchError((error: any) => Observable.throw(error.json())));
  }
}
