
<mat-dialog-content>
  <div class="popup-title">
    <div class="heading notifications-header">Browser Suggestion</div>
    <div class="closePopupBtn">
      <button class="mat-button mat-icon-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="description message" >
    {{message}}
    </div>
    <mat-divider></mat-divider>
    <div class="action-btn" style="margin-bottom: 10px;" fxLayout="row wrap" fxLayoutAlign="center center">
      <button class="action-button" style="font-size: 11px;" mat-dialog-close>Close</button>
    
    </div>
</mat-dialog-content>