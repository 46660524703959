import { Component, OnInit } from '@angular/core';
import { FooterService } from '../footer.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedback: string;
  feedbackStatus: string;
  feedbackNote: string;
  sendingFeedback: boolean;
  feedbackNoteExtend: string;
  constructor(private _footerSvc: FooterService) {}

  ngOnInit() {
    this.feedback = '';
    this.feedbackStatus = '';
    this.feedbackNote =
      'We are happy to hear from you!';
      this.feedbackNoteExtend='Send in your feedback, suggestions, opinions here.';
    this.sendingFeedback = false;
  }

  callFeedbackService(feedback) {
    if (this.feedback === '') {
      this.feedbackNote = 'No feedback found.';
    } else {
      //enable the loader icon
      this.sendingFeedback = true;
      this.feedbackNote = 'Sending ...';
      // Remove new line characters from feedback input
      const feedbackString = feedback.replace(/\r?\n|\r/g, ' ');

      const jsonFeedback = '{"message":"' + feedbackString + '"}';

      const feedbackObject = JSON.parse(jsonFeedback);

      //Calling the service to send feedback
      this._footerSvc.sendFeedback(feedbackObject).subscribe(
        data => {
          this.feedback = '';
          this.sendingFeedback=false;
          this.feedbackStatus = 'feedbackSuccess';
          this.feedbackNote =
            'Your Feedback has been recorded and sent to the concerned team. Thank You!';
        },
        error => {
          this.sendingFeedback=false;
          this.feedback = '';
          this.feedbackStatus = 'feedbackError';
          this.feedbackNote =
            'Your feedback has not been submitted due to an error. Try after some time.';
        }
      );
    }
  }
}
