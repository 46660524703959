import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
  Inject,
  DoCheck,
  ChangeDetectorRef
} from '@angular/core';
import {
  IContentTileLP,
  IBadgeDetails,
  IPackageProgress,
  IVideoProgressList
} from './content-tile-lp.interface';
import { ContentTileLPModel } from './content-tile-lp.model';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../../shared/user-details/store';
import { AddedContentDataService } from '../../../shared/services/added-content-data.service';

import {
  LearningPath,
  PackageList
} from '../../../shared/user-details/store/user-details.interface';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import {
  LPBreadcrumbsStateModel,
  ICourseDetailsModel,
  IPackageDetailsModel,
  IVideoDetailsModel,
  IUnitDetailsModel
} from 'apps/yorbit-ui/src/app/learning-path/learning-path-breadcrumbs.model';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ILPBreadcrumbs } from 'apps/yorbit-ui/src/app/learning-path/learning-path-breadcrumbs.interface';
import { LearningPathService } from 'apps/yorbit-ui/src/app/learning-path/learning-path.service';
import { ContentTileLpService } from './content-tile-lp.service';
import { Subscriber } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataTemplateDialogComponent } from '../data-template-dialog/data-template-dialog.component';
import { StartCourseRequestComponent } from '../content-tile-lp/start-course-request/start-course-request.component';
import { CancelCourseRequestComponent } from '../content-tile-lp/cancel-course-request/cancel-course-request.component';
import { ContentTileDeletePopupComponent } from '../content-tile-lp/content-tile-delete-popup/content-tile-delete-popup.component';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '../../services/window.service';
import { DatePipe } from '@angular/common';
import { Globals } from '../../../globals';
import * as fromDeclareStore from '../../declare-store/store';
import { DeclareStoreService } from '../../declare-store/declare-store.service';
import { DeclarePopupComponent } from '../declare-popup/declare-popup.component';
import { SystemService } from '../../services/system.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DeclarePopupService } from '../declare-popup/declare-popup.service';
import { MandatoryCourseListService } from '../../services/mandatory-course-list.service';
import { WorkflowServiceService } from '../../services/workflow-service.service';
import { LearnersIntentPopupComponent } from './learners-intent-popup/learners-intent-popup.component';
import * as fromCloulabStore from '../../cloudlab-store/store';
import { CloudlabStoreService } from '../../cloudlab-store/cloudlab-store.service';
import { LearnerBatchSelectionComponent } from '../../../classroom/dialog/learner-batch-selection/learner-batch-selection.component';

@Component({
  selector: 'yorbit-content-tile-lp',
  templateUrl: './content-tile-lp.component.html',
  styleUrls: ['./content-tile-lp.component.scss']
})
export class ContentTileLpComponent
  implements OnInit, OnChanges, OnDestroy, DoCheck {
  @Output()
  showCourseFromTargetSkill: EventEmitter<any> = new EventEmitter<any>();
  @Input() skillPathPackageList: PackageList[];
  @Input('card-details') cardDetails: IContentTileLP;
  @Input() isTargetCourse: boolean;
  // @Input() isClassroom: boolean = false;
  @Output() updateDeclareForSkilling = new EventEmitter<any>();
  @Output() removeHighlightStyle = new EventEmitter<any>();


  ContentTileCompSubcriptions: any;
  coursesList: Array<any>;
  courseStatus: string;
  declareWorkflowStatus: boolean;
  canStartCourse: boolean;
  LPBreadcrumbs: ILPBreadcrumbs;
  showDeleteIcon: boolean;
  currentBreakPoint: string;
  assignerImageUrl: any;
  assignerName: string;
  canStartSkillPath: boolean;
  arrayPropertyFilterType: any;
  loadCoursesListFormAPI: boolean;
  loadedCoursesListFormAPI: boolean;
  credits: number;
  complianceLPDetails: null;
  mandatoryLPProgress: number;
  retakeDate: any;
  retakeDatePOSHUSA: any;
  mandatoryRetakeReminder: number;
  isCourseWorkflowStarted: boolean;
  declareStatus: boolean;
  declareStatusMessage: string;
  retakeDateLoading: boolean;
  totalRemainingDaysForRetake: number;
  isNewJoinee: boolean;
  learningPathCompletionDate: any;
  declareWorkflowStatusLoaded: boolean;
  quizDetails: any;
  showDeclareIcon: boolean;
  declareFallbackActive: boolean;
  lpPathId: any;
  isNewAdditionPresent: boolean;
  showCourseAdditionMsg: boolean;
  newAdditionMsg: string;
  canShowProgress: boolean = false;
  targetCourseSub: any = {};
  packageList: any = [];
  approvalStatus: string;
  intentComments: any;
  IntentId: any;
  quizPresent: boolean = false;
  show201CourseTileMsg: any;
  // isRetakeMandatory:boolean;
  userDetails$: any;
  workflow: any;
  isCloudLabAvailable: boolean;
  scormCourseIds: string[];
  scormResumeIds: any[];
  courseIdAceingLeadership: boolean = false;
  riskBrigadeItemType: string = 'RiskBrigadeCourse';
  message = '';
  mandatoryLPProgressCount: any;
  isClassroom: boolean;
  classroomBatches: [];

  constructor(
    private lpService: LearningPathService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private dialog: MatDialog,
    public contentTileLpService: ContentTileLpService,
    private mediaObserver: MediaObserver,
    private _graphSvc: GraphDataService,
    @Inject(WINDOW) private _window: any,
    private sanitizer: DomSanitizer,
    private _popup: MatDialog,
    private systemService: SystemService,
    public globals: Globals,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private declareStore: Store<fromDeclareStore.IdeclareState>,
    private declareStoreService: DeclareStoreService,
    private declarePopupService: DeclarePopupService,
    private mandatoryCourseService: MandatoryCourseListService,
    private workflowService: WorkflowServiceService,
    private cloulabStore: Store<fromCloulabStore.IcloudlabState>,
    private cloudlabStoreService: CloudlabStoreService,
    private cd: ChangeDetectorRef,
    private addedContentDataService: AddedContentDataService
  ) {
    this.courseStatus = 'loading...';
    this.declareFallbackActive = false;
    //this.cardDetails = { ...ContentTileLPModel };
    this.cardDetails = JSON.parse(JSON.stringify(ContentTileLPModel));
    this.ContentTileCompSubcriptions = {};
    this.coursesList = [];
    this.LPBreadcrumbs = LPBreadcrumbsStateModel;
    this.showDeleteIcon = false;
    this.subscribeMediaChanges();
    this.subscribeToBreadCrumbEvents();
    this.currentBreakPoint = '';
    this.assignerImageUrl = null;
    this.canStartSkillPath = true;
    this.arrayPropertyFilterType = new ArrayPropertyFilterPipe();
    this.loadCoursesListFormAPI = true;
    this.loadedCoursesListFormAPI = false;
    this.credits = 0;
    this.retakeDate = this.globals.mandatoryRetakeDate;
    this.retakeDatePOSHUSA = this.globals.mandatoryRetakeDateForPoshUSA;
    this.mandatoryLPProgress = 0;
    this.mandatoryRetakeReminder = 0;
    this.complianceLPDetails = this.globals.complianceLPDetails;
    this.totalRemainingDaysForRetake = 0;
    this.isNewJoinee = this.globals.isNewJoinee;
    this.scormCourseIds = this.globals.scormCourseIds;
    this.scormResumeIds = this.globals.scormResumeIds;



    this.learningPathCompletionDate = null;
    this.isNewAdditionPresent = false;
    this.showCourseAdditionMsg = false;
    // this.show201CourseTileMsg = false;
    //  this.isRetakeMandatory = false;
    this.approvalStatus = '';
    this.mandatoryLPProgressCount = 0;
    this.isClassroom = false;
    this.classroomBatches = [];
  }

  ngOnChanges(changesObj) {
    if (changesObj.cardDetails) {
      if (this.cardDetails.ItemType == this.riskBrigadeItemType) {
        this.cardDetails.ItemType = 'Course'
      }
      if (this.cardDetails.ItemId) {
        this.onFirstLoad();
        this.checkLabInStore(this.cardDetails.ItemId);
        // this.ShowQuizPromptForExternalQ();
        // this.ShowQuizPrompt();
      } else {
        //this.cardDetails = { ...ContentTileLPModel };
        this.cardDetails = JSON.parse(JSON.stringify(ContentTileLPModel));

      }
      // ////console.log('card details ******', this.cardDetails);
      // if (this.cardDetails) {
      //   this.contentTileLpService.checkAndGetUpcomingClassroomBatches(this.cardDetails.ItemId).subscribe((batches: any) => {
      //     this.classroomBatches = batches;
      //   });
      // }
    }
  }
  ngDoCheck() {
    // if (this.showCourseAdditionMsg) {
    //   //console.log('showCourseAdditionMsg', true);
    // } else if (
    //   this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() ===
    //     'code of conduct' &&
    //   this.coursesList.length == 5
    // ) {
    //   //console.log('showCourseAdditionMsg', false);
    //   this.showCourseAdditionMsg = this.canShowComplianceCourseMessage();
    //   //console.log(`showCourseAdditionMsg ${this.showCourseAdditionMsg}`);
    // }
  }
  getSkillPathCoursesStatus(coursesList) {
    this.coursesList = [];
    if (coursesList.length != 0) {
      coursesList.forEach(course => {
        let courseEntity = { ...course, Completed: false };
        if (courseEntity.ItemExpertise == '101') {
          //check achievements
          this.userDetailsStore
            .select(
              fromUserDetailsStore.getBadgesByCourseIdSelector(
                courseEntity.ItemId
              )
            )
            .subscribe(badge => {
              if (
                (badge.Status == true || badge.Status == 'true') &&
                !badge.IsRetakeMandatory
              ) {
                courseEntity.Completed = true;
              } else {
                courseEntity.Completed = false;
              }
            });
        } else {
          //check course progress list
          this.userDetailsStore
            .select(
              fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
                courseEntity.ItemId
              )
            )
            .subscribe(courseDetails => {
              if (
                courseDetails.WorflowStatus &&
                courseDetails.WorflowStatus != null &&
                (courseDetails.WorflowStatus.toLowerCase() ==
                  'course completed' ||
                  courseDetails.WorflowStatus.toLowerCase() == 'completed')
              ) {
                courseEntity.Completed = true;
              } else {
                courseEntity.Completed = false;
              }
              if (!courseEntity.Completed) {
                this.userDetailsStore
                  .select(
                    fromUserDetailsStore.getBadgesByCourseIdSelector(
                      courseEntity.ItemId
                    )
                  )
                  .subscribe(badge => {
                    if (badge.Status == true || badge.Status == 'true') {
                      this.declareStore
                        .select(
                          fromDeclareStore.getDeclareStatusByCourseId(
                            courseEntity.ItemId
                          )
                        )
                        .subscribe(declareStatus => {
                          ////console.log('declare status', declareStatus);
                          if (
                            declareStatus != undefined &&
                            declareStatus.DeclareFlagStatus ==
                            'DeclaredAndApproved'
                          ) {
                            courseEntity.Completed = true;
                          } else {
                            courseEntity.Completed = false;
                          }
                        });
                    }
                  });
              }
            });
        }
        //if any of the course is incomplete then start course is disabled
        // if (courseEntity.Completed == false) {
        //   this.canStartSkillPath = false;
        // }
        this.coursesList.push({ ...courseEntity });
        if (this.coursesList.length > 0) {
          let unCompletedCount = 0;
          this.coursesList.forEach(row => {
            if (row.Completed == false) {
              unCompletedCount++;
            }
          });
          if (unCompletedCount > 0) {
            this.canStartSkillPath = false;
          } else {
            this.canStartSkillPath = true;
          }
        }
      });
    } else {
      this.canStartSkillPath = true;
    }
    this.learningPathCompletionDate = null;
    this.getLearningPathCompletedDate();
  }

  onFirstLoad() {
    //  this.subscribeToBreadCrumbEvents();
    this.showCourseAdditionMsg = false;
    this.isCloudLabAvailable = false;
    if (this.isTargetCourse == undefined || this.isTargetCourse == null) {
      this.isTargetCourse = false;
    }
    this.showDeclareIcon =
      !(
        this.cardDetails.IsGlobalAndMandatory ||
        this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() ===
        this.globals.CocLpName.toLowerCase() || this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() === this.globals.BEACLpName.toLowerCase() ||
        this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() ==
        'sexual harassment prevention training - usa' ||
        this.LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName || this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() ==
        'msc industrial'
      ) &&
      this.cardDetails.ItemType.toLowerCase() == 'course' &&
      !(this.cardDetails.AccountPackage || this.cardDetails.ProjectPackage) &&
      !this.isDeclareBlocked();
    /* setting default value for declareWorkflowStatus as false as skill plan lps are not handled in the mobile app*/
    this.declareWorkflowStatus = false;
    this.declareWorkflowStatusLoaded = false;
    this.canStartCourse = false;
    this.courseStatus = 'loading...';
    this.courseIdAceingLeadership = -1 != this.globals.declareBlockedAceingLeadership.indexOf(this.cardDetails.ItemId);

    if (this.isTargetCourse) {
      this.getSkillPathCoursesStatus(this.lpService.getSkillPathPackageList());
    }
    if (
      this.cardDetails.ItemType == 'Package' ||
      this.cardDetails.ItemType == 'FamilyPackage'
    ) {
      //this.getCoursesInsidePackage();
    }
    if (
      (this.cardDetails.ItemExpertise == '201' ||
        this.cardDetails.ItemExpertise == '301') &&
      (this.cardDetails.ItemType != 'Package' &&
        this.cardDetails.ItemType != 'FamilyPackage')
    ) {
      this.courseStatus = 'loading...';
      this.getDeclareWorkflowStatus(
        this.cardDetails.ItemId,
        this.cardDetails.ItemExpertise
      );
    }
    if (this.cardDetails.ItemExpertise == '101') {
      if (
        this.cardDetails.Badges != null &&
        this.cardDetails.Badges.length != 0
      ) {
        if (
          (this.cardDetails.Badges[0].Status == 'true' ||
            this.cardDetails.Badges[0].Status == true) &&
          !this.checkIsRetakeMandatory(this.cardDetails.ItemId)
        ) {
          this.courseStatus = 'Course Completed';
        } else if (
          (this.cardDetails.Badges[0].Status == 'true' ||
            this.cardDetails.Badges[0].Status == true) &&
          this.checkIsRetakeMandatory(this.cardDetails.ItemId)
        ) {
          this.courseStatus = 'Start Course';

        }
        // else if ((this.cardDetails.Badges[0].Status == 'true' ||
        //   this.cardDetails.Badges[0].Status == true) && this.checkIsRetakeMandatory(this.cardDetails.ItemId)
        // ) {
        //   this.courseStatus = 'Start Course';
        // }
        else {
          // this.courseStatus = 'Start Course';
          this.userDetailsStore
            .select(
              fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
                this.cardDetails.ItemId
              )
            )
            .subscribe(course => {
              if (course.WorflowStatus == undefined) {
                this.courseStatus = course.WorkflowStatus;
              } else {
                this.courseStatus = course.WorflowStatus;
              }
              if (this.courseStatus == undefined || this.courseStatus == null) {
                this.courseStatus = 'Start Course';
              }
            });
        }
      }
      if (
        this.cardDetails.ItemType != 'Package' &&
        this.cardDetails.ItemType != 'FamilyPackage'
      ) {
        this.getCourseProgress(this.cardDetails.ItemId);
      }
      this.getDeclareWorkflowStatus(
        this.cardDetails.ItemId,
        this.cardDetails.ItemExpertise
      );
    }
    if (
      this.cardDetails.AssignedBy != null &&
      this.cardDetails.AssignedBy != ''
    ) {
      this.getAssignerDetails();
    }
    if (!this.isTargetCourse && this.cardDetails.ItemExpertise == '101') {
      this.getQuizDetails(this.cardDetails.ItemId);
    }
    // this.getCloudLabDetails();
    if (-1 != this.scormCourseIds.indexOf(this.cardDetails.ItemId)) {

      let scormBadgeData = this.lpService.getScormBadge().subscribe(status => {
        //this.resumeLP = status;
        // console.log("getscormbadge");
        let scormCourseStatus = false;
        let index = status.findIndex(x => x.courseId == this.cardDetails.ItemId);
        if (index != -1) {
          scormCourseStatus = status[index].status;
        }

        //if (!status) {
        if (!scormCourseStatus) {

          if (-1 != this.scormResumeIds.indexOf(this.cardDetails.ItemId)) {
            this.userDetailsStore
              .select(
                fromUserDetailsStore.getBadgesByCourseIdSelector(this.cardDetails.ItemId)
              )
              .subscribe(badge => {
                //console.log("badge");
                if (
                  !(badge.Status == true || badge.Status == 'true')) {

                  this.scormWorkflow();
                }
              })
          }
        } else {
          this.courseStatus = 'Course Completed';

        }
        //console.log("Status:", this.cardDetails.ItemId, this.courseStatus);
        scormBadgeData.unsubscribe();

      });
    }
  }

  ngOnInit() {
    this.message = 'Complete all the courses in this learning path to be <b>' + (this.globals.LpName == 'coc' ? this.globals.CocLpName : 'Business Ethics') + ' Compliant</b>';
    //console.log(this.message);
    if (this.isTargetCourse == undefined || this.isTargetCourse == null) {
      this.isTargetCourse = false;
      ////console.log('LP Name' + this.LPBreadcrumbs.LPDetails.LPName);s
    } else {
      this.ContentTileCompSubcriptions.targetTileSub = this.contentTileLpService.updateIsTargetCourse$(this.isTargetCourse);
    }
    // this.contentTileLpService.getMandatoryLPProgressStatus().subscribe(lpProgress =>{
    //   this.globals.mandatoryLPProgress = lpProgress;
    //   this.mandatoryLPProgress = this.globals.mandatoryLPProgress;
    // }); 
    let breadcrumbs = this.LPBreadcrumbs;
  }

  subscribeToBreadCrumbEvents() {
    //create breadcrumbs model and interface to init it in different components as well instead in the service
    this.ContentTileCompSubcriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        // //console.log("breadcrumbs", breadcrumbs);
        if (breadcrumbs.LPDetails.LPId != null) {
          this.LPBreadcrumbs = breadcrumbs;
          if (
            this.LPBreadcrumbs.LPDetails.IsLPMandatory ||
            this.LPBreadcrumbs.PackageDetails.PackageId != null
          ) {
            this.showDeleteIcon = false;
            this.contentTileLpService.getIsTargetCourse$().subscribe((isTargetCourse) => {
              // if (this.isTargetCourse) {
              if (isTargetCourse) {
                if (
                  this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() ===
                  this.globals.CocLpName.toLowerCase() || this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() === this.globals.BEACLpName.toLowerCase() ||
                  this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() ===
                  'sexual harassment prevention training - usa' ||
                  this.LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName
                ) {
                  //this.retakeDateLoading = true;
                  this.userDetailsStore
                    .select(
                      fromUserDetailsStore.getLearningPathByIdEntities(
                        breadcrumbs.LPDetails.LPId.toString().toUpperCase()
                      )
                    )
                    .subscribe(lp => {
                      //console.log('breadcrumbs');

                      this.getLPDetails(lp);
                      this.getMandatoryDates(lp);
                      this.packageList = lp;
                    });
                }
              }
            });
          } else {
            if (this.LPBreadcrumbs.LPDetails.LPName != this.globals.degreedLPName) {
              this.showDeleteIcon = true;
            }
          }
        }
      });
  }

  getCourseProgress(id) {
    this.canShowProgress = false;
    this.userDetailsStore
      .select(fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(id))
      .subscribe(course => {
        if (
          course != undefined &&
          this.cardDetails.PackageProgress.Progress != undefined
        ) {
          //this.cardDetails.PackageProgress.Progress = course.Progress;
          this.canShowProgress = true;
        }
      });
  }
  scormWorkflow() {
    this.userDetailsStore
      .select(
        fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
          this.cardDetails.ItemId)).subscribe(course => {
            this.cardDetails.WorflowStatus = course.WorflowStatus;
            if ('course completed' != course.WorflowStatus.toLowerCase()) {
              this.courseStatus = 'Course In-Progress';
            }
          })
  }

  checkFor201ApprovalStatus() {
    this.canStartCourse = false;
    this.userDetailsStore
      .select(
        fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
          this.cardDetails.ItemId
        )
      )
      .subscribe(course => {
        this.cardDetails.RequestId = course.RequestID;
        this.cardDetails.WorflowStatus = course.WorflowStatus;
        this.credits = course.Credits;
        if (course.WorflowStatus == undefined) {
          course.WorflowStatus = course.WorkflowStatus;
        }
        if (course.WorflowStatus == null) {
          this.cardDetails.WorflowStatus = '';
          course.WorflowStatus = '';
        }
        let WorflowStatus = course.WorflowStatus.toLowerCase();
        if (
          '' == WorflowStatus ||
          'request' == WorflowStatus ||
          'cancelled' == WorflowStatus ||
          'request cancelled' == WorflowStatus ||
          'rejected' == WorflowStatus ||
          'preapproved' == WorflowStatus ||
          'pre-approved' == WorflowStatus ||
          'send request' == WorflowStatus ||
          'not started' == WorflowStatus
        ) {
          this.courseStatus = 'Start Course';
          if (this.cardDetails.IsBlocked || this.declareWorkflowStatus) {
            this.canStartCourse = false;
          } else {
            this.canStartCourse = true;
          }
        } else if ('pending' == WorflowStatus) {
          this.courseStatus = 'Request Sent';
        } else if ('under review' == WorflowStatus) {
          this.courseStatus = 'Processing';
        } else if ('approved' == WorflowStatus) {
          this.courseStatus = 'Request Approved';
        } else {
          this.courseStatus = WorflowStatus;
        }
        if (this.approvalStatus && this.approvalStatus.length != 0) {
          if ('planning in-progress' == this.approvalStatus.toLowerCase()) {
            this.courseStatus = 'Planning In-Progress';
          } else if (
            'course procedure shared' == this.approvalStatus.toLowerCase()
          ) {
            this.courseStatus = 'Course Procedure shared';
          } else if (
            'course in-progress' == this.approvalStatus.toLowerCase()
          ) {
            this.courseStatus = 'Course In-Progress';
          } else if ('course completed - resigned' == this.courseStatus) {
            this.courseStatus = 'Course Completed';
          }
        }
        this.isCourseWorkflowStarted = this.isWorkflowStarted();
        this.getCloudLabDetails();
        this.show201CourseTileMsg = false;
        if (this.cardDetails.ItemExpertise == '201') {
          if (
            this.workflowService.canShowWorkflowMsg.includes(
              this.cardDetails.WorflowStatus.toUpperCase()
            )
          ) {
            this.show201CourseTileMsg = true;
          } else if (
            this.cardDetails.RequestId != null &&
            this.cardDetails.RequestId != '' &&
            !this.workflowService.canNotShowWorkflowMsg.includes(
              this.cardDetails.WorflowStatus.toUpperCase()
            )
          ) {
            this.getWorkflowStatus(this.cardDetails.RequestId.toString());
          } else {
            this.show201CourseTileMsg = false;
          }
        } else {
          //for expretise 101 and 301, we are not showing workflow message
          this.show201CourseTileMsg = false;
        }
      });
  }

  // getCoursesInsidePackage() {
  //   this.contentAddedCheckService.contentAddedDetailsForPackagesLoaded.subscribe(
  //     isLoaded => {
  //       //console.log(
  //         'packagescourses loaded content tile comp',
  //         this.cardDetails.ItemId,
  //         isLoaded
  //       );
  //       if (isLoaded) {
  //         this.ContentTileCompSubcriptions.getCoursesInPackages = this.userDetailsStore
  //           .select(fromUserDetailsStore.getPackagesListWithCourses)
  //           .subscribe(PackagesListWithCourses => {
  //             if (
  //               PackagesListWithCourses != undefined &&
  //               PackagesListWithCourses[this.cardDetails.ItemId] != undefined
  //             ) {
  //               this.coursesList =
  //                 PackagesListWithCourses[this.cardDetails.ItemId];
  //               this.loadedCoursesListFormAPI = true;

  //               if (this.ContentTileCompSubcriptions.getCoursesInPackages) {
  //                 this.ContentTileCompSubcriptions.getCoursesInPackages.unsubscribe();
  //               }
  //             } else {
  //               this.coursesList = [];
  //               this.loadedCoursesListFormAPI = true;
  //               // if (this.loadCoursesListFormAPI) {
  //               //   this.loadCoursesListFormAPI = false;
  //               //   this.lpService.loadCoursesInsidePackageToStore(
  //               //     this.cardDetails.AssignedBy,
  //               //     this.cardDetails.ItemId,
  //               //     this.cardDetails.AccountId,
  //               //     this.cardDetails.ProjectId
  //               //   );
  //               // }
  //             }
  //           });
  //       }
  //     }
  //   );
  // }

  getApprovalFor201(event) {
    this.removeHighlightStyle.emit(true);
    if (this.courseStatus.toLowerCase() == 'start course') {
      event.stopPropagation();
    }
    if (
      this.cardDetails.ItemExpertise == '201' ||
      this.cardDetails.ItemExpertise == '301'
    ) {
      if (this.courseStatus.toLowerCase() == 'start course') {
        if (
          this.canStartCourse ||
          (this.canStartSkillPath &&
            this.isTargetCourse &&
            !this.declareWorkflowStatus)
        ) {
          let payload = {
            LearnerIntentId: null,
            LearnerIntentRemarks: null,
            GetIntent: true,
            Prerequisite: [],
            validationResponse: ''
          };
          // if (this.isClassroom) {
          //   this.contentTileLpService.checkAndGetUpcomingClassroomBatches(this.cardDetails.ItemId).subscribe((batches: any) => {
          //     this.classroomBatches = batches;
          //     if (this.classroomBatches) {
          //       console.log("ppppppppppppppp", this.classroomBatches['BatchDetails']);
          //       this.dialog.open(LearnerBatchSelectionComponent, {
          //         id: "ClassroomBatchSelection",
          //         panelClass: 'popupDialogContainer',
          //         disableClose: true,
          //         width: '500px',
          //         data: {
          //           title: this.cardDetails.ItemName,
          //           batchList: this.classroomBatches['BatchDetails']
          //         }
          //       });
          //       this.dialog.getDialogById('ClassroomBatchSelection').afterClosed().subscribe((batchId) => {
          //         if (batchId) {
          //           this.openStartCoursePopup(payload);
          //         }
          //       });
          //     }
          //   });
          // } else {
          this.openStartCoursePopup(payload);
          // }
        } else if (this.cardDetails.IsBlocked) {
          this.dialog.open(DataTemplateDialogComponent, {
            data: {
              data:
                'To access this course you will need to complete the other courses in the skill plan or declare skill and have it validated.',
              itemName: this.cardDetails.ItemName
            },
            panelClass: 'popupDialogContainer',
            disableClose: true
          });
        } else if (this.declareWorkflowStatus) {
          if (this.systemService.detectMobileAgent()) {
            this.dialog.open(DataTemplateDialogComponent, {
              data: {
                data: 'Not allowed as you have declared this skill',
                itemName: this.cardDetails.ItemName
              },
              panelClass: 'popupDialogContainer',
              disableClose: true
            });
          }
        }
      }
    }
  }

  openStartCoursePopup(data) {
    //trigger start course request popup
    let popUpInput = {
      WorflowStatus: this.cardDetails.WorflowStatus,
      ItemId: this.cardDetails.ItemId,
      RequestId: this.cardDetails.RequestId,
      PackageId: this.LPBreadcrumbs.PackageDetails.PackageId,
      LPId: this.LPBreadcrumbs.LPDetails.LPId,
      IsLPMandatory: this.LPBreadcrumbs.LPDetails.IsLPMandatory,
      ItemExpertise: this.cardDetails.ItemExpertise,
      ItemName: this.cardDetails.ItemName,
      AssignorRole: this.cardDetails.AssignorRole,
      LearnerIntentId: data.LearnerIntentId,
      LearnerIntentRemarks: data.LearnerIntentRemarks,
      GetIntent: data.GetIntent,
      Prerequisite: data.Prerequisite,
      validationResponse: data.validationResponse,
      IsAccount: this.LPBreadcrumbs.CourseDetails.IsAccount,
      IsProject: this.LPBreadcrumbs.CourseDetails.IsProject,
      AccountId: this.LPBreadcrumbs.CourseDetails.AccountId,
      ProjectId: this.LPBreadcrumbs.CourseDetails.ProjectId,
      LPCategory: this.LPBreadcrumbs.LPDetails.LPCategory,
      ClassroomBatchId: data.classroom?.selectedBatchId
    };
    this.dialog.open(StartCourseRequestComponent, {
      id: 'getApprovalFor201',
      data: popUpInput,
      panelClass: 'popupDialogContainer',
      disableClose: true
      //addDialogContainer
    });
    this.dialog
      .getDialogById('getApprovalFor201')
      .afterClosed()
      .subscribe(data => {
        if (!data.canClose && data.getIntent) {
          //open Intent popup
          this.openIntentPopup(data);
        } else {
          this.approvalStatus = data.status;
          this.checkFor201ApprovalStatus();
          const isCourseWorkflowStarted = this.isWorkflowStarted();
          if (isCourseWorkflowStarted && data.canRouteToLPDetailPage) {
            this.backToLPDetailsPage(popUpInput);
          }
        }
      });
  }

  openIntentPopup(popupData) {
    //event.stopPropagation();
    //  const dialogConfig = new MatDialogConfig();
    //  dialogConfig.disableClose = true;
    //  dialogConfig.autoFocus = true;
    //  dialogConfig.panelClass = 'popupDialogContainer';

    //  dialogConfig.data = {
    //    IntentData: {
    //      intentId: this.IntentId,
    //      comments: this.intentComments
    //    }
    //  };
    //  ////console.log('data from dialog', dialogConfig.data);
    let classroom = {};
    if (popupData.isClassroom) {
      classroom = {
        isClassroom: popupData.isClassroom,
        selectedBatchId: popupData.selectedBatchId
      }
    }
    this.dialog.open(LearnersIntentPopupComponent, {
      id: 'learnersIntent',
      data: {
        ItemName: this.cardDetails.ItemName,
        Prerequisite: popupData.Prerequisite,
        validationResponse: popupData.validationResponse,
        classroom: classroom
      },
      panelClass: 'popupDialogContainer',
      disableClose: true
      //addDialogContainer
    });

    this.dialog
      .getDialogById('learnersIntent')
      .afterClosed()
      .subscribe(data => {
        //////console.log('data from popup', data);
        if (data.openApproval) {
          this.openStartCoursePopup(data.approvalData);
        }
      });
  }

  getDeclareWorkflowStatus(courseId, expertise) {
    if (this.showDeclareIcon) {
      this.declareStore
        .select(fromDeclareStore.getDeclareStatusByCourseId(courseId))
        .subscribe(status => {
          if (status) {
            this.processDeclareWorkflow(
              courseId,
              expertise,
              status.DeclareFlagStatus
            );
          } else {
            this.processDeclareWorkflow(courseId, expertise, 'not declared');
          }
        });
    }
  }

  declareFallbackGetUpdate(courseId, expertise) {
    this.declareStore
      .select(fromDeclareStore.getDeclareLoaded)
      .subscribe(loaded => {
        /**
         * if first load is true, get status for undefined
         */
        if (loaded) {
          this.declareStore.dispatch(
            new fromDeclareStore.DeclareGetStatusByCourseIds([
              { CourseId: courseId, Expertise: expertise }
            ])
          );
          this.getDeclareWorkflowStatus(courseId, expertise);

          this.declareStoreService
            .getDeclareWorkflowStatus([
              { CourseId: courseId, Expertise: expertise }
            ])
            .toPromise()
            .then(declareFlagStatus => {
              this.declareStore.dispatch(
                new fromDeclareStore.DeclareGetStatusSuccess(declareFlagStatus)
              );
              this.processDeclareWorkflow(
                courseId,
                expertise,
                declareFlagStatus
              );
            })
            .catch(error => {
              this.declareStore.dispatch(
                new fromDeclareStore.DeclareGetStatusError(error)
              );
            });
        }
      });
  }

  processDeclareWorkflow(courseId, expertise, status) {
    switch (status.toLowerCase()) {
      case 'declaredandapproved':
      case 'approved':
      case 'pending':
        this.declareWorkflowStatus = true;
        break;
      default:
        this.declareWorkflowStatus = false;
        break;
    }
    this.declareWorkflowStatusLoaded = true;
    if ('101' !== expertise) {
      this.checkFor201ApprovalStatus();
    } else {
      this.getQuizDetails(courseId);
    }
    if (expertise != '201') {
      this.show201CourseTileMsg = false;
    }
    this.isCourseWorkflowStarted = this.isWorkflowStarted();
    this.declareStatusMessage = this.getDeclareStatus(status);
    this.declareStatus = this.isDeclareWorkflowStarted(status);
  }
  cancelApprovalFor201() {
    this.removeHighlightStyle.emit(true);
    //triggger cancel course request popup
    this.dialog.open(CancelCourseRequestComponent, {
      id: 'cancelApprovalFor201',
      panelClass: 'popupDialogContainer',
      disableClose: true,
      //dialogue-box-data
      data: {
        ItemId: this.cardDetails.ItemId,
        PackageId: this.LPBreadcrumbs.PackageDetails.PackageId,
        RequestId: this.cardDetails.RequestId,
        ItemExpertise: this.cardDetails.ItemExpertise,
        LPId: this.LPBreadcrumbs.LPDetails.LPId,
        IsLPMandatory: this.LPBreadcrumbs.LPDetails.IsLPMandatory,
        ItemName: this.cardDetails.ItemName
      }
    });
    this.dialog
      .getDialogById('cancelApprovalFor201')
      .afterClosed()
      .subscribe(() => {
        this.checkFor201ApprovalStatus();
      });
  }

  deleteItemFromLP() {
    this.removeHighlightStyle.emit(true);
    //if course check workflow status
    if (this.cardDetails.ItemType == 'Course') {
      if (
        this.courseStatus.toLowerCase() == 'start course' ||
        this.cardDetails.ItemExpertise == '101'
      ) {
        //delete popup
        let hasCourseInProgress = false;
        if (this.cardDetails.PackageProgress.Progress > 0) {
          hasCourseInProgress = true;
        }
        this.dialog.open(ContentTileDeletePopupComponent, {
          id: 'ContentTileDeletePopupComponent',
          panelClass: 'popupDialogContainer',
          disableClose: true,
          //dialogue-box-data
          data: {
            LPId: this.LPBreadcrumbs.LPDetails.LPId,
            ItemId: this.cardDetails.ItemId,
            ItemExpertise: this.cardDetails.ItemExpertise,
            ItemType: this.cardDetails.ItemType,
            HasCourseInProgress: hasCourseInProgress,
            IsAccount: this.cardDetails.AccountPackage,
            IsProject: this.cardDetails.ProjectPackage,
            ItemName: this.cardDetails.ItemName,
            ProgressList: this.cardDetails.ProgressList
          }
        });
      } else {
        //show alert msg
        this.dialog.open(DataTemplateDialogComponent, {
          data: {
            data:
              'A ' +
              this.cardDetails.ItemExpertise +
              ' course that is in progress/approved/completed state cannot be deleted from the Learning Path.',
            itemName: this.cardDetails.ItemName
          },
          panelClass: 'popupDialogContainer',
          disableClose: true
        });
      }
    } else if (
      this.cardDetails.ItemType == 'Package' ||
      this.cardDetails.ItemType == 'FamilyPackage'
    ) {
      let isDeletable = true;
      let hasCourseInProgress = false;
      for (
        let courseIndex = 0;
        courseIndex < this.coursesList.length;
        courseIndex++
      ) {
        let course = this.coursesList[courseIndex];
        if (
          course.ItemExpertise == '101' &&
          parseInt(course.PackageProgress.Progress) > 0
        ) {
          hasCourseInProgress = true;
        }
        this.userDetailsStore
          .select(
            fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
              course.ItemId
            )
          )
          .subscribe(entity => {
            if (
              entity.WorflowStatus == null ||
              entity.WorflowStatus == undefined
            ) {
              entity.WorflowStatus = '';
            }
            course.WorflowStatus = entity.WorflowStatus.toLowerCase();
          });
        if (
          (course.ItemExpertise == '201' || course.ItemExpertise == '301') &&
          ('' != course.WorflowStatus &&
            'request' != course.WorflowStatus &&
            'cancelled' != course.WorflowStatus &&
            'request cancelled' != course.WorflowStatus &&
            'rejected' != course.WorflowStatus &&
            'preapproved' != course.WorflowStatus &&
            'pre-approved' != course.WorflowStatus &&
            'send request' != course.WorflowStatus &&
            'not started' != course.WorflowStatus)
        ) {
          isDeletable = false;
          break;
        }
      }
      if (isDeletable) {
        //delete popup
        this.dialog.open(ContentTileDeletePopupComponent, {
          id: 'ContentTileDeletePopupComponent',
          panelClass: 'popupDialogContainer',
          disableClose: true,
          //dialogue-box-data
          data: {
            LPId: this.LPBreadcrumbs.LPDetails.LPId,
            ItemId: this.cardDetails.ItemId,
            ItemExpertise: this.cardDetails.ItemExpertise,
            ItemType: this.cardDetails.ItemType,
            HasCourseInProgress: hasCourseInProgress,
            IsAccount: this.cardDetails.AccountPackage,
            IsProject: this.cardDetails.ProjectPackage,
            ItemName: this.cardDetails.ItemName,
            ProgressList: this.cardDetails.ProgressList
          }
        });
        this.dialog
          .getDialogById('ContentTileDeletePopupComponent')
          .afterClosed()
          .subscribe(deleteResponse => {
            if (deleteResponse) {
              this.coursesList.forEach(course => {
                this.globals.packageCourseList.splice(
                  this.globals.packageCourseList.indexOf(course.ItemId),
                  1
                );
              });
            }
          });
      } else {
        //show alert msg
        this.dialog.open(DataTemplateDialogComponent, {
          panelClass: 'popupDialogContainer',
          disableClose: true,
          //dialogue-box-data
          data: {
            data:
              'A ' +
              this.cardDetails.ItemExpertise +
              ' package cannot be deleted if there are any 201/301 course(s) in progress',
            itemName: this.cardDetails.ItemName
          }
        });
      }
    }
  }

  showCourseFromSkillPath(id, event) {
    if (this.isTargetCourse && this.currentBreakPoint == 'xs') {
      this.showCourseFromTargetSkill.emit({ ItemId: id });
      event.stopPropagation();
    }
  }

  subscribeMediaChanges() {
    this.mediaObserver.media$.subscribe((media: MediaChange) => {
      //add this.router.url=='/learningpath' check in the below if condition for xs
      this.currentBreakPoint = media.mqAlias;
    });
  }

  getAssignerDetails() {
    this._graphSvc.getUserImage(this.cardDetails.AssignedBy).subscribe(
      data => {
        this.assignerImageUrl = this.createImageURL(data);
      },
      error => {
        this.assignerImageUrl = null;
      }
    );
    this._graphSvc.getUserName(this.cardDetails.AssignedBy).subscribe(
      data => {
        this.assignerName = data.displayName;
      },
      error => {
        this.assignerName = this.cardDetails.AssignedBy;
      }
    );
  }

  createImageURL(imageBlob) {
    const imageURL = this._window.URL.createObjectURL(imageBlob);
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }

  declareSkill(event) {
    this.removeHighlightStyle.emit(true);
    event.stopPropagation();
    if (
      this.isCourseWorkflowStarted === false &&
      this.declareStatus === false
    ) {
      //action on declare flag if a course is not requested for approval or not requested for schedule
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //addDialogContainer
      let isSkillplan = false;
      this.userDetailsStore
        .select(
          fromUserDetailsStore.getLearningPathByIdEntities(
            //  breadcrumbs.LPDetails.LPName
            // breadcrumbs.LPDetails.IsLPMandatory ? breadcrumbs.LPDetails.LPName : breadcrumbs.LPDetails.LPId
            this.LPBreadcrumbs.LPDetails.IsLPMandatory &&
              this.LPBreadcrumbs.LPDetails.LPCategory != 'skilling'
              ? this.LPBreadcrumbs.LPDetails.LPName.toUpperCase()
              : this.LPBreadcrumbs.LPDetails.LPId
          )
        )
        .subscribe(lp => {
          this.lpPathId = lp.PathId;
          isSkillplan = lp.IsSkillPlan;
        });
      dialogConfig.data = {
        courseName: this.cardDetails.ItemName,
        courseId: this.cardDetails.ItemId,
        expertise: this.cardDetails.ItemExpertise,
        skillPlanId: this.LPBreadcrumbs.LPDetails.SkillPlanId,
        learningPathId: this.lpPathId,
        IsSkillPlan: isSkillplan
      };

      const dialogRef = this._popup.open(DeclarePopupComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          let declareFlagStatus = [{ "CourseId": this.cardDetails.ItemId, "DeclareFlagStatus": "DeclaredAndApproved" }]
          this.declareStore.dispatch(
            new fromDeclareStore.DeclareGetStatusSuccess(declareFlagStatus)
          );
          this.processDeclareWorkflow(
            this.cardDetails.ItemId,
            this.cardDetails.ItemExpertise,
            "declaredandapproved"
          );
          this.userDetailsStore
            .select(
              fromUserDetailsStore.getBadgesByCourseIdSelector(
                this.cardDetails.ItemId
              )
            )
            .subscribe(badge => {
              this.userDetailsStore
                .select(
                  fromUserDetailsStore.getQuizByCourseIdSelector(
                    this.cardDetails.ItemId
                  )
                )
                .subscribe(quiz => {
                  if (quiz && quiz.HasPassed) {
                  } else {
                    this.userDetailsStore.dispatch(
                      new fromUserDetailsStore.UpdateAchievements({
                        badgeId: badge.BadgeId,
                        badgeStatus: response,
                        quizScore: null
                      })
                    );
                  }
                });

              if (this.LPBreadcrumbs.LPDetails.LPCategory === 'skilling') {
                this.updateDeclareForSkilling.emit();
              }
            });
        }
      });
    } else {
      // do nothing
      if (this.systemService.detectMobileAgent()) {
        let message = this.isCourseWorkflowStarted
          ? this.showToolTip(this.cardDetails.WorflowStatus)
          : this.declareWorkflowStatus
            ? this.declareStatusMessage
            : 'Declare this skill';

        if ('declared' === message.toLocaleLowerCase()) {
          message = 'You have already declared this Course';
        }
        this.dialog.open(DataTemplateDialogComponent, {
          data: {
            data: message,
            itemName: this.cardDetails.ItemName
          },
          panelClass: 'popupDialogContainer',
          disableClose: true
        });
      }
    }
  }

  isWorkflowStarted() {
    if (this.cardDetails.ItemExpertise == '101') {
      if (this.courseStatus === 'Course Completed') {
        return true;
      } else {
        return false;
      }
    } else {
      const _201workflowStatus =
        this.courseStatus != null
          ? this.courseStatus.toLowerCase()
          : this.courseStatus;
      switch (_201workflowStatus) {
        //not started status
        case '':
        case null:
        case 'start course':
        case 'not started':
        case 'cancelled':
        case 'request cancelled':
        case 'rejected':
        case 'pre-approved':
          return false;
        default:
          return true;
      }
    }
  }

  showToolTip(workflowStatus) {
    if (workflowStatus === 'Under Review' || workflowStatus === 'Pending') {
      return 'Please \'Cancel Request\' to Declare the course!';
    } else if (
      (workflowStatus && workflowStatus.toLowerCase() === 'course completed') ||
      (this.cardDetails.ItemExpertise === '101' &&
        this.courseStatus.toLowerCase() === 'course completed')
    ) {
      return 'Sorry, completed courses cannot be declared';
    } else if (workflowStatus != null && workflowStatus !== '') {
      return 'Sorry, you cannot declare a course which has been started!';
    } else return '';
  }

  getDeclareStatus(Status) {
    if (Status != undefined) {
      switch (Status.toLowerCase()) {
        //not started status
        case 'not declared':
          return '';
        case 'denied':
          return 'Request Denied';
        case 'approved':
        case 'declaredandapproved':
          return 'Declared';
        case 'pending':
          return 'Pending Approval';
        case 'no any 201 course is present':
          return '';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  isDeclareWorkflowStarted(Status) {
    if (Status !== undefined) {
      switch (Status.toLowerCase()) {
        //not started status
        case 'not declared':
        case 'denied':
          return false;
        case 'approved':
        case 'declaredandapproved':
        case 'pending':
        case 'no any 201 course is present':
          return true;
        default:
          return true;
      }
    } else return false;
  }

  ngOnDestroy() {
    // if(this.ContentTileCompSubcriptions.breadcrumbsSubscription){
    //   this.ContentTileCompSubcriptions.breadcrumbsSubscription.unsubscribe();
    // }
    if (this.ContentTileCompSubcriptions.cloudLabSubscription) {
      this.ContentTileCompSubcriptions.cloudLabSubscription.unsubscribe();
    }
    if (this.ContentTileCompSubcriptions.mandatoryLpProgressSub) {
      this.ContentTileCompSubcriptions.mandatoryLpProgressSub.unsubscribe();
    }
    if (this.ContentTileCompSubcriptions.targetTileSub) {
      this.ContentTileCompSubcriptions.targetTileSub.unsubscribe();
    }
  }

  getLPDetails(lp) {
    if (
      this.LPBreadcrumbs.LPDetails.LPName == this.globals.CocLpName.toUpperCase() || this.LPBreadcrumbs.LPDetails.LPName == this.globals.BEACLpName.toUpperCase()
    ) {
      if (this.globals.complianceLPDetails == null) {
        this.globals.complianceLPDetails = {
          CompletedDate: this.isDateValid(lp.CompletedDate)
            ? this.getTime(lp.CompletedDate, 'dd/MMM/yyyy')
            : 'NA',
          Progress: lp.LearningPathProgress.Progress,
          PackageList: lp.PackageList
        };
        this.complianceLPDetails = this.globals.complianceLPDetails;
      }
    } else if (
      this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() ==
      'sexual harassment prevention training - usa' ||
      this.LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName
    ) {
      if (this.globals.complianceLPDetailsForPoshUSA == null) {
        this.globals.complianceLPDetailsForPoshUSA = {
          CompletedDate: this.isDateValid(lp.CompletedDate)
            ? this.getTime(lp.CompletedDate, 'dd/MMM/yyyy')
            : 'NA',
          Progress: lp.LearningPathProgress.Progress,
          PackageList: lp.PackageList
        };
        this.complianceLPDetails = this.globals.complianceLPDetailsForPoshUSA;
      }
    }
  }

  isDateValid(date) {
    if (date != 'NA') {
      const result = new Date(date);
      if (result.getFullYear() == 1) {
        return false;
      } else {
        return true;
        //  return this.datePipe.transform(date,'dd/MM/yyyy');
      }
    } else {
      return false;
    }
  }

  getTime(date, format) {
    const result = new Date(date);
    return new DatePipe('en-US').transform(result, format);
  }

  getMandatoryLPProgress(lp) {
    let percent = 0;
    let completedCourses = [];
    let packageList = [];
    packageList = lp.PackageList.filter(x => x.IsDeleted == false);
    if (packageList.length > 0) {
      packageList.forEach(packages => {
        this.userDetailsStore
          .select(
            fromUserDetailsStore.getBadgesByCourseIdSelector(packages.ItemId)
          )
          .subscribe(badge => {
            if (
              (badge.Status == true || badge.Status == 'true') &&
              !badge.IsRetakeMandatory
            ) {
              completedCourses.push(packages);
            }
          });
      });
      if (completedCourses.length !== 0) {
        percent = (completedCourses.length / packageList.length) * 100;
        this.mandatoryLPProgressCount = this.contentTileLpService
          .updateMandatoryLPProgressStatus(percent);
        //return percent;
      }
      //else return 0;
    }
    //return percent;
  }

  getMandatoryDates(lp) {
    //this.retakeDateLoading = true;
    if (
      this.LPBreadcrumbs.LPDetails.LPName == this.globals.CocLpName.toUpperCase() || this.LPBreadcrumbs.LPDetails.LPName == this.globals.BEACLpName.toUpperCase()
    ) {
      if (!this.globals.isDateAPICalled) {
        this.contentTileLpService
          .getMandatoryDates(this.globals.LpName == 'coc' ? this.globals.CocLpName.toUpperCase() : this.globals.BEACLpName.toUpperCase())
          .then(dates => {
            this.globals.isDateAPICalled = true;
            this.globals.mandatoryReminderCount = dates
              ? dates.ReminderID
              : null;
            this.globals.isNewJoinee = dates.IsNewJoinee;
            this.isNewJoinee = this.globals.isNewJoinee;
            this.mandatoryRetakeReminder = this.globals.mandatoryReminderCount;
            this.globals.mandatoryRetakeDate = new Date(dates.ReminderDate);
            //    if (dates.ReminderID >= 0) {
            //When the counter is zero, the courses are completed, otherwise not.
            // Also, when the counter is greater than zero, the learner is in cut off period
            //     this.globals.businessEthicsNotCompleted = false;
            //let date = new Date(dates.ReminderDate);
            // ReminderID == 1 implies the learner is a new Joiner (IsNewJoinee == true) and is in cutoff period
            // ReminderID >=2 implies the learner is a retaker(IsNewJoinee == false) and is in cutoff period
            setTimeout(() => {
              // this.globals.mandatoryRetakeDate = new Date(date.setDate(date.getDate() - 1));
              this.retakeDate = new Date(this.globals.mandatoryRetakeDate);
              this.totalRemainingDaysForRetake = this.numberOfDaysMore(
                this.globals.mandatoryRetakeDate
              );
              // this.globals.mandatoryLPProgress = this.getMandatoryLPProgress(
              //   lp
              // );

              this.ContentTileCompSubcriptions.mandatoryLpProgressSub = this.contentTileLpService.getMandatoryLPProgressStatus().subscribe(lpProgress => {
                this.globals.mandatoryLPProgress = lpProgress;
                this.mandatoryLPProgress = this.globals.mandatoryLPProgress;
              });
              this.getMandatoryLPProgress(lp);
              this.mandatoryLPProgress = this.globals.mandatoryLPProgress;
              this.canShowComplianceCourseMessage().then((res: boolean) => {
                this.showCourseAdditionMsg = res;
                this.retakeDateLoading = false;
              });
            }, 1000);
          });
      } else {
        setTimeout(() => {
          // this.isNewAdditionPresent = this.checkCourseInLP('1416', lp);
          this.retakeDate = new Date(this.globals.mandatoryRetakeDate);
          // this.globals.mandatoryLPProgress = this.getMandatoryLPProgress(lp);
          // this.mandatoryLPProgress = this.globals.mandatoryLPProgress;

          this.ContentTileCompSubcriptions.mandatoryLpProgressSub = this.contentTileLpService.getMandatoryLPProgressStatus().subscribe(lpProgress => {
            this.globals.mandatoryLPProgress = lpProgress;
            this.mandatoryLPProgress = this.globals.mandatoryLPProgress;
          });
          this.getMandatoryLPProgress(lp);
          this.mandatoryLPProgress = this.globals.mandatoryLPProgress;
          //this.showCourseAdditionMsg = this.canShowComplianceCourseMessage();
          this.canShowComplianceCourseMessage().then((res: boolean) => {
            this.showCourseAdditionMsg = res;
            this.retakeDateLoading = false;
          });

          // this.mandatoryRetakeReminder = this.globals.mandatoryReminderCount;
          // this.isNewJoinee = this.globals.isNewJoinee;
          // this.retakeDate = new Date(this.globals.mandatoryRetakeDate);
          // this.totalRemainingDaysForRetake = this.numberOfDaysMore(this.globals.mandatoryRetakeDate);
        }, 1000);
      }
    } else if (
      this.LPBreadcrumbs.LPDetails.LPName ==
      'SEXUAL HARASSMENT PREVENTION TRAINING - USA' ||
      this.LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName
    ) {
      this.showCourseAdditionMsg = false;

      if (!this.globals.isDateAPICalledForPoshUSA) {
        this.contentTileLpService
          //.getMandatoryDates('SEXUAL HARASSMENT PREVENTION TRAINING - USA')
          .getMandatoryDates(this.globals.newPOSHUSLpName)
          .then(dates => {
            this.globals.isDateAPICalledForPoshUSA = true;
            this.globals.mandatoryReminderCountForPoshUSA = dates
              ? dates.ReminderID
              : null;
            this.globals.isNewJoineeForPoshUSA = dates.IsNewJoinee;
            this.isNewJoinee = this.globals.isNewJoineeForPoshUSA;
            this.mandatoryRetakeReminder = this.globals.mandatoryReminderCountForPoshUSA;
            this.globals.mandatoryRetakeDateForPoshUSA = new Date(
              dates.ReminderDate
            );
            this.globals.counterPOSHUSA = dates.Counter;

            //    if (dates.ReminderID >= 0) {
            //When the counter is zero, the courses are completed, otherwise not.
            // Also, when the counter is greater than zero, the learner is in cut off period
            //     this.globals.businessEthicsNotCompleted = false;
            //let date = new Date(dates.ReminderDate);
            // ReminderID == 1 implies the learner is a new Joiner (IsNewJoinee == true) and is in cutoff period
            // ReminderID >=2 implies the learner is a retaker(IsNewJoinee == false) and is in cutoff period
            setTimeout(() => {
              // this.globals.mandatoryRetakeDate = new Date(date.setDate(date.getDate() - 1));
              this.retakeDate = new Date(
                this.globals.mandatoryRetakeDateForPoshUSA
              );
              this.totalRemainingDaysForRetake = this.numberOfDaysMore(
                this.globals.mandatoryRetakeDateForPoshUSA
              );
              // this.globals.mandatoryLPProgressForPoshUSA = this.getMandatoryLPProgress(
              //  lp
              // );
              this.getMandatoryLPProgress(lp);
              this.mandatoryLPProgress = this.globals.mandatoryLPProgressForPoshUSA;
              this.ContentTileCompSubcriptions.mandatoryLpProgressSub = this.contentTileLpService.getMandatoryLPProgressStatus().subscribe(lpProgress => {
                this.globals.mandatoryLPProgressForPoshUSA = lpProgress;
                this.mandatoryLPProgress = this.globals.mandatoryLPProgressForPoshUSA;
              });
              //this.showCourseAdditionMsg = this.canShowComplianceCourseMessage();
              this.retakeDateLoading = false;
            }, 1000);
          });
      } else {
        setTimeout(() => {
          // this.isNewAdditionPresent = this.checkCourseInLP('1416', lp);
          // this.globals.mandatoryLPProgressForPoshUSA = this.getMandatoryLPProgress(
          //  lp
          // );
          this.getMandatoryLPProgress(lp);
          this.ContentTileCompSubcriptions.mandatoryLpProgressSub = this.contentTileLpService.getMandatoryLPProgressStatus().subscribe(lpProgress => {
            this.globals.mandatoryLPProgressForPoshUSA = lpProgress;
            this.mandatoryLPProgress = this.globals.mandatoryLPProgressForPoshUSA;
          });
          this.mandatoryLPProgress = this.globals.mandatoryLPProgressForPoshUSA;
          //this.showCourseAdditionMsg = this.canShowComplianceCourseMessage();
          this.retakeDateLoading = false;
          // this.mandatoryRetakeReminder = this.globals.mandatoryReminderCount;
          // this.isNewJoinee = this.globals.isNewJoinee;
          // this.retakeDate = new Date(this.globals.mandatoryRetakeDate);
          // this.totalRemainingDaysForRetake = this.numberOfDaysMore(this.globals.mandatoryRetakeDate);
        }, 1000);
      }
    }
  }

  numberOfDaysMore(retakeDate) {
    let today = new Date();
    let totalDays = 0;
    let currentMonth;
    let retakeDateMonth;
    let currentYear = today.getFullYear();
    let retakeDateYear = new Date(retakeDate).getFullYear();

    currentMonth = today.getMonth();
    retakeDateMonth = new Date(retakeDate).getMonth();

    if (today <= new Date(retakeDate)) {
      //To get the number of days remaining in the current month
      totalDays =
        new Date(today.getFullYear(), currentMonth + 1, 0).getDate() -
        today.getDate();
      if (currentYear === retakeDateYear) {
        //Iterate from next month
        for (let i = currentMonth + 1; i <= retakeDateMonth; i++) {
          totalDays += new Date(today.getFullYear(), i + 1, 0).getDate();
        }
      } else {
        let daysInCurrentYear = 0;
        let daysInRetakeYear = 0;
        //Get total days of current year starting from next month
        daysInCurrentYear += this.remainingDaysInAYear(
          currentYear,
          currentMonth + 1,
          11
        );
        //Get total days of retake year starting from month 0
        daysInRetakeYear += this.remainingDaysInAYear(
          retakeDateYear,
          0,
          retakeDateMonth
        );
        totalDays = totalDays + daysInCurrentYear + daysInRetakeYear;
      }
    }
    return totalDays;
  }

  remainingDaysInAYear(year, startMonth, EndMonth) {
    let daysInGivenYear = 0;
    for (let i = startMonth; i <= EndMonth; i++) {
      daysInGivenYear += new Date(year, i + 1, 0).getDate();
    }
    return daysInGivenYear;
  }

  getCompletedDate(itemId) {
    this.userDetailsStore
      .select(fromUserDetailsStore.getBadgesByCourseIdSelector(itemId))
      .subscribe(badge => {
        this.cardDetails.ModifiedDate = badge.CompletedDate;
      });
    return this.cardDetails.ModifiedDate;
  }

  getLearningPathCompletedDate() {
    let date = null;
    let isRetakeMandatoryCheck = false;
    let retakeCourseCount = 0;
    if (this.coursesList.length > 0) {
      this.coursesList.forEach(course => {
        this.userDetailsStore
          .select(
            fromUserDetailsStore.getBadgesByCourseIdSelector(course.ItemId)
          )
          .subscribe(badge => {
            //To track if any of the badge's MandatoryRetake flag is set
            if (badge.IsRetakeMandatory) {
              isRetakeMandatoryCheck = true;
              retakeCourseCount++;
            }
            if (this.isDateValid(badge.CompletedDate)) {
              if (date == null) {
                date = this.getTime(badge.CompletedDate, 'dd/MMM/yyyy');
              } else {
                if (new Date(badge.CompletedDate) > new Date(date)) {
                  date = this.getTime(badge.CompletedDate, 'dd/MMM/yyyy');
                }
              }
            }
          });
      });
      if (retakeCourseCount == this.coursesList.length) {
        this.learningPathCompletionDate = null;
      } else {
        this.learningPathCompletionDate = date;
      }
    }
  }

  checkIsRetakeMandatory(itemId) {
    let retakeMandatory = false;
    this.userDetailsStore
      .select(fromUserDetailsStore.getBadgesByCourseIdSelector(itemId))
      .subscribe(badge => {
        retakeMandatory = badge.IsRetakeMandatory;
        return retakeMandatory;
      });
    return retakeMandatory;
  }

  reloadSkillTargetCourse() {
    if (this.isTargetCourse) {
      this.canStartSkillPath = true;
      this.getSkillPathCoursesStatus(this.lpService.getSkillPathPackageList());
    }
  }

  getQuizDetails(courseId) {
    this.userDetails$ = this.userDetailsStore.select(
      fromUserDetailsStore.getUserDetailObject
    );
    this.userDetails$.subscribe(userDetails => {
      const quiz = userDetails.QuizDetails.find(
        quiz => quiz.EntityId == courseId
      );
      if (quiz && quiz.EntityId) {
        this.quizDetails = { ...quiz };
        this.quizPresent = true;
        // ////console.log("**********quiz details....", this.quizDetails);
      } else {
        this.quizPresent = false;
        this.quizDetails = {
          Progress: 0
        };
      }

      if (
        this.quizDetails &&
        this.quizDetails.HasPassed &&
        !this.checkIsRetakeMandatory(this.cardDetails.ItemId)
      ) {
        this.courseStatus = 'Course Completed';
      } else if (this.cardDetails.Badges != null &&
        this.cardDetails.Badges.length != 0 &&
        (this.cardDetails.Badges[0].Status == 'true' ||
          this.cardDetails.Badges[0].Status == true) &&
        !this.checkIsRetakeMandatory(this.cardDetails.ItemId)) {
        // Do not reset it to Start course. for Scorm Courses


      } else {
        // this.courseStatus = 'Start Course';
        this.userDetailsStore
          .select(
            fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
              this.cardDetails.ItemId
            )
          )
          .subscribe(course => {
            if (course.WorflowStatus == undefined) {
              this.courseStatus = course.WorkflowStatus;
            } else {
              this.courseStatus = course.WorflowStatus;
            }
            if (this.courseStatus == undefined || this.courseStatus == null) {
              this.courseStatus = 'Start Course';
            }
          });
      }
      if (this.cardDetails.ItemExpertise == '101') {
        this.getCloudLabDetails();
      }
    });
    // this.userDetailsStore
    //   .select(fromUserDetailsStore.getQuizByQuizIdSelector(courseId))
    //   .subscribe(quiz => );
  }

  canShowQuizPrompt() {
    if (
      this.quizDetails &&
      !this.quizDetails.HasPassed &&
      this.cardDetails.PackageProgress &&
      this.cardDetails.PackageProgress.Progress == '100' &&
      !this.scormCourseIds
    ) {
      return true;
    }
    return false;
  }

  ShowQuizPrompt() {
    if (
      this.quizPresent &&
      this.cardDetails.ItemExpertise === '101' &&
      this.quizDetails &&
      !this.quizDetails.HasPassed &&
      this.cardDetails.PackageProgress &&
      this.cardDetails.PackageProgress.Progress == '100' &&
      this.courseStatus !== 'Course Completed'
    ) {
      return true;
    }
    return false;
  }

  ShowQuizPromptForExternalQ() {
    if (
      this.quizPresent &&
      this.cardDetails.ItemExpertise === '101' &&
      this.quizDetails &&
      !this.quizDetails.HasPassed &&
      this.cardDetails.VideoCount == '0'
    ) {
      return true;
    }
    return false;
  }

  checkCourseInLP(courseId, lp) {
    let packageList = [];
    let isPresent = false;
    packageList = lp.PackageList;
    if (packageList.length > 0) {
      packageList.forEach(row => {
        if (row.ItemId == courseId) {
          isPresent = true;
        }
      });
    } else {
      isPresent = false;
    }
    //console.log(`ispresent ${isPresent}`);
    return isPresent;
  }
  canShowComplianceCourseMessage() {
    this.isNewAdditionPresent = this.checkCourseInLP('1416', this.packageList);
    let compliant = this.checkIsComplaint(this.isNewJoinee);
    //console.log(`compliant ${compliant}`);
    let nonComplaint = this.checkIsBlocked(this.isNewJoinee);
    //console.log(`nonComplaint ${nonComplaint}`);

    let isIn45DaysWindow = this.checkIsIn45DaysWindow(this.isNewJoinee);
    //console.log(`isIn45DaysWindow ${isIn45DaysWindow}`);

    let currentDate = new Date();
    this.newAdditionMsg = '';
    //Check if mind is Retaker
    if (!this.isNewJoinee) {
      //console.log(`isNewJoinee ${this.isNewJoinee}`);

      //Check if ISMS is not present
      if (!this.isNewAdditionPresent) {
        //console.log(`isNewAdditionPresent ${this.isNewAdditionPresent}`);

        //if mind is compliant
        if (compliant || isIn45DaysWindow || nonComplaint) {
          //show message
          if (compliant) {
            this.newAdditionMsg =
              'Information Security Awareness course will be added to this learning path';
            this.newAdditionMsg +=
              ' on ' +
              this.getTime(this.retakeDate, 'dd/MMM/yyyy') +
              ' for your pursual.';
          } else if (isIn45DaysWindow || nonComplaint) {
            this.newAdditionMsg =
              'Information Security Awareness course will be added to this learning path';
            this.newAdditionMsg +=
              ' in the year ' +
              (currentDate.getFullYear() + 1) +
              ' for your pursual.';
          }
          //console.log(`newAdditionMsg ${this.newAdditionMsg}`);
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(true);
            }, 0);
          });
        }
      } else {
        //If ISMS is already present in LP
        // if (currentDate > this.retakeDate || currentDate <= this.retakeDate || isIn45DaysWindow)
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(false);
          }, 0);
        });
      }
    }
    //if the mind is New Joinee
    else {
      //console.log(`isNewJoinee ${this.isNewJoinee}`);
      //Check if ISMS is not present in LP
      if (!this.isNewAdditionPresent) {
        if (isIn45DaysWindow || nonComplaint) {
          this.newAdditionMsg =
            'Information Security Awareness course will be added to this learning path';
          this.newAdditionMsg +=
            ' in the year ' +
            (currentDate.getFullYear() + 1) +
            ' for your pursual.';
          //console.log(`newAdditionMsg ${this.newAdditionMsg}`);
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(true);
            }, 0);
          });
        }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(false);
          }, 0);
        });
      } else {
        //If ISMS is already present in LP
        // if (currentDate > this.retakeDate || currentDate <= this.retakeDate || isIn45DaysWindow)
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(false);
          }, 0);
        });
      }
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(false);
      }, 0);
    });
  }

  checkIsBlocked(isNewJoinee) {
    let currentDate = new Date();
    let cDateFormatted = new Date(this.getTime(currentDate, 'dd/MMM/yyyy'));
    //console.log(`cDateFormatted ${cDateFormatted}`);

    let retakeDateFormatted = new Date(
      this.getTime(this.retakeDate, 'dd/MMM/yyyy')
    );
    //console.log(`retakeDateFormatted ${retakeDateFormatted}`);

    if (!isNewJoinee) {
      //Retaker
      if (
        !this.globals.mandatoryLPUnlimitedReminderList.includes(
          this.globals.loggedInUserCompetency
        ) &&
        this.globals.mandatoryReminderCount == 15 &&
        cDateFormatted > retakeDateFormatted
        //this.getTime(currentDate, 'dd/MMM/yyyy') >  this.getTime( this.retakeDate, 'dd/MMM/yyyy')
        //currentDate > this.retakeDate
      ) {
        return true;
      } else if (
        this.globals.mandatoryLPUnlimitedReminderList.includes(
          this.globals.loggedInUserCompetency
        ) &&
        this.globals.mandatoryReminderCount >= 15 &&
        cDateFormatted > retakeDateFormatted
        //this.getTime(currentDate, 'dd/MMM/yyyy') >  this.getTime( this.retakeDate, 'dd/MMM/yyyy')
        //currentDate > this.retakeDate
      ) {
        return true;
      }
      return false;
    } else {
      //New Joinee
      if (
        !this.globals.mandatoryLPUnlimitedReminderList.includes(
          this.globals.loggedInUserCompetency
        ) &&
        this.globals.mandatoryReminderCount == 14
      ) {
        return true;
      } else if (
        this.globals.mandatoryLPUnlimitedReminderList.includes(
          this.globals.loggedInUserCompetency
        ) &&
        this.globals.mandatoryReminderCount >= 14
      ) {
        return true;
      }
      return false;
    }
  }

  checkIsIn45DaysWindow(isNewJoinee) {
    let currentDate = new Date();
    let cDateFormatted = new Date(this.getTime(currentDate, 'dd/MMM/yyyy'));
    //console.log(`cDateFormatted ${cDateFormatted}`);

    let retakeDateFormatted = new Date(
      this.getTime(this.retakeDate, 'dd/MMM/yyyy')
    );
    //console.log(`retakeDateFormatted ${retakeDateFormatted}`);

    if (!isNewJoinee) {
      //Retaker
      if (
        this.globals.mandatoryReminderCount >= 2 &&
        this.globals.mandatoryReminderCount < 15 &&
        cDateFormatted <= retakeDateFormatted
        //this.getTime(currentDate, 'dd/MMM/yyyy') <=  this.getTime( this.retakeDate, 'dd/MMM/yyyy')
        // currentDate <= this.retakeDate
      ) {
        return true;
      }
      return false;
    } else {
      //New Joinee
      if (
        this.globals.mandatoryReminderCount >= 1 &&
        this.globals.mandatoryReminderCount < 14
      ) {
        return true;
      }
      return false;
    }
  }

  checkIsComplaint(isNewJoinee) {
    let currentDate = new Date();
    let cDateFormatted = new Date(this.getTime(currentDate, 'dd/MMM/yyyy'));
    //console.log(`cDateFormatted ${cDateFormatted}`);

    let retakeDateFormatted = new Date(
      this.getTime(this.retakeDate, 'dd/MMM/yyyy')
    );
    //console.log(`retakeDateFormatted ${retakeDateFormatted}`);

    if (!isNewJoinee) {
      //Retaker
      if (
        this.globals.mandatoryReminderCount == 1 &&
        cDateFormatted <= retakeDateFormatted
        //this.getTime(currentDate, 'dd/MMM/yyyy') <=  this.getTime( this.retakeDate, 'dd/MMM/yyyy')
        // currentDate <= this.retakeDate
      ) {
        return true;
      }
      return false;
    } else {
      //New Joinee
      //This scenario will never happen.
      //Because the moment a New Joinee completes all the courses, the mind becomes a retaker.
      //Until then the mind will be in 45 Days period or will he be blocked.
      return false;
    }
  }

  canShowRetakeDate() {
    if (
      this.LPBreadcrumbs.LPDetails.LPName == this.globals.CocLpName.toUpperCase() || this.LPBreadcrumbs.LPDetails.LPName == this.globals.BEACLpName.toUpperCase()
    ) {
      let currentDate = new Date();
      let mandatoryDate = new Date(this.globals.mandatoryRetakeDate);
      let cDateFormatted = new Date(this.getTime(currentDate, 'dd/MMM/yyyy'));
      let mDateFormatted = new Date(this.getTime(mandatoryDate, 'dd/MMM/yyyy'));
      if (
        !this.retakeDateLoading &&
        !this.globals.isNewJoinee &&
        this.globals.mandatoryReminderCount == 1 &&
        cDateFormatted <= mDateFormatted
        //this.getTime(currentDate, 'dd/MMM/yyyy') <=  this.getTime( mandatoryDate, 'dd/MMM/yyyy')
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.LPBreadcrumbs.LPDetails.LPName ==
      'SEXUAL HARASSMENT PREVENTION TRAINING - USA' ||
      this.LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName
    ) {
      let currentDate = new Date();
      let mandatoryDate = new Date(this.globals.mandatoryRetakeDateForPoshUSA);
      let cDateFormatted = new Date(this.getTime(currentDate, 'dd/MMM/yyyy'));
      let mDateFormatted = new Date(this.getTime(mandatoryDate, 'dd/MMM/yyyy'));
      if (
        !this.retakeDateLoading &&
        !this.globals.isNewJoineeForPoshUSA &&
        this.globals.mandatoryReminderCountForPoshUSA == 1 &&
        cDateFormatted <= mDateFormatted
        //this.getTime(currentDate, 'dd/MMM/yyyy') <=  this.getTime( mandatoryDate, 'dd/MMM/yyyy')
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  isDeclareBlocked() {
    let id = this.cardDetails.ItemId.toString();
    if (
      this.globals.declareBlockedCourses.includes(this.cardDetails.ItemId) ||
      this.globals.courseListForShowingLastCompletedDate.includes(
        this.cardDetails.ItemId
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  isMandateCourse() {
    if (this.cardDetails.ItemId != undefined) {
      const id = this.cardDetails.ItemId.toString();
      if (this.globals.courseListForShowingLastCompletedDate.indexOf(this.cardDetails.ItemId) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  isAScormCourse() {
    if (this.cardDetails.ItemId != undefined) {
      const id = this.cardDetails.ItemId.toString();
      if (this.globals.scormResumeIds.indexOf(this.cardDetails.ItemId) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  backToLPDetailsPage(popUpInput) {
    let packageId = 'null';
    if (popUpInput.PackageId) {
      packageId = popUpInput.PackageId;
    }
    this.router.navigate([
      'learningpath/category/' +
      popUpInput.LPCategory +
      '/id/' +
      popUpInput.LPId +
      '/package/' +
      packageId +
      '/course/' +
      popUpInput.ItemId +
      '/account/' +
      popUpInput.IsAccount +
      '/project/' +
      popUpInput.IsProject +
      '/accountId/' +
      popUpInput.AccountId +
      '/projectId/' +
      popUpInput.ProjectId +
      '/tabs/playlist'
    ]);
  }

  getWorkflowStatus(RequestId) {
    const list1 = this.workflowService.canShowWorkflowMsg;
    const list2 = this.workflowService.canNotShowWorkflowMsg;
    if (
      this.cardDetails.ItemExpertise === '201' &&
      (RequestId != null && RequestId != '')
    ) {
      let workFlowSub = this.workflowService.reqIdWorkflowPair.subscribe(
        data => {
          if (data && data.length > 0) {
            let result = data.find(workflowPair => {
              if (workflowPair.requestID == RequestId) {
                return workflowPair;
              }
            });
            if (result != null && result.WorkflowStatus != null) {
              if (result.WorkflowStatus != null) {
                if (
                  list1.includes(
                    result.WorkflowStatus.toUpperCase() ||
                    this.isCourseWorkflowStarted
                  ) &&
                  (!list2.includes(result.WorkflowStatus.toUpperCase()) ||
                    !list2.includes(
                      this.cardDetails.WorflowStatus.toUpperCase()
                    ))
                ) {
                  this.show201CourseTileMsg = true;
                } else if (
                  list1.includes(this.cardDetails.WorflowStatus.toUpperCase())
                ) {
                  this.show201CourseTileMsg = true;
                } else {
                  this.show201CourseTileMsg = false;
                }
              }
            } else {
              if (
                this.isCourseWorkflowStarted &&
                (list1.includes(this.cardDetails.WorflowStatus.toUpperCase()) ||
                  !list2.includes(this.cardDetails.WorflowStatus.toUpperCase()))
              ) {
                this.show201CourseTileMsg = true;
              } else if (
                list1.includes(this.cardDetails.WorflowStatus.toUpperCase())
              ) {
                this.show201CourseTileMsg = true;
              } else {
                this.show201CourseTileMsg = false;
              }
            }
          } else {
            if (
              this.isCourseWorkflowStarted &&
              (list1.includes(this.cardDetails.WorflowStatus.toUpperCase()) ||
                !list2.includes(this.cardDetails.WorflowStatus.toUpperCase()))
            ) {
              this.show201CourseTileMsg = true;
            } else if (
              list1.includes(this.cardDetails.WorflowStatus.toUpperCase())
            ) {
              this.show201CourseTileMsg = true;
            } else {
              this.show201CourseTileMsg = false;
            }
          }
        }
      );
    }
  }

  getCloudLabDetails() {
    let isLabAvailable = true;
    let showCloudLabIcon = false;
    let expertise = this.cardDetails.ItemExpertise;
    let courseId = this.cardDetails.ItemId;
    this.cloulabStore
      .select(fromCloulabStore.getCloudlabLoaded)
      .subscribe(loaded => {
        if (loaded) {
          this.ContentTileCompSubcriptions.cloudLabSubscription = this.cloulabStore
            .select(fromCloulabStore.getCloudlabStatusByCourseId(courseId))
            .subscribe(lab => {
              if (lab && lab.statusMessage.toLowerCase() == 'success') {
                if (lab.labdetails && lab.labdetails.length > 0) {
                  isLabAvailable = true;
                } else {
                  isLabAvailable = false;
                }
                if (this.cardDetails.ItemExpertise.toString() == '101') {
                  if (this.courseStatus.toLowerCase() == 'course completed') {
                    showCloudLabIcon = false;
                    this.isCloudLabAvailable = false;
                  } else {
                    //chk if cloud lab available
                    if (isLabAvailable == true) {
                      showCloudLabIcon = true;
                      this.isCloudLabAvailable = true;
                    } else {
                      showCloudLabIcon = false;
                      this.isCloudLabAvailable = false;
                    }
                  }
                  // return showCloudLabIcon;
                } else if (
                  this.cardDetails.ItemExpertise.toString() == '201' ||
                  this.cardDetails.ItemExpertise.toString() == '301'
                ) {
                  if (
                    this.isWorkflowStarted() &&
                    this.courseStatus.toLowerCase() != 'course completed'
                  ) {
                    if (isLabAvailable == true) {
                      showCloudLabIcon = true;
                      this.isCloudLabAvailable = true;
                    } else {
                      showCloudLabIcon = false;
                      this.isCloudLabAvailable = false;
                    }
                  } else {
                    showCloudLabIcon = false;
                    this.isCloudLabAvailable = false;
                  }
                  //return showCloudLabIcon;
                }
              }
            });
        }
      });
  }

  checkLabInStore(courseId) {
    this.cloudlabStoreService.fetchLabsInBatch([courseId]);
  }


  checkTimeBoundCourseExpiryDate(dateValue) {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    var dueDate = new Date(dateValue);
    var days = Math.floor((currentDate.getTime() - dueDate.getTime()) / 1000 / 60 / 60 / 24);
    if (days < 0) {
      return false
    } else {
      return true
    }
  }

  checkTimeBoundCourseExpiryDate1(dateValue) {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 5);
    var dueDate = new Date(dateValue);
    var days = Math.floor((currentDate.getTime() - dueDate.getTime()) / 1000 / 60 / 60 / 24);
    if (days < 0) {
      return false
    } else {
      return true
    }
  }

  getFormattedDate(dateValue) {
    var date = new Date(dateValue);
    return new DatePipe('en-US').transform(date, 'dd MMMM yyyy')
  }


}
