<mat-card class="container" fxFlexFill fxLayout="column">
  
<img class="header-image" fxFlex="1 1 50%" src="../../../../assets/images/BehavioralAssessment/behavioral_tile_icon.svg"/>
<div class="content" fxFlex="1 1 50%" fxLayout="row wrap" fxLayoutGap=5px>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <label class="title" >Behavioral Assessment</label>
      <img *ngIf="cardData?.status=='PDFgenerated'" class="complted-icon"   src="../../../../assets/images/BehavioralAssessment/icon_completed.svg"/>
  </div>
  <div (click)="downloadReport()" fxFlexFill class="btn mousePointer"  fxLayoutAlign="center center">
    <span *ngIf="cardData?.status=='Not Started'" title='Take Assessment'>Take Assessment</span>
    <span *ngIf="cardData?.status=='Completed'" title='Generating Report'>Generating Report </span>
    <div fxLayoutAlign="center center" *ngIf="cardData?.status=='PDFgenerated'">
      <span style="font-size: 0.8rem;" title='Download the report'>Download the report</span>
      <img style="margin-left: 8px;" cla-ss="download-icon"   src="../../../../assets/images/BehavioralAssessment/icon_download.svg"/>
    </div>
    
  </div>
</div>
</mat-card>