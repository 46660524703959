<div *ngIf="contentLoadedSuccessfully && !showPagePreloader" fxFlex fxLayout="row" class="academy-view-container" [ngClass]="{'popover':viewMode=='popover','accounts-browse':isAccountBrowse}"
  [ngClass.xs]="{'academy-view-container-xs':true}" (click)="viewMode=='popover'? $event.stopPropagation():''">
  <div fxFlex="200px" fxFlex.xs="100%" fxLayout="column" fxLayoutAlign="start stretch" class="academies-list-container">
    <div fxFlex="45px" [fxShow.gt-xs]="true" fxShow.xs="false" fxLayout="row" class="header" fxLayoutAlign="center center">
      <div fxFlex="150px" *ngIf="!isAccountBrowse">Academy / Genre</div>
      <div fxFlex="150px" *ngIf="isAccountBrowse">Accounts / Projects</div>
    </div>
    <div class="academies-list overflow-theme-dark" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
      <div *ngFor="let academy of academyList">
        <div fxLayout="column" fxLayoutAlign="start stretch" *ngIf="!(roleList.CUSTOMER&&'global'===academy.Name.toLowerCase())">
          <div class="academy mousePointer {{academy.Name}}" [ngClass.gt-xs]="{'academySelected':academy.UniqueId==selectedAcademy.UniqueId}"
            [ngClass.xs]="{'academy-xs':true}" title="{{academy.Name}}" fxFlex="40px" fxLayout="row" fxLayoutAlign="start center"
            (click)="onAcademySelection(academy);$event.stopPropagation()">
            <div fxFlexOffset="15px" fxFlex class="title" fxLayoutAlign="start start">{{academy.Name}}</div>
            <mat-icon fxFlex="30px" fxShow.xs="false" [fxShow.gt-xs]="academy.UniqueId==selectedAcademy.UniqueId">keyboard_arrow_right</mat-icon>
            <mat-icon fxFlex="30px" fxShow.gt-xs="false" [fxShow.xs]="academy.UniqueId!=selectedAcademy.UniqueId || (academy.UniqueId==selectedAcademy.UniqueId && !selectedAcademy.showGenres)">keyboard_arrow_down</mat-icon>
            <mat-icon fxFlex="30px" fxShow.gt-xs="false" [fxShow.xs]="academy.UniqueId==selectedAcademy.UniqueId && selectedAcademy.showGenres">keyboard_arrow_up</mat-icon>
          </div>
          <div class="genres-list-container-xs" [fxShow.xs]="academy.UniqueId==selectedAcademy.UniqueId && selectedAcademy.showGenres"
            fxShow.gt-xs="false" fxLayout="column" fxLayoutAlign="start stretch">
            <div class="genreTitle genre" *ngFor="let genre of academy.GenreTerms" fxFlex="30px" fxLayoutAlign="start center" (click)="onGenreSelection(genre)">
              <div>{{genre.Name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex fxShow.xs="false" fxShow.gt-xs="true" fxLayout="column" class="genres-list-container">
    <div class="genres-list" fxLayout="column wrap" fxLayoutAlign="start start">
      <div fxLayout="column" class="genre" (mouseover)="setGenreHoverIndex(index)" (mouseout)="setGenreHoverIndex(-1)" [ngClass]="{'olderSiblingBorder': index===selectedGenreIndex}"
        *ngFor="let genre of selectedAcademy.GenreTerms;let index = index" fxLayoutAlign="start stretch">
        <div class="genreTitle mousePointer textTruncate" title="{{genre.name}}" (click)="onGenreSelection(genre);exploreMenuTrigger">
          {{genre.Name}}
        </div>
        <div class="skill-list" fxFlexOffset="20px" [fxShow.gt-xs]="true" [fxShow.xs]="false">
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <div class="skillTitle mousePointer" fxlayoutAlign="start center" (click)="onSkillSelection(genre,skill)" fxFlex="22px" *ngFor="let skill of genre.SkillTerms | slice:0:genre.SkillTerms.maxItems">
              {{skill.Name}}
            </div>
          </div>
        </div>
        <div fxFlex="14px" class="showMoreLessContainer" fxLayoutAlign="start center" *ngIf="genre.SkillTerms.length>10 && genre.SkillTerms.maxItems==10 && viewMode!='popover'">
          <div class="show-more mousePointer" (click)="genre.SkillTerms.maxItems=genre.SkillTerms.length">Show more</div>
        </div>
        <div fxFlex="14px" class="showMoreLessContainer" fxLayoutAlign="end center" *ngIf="genre.SkillTerms.maxItems>10 && viewMode!='popover'">
          <div class="show-less mousePointer" (click)="genre.SkillTerms.maxItems=10">Show less</div>
        </div>
        <mat-divider fxFlex="1px" *ngIf="viewMode!='popover'"></mat-divider>
      </div>
    </div>
  </div>
  <div fxFlex="280px" *ngIf="viewMode=='popover' && !roleList.CUSTOMER" fxLayout="column" class="new-courses-container" fxLayoutAlign="start stretch">
    <div class="new-courses-list" fxFlex fxLayout="column" [ngClass]="{'noBackground': roleList.CUSTOMER}">
      <div class="header" fxFlex="50px" *ngIf="newCoursesLoadedSuccessfully">
        New Courses
      </div>
      <div class="courses" fxLayout="column" *ngIf="newCoursesLoadedSuccessfully">
        <!-- <div class="course" *ngFor="let course of newCoursesList" (click)="gotToCourseDetailPage(course)">
          {{course.Name}}
        </div> -->
        <div class="content-tile-holder" input type="text" *ngFor="let item of newCoursesList|slice:0:5" fxFlex="140px" fxLayout="column" fxLayoutAlign="center center">
          <div fxFlex="130px" fxLayoutAlign="center stretch">
            <div fxFlex="225px" class="course mousePointer bg{{item.Expertise}}" (click)="goToDetailsPage(item)">
              <div fxFlex="110px" fxLayout="column" fxLayoutAlign="start start">
                <div class="expertise" fxFlex>{{ item.Expertise }}</div>
                <div class="name" fxFlex="52px" title="{{item.Name}}">
                  {{ item.Name }}
                </div>
              </div>
              <div class="representation" fxFlex="85px">{{'C'}}</div>
            </div>
            <!-- <yorbit-content-tile-browse [cardDetails]='item' fxLayoutAlign="start start" (click)="navigateTo(item)" fxFlexFill></yorbit-content-tile-browse> -->
          </div>
        </div>
      </div>
      <div *ngIf="showPreloaderInNewCoursesList && !roleList.CUSTOMER">
        <app-preloader></app-preloader>
      </div>
      <div *ngIf="!showPreloaderInNewCoursesList && !newCoursesLoadedSuccessfully">
        Technical issue in loading content. Try later.
      </div>
    </div>
    <button fxFlexOffset="15px" fxFlex="30px" class="mousePointer" (click)="goToBrowseView()">Go to Detailed View</button>
  </div>
</div>
<div *ngIf="showPagePreloader" fxFlexFill>
  <app-preloader></app-preloader>
</div>
<div *ngIf="!contentLoadedSuccessfully  && !showPagePreloader" fxFlexFill>
  Technical issue in loading content. Try later.
</div>