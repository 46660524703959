import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { C2opsRoutingModule } from '../../../c2ops/c2ops-routing/c2ops-routing.module';
import { ContentUploadStatusPopupComponent } from '../../content-upload-status-popup/content-upload-status-popup.component';
import { Conentupload101Service } from '../conentupload-101.service';
import { ContentUpload101PopupsComponent } from '../content-upload101-popups/content-upload101-popups.component';
import { ValidateMinsSeconds, ValidateNumber } from '../contentupload-101-validators';

@Component({
  selector: 'yorbit-units-and-topics',
  templateUrl: './units-and-topics.component.html',
  styleUrls: ['./units-and-topics.component.scss']
})
export class UnitsAndTopicsComponent implements OnInit {

  @Input() course;
  @Output() enableQuizUploadComponent = new EventEmitter<any>();
  @Output() enableContentVerificationComponent = new EventEmitter<any>();
  @Output() enable101ContentUploadComponent = new EventEmitter<any>();
  public unitsTopicForm: FormGroup;
  unitsFormData: any[] = [];
  unitFormDummy: any;
  submitted: boolean;
  isFormValid: boolean;
  durationValid: boolean;
  allSourceLinksValid: boolean;
  adminPageUrl: string = "";
  azureLinks: string[] = [];
  youtubeLinks: string[] = [];
  validateButton: string = 'VALIDATE LINKS';
  saveButton: string = 'SAVE';
  saved: boolean;
  isSubmittedOrApproved: boolean = false;
  isYorbitCourse: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private _contentUpload101Service: Conentupload101Service,
    public confirmDialogRef: MatDialogRef<ContentUpload101PopupsComponent>,
    public dialog: MatDialog,
    private _envSvc: EnvironmentService) {
    this.adminPageUrl = this._envSvc.getEnvironment().appUrl;
  }

  ngOnInit() {
    this.isYorbitCourse = this.course.IsYorbitCourse;
    this._contentUpload101Service.getUnitsAndContentsFor101(this.course.UniqueId).subscribe(data => {
      if (data != null) {
        this.unitsFormData = data;
        for (let i = 0; i < data.length; i++) {
          if (i > 0)
            this.addNewUnit();
          const units = this.unitsTopicForm.get('unitsAndTopics')['controls'][i];
          units.controls['unitName'].setValue(data[i].UnitName);
          for (let j = 0; j < data[i].Topics.length; j++) {
            if (j > 0)
              this.addTopicsToUnits(i);
            const topics = this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls['topics'].controls[j];
            topics.controls['videoType'].setValue(data[i].Topics[j].VideoType);
            topics.controls['topicName'].setValue(data[i].Topics[j].TopicName);
            topics.controls['durationHour'].setValue(data[i].Topics[j].DurationHour);
            topics.controls['durationMins'].setValue(data[i].Topics[j].DurationMins);
            topics.controls['durationSeconds'].setValue(data[i].Topics[j].DurationSeconds);
            topics.controls['sourceLink'].setValue(data[i].Topics[j].SourceLink);
            if (topics.controls['videoType'].value === 'azure' || !this.isYorbitCourse) {
              topics.controls['durationHour'].enable();
              topics.controls['durationMins'].enable();
              topics.controls['durationSeconds'].enable();
            }
            else if (topics.controls['videoType'].value === 'youtube' && this.isYorbitCourse) {
              topics.controls['durationHour'].disable();
              topics.controls['durationMins'].disable();
              topics.controls['durationSeconds'].disable();
            }
          }
          //this.unitsTopicForm.controls[i].setValue()
        }
        if (this.course.IsSubmittedOrApprovedCourse) {
          this.unitsTopicForm.disable();
          this.isSubmittedOrApproved = true;
        }
      }
    });
    this.submitted = false;
    this.isFormValid = true;
    this.durationValid = true;
    this.allSourceLinksValid = false;
    this.saved = false;
    this.unitsTopicForm = this.formBuilder.group({
      unitsAndTopics: this.formBuilder.array([this.addUnit()])
    });
    const data = this.unitsTopicForm.getRawValue();
    this.unitFormDummy = JSON.parse(JSON.stringify(data));
  }

  private addTopics(): FormGroup {
    this.submitted = false;
    return this.formBuilder.group({
      videoType: ["youtube", [Validators.required]],
      topicName: ['', [Validators.required]],
      sourceLink: ['', [Validators.required]],
      durationHour: [{ value: '', disabled: this.isYorbitCourse ? true : false }, [Validators.required, ValidateNumber]],
      durationMins: [{ value: '', disabled: this.isYorbitCourse ? true : false }, [Validators.required, ValidateMinsSeconds]],
      durationSeconds: [{ value: '', disabled: this.isYorbitCourse ? true : false }, [Validators.required, ValidateMinsSeconds]],
      isSourceLinkValid: [true],
      onHover: [false]
    })
  }

  get getNewUnits(): FormArray {
    return <FormArray>this.unitsTopicForm.get('unitsAndTopics');
  }

  private addUnit(): FormGroup {
    this.submitted = false;
    return this.formBuilder.group({
      unitName: ['', [Validators.required]],
      topics: this.formBuilder.array([this.addTopics()]),
      isValid: true,
      isLinksValid: true,
      durationValid: true
    });
  }

  videoTypeChange(videoType, unit, topic) {
    const control = this.unitsTopicForm.get('unitsAndTopics')['controls'][unit].controls['topics'].controls[topic];
    if (videoType == 'azure') {
      control.controls['durationHour'].enable();
      control.controls['durationMins'].enable();
      control.controls['durationSeconds'].enable();
    }
    else if (videoType == 'youtube') {
      control.controls['durationHour'].disable();
      control.controls['durationMins'].disable();
      control.controls['durationSeconds'].disable();
    }

  }

  onTextChange() {
    this.saveButton = 'SAVE';
  }

  sourceLinkChange(i, j) {
    this.unitFormDummy.unitsAndTopics[i].topics[j].isSourceLinkValid = true;
    this.allSourceLinksValid = false;
    this.validateButton = 'VALIDATE LINKS';
    this.saveButton = 'SAVE';
  }

  removeTopics(unit, topic) {
    const control = (<FormArray>(<FormGroup>this.getNewUnits.controls[unit]).controls.topics);
    control.removeAt(topic);
    this.unitFormDummy = [];
    const data = this.unitsTopicForm.getRawValue();
    this.unitFormDummy = JSON.parse(JSON.stringify(data));
  }

  removeUnits(unit) {
    const control = <FormArray>this.getNewUnits;
    //const control = (<FormGroup>this.getNewUnits.controls.units);
    control.removeAt(unit);
    this.unitFormDummy = [];
    const data = this.unitsTopicForm.getRawValue();
    this.unitFormDummy = JSON.parse(JSON.stringify(data));
  }

  validateSourceLink() {
    this.validateButton = "VALIDATING..";
    this.azureLinks = [];
    this.youtubeLinks = [];
    this.allSourceLinksValid = true;
    for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
      for (let j = 0; j < this.unitsTopicForm.value.unitsAndTopics[i].topics.length; j++) {
        if (this.unitsTopicForm.value.unitsAndTopics[i].topics[j].videoType == "youtube") {
          this.youtubeLinks.push(this.unitsTopicForm.value.unitsAndTopics[i].topics[j].sourceLink.trim());
        }
        else if (this.unitsTopicForm.value.unitsAndTopics[i].topics[j].videoType == "azure") {
          this.azureLinks.push(this.unitsTopicForm.value.unitsAndTopics[i].topics[j].sourceLink.trim());
        }
      }
    }
    this._contentUpload101Service.validateYoutubeLinks(this.youtubeLinks).subscribe(data => {
      let k = 0
      for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
        for (let j = 0; j < this.unitsTopicForm.value.unitsAndTopics[i].topics.length; j++) {
          if (this.unitsTopicForm.value.unitsAndTopics[i].topics[j].videoType == "youtube") {
            if (this.unitsTopicForm.value.unitsAndTopics[i].topics[j].sourceLink.trim() == data[k].YoutubeLink) {
              if (data[k].Duration > 0) {
                this.unitFormDummy.unitsAndTopics[i].topics[j].isSourceLinkValid = true;
                var duration = new Date(data[k].Duration * 1000);
                const control = this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls['topics'].controls[j];

                control.controls['durationHour'].setValue(duration.getUTCHours().toString().padStart(2, '0'));
                control.controls['durationMins'].setValue(duration.getUTCMinutes().toString().padStart(2, '0'));
                control.controls['durationSeconds'].setValue(duration.getSeconds().toString().padStart(2, '0'));
                this.unitsTopicForm.value.unitsAndTopics[i].topics[j].durationHour = duration.getUTCHours().toString().padStart(2, '0');
                this.unitsTopicForm.value.unitsAndTopics[i].topics[j].durationMins = duration.getUTCMinutes().toString().padStart(2, '0');
                this.unitsTopicForm.value.unitsAndTopics[i].topics[j].durationSeconds = duration.getSeconds().toString().padStart(2, '0');
              }
              else
                this.unitFormDummy.unitsAndTopics[i].topics[j].isSourceLinkValid = false;
              k++;
            }
          }
        }
      }
      this._contentUpload101Service.validateAzureLinks(this.azureLinks).subscribe(data => {
        let k = 0
        for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
          for (let j = 0; j < this.unitsTopicForm.value.unitsAndTopics[i].topics.length; j++) {
            if (this.unitsTopicForm.value.unitsAndTopics[i].topics[j].videoType == "azure") {
              if (this.unitsTopicForm.value.unitsAndTopics[i].topics[j].sourceLink.trim() == data[k].AzureLink) {
                this.unitFormDummy.unitsAndTopics[i].topics[j].isSourceLinkValid = data[k].IsValid;
                k++;
              }
            }
          }
        }
        for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
          for (let j = 0; j < this.unitsTopicForm.value.unitsAndTopics[i].topics.length; j++) {
            if (!this.unitFormDummy.unitsAndTopics[i].topics[j].isSourceLinkValid) {
              this.unitsTopicForm.value.unitsAndTopics[i].isLinksValid = false;
              this.allSourceLinksValid = false;
              break;
            }
          }
        }
        if (!this.allSourceLinksValid) {
          this.validateButton = 'VALIDATE LINKS';
          this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
            disableClose: true,
            panelClass: "expertDialogContainer",
            data: 'SourceLinkInvalid'
          });
        }
        else {
          this.validateButton = 'VALIDATED';
        }
      });
    });
  }

  addNewUnit() {
    this.allSourceLinksValid = false;
    this.validateButton = 'VALIDATE LINKS';
    this.saveButton = 'SAVE';
    this.getNewUnits.push(this.addUnit());
    this.unitFormDummy.unitsAndTopics.push({
      unitName: '',
      topics: [{
        videoType: "youtube",
        topicName: '',
        sourceLink: '',
        durationHour: '',
        durationMins: '',
        durationSeconds: '',
        isSourceLinkValid: true,
        onHover: false
      }],
      isValid: true,
      isLinksValid: true,
      durationValid: true
    });
  }

  addTopicsToUnits(index) {
    this.allSourceLinksValid = false;
    this.validateButton = 'VALIDATE LINKS';
    this.saveButton = 'SAVE';
    (<FormArray>(<FormGroup>this.getNewUnits.controls[index]).controls.topics).push(this.addTopics());
    this.unitFormDummy.unitsAndTopics[index].topics.push({
      videoType: "youtube",
      topicName: '',
      sourceLink: '',
      durationHour: '',
      durationMins: '',
      durationSeconds: '',
      isSourceLinkValid: true,
      onHover: false
    });
  }

  moveUp(unit) {
    moveItemInArray(this.unitsTopicForm.get('unitsAndTopics')['controls'], unit, unit - 1);
  }

  moveDown(unit) {
    moveItemInArray(this.unitsTopicForm.get('unitsAndTopics')['controls'], unit, unit + 1);
  }

  dropOption(event: CdkDragDrop<any>, unit) {
    const controls = this.unitsTopicForm.get('unitsAndTopics')['controls'][unit];
    moveItemInArray(controls.get('topics')['controls'], event.previousIndex, event.currentIndex);
  }

  uploadAzureVideos() {
    window.open(this.adminPageUrl + 'admin/videoupload', '_blank');
  }

  mouseEnter(unit, topic) {
    this.unitFormDummy.unitsAndTopics[unit].topics[topic].onHover = true;
  }

  mouseLeave(unit, topic) {
    this.unitFormDummy.unitsAndTopics[unit].topics[topic].onHover = false;
  }

  openInformation() {
    this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
      disableClose: true,
      panelClass: "expertDialogContainer",
      data: 'UnitsAndTopicsInformation'
    });
  }

  onSubmit() {
    this.submitted = true;
    this.isFormValid = true;
    this.durationValid = true;
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const data = this.unitsTopicForm.getRawValue();
    if (!this.isSubmittedOrApproved) {
      //this.unitsFormData = JSON.parse(JSON.stringify(data));


      for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
        for (let j = 0; j < this.unitsTopicForm.value.unitsAndTopics[i].topics.length; j++) {
          if (this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls.topics.controls[j].value.videoType == "azure") {
            if (this.unitsTopicForm.value.unitsAndTopics[i].topics[j].topicName.trim() === ""
              || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].sourceLink.trim() === ""
              || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].durationHour.toString().trim() === ""
              || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].durationMins.toString().trim() === ""
              || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].durationSeconds.toString().trim() === "")
              this.unitsTopicForm.value.unitsAndTopics[i].isValid = false;
            if (!this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls.topics.controls[j].controls.durationHour.valid
              || !this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls.topics.controls[j].controls.durationMins.valid
              || !this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls.topics.controls[j].controls.durationSeconds.valid) {
              this.unitsTopicForm.value.unitsAndTopics[i].durationValid = false;
            }
          }
          else {
            if (this.unitsTopicForm.value.unitsAndTopics[i].topics[j].topicName.trim() === ""
              || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].sourceLink.trim() === "")
              this.unitsTopicForm.value.unitsAndTopics[i].isValid = false;
          }
        }
      }

      for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
        if (!this.unitsTopicForm.value.unitsAndTopics[i].isValid)
          this.isFormValid = false;
        if (!this.unitsTopicForm.value.unitsAndTopics[i].durationValid)
          this.durationValid = false;
      }


      if (this.isFormValid && this.durationValid) {
        if (!this.allSourceLinksValid && this.isYorbitCourse) {
         // //console.log('hit', this.isYorbitCourse, this.course);
          this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
            disableClose: true,
            panelClass: "expertDialogContainer",
            data: 'SourceLinkInvalid'
          });
        } else {
          for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
            this.unitsFormData[i] = {
              UnitName: this.unitsTopicForm.value.unitsAndTopics[i].unitName,
              Topics: this.unitsTopicForm.value.unitsAndTopics[i].topics
            }
          }
          this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
            disableClose: true,
            panelClass: "expertDialogContainer",
            data: 'Confirmation'
          });
          this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              if (result == 'Yes') {
                this._contentUpload101Service.updateUnitsAndTopics(this.unitsFormData, { headers }, this.course.UniqueId)
                  .subscribe(response => {
                    this.confirmDialogRef = null;
                    if (response) {
                      // this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
                      //   disableClose: true,
                      //   panelClass: "expertDialogContainer",
                      //   data: 'UnitsMessage'
                      // });
                      //this.confirmDialogRef.afterClosed().subscribe(() => {});
                      var course = {
                        'UniqueId': this.course.UniqueId,
                        'Name': this.course.Name,
                        'FromHomePage': false,
                        'IsCourseInternal':this.course.IsCourseInternal,
                        'expertise':'101'
                      }
                      this.enableQuizUploadComponent.emit(course);
                    }
                  });
              }
            }
            this.confirmDialogRef = null;
          });

        }
      }
    }
    else {
      var course = {
        'UniqueId': this.course.UniqueId,
        'Name': this.course.Name,
        'IsSubmittedOrApprovedCourse': true,
        'FromHomePage': false,
        'IsCourseInternal':this.course.IsCourseInternal,
        'expertise':'101'
      }
      this.enableQuizUploadComponent.emit(course);
    }
  }
  onSave() {
    this.saveButton = 'SAVING..';
    this.isFormValid = true;
    this.durationValid = true;
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const data = this.unitsTopicForm.getRawValue();
    //this.unitsFormData = JSON.parse(JSON.stringify(data));


    for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
      for (let j = 0; j < this.unitsTopicForm.value.unitsAndTopics[i].topics.length; j++) {
        if (this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls.topics.controls[j].value.videoType == "azure"
          || !this.isYorbitCourse) {
          if (this.unitsTopicForm.value.unitsAndTopics[i].unitName.trim() === ""
            || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].topicName.trim() === ""
            || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].sourceLink.trim() === ""
            || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].durationHour.toString().trim() === ""
            || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].durationMins.toString().trim() === ""
            || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].durationSeconds.toString().trim() === "")
            this.unitsTopicForm.value.unitsAndTopics[i].isValid = false;
          if (!this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls.topics.controls[j].controls.durationHour.valid
            || !this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls.topics.controls[j].controls.durationMins.valid
            || !this.unitsTopicForm.get('unitsAndTopics')['controls'][i].controls.topics.controls[j].controls.durationSeconds.valid) {
            this.unitsTopicForm.value.unitsAndTopics[i].durationValid = false;
          }
        }
        else {
          if (this.unitsTopicForm.value.unitsAndTopics[i].topics[j].topicName.trim() === ""
            || this.unitsTopicForm.value.unitsAndTopics[i].topics[j].sourceLink.trim() === "")
            this.unitsTopicForm.value.unitsAndTopics[i].isValid = false;
        }
      }
    }


    for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
      if (!this.unitsTopicForm.value.unitsAndTopics[i].isValid)
        this.isFormValid = false;
      if (this.isYorbitCourse) {
        if (!this.unitsTopicForm.value.unitsAndTopics[i].durationValid)
          this.durationValid = false;
      }
    }


    if (this.isFormValid && this.durationValid) {
      if (!this.allSourceLinksValid && this.isYorbitCourse) {
        this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
          disableClose: true,
          panelClass: "expertDialogContainer",
          data: 'SourceLinkClick'
        });
        this.saveButton = "SAVE";
      } else {
        for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
          this.unitsFormData[i] = {
            UnitName: this.unitsTopicForm.value.unitsAndTopics[i].unitName,
            Topics: this.unitsTopicForm.value.unitsAndTopics[i].topics
          }
        }
        if (!this.isYorbitCourse) {
          for (let i = 0; i < this.unitsTopicForm.value.unitsAndTopics.length; i++) {
            for (let j = 0; j < this.unitsTopicForm.value.unitsAndTopics[i].topics.length; j++) {
              this.unitsFormData[i].Topics[j].videoType = "NA"
            }
          }
        }
        this._contentUpload101Service.updateUnitsAndTopics(this.unitsFormData, { headers }, this.course.UniqueId)
          .subscribe(response => {
            this.confirmDialogRef = null;
            if (response) {
              this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
                disableClose: true,
                panelClass: "expertDialogContainer",
                data: 'UnitsMessage'
              });
              this.submitted = true;
              this.saveButton = "SAVED";
            }
          });
      }
    }
    else {
      this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
        disableClose: true,
        panelClass: "expertDialogContainer",
        data: 'UnitsDataInvalid'
      });
      this.saveButton = "SAVE";
    }
  }
  onExit() {
    var errorMsg =
      'Changes you have made (if any) and unsaved will be lost.<br/>Are you sure you want to Exit Form?';

    let dataPayload = {
      msg: errorMsg,
      title: 'Exit Form'
    };
    let type = 'exit';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: dataPayload,
      width: '250px',
      height: '250px',
      type: type
    };
    const response = this.dialog.open(
      ContentUploadStatusPopupComponent,
      dialogConfig
    );
    response.afterClosed().subscribe(res => {
      if (!res) {
        this.enableContentVerificationComponent.emit('101');
      }
      else {
        return;
      }
    });
  }
  
  onBackPressed(){
    this.enable101ContentUploadComponent.emit(this.course.UniqueId);
  }
  saveDisabled(){
    
  }
}

