import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromBrowseStore from '../store';
import { BrowseService } from '../browse.service';
import { Subscriber, Observable, fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { ArrayPropertyFilterPipe, ItemTypePipe } from '@YorbitWorkspace/pipes';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import {
  SkillPipe,
  CourseTypePipe,
  ExpertisePipe,
  FilterContentTileByItsPropertiesPipe,
  VendorPipe,
  FilterBrowseContentPipe
} from '@YorbitWorkspace/pipes';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';

@Component({
  selector: 'yorbit-genre-view',
  templateUrl: './genre-view.component.html',
  styleUrls: ['./genre-view.component.scss']
})
export class GenreViewComponent implements OnInit, OnDestroy {
  //@ViewChild('container') containerRef: ElementRef;
  //filters
  filterBrowseContent: any;
  packagesStartIndex: number = 0;
  packagesEndIndex: number = 0;
  coursesStartIndex: number = 0;
  coursesEndIndex: number = 0;
  overflowHeight: number;
  noOfElementsInRow: number;
  noOfRows: number;
  numberOfRowsForPackages: number = 0;
  currentRowNumber: number = 0;
  scrollTop: number = 0;
  //filter models
  searchContentTilesModel: any;
  selectedExpertiseToFilter: any;
  selectedCreditsToFilter: any;
  selectedCourseTypeToFilter: any;
  selectedSkillsToFilter: Array<String>;
  genreViewCompSubscriptions: any;
  routeParams: any;
  academyList: any;
  selectedAcademy: any;
  selectedGenre: any;
  showCourses: boolean;
  showSkills: boolean;
  packageList: Array<any>;
  loadedUniqueVendorAndType: boolean;
  filteredCourseList: Array<any>;
  filteredPackageList: Array<any>;
  filteredPackagesList: Array<any>;
  visibleCoursesList: Array<any>;
  visiblePackagesList: Array<any>;
  filteredPackagesAndCoursesList: Array<any>;
  courseTypes: Array<string>;
  expertises: Array<string>;
  credits: Array<string>;
  vendors: Array<string>;
  filterByProperty = new ArrayPropertyFilterPipe();
  mediaBreakPoint: string;
  showExpertiseFiltersMenu: boolean;
  filterContentTiles: any;
  accountsList: any;
  accountsLoaded: boolean;
  isAccountBrowse: boolean;
  roleList: fromRoleAccessStore.IroleData;
  showPagePreloader: boolean;
  pageLoadedSuccessfully: boolean;
  showCoursesPreloader: boolean;
  coursesLoadedSuccessfully: boolean;
  showSearchIcon: boolean;
  vendorFilter: VendorPipe;
  selectedVendorToFilter: any[];
  courseTypeFilter: CourseTypePipe;
  expertiseFilter: ExpertisePipe;
  skillFilter: SkillPipe;
  filterByItsProperties: FilterContentTileByItsPropertiesPipe;
  filterModel: { Skill: String[]; Expertise: any; Type: any; Credits: any; SearchBy: any };
  previousAcademyId:any=null;
  previousGenreId:any=null;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private browseStore: Store<fromBrowseStore.IbrowseAcademyReducerState>,
    private browseService: BrowseService,
    private mediaObserver: MediaObserver,
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    //filters
    this.showPagePreloader = true;
    this.pageLoadedSuccessfully = false;
    this.showCoursesPreloader = false;
    this.coursesLoadedSuccessfully = false;
    this.courseTypeFilter = new CourseTypePipe();
    this.vendorFilter = new VendorPipe();
    this.expertiseFilter = new ExpertisePipe();
    this.skillFilter = new SkillPipe();
    this.filterByItsProperties = new FilterContentTileByItsPropertiesPipe();
    //filter models
    this.searchContentTilesModel = '';
    this.selectedExpertiseToFilter = ['101', '201', '301'];
    this.selectedCourseTypeToFilter = [];
    this.selectedCreditsToFilter = [];
    this.selectedVendorToFilter = [];
    this.selectedSkillsToFilter = [];

    this.genreViewCompSubscriptions = {};
    this.courseTypes = [];
    this.credits = [];
    this.expertises = ['101', '201', '301'];
    this.vendors = [];
    this.showCourses = false;
    this.showSkills = true;
    this.selectedAcademy = {};
    this.selectedGenre = {};
    this.selectedAcademy.GenreTerms = [];
    this.mediaBreakPoint = '';
    this.showExpertiseFiltersMenu = false;
    this.subscribeToShowSkillsStatusFlag();
    this.subscribeToGetSelectedSkillsInGenre();
    this.browseService.updateShowSkillsFlagStatus(true);
    this.browseService.updateShowCoursesFlagStatus(false);
    this.accountsList = [];
    this.filteredCourseList = [];
    this.filteredPackageList = [];
    this.visibleCoursesList = [];
    this.visiblePackagesList = [];
    this.filteredPackagesAndCoursesList = [];
    this.loadedUniqueVendorAndType = false;
    this.packageList = [];
    this.filteredPackagesList = [];
    this.accountsLoaded = false;
    this.isAccountBrowse = false;
    this.showSearchIcon = true;
    this.filterModel = {
      Skill: this.selectedSkillsToFilter,
      Expertise: this.selectedExpertiseToFilter,
      Type: this.selectedCourseTypeToFilter,
      Credits: this.selectedCreditsToFilter,
      SearchBy: this.searchContentTilesModel
    };
  }
  validateRoute() {
    if (this.router.url.indexOf('accountsAndProjects') == -1) {
      this.subscribeToBrowseStore();
      this.subscribeMediaChanges();
    } else {
      this.isAccountBrowse = true;
      this.loadAccounts();
    }
  }
  loadAccounts() {
    this.browseService.getAccountsList().subscribe(accountList => {
      if (!this.accountsLoaded && accountList.length != 0) {
        this.academyList = accountList;
        if (this.academyList.length == 0) {
          this.showPagePreloader = false;
          this.pageLoadedSuccessfully = false;
          this.showCoursesPreloader = false;
          this.coursesLoadedSuccessfully = false;
        } else {
          this.showPagePreloader = false;
          this.pageLoadedSuccessfully = true;
          this.showCoursesPreloader = false;
          this.coursesLoadedSuccessfully = false;
          this.subscribeToRouterParams();
        }
        this.accountsLoaded = true;
      }
    });
  }
  subscribeToShowSkillsStatusFlag() {
    this.browseService.showCoursesInGenreView$.subscribe(flag => {
      this.showCourses = flag;
    });
  }
  subscribeToGetSelectedSkillsInGenre() {
    this.browseService.getSelectedSkill().subscribe((skills: any) => {
      if (skills.length != 0) {
        this.selectedSkillsToFilter = skills;
        if (this.selectedGenre.UniqueId != undefined) {
          this.selectedGenre.SkillTerms.forEach(skill => {
            if (this.selectedSkillsToFilter.indexOf(skill.Name) == -1) {
              skill.isSelected = false;
            } else {
              skill.isSelected = true;
            }
          });
        }
      }
    });
  }
  subscribeToRouterParams() {
    this.genreViewCompSubscriptions.routerParamsSub = this.activatedRoute.params.subscribe(
      params => {
        this.routeParams = params;
        let academyId: any = '';
        let genreId: any = '';
        if (this.router.url.indexOf('accountsAndProjects') != -1) {
          academyId = params['accountId'];
          if (params['projectId'] != null && params['projectId'] != 'null') {
            genreId = params['projectId'];
          } else {
            genreId = params['accountId'];
          }
        } else {
          academyId = parseInt(params['academyId']);
          genreId = parseInt(params['genreId']);
        }

        if (
          (academyId != undefined || academyId != null) &&
          (genreId != null || genreId != undefined)
        ) {
          //filter genre with academyId and genreId
          this.selectedAcademy = this.filterByProperty.transform(
            this.academyList,
            {
              property: 'UniqueId',
              flag: academyId
            }
          )[0];
          /**
           *
           this.selectedGenre = this.filterByProperty.transform(
            this.selectedAcademy.GenreTerms,
            {
              property: 'UniqueId',
              flag: genreId
            }
          )[0];
          */
         this.selectedGenre = JSON.parse(JSON.stringify(
           this.filterByProperty.transform(
             this.selectedAcademy.GenreTerms,
             {
               property: 'UniqueId',
               flag: genreId
              }
            )[0]
            ));
            this.selectedGenre.showSkills = true;
          this.selectedGenre.SkillTerms.forEach((skill, index) => {
            if (skill.Name == '' || skill.Name == null) {
              this.selectedGenre.SkillTerms.splice(index, 1);
            }
            if (this.selectedSkillsToFilter.indexOf(skill.Name) == -1) {
              skill.isSelected = false;
            } else {
              skill.isSelected = true;
            }
          });
          let genreDetails = {
            GenreName: this.selectedGenre.Name,
            GenreId: this.selectedGenre.UniqueId
          };
          this.browseService.updateSelectedGenre(genreDetails);
          this.getCoursesFromGenre();
        }
      }
    );
  }

  subscribeToBrowseStore() {
    this.genreViewCompSubscriptions.browseStoreSub = this.browseStore
      .select(fromBrowseStore.getBrowseAcadmeyList)
      .subscribe(academyList => {
        //console.log(academyList)
        //if loaded
        if (academyList.AcademyTerms) {
          this.academyList = academyList.AcademyTerms;
          if (this.academyList.length == 0) {
            this.showPagePreloader = false;
            this.pageLoadedSuccessfully = false;
            this.showCoursesPreloader = false;
            this.coursesLoadedSuccessfully = false;
          } else {
            this.showPagePreloader = false;
            this.pageLoadedSuccessfully = true;
            this.showCoursesPreloader = false;
            this.coursesLoadedSuccessfully = false;
            this.subscribeToRouterParams();
          }
        }
      });
  }
  getCoursesFromGenre() {
    this.showPagePreloader = false;
    this.pageLoadedSuccessfully = true;
    this.showCoursesPreloader = true;
    this.coursesLoadedSuccessfully = false;
    if (this.router.url.indexOf('accountsAndProjects') == -1) {
      this.loadYorbitCourses();
    } else {
      this.loadAccountCourses();
    }
  }
  loadAccountCourses() {
    if (this.selectedGenre.IsAccount) {
      let payload = { ItemId: this.selectedGenre.AccountId };
      this.browseService.getAccountCourses(payload).then((response: any) => {
        this.packageList = response.packages;
        this.showPagePreloader = false;
        this.pageLoadedSuccessfully = true;
        this.showCoursesPreloader = false;
        this.coursesLoadedSuccessfully = true;
        this.changeDetectorRef.detectChanges();
        this.filterPackagesList('noFilter');
      });
    } else if (this.selectedGenre.IsProject) {
      let payload = { ItemId: this.selectedGenre.ProjectId };
      this.browseService.getProjectContent(payload).then((response: any) => {
        this.packageList = response.packages;
        this.showPagePreloader = false;
        this.pageLoadedSuccessfully = true;
        this.showCoursesPreloader = false;
        this.coursesLoadedSuccessfully = true;
        this.changeDetectorRef.detectChanges();
        this.filterPackagesList('noFilter');
      });
    }
  }
  loadYorbitCourses() {
    let payload = {
      Academy: this.selectedAcademy.Name,
      Genre: this.selectedGenre.Name
    };
    if((this.previousAcademyId==null || this.previousGenreId==null)|| (!(this.previousAcademyId==this.selectedAcademy.Name && this.previousGenreId==this.selectedGenre.Name))){
      this.browseStore.dispatch(
        new fromBrowseStore.BrowseGetAcademyContent(payload)
      );  
    }
   
    this.browseStore
      .select(fromBrowseStore.getBrowseAcadmeyContentLoaded)
      .subscribe(response => {
        if (response) {
          if (
            this.selectedGenre &&
            this.selectedGenre.Contents &&
            this.showCoursesPreloader
          ) {
            // vendor and type has to be valid
            this.loadedUniqueVendorAndType = false;
            this.packageList = this.selectedGenre.Contents.packages;
            this.showPagePreloader = false;
            this.pageLoadedSuccessfully = true;
            this.showCoursesPreloader = false;
            this.coursesLoadedSuccessfully = true;
            this.changeDetectorRef.detectChanges();
            this.filterPackagesList('noFilter');
            this.previousAcademyId=this.selectedAcademy.Name;
            this.previousGenreId=this.selectedGenre.Name;

            if (this.router.url.indexOf('livingmindfully') != -1) {
              this.selectedGenre.SkillTerms.forEach((livingmindfullyItem)=>{
                if(livingmindfullyItem.Name == 'Living Mindfully')
                {
                  setTimeout(()=>{
                    this.filterBySkills(livingmindfullyItem);
                  },1500);

                }
              });

            }
        }
      }
      });




    // this.loadedUniqueVendorAndType = false;
    // this.selectedGenre.Contents = {
    //   packages:[],
    //   pageIndex: null,
    //   totalPackages: 0
    // };
    // this.packageList = this.selectedGenre.Contents.packages;
    // this.showPagePreloader = false;
    // this.pageLoadedSuccessfully = true;
    // this.filterPackagesList();
    // this.showCoursesPreloader = false;
    // this.coursesLoadedSuccessfully = true;
  }
  onGenreSelection(genre) {
    this.resetAllFilters();
    if (
      genre.UniqueId == this.selectedGenre.UniqueId &&
      this.mediaBreakPoint != 'xs'
    ) {
      genre.showSkills = !genre.showSkills;
    } else if (this.mediaBreakPoint == 'xs') {
      this.showCoursesInGenreView();
    } else {
      //change genreId in url
      this.selectedSkillsToFilter = [];
      this.packageList = [];
      if (this.router.url.indexOf('accountsAndProjects') == -1) {
        this.router.navigate(
          [
            'academy/' +
            this.selectedAcademy.UniqueId +
            '/genre/' +
            genre.UniqueId
          ],
          {
            relativeTo: this.activatedRoute.parent
          }
        );
      } else {
        this.router.navigate([
          'accountsAndProjects/' +
          this.selectedAcademy.UniqueId +
          '/' +
          genre.UniqueId
        ]);
      }
    }
  }
  subscribeMediaChanges() {
    this.genreViewCompSubscriptions.mediaChangeSubscription = this.mediaObserver.media$.subscribe(
      (media: MediaChange) => {
        this.mediaBreakPoint = media.mqAlias;
      }
    );
  }
  showCoursesInGenreView() {
    this.showCourses = true;
    this.browseService.updateShowSkillsFlagStatus(false);
    this.browseService.updateShowCoursesFlagStatus(true);
  }
  resetAllFilters() {
    //filter models
    this.searchContentTilesModel = '';
    this.selectedExpertiseToFilter = ['101', '201', '301'];
    this.selectedCourseTypeToFilter = [];
    this.selectedCreditsToFilter = [];
    this.selectedVendorToFilter = [];
    this.filterPackagesList('noFilter');
  }
  filterBySkills(skill) {
    skill.isSelected = !skill.isSelected;
    if (skill.isSelected) {
      this.selectedSkillsToFilter.push(skill.Name);
    } else {
      this.selectedSkillsToFilter.splice(
        this.selectedSkillsToFilter.indexOf(skill.Name),
        1
      );
    }
    this.resetAllFilters();
    this.filterPackagesList('noFilter');
  }
  filterByExpertise(expertise, checked) {
    if (expertise.toLowerCase() == 'all') {
      if (checked) {
        this.selectedExpertiseToFilter = ['101', '201', '301'];
      } else {
        this.selectedExpertiseToFilter = [];
      }
    } else {
      if (checked) {
        this.selectedExpertiseToFilter.push(expertise);
      } else {
        this.selectedExpertiseToFilter.splice(
          this.selectedExpertiseToFilter.indexOf(expertise),
          1
        );
      }
    }
    this.filterPackagesList('noFilter');
  }
  filterByCourseType(type, checked) {
    if (type.toLowerCase() == 'all') {
      if (checked) {
        this.selectedCourseTypeToFilter = this.courseTypes;
      } else {
        this.selectedCourseTypeToFilter = [];
      }
    } else {
      if (checked) {
        this.selectedCourseTypeToFilter.push(type);
      } else {
        this.selectedCourseTypeToFilter.splice(
          this.selectedCourseTypeToFilter.indexOf(type),
          1
        );
      }
    }
    this.filterPackagesList('courseType');
  }

  filterByCredits(credit, checked) {
    if (checked) {
      this.selectedCreditsToFilter.push(credit);
    } else {
      this.selectedCreditsToFilter.splice(
        this.selectedCreditsToFilter.indexOf(credit),
        1
      );
    }
    this.filterPackagesList('credits');
  }
  onFiltersChange() {
    this.filteredPackagesList = this.filterBrowseContent.transform(
      this.packageList,
      this.filterModel
    );
  }
  onSearchInputChange() {
    if (this.searchContentTilesModel.length != 0) {
      this.showSearchIcon = false;
    } else {
      this.showSearchIcon = true;
    }
    this.filterPackagesList('noFilter');
  }
  filterByVendor(type, checked) {
    if (checked) {
      this.selectedVendorToFilter.push(type);
    } else {
      this.selectedVendorToFilter.splice(
        this.selectedVendorToFilter.indexOf(type),
        1
      );
    }
    this.filterPackagesList('vendor');
  }
  ngOnInit() {
    this.validateRoute();
    this.userRoleAccessStore
      .select(fromRoleAccessStore.getRoleAccessList)
      .subscribe(roleList => {
        this.roleList = roleList;
      });
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
    this.browseService.updateSelectedGenre(null);
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.genreViewCompSubscriptions) {
      let subscriber = this.genreViewCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  goToAcademyView() {
    if (this.router.url.indexOf('accountsAndProjects') == -1) {
      this.router.navigate(['academy/' + this.selectedAcademy.UniqueId], {
        relativeTo: this.activatedRoute.parent
      });
    } else {
      this.router.navigate(['browse/accountsAndProjects']);
    }
    this.browseService.updateShowSkillsFlagStatus(false);
    this.browseService.updateShowCoursesFlagStatus(false);
  }
  getFilterObj(content) {
    // if (
    //   this.courseTypes.indexOf(content.Type) === -1 &&
    //   content.Type != '' &&
    //   content.Type != null
    // ) {
    //   this.courseTypes.push(content.Type);
    // }
    // if (
    //   this.vendors.indexOf(content.Vendor) === -1 &&
    //   content.Vendor != '' &&
    //   content.Vendor != null
    // ) {
    //   this.vendors.push(content.Vendor);
    // }
  }

  filterPackagesList(type) {
    let courseList101 = [];
    let courseList201 = [];
    let courseList301 = [];
    let packageList101 = [];
    let packageList201 = [];
    let packageList301 = [];
    this.packageList.forEach((value, key) => {
      if (!this.loadedUniqueVendorAndType) {
        if (
          value.Type != '' &&
          value.Type != null
        ) {
          this.courseTypes.push(value.Type);
        }
        if (
          value.Vendor != '' &&
          value.Vendor != null
        ) {
          this.vendors.push(value.Vendor);
        }
        if (value.Credits !== '' && value.Credits !== null) {
          this.credits.push(value.Credits);
        }
        this.loadedUniqueVendorAndType = true;
      }
      if (
        (this.selectedExpertiseToFilter.length == 3 &&
          this.selectedCourseTypeToFilter.length == 0 &&
          this.selectedCreditsToFilter.length == 0 &&
          this.selectedSkillsToFilter.length == 0 &&
          this.selectedVendorToFilter.length == 0 &&
          this.searchContentTilesModel.length == 0) ||
        (this.selectedExpertiseToFilter.indexOf(value.Expertise) != -1 &&
          (this.selectedCourseTypeToFilter.length == 0 ||
            this.selectedCourseTypeToFilter.indexOf(value.Type) != -1) &&
          (this.selectedCreditsToFilter.length == 0 ||
            this.selectedCreditsToFilter.indexOf(value.Credits) != -1) &&
          (this.selectedVendorToFilter.length == 0 ||
            this.selectedVendorToFilter.indexOf(value.Vendor) != -1) &&
          (this.selectedSkillsToFilter.length == 0 ||
            (this.isAccountBrowse ? this.selectedSkillsToFilter.indexOf(value.Category) != -1 : this.selectedSkillsToFilter.indexOf(value.Skill) != -1)) &&
          (this.searchContentTilesModel.length == 0 ||
            value.Name.toLowerCase().indexOf(
              this.searchContentTilesModel.toLowerCase()
            ) != -1))
      ) {
        if (value.Expertise == '101' && value.ItemType == 'Course') {
          courseList101.push(value);
        } else if (
          value.Expertise == '101' &&
          (value.ItemType == 'Package' || value.ItemType == 'FamilyPackage')
        ) {
          packageList101.push(value);
        } else if (value.Expertise == '201' && value.ItemType == 'Course') {
          courseList201.push(value);
        } else if (
          value.Expertise == '201' &&
          (value.ItemType == 'Package' || value.ItemType == 'FamilyPackage')
        ) {
          packageList201.push(value);
        } else if (value.Expertise == '301' && value.ItemType == 'Course') {
          courseList301.push(value);
        } else if (
          value.Expertise == '301' &&
          (value.ItemType == 'Package' || value.ItemType == 'FamilyPackage')
        ) {
          packageList301.push(value);
        }
      }
    });
    this.filteredCourseList = [
      ...courseList301,
      ...courseList201,
      ...courseList101
    ];
    this.filteredPackageList = [
      ...packageList301,
      ...packageList201,
      ...packageList101
    ];

    let dummyEntryForPartition = {
      ItemType: 'partition'
    };
    if (
      this.filteredPackageList.length != 0 &&
      this.filteredCourseList.length != 0
    ) {
      this.filteredPackageList.push(dummyEntryForPartition);
    }
    this.filteredPackagesAndCoursesList = this.filteredPackageList.concat(
      this.filteredCourseList
    );

    if (type != 'vendor' || this.selectedVendorToFilter.length == 0)
      this.vendors = Array.from(new Set(this.filteredPackagesAndCoursesList.map(x => x.Vendor)));
    if (type != 'courseType' || this.selectedCourseTypeToFilter.length == 0)
      this.courseTypes = Array.from(new Set(this.filteredPackagesAndCoursesList.map(x => x.Type)));
    if (type != 'credits' || this.selectedCreditsToFilter.length == 0)
      this.credits = Array.from(new Set(this.filteredPackagesAndCoursesList.map(x => x.Credits)));
     // //console.log('da', this.filteredPackagesAndCoursesList, this.credits);

    this.courseTypes = this.courseTypes.filter(function (element) {
      return element != null && element !== '';
    });

    this.vendors = this.vendors.filter(function (element) {
      return element != null && element !== '';
    });

    this.credits = this.credits.filter(function (element) {
      return element != null && element !== '';
    });

    this.credits.sort();
    this.vendors.sort();
    this.courseTypes.sort();

  }
}
