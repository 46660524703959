import {
  Component,
  OnInit,
  Output,
  Input,
  Inject,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { HeaderService } from './header.service';
import { AccountService } from '../account/account.service';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';
import * as fromMyPageStore from '../my-page/store';
import { AuthService } from '@YorbitWorkspace/auth';
import { AdalService } from 'adal-angular4';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { HeaderPopupComponent } from './header-popup/header-popup.component';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { WINDOW } from '../shared/services/window.service';
import { Globals } from '../globals';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { CdmService } from '../cdm/cdm.service';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import { SparkIntegrationService } from '../shared/services/spark-integration.service';
import { RoleBasedLearningStoreService } from '../shared/role-based-learning-store/role-based-learning-store.service';
import { RiskBrigadeService } from '../shared/services/risk-brigade.service';

import { AceingLeadershipService } from '../shared/services/aceing-leadership.service';
@Component({
  selector: 'yorbit-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() openSideNav: EventEmitter<any> = new EventEmitter();
  @Input('id') id: any;
  @Input('EmpId') EmpId: any;
  // @Input() IsC7nabv: boolean;
  // @Input() isC7nbelow: boolean;

  public search: any;
  public searchClicked = false;
  public hideSearchIcon = false;
  public disableOthers = false;
  public preloaderForSearch = false;
  userImageFromGraphDetails: any;
  yorbitPoints: number | string;
  roleList: fromRoleAccessStore.IroleData;
  complianceStatus: string;
  isCompliant: boolean;
  accountAndProjectList: any;
  isFutureSkillPage: boolean;
  currentRoute: string;
  IsC4nabv: boolean;
  rblAssignedFlag = false;
  aceingLeadershipAssigned: boolean = false;

  riskBrigadeAssignedFlag: boolean = false;

  constructor(
    private router: Router,
    private _envService: EnvironmentService,
    private headerService: HeaderService,
    private appStore: Store<any>,
    private domSanitizer: DomSanitizer,
    private myPageStore: Store<fromMyPageStore.IMyPageReducerState>,
    private accountService: AccountService,
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    //private myPageStore: Store<fromMyPageStore.IMyPageReducerState>,
    private adalService: AdalService,
    private _popup: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(WINDOW) private _window: any,
    public globals: Globals,
    private _insightService: AppInsightService,
    private cdmService: CdmService,
    private activatedRoute: ActivatedRoute,
    private msalService: MsalService,
    private _sparkService: SparkIntegrationService,
    private rblStoreService: RoleBasedLearningStoreService,
    private _aceingLeadershipService: AceingLeadershipService,
    private _riskBrigadeService: RiskBrigadeService
  ) {
    this.search = '';
    this.userImageFromGraphDetails = null;
    this.yorbitPoints = 0;
    this.accountAndProjectList = [];
    this.headerService.futureSkillButtonAnimation.subscribe(value => {
      this.isFutureSkillPage = value;
    });
  }

  ngOnInit() {
    this.isFutureSkillPage = false;
    this.loadUserYorbitProfile();
    //get the roles
    this.userRoleAccessStore
      .select(fromRoleAccessStore.getRoleAccessList)
      .subscribe(roleList => {
        this.roleList = roleList;
      });
    this.getCompliance();
    this.getAdminRoles();
    this.subscribeToCompetencyStatus();
    //get account and projects list
    this.accountService.getUsersAccountAndProjectList().subscribe(list => {
      if (list) list.sort(this.sortByAccountName);
      this.headerService.saveAccountData(list);
      this.accountAndProjectList = list;
    });

    this.currentRoute = this.router.url.replace('/', '');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.url.replace('/', '');
      }
    });
    this.getAceingLeadershipValidEmpIds();
  }

  getRBLRoleTaggingData(userMid) {

    this.rblAssignedFlag = false;
    // this.rblStoreService.getRoleTaggingEmpData().subscribe(empData=>{
    //     //console.log(empData);
    //     var tempEmp:any=empData;
    //       if(tempEmp.filter(emp=>emp.MID===userMid)[0]){
    // this.rblAssignedFlag=true;
    //       }
    // });
  }

  subscribeToCompetencyStatus() {
    this._sparkService.getCompStatus().subscribe(IsC4nabv => {
      //this.IsC4nabv = true;
      this.IsC4nabv = IsC4nabv;
    });
  }

  goToHMMSparkPage() {
    let payload = {
      action: 'Yorbit HMM Spark Banner Clicked',
      location: 'Home Page',
      trackEvent: 'Harvard Manage Mentor'
    };
    this.trackNewFeaturesInsights(payload);
    this.router.navigate(['/browse/HMM-Spark']);
  }

  getAdminRoles() {
    this.userRoleAccessStore
      .select(fromRoleAccessStore.getRoleAccessList)
      .subscribe(roleList => {
        this.roleList = roleList;
      });
  }

  loadUserYorbitProfile() {
    this.appStore.select('Graph').subscribe(graphDetails => {
      if (
        graphDetails.loaded &&
        graphDetails.userGraphInfo.user_image.size != 0 &&
        this.userImageFromGraphDetails == null
      ) {
        this.userImageFromGraphDetails = this.domSanitizer.bypassSecurityTrustUrl(
          this._window.URL.createObjectURL(
            graphDetails.userGraphInfo.user_image
          )
        );
        //this.changeDetectorRef.detectChanges();
      }
    });
    this.appStore.select('userDetails').subscribe(userDetails => {
      if (userDetails.user.loaded) {
        this.yorbitPoints = userDetails.user.data.Achievements.Points;
        this.getRBLRoleTaggingData(userDetails.user.data.id);
        this.getRiskBrigadeTaggingData(userDetails.user.data.id)
      }
    });
  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.doSearch();
    }
  }
  inputChanged(event) {
    if (event === '') {
      this.hideSearchIcon = false;
    } else {
      this.hideSearchIcon = true;
    }
    setTimeout(() => {
      this.hideSearchIcon = false;
    }, 1000);
  }
  doSearch() {
    if (this.search != null && this.search !== '') {
      this.preloaderForSearch = true;
      let data;
      data = {
        searchKey: this.search
      };
      this.searchClicked = true;
      this.hideSearchIcon = true;
      this.router.navigate(['search/' + this.search]).then(
        nav => {
          this.search = '';
          this.hideSearchIcon = false;
          this.preloaderForSearch = false;
        },
        err => { }
      );
      // this.headerService.getSearchData(data).subscribe(
      //   res => {
      //     const results = res;
      //     //save search results in service
      //     this.headerService.saveSearchedData(this.search, results);
      //     //after getting results go to search page
      //   },
      //   error => {
      //   }
      // );
    }
  }

  getCompliance() {
    this.myPageStore.select(fromMyPageStore.getMyPageCompliance).subscribe(
      data => {
        this.complianceStatus = data.ComplianceStatus;
        if (this.complianceStatus.toLowerCase() === 'non-compliant') {
          this.isCompliant = false;
        } else {
          this.isCompliant = true;
        }
      },
      error => { }
    );
  }
  openFutureSkill() {
    const routeTitle = this.activatedRoute.snapshot.firstChild.children[0].data
      .title;
    const insightPayload = {
      MID: this.globals.MId,
      NavigatedFrom: routeTitle
    };
    this._insightService.trackEvent(
      'Future Skill Icon clicked',
      insightPayload
    );
    this.router.navigate(['/futureskills']);
  }
  openProjectCaselets() {
    var url = this._envService.getEnvironment().appUrl;
    window.open(url + 'projectcaselets', '_blank');
  }
  goToAccountPage() {
    this.router.navigate(['account/', this.accountAndProjectList[0].AccountId]);
  }
  sortByAccountName(a, b) {
    const nameA = a.AccountId.toLowerCase(),
      nameB = b.AccountId.toLowerCase();
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  }
  logOut() {
    if (BroadcastChannel != undefined) {
      let swbroadcast = new BroadcastChannel('Yorbit-Channel');
      swbroadcast.postMessage({
        payload: 'LOGOUT'
      });
    }
    localStorage.setItem('isSessionExpiryPopupOpen', '0');
    window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
    //  this.adalService.logOut();
    this.msalService.logout();
  }
  openPopup() {
    this.router.navigate(['certificateUpload']);
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.panelClass = 'popupDialogContainer';
    // dialogConfig.data = {};
    // const response = this._popup.open(HeaderPopupComponent, dialogConfig);
    // response.afterClosed().subscribe(res => {
    //   //////console.log('response from header pop up', res);
    //   if (res.data === 'Yes') {
    //     this.router.navigate(['iCertificationUpload']);
    //   } else if (res.data === 'No') {
    //     this.router.navigate(['eCertificationUpload']);
    //   }
    // });
  }
  // redirectToSpark() {
  //   let payload = {
  //     action: 'Harvard Manage Mentor Spark Logo Clicked',
  //     location: 'Home Page on Desktop',
  //     trackEvent: 'Harvard Manage Mentor Spark'
  //   };
  //   this.trackNewFeaturesInsights(payload);
  //   window.open(this.globals.spark_url, '_blank');
  // }
  // redirectToHMM() {
  //   let payload = {
  //     action: 'Harvard Manage Mentor Logo Clicked',
  //     location: 'Home Page on Desktop',
  //     trackEvent: 'Harvard Manage Mentor'
  //   };
  //   this.trackNewFeaturesInsights(payload);
  //   window.open(this.globals.HMM_url, '_blank');
  // }

  trackNewFeaturesInsights(payload) {
    const insightPayload = {
      Action: payload.action,
      Location: payload.location
    };
    this._insightService.trackEvent(payload.trackEvent, insightPayload);
  }

  routeToApprovals(role) {
    this.cdmService.updateSearchStatus(false);
    let filtersObj = {
      limit: 20,
      index: 0,
      nextOrPrevious: 'next',
      acceptedRequests: false,
      actionedNo: false,
      actionedYes: false,
      arrangeByCourse: false,
      arrangeByDate: false,
      billablestatus: '',
      intentid: '',
      competency: '',
      courseName: '',
      deniedRequests: false,
      isACMorPCMAssigned: false,
      isOther201sInProgress: false,
      location: '',
      onHoldRequests: false,
      preRequisiteCoursesCompleted: false,
      preRequisiteCoursesNonCompleted: false,
      resignationstatus: '',
      searchMids: [],
      tab: ''
    };
    this.cdmService.updateRequestsFilters(filtersObj);
    if (role == 'cdm') {
      this.router.navigate(['lbp']);
    } else if (role == 'rm') {
      this.router.navigate(['rm']);
    } else {
      //do nothing
    }
  }

  getRiskBrigadeTaggingData(userMid) {
    this._riskBrigadeService.CheckUserEligibilityForRiskBrigade()
      .then(
        (data: any) => {
          // console.log(data)
          this.riskBrigadeAssignedFlag = data?.IsPresent;
        },
        error => {
        }
      );
  }
  getAceingLeadershipValidEmpIds() {
    this._aceingLeadershipService.UserValidationforAceingLeadership()
      .then(
        (Id: any) => {
          this.aceingLeadershipAssigned = Id ? Id.IsPresent : false;
        },
        error => {
          this.aceingLeadershipAssigned = false;
        }
      );
  }
}
