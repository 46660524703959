<mat-dialog-content>

  <div fxLayout="column" fxLayoutAlign="center" *ngIf="false">
    <div class="popup-title">
      <div class="heading header-alignment">
        <span>SME Evalution Request Upload Preview</span>
      </div>
    </div>
    <div class="popupHolder" fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="column" class="popup-content" fxLayoutAlign="start start">
        <table>
          <tr>
            <td fxLayoutAlign="start start">Course Name</td>
            <th class="details">{{data.RequestedCourseName}}</th>
          </tr>
          <tr>
            <td fxLayoutAlign="start start">Request Id</td>
            <th class="details">{{data.RequestId}}</th>
          </tr>
          <tr>
            <td fxLayoutAlign="start start">User Name</td>
            <th class="details">{{data.Name}}</th>
          </tr>
          <tr>
            <td fxLayoutAlign="start start">Final Average Score</td>
            <th class="details">{{payload.Score}}</th>
          </tr>
          <tr>
            <td fxLayoutAlign="start start">Status</td>
            <th class="details">{{payload.PassOrFailOrReappear=='pass'?'Pass':'Not Cleared'}}</th>
          </tr>
          <tr>
            <td fxLayoutAlign="start start">File Name</td>
            <th class="details">{{fileName}}</th>
          </tr>
        </table>
      </div>

    </div>
    <div class="action-buttons">
      <button class="action-button" (click)="close(false)">Cancel</button>
      <button class="action-button" (click)="UploadSME()">Confirm</button>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center">
    <div class="popup-title">
      <div class="heading header-alignment">
        <span *ngIf="data.popUpMsg != headingText">SME Evalution Form</span>
        <span *ngIf="data.popUpMsg == headingText">Exit Form</span>
      </div>
    </div>
    <div class="popupHolder" fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="column" class="popup-content" fxLayoutAlign="center center">
        <div [innerHTML]="data.popUpMsg"></div>
      </div>

    </div>
    <div class="action-buttons" *ngIf="data.isFormValid && !info" fxLayout="row" fxLayoutAlign="center center">
      <button class="action-button" (click)="close(false)">No</button>
      <button class="action-button" (click)="close(true)">Yes</button>
    </div>
    <div class="action-buttons" *ngIf="!data.isFormValid && !info">
      <button class="action-button" (click)="close(false)">Okay</button>
    </div>
    <div class="action-buttons" *ngIf="info">
      <button class="action-button" (click)="justClose()">Okay</button>
    </div>
  </div>

</mat-dialog-content>