<div class="cloud-lab-container" fxLayout="column" [ngStyle]="{'border-bottom': (courseExpertise != '101' && cloudLabDetails && cloudLabDetails.length > 0) ? '1px solid #7b8f9f' : 'none' }">
  <div *ngIf="false" class="help-icon mousePointer" title="Click here to access Coud Lab FAQ" (click)="goToCloudHelp()">
    <mat-icon>help_outline</mat-icon>
  </div>
  <div class="pre-loader" *ngIf="getRequestProcessing">
    <app-preloader></app-preloader>
  </div>
  <div class="pre-loader" fxShow.xs="true" fxHide *ngIf="!getRequestProcessing && cloudLabDetails
  && cloudLabDetails.length == 0" style="text-align: center;padding: 40px 0px;">
    Cloud Lab Not Available
  </div>
  <div *ngIf="!getRequestProcessing && cloudLabDetails
  && cloudLabDetails.length > 0 &&
  (( cloudLabDetails.length != 1)||(cloudLabDetails.length == 1 && null!=cloudLabDetails[0].cloudLabDetails))" fxLayout="column"
    fxLayoutAlign="start center" class="">
    <div fxLayout="row" style="width:100%">
      <div fxFlex="20" fxFlex="1 1 auto" class="cloud-icon-holder" fxLayoutAlign="center center">
        <div class="icon-holder-width ">
          <div class="yorbitSprite bg{{courseExpertise}}" style="margin:auto; border-radius:5px" [ngClass]="{
          'internalCloudLabLarge' : (cloudLabDetails[0].cloudLabDetails)?cloudLabDetails[0].cloudLabDetails.IsInternal:false,
          'externalCloudLabLarge' : (cloudLabDetails[0].cloudLabDetails)?!cloudLabDetails[0].cloudLabDetails.IsInternal:false
        }"></div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="80">
        <div fxLayout="column" [ngClass]="{'lab-overflow':showMoreClicked, 'lab-details-scrollable':!showMoreClicked}">
          <div *ngFor="let cloud of cloudLabDetailsForUI; let cloudIndex = index" class="lab-details-container" 
          [ngClass]="{'dividerHorizontal':cloudIndex > 0}" fxFlex="100">
            <!-- Cloud Lab Icon-->
            <div fxLayout="column">
              <div fxLayout="column" fxLayoutAlign="center start" class="cloud-details-holder">
                <div fxLayout="row" fxLayoutAlign="start center" [ngStyle]="{'width':'100%','position':'relative'}" *ngIf="cloud.cloudLabDetails && cloud.cloudLabDetails.IsInternal">
                  <!-- <div>
                    <span class="cloud-lab-label">{{ 'Cloud Lab Name' + ' : ' }}</span>
                    <span class="cloud-lab-name">{{ cloud.cloudLabDetails.CloudLabName}}</span>
                  </div> -->
                  <div class="cloud-lab-label">{{'Cloud Lab Name : '}}</div>                 
                  <div fxFlex class="cloud-lab-name">
                    {{ cloud.cloudLabDetails.CloudLabName }}
                  </div>               
                </div>
                <div fxLayout="row" class="details" *ngIf="cloud.cloudLabDetails != null" fxLayoutAlign="start start">
                  <!-- Internal Cloud Lab -->
                  <div fxFlex="90" class="access-status" *ngIf="cloud.cloudLabDetails&&cloud.cloudLabDetails.IsInternal">
                    <!-- Cloud lab is not requested yet or if C2 ops team has rejected the request -->
                    <div *ngIf="isNotRequested(cloud.cloudLabDetails.AccessStatus)" fxLayout="column" fxLayoutAlign="center start">
                      <div class="action-btn">
                        <button class="disabled" *ngIf="requestCloudAccessProcessing[cloudIndex]" [disabled]="true">Requesting...</button>
                        <button class="buttonRequestCloudAccess mousePointer" (click)="requestCloudLabAccess(cloud.cloudLabDetails, cloudIndex)"
                          *ngIf="!requestCloudAccessProcessing[cloudIndex]" [disabled]="requestCloudAccessProcessing[cloudIndex] || disableCloudLabRequestButton[cloudIndex] || disableOtherCloudLabReqButtons()"
                          [ngClass]="{'disabled': canDisableRequestBtn(cloudIndex)}" title="{{disableCloudLabRequestButton[cloudIndex]? disableMsg[cloudIndex]:''}}">
                  Request Cloud Lab Access
                 </button>
                      </div>
                      <div class="request-status" *ngIf="requestCloudAccessError[cloudIndex]">
                        Some error occured. Please try again.
                      </div>
                    </div>
                    <!-- Cloud lab request is sent by the learner -->
                    <div *ngIf="cloud.cloudLabDetails.AccessStatus == 'Access Requested'" fxLayout="column" fxLayoutAlign="center start">
                      <div class="">
                        <div class="info">
                          Cloud Lab Access Request Sent!
                        </div>
                      </div>
                    </div>
                    <!-- Cloud lab access is granted -->
                    <div *ngIf="cloud.cloudLabDetails.AccessStatus == 'Access Granted'" fxLayout="column" fxLayoutAlign="center start">
                      <div class="">
                        <div class="info">
                          Cloud Lab Access Provided!
                        </div>
                      </div>
                      <div class="cloud-duration" *ngIf="cloud.cloudLabDetails.Duration">
                        Access Duration : {{cloud.cloudLabDetails.Duration}}
                      </div>
                    </div>
                    <!-- Cloud lab access is expired -->
                    <div *ngIf="cloud.cloudLabDetails.AccessStatus == 'Access Revoked'" fxLayout="column" fxLayoutAlign="center start">
                      <div class="info">
                        Cloud Lab Access Revoked!
                      </div>
                    </div>
                    <!-- Cloud lab is completed -->
                    <div *ngIf="cloud.cloudLabDetails.AccessStatus == 'Course Already Completed'" fxLayout="column" fxLayoutAlign="center start">
                      <div class="action-btn">
                        <button class="disabled" [disabled]="true" title="Cloud lab cannot be requested for a course that is completed.">
                  Request Cloud Lab Access
                </button>
                      </div>
                    </div>
                  </div>
                  <!-- External Cloud Lab -->
                  <div fxFlex="90" class="access-status" *ngIf="cloud.cloudLabDetails&&!cloud.cloudLabDetails.IsInternal">
                    <div class="info" style="text-align:left; padding-right: 5px;">
                      To get the best out of this course you can utilize the cloud lab provided by <b>{{vendor}}</b>.
                    </div>
                  </div>
                  <!-- Information Icon -->
                  <div fxFlex="10" style="position:relative" fxLayout="column" fxLayoutAlign="start start" *ngIf="cloud.cloudLabDetails.AccessStatus != 'Course Already Completed'">
                    <div class="info-icon mousePointer" (mouseover)="hovered[cloudIndex] = true" (mouseleave)="hovered[cloudIndex] = false; clicked[cloudIndex] = false"
                      (click)="clicked[cloudIndex] = true">i</div>
                    <div class="info-box-pos">
                      <ng-template *ngIf="hovered[cloudIndex] || clicked[cloudIndex]" [ngTemplateOutlet]="toolTip" [ngTemplateOutletContext]="{
                cloudData: cloud.cloudLabDetails
              }"></ng-template>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div *ngIf="!getRequestProcessing && getRequestError">
              <div class="info" *ngIf="" fxLayout="column" fxLayoutAlign="center center">
                Some error occured. Please try later.
              </div>
            </div>
          </div>
        </div>
        <div fxLayoutAlign="end end" style="width:100%;padding:3px 10px">
          <div class="expand" (click)="expand()" *ngIf="cloudLabDetails.length > 1 && !showMoreClicked">Show More</div>
          <div class="collapse" (click)="collapse()" *ngIf="cloudLabDetails.length > 1 && showMoreClicked">Show Less</div>
        </div>
      </div>

    </div>
  </div>
</div>


<ng-template #toolTip let-cloud="cloudData">
  <!-- Tool tip for internal cloud labs-->
  <div class="tool-tip-box" fxLayout="column" *ngIf="cloud.IsInternal">
    <div class="title">{{cloud.CloudLabName}}</div>
    <div>
      <ng-template [ngTemplateOutlet]="toolTipMessagesInternal" [ngTemplateOutletContext]="{
          status : cloud.AccessStatus,
          duration : cloud.Duration,
          name : cloud.CloudLabName
        }"></ng-template>
    </div>
  </div>
  <!-- Tool tip for external cloud labs -->
  <div class="tool-tip-box" fxLayout="column" *ngIf="!cloud.IsInternal">
    <div class="title">{{"Cloud Lab"}}</div>
    <div class="message1">{{ cloudLabToolTipMessages.VendorProvidedLab.message1 + vendor + '.'}}</div>
    <div class="message2">{{ cloudLabToolTipMessages.VendorProvidedLab.message2 }}</div>
    <div class="red"></div>
  </div>
</ng-template>

<ng-template #toolTipMessagesInternal let-status="status" let-duration="duration" let-name="name">
  <!-- Not Requested || Access Rejected-->
  <div *ngIf="isNotRequested(status)" fxLayout="column">
    <div class="message1">{{cloudLabToolTipMessages.RequestAccess.message1}}</div>
    <div class="red" style="padding-top:10px">{{cloudLabToolTipMessages.RequestAccess.message2 + ' ' + duration}}</div>
  </div>
  <!-- Access Requested -->
  <div *ngIf="status == 'Access Requested'" fxLayout="column">
    <div class="message1" *ngIf="courseExpertise == '101'">
      {{cloudLabToolTipMessages.AccessRequested.message1}}
    </div>
    <div class="message1" *ngIf="courseExpertise != '101' && workflowStatus == 'UNDER REVIEW'">
      {{cloudLabToolTipMessages.AccessRequested.message1_AutoApproval}}
    </div>
    <div class="message1" *ngIf="courseExpertise != '101' && workflowStatus == 'PENDING' || workflowManipulated == 'Request On Hold'">
      {{cloudLabToolTipMessages.AccessRequested.message1_RMApproval}}
    </div>
    <div class="message1" *ngIf="courseExpertise != '101' && workflowManipulated == 'Course In Progress' || workflowManipulated == 'Course Completed'">
      {{cloudLabToolTipMessages.AccessRequested.message1}}
    </div>
    <div class="message2">{{cloudLabToolTipMessages.AccessRequested.message2}}</div>
  </div>
  <!-- Access Granted -->
  <div *ngIf="status == 'Access Granted'" fxLayout="column">
    <div class="message1">{{cloudLabToolTipMessages.AccessGranted.message1 + duration }}</div>
    <div class="message2">{{cloudLabToolTipMessages.AccessGranted.message2}}</div>
  </div>
  <!-- Access Revoked-->
  <div *ngIf="status == 'Access Revoked'" fxLayout="column">
    <div class="message1">{{cloudLabToolTipMessages.AccessRevoked.message1}}</div>
    <div class="message2">{{cloudLabToolTipMessages.AccessRevoked.message2}}</div>
  </div>
</ng-template>