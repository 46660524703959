import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CourseProcedureUploadComponent } from '../pss/course-procedure-upload/course-procedure-upload.component';

@Injectable({
  providedIn: 'root'
})
export class SmeService {
  config:any;
  constructor(
    private http: HttpClient,
    private _envService: EnvironmentService
  ) {
    this.config = this._envService.getEnvironment();
   }

  //POST /Workflow/SMERequests/{requestId}

  getSmeRequest(requestId) {
    const payload={"acceptedRequests":false,"actionedNo":false,"actionedYes":false,"arrangeByCourse":false,"arrangeByDate":false,"billablestatus":"","competency":"","courseName":"","deniedRequests":false,"isACMorPCMAssigned":false,"isOther201sInProgress":false,"location":"","onHoldRequests":false,"preRequisiteCoursesCompleted":false,"preRequisiteCoursesNonCompleted":false,"resignationstatus":"","searchMids":[],"intentid":"","PeopleOwner":""}
    let url = "Workflow/SMERequests/"+requestId;
    return this.http.post<any>(this.config.apiUrl + url,payload);
  }

  // uploadSme(form : FormData,payload : object) {
    uploadSme(formData : any) {

    let fd = new FormData();
   // fd.append('file', form.get('file'));
    fd.delete('data');
   // fd.append('data', payload);

   let payload = {
    RequestId: formData.RequestId,
    PassOrFailOrReappear: formData.finalStatus,
    CourseId: formData.CourseId,
    LearnerId: formData.associatePsId,
    Score: formData.averageScore
    }

    Object.entries(payload).forEach(([key, value]) => {
      fd.append(key, value);
    });

    let url = "Workflow/SMEEvaluatorAction";
    return this.http.post<any>(this.config.apiUrl + url,fd);
  }

   //download SME template button
   downloadTemplateForUpload() {
    return this.http
      .get<any>(this.config.apiUrl + 'PSS/Download/SMETemplate', {
        responseType: 'arraybuffer' as 'json',
        observe: 'response'
      })
      .toPromise();
  }

  insertSMEEvaluationDetails(formData, isSubmitted) {
    let payload = {
      RequestId : formData.RequestId,
      Attempt : formData.Attempt,
      AssociateName : formData.associateName,
      AssociatePSID : formData.associatePsId,
      BUDescription : formData.practiceUnit,
      Grade : formData.grade,
      IRMName : formData.managerName,
      LeafSkill : formData.leafSkill,
      CurrentProficiency : formData.currentProficiency,
      RequiredProficiency : formData.requiredProficiency,
      YearsOfExperience : formData.yearsExperience,
      MonthsOfExperience : formData.monthsExperience,
      DateOfInterview : formData.interviewDate ? formData.interviewDate : null,
      InterviewFormat : formData.format,
      InterviewPanelists : formData.panelistsName,
      InterviewOtherDetails:formData.otherDetails,
      WorkExperienceDescription : formData.workExperience,
      WorkExperienceScore : formData.workExperienceScore,
      WorkExperienceKeySpike : formData.workExperienceSpike,
      WorkExperienceGaps : formData.workExperienceGaps,
      ToolingDescription : formData.tooling,
      ToolingScore : formData.toolingScore,
      ToolingKeySpike: formData.toolingSpike,
      ToolingGaps : formData.toolingGaps,
      ProcedureMethodologyDescription : formData.procedures,
      ProcedureMethodologyScore : formData.proceduresScore,
      ProcedureMethodologyKeySpike : formData.proceduresSpike,
      ProcedureMethodologyGaps : formData.proceduresGaps,
      SolutioningDescription : formData.solutioning,
      SolutioningScore : formData.solutioningScore,
      SolutioningKeySpike : formData.solutioningSpike,
      SolutioningGaps : formData.solutioningGaps,
      SituationalQuestionsDescription : formData.situationalQuestions,
      SituationalQuestionsScore : formData.situationalScore,
      SituationalQuestionsKeySpike : formData.situationalSpike,
      SituationalQuestionsGaps : formData.situationalGaps,
      CertificateScoreDescription : formData.certificationsScore,
      CertificateScoreScore : formData.certificationsScoreSelect,
      CertificateScoreKeySpike : formData.certificationsSpike,
      CertificateScoreGaps : formData.certificationsGaps,
      AssociateProficiencyMeet : formData.proficiencyMet,
      AverageScore : formData.averageScore,
      KeyReason : formData.keyReasons,
      SMEScore : formData.smeScore,
      ScoreChangeReasons:formData.scoreChangeReasons,
      IsFormSubmitted : isSubmitted,
      Status : formData.finalStatus ? formData.finalStatus : "NULL"
    }
    const URL = 'Workflow/SMEEvaluation';
    return this.http.post<any>(this.config.apiUrl + URL, payload);
  }
  
  getEvaluationDetails(requestId, attempt) {
    const URL = 'Workflow/SMEEvaluation';
    return this.http.get<any>(this.config.apiUrl + URL + '/' + requestId + '/' + attempt );
  }

  getSMEPanelist(skill: string) {
    const URL = 'Workflow/SMEpanelist';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const payload = { skill: skill };
    return this.http.post<any>(this.config.apiUrl + URL, payload, { headers: headers });
  }
}