import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LearningPathService } from 'apps/yorbit-ui/src/app/learning-path/learning-path.service';
import { PlaylistService } from '../playlist.service';
import { VideoPlayersService } from 'apps/yorbit-ui/src/app/shared/video-players/video-players.service';
import * as fromUserDetailsStore from 'apps/yorbit-ui/src/app/shared/user-details/store';
import {
  IuserDetails,
  LearningPath,
  PackageList,
} from 'apps/yorbit-ui/src/app/shared/user-details/store/user-details.interface';
import { Store } from '@ngrx/store';
import {
  LPBreadcrumbsStateModel,
  ILPDetailsModel,
  ICourseDetailsModel,
  IPackageDetailsModel,
  IVideoDetailsModel,
} from 'apps/yorbit-ui/src/app/learning-path/learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from 'apps/yorbit-ui/src/app/learning-path/learning-path-breadcrumbs.interface';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { QuizConfirmationPopupComponent } from '../quiz-confirmation-popup/quiz-confirmation-popup.component';
import { AccountContentCompletionConfirmationComponent } from '../account-content-completion-confirmation/account-content-completion-confirmation.component';
import { saveAs } from 'file-saver';
import { Subscriber } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import { SystemService } from '../../services/system.service';
import { Globals } from '../../../globals';
@Component({
  selector: 'yorbit-playlist-internal-course',
  templateUrl: './playlist-internal-course.component.html',
  styleUrls: ['./playlist-internal-course.component.scss'],
})
export class PlaylistInternalCourseComponent implements OnInit, OnDestroy {
  playlist: any;
  @Input() courseMaterial;
  learningPathsLoadedSuccessfully: boolean = false;
  playlistCompSubscriptions: any;
  LPBreadcrumbs: ILPBreadcrumbs;
  courseDetails: PackageList;
  filterPipe = new ArrayPropertyFilterPipe();
  units: Array<any>;
  unitContents: any;
  courseMatAvailable: any;
  courseMaterialType: any;
  completeVideosList: any;
  progressList: Array<any>;
  selectedUnitId: string | number;
  selectedVideoId: string | number;
  playlistUnitCount: number;
  canPlayFirstVideoInFirstUnit: boolean;
  lpResumeStatus: boolean;
  resumeDetails: any;
  allUnitsLoaded: boolean;
  isFirstLoad: boolean;
  canPlayAll: boolean;
  loading: boolean;
  loadedSuccessFully: boolean;
  IsCourseMaterialAvailable: boolean;
  courseValues: any;
  color: ThemePalette = 'warn';
  Totaltime: any;
  deletedProgressListBackUp = [];
  deletedProgressListBackUpLoaded = false;
  updatedVideoIdsFromDeletedProgressList = [];
  contentUnits: any;
  courseContents = [];
  multiContentCompletion: any;
  multiContents: any;
  allUnitsDetails: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private playlistService: PlaylistService,
    private lpService: LearningPathService,
    private videoPlayersService: VideoPlayersService,
    private dialog: MatDialog,
    private _systemSvc: SystemService,
    private globals: Globals
  ) {
    this.allUnitsLoaded = false;
    this.playlistCompSubscriptions = {};
    this.LPBreadcrumbs = { ...LPBreadcrumbsStateModel };
    this.units = [];
    this.unitContents = {};
    this.completeVideosList = [];
    this.playlist = [];
    this.playlistUnitCount = 0;
    this.progressList = [];
    this.lpResumeStatus = false;
    this.resumeDetails = null;
    this.isFirstLoad = true;
    this.loading = true;
    this.loadedSuccessFully = false;
    this.allUnitsDetails = [];

    /*Can play all vidoes flag defines whther to auto play next video in the playlist  */
    this.subscribeToCanPlayAllFlag();
  }

  subscribeToCanPlayAllFlag() {
    this.playlistService.getCanPlayAllVideosFlag().subscribe((value) => {
      this.canPlayAll = value;
    });
  }
  ngOnInit() {
    this.courseMatAvailable =
      this.courseMaterial.CourseMaterialLink != '' ? true : false;
    this.courseMaterialType = this.courseMaterial.CourseMaterialType;
    this.playlistCompSubscriptions.getUserDetailLoaded = this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailLoaded)
      .subscribe((loaded) => {
        //console.log("user details loaded");
        if (loaded && !this.learningPathsLoadedSuccessfully) {
          this.learningPathsLoadedSuccessfully = true;
          /* get resume status
             if true set canPlayFirstVideoInFirstUnit to true and get lp resume details
             if false set canPlayFirstVideoInFirstUnit to false
             */
          this.subscribeToResumeStatus();
          /* Subscribe to LP Breadcrumbs
              Get the course Details from breadcrumbs
              load the playlist => All Videos Data
              get ProgressList from docdb inside the LP Packagelist for the course and set default values if entry is not created
              update Full PlayList
              */
          this.subscribeToBreadCrumbEvents();
          /* Get the playing video details
              set selected Unit Id and selectedVideoId
              update breadcrumbs data */
          this.getSelectedVideoIdSubscription();
          this.getContentProgressInsideDeletedBackUp();
          //this.updateDocDBWithDeletedProgressList();
          
        }
      });
  }
  subscribeToResumeStatus() {
    this.playlistCompSubscriptions.resumeStatusSubscription = this.lpService
      .getResumeStatus()
      .subscribe((status) => {
        this.lpResumeStatus = status;
        if (this.lpResumeStatus) {
          this.canPlayFirstVideoInFirstUnit = false;
          this.getResumeDetailsSubscription();
        } else {
          this.canPlayFirstVideoInFirstUnit = true;
        }
      });
  }
  getContentProgressInsideDeletedBackUp() {
    this.playlistCompSubscriptions.getContentProgressInsideDeletedBackUp =
      this.userDetailsStore
        .select(fromUserDetailsStore.getContentProgressInsideDeletedBackUp())
        .subscribe((res) => {
          if (res && res.length > 0 && !this.deletedProgressListBackUpLoaded) {
            let deletedProgressBackUP = res;
            this.deletedProgressListBackUpLoaded = true;
            let payload = {
              LearningPathId: this.LPBreadcrumbs.LPDetails.LPId,
              ItemId: '',
              ItemType: '',
              CourseId: '',
              ProgressList: [],
            };
            if (
              this.LPBreadcrumbs.PackageDetails.PackageId != null &&
              this.LPBreadcrumbs.PackageDetails.PackageId.toString() != ''
            ) {
              payload.ItemId =
                this.LPBreadcrumbs.PackageDetails.PackageId.toString();
              payload.ItemType = 'FamilyPackage';
            } else {
              payload.ItemId =
                this.LPBreadcrumbs.CourseDetails.CourseId.toString();
              payload.ItemType = 'Course';
            }
            payload.CourseId =
              this.LPBreadcrumbs.CourseDetails.CourseId.toString();

            deletedProgressBackUP.forEach((val) => {
              this.deletedProgressListBackUp[val.ContentId.toString()] = val;
              if (
                val.CourseId.toString() ==
                this.LPBreadcrumbs.CourseDetails.CourseId.toString()
              ) {
                payload.ProgressList.push(val);
              }
            });
            if (
              payload.LearningPathId &&
              payload.CourseId &&
              payload.ItemId &&
              payload.ProgressList.length > 0
            ) {
              this.playlistService
                .updateUserDeletedVideoProgress(payload)
                .then((res) => {
                  if (res && res == 'success') {
                    payload.ProgressList.forEach((ele) => {
                      this.updatedVideoIdsFromDeletedProgressList.push(
                        ele.ContentId
                      );
                    });
                    this.userDetailsStore.dispatch(
                      new fromUserDetailsStore.UpdateVideoProgressForReaddedCourses(
                        payload
                      )
                    );
                    let ids = [];
                    payload.ProgressList.forEach((element) => {
                      ids.push(element.ContentId);
                    });
                    this.userDetailsStore.dispatch(
                      new fromUserDetailsStore.ClearDeletedVideoProgressBackUp(
                        ids
                      )
                    );
                  }
                })
                .catch((err) => { });
            }
          }
        });
  }
  subscribeToBreadCrumbEvents() {
    this.playlistCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe((breadcrumbs) => {
        this.LPBreadcrumbs = breadcrumbs;
        if (
          this.LPBreadcrumbs.CourseDetails.CourseId != null &&
          this.isFirstLoad
        ) {
          this.isFirstLoad = false;
          /*
            get ProgressList from docdb inside the LP Packagelist for the course and set default values if entry is not created
                    */
          this.getProgressList();
          this.getVideoDetailsToCaptureProgressInProgressBar();
          /* Get Units List
                    Get Videos List inside each Unit
                    update completeVideosList
                    if resume status is false Load First Video in First Unit
                    if resume status is true load the resume video
                    set complete flag based on the progress list
                     */
          this.getUnitsInsideCourse(this.LPBreadcrumbs.CourseDetails);
        }
      });
  }
  getProgressList() {
    this.playlistCompSubscriptions.getContentProgressInsideUnitSub =
      this.userDetailsStore
        .select(
          fromUserDetailsStore.getContentProgressInsideUnit(
            this.LPBreadcrumbs.LPDetails.IsLPMandatory
              ? this.LPBreadcrumbs.LPDetails.LPCategory.toLowerCase() !=
                'skilling'
                ? this.LPBreadcrumbs.LPDetails.LPName.toUpperCase()
                : this.LPBreadcrumbs.LPDetails.LPId
              : this.LPBreadcrumbs.LPDetails.LPId,
            this.LPBreadcrumbs.PackageDetails.PackageId != null
              ? this.LPBreadcrumbs.PackageDetails.PackageId.toString()
              : this.LPBreadcrumbs.CourseDetails.CourseId.toString()
          )
        )
        .subscribe((res) => {
          if (res != null && res.length != 0) {
            ////console.log('progress list store update after progress capture');

            res.forEach((progresslist) => {
              progresslist.Progress = parseInt(progresslist.Progress);
              this.progressList[progresslist.ContentId.toString()] =
                progresslist;
            });
          }
        });
    this.playlistCompSubscriptions.getContentProgressInsideUnitSub.unsubscribe();
  }
  // getVideoDetailsToCaptureProgressInProgressBar() {
  //   this.playlistCompSubscriptions.getVideoDetailsToCaptureProgressSub = this.videoPlayersService
  //     .getVideoDetailsToCaptureProgress()
  //     .subscribe(ress => {
  //       ////console.log('video details to capture progress', ress);
  //       this.playlistCompSubscriptions.getContentProgressInsideUnitSub2 = this.userDetailsStore
  //         .select(
  //         fromUserDetailsStore.getContentProgressInsideUnit(
  //           this.LPBreadcrumbs.LPDetails.IsLPMandatory
  //             ? this.LPBreadcrumbs.LPDetails.LPCategory.toLowerCase() !=
  //               'skilling'
  //               ? this.LPBreadcrumbs.LPDetails.LPName.toUpperCase()
  //               : this.LPBreadcrumbs.LPDetails.LPId
  //             : this.LPBreadcrumbs.LPDetails.LPId,
  //           this.LPBreadcrumbs.PackageDetails.PackageId != null
  //             ? this.LPBreadcrumbs.PackageDetails.PackageId.toString()
  //             : this.LPBreadcrumbs.CourseDetails.CourseId.toString()
  //         )
  //         )
  //         .subscribe(res => {
  //           if (
  //             res &&
  //             res.length != 0 &&
  //             this.LPBreadcrumbs.VideoDetails.VideoId != null
  //           ) {
  //             res.forEach(progresslist => {
  //               // ////console.log('progress list store update after progress capture',  progresslist.ContentId, this.LPBreadcrumbs.VideoDetails.VideoId);
  //               if (
  //                 progresslist.ContentId ==
  //                 this.LPBreadcrumbs.VideoDetails.VideoId.toString()
  //               ) {
  //                 progresslist.Progress = parseInt(progresslist.Progress);
  //                 this.progressList[
  //                   progresslist.ContentId.toString()
  //                 ] = progresslist;
  //                 this.progressList = [...this.progressList];
  //                 // ////console.log("progresslist", progresslist);
  //               }
  //             });
  //           }
  //         });
  //       this.playlistCompSubscriptions.getContentProgressInsideUnitSub2.unsubscribe();
  //       this.playlistCompSubscriptions.getContentProgressInsideUnitSub2 = null;
  //     });
  // }
  getVideoDetailsToCaptureProgressInProgressBar() {
    this.playlistCompSubscriptions.getVideoDetailsToCaptureProgressSub =
      this.videoPlayersService
        .getVideoDetailsToCaptureProgress()
        .subscribe((videoDetails) => {
          ////console.log('video details to capture progress', ress);
          if (videoDetails) {
            let progress = 0;
            if (videoDetails.CurrentTime != 0) {
              progress = Math.ceil(
                (videoDetails.CurrentTime / videoDetails.TotalTime) * 100
              );
              if (this.progressList[videoDetails.ContentId.toString()]) {
                this.progressList[
                  videoDetails.ContentId.toString()
                ].CurrentTime = videoDetails.CurrentTime;
              } else {
                this.progressList[videoDetails.ContentId.toString()] =
                  videoDetails;
              }
            }
            if (this.progressList[videoDetails.ContentId.toString()]) {
              this.progressList[videoDetails.ContentId.toString()].Progress =
                progress;
            }
            this.progressList = [...this.progressList];
          }
        });
  }
  getUnitsInsideCourse(course) {
    this.playlistCompSubscriptions.getContentInCourseSub = this.playlistService
      .getContentInsideCourse(this.LPBreadcrumbs.CourseDetails.CourseId)
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.loadedSuccessFully = true;
          let unitId;
          if (data.packages != null) {
            this.units = data.packages;
            this.units.forEach((unit, index) => {
              this.courseValues = this.units[index];
              //this.courseMatAvailable = this.courseValues[index].CourseMaterialType;
              //////console.log('data #####', this.courseMatAvailable);
              this.completeVideosList.push({ unitId: unit.Id, videos: [] });
              this.getContentInYorbitCourseUnit(unit.Id, index);
            });
          }
        },
        (error) => {
          this.loading = false;
          this.loadedSuccessFully = false;
        }
      );
  }
  getContentInYorbitCourseUnit(unitId, index) {
    this.playlistCompSubscriptions.getUnitsRequestSub = this.playlistService
      .getContentInsideUnit(unitId)
      .subscribe(
        (data: any) => {
          this.playlistUnitCount++;
          if (data.ContentList != null) {
            if (data.ContentList.length > 0) {
              this.unitContents[unitId] = data.ContentList;
              this.allUnitsDetails[index] = data.ContentList;
              this.completeVideosList.forEach((unit) => {
                if (unit.unitId == unitId) {
                  this.unitContents[unitId].forEach((video, index) => {
                    //check resume status and load video if required
                    //update the completeVideosList
                    if (video.SourceType.toLowerCase() == 'youtube') {
                      video.VideoId = video.SourceUrl.split('embed/')[1];
                      video.ContentId = video.Id;
                    } else if (video.SourceType.toLowerCase() == 'o365') {
                      video.VideoId = video.Id;
                      // video.Image = "../../../../assets/images/O365_default.png";
                      video.Image =
                        '../../../../assets/images/O365_default_LTIMindtree.png';
                    } else if (video.SourceType.toLowerCase() == 'scorm') {
                      video.VideoId = video.Id;
                      video.Image =
                        '../../../../assets/images/O365_default_LTIMindtree.png';
                    } else {
                      video.VideoId = video.Id;
                      video.Image =
                        '../../../../assets/images/O365_default_LTIMindtree.png';
                    }
                    unit.videos.push(video);
                    if (
                      this.lpResumeStatus &&
                      unitId == this.resumeDetails.UnitId &&
                      video.Id == this.resumeDetails.ContentId
                    ) {
                      this.loadVideo(unitId, index, this.resumeDetails.Time);
                    }
                    /*
                                      check and update the progress  list if entry is not present for videos
                                      and set complete flag to true if progress > 95
                                      */
                    if (this.progressList[video.Id.toString()] == undefined) {
                      if (
                        this.deletedProgressListBackUp[video.Id.toString()] !=
                        undefined
                      ) {
                        let videoProgress =
                          this.deletedProgressListBackUp[video.Id.toString()];
                        videoProgress['CourseId'] =
                          this.LPBreadcrumbs.CourseDetails.CourseId;
                        this.progressList[video.Id.toString()] = videoProgress;
                        video.Completed = false;
                      } else {
                        let videoProgress = {};
                        videoProgress['Progress'] = 0;
                        videoProgress['ContentId'] = video.UniqueId;
                        videoProgress['CourseId'] =
                          this.LPBreadcrumbs.CourseDetails.CourseId;
                        videoProgress['CurrentTime'] = 0;
                        this.progressList[video.Id.toString()] = videoProgress;
                        video.Completed = false;
                      }
                    } else {
                      if (
                        Math.ceil(
                          parseInt(
                            this.progressList[video.Id.toString()].Progress
                          )
                        ) >= 95
                      ) {
                        video.Completed = true;
                      }
                      //convert video.duration into secs
                      //calculate progress using this.progressList[video.Id].Progress and duration
                      // set this.progressList[video.Id].Progress = calculated progress

                      //  if(this.progressList[video.Id].CurrentTime > 0 || video.Duration > 0 || this.progressList[video.Id].Progress != undefined){
                      const duration = (video.Duration || '').split('.');
                      for (let i = 0; i < duration.length; i++) {
                        // tslint:disable-next-line:radix
                        duration[i] = isNaN(parseInt(duration[i]))
                          ? 0
                          : parseInt(duration[i]);
                      }
                      const hrs = duration[0] * 60 * 60;
                      const mins = duration[1] * 60;
                      const seconds = duration[2];

                      this.Totaltime = hrs + mins + seconds;
                      // //console.log(
                      //   '$$ this.progressList[video.Id.toString()].CurrentTime',
                      //   this.progressList[video.Id.toString()].CurrentTime
                      // );
                      this.progressList[video.Id.toString()].CurrentTime =
                        this.progressList[video.Id.toString()].CurrentTime ==
                          null
                          ? '0.0'
                          : this.progressList[
                            video.Id.toString()
                          ].CurrentTime.toString();
                      const curr =
                        this.progressList[
                          video.Id.toString()
                        ].CurrentTime.split('.');
                      // tslint:disable-next-line:radix
                      const currTime = parseInt(curr[0]);

                      if (this.Totaltime == 0) {
                        this.progressList[video.Id.toString()].Progress =
                          Math.ceil(
                            parseInt(
                              this.progressList[video.Id.toString()].Progress
                            )
                          );
                      } else {
                        const CalProgress = (currTime / this.Totaltime) * 100;
                        this.progressList[video.Id.toString()].Progress =
                          Math.ceil(CalProgress);
                      }
                      if (
                        this.progressList[video.Id.toString()].Progress == 0
                      ) {
                        this.progressList[video.Id.toString()].Progress = 1;
                      }
                    }
                  });
                }
              });
              if (index == 0) {
                this.selectedUnitId = unitId;
                if (!this.lpResumeStatus && this.canPlayFirstVideoInFirstUnit) {
                  //load first video by default
                  this.loadVideo(this.selectedUnitId, 0, null);
                }
              }
            }
          }
          if (this.units.length == this.playlistUnitCount) {
            this.updateAllVideoIdsInPlaylist();
          }
        },
        (error) => { }
      );
  }
  loadVideo(unitId, videoIndex, time) {
    let video = this.unitContents[unitId][videoIndex];
    this.selectedVideoId = video.VideoId;
    if(this.courseContents.length > 0) {
      let nextVideoId;
      let isNextVideoId = false;
      for(let i = 0; i < this.courseContents.length; i++) {
        for(let j = 0; j < this.courseContents[i].length; j++) {
          if(isNextVideoId) {
            nextVideoId = this.courseContents[i][j].ContentId;
          }
          if(this.courseContents[i][j].IsContentEnabled == 1) {
            nextVideoId = this.courseContents[i][j].ContentId;
            if((this.courseContents[i][j].length - 1) == j) {
              isNextVideoId = true;
            }
          }
        }
      }
      // this.selectedVideoId = nextVideoId;

    }
    if (!this.allUnitsLoaded && videoIndex == 0) {
      let playlistParams = {
        playlist: [
          {
            videoId: video.VideoId,
            sourceUrl: video.SourceUrl,
            sourceType: video.SourceType,
            isCompliance: video.Compliance,
            resumeTime: this.lpResumeStatus
              ? time
              : this.progressList[video.Id.toString()].Progress == 100
                ? 0
                : this.progressList[video.Id.toString()].CurrentTime,
          },
        ],
        allUnitsLoaded: false,
        resume: false,
      };
      if (this.lpResumeStatus) {
        playlistParams.resume = true;
      }
      this.videoPlayersService.updatePlaylist(playlistParams);
    }
    // else if(video.SourceType && (video.SourceType.toLowerCase() == "scorm")){
    //   let playlistParams = {
    //     playlist: [
    //       {
    //         videoId: video.VideoId,
    //         sourceUrl: video.SourceUrl,
    //         sourceType: video.SourceType,
    //         isCompliance: video.Compliance,
    //         resumeTime: this.lpResumeStatus
    //           ? time
    //           : this.progressList[video.Id.toString()].Progress == 100
    //             ? 0
    //             : this.progressList[video.Id.toString()].CurrentTime
    //       }
    //     ],
    //     allUnitsLoaded: true,
    //     resume: false
    //   };
    //   console.log("PlaylistParams", playlistParams);
    //   this.videoPlayersService.updatePlaylist(playlistParams);
    // }
    this.navigateToVideoComp(video.VideoId, video.SourceType);
    document.getElementsByClassName('lp-course-detail-page')[0].scrollTop = 0;
  }
  updateAllVideoIdsInPlaylist() {
    this.playlist = [];
    this.completeVideosList.forEach((unit) => {
      unit.videos.forEach((video) => {
        this.playlist.push({
          contentId: video.ContentId,
          videoId: video.VideoId,
          sourceUrl: video.SourceUrl,
          sourceType: video.SourceType,
          isCompliance: video.Compliance,
          resumeTime:
            this.progressList[video.Id.toString()].Progress == 100
              ? 0
              : this.progressList[video.Id.toString()].CurrentTime,
        });
      });
    });
    this.videoPlayersService.updatePlaylist({
      playlist: this.playlist,
      allUnitsLoaded: true,
      resume: false,
    });
    this.allUnitsLoaded = true;
  }
  /* Get the playing video details
    set selected Unit Id and selectedVideoId
    update breadcrumbs data */
  getSelectedVideoIdSubscription() {
    this.playlistCompSubscriptions.getSelectedVideoIdSub = this.playlistService
      .getSelectedVideoId()
      .subscribe((data: any) => {
        //selectedVideoId = parseInt(selectedVideoId);

        if (data.id != 0) {
          this.selectedVideoId = data.id;
          this.canPlayFirstVideoInFirstUnit = false;
        }
        if (data.id != 0 && data.allUnitsLoaded) {
          this.completeVideosList.forEach((unit) => {
            unit.videos.forEach((video) => {
              if (video.VideoId == this.selectedVideoId) {
                let unitEntity = this.filterPipe.transform(this.units, {
                  property: 'Id',
                  flag: unit.unitId,
                })[0];
                this.selectedUnitId = unit.unitId;
                let videoEntity = video;
                let lpEntity = this.LPBreadcrumbs.LPDetails;
                let packageEntity = this.LPBreadcrumbs.PackageDetails;
                let courseEntity = this.LPBreadcrumbs.CourseDetails;
                //console.log("lp breadcrumbs update from getSelectedVideoIdSubscription");
                this.lpService.updateBreadcrumbs(
                  lpEntity,
                  packageEntity,
                  courseEntity,
                  unitEntity,
                  videoEntity
                );
              }
            });
          });
        }
        if (this.allUnitsDetails.length > 0) {
          
          this.videoPlayersService
            .fetchCompletionStatus(
              this.globals.MId,
              this.LPBreadcrumbs.CourseDetails.CourseId
            )
            .subscribe((completionStatus) => {
              this.multiContentCompletion = completionStatus;
              if (this.multiContentCompletion) {
                this.courseContents = [];
                // this.allUnitsDetails = this.allUnitsDetails.sort((a, b) => (a.Id - b.Id));
                let firstContentId = this.allUnitsDetails[0][0].Id;
                let lastContentCompletion;
                for (let i = 0; i < this.allUnitsDetails.length; i++) {
                  let unitContents;
                  if (
                    this.multiContentCompletion.ContentCompletionStatus !=
                    null &&
                    this.multiContentCompletion.ContentCompletionStatus
                    .length > 0
                    ) {
                    let nextContent = 0;
                    unitContents = this.allUnitsDetails[i].map((x, index) => {
                      const contents =
                        this.multiContentCompletion.ContentCompletionStatus.find(
                          (content) => content.Id == x.Id
                        );
                      if (contents) {
                        lastContentCompletion = contents;
                        nextContent++;
                      }
                      
                      return {
                        ContentId: x.Id,
                        Completion: contents ? true : false,
                        IsContentEnabled: (((contents || nextContent > 0) && nextContent >= index) || (i > 0 && (lastContentCompletion.Id == (this.allUnitsDetails[i - 1][(this.allUnitsDetails[i - 1].length) - 1].Id)) && index == 0) ? 1 : 0)
                      };
                    });
                  } else {
                    unitContents = this.allUnitsDetails[i].map((x, index) => {
                      return {
                        ContentId: x.Id,
                        Completion: false,
                        IsContentEnabled: firstContentId == x.Id ? 1 : 0,
                      };
                    });
                  }
                  this.courseContents[i] = unitContents;
                }
                
                // this.courseContents = this.courseContents.sort((a, b) => (a.ContentId - b.ContentId));
                if (
                  this.allUnitsDetails.length == this.courseContents.length
                ) {

                  this.videoPlayersService.updateCompletionStatus(
                    this.courseContents
                  );
                  setTimeout(() => {
                    this.videoPlayersService
                      .getCompletionStatus()
                      .subscribe((completionStatus) => {
                        this.courseContents = completionStatus;
                      });
                  }, 2000);
                  // this.videoPlayersService
                  //     .getCompletionStatus()
                  //     .subscribe((completionStatus) => {
                  //       this.courseContents = completionStatus;
                  //     });
                }
              }
            });
        }
      });
  }
  /* Toggle auto play button */
  togglePlayNextVideoFlag() {
    this.canPlayAll = !this.canPlayAll;
    this.playlistService.updateCanPlayAllVideosFlag(this.canPlayAll);
  }
  /* Get Resume Video Details */
  getResumeDetailsSubscription() {
    this.playlistCompSubscriptions.resumeDetailsSubscription =
      this.userDetailsStore
        .select(fromUserDetailsStore.getLPResumeDetails)
        .subscribe((resumeDetails) => {
          ////console.log('resume details update');
          this.resumeDetails = resumeDetails;
        });
  }
  navigateToVideoComp(videoId, source) {
    let sourceNav =
      source.toLowerCase() == 'youtube' ||
        source.toLowerCase() == 'o365' ||
        source.toLowerCase() == 'scorm'
        ? source
        : 'others';
    this.router.navigate([sourceNav.toLowerCase() + '/videoId/' + videoId], {
      relativeTo: this.activatedRoute,
    });
  }
  showQuizConfirmationDialogBox() {
    const dialogRef = this.dialog.open(
      AccountContentCompletionConfirmationComponent,
      {
        panelClass: 'popupDialogContainer',
        disableClose: true,
      }
    );
    this.playlistCompSubscriptions.quizDialogRefSub = dialogRef
      .afterClosed()
      .subscribe((confirmStatus) => {
        if (confirmStatus == true) {
          //navigate to quiz page
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
    this.resetAllServicesToInitialState();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.playlistCompSubscriptions) {
      let subscriber = this.playlistCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  resetAllServicesToInitialState() {
    this.videoPlayersService.updatePlaylist({
      playlist: [],
      allUnitsLoaded: false,
      resume: false,
    });
    this.playlistService.updateSelectedVideoId({
      source: null,
      id: 0,
      playlistLength: 0,
      allUnitsLoaded: false,
    });
    //this.videoPlayersService.updateVideoDetailsToCaptureProgress(null);
  }
  downloadCourseMaterial() {
    if (
      this.courseMatAvailable &&
      this.courseMaterial.CourseMaterialType == 'Internal'
    ) {
      this._systemSvc
        .getBlobSasKey(this.courseMaterial.CourseMaterialLink)
        .then((response: any) => {
          try {
            let decodedUri = this._systemSvc.getAtoBDecode(response);
            window.open(decodedUri, '_blank');
          } catch { }
        });
    } else {
      window.open(this.courseMaterial.CourseMaterialLink, '_blank');
    }
  }
}
