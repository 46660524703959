<div class="cnt" fxLayout="column" fxLayoutGap="10px">

  <div fxLayout="row"  fxLayoutAlign="end center" class="filter">
    <div *ngFor="let item of ['all','101','201','301']" class="filter-tag"
      [class.selected-filter-tag]="selectedFilter === item" (click)="clickSelectedFilter(item)">
      <label>{{item}}</label>
    </div>
    <img class="reload-icon" (click)="clickSelectedFilter('all')"
      src="../../../../assets/images/DandI/ic-reload.png" alt="">
  </div>
  
  <div  fxLayout="column"  class="course-container"  fxLayoutGap="22px">
  <div fxLayout="column" *ngIf="dataList.length>0" fxLayoutGap="22px">
    <div *ngFor="let item of dataList" style="width: 100%;">
     
         <yorbit-course-progress-tile [item]="item"></yorbit-course-progress-tile>
    </div>
  </div>
  
  <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isDataLoading==true">
    <app-preloader></app-preloader>
  </div>

  <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="!isDataLoading && dataList.length==0">
    <span  class="no-data-found">
        Course progress is not available.
    </span>
  </div>
  </div>
</div>

