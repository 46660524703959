<mat-dialog-content class="videoPopupInfo">
  <div class="close">
    <button mat-button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <!-- message -->
  <div class="message">
    <div class="note">
      To track progress and earn badge, add course to learning path.
    </div>
    <div class="note">
      If you continue watching it here, no progress will be captured.
    </div>
    <mat-divider></mat-divider>
    <div class="note" style="color:red;margin-bottom: 0;">
      Otherwise, after taking course you have to watch the same {{textMsg}} again to
      capture the progress
    </div>
  </div>
  <mat-divider></mat-divider>
  <div style="text-align: center">
    <button class="okButton mousePointer" (click)="close()" *ngIf="addToLP==false">
      OK
    </button>
    <button class="okButton mousePointer" (click)="addToLp()" *ngIf="addToLP==true">
      Add to LP
    </button>
  </div>
  <mat-divider></mat-divider>
</mat-dialog-content>
