import { Component, OnInit, Input, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { SmeService } from '../../sme/sme.service';
import { SMEEvaluationDetails } from '../../sme/sme.model';
import { UploadSmeRequestInfoPopupComponent } from '../../sme/upload-sme-request-info-popup/upload-sme-request-info-popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Globals } from '../../globals';
import {MatRippleModule } from '@angular/material/core';
// import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';


@Component({
  selector: 'yorbit-webform',
  templateUrl: './webform.component.html',
  styleUrls: ['./webform.component.scss'],
  providers: [
    // {
    // provide: STEPPER_GLOBAL_OPTIONS, 
    // useValue: { displayDefaultIndicatorType: false }
    // },
    DatePipe]
})

export class WebformComponent implements OnInit {
  isInProgress: boolean = false;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  participantsDetails: FormGroup;
  skillEvaluationDetails: FormGroup;
  interviewDetails: FormGroup;
  evaluationParameters: FormGroup;
  finalEvaluation: FormGroup;
  smeData : SMEEvaluationDetails = new SMEEvaluationDetails();
  step = 0;
  steps: boolean[];
   @Input('formData') formData;
   @Input('learnerDetails') learnerDetails;
   @ViewChild('stepper') private stepper : MatStepper;
   interviewDate : any;
   saveLabel : string = "Save as Draft";
   submitLabel : string = "Submit";
   isFormSubmitted : boolean = false;
   participantsDetailsFilled     : boolean = false;
   skillEvaluationDetailsFilled  : boolean = false;
   interviewDetailsFilled        : boolean = false;
   evaluationParametersFilled    : boolean = false;
   finalEvaluationFilled         : boolean = false;
   maxDate : string;
   skillEvaluationErrorFlag:boolean=false;
   interviewDetailsErrorFlag:boolean=false;
   evaluationParametersErrorFlag:boolean=false;
   finalEvaluationErrorFlag:boolean=false;
   averageScoreAutoPopulate:number=0;
   num:any;
   showScoreChangeReason:boolean=false;
   emptyFields : any = [];
   errorFields : any = [];
   fieldValidationErrMsg : string = "";
   errFieldsWorkExp : any = [];
	errFieldsProc : any = [];
	errFieldsSolutioning : any = [];
	errFieldsSituationalQues : any = [];
	errFieldsCert : any = [];
  errFieldsWorkExpMsg : string = "";
  errFieldsProcMsg: string = "";
  errFieldsSituationalQuesMsg : string = "";
  errFieldsSolutioningMsg : string = "";
  errFieldsCertMsg: string = "";
  smeSavedScore:string="";
  errorMessage: boolean=false;
  panelistEmails : any = [];

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private _smeService: SmeService,
    private _popup: MatDialog,
    private datePipe: DatePipe,
    private globals : Globals,
    private cdr: ChangeDetectorRef
  ) {
    this.steps = [false, false, false, false, false];
  }

  ngOnInit() {
    const today = new Date();
    this.maxDate = today.toISOString().split('T')[0];

    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required]
    });

    this.participantsDetails = this.fb.group({
      associateName: [''],
      associatePsId: [''],
      grade: [''],
      managerName: [''],
      practiceUnit: ['']
    });
    this.skillEvaluationDetails = this.fb.group({
      leafSkill: [''],
      currentProficiency: [''],
      requiredProficiency: [''],
     // skillGap: [''],
      //interviewProficiency: [''],
    //  gapYes: [''],
      //gapNo: [''],
     // proficiencyDefinitions: [''],
      yearsExperience: [''],
      monthsExperience: ['']
    });
    this.interviewDetails = this.fb.group({
      interviewDate: [''],
      format: [''],
      panelistsName: [''],
     // ps1: [''],
     // ps2: [''],
      otherDetails: ['']
    });
    this.evaluationParameters = this.fb.group({
      workExperience: [''],
      workExperienceScore: ['0'],
      workExperienceSpike: [''],
      workExperienceGaps: [''],

      tooling: [''],
      toolingScore: ['0'],
      toolingSpike: [''],
      toolingGaps: [''],

      procedures: [''],
      proceduresScore: ['0'],
      proceduresSpike: [''],
      proceduresGaps: [''],

      solutioning: [''],
      solutioningScore: ['0'],
      solutioningSpike: [''],
      solutioningGaps: [''],

      situationalQuestions: [''],
      situationalScore: ['0'],
      situationalSpike: [''],
      situationalGaps: [''],

      certificationsScore: [''],
      certificationsScoreSelect: ['0'],
      certificationsSpike: [''],
      certificationsGaps: [''],
    });
    this.finalEvaluation = this.fb.group({
      proficiencyMet: [''],
      averageScore: [''],
      keyReasons: [''],
      finalStatus : [''],
      smeScore:['',[this.numberValidator]],
      scoreChangeReasons:['']
    });


    console.log(this.formData);
    console.log(this.learnerDetails);
    this.smeData.RequestId = this.formData.RequestId;
    this.smeData.CourseId = this.formData.CourseId;

    // Format the interviewDate if it exists
    if (this.interviewDate) {
      this.interviewDate = this.datePipe.transform(this.interviewDate, 'yyyy-MM-dd');
    }
    // this.route.params.subscribe(params => {
    //   console.log(params['requestId']); // Access the query parameter by key
    //   this._smeService.getSmeRequest(params['requestId']).subscribe(result => {
    //     console.log(result);
    //     if(result){
    //       this.smeData.RequestId = result.RequestId;
    //       console.log(this.smeData.RequestId);
    //     }
    //   });
    // });
    this._smeService.getEvaluationDetails(this.smeData.RequestId, this.formData.Attempt).subscribe(response => {
      console.log(response);
      if(response)
      {
        this.populateDataInWebForm(response);
        if(response.IsFormSubmitted){
          this.isFormSubmitted = true;
          this.participantsDetails.disable(); 
          this.skillEvaluationDetails.disable();
          this.interviewDetails.disable();
          this.evaluationParameters.disable();
          this.finalEvaluation.disable();
        }else{
          this.isFormSubmitted = false;   
        }
      }
      else{
        this.loadDetailsForFirstTimeSave();
      }
      this.smeSavedScore = response.SMEScore;
      console.log(this.smeSavedScore);
    });

    console.log(this.smeSavedScore);
    this.finalEvaluation.controls.smeScore.valueChanges.subscribe((val) => {
      if(val>=1 && val<=4){
        this.errorMessage = false;       
      }
      else{
        this.errorMessage = true;
      }
      console.log(this.errorMessage);
      this.changeFinalStatus(val);
    });
    this.initializeEventListeners();
   
  }

  setStep(index: number) {
    this.step = index;
  }

  goToStep(stepIndex){
    this.resetSteps(this.stepper);
    this.stepper.selectedIndex = stepIndex;
    this.resetErrVariables();
     /*
    let isFormValid = this.validateForms();   
    let page = ""
    switch(stepIndex){
      case 0:
        page = "participantDetails";
        break;
      case 1:
         page = "skillEvaluation";
         if (!this.skillEvaluationDetailsFilled) {
          this.skillEvaluationErrorFlag = true;
          console.log("skill eval: "+this.skillEvaluationErrorFlag);   
          this.checkForms(page);  
                
        }  else{
          this.skillEvaluationErrorFlag = false;
        }     
        break;
        case 2:
          page = "interviewDetails";
          if (!this.interviewDetailsFilled) {
            this.interviewDetailsErrorFlag=true;
            console.log("interview: "+this.interviewDetailsErrorFlag);
            this.checkForms(page);
             
           } else{
            this.interviewDetailsErrorFlag = false;
           }
          break;
          case 3:
            page = "evaluationParams";
            if (!this.evaluationParametersFilled) {
              this.evaluationParametersErrorFlag=true;
              console.log("eval param: "+this.evaluationParametersErrorFlag);
              this.checkForms(page);
             }
             else{
              this.evaluationParametersErrorFlag = false;
             }
            break;
            case 4:
              page = "finalEvaluation";
              if (!this.finalEvaluationFilled) {
                this.finalEvaluationErrorFlag=true;
                console.log("final eval: "+this.finalEvaluationErrorFlag);
                this.checkForms(page);
               }
               else{
                this.finalEvaluationErrorFlag = false;
               }
              break;
    }
    */
    //this.stepper.steps.toArray()[stepIndex].completed = true;
  }

  nextStep(stepper: MatStepper, page:string) {
   let isFormValid = this.validateForms();
    if(page == "participantDetails"){
      this.resetSteps(stepper);
        stepper.next();
    }
    if(page == "skillEvaluation"){
      if (!this.skillEvaluationDetailsFilled) {
        this.skillEvaluationErrorFlag = true;
        console.log("skill eval: "+this.skillEvaluationErrorFlag);   
        this.checkForms(page);  
              
      }
      else{
        this.emptyFields = [];
        this.resetSteps(stepper);
        stepper.next();
      }
    }
    if(page == "interviewDetails"){
      if (!this.interviewDetailsFilled) {
        this.interviewDetailsErrorFlag=true;
        console.log("intervie: "+this.interviewDetailsErrorFlag);
        this.checkForms(page);
         
       }
       else{
        this.emptyFields = [];
        this.resetSteps(stepper);
        stepper.next();
      }
    }
    if(page == "evaluationParams"){
      if (!this.evaluationParametersFilled) {
        this.evaluationParametersErrorFlag=true;
        console.log("eval param: "+this.evaluationParametersErrorFlag);
        this.checkForms(page);
       }
       else{
        this.emptyFields = [];
         this.resetSteps(stepper);
         stepper.next();
       }
    }
    if(page == "finalEvaluation"){
      console.log(this.errorMessage);
      if (!this.finalEvaluationFilled || this.errorMessage) {
        this.finalEvaluationErrorFlag=true;
        console.log("final eval: "+this.finalEvaluationErrorFlag);
        this.checkForms(page);
       }
       else{
        this.emptyFields = [];
        //  this.resetSteps(stepper);
        //  stepper.next();
        this.openpopup(isFormValid, "submit");  
       }
    }
     
  //       // debugger;   
  //   console.log(stepper.selectedIndex);
  //   this.resetSteps(stepper);
  // //  stepper.steps.toArray()[stepper.selectedIndex].completed = true;
  //   stepper.next();    
  }

  prevStep(stepper: MatStepper) {
    this.resetSteps(stepper);
  //  stepper.steps.toArray()[stepper.selectedIndex].completed = true;
    stepper.previous();
  }

  resetSteps(stepper: MatStepper) {
    for (let i = 0; i < stepper.steps.length; i++) {
      stepper.steps.toArray()[i].completed = false;      
    }
  }

  save(isSubmitted) {
    this.smeData = {
      ...this.participantsDetails.getRawValue(),
      ...this.skillEvaluationDetails.getRawValue(),
      ...this.interviewDetails.getRawValue(),
      ...this.evaluationParameters.getRawValue(),
      ...this.finalEvaluation.getRawValue(),
      RequestId: this.smeData.RequestId,
      Attempt : this.formData.Attempt,      
      CourseId : this.formData.CourseId,
      // DateOfInterview: this.datePipe.transform(this.interviewDetails.get('interviewDate')?.value, 'yyyy-MM-dd'),
      interviewDate : this.interviewDetails.get('interviewDate').value ? (this.datePipe.transform(this.interviewDetails.get('interviewDate')?.value, 'yyyy-MM-dd')) : 
      null
    };
    console.log(this.smeData);
    this.isInProgress = true;
    if(!isSubmitted){
      this.saveLabel = "Saving...";
    }
    else if(isSubmitted){
      this.submitLabel = "Submitting...";
    }
    else{
      this.saveLabel = "Save as Draft";
    }
    this._smeService
    .insertSMEEvaluationDetails(this.smeData, isSubmitted)
    .subscribe(response => {
      if(isSubmitted){     
      this._smeService.uploadSme(this.smeData).subscribe(response => {
      this.isInProgress = false;
      this.saveLabel = "Save as Draft";
      this.submitLabel = "Submit";
      this.isFormSubmitted  = true;
      this.openInfoPopup("Data is submitted successfully");
      console.log(response);
      });
    }
    else{
      this.isInProgress = false;
      this.saveLabel = "Save as Draft";
      this.submitLabel = "Submit";
      this.isFormSubmitted  = false;
      this.openInfoPopup("Data is saved successfully");
    }      
    },
    (error: any) => {
      console.log(error);
      this.isInProgress = false;
      this.isFormSubmitted = false;
      this.saveLabel =  "Save as Draft";
      this.submitLabel = "Submit";
      this.openInfoPopup("Somethig went wrong. Please try again");
    });    
  }

  submit(stepper, page) {  
   // let isFormValid = this.validateForms();  
    //this.openpopup(isFormValid, "submit");   

  this.nextStep(stepper, page);

   
  }

  populateDataInWebForm(response: any) {
    this.participantsDetails.get('associateName').setValue(response.AssociateName);
    this.participantsDetails.get('associatePsId').setValue(response.AssociatePSID);
    this.participantsDetails.get('grade').setValue(response.Grade);
    this.participantsDetails.get('managerName').setValue(response.IRMName);
    this.participantsDetails.get('practiceUnit').setValue(response.BUDescription);
    this.participantsDetails.get('associateName').disable();
    this.participantsDetails.get('associatePsId').disable();
    this.participantsDetails.get('grade').disable();
    this.participantsDetails.get('managerName').disable();
    this.participantsDetails.get('practiceUnit').disable();

    this.skillEvaluationDetails.get('leafSkill').setValue(response.LeafSkill);
    this.getSMEPanelist(response.LeafSkill);
    this.skillEvaluationDetails.get('currentProficiency').setValue(response.CurrentProficiency);
    this.skillEvaluationDetails.get('requiredProficiency').setValue(response.RequiredProficiency);
    this.skillEvaluationDetails.get('leafSkill').disable();
    //this.skillEvaluationDetails.get('currentProficiency').disable();

    //this.skillEvaluationDetails.get('skillGap').setValue(response.SkillGap);
    //this.skillEvaluationDetails.get('interviewProficiency').setValue(response.InterviewProficiency);
    //this.skillEvaluationDetails.get('gapYes').setValue(response.GapYes);
    //this.skillEvaluationDetails.get('gapNo').setValue(response.GapNo);
    this.skillEvaluationDetails.get('yearsExperience').setValue(response.YearsOfExperience);
    this.skillEvaluationDetails.get('monthsExperience').setValue(response.MonthsOfExperience);

    //this.interviewDetails.get('panelistsName').setValue(response.InterviewPanelists ? response.InterviewPanelists  : this.globals.MId);
    //this.interviewDetails.get('panelistsName').disable();
    if("0001-01-01T00:00:00" == response.DateOfInterview){
      this.interviewDetails.get('interviewDate').value?.reset();
    }else{
      this.interviewDetails.get('interviewDate').setValue(response.DateOfInterview ? this.datePipe.transform(response.DateOfInterview, 'yyyy-MM-dd') : this.interviewDetails.get('interviewDate').value?.reset());
    }
    // this.interviewDate = this.datePipe.transform(response.DateOfInterview, 'yyyy-MM-dd'),
    this.interviewDetails.get('format').setValue(response.InterviewFormat);
    //this.interviewDetails.get('ps1').setValue(response.PS1);
    //this.interviewDetails.get('ps2').setValue(response.PS2);
    this.interviewDetails.get('otherDetails').setValue(response.InterviewOtherDetails);

    this.evaluationParameters.get('workExperience').setValue(response.WorkExperienceDescription);
    this.evaluationParameters.get('workExperienceScore').setValue(response.WorkExperienceScore);
    this.evaluationParameters.get('workExperienceSpike').setValue(response.WorkExperienceKeySpike);
    this.evaluationParameters.get('workExperienceGaps').setValue(response.WorkExperienceGaps);

    this.evaluationParameters.get('tooling').setValue(response.ToolingDescription);
    this.evaluationParameters.get('toolingScore').setValue(response.ToolingScore);
    this.evaluationParameters.get('toolingSpike').setValue(response.ToolingKeySpike);
    this.evaluationParameters.get('toolingGaps').setValue(response.ToolingGaps);

    this.evaluationParameters.get('procedures').setValue(response.ProcedureMethodologyDescription);
    this.evaluationParameters.get('proceduresScore').setValue(response.ProcedureMethodologyScore);
    this.evaluationParameters.get('proceduresSpike').setValue(response.ProcedureMethodologyKeySpike);
    this.evaluationParameters.get('proceduresGaps').setValue(response.ProcedureMethodologyGaps);

    this.evaluationParameters.get('solutioning').setValue(response.SolutioningDescription);
    this.evaluationParameters.get('solutioningScore').setValue(response.SolutioningScore);
    this.evaluationParameters.get('solutioningSpike').setValue(response.SolutioningKeySpike);
    this.evaluationParameters.get('solutioningGaps').setValue(response.SolutioningGaps);

    this.evaluationParameters.get('situationalQuestions').setValue(response.SituationalQuestionsDescription);
    this.evaluationParameters.get('situationalScore').setValue(response.SituationalQuestionsScore);
    this.evaluationParameters.get('situationalSpike').setValue(response.SituationalQuestionsKeySpike);
    this.evaluationParameters.get('situationalGaps').setValue(response.SituationalQuestionsGaps);

    this.evaluationParameters.get('certificationsScore').setValue(response.CertificateScoreDescription);
    this.evaluationParameters.get('certificationsScoreSelect').setValue(response.CertificateScoreScore);
    this.evaluationParameters.get('certificationsSpike').setValue(response.CertificateScoreKeySpike);
    this.evaluationParameters.get('certificationsGaps').setValue(response.CertificateScoreGaps);

    this.finalEvaluation.get('proficiencyMet').setValue(response.AssociateProficiencyMeet);

    //this.findAverageScore(this.evaluationParameters.get('workExperienceScore').value, this.evaluationParameters.get('toolingScore').value, this.evaluationParameters.get('proceduresScore').value, this.evaluationParameters.get('solutioningScore').value,this.evaluationParameters.get('situationalScore').value,  this.evaluationParameters.get('certificationsScoreSelect').value) 
    this.finalEvaluation.get('keyReasons').setValue(response.KeyReason);
    if(response.SMEScore != null && response.SMEScore !=""){
      this.finalEvaluation.get('smeScore').setValue(response.SMEScore);
    }
    else{
      this.finalEvaluation.get('smeScore').setValue(this.num);
    } 
    //this.finalEvaluation.get('smeScore').setValue(response.SMEScore); 
    this.finalEvaluation.get('scoreChangeReasons').setValue(response.ScoreChangeReasons); 
    console.log(this.averageScoreAutoPopulate);
    this.changeFinalStatus(this.finalEvaluation.get('smeScore').value);
 
    // this.finalEvaluation.get('finalStatus').setValue(response.Status);  
    
    // if(response.IsFormSubmitted){
    //   this.disableCompleteWebForm();
    // }
  }

  disableCompleteWebForm(){
    this.participantsDetails.disable();
    this.skillEvaluationDetails.disable();
    this.interviewDetails.disable();
    this.evaluationParameters.disable();
    this.finalEvaluation.disable();
  }

  loadDetailsForFirstTimeSave(){
    this.participantsDetails.get('associateName').setValue(this.learnerDetails.associateName);
    this.participantsDetails.get('associatePsId').setValue(this.learnerDetails.associatePSID);
    this.participantsDetails.get('grade').setValue(this.learnerDetails.associateGrade);
    this.participantsDetails.get('managerName').setValue(this.learnerDetails.associateRM);
    this.participantsDetails.get('practiceUnit').setValue(this.learnerDetails.practice);
    this.participantsDetails.disable();

    this.skillEvaluationDetails.get('leafSkill').setValue(this.learnerDetails.leafSkill);
    // this.skillEvaluationDetails.get('currentProficiency').setValue(this.learnerDetails.currentProficiency);
    this.skillEvaluationDetails.get('leafSkill').disable();
    //this.skillEvaluationDetails.get('currentProficiency').disable();

    //this.interviewDetails.get('panelistsName').setValue(this.globals.MId);
    this.getSMEPanelist( this.skillEvaluationDetails.get('leafSkill').value);
    this.interviewDetails.get('panelistsName').disable();   
  }

  // Method to check if all form controls have values
  areAllControlsFilled(formGroup: FormGroup): boolean {
    let allFilled = true;
    var optionalControls = ['otherDetails', 'workExperienceSpike','workExperienceGaps','tooling','toolingScore','toolingSpike','toolingGaps','proceduresSpike','proceduresGaps','solutioningSpike','solutioningGaps','situationalSpike','situationalGaps','certificationsSpike','certificationsGaps','certificationsScore','certificationsScoreSelect']
    if(this.finalEvaluation.get('smeScore').value == this.finalEvaluation.get('averageScore').value){
      optionalControls.push('scoreChangeReasons');
    }

    Object.keys(formGroup.controls).forEach(key => {
      if (!optionalControls.includes(key) && 
      (!formGroup.controls[key].value || formGroup.controls[key].value == "" || 
        ( (key != "yearsExperience" && key != "monthsExperience") && formGroup.controls[key].value == "0"))) {
          if((key == "yearsExperience" || key == "monthsExperience") && formGroup.controls[key].value === 0){

          }else{
        
        allFilled = false;
          }
      }
    });
    return allFilled;
  }

  validateForms(): boolean {
    this.participantsDetailsFilled = this.areAllControlsFilled(this.participantsDetails);
    this.skillEvaluationDetailsFilled = this.areAllControlsFilled(this.skillEvaluationDetails);
    this.interviewDetailsFilled = this.areAllControlsFilled(this.interviewDetails);
    this.evaluationParametersFilled = this.areAllControlsFilled(this.evaluationParameters);
    this.finalEvaluationFilled = this.areAllControlsFilled(this.finalEvaluation);

    //validation
    if(this.skillEvaluationDetailsFilled){
      this.skillEvaluationErrorFlag = false;
    }
    if(this.interviewDetailsFilled){
      this.interviewDetailsErrorFlag = false;
    }
    if(this.evaluationParametersFilled){
      this.evaluationParametersErrorFlag=false;
    }
    if(this.finalEvaluationFilled){
      this.finalEvaluationErrorFlag=false;
    }

    if (this.participantsDetailsFilled && this.skillEvaluationDetailsFilled && this.interviewDetailsFilled && this.evaluationParametersFilled && this.finalEvaluationFilled) {
      if(this.interviewDetails.get('interviewDate').value == '' || this.interviewDetails.get('interviewDate').value == null || this.interviewDetails.get('interviewDate').value == undefined || this.interviewDetails.get('interviewDate').value == '0001-01-01'){
        this.interviewDetails.get('interviewDate').reset();
        console.log('All controls are filled');
      return false;
      }
      return true;
    } else {
      console.log('Some controls are empty');
      return false;
    }
  }

   openpopup(isFormValid, action) {          
     let popupMsg = '';
     if(isFormValid && action == "submit"){
      popupMsg = "You are about to submit the Evaluation Form. <br/> Please note that the details cannot be edited once submitted. <br/> Are you sure you want to submit the Form?";
     }
     else if(action == "submit"){
      let errorTabs = "";
       if (!this.participantsDetailsFilled) {
         let tabName = "Participants Details";
         errorTabs = errorTabs == "" ? (errorTabs + tabName) : errorTabs + ", " + tabName;
       }
       if (!this.skillEvaluationDetailsFilled) {
         let tabName = "Skill Evaluation Details";
         errorTabs = errorTabs == "" ? (errorTabs + tabName) : errorTabs + ", " + tabName;
       }
       if (!this.interviewDetailsFilled) {
         let tabName = "Interview Details";
         errorTabs = errorTabs == "" ? (errorTabs + tabName) : errorTabs + ", " + tabName;
       }
       if (!this.evaluationParametersFilled) {
         let tabName = "Parameters";
         errorTabs = errorTabs == "" ? (errorTabs + tabName) : errorTabs + ", " + tabName;
       }
       if (!this.finalEvaluationFilled) {
         let tabName = "Final Evaluation";
         errorTabs = errorTabs == "" ? (errorTabs + tabName) : errorTabs + ", " + tabName;
       }       
       
      popupMsg = "Few fields are empty in " + errorTabs  + " tab(s)." + "<br/> Please fill all the required fields to submit the Evaluation Form.";
     }
     else{
      if(action != "submit" && !isFormValid){
        let errorFields = "";      
        popupMsg = "Following fields are not filled. Please fill all the mandatory fields to proceed. <br/>" ;
        }
        popupMsg += this.emptyFields.join(', ');
      }
     
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = {
        popUpMsg: popupMsg,
        isFormValid : isFormValid     
        
      };
      const response = this._popup.open(
        UploadSmeRequestInfoPopupComponent,
        dialogConfig
      );
      response.afterClosed().subscribe(res => {
     
       if(isFormValid && res == true){
        this.save(true);
       }
       else if(isFormValid && res == false){
        console.log("User didn't confirm the submission");
       }
       else if(!isFormValid){
        console.log("User didn't fill all the mandatory fields");
       }
      });
    }

    openInfoPopup(isSuccess){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = {
        popUpMsg: isSuccess ? "Data is saved successfully." : "Somethig went wrong. Please try again.",
        isFormValid : false ,
        isInfo : true   
        
      };
      const response = this._popup.open(
        UploadSmeRequestInfoPopupComponent,
        dialogConfig
      );      
    }  

    checkResultLogic() : boolean{
      if(this.finalEvaluation.get('averageScore').value < 3 && this.finalEvaluation.get('finalStatus').value == 'Not Cleared' || this.finalEvaluation.get('averageScore').value >= 3 && this.finalEvaluation.get('finalStatus').value == 'Pass'){
        return true;
      }
      return false;
    }
    public date: Date;

    clearDate(event) {
     event.stopPropagation();
     this.interviewDetails.get('interviewDate').setValue(null)
   }

   changeFinalStatus(val) {
    if(val <2.8) {
      this.finalEvaluation.controls.finalStatus.setValue('Not Clear');
    } else {
      this.finalEvaluation.controls.finalStatus.setValue('Pass');
    }
   }

   findAverageScore(workExperienceScore, toolingScore,proceduresScore,solutioningScore,situationalScore,certificationsScoreSelect) {
    if(toolingScore !="0" && certificationsScoreSelect !="0"){
      this.averageScoreAutoPopulate = (parseInt(workExperienceScore, 10) + parseInt(toolingScore, 10)+ parseInt(proceduresScore, 10) + parseInt(solutioningScore, 10) +parseInt(situationalScore, 10)+ parseInt(certificationsScoreSelect, 10))/6;
    }
    else if(toolingScore =="0" && certificationsScoreSelect !="0"){
      this.averageScoreAutoPopulate = (parseInt(workExperienceScore, 10) + parseInt(proceduresScore, 10) + parseInt(solutioningScore, 10) +parseInt(situationalScore, 10)+ parseInt(certificationsScoreSelect, 10))/5;
    }
    else if(toolingScore !="0" && certificationsScoreSelect =="0"){
      this.averageScoreAutoPopulate = (parseInt(workExperienceScore, 10) + parseInt(proceduresScore, 10) + parseInt(solutioningScore, 10) +parseInt(situationalScore, 10)+ parseInt(toolingScore, 10))/5;
    }
    else{
      this.averageScoreAutoPopulate = (parseInt(workExperienceScore, 10) + parseInt(proceduresScore, 10) + parseInt(solutioningScore, 10) +parseInt(situationalScore, 10))/4;
      //console.log(this.averageScoreAutoPopulate);
    }

    this.num = Number.isInteger(this.averageScoreAutoPopulate) ? this.averageScoreAutoPopulate : this.averageScoreAutoPopulate.toFixed(2)
    this.finalEvaluation.controls.averageScore.setValue(this.num);
    if(!this.isFormSubmitted)
    {
    this.finalEvaluation.controls.smeScore.setValue(this.num);
    }
    //this.avgScore = this.num;
   }

   getSMEPanelist(skill){
    this._smeService.getSMEPanelist(this.smeData.RequestId.toString()).subscribe(response => {
      console.log(response);
      var midlist = [];
      response.forEach(element => {
        if(element && element.MID){
        midlist.push(element.MID);
        this.panelistEmails.push({'MID' : element.MID, 'EmailID': element.EmailID});  
        }   
      });
      // var list = response.join(', ');

      var list = midlist.join(', ');
      this.interviewDetails.get('panelistsName').setValue(list);
      this.interviewDetails.get('panelistsName').disable();
    })
   }

    // Method to get a list of empty fields in a form group
  getEmptyFields(formGroup: FormGroup): string[] {
    const emptyFields: string[] = [];
    const optionalControls = ['otherDetails', 'workExperienceSpike', 'workExperienceGaps', 'tooling', 'toolingScore', 'toolingSpike', 'toolingGaps', 'proceduresSpike', 'proceduresGaps', 'solutioningSpike', 'solutioningGaps', 'situationalSpike', 'situationalGaps', 'certificationsSpike', 'certificationsGaps','certificationsScoreSelect','certificationsScoreSelect']; // Add the controls you want to exclude here

    Object.keys(formGroup.controls).forEach(key => {
      if (!optionalControls.includes(key) && (!formGroup.controls[key].value || (formGroup.controls[key].value == "" || formGroup.controls[key].value == null) || ((key != "yearsExperience" && key != "monthsExperience") && formGroup.controls[key].value == "0"))) {
        console.log(key , formGroup.controls[key].value );
        if((key == "yearsExperience" || key == "monthsExperience") && formGroup.controls[key].value === 0){
          //valid
        }
        else{
          emptyFields.push(key);
        }
      }
    });
    if(this.errorMessage && !emptyFields.includes('smeScore')){
      emptyFields.push('smeScore');
    }
    return emptyFields;   
  }

  checkForms(page) {
    let emptyFields = [];
    this.errorFields = [];
    this.fieldValidationErrMsg = "";
    if(page == "participantDetails"){
       emptyFields = this.getEmptyFields(this.participantsDetails);      
    }
   else if(page == "skillEvaluation"){      
     emptyFields = this.getEmptyFields(this.skillEvaluationDetails);   
     if(emptyFields.length > 0 ){
      this.fieldValidationErrMsg = "Please fill following mandatory fields to proceed. ";
      emptyFields.forEach(field=>{
        if(field == "currentProficiency")
        {
          this.errorFields.push("Current Level of Proficiency");
        }
        if(field == "requiredProficiency"){
          this.errorFields.push("Proficiency Level Required");
        }
        if(field == "yearsExperience"){
          if(this.skillEvaluationDetails.get('yearsExperience').value == null || this.skillEvaluationDetails.get('yearsExperience').value == "" ){
          this.errorFields.push("Years");
        }
      }
        if(field == "monthsExperience"){
          if(this.skillEvaluationDetails.get('monthsExperience').value == null || this.skillEvaluationDetails.get('monthsExperience').value == ""){
          this.errorFields.push("Months");
          }
        }
      });
      this.fieldValidationErrMsg += this.errorFields.join(', ');
     } 
  }
 else if(page == "interviewDetails" ){
     emptyFields = this.getEmptyFields(this.interviewDetails);
     if(emptyFields.length > 0 ){
      this.fieldValidationErrMsg = "Please fill following mandatory fields to proceed. ";
      emptyFields.forEach(field=>{
        if(field == "interviewDate")
        {
          this.errorFields.push("Date of Interview");
        }
        if(field == "format"){
          this.errorFields.push("Format");
        }       
      });
      this.fieldValidationErrMsg += this.errorFields.join(', ');
     }
  }
  else if(page == "evaluationParams"){
    this.errFieldsWorkExp = [];
    this.errFieldsProc = [];
    this.errFieldsSolutioning = [];
    this.errFieldsSituationalQues = [];
    this.errFieldsCert = [];

     emptyFields = this.getEmptyFields(this.evaluationParameters);
     if(emptyFields.length > 0 ){
      this.fieldValidationErrMsg = "Please fill following mandatory fields to proceed. ";
      emptyFields.forEach(field=>{
        if(field == "workExperience")
        {
          this.errorFields.push("Work Experience");
          this.errFieldsWorkExp.push("Work Experience");
        }
        if(field == "workExperienceScore"){
          this.errorFields.push("Score");
          this.errFieldsWorkExp.push("Work Experience Score");
        }   
        if(field == "procedures")
          {
            this.errorFields.push("Procedures/Methodologies");
            this.errFieldsProc.push("Procedures/Methodologies");
          }
          if(field == "proceduresScore"){
            this.errorFields.push("Score");
            this.errFieldsProc.push("Procedures/Methodologies Score");
          }  
          if(field == "solutioning")
            {
              this.errorFields.push("Solutioning");
              this.errFieldsSolutioning.push("Solutioning");
            }
            if(field == "solutioningScore"){
              this.errorFields.push("Score");
              this.errFieldsSolutioning.push("Solutioning Score");
            }  
            if(field == "situationalQuestions")
              {
                this.errorFields.push("Situational Questions");
                this.errFieldsSituationalQues.push("Situational Questions");
              }
              if(field == "situationalScore"){
                this.errorFields.push("Score");
                this.errFieldsSituationalQues.push("situational Questions Score");
              }  
              // if(field == "certificationsScore")
              //   {
              //     this.errorFields.push("Certifications Score");
              //     this.errFieldsCert.push("Certifications Score Description");
              //   }
              //   if(field == "certificationsScoreSelect"){
              //     this.errorFields.push("Score");
              //     this.errFieldsCert.push("Certifications Score");
              //   }    
      });
      this.fieldValidationErrMsg += this.errorFields.join(', ');
      let err = "Please fill the following mandatory fields to proceed. ";
      this.errFieldsWorkExpMsg = err + this.errFieldsWorkExp.join(', ');
      this.errFieldsProcMsg = err + this.errFieldsProc.join(', ');
      this.errFieldsSituationalQuesMsg = err + this.errFieldsSituationalQues.join(', ');
      this.errFieldsSolutioningMsg = err + this.errFieldsSolutioning.join(', ');
      //this.errFieldsCertMsg = err + this.errFieldsCert.join(', ');
      
     }
  }
  else if(page == "finalEvaluation"){
     emptyFields = this.getEmptyFields(this.finalEvaluation);
     if(emptyFields.length > 0 ){
      this.fieldValidationErrMsg = "Please fill following mandatory fields to proceed. ";
      emptyFields.forEach(field=>{
        if(field == "proficiencyMet")
        {
          this.errorFields.push("Proficiency Definition");
        }
        if(field == "keyReasons"){
          this.errorFields.push("Key Reasons");
        }  
        if(field == "smeScore"){
          this.errorFields.push("Overall SME Score");
        }
        if(field == "scoreChangeReasons"){
          if(this.showScoreChangeReason){
          this.errorFields.push("Reasons for Providing Overall SME Score");
          }
        }
      });
      this.fieldValidationErrMsg += this.errorFields.join(', ');
     }
  }
  else{
     emptyFields = [];
  }

  if (emptyFields.length > 0) {
    console.log('Empty fields:', emptyFields);
    this.emptyFields = emptyFields;
    
   // this.openpopup(false, "next");
  } else {
    console.log('All fields are filled');
  }
  }

  isWorkExp(){    
    if(this.emptyFields.includes("workExperience") || this.emptyFields.includes("workExperienceScore")){
      return true;
    }
  }
  isProc(){
    if(this.emptyFields.includes("procedures") || this.emptyFields.includes("proceduresScore")){
      return true;
    }
  }

  isSolution(){
    if(this.emptyFields.includes("solutioning") || this.emptyFields.includes("solutioningScore")){
      return true;
    }
  }
  isSituational(){
    if(this.emptyFields.includes("situationalQuestions") || this.emptyFields.includes("situationalScore")){
      return true;
    }
  }
  // isCert(){
  //   if(this.emptyFields.includes("certificationsScore") || this.emptyFields.includes("certificationsScoreSelect")){
  //     return true;
  //   }
  // }

  resetErrVariables(){
    this.skillEvaluationErrorFlag = false;
    this.interviewDetailsErrorFlag = false;
    this.evaluationParametersErrorFlag = false;
    this.finalEvaluationErrorFlag = false;    
    this.fieldValidationErrMsg = "";
    this.errFieldsWorkExpMsg          = "";
    this.errFieldsProcMsg             = "";
    this.errFieldsSituationalQuesMsg  = "";
    this.errFieldsSolutioningMsg      = "";
    //this.errFieldsCertMsg             = "";

  }
  
  numberValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    //console.log(value);
    // const regex = /^(?:0-3?|4(?:\.0*)?)$/;
    // console.log(regex.test(value));
    if(value>=1 && value<=4){
      return null;
      
    }
    else{
      return { invalidNumber: true };
    }
  }

  initializeEventListeners(){
     //1)change in work experience score
     this.evaluationParameters.get('workExperienceScore').valueChanges.subscribe(val=>{
      this.findAverageScore(parseInt(val, 10),this.evaluationParameters.get('toolingScore').value,this.evaluationParameters.get('proceduresScore').value,this.evaluationParameters.get('solutioningScore').value,this.evaluationParameters.get('situationalScore').value,this.evaluationParameters.get('certificationsScoreSelect').value)
    })
    //2)change in tooling score
    this.evaluationParameters.get('toolingScore').valueChanges.subscribe(val=>{
      this.findAverageScore(this.evaluationParameters.get('workExperienceScore').value,parseInt(val, 10),this.evaluationParameters.get('proceduresScore').value,this.evaluationParameters.get('solutioningScore').value,this.evaluationParameters.get('situationalScore').value,this.evaluationParameters.get('certificationsScoreSelect').value)
    })
    //3)change in procedure score
    this.evaluationParameters.get('proceduresScore').valueChanges.subscribe(val=>{
      this.findAverageScore(this.evaluationParameters.get('workExperienceScore').value,this.evaluationParameters.get('toolingScore').value,parseInt(val, 10),this.evaluationParameters.get('solutioningScore').value,this.evaluationParameters.get('situationalScore').value,this.evaluationParameters.get('certificationsScoreSelect').value)
    })
    //4)change in solutioning score
    this.evaluationParameters.get('solutioningScore').valueChanges.subscribe(val=>{
      this.findAverageScore(this.evaluationParameters.get('workExperienceScore').value,this.evaluationParameters.get('toolingScore').value,this.evaluationParameters.get('proceduresScore').value,parseInt(val, 10),this.evaluationParameters.get('situationalScore').value,this.evaluationParameters.get('certificationsScoreSelect').value)
    })
    //5) change in situational score
    this.evaluationParameters.get('situationalScore').valueChanges.subscribe(val=>{
      this.findAverageScore(this.evaluationParameters.get('workExperienceScore').value,this.evaluationParameters.get('toolingScore').value,this.evaluationParameters.get('proceduresScore').value,this.evaluationParameters.get('solutioningScore').value,parseInt(val, 10),this.evaluationParameters.get('certificationsScoreSelect').value)
    })
    //6) change in certifications score 
    this.evaluationParameters.get('certificationsScoreSelect').valueChanges.subscribe(val=>{
      this.findAverageScore(this.evaluationParameters.get('workExperienceScore').value,this.evaluationParameters.get('toolingScore').value,this.evaluationParameters.get('proceduresScore').value,this.evaluationParameters.get('solutioningScore').value,this.evaluationParameters.get('situationalScore').value,parseInt(val, 10))
    })
    //Overall SME Score Change
    this.finalEvaluation.get('smeScore').valueChanges.subscribe(value => {
      if(this.finalEvaluation.get('smeScore').value == this.finalEvaluation.get('averageScore').value){
        this.showScoreChangeReason = false;
      }
      else{
        this.showScoreChangeReason = true;
      }
      // console.log(this.finalEvaluation.get('smeScore').value +' '+this.finalEvaluation.get('averageScore').value+' '+ this.showScoreChangeReason)
      
    });
  }

}



