<div fxLayout="column" class="yorbit-courses-sel-box" fxLayoutAlign="center center">
    <div fxLayout="column" class="sel-container" fxLayoutAlign="center center" *ngIf="!isCoursesLoading">
        <div class="prereq-control">
            <div class="selected-courses-box" *ngIf="selectedCourses.length > 0">
                <div *ngFor="let item of selectedCourses" fxLayout="row">
                    <div class="course-name" fxFlex="90" title="{{item.Name + '-' + item.Expertise}}">{{ item.Name + '-' + item.Expertise}}</div>
                    <div fxFlex="10" (click)="removeItem(item)" class="del-icon-holder floatLeft mousePointer">
                        <div class="yorbitSprite del-icon-grey-small"></div>
                    </div>
                </div>
            </div>
            <div class="selected-courses-box" *ngIf="selectedCourses.length == 0">
               <div class="placeholder-msg">
                   Courses selected by you will be listed here.
               </div>
            </div>
        </div>
        <div class="prereq-search">
            <input type="text" [(ngModel)]="searchKey" placeholder="Search Course" (keyup)="filterCourses()" [ngModelOptions]="{ standalone: true }"
            />
            <mat-icon class="close-search-icon mousePointer" [fxShow.gt-xs]="searchKey!=''" [fxShow.xs]="showSearchBar" (click)="showSearchBar = false;searchKey='';filterCourses();">clear</mat-icon>
          <mat-icon class="search-mat-icon mousePointer" [fxShow.gt-xs]="searchKey==''" [fxShow.xs]="!showSearchBar" (click)="showSearchBar = true">search</mat-icon>
        </div>
        <div *ngIf="coursesFinal.length >= 0 && searchKey!='' && searchKey.length>=3" class="prereq-list">
            <div class="list" fxLayout="column">
                <div *ngFor="let course of filteredCourses;
                        let i = index;
                        trackBy : index" fxLayout="row">
                    <div>
                        <input id="row{{ i }}" type="checkbox" [(ngModel)]="course.checked" [ngModelOptions]="{ standalone: true }" [value]="course.Id"
                            name="row{{ i }}" (change)="watchChange($event, course)" />
                    </div>
                    <div>
                        <label for="row{{ i }}" class="mousePointer" style="vertical-align: 2px;">
                        {{ course.Name + " - " + course.Expertise }}
                    </label>
                    </div>
                </div>
                <div *ngIf="filteredCourses.length === 0 || coursesFinal.length === 0">No Courses Found</div>
            </div>
        </div>
        <div class="action-buttons">
            <button class="action-button" [disabled]="selectedCourses.length == 0" 
            [ngClass]="{'disabled': selectedCourses.length == 0}"
            title="{{selectedCourses.length == 0 ? 'Please select atleast one course' : ''}}"
            (click)="emitEvent(Events.OK)">OK</button>
            <button class="action-button space-left" (click)="emitEvent(Events.CANCEL)">Cancel</button>
        </div>
    </div>
    <div *ngIf="isCoursesLoading">
        <app-preloader></app-preloader>
    </div>
</div>