<mat-dialog-content class="popupDialog">
    <!--Close Popup Icon-->
    <div class="closePopupDialog">
      <button mat-button mat-icon-button (click)="closePopup()">
        X
      </button>
    </div>
    
    <!--Popup Header-->
    <div class="popupHeader">{{popupHeading}}</div>

<div style="text-align: center;" class="modal-body">
  <!-- <div class="content"> -->
 
      <div  ngClass="{'disable': data.length > 0 && data[0]}">
        <input style="margin-top: 10px;" type="checkbox"  [disabled]="!data[1]" (change)="selectURL($event, data[1])"  value="data[1]"><b>Attempt1</b>
     
      </div>

    <div></div>
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      <div style="margin-top: 10px;" ngClass="{'disable': data.length > 0 && data[1]}">
        <input type="checkbox" [disabled]="!data[2]" (change)="selectURL($event, data[2])" value="data[2]"> <b>Attempt2</b>

      </div>
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      <div style="margin-top: 10px;" ngClass="{'disable': data.length > 0 && data[3]}">
          <input type="checkbox" [disabled]="!data[3]" (change)="selectURL($event, data[3])" value="data[3]"> <b>Attempt3</b>
         
      </div>
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
  
     </div>
<!-- </div> -->

<div class="actionButtonContainer">
    <div>
      <button mat-raised-button class="actionButton download-btn" (click)="Download()" [disabled]="urls.length === 0">Download</button>
    </div>
  </div>

 
</mat-dialog-content>
