<button mat-button color="warn" (click)="onClick()">
  <mat-icon>file_upload</mat-icon>
  {{text}}
</button>
<br/>
<ul>
  <li *ngFor="let file of files" [@fadeInOut]="file.state">
        <mat-progress-bar [value]="file.progress" style="width:68px; align-self:center"></mat-progress-bar>
        <span id="file-label">
        {{file.data.name}} 
        <a title="Retry" (click)="retryFile(file)" *ngIf="file.canRetry">
        <mat-icon>refresh</mat-icon></a>
        <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel">
        <mat-icon>cancel</mat-icon></a>
        </span>
  </li>
</ul>
<input type="file" id="fileUpload" name="fileUpload" multiple="multiple" 

accept="{{accept}}" style="display:none;"/>