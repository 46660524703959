<div class="requestFilterParent">
    <div class="dateAndResetFilterRow">
      <div class="font12 floatLeft">
        <div class="textStyle">From</div>
          <div class="matForm">
            <div class="dateDiv">
              <input
                [matDatepicker]="iFromDate"
                placeholder="Choose a Date"
                (dateInput)="fromDateSelected($event)"
                (dateChange)="fromDateSelected($event)"
                class="certDate"
                [(ngModel)] = "selectedFromDate"
                [max]="maxDate"
                readonly/>
              </div>              
              <mat-datepicker matInput #iFromDate ></mat-datepicker>
          <i class="spriteIcons floatLeft calendarIcon mousePointer" (click)="openCalendar(iFromDate)"
          matTooltipClass="tooltipStyle"
          matTooltip="Click to pick a date"></i>
          </div>
          <div class="errorMessage" *ngIf="isFromDateInvalid">{{fromDateErrorText}}</div>
      </div>
      <div class="toParentDiv">
          <div class="textStyle">To</div>
            <div class="matForm">
              <div class="dateDiv">
                <input 
                  [matDatepicker]="iToDate"
                  placeholder="Choose a Date"
                  (dateInput)="toDateSelected($event)"
                  (dateChange)="toDateSelected($event)"
                  class="certDate"
                  [(ngModel)] = "selectedEndDate"
                  [max]="maxDate"                 
                  readonly/>
              </div>
                <mat-datepicker #iToDate></mat-datepicker>
              <i class="spriteIcons floatLeft calendarIcon mousePointer" (click)="openCalendar(iToDate)" 
              matTooltipClass="tooltipStyle"
              matTooltip="Click to pick a date"></i>
            </div>
            <div class="errorMessage" *ngIf="isToDateInvalid">{{toDateErrorText}}</div>
      </div>
      <div class="resetAllDiv">
        <button class="resetAllButton" mat-raised-button aria-label="Go" (click)="resetAllFilters()">
          <i class="spriteIcons floatRight resetAllIcon"></i>
          Reset All Filters
        </button>
      </div>
    </div>
    <div style="overflow: auto">
    <div class="filterTypeColumn font12 floatLeft">
      <div class="filterTypeRows mousePointer" [ngClass]="{'groupSelected':'Academy'===selectedFilterType}"
        (click)="filterTypeChange('Academy')">
        <div [hidden]="!isAcademyFiltered">
            <div class="spriteIcons floatLeft resetIcon" (click)="clearSelection('Academy')"></div>
        </div>
        <div class="filterTypeText">Academy</div>
        <div class="rightArrow" *ngIf="'Academy'===selectedFilterType">></div>
      </div>
      <div class="filterTypeRows mousePointer" [ngClass]="{'groupSelected':'Course Name'===selectedFilterType}" 
        (click)="filterTypeChange('Course Name')">
        <div [hidden]="!isCourseNameFiltered">
          <div class="spriteIcons floatLeft resetIcon" (click)="clearSelection('Course Name')"></div>
        </div>
        <div class="filterTypeText">Course Name</div>
        <div class="rightArrow" *ngIf="'Course Name'===selectedFilterType">></div>
      </div>
      <div class="filterTypeRows mousePointer" [ngClass]="{'groupSelected':'Course Type'===selectedFilterType}"
        (click)="filterTypeChange('Course Type')">
        <div [hidden]="!isCourseTypeFiltered">
            <div class="spriteIcons floatLeft resetIcon" (click)="clearSelection('Course Type')"></div>
        </div>
        <div class="filterTypeText">Course Type</div>
        <div class="rightArrow" *ngIf="'Course Type'===selectedFilterType">></div>
      </div>
      <div class="filterTypeRows mousePointer" [ngClass]="{'groupSelected':'Evaluator/Vendor Name'===selectedFilterType}"
        (click)="filterTypeChange('Evaluator/Vendor Name')">
        <div [hidden]="!isEvalVendorFiltered">
          <div class="spriteIcons floatLeft resetIcon" (click)="clearSelection('Evaluator/Vendor Name')"></div>
        </div>
        <div class="filterTypeText">Evaluator/Vendor Name</div>
        <div class="rightArrow" *ngIf="'Evaluator/Vendor Name'===selectedFilterType">></div>
      </div>
      <div class="filterTypeRows mousePointer" [ngClass]="{'groupSelected':'Course Vendor'===selectedFilterType}"
        (click)="filterTypeChange('Course Vendor')">
        <div [hidden]="!isCourseVendorFiltered">
          <div class="spriteIcons floatLeft resetIcon" (click)="clearSelection('Course Vendor')"></div>
        </div>
        <div class="filterTypeText">Course Vendor</div>
        <div class="rightArrow" *ngIf="'Course Vendor'===selectedFilterType">></div>
      </div>
      <div class="filterTypeRows mousePointer" [ngClass]="{'groupSelected':'Certification Sub Status'===selectedFilterType}"
        (click)="filterTypeChange('Certification Sub Status')">
        <div [hidden]="!isSubStatusFiltered">
          <div class="spriteIcons floatLeft resetIcon" (click)="clearSelection('Certification Sub Status')"></div>
        </div>
        <div class="filterTypeText">Course Sub Status</div>
        <div class="rightArrow" *ngIf="'Certification Sub Status'===selectedFilterType">></div>
      </div>
      <div class="filterTypeLastRow mousePointer" [ngClass]="{'groupSelected':'Learning OPM SPOC'===selectedFilterType}"
        (click)="filterTypeChange('Learning OPM SPOC')">
        <div [hidden]="!isLearningOPMFiltered">
          <div class="spriteIcons floatLeft resetIcon" (click)="clearSelection('Learning OPM SPOC')"></div>
        </div>
        <div class="filterTypeText">Learning OPM SPOC</div>
        <div class="rightArrow" *ngIf="'Learning OPM SPOC'===selectedFilterType">></div>
      </div>
    </div>
    <div class="filterDetailsColumn floatLeft" *ngIf="!showLoading.contentData">
      <div class="preloaderStyle"><app-preloader></app-preloader></div>
    </div>
    <div class="filterDetailsColumn floatLeft cNAndCVHeight font12" *ngIf="divCondtion && showLoading.contentData"  fxLayout="row">
      <yorbit-pss-course-name fxFlexFill  (isCourseNameFiltered)="courseNameFiltered($event)" *ngIf="'Course Name'===selectedFilterType"></yorbit-pss-course-name>
      <yorbit-pss-course-vendor fxFlexFill  (isCourseVendorFiltered)="courseVendorFiltered($event)" *ngIf="'Course Vendor'===selectedFilterType"></yorbit-pss-course-vendor>
    </div>
    <div class="filterDetailsColumn floatLeft scrollHorizontal font12" *ngIf="!divCondtion && showLoading.contentData" fxLayout="row">

      <yorbit-pss-course-type fxFlexFill fxLayout="column wrap" fxLayoutAlign="start start" fxLayoutGap="10px" (isCourseTypeFiltered)="courseTypeFiltered($event)" *ngIf="'Course Type'===selectedFilterType"></yorbit-pss-course-type>
      <yorbit-pss-academy fxFlexFill fxLayout="column wrap" fxLayoutAlign="start start" fxLayoutGap="10px" (isAcademyFiltered)="academyFiltered($event)" *ngIf="'Academy'===selectedFilterType"></yorbit-pss-academy>
      <yorbit-pss-evaluator-vendor fxFlexFill fxLayout="column wrap" fxLayoutAlign="start start" fxLayoutGap="10px" (isEvalVendorFiltered)="evalVendorFiltered($event)" *ngIf="'Evaluator/Vendor Name'===selectedFilterType"></yorbit-pss-evaluator-vendor>
      <yorbit-pss-certification-substatus fxFlexFill fxLayout="column wrap" fxLayoutAlign="start start" fxLayoutGap="10px" (isSubStatusFiltered)="subStatusFiltered($event)" *ngIf="'Certification Sub Status'===selectedFilterType"></yorbit-pss-certification-substatus>
      <yorbit-pss-learning-opmspoc fxFlexFill fxLayout="column wrap" fxLayoutAlign="start start" fxLayoutGap="10px" (isLearningOPMFiltered)="learningOPMFiltered($event)" *ngIf="'Learning OPM SPOC'===selectedFilterType"></yorbit-pss-learning-opmspoc>
      </div>
    <div class="filterSelectionColumn floatLeft">
      <div class="filterSelectionRowText">Enter PSID/Request ID</div>
      <div class="textBoxDiv"><input #model="ngModel" type="text" name="searchTextBox" 
        [(ngModel)]="searchText" class="textBoxStyle" 
        [ngModelOptions]="{updateOn: 'blur'}" (blur)="validateSearchText()">
      </div>
      <div class="filterSelectionRowText errorMessage" *ngIf="isSearchInvalid">*Enter valid PSID or Request ID</div>
      <div class="filterSelectionRowText">Evaluation Type</div>
      <div class="evaluatorTypeRadioButtons font12">
        <div fxLayout="row" fxLayoutAlign="start center">
          <input type="radio" name="evaluationType" [(ngModel)]="evaluatorType" value="internal">Internal
          <input type="radio" name="evaluationType" [(ngModel)]="evaluatorType" value="external">External         
          <input type="radio" name="evaluationType" [(ngModel)]="evaluatorType" value="all">All
        </div>
      </div>
      <div class="checkBoxList font12">
        <div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <input type="checkbox" name="checkBoxFilter" value="dueForPublishing" [checked]="isDueForPublishing"
            (change)="onSelectionChange('dueForPublishing')">Due For Publishing<br />
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <input type="checkbox" name="checkBoxFilter" value="slaHitRequests" [checked]="isSlaHitRequests"
            (change)="onSelectionChange('slaHitRequests')">SLA Hit Requests<br />
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <input type="checkbox" name="checkBoxFilter" value="projectRequests" [checked]="isProjectRequests"
            (change)="onSelectionChange('projectRequests')">Project Requests<br />
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <input type="checkbox" name="checkBoxFilter" value="assignmentRequests" [checked]="isAssignmentRequests"
            (change)="onSelectionChange('assignmentRequests')">Assignment Requests
        </div>
      </div>
    </div>
  </div>
    </div>
    <div class="applySearchRow">
      <div class="switchToggle floatLeft">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="isFilteredChecked">
            <div class="slider round">
            </div>
        </label>
      </div>
      <div class="spriteIcons downloadIcon downloadIconAlign mousePointer" (click)="downloadRecords()"
      matTooltipClass="tooltipStyle"
      matTooltip="{{isFilteredChecked?'Download filtered course evaluation requests':'Download all course evaluation requests'}}"></div>
  
      <button class="applyButton floatRight" mat-raised-button aria-label="Go" (click)="applySearch()">
        Apply & Search
      </button>
    </div>
  </div>
  
  <div *ngIf="count == 0 && !isRecordsLoading" class="noRecords">
    {{isNoRecordsText ? 'No records found' : 'Apply filters and search to view results'}}</div>
  <div class="preloaderStyle recordsPreloader" *ngIf="isRecordsLoading"><app-preloader></app-preloader></div>
  <yorbit-pss-evaluation-content (isContentCompDisabled)="closeContentComponent($event)" [requestList] = "pssEvaluationRequestList" [totalRecords] = "count" *ngIf="isLoadEvaluationList"></yorbit-pss-evaluation-content>
