<mat-dialog-content>
  <!--class="shareList"-->
  <!--Close Popup Icon-->
  <div class="closeShare">
    <button class="closeShareButton mousePointer" mat-button mat-icon-button (click)="closeShare()" [disabled]="">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!--Course Name-->
  <div class="courseName">{{popupTitle}}</div>

  <!--Mat divider-->
  <mat-divider></mat-divider>

  <!--Search bar-->
  <form *ngIf="!initialLoad" class="searchBlock">
    <div *ngIf="(5<=userListToShare.length)" class="preventEvaluator" (click)="$event.stopPropagation();"></div>
    <mat-form-field class="searchUserContainer">
      <input type="text" placeholder="Find Associates" aria-label="Number" matInput [formControl]="ShareControl"
        [matAutocomplete]="auto" [(ngModel)]="searchUserInput" (keyup)="searchUser(searchUserInput, $event)"
        [errorStateMatcher]="validateUserAdded" />
      <!--
        <mat-error> {{(noSuggestionError)?"": UserAddedValidateError }} </mat-error> -->

      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayEmpty"
        (optionSelected)="userSelectHandler($event)">
        <mat-option *ngFor="let user of suggestionList" [value]="user">
          <yorbit-share-user-profile [user]="user"
            (click)="addUserFromSuggestionList(user)"></yorbit-share-user-profile>
        </mat-option>
      </mat-autocomplete>

      <button mat-button *ngIf="searchUserInput" matSuffix mat-icon-button aria-label="Clear" (click)="resetUserInput()"
        class="deteleButtonNoStyle">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>
  <div *ngIf="noSuggestionError" class="error-msg">Please enter a valid PSID or name</div>
  <div *ngIf="UserAddedValidateFail" class="error-msg">{{UserAddedValidateError }}</div>
  <div *ngIf="!initialLoad" class="noteForUser">{{noteForUser}}</div>

  <!--Selected user list-->
  <div class="userListContainer" *ngIf="!(0 >= userListToShare.length) && !initialLoad">
    <div class="userList" *ngFor="let user of userListToShare" fxFlexLayout="row" fxLayoutAlign="center center">
      <diV fxFlex="80">
        <yorbit-share-user-profile class="userProfile" [user]="user"></yorbit-share-user-profile>
      </diV>
      <div fxFlex="20">
        <button class="removeUser" mat-button mat-icon-button (click)="removeUserFromSuggestionList(user)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!--Action buttons-->
  <div class="action-btn" *ngIf="!initialLoad">
    <button class="action-button shareButton" (click)="shareToUser()"
      [ngClass]="{'disabled':userListToShare.length === 0}">
      Save
    </button>
    <button class="action-button shareButton" (click)="closeShare()">Cancel</button>
  </div>



  <div *ngIf="initialLoad" style="margin: 0 auto;">
    <app-preloader></app-preloader>
  </div>
</mat-dialog-content>