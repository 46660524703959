import { Injectable } from '@angular/core';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AssignContentService {
  config: Ienvironment;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }

  cdmPushContent(assignData, role, isProjectPackage) {
    if (isProjectPackage) {
      return this.http
        .post(
          this.config.apiUrl +
            'ProjectPackages/' +
            role +
            'Assignment/AssignmentPackage/Users',
          assignData
        )
        .toPromise();
    } else {
      //POST /User/MandatoryLP/{role}/{intentId}/{intentDescription}
      return this.http
        .post(this.config.apiUrl + 'User/MandatoryLP/' + role, assignData)
        .toPromise();
    }
  }

  getProjectPackageDetails(payload, itemType) {
    if (itemType.toLowerCase() === 'course')
      return this.http.post(this.config.apiUrl + 'Course', payload).toPromise();
    else if (itemType.toLowerCase() === 'familypackage')
      return this.http
        .post(this.config.apiUrl + 'Package', payload)
        .toPromise();
  }
  getPackageDetails(id, type) {
    if (type === 'Course') {
      return this.http.get(this.config.apiUrl + 'Course/' + id).toPromise();
    } else {
      return this.http
        .get(this.config.apiUrl + 'Package/' + id + '/' + type)
        .toPromise();
    }
  }

  getContentInsideFamilyPackage(id) {
    return this.http
      .get(this.config.apiUrl + 'LearningPlan/FamilyPackages/' + id)
      .toPromise();
  }
  getContentOfPackage_PP(payload) {
    return this.http
      .post(this.config.apiUrl + 'LearningPlan/Package/Courses', payload)
      .toPromise();
  }

  downloadTemplateForBulkUpload(): Observable<any> {
    return this.http.get<any>(this.config.apiUrl + 'Course/Download/BulkAssignmentTemplate', {
      responseType: 'arraybuffer' as 'json',
      observe: 'response'
    });
  }

  uploadBulkAssignment(file, payload) {
    // let payload={
    //   ItemId:"9000",
    //   ItemType:"Course",
    //   ItemName:"Course",
    //   AssignerID:"61032565",
    //   IntentID:"1",
    //   IntentDescription:"NA",
    //   ItemExpertise:"101",
    //   Duration:"01.00.00",
    //   Role:"CDM",
    // }
    const payloadString=JSON.stringify(payload);
    // let formDataWithObj=new FormData()
    // formDataWithObj.append('data',payloadString);
    // formDataWithObj.append('file',file,file.name);
    file.delete('data');
    file.append('data',payloadString);
    return this.http
    .post<any>(this.config.apiUrl + 'User/BulkAssignmentUpload',file);
  }
}
