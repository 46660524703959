import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DiversityAndInclusionService {
  config: Ienvironment;
  videosList: any;
  coursesList: any;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.videosList = [];
    this.coursesList = [];
  }

  getVideosForDiversityAndInclusion(category?:any) {
   // var data = {  category};
   const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let promise = new Promise((resolve, reject) => {
        this.http
          .post(this.config.apiUrl + 'User/GetVideosForDiversityAndInclusion/',JSON.stringify(category),{headers: headers})
          .toPromise()
          .then(list => {
            if (list != null) {
              this.videosList = list;
            } else {
              this.videosList = [];
            }
            resolve(this.videosList);
          });
    });
    return promise;
  }

  getArticlesForDiversityAndInclusion(filter: string = 'article') {
    return this.http
      .post<any>(
        this.config.apiUrl + 'User/DiversityAndInclusion/' + filter,
        {}
      )
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getCoursesForDiversityAndInclusion() {
    let promise = new Promise((resolve, reject) => {
      if (this.coursesList.length === 0) {
        this.http
          .get(this.config.apiUrl + 'User/GetCoursesForDiversityAndInclusion')
          .toPromise()
          .then(list => {
            if (list != null) {
              this.coursesList = list;
            } else {
              this.coursesList = [];
            }
            resolve(this.coursesList);
          });
      } else {
        resolve(this.coursesList);
      }
    });
    return promise;
  }
}
