
  <div  fxAlign="row"  fxFlexFill class="vid-container" fxLayoutAlign="space-between start" *ngIf="align=='row'">
    <div class="about-video" fxLayout="column" fxFlex="40">
          <span class="header">{{video?.Title}}</span>
          <span class="description">{{video?.Description}}</span>   
    </div>
        <div class="video" fxFlex="55">
                <yorbit-youtube-video-player
                *ngIf="video.VideoType == 'YouTube'"
                [PlayerConfig]="videoConfig"
              ></yorbit-youtube-video-player>
              <yorbit-o365-video-player
              *ngIf="video.VideoType == 'O365'"
                [PlayerConfig]="videoConfig"
              ></yorbit-o365-video-player>
        </div>
  </div>


<div class="vid-container" fxAlign="column"  *ngIf="align=='column'">
  <div class="about-video">
    <span class="header">{{video?.Title}}</span>
  </div>
  <div class="video" style="margin: 10px 0px 10px 0px;">
        <yorbit-youtube-video-player
        *ngIf="video.VideoType == 'YouTube'"
        [PlayerConfig]="videoConfig"
      ></yorbit-youtube-video-player>
      <yorbit-o365-video-player
      *ngIf="video.VideoType  == 'O365'"
        [PlayerConfig]="videoConfig"
      ></yorbit-o365-video-player>
  </div>
  <div class="about-video">
    <span class="description">{{video?.Description}}</span>   
  </div>
</div>