<div class="learning-history-container" *ngIf="!historyLoading" fxLayout="column">


  <div class="export-learning-history-container" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start" fxLayout.lt-sm="column"
    fxLayoutAlign.lt-sm="start stretch" fxHide.lt-lg = "true">
    <div class="DownloadLearning">Download Learning History</div>
    <div class="ExpertiselevelMenu" fxLayoutGap="10px" fxLayoutAlign="space-around start">
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxFlex="20px" fxLayoutAlign="start start" class="selectExpertise">Select Expertise</div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <div class="selectMenuContainer">
            <mat-menu class="dropdownOption" backdropClass="menuOverlay" #menu="matMenu" [overlapTrigger]="false">
              <div mat-menu-item *ngFor="let expertise of expertiselLevelGroup" (click)="selectExpertise(expertise);expertiselLevelTrigger.closeMenu()">
                <div class="expertise-view">{{ expertise.viewValue }} </div>                
              </div>
            </mat-menu>
            <button [matMenuTriggerFor]="menu" #expertiselLevelTrigger="matMenuTrigger" fxLayoutAlign="start center" class="mousePointer dropdownbtn" >
                <div class="selected-expertise">{{selected}} </div>
                <div [ngClass]="expertiselLevelTrigger.menuOpen ? 'menuOpened yorbitSprite upArrow' : 'menuClosed yorbitSprite downArrow'" class="yorbitSprite downArrow"></div>              
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxFlex="20px" fxLayoutAlign="start start" class="StartEnddate">Start date</div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <div class="date-picker-holder" fxLayout="row" fxLayoutAlign="start stretch">
            <!-- {{endDate?.value!=''? maxDate : endDate?.value}}
            {{endDate?.value!=''}} -->
            <!-- {{minDate}} -->
              <mat-form-field class="datepickerForm">
                  <input 
                  matInput 
                  [matDatepicker]="startDatePicker"  
                  [min]="minDate" 
                  [max]="endDate?.value==''?maxDate:endDate?.value"                                
                  (dateChange)="onDateChangeEvent($event, 'start')" 
                  [formControl]="startDateForm" 
                  />
                 <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
              <!-- [min]="minDate" 
              [max]="endDate?.value==''?maxDate:endDate?.value"    -->
              <div class="date-value" fxFlex="140px" fxLayoutAlign="center center" [ngClass]="{placeholderstyle:endDate?.value==''}">
                {{ startDate?.value!=''? (startDate?.value| date: "MM-dd-yyyy"):(minDate | date:'MM-dd-yyyy') }}
              </div>
              <div fxFlex="40px" fxLayoutAlign="center center">
                <div class="yorbitSprite calendarSpirteIcon mousePointer" (click)="startDatePicker.open()"></div>
              </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxFlex="20px" fxLayoutAlign="start start" class="StartEnddate">End date</div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <div class="date-picker-holder" fxLayout="row" fxLayoutAlign="start stretch">
            <!-- {{startDate?.value==''?minDate:startDate?.value}}
            {{startDate?.value==''}}
            {{minDate}}
            {{startDate?.value}} -->
            <mat-form-field class="datepickerForm">
                <input
                matInput 
                [matDatepicker]="endDatePicker" 
                [min]="startDate?.value==''?minDate:startDate?.value"
                [max]="maxDate" 
                (dateChange)="onDateChangeEvent($event, 'end')" 
                [formControl]="endDateForm" 
                />
               <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
            <div class="date-value" fxFlex="140px" fxLayoutAlign="center center" [ngClass]="{placeholderstyle:endDate?.value==''}">
              {{ endDate?.value!=''? (endDate?.value| date: "MM-dd-yyyy"):(maxDate | date:'MM-dd-yyyy') }}
            </div>
            <div fxFlex="40px" fxLayoutAlign="center center">
              <div class="yorbitSprite calendarSpirteIcon mousePointer" (click)="endDatePicker.open()"></div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxFlex="20px" fxLayoutAlign="start start"></div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <button class="APPLYButton" mat-raised-button (click)="getBadgesFilter()" [ngClass]="{'disabled':rawData.length == 0}"
          [disabled]="rawData.length == 0" title ="{{(rawData.length == 0 || historyDetails.length == 0) ? 'No Learning Data found' : 'Please click on Apply to Preview and Download the Summary'}}">Apply</button>
          <button mat-raised-button (click)="getCourseNames()" [ngClass]="{'disabled' : (loadingCourseNames || historyDetails.length == 0 || !isGoBtnClicked) }"
          [disabled]="loadingCourseNames || historyDetails.length == 0 || !isGoBtnClicked"
          title = "{{historyDetails.length == 0 ? 'No Learning Data found' :(!isGoBtnClicked ? 'Please click on Apply to Preview and Download the Summary': '')}}">
                <div *ngIf="!loadingCourseNames" fxFlex="80px" fxLayoutAlign="space-evenly center">Download</div>
                <div *ngIf="!loadingCourseNames" class="mousePointer yorbitSprite downloadSpriteIconWhite"></div>
                <div [ngClass]="{'disabled' : loadingCourseNames}" *ngIf="loadingCourseNames" class="disabled-btn">Downloading...</div>
          </button>
        </div>        
      </div>
    </div>
  </div>
  <div class="line" [ngClass.lt-md]="'lineXs'"></div>

  <div class="Badges-per-year">
    <div class="filters">
      <div class="year-filter">
        <div class="floatLeft" style="width:24px;height:24px">
          <mat-icon *ngIf="selectedYear != oldDOCYear && oldDOCYear !== undefined" class="mousePointer" (click)="filterByYear(selectedYear, false)">chevron_left</mat-icon>
        </div>
        <div class="year floatLeft">{{ selectedYear }}</div>
        <div class="floatLeft" style="width:24px;height:24px">
          <mat-icon *ngIf="selectedYear != latestDOCYear && latestDOCYear !== undefined" class="mousePointer" (click)="filterByYear(selectedYear, true)">chevron_right</mat-icon>
        </div>
      </div>
    </div>

    <!--[ngClass.gt-md]="'overflow'"-->
    <div class="history" fxLayout="column">
      <div class="quarterly-info" [ngClass.lt-md]="'quarterly-infoXs'" fxLayout="row">
        <div class="heading">
          <div class="quarter-label"><span>Jan-Mar</span></div>
        </div>
        <div class="data" fxFlex fxLayout="row" fxLayoutAlign.lt-md="start center" fxLayoutAlign.gt-sm="start start">
          <div class="background-lines">
            <div class="pointer"></div>
          </div>
          <div class="badges-count-data" [fxShow.lt-md]="firstQuarterHistory.length>0" fxShow.gt-sm="false" fxLayout.lt-md="column"
            fxLayout.gt-sm="row" fxLayoutAlign.xs="center center" fxLayoutAlign.gt-xs="start center">
            <div class="badges-count">{{ firstQuarterHistory.length }}</div>
            <div class="label">courses completed </div>
          </div>
          <div class="badge-container" *ngIf="firstQuarterHistory.length != 0" fxShow fxHide.lt-md="true">
            <ng-template [ngTemplateOutlet]="badgesTemplate" [ngTemplateOutletContext]="{
                badgeArray: firstQuarterHistory
              }"></ng-template>
          </div>
          <div class="badge-container" *ngIf="firstQuarterHistory.length != 0 && openExpansion[0]" fxHide fxShow.lt-md="true">
            <ng-template [ngTemplateOutlet]="badgesTemplate" [ngTemplateOutletContext]="{
                badgeArray: firstQuarterHistory
              }"></ng-template>
          </div>
          <div class="no-badges-info" fxFlexAlign="center" *ngIf="firstQuarterHistory.length == 0">
            No courses completed
          </div>
          <div class="no-badges-info more-badges" *ngIf="firstQuarterHistory.length > 0" fxHide fxShow.lt-md="true">
            <mat-icon *ngIf="!openExpansion[0]" (click)="openDialog(firstQuarterHistory,'Jan-Mar')">expand_more</mat-icon>
          </div>
        </div>
      </div>
      <div class="quarterly-info" [ngClass.lt-md]="'quarterly-infoXs'" fxLayout="row">
        <div class="heading ">
          <div class="quarter-label"><span>Apr-Jun</span></div>
        </div>
        <div class="data" fxFlex fxLayout="row" fxLayoutAlign.lt-md="start center" fxLayoutAlign.gt-sm="start start">
          <div class="background-lines">
            <div class="pointer"></div>
          </div>
          <div class="badges-count-data" [fxShow.lt-md]="secondQuarterHistory.length>0" fxShow.gt-sm="false" fxLayout.lt-md="column"
            fxLayout.gt-sm="row" fxLayoutAlign.xs="center center" fxLayoutAlign.gt-xs="start center">
            <div class="badges-count">{{ secondQuarterHistory.length }}</div>
            <div class="label">courses completed </div>
          </div>
          <div class="badge-container" *ngIf="secondQuarterHistory.length != 0" fxShow fxHide.lt-md="true">
            <ng-template [ngTemplateOutlet]="badgesTemplate" [ngTemplateOutletContext]="{
                badgeArray: secondQuarterHistory
              }"></ng-template>
          </div>
          <div class="badge-container" *ngIf="secondQuarterHistory.length != 0 && openExpansion[1]" fxHide fxShow.lt-md="true">
            <ng-template [ngTemplateOutlet]="badgesTemplate" [ngTemplateOutletContext]="{
                badgeArray: secondQuarterHistory
              }"></ng-template>
          </div>
          <div class="no-badges-info" fxFlexAlign="center" *ngIf="secondQuarterHistory.length == 0">
            No courses completed
          </div>
          <div class="no-badges-info more-badges" *ngIf="secondQuarterHistory.length > 0" fxHide fxShow.lt-md="true">
            <mat-icon *ngIf="!openExpansion[1]" (click)="openDialog(secondQuarterHistory,'Apr-Jun')">expand_more</mat-icon>
          </div>
        </div>
      </div>
      <div class="quarterly-info" [ngClass.lt-md]="'quarterly-infoXs'" fxLayout="row">
        <div class="heading">
          <div class="quarter-label"><span>July-Sep</span></div>
        </div>
        <div class="data" fxFlex fxLayout="row" fxLayoutAlign.lt-md="start center" fxLayoutAlign.gt-sm="start start">
          <div class="background-lines">
            <div class="pointer"></div>
          </div>
          <div class="badges-count-data" [fxShow.lt-md]="thirdQuarterHistory.length>0" fxShow.gt-sm="false" fxLayout.lt-md="column"
            fxLayout.gt-sm="row" fxLayoutAlign.xs="center center" fxLayoutAlign.gt-xs="start center">
            <div class="badges-count">{{ thirdQuarterHistory.length }}</div>
            <div class="label">courses completed </div>
          </div>
          <div class="badge-container" *ngIf="thirdQuarterHistory.length != 0" fxShow fxHide.lt-md="true">
            <ng-template [ngTemplateOutlet]="badgesTemplate" [ngTemplateOutletContext]="{
                badgeArray: thirdQuarterHistory
              }"></ng-template>
          </div>
          <div class="badge-container" *ngIf="thirdQuarterHistory.length != 0 && openExpansion[2]" fxHide fxShow.lt-md="true">
            <ng-template [ngTemplateOutlet]="badgesTemplate" [ngTemplateOutletContext]="{
                badgeArray: thirdQuarterHistory
              }"></ng-template>
          </div>
          <div class="no-badges-info" fxFlexAlign="center" *ngIf="thirdQuarterHistory.length == 0">
            No courses completed
          </div>
          <div class="no-badges-info more-badges" *ngIf="thirdQuarterHistory.length > 0" fxHide fxShow.lt-md="true">
            <mat-icon *ngIf="!openExpansion[2]" (click)="openDialog(thirdQuarterHistory,'July-Sept')">expand_more</mat-icon>
          </div>
        </div>
      </div>
      <div class="quarterly-info" [ngClass.lt-md]="'quarterly-infoXs'" fxLayout="row">
        <div class="heading ">
          <div class="quarter-label"><span>Oct-Dec</span></div>
        </div>
        <div class="data" fxFlex fxLayout="row" fxLayoutAlign.lt-md="start center" fxLayoutAlign.gt-sm="start start">
          <div class="background-lines">
            <div class="pointer"></div>
          </div>
          <div class="badges-count-data" [fxShow.lt-md]="fourthQuarterHistory.length>0" fxShow.gt-sm="false" fxLayout.lt-md="column"
            fxLayout.gt-sm="row" fxLayoutAlign.xs="center center" fxLayoutAlign.gt-xs="start center">
            <div class="badges-count">{{ fourthQuarterHistory.length }}</div>
            <div class="label">courses completed </div>
          </div>
          <div class="badge-container" *ngIf="fourthQuarterHistory.length != 0" fxShow fxHide.lt-md="true">
            <ng-template [ngTemplateOutlet]="badgesTemplate" [ngTemplateOutletContext]="{
                badgeArray: fourthQuarterHistory
              }"></ng-template>
          </div>
          <div class="badge-container" *ngIf="fourthQuarterHistory.length != 0 && openExpansion[3]" fxHide fxShow.lt-md="true">
            <ng-template [ngTemplateOutlet]="badgesTemplate" [ngTemplateOutletContext]="{
                badgeArray: fourthQuarterHistory
              }"></ng-template>
          </div>

          <div class="no-badges-info" fxFlexAlign="center" *ngIf="fourthQuarterHistory.length == 0">
            No courses completed
          </div>
          <div class="no-badges-info more-badges" *ngIf="fourthQuarterHistory.length > 0" fxHide fxShow.lt-md="true">
            <mat-icon *ngIf="!openExpansion[3]" (click)="openDialog(fourthQuarterHistory,'Oct-Dec')">expand_more</mat-icon>
          </div>
        </div>
      </div>
      <div class="baselineStyle"></div>
    </div>

  <ng-template #badgesTemplate let-badges="badgeArray">
      <div
        *ngFor="let badge of badges"
        class="myPageBadge mousePointer"
        [ngStyle]="{
          display: 'inline-block'
        }"
        title="{{badge.BadgeName}}"
        popover-placement="bottom-left"
        popover-trigger="outsideClick"
        uib-popover-template="badgePopoverUrl"
        (click)="open(badge)"
      >
        <img class="badgeSize" *ngIf="badge.RepoType == 'Degreed'" src="assets/images/Badges/badge_degreed.png"/>
        <img class="badgeSize" *ngIf="badge.Type == 'YorbitLearning' && badge.RepoType != 'Degreed'" src="assets/images/Badges/badge_{{(badge.IsAcquired?'':'declare_')+badge.Expertise}}.png" />
        <img class="badgeSize" *ngIf="badge.Type == 'ExternalLearning'" src="assets/images/Badges/badge_external_learning.jpg" />
        <img class="badgeSize" *ngIf="badge.Type == 'RoleBasedLearning' && badge.BadgeName.slice(badge.BadgeName.lastIndexOf('_')+1) == 'Basic'" src="assets/images/Badges/badge_rbl_basic.jpg" />
        <img class="badgeSize" *ngIf="badge.Type == 'RoleBasedLearning' && badge.BadgeName.slice(badge.BadgeName.lastIndexOf('_')+1) == 'Advance'" src="assets/images/Badges/badge_rbl_advance.jpg" />
        
        <!-- <div class="titleOnBadge">
            <div style="text-align:center">{{ badge.BadgeName }}</div>
          </div> -->
        <div class="titleHolder expertise{{badge.RepoType === 'Degreed' ? badge.RepoType : badge.Expertise}}" fxLayoutAlign="center center">
          <div class="titleBelowBadge">{{ badge.BadgeName }}</div>
        </div>
        <div class="badgeEarnedDate">
          {{ badge.CompletedDate | date: 'dd/MMM' }}
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="learning-history-container" *ngIf="historyLoading">
  <app-preloader></app-preloader>
</div>