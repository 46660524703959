<mat-dialog-content>

  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closePopUp()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title" title="{{cardDetails.ItemName}}">
    <div class="heading-custom">Learning Intent: {{cardDetails.ItemName}}</div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="popup-content">

    <div class="intentDesc" *ngIf="cardDetails.validationResponse.IsAutoApproval">
      Please specify your intent behind learning this course.
    </div>

    <div class="intentDesc" *ngIf="!cardDetails.validationResponse.IsAutoApproval">
      This course requires an approval.
      Please specify your intent behind learning this course.
    </div>
    <mat-divider></mat-divider>
    <div class="intentList">
      <label mat-subheader class="Intentlabel">Select Learning Intent <span style="color: red;">*</span></label>
      <!-- preloader -->
      <div fxLayout="column" fxLayoutAlign="center center" *ngIf="showPreloader" class="container-xs">
        <!-- <div *ngIf="showPreloader"> -->
          <app-preloader></app-preloader>
        <!-- </div> -->
      </div>
      <!-- preloader ends -->
      <div class="intent-list-scrollable"  *ngIf="!showPreloader">
        <mat-radio-group  name="intent" fxLayout="column" fxLayoutAlign="start stretch" [(ngModel)]="intentId">
          <mat-radio-button *ngFor="let item of List;"  class="lists"  [value]="item.IntentId" required>
            <span class="mat-radio-label">{{item.IntentName}}</span><br>
           <span class="wrap-mat-radio-label" >{{item.LearnerIntentDescription}}</span>
           </mat-radio-button>
           <div fxFlex fxLayout="column" fxLayoutAlign="end stretch" *ngIf="intentId == 1" class="comment">
            <textarea fxFlex="70px" 
            style="border-radius: 10px; padding: 8px;outline: none;border:1.3px solid #b6b2b2;" 
            [(ngModel)]="intentComments" placeholder="Please enter comments" ></textarea>
            </div>
         </mat-radio-group>
        </div>
      </div>
      <mat-divider></mat-divider>
      <mat-dialog-actions class="actionButtonContainer"  fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
            <!-- <div class="buttons"> -->
          <button class="backBtn actionButton"
          *ngIf='cardDetails.Prerequisite && 0<cardDetails.Prerequisite.length'
          (click)="goBack()"> BACK </button>
          <button class="actionButton action-button" 
          (click)=" intentId == undefined  || (intentId == 1 && (!intentComments || intentComments.trim() == ''))? null:submitIntent()" 
          [ngClass]="
          {'mouseDisabled': intentId == undefined  || (intentId == 1 && (!intentComments || intentComments.trim() == ''))}"  title="{{
            (intentId == undefined && (intentId != 1))? tooltip : ((intentId == 1 && (!intentComments|| intentComments.trim() == '')) ? tooltip2 : '')
          }}" 
          style="height: 34px; border-radius: 5px;font-size: 12px;
              min-width: 100px;"> PROCEED </button>
            <!-- </div>   -->
      </mat-dialog-actions>

 </div>

</mat-dialog-content>
