<div class="future-skills-container" [ngClass.gt-lg]="['gt-lg']" [ngClass.lg]="['lg']" [ngClass.md]="['md']" [ngClass.sm]="['sm']"
  [ngClass.xs]="['xs']" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md="start stretch">
  <div  class="banner-holder" fxLayout="column" fxLayoutAlign="start stretch" [ngStyle.lt-md]="{'width':'100% !important'}">
    <div fxLayout="row" fxLayoutAlign="start stretch">
      <div fxFlex class="banner" fxLayoutAlign="start stretch">
        <img class="banner-image" fxShow.xs="false" fxShow.gt-xs="true" src="../../assets/images/Future_Skills_Banner.jpg" />
        <img class="banner-image" fxShow.xs="true" fxShow.gt-xs="false" src="../../assets/images/Future_Skills_Banner_Mobile.jpg"
        />
      </div>
    </div>
  </div>
  <!-- Medium to extra large screen -->
  <div fxHide fxShow.gt-sm="true" class="future-skill-title">CHANNELS</div>
  <div fxLayout="column" fxLayoutAlign="center center" style="width:100%">
  <div fxLayout="row wrap" style="width:100%" fxHide  fxShow.gt-sm="true" *ngFor="let futureSkill of masterList; let i = index">
    <div fxLayout = "row" style="width:100%;"  fxLayoutAlign="center center">
      <div fxLayout="row" fxFlex="165px" *ngFor="let skill of futureSkill; let i = index" class="future-skill mousePointer" (click)="redirectToSite(skill)"
        title="Click to view content related to {{skill.ChannelName}} on Future Skills">
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="thumbnail imageDiv">
            <img class="imageStyle" [src]="skill.ImageUrl">
            <div class="channelName">
              {{skill.ChannelName}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <!-- Mobile and Small Screen -->
  <div fxFlex="100%" fxHide fxShow.lt-md="true" class="future-skill-title-mobile">CHANNELS</div>
  <div fxLayout="row" class="mobileList" fxHide fxShow.lt-md="true" *ngFor="let futureSkill of masterListMobile; let i = index">
    <div fxLayout="row" *ngFor="let skill of futureSkill; let i = index" class="future-skill mousePointer" (click)="redirectToSite(skill)"
      title="Click to view content related to {{skill.ChannelName}} on Future Skills">

      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="thumbnail imageDiv-mobile">
          <img class="imageStyle-mobile" [src]="skill.ImageUrl">
          <div class="channelName">
              {{skill.ChannelName}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>