<div class="fields-container" *ngIf="contentLoaded.basicData">
  <div class="menuHeader">
    <div class="floatLeft goBackToGridContainer">
      <mat-icon (click)="gotoCourseMenu()" class="floatLeft mousePointer">keyboard_arrow_left</mat-icon>
      <div class="goBackToGrid mousePointer floatLeft" (click)="gotoCourseMenu()">Go back to course selection</div>
    </div>
    <div class="floatRight">
      <div
        *ngIf="isEditDiabled && content &&'approved'!=content.CourseStatus.toLowerCase() && 'denied'!=content.CourseStatus.toLowerCase()">
        <button (click)="openDownloadFileWithSasKey(refDocLink)" class="actionButton floatLeft marginAfter"
          *ngIf="refDocLink!=null && refDocLink!='' " title="Download Course Procedure Reference Document"
          fxLayoutAlign="center center">
          Reference Document
          <div class="mousePointer yorbitSprite downloadSpriteIconWhite floatLeft scale"></div>
        </button>
        <button (click)="approveOrDenyCourse('approve')" class="actionButton floatLeft marginAfter"
          title="Approve and Publish Content">Verify and Launch</button>
        <button (click)="approveOrDenyCourse('denied')" class="actionButton floatLeft marginAfter"
          title="Deny and revert back to Content Partner">Decline</button>
        <div *ngIf="content.Expertise != '301'" class="mousePointer yorbitSprite editIcon floatLeft iconMargin"
          (click)="isEditDiabled = !isEditDiabled" title="Click Here to enable Edit">
        </div>
      </div>

      <div *ngIf="!isEditDiabled &&content&&'approved'!=content.CourseStatus.toLowerCase()">
        <button (click)="saveAll()" class="actionButton floatLeft marginAfter"
          title="Click Here to Save all changes">Save</button>
        <div class="mousePointer yorbitSprite resetIcon floatLeft" (click)="resetAll()"
          title="Click here to undo the changes.">
        </div>
      </div>

      <div *ngIf="content&&'approved'==content.CourseStatus.toLowerCase()">
        Content is Verified and Launched
      </div>

      <div *ngIf="content&&'denied'==content.CourseStatus.toLowerCase()">
        Content is Denied
      </div>

    </div>
  </div>
  <div class="overflowContainer" *ngIf="contentLoaded.basicData">
    <div>
      <div class="header firstHeader">Basic Course Details</div>
      <ng-template [ngTemplateOutlet]="basicCourseStructure">
      </ng-template>
    </div>

    <div *ngIf="content.Expertise == '301' && content.StackAndSkills != null">
      <div class="header firstHeader">Stack : Skill</div>
      <ng-template [ngTemplateOutlet]="StackAndSkills">
      </ng-template>
    </div>

    <div>
      <div fxFlex fxLayout="column" class="courseOperationDetails" *ngIf="content.Expertise != '301'">
        <div class="header marginAfter">Course Operational Details</div>
        <div *ngIf="CreditSpecial">Not applicable for Course Launched only for Providing Badge and Credits</div>
        <ng-template [ngTemplateOutlet]="courseOperationDetails" *ngIf="!CreditSpecial"></ng-template>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!contentLoaded.basicData" style="width:100%;height:100%" fxLayout="column" fxLayoutAlign="center center">
  <div>
    <app-preloader></app-preloader>
  </div>
</div>

<ng-template #StackAndSkills>
  <div fxLayout="row" *ngFor="let data of StackAndSkill" class="field-data">
    <div class="field-label" fxFlex="20">{{data.stack}}</div>
    <div class="field-label" fxFlex="2">:</div>
    <div class="field-value" fxFlex="75">{{data.skill}}</div>
  </div>
</ng-template>

<ng-template #basicCourseStructure>
  <div fxLayout="row" *ngFor="let data of basicCourseData" class="field-data">
    <div class="field-label" fxFlex="20">{{data.label}}</div>
    <div class="field-label" fxFlex="2">:</div>
    <div class="field-value" fxFlex="75"
      [innerHTML]="(data.value != null && data.value!= '')?data.value:(content.Expertise == 301 ? 'Not Applicable' : 'NA')"
      [ngClass]="{'no-data':(data.value == null || data.value== '')}"></div>
  </div>
  <div fxLayout="row" class="field-data specialCourse" *ngIf="content.Expertise != '301'">
    <div class="field-label" fxFlex="20" [ngClass]="{'fieldEdited':(CreditSpecial!==content?.CreditSpecial)}">Is Course
      Launched only for Providing Badge and Credits?</div>
    <div class="field-label" fxFlex="2">:</div>
    <div class="field-value" fxFlex="75" [ngClass]="{mouseDisabled: isEditDiabled}">
      <input type="radio" name="CreditSpecial" [disabled]="isEditDiabled" [checked]="CreditSpecial" [value]="true"
        [(ngModel)]="CreditSpecial" />Yes
      <input type="radio" name="CreditSpecial" [disabled]="isEditDiabled" [checked]="!CreditSpecial" [value]="false"
        [(ngModel)]="CreditSpecial" />No
    </div>
  </div>
</ng-template>

<ng-template #courseOperationDetails>
  <div fxFlex fxLayout="column">
    <ng-template [ngTemplateOutlet]="infrastructure"></ng-template>
    <ng-template [ngTemplateOutlet]="courseLogin"></ng-template>
    <ng-template [ngTemplateOutlet]="learningMaterial"></ng-template>
    <ng-template [ngTemplateOutlet]="proofOfCourseCompletion"></ng-template>
    <ng-template [ngTemplateOutlet]="classRoom"></ng-template>
    <ng-template [ngTemplateOutlet]="projectWork"></ng-template>
    <ng-template [ngTemplateOutlet]="assessment"></ng-template>
    <ng-template [ngTemplateOutlet]="quiz"></ng-template>
  </div>
</ng-template>

<ng-template #infrastructure>
  <div fxFlex fxLayout="column">
    <div class="subHeader" fxFlex fxLayout="row">Infrastructure</div>
    <div fxFlex fxLayout="row" fxLayoutAlign="space-around stretch" *ngIf="contentLoaded.infrastructure">
      <div fxFlex="200px"
        *ngFor="let infrastructureObj of infrastructureInterface?.infrastructure;let infraIndex=index">
        <div fxLayout="column" class="paddingAround">
          <mat-checkbox [disabled]="isEditDiabled" color="baseblue"
            [ngClass]="{'fieldEdited':(infrastructureValue.infrastructure[infraIndex]!==content.Infrastructure[infraIndex])}"
            [(ngModel)]="infrastructureValue.infrastructure[infraIndex]"
            (change)="infrastructureSelected(infraIndex,$event)">
            {{infrastructureObj.Infrastructure}}
          </mat-checkbox>
          <div *ngIf="4!=infraIndex">
            <div *ngFor="let infrastructureTypeObj of infrastructureInterface.infrastructureType">
              <div
                *ngIf="(infraIndex+1)==infrastructureTypeObj.CourseInfrastructureId && infrastructureValue.infrastructure[infraIndex]">
                <div
                  [ngClass]="{'fieldEdited':(infrastructureValue.infrastructureType[infrastructureTypeObj.Id-1]!=content.InfrastructureType[infrastructureTypeObj.Id-1])}">
                  {{infrastructureTypeObj.InfrastructureType}}
                </div>
                <div>
                  <textarea class="textareaField"
                    [ngClass]="{'textareaEmpty':''==infrastructureValue.infrastructureType[infrastructureTypeObj.Id-1]}"
                    placeholder="{{textareaEmptyPlaceholder}}"
                    [disabled]="isEditDiabled||!infrastructureValue.infrastructure[infraIndex]"
                    [(ngModel)]="infrastructureValue.infrastructureType[infrastructureTypeObj.Id-1]"></textarea>
                </div>
              </div>

            </div>
          </div>
          <div>

          </div>
          <div>

          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!contentLoaded.infrastructure">
      <app-preloader></app-preloader>
    </div>
  </div>
</ng-template>

<ng-template #courseLogin>
  <div fxFlex fxLayout="column">
    <div class="subHeader">
      Course Login
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="space-around stretch" *ngIf="contentLoaded.courseLogin"
      class="custom-chkbox">

      <div fxFlex *ngFor="let courseLoginObj of courseLoginInterface?.courseLogin;let loginIndex=index">
        <div class="horizontalScroll">
          <div fxLayout="column" fxFlex fxLayoutAlign="start  center" class="paddingAround">
            <div fxLayout="column" fxLayoutAlign="center start" class="marginAfter custom-chkbox"
              [ngClass]="{'disabled-custom-chkbox':isEditDiabled}">
              <mat-checkbox [disabled]="isEditDiabled" color="baseblue"
                [ngClass]="{'fieldEdited':(courseLoginValue.LoginType[loginIndex]!==content.LoginType[loginIndex])}"
                [(ngModel)]="courseLoginValue.LoginType[loginIndex]" (change)="courseLoginSelected(loginIndex,$event)">
                {{courseLoginObj.Login}}
              </mat-checkbox>
            </div>

            <div>
              <div *ngIf="1==loginIndex && courseLoginValue.LoginType[loginIndex]" fxFlexOffset="20px" fxLayout="column"
                fxFlex>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div *ngFor="let courseName of courseLoginValue.LinktoCoursesForSelfRegistration;
                      let linkIndex=index; trackBy:trackByFunction" fxFlex="200px" fxLayout="column"
                    fxFlexOffset="10px">
                    <div fxFlex fxLayout="row" style="padding:5px 0px">
                      <div
                        [ngClass]="{'fieldEdited':(courseLoginValue.LinktoCoursesForSelfRegistration[linkIndex]!=content.LinktoCoursesForSelfRegistration[linkIndex])}">
                        {{courseLoginInterface.courseLoginType[1].LoginType}}
                      </div>
                      <div fxFlex fxLayoutAlign="end center" style="padding-right:5px;cursor: pointer;"
                        *ngIf="linkIndex != 0 && !isEditDiabled"
                        (click)="deleteLoginData(linkIndex,courseLoginValue.LinktoCoursesForSelfRegistration)">
                        <div class="yorbitSprite del-icon-grey-small floatRight del-icon-pos"></div>
                      </div>
                    </div>
                    <div fxFlex>
                      <textarea class="textareaField" style="width:95%"
                        [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                        placeholder="{{textareaEmptyPlaceholder}}"
                        [ngClass]="{'textareaEmpty':''==courseLoginValue.LinktoCoursesForSelfRegistration[linkIndex]}"
                        [(ngModel)]="courseLoginValue.LinktoCoursesForSelfRegistration[linkIndex]"
                        (input)="courseLoginChange(linkIndex,'loginCourseLinkSelf',$event.target.value)"></textarea>
                    </div>
                  </div>
                  <div fxFlexAlign="end" style="padding-bottom:5px">
                    <button *ngIf="courseLoginValue.LinktoCoursesForSelfRegistration?.length != 0"
                      [ngClass]="{mouseDisabled: isEditDiabled||!courseLoginValue.LoginType[loginIndex]}"
                      [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                      (click)="addCourseLoginNameAndLink('self','link')" class="actionButton addLinkAndNameBtn">
                      Add Course Link
                    </button>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start end">
                  <div *ngFor="let courseName of courseLoginValue.CoursesNameForSelfRegistration;
                let linkIndex=index; trackBy:trackByFunction" fxFlex="200px" fxLayout="column" fxFlexOffset="10px">
                    <div fxFlex fxLayout="row" style="padding:5px 0px">
                      <div
                        [ngClass]="{'fieldEdited':(courseLoginValue.CoursesNameForSelfRegistration[linkIndex]!=content.CoursesNameForSelfRegistration[linkIndex])}">
                        {{courseLoginInterface.courseLoginType[2].LoginType}}
                      </div>

                      <div fxFlex fxLayoutAlign="end center" style="padding-right:5px;cursor: pointer;"
                        *ngIf="linkIndex != 0 && !isEditDiabled"
                        (click)="deleteLoginData(linkIndex,courseLoginValue.CoursesNameForSelfRegistration)">
                        <div class="yorbitSprite del-icon-grey-small floatRight del-icon-pos"></div>
                      </div>


                    </div>
                    <div fxFlex>
                      <textarea class="textareaField" style="width:95%"
                        [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                        [ngClass]="{'textareaEmpty':''==courseLoginValue.CoursesNameForSelfRegistration[linkIndex]}"
                        placeholder="{{textareaEmptyPlaceholder}}"
                        [(ngModel)]="courseLoginValue.CoursesNameForSelfRegistration[linkIndex]"
                        (input)="courseLoginChange(linkIndex,'loginCourseNameSelf',$event.target.value)"></textarea>
                    </div>
                    <!-- <mat-divider class="divider"></mat-divider> -->
                  </div>
                  <div>
                    <button *ngIf="courseLoginValue.CoursesNameForSelfRegistration?.length != 0"
                      [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                      [ngClass]="{mouseDisabled: isEditDiabled||!courseLoginValue.LoginType[loginIndex]}"
                      (click)="addCourseLoginNameAndLink('self','name')" class="actionButton addLinkAndNameBtn">
                      Add Course Name
                    </button>
                  </div>
                </div>
                <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxFlexOffset="10px">
                  <button *ngIf="courseLoginValue.LinktoCoursesForSelfRegistration?.length == 0 &&
                courseLoginValue.CoursesNameForSelfRegistration?.length == 0"
                    [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                    [ngClass]="{mouseDisabled: isEditDiabled||!courseLoginValue.LoginType[loginIndex]}"
                    (click)="addCourseLoginNameAndLink('self','both')" class="actionButton addLinkAndNameBtn">
                    Add Course Link and Name
                  </button>
                </div>
              </div>
              <div *ngIf="2==loginIndex && courseLoginValue.LoginType[loginIndex]" fxFlexOffset="30px"
                fxLayout="column">
                <div>
                  <div fxFlex="200px" fxFlexOffset="10px">
                    <div [ngClass]="{'fieldEdited':(courseLoginValue.LoginDetails!=content.LoginDetails)}">
                      {{courseLoginInterface.courseLoginType[3].LoginType}}
                    </div>
                    <div>
                      <textarea class="textareaField" style="width:90%" style="width:80%"
                        [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                        [ngClass]="{'textareaEmpty':''==courseLoginValue.LoginDetails}"
                        [(ngModel)]="courseLoginValue.LoginDetails" placeholder="{{textareaEmptyPlaceholder}}"
                        (input)="courseLoginChange('','loginDetails',$event.target.value)"></textarea>
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start end">
                  <div *ngFor="let courseName of courseLoginValue.LinktoCoursesForLearningOPMTeam;
                  let linkIndex=index; trackBy:trackByFunction" fxFlex="200px" fxLayout="column" fxFlexOffset="10px">
                    <div fxFlex fxLayout="row" style="padding:5px 0px">
                      <div
                        [ngClass]="{'fieldEdited':(courseLoginValue.LinktoCoursesForLearningOPMTeam[linkIndex]!=content.LinktoCoursesForLearningOPMTeam[linkIndex])}">
                        {{courseLoginInterface.courseLoginType[4].LoginType}}
                      </div>
                      <div fxFlex fxLayoutAlign="end center" style="padding-right:5px;cursor: pointer;"
                        *ngIf="linkIndex != 0 && !isEditDiabled"
                        (click)="deleteLoginData(linkIndex,courseLoginValue.LinktoCoursesForLearningOPMTeam)">
                        <div class="yorbitSprite del-icon-grey-small floatRight del-icon-pos"></div>
                      </div>
                    </div>
                    <div fxFlex>
                      <textarea class="textareaField" style="width:95%"
                        [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                        [ngClass]="{'textareaEmpty':''==courseLoginValue.LinktoCoursesForLearningOPMTeam[linkIndex]}"
                        [(ngModel)]="courseLoginValue.LinktoCoursesForLearningOPMTeam[linkIndex]"
                        placeholder="{{textareaEmptyPlaceholder}}"
                        (input)="courseLoginChange(linkIndex,'loginCourseLinkOpm',$event.target.value)"></textarea>
                    </div>
                  </div>
                  <div fxFlexAlign="end" style="padding-bottom:5px">
                    <button *ngIf="courseLoginValue.LinktoCoursesForLearningOPMTeam?.length != 0"
                      [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                      [ngClass]="{mouseDisabled: isEditDiabled||!courseLoginValue.LoginType[loginIndex]}"
                      (click)="addCourseLoginNameAndLink('opm','link')" class="actionButton addLinkAndNameBtn">
                      Add Course Link
                    </button>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start end">
                  <div *ngFor="let courseName of courseLoginValue.CoursesNameForLearningOPMTeam;
                let linkIndex=index; trackBy:trackByFunction" fxFlex="200px" fxLayout="column" fxFlexOffset="10px">
                    <div fxFlex fxLayout="row" style="padding:5px 0px">
                      <div
                        [ngClass]="{'fieldEdited':(courseLoginValue.CoursesNameForLearningOPMTeam[linkIndex]!=content.CoursesNameForLearningOPMTeam[linkIndex])}">
                        {{courseLoginInterface.courseLoginType[5].LoginType}}
                      </div>
                      <div fxFlex fxLayoutAlign="end center" style="padding-right:5px;cursor: pointer;"
                        *ngIf="linkIndex != 0 && !isEditDiabled"
                        (click)="deleteLoginData(linkIndex,courseLoginValue.CoursesNameForLearningOPMTeam)">
                        <div class="yorbitSprite del-icon-grey-small floatRight del-icon-pos"></div>
                      </div>
                    </div>
                    <div>
                      <textarea class="textareaField" style="width:95%"
                        [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                        [ngClass]="{'textareaEmpty':''==courseLoginValue.CoursesNameForLearningOPMTeam[linkIndex]}"
                        [(ngModel)]="courseLoginValue.CoursesNameForLearningOPMTeam[linkIndex]"
                        placeholder="{{textareaEmptyPlaceholder}}"
                        (input)="courseLoginChange(linkIndex,'loginCourseNameOpm',$event.target.value)"></textarea>
                    </div>
                  </div>
                  <div fxFlexAlign="end" style="padding-bottom:5px">
                    <button *ngIf="courseLoginValue.CoursesNameForLearningOPMTeam?.length != 0"
                      [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                      [ngClass]="{mouseDisabled: isEditDiabled||!courseLoginValue.LoginType[loginIndex]}"
                      (click)="addCourseLoginNameAndLink('opm','name')" class="actionButton addLinkAndNameBtn">
                      Add Course Name
                    </button>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start" fxFlexOffset="10px">
                  <button *ngIf="courseLoginValue.LinktoCoursesForLearningOPMTeam?.length == 0 &&
                  courseLoginValue.CoursesNameForLearningOPMTeam?.length == 0"
                    [disabled]="isEditDiabled||!courseLoginValue.LoginType[loginIndex]"
                    [ngClass]="{mouseDisabled: isEditDiabled||!courseLoginValue.LoginType[loginIndex]}"
                    (click)="addCourseLoginNameAndLink('opm','both')" class="actionButton addLinkAndNameBtn">
                    Add Course Link and Name
                  </button>
                </div>
              </div>
            </div>
            <div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="!contentLoaded.courseLogin">
      <app-preloader></app-preloader>
    </div>
  </div>
</ng-template>

<ng-template #learningMaterial>
  <div fxFlex fxLayout="column" *ngIf="0!=learningMaterialDetails.length">
    <div class="subHeader">
      Learning Material
    </div>
    <div *ngIf="0<learningMaterialDetails.length" fxLayout="column">
      <div fxFlex class="learningSession" *ngFor="let material of learningMaterialDetails">
        <div class="subHeaderSubTitle" fxLayout="row">{{material.title}}</div>
        <div fxLayout="row">
          <div fxFlex="20">Name of the Learning Material</div>
          <div fxFlex="2">:</div>
          <div fxFlex="75">{{material.name}}</div>
        </div>
        <div fxLayout="row">
          <div fxFlex="20">File containing Learning Material</div>
          <div fxFlex="2">:</div>
          <div fxFlex="75"><a class="anchorTag"
              (click)="openDownloadFileWithSasKey(material.file)">{{material.file}}</a></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #proofOfCourseCompletion>
  <div fxFlex fxLayout="column" *ngIf="content.CourseCompletionGroup">
    <div class="subHeader">
      Proof of Course Completion
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="space-around stretch" *ngIf="contentLoaded.courseCompletion">
      <div fxFlex="220px"
        *ngFor="let courseCompletionObj of courseCompletionInterface?.courseCompletion;let completionIndex=index">
        <div fxLayout="column" class="">
          <mat-checkbox [disabled]="isEditDiabled" color="baseblue"
            [ngClass]="{'fieldEdited':(courseCompletionValue.courseCompletion[completionIndex]!==content.CourseCompletionGroup.CourseCompletionFormArray[completionIndex])}"
            [(ngModel)]="courseCompletionValue.courseCompletion[completionIndex]">
            {{courseCompletionObj.Description}}
          </mat-checkbox>
          <div *ngIf="4!=completionIndex">

            <div *ngFor="let courseCompletionTypeObj of courseCompletionInterface.courseCompletionType;let index=index">

              <div *ngIf="(completionIndex+1)==courseCompletionTypeObj.CourseCompletionId
              && 0!=(index+1) % 3">
                <div
                  [ngClass]="{'fieldEdited':(courseCompletionValue.courseCompletionType[courseCompletionTypeObj.Id-1]!=content.CourseCompletionGroup.CourseCompletionTypeFormArray[courseCompletionTypeObj.Id-1])}">
                  {{courseCompletionTypeObj.TypeDescription}}
                </div>
                <div>
                  <textarea class="textareaField"
                    [disabled]="isEditDiabled||!courseCompletionValue.courseCompletion[completionIndex]"
                    [ngClass]="{'textareaEmpty':''==courseCompletionValue.courseCompletionType[courseCompletionTypeObj.Id-1]}"
                    placeholder="{{textareaEmptyPlaceholder}}"
                    [(ngModel)]="courseCompletionValue.courseCompletionType[courseCompletionTypeObj.Id-1]">
                </textarea>
                </div>
              </div>

              <div *ngIf="(completionIndex+1)==courseCompletionTypeObj.CourseCompletionId
              && 0==(index+1) % 3">
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px"
                  [ngClass]="{'fieldEdited':(courseCompletionValue.courseCompletionType[courseCompletionTypeObj.Id-1]!=content.CourseCompletionGroup.CourseCompletionTypeFormArray[courseCompletionTypeObj.Id-1])}">
                  {{courseCompletionTypeObj.TypeDescription}}
                  <a class="breakWordOverflow anchorTag"
                    (click)="openDownloadFileWithSasKey(courseCompletionValue.courseCompletionType[courseCompletionTypeObj.Id-1])">
                    {{courseCompletionValue.courseCompletionType[courseCompletionTypeObj.Id-1]}}
                  </a>
                  <input type="file" id="courseCompletionTemplateFile" YorbitWorkspaceFileModel
                    [modifyDOMElements]="false"
                    (change)="fileUpdate($event.target.files,'courseCompletionTemplate',index)"
                    [disabled]="isEditDiabled || !courseCompletionValue.courseCompletion[completionIndex]"
                    [ngClass]="{mouseDisabled: isEditDiabled}" />
                  <div class="file-upload-msg" *ngIf="!isEditDiabled"
                    [ngClass]="{'error-msg':canUploadFile['courseCompletionTemplate']==undefined?false:(canUploadFile['courseCompletionTemplate'][index]==undefined?false:!canUploadFile['courseCompletionTemplate'][index])}">
                    Please choose a zip/7z file of size
                    <=5 MB. </div>
                      <button class="actionButton marginUpAndDown"
                        [ngClass]="{mouseDisabled: uploadStatus.courseCompletionTemplate[index]!='Upload' || isEditDiabled || (!isEditDiabled && canUploadFile['courseCompletionTemplate']==undefined?true:(canUploadFile['courseCompletionTemplate'][index]==undefined?true:!canUploadFile['courseCompletionTemplate'][index]))}"
                        [disabled]="uploadStatus.courseCompletionTemplate[index]!='Upload' ||  isEditDiabled || (!isEditDiabled && canUploadFile['courseCompletionTemplate']==undefined?true:(canUploadFile['courseCompletionTemplate'][index]==undefined?true:!canUploadFile['courseCompletionTemplate'][index]))"
                        (click)="
                        uploadFile(
                          'courseCompletionTemplate',
                          index
                        )">{{uploadStatus.courseCompletionTemplate[index] ?
                        uploadStatus.courseCompletionTemplate[index] : "Upload"}}</button>

                      <!--
                  only for course completion Certificate
                -->
                      <!-- <div *ngIf="completionIndex==0">
                        Please enter Status & Sub-Status for course on Certificate acceptance completion
                        <div> -->

                      <!-- <div class="panelMargin">
                      <div ngClass="fieldLabel">
                        <label>Course Status</label>
                      </div>
                      <div ngClass="fieldInput">
                        <select [(ngModel)]="PssCertStatus"
                        title="{{PssCertStatus}}"
                         class="textareaDiv PssStatusAndSub" (change)="setPssStatus($event,'Status')"
                         [ngClass]="{mouseDisabled: isEditDiabled}" [disabled]="isEditDiabled">
                          <option [ngValue]="null" disabled selected >Please select a Status</option>
                          <option style="background-color:rgba(0,0,0,.07)" *ngFor="let StatusObj of certificateStatusList;let statusIndex=index"
                          value="{{StatusObj.Status}}">{{StatusObj.Status}}</option>
                    </select>
                            </div>
                          </div> -->

                      <!-- <div class="panelMargin">
                      <div ngClass="fieldLabel">
                        <label>Course Sub-Status</label>
                      </div>
                      <div ngClass="fieldInput">
                        <select [(ngModel)]="PssCertSubStatus"
                        title="{{PssCertSubStatus}}"
                         class="textareaDiv PssStatusAndSub" (change)="setPssStatus($event,'SubStatus')"
                        [disabled]="isEditDiabled||(null==certificateSeletedState.Status)"
                        [ngClass]="{mouseDisabled: isEditDiabled}">
                          <option [ngValue]="null" disabled selected >Please select a Sub-Status</option>
                          <option style="background-color:rgba(0,0,0,.07)" *ngFor="let SubStatus of certificateStatusList[certificateSeletedState.StatusIndex].SubStatus;let statusIndex=index"
                          value="{{SubStatus}}">{{SubStatus}}</option>
                    </select>
                            </div>
                          </div> -->

                  </div>
                  <!--
    only for course completion Certificate
                -->
                </div>
              </div>
            </div>
          </div>
          <div>
          </div>
          <div>
          </div>
        </div>
      </div>
      <div *ngIf="!contentLoaded.courseCompletion">
        <app-preloader></app-preloader>
      </div>
    </div>
</ng-template>

<ng-template #classRoom>
  <div fxFlex fxLayout="column" *ngIf="classRoomDetails">
    <div class="subHeader">
      Class Room
    </div>
    <div *ngIf="classRoomDetails&&classRoomDetails.ClassRoom">
      <div class="marginBottom" *ngIf="classRoomDetails.ClassroomExecution">
        Classroom Execution : {{("1"==classRoomDetails.ClassroomExecution)? 'Managed by Vendor' : 'Managed by Learning
        OPM Team'}}
      </div>
      <div *ngFor="let classroom of classRoomDetails.ClassRoom;let classroomIndex=index">
        <div *ngIf="classroom.ClassroomExecution">
          Classroom Execution :{{("1"==classroom.ClassroomExecution)? 'Managed by Vendor' : 'Managed by Learning OPM
          Team'}}
        </div>
        <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20">Session No</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            {{classroom.NoOfClassRoomSessions}}
          </div>
        </div>
        <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20">Name of the Class room Session</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            {{classroom.NameOfClassRoomSessions}}
          </div>
        </div>
        <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20">Topics Covered</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            {{classroom.ClassroomDescription}}
          </div>
        </div>
        <!-- <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20"
            [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].VendorOrFaultyName!==content.ClassRoomGroup.ClassRoom[classroomIndex].VendorOrFaultyName)}">
            Name of Vendor</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            <textarea class="textareaField textareaLine" [disabled]="isEditDiabled"
              [ngClass]="{'textareaEmpty':''==classRoomDetails.ClassRoom[classroomIndex].VendorOrFaultyName}"
              placeholder="{{textareaEmptyPlaceholder}}"
              [(ngModel)]="classRoomDetails.ClassRoom[classroomIndex].VendorOrFaultyName"></textarea>
            (input)="classRoomDetails.ClassRoom[classroomIndex].VendorOrFaultyName=$event.target.value"
          </div>
        </div> -->
        <!-- <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20">Assignment Required?</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            {{classroom.IsAssignmentRequired}}
          </div>
        </div> -->
        <!-- <div fxLayout="row" class="field-data" *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no'">
          <div class="field-label" fxFlex="20">Assignment Description</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            {{classroom.AssignmentDescription}}
          </div>
        </div> -->
        <!-- <div fxLayout="row" class="field-data" *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no'">
          <div class="field-label" fxFlex="20"
            [ngClass]="{'fieldEdited':editedStatus.classRoomAssignmentProblemStatement[classroomIndex]}">Assignment
            Problem Statement</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            <div fxFlex fxLayout="row" fxLayoutAlign="start start">
              <a class="anchorTag"  (click)="openDownloadFileWithSasKey(classroom.AssignmentContentLink)"
                [ngClass]="{'space-after-href': (classroom && classroom.AssignmentContentLink != '')}">
                {{classroom.AssignmentContentLink}}
              </a>
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" flexLayoutAlign="start start">

                  <input type="file" id="assignmentProblemStatement{{classroomIndex}}" YorbitWorkspaceFileModel
                    [modifyDOMElements]="false"
                    (change)="fileUpdate($event.target.files,'ClassRoomAssignmentProblemStatement',classroomIndex)"
                    [disabled]="isEditDiabled" [ngClass]="{mouseDisabled: isEditDiabled}" />
                  <div class="file-upload-msg" *ngIf="!isEditDiabled"
                    [ngClass]="{'error-msg':canUploadFile['ClassRoomAssignmentProblemStatement']==undefined?false:(canUploadFile['ClassRoomAssignmentProblemStatement'][classroomIndex]==undefined?false:!canUploadFile['ClassRoomAssignmentProblemStatement'][classroomIndex])}">
                    Please choose a zip/7z file of size
                    <=5 MB. </div>
                  </div>
                  <button class="actionButton marginAfter"
                    [ngClass]="{mouseDisabled:uploadStatus.classRoomAssignmentProblemStatement[classroomIndex]!='Upload'|| isEditDiabled ||(!isEditDiabled && canUploadFile['ClassRoomAssignmentProblemStatement']==undefined?true:(canUploadFile['ClassRoomAssignmentProblemStatement'][classroomIndex]==undefined?true:!canUploadFile['ClassRoomAssignmentProblemStatement'][classroomIndex]))}"
                    [disabled]="uploadStatus.classRoomAssignmentProblemStatement[classroomIndex]!='Upload'||isEditDiabled || (!isEditDiabled && canUploadFile['ClassRoomAssignmentProblemStatement']==undefined?true:(canUploadFile['ClassRoomAssignmentProblemStatement'][classroomIndex]==undefined?true:!canUploadFile['ClassRoomAssignmentProblemStatement'][classroomIndex]))"
                    (click)="
                uploadFile(
                  'ClassRoomAssignmentProblemStatement',
                  classroomIndex
                )">{{uploadStatus.classRoomAssignmentProblemStatement[classroomIndex]}}</button>
                </div>
              </div>
            </div>
          </div> -->
        <!-- <div fxLayout="row" class="field-data" *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no'">
            <div class="field-label" fxFlex="20"
              [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].IsEvaluationRequired!==content.ClassRoomGroup.ClassRoom[classroomIndex].IsEvaluationRequired)}">
              Evaluation required for Assignment?</div>
            <div class="field-label" fxFlex="2">:</div>
            <div class="field-value" fxFlex="75" [ngClass]="{mouseDisabled: isEditDiabled}">
              <input type="radio"
                name="{{classroom.NameOfClassRoomSessions+classroomIndex}}classRoomDetailsIsEvaluationRequired"
                [disabled]="true || isEditDiabled || content.ClassRoomGroup.ClassRoom[classroomIndex].IsEvaluationRequired.toLowerCase() =='no'"
                [checked]="'Yes'===classroom.IsEvaluationRequired" [value]="'Yes'"
                [(ngModel)]="classroom.IsEvaluationRequired" />Yes
              <input type="radio"
                name="{{classroom.NameOfClassRoomSessions+classroomIndex}}classRoomDetailsIsEvaluationRequired"
                [disabled]="true || isEditDiabled || content.ClassRoomGroup.ClassRoom[classroomIndex].IsEvaluationRequired.toLowerCase() =='no'"
                [checked]="'No'===classroom.IsEvaluationRequired" [value]="'No'"
                [(ngModel)]="classroom.IsEvaluationRequired" />No
            </div>
          </div> -->


        <!-- <div fxLayout="row" class="field-data"
              *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no' && 'Yes'===classRoomDetails.ClassRoom[classroomIndex].IsEvaluationRequired">
              <div class="field-label" fxFlex="20"
                [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorType!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorType)}">
                Evaluator Type</div>
              <div class="field-label" fxFlex="2">:</div>
              <div class="field-value" fxFlex="75" [ngClass]="{mouseDisabled: isEditDiabled}">
                <input type="radio"
                  name="{{classroom.NameOfClassRoomSessions+classroomIndex}}classRoomDetailsEvaluatorType"
                  [disabled]="isEditDiabled"
                  [checked]="'Internal'===classRoomDetails.ClassRoom[classroomIndex].EvaluatorType" [value]="'Internal'"
                  [(ngModel)]="classRoomDetails.ClassRoom[classroomIndex].EvaluatorType"
                  (change)="internalExternalRadioChange('classroom',classroomIndex)" />Internal
                <input type="radio"
                  name="{{classroom.NameOfClassRoomSessions+classroomIndex}}classRoomDetailsEvaluatorType"
                  [disabled]="isEditDiabled"
                  [checked]="'External'===classRoomDetails.ClassRoom[classroomIndex].EvaluatorType" [value]="'External'"
                  [(ngModel)]="classRoomDetails.ClassRoom[classroomIndex].EvaluatorType"
                  (change)="internalExternalRadioChange('classroom',classroomIndex)" />External
              </div>
            </div> -->

        <!-- <div
            *ngIf="'Internal'===classRoomDetails.ClassRoom[classroomIndex].EvaluatorType && classroom.IsEvaluationRequired.toLowerCase() =='yes'">
            <yorbit-search-evaluator class="mousePointer" *ngIf="!isEditDiabled"
              [evaluatorDetails]="{'type':'classroom','list':classRoomDetails.ClassRoom[classroomIndex].EvaluatorEmailID}"
              (updateEvaluatorFromPopup)="updateEvaluatorFromPopup($event,'classroom',classroomIndex)">
            </yorbit-search-evaluator>

            <div fxLayout="row" class="field-data" *ngIf="''!=classRoomDetails.ClassRoom[classroomIndex].EvaluatorName">
              <div class="field-label" fxFlex="20"
                [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorName!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorName)}">
                Evaluator's Name</div>
              <div class="field-label" fxFlex="2">:</div>
              <div class="field-value" fxFlex="75">
                <div class="field-label"
                  [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorName!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorName)}">
                  {{classRoomDetails.ClassRoom[classroomIndex].EvaluatorName}}</div>
              </div>
            </div>
            <div fxLayout="row" class="field-data" *ngIf="''!=classRoomDetails.ClassRoom[classroomIndex].EvaluatorName">
              <div class="field-label" fxFlex="20"
                [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorEmailID!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorEmailID)}">
                Evaluator's Email ID</div>
              <div class="field-label" fxFlex="2">:</div>
              <div class="field-value" fxFlex="75">
                <div class="field-label"
                  [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorEmailID!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorEmailID)}">
                  {{classRoomDetails.ClassRoom[classroomIndex].EvaluatorEmailID}}</div>
              </div>
            </div>
            <div fxLayout="row" class="field-data" *ngIf="''!=classRoomDetails.ClassRoom[classroomIndex].EvaluatorName">
              <div class="field-label" fxFlex="20"
                [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorVendor!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorVendor)}">
                Evaluators Vendor</div>
              <div class="field-label" fxFlex="2">:</div>
              <div class="field-value" fxFlex="75">
                <div class="field-label"
                  [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorVendor!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorVendor)}">
                  {{classRoomDetails.ClassRoom[classroomIndex].EvaluatorVendor}}</div>
              </div>
            </div>
          </div> -->
        <!-- <div
          *ngIf="'External'===classRoomDetails.ClassRoom[classroomIndex].EvaluatorType && classroom.IsEvaluationRequired.toLowerCase() =='yes'">
          <div fxLayout="row" class="field-data"
            *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no' && 'Yes'===classRoomDetails.ClassRoom[classroomIndex].IsEvaluationRequired">
            <div class="field-label" fxFlex="20"
              [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorName!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorName)}">
              Evaluators Name</div>
            <div class="field-label" fxFlex="2">:</div>
            <div class="field-value" fxFlex="75">
              <textarea class="textareaField textareaLine" [disabled]="isEditDiabled"
                [(ngModel)]="classRoomDetails.ClassRoom[classroomIndex].EvaluatorName"
                [ngClass]="{'textareaEmpty':''==classRoomDetails.ClassRoom[classroomIndex].EvaluatorName}"
                placeholder="{{textareaEmptyPlaceholder}}"></textarea>
              (input)="classRoomDetails.ClassRoom[classroomIndex].EvaluatorName=$event.target.value"
            </div>
          </div>
          <div fxLayout="row" class="field-data"
            *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no' && 'Yes'===classRoomDetails.ClassRoom[classroomIndex].IsEvaluationRequired">
            <div class="field-label" fxFlex="20"
              [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorEmailID!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorEmailID)}">
              Evaluators Email ID</div>
            <div class="field-label" fxFlex="2">:</div>
            <div class="field-value" fxFlex="75">
              <textarea class="textareaField textareaLine" [disabled]="isEditDiabled"
                [ngClass]="{'textareaEmpty':''==classRoomDetails.ClassRoom[classroomIndex].EvaluatorEmailID}"
                placeholder="{{textareaEmptyPlaceholder}}"
                [(ngModel)]="classRoomDetails.ClassRoom[classroomIndex].EvaluatorEmailID"></textarea>
              (input)="classRoomDetails.ClassRoom[classroomIndex].EvaluatorEmailID=$event.target.value"
            </div>
          </div>
          <div fxLayout="row" class="field-data"
            *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no' && 'Yes'===classRoomDetails.ClassRoom[classroomIndex].IsEvaluationRequired">
            <div class="field-label" fxFlex="20"
              [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].EvaluatorVendor!==content.ClassRoomGroup.ClassRoom[classroomIndex].EvaluatorVendor)}">
              Evaluators Vendor Name</div>
            <div class="field-label" fxFlex="2">:</div>
            <div class="field-value" fxFlex="75">
              <textarea class="textareaField textareaLine" [disabled]="isEditDiabled"
                [(ngModel)]="classRoomDetails.ClassRoom[classroomIndex].EvaluatorVendor"
                [ngClass]="{'textareaEmpty':''==classRoomDetails.ClassRoom[classroomIndex].EvaluatorVendor}"
                placeholder="{{textareaEmptyPlaceholder}}"></textarea>
              (input)="classRoomDetails.ClassRoom[classroomIndex].EvaluatorVendor=$event.target.value"
            </div>
          </div>
        </div> -->
        <!-- <div fxLayout="row" class="field-data"
          *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no' && 'Yes'===classRoomDetails.ClassRoom[classroomIndex].IsEvaluationRequired">
          <div class="field-label" fxFlex="20"
            [ngClass]="{'fieldEdited':(classRoomDetails.ClassRoom[classroomIndex].LocationofEvaluator!==content.ClassRoomGroup.ClassRoom[classroomIndex].LocationofEvaluator)}">
            Location of Evaluator</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75" [ngClass]="{mouseDisabled: isEditDiabled}">
            <input type="radio"
              name="{{classroom.NameOfClassRoomSessions+classroomIndex}}classRoomDetailsLocationofEvaluator"
              [disabled]="isEditDiabled"
              [checked]="'Onsite'===classRoomDetails.ClassRoom[classroomIndex].LocationofEvaluator" [value]="'Onsite'"
              [(ngModel)]="classRoomDetails.ClassRoom[classroomIndex].LocationofEvaluator" />Onsite
            <input type="radio"
              name="{{classroom.NameOfClassRoomSessions+classroomIndex}}classRoomDetailsLocationofEvaluator"
              [disabled]="isEditDiabled"
              [checked]="'Offshore'===classRoomDetails.ClassRoom[classroomIndex].LocationofEvaluator"
              [value]="'Offshore'"
              [(ngModel)]="classRoomDetails.ClassRoom[classroomIndex].LocationofEvaluator" />Offshore
          </div>
        </div> -->
        <!-- <div fxLayout="row" class="field-data"
          *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no' && 'Yes'===classRoomDetails.ClassRoom[classroomIndex].IsEvaluationRequired"
          [ngClass]="{'fieldEdited':editedStatus.classRoomEvaluationTemplate[classroomIndex]}">
          <div class="field-label" fxFlex="20"
            [ngClass]="{'fieldEdited':editedStatus.classRoomEvaluationTemplate[classroomIndex]}">Evaluation Template
            for Assignment</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            <div fxFlex fxLayout="row" fxLayoutAlign="space-around stretch">

              <div class="space-after-href" *ngIf="''==classRoomDetails.ClassRoom[classroomIndex].EvaluationTemplateLink
                ||null==classRoomDetails.ClassRoom[classroomIndex].EvaluationTemplateLink">
                Not Available
              </div>

              <a class="anchorTag"
                 (click)="openDownloadFileWithSasKey(classRoomDetails.ClassRoom[classroomIndex].EvaluationTemplateLink)"
                [ngClass]="{'space-after-href': (classroom && classRoomDetails.ClassRoom[classroomIndex].EvaluationTemplateLink != '')}">
                {{classRoomDetails.ClassRoom[classroomIndex].EvaluationTemplateLink}}
              </a>
              <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="start start">

                  <input type="file" id="classEvaluationTemplateFile" YorbitWorkspaceFileModel
                    [modifyDOMElements]="false"
                    (change)="fileUpdate($event.target.files,'ClassRoomEvaluationTemplate',classroomIndex)"
                    [disabled]="isEditDiabled" [ngClass]="{mouseDisabled: isEditDiabled}" />
                  <div class="file-upload-msg" *ngIf="!isEditDiabled"
                    [ngClass]="{'error-msg': canUploadFile['ClassRoomEvaluationTemplate']==undefined?false:(canUploadFile['ClassRoomEvaluationTemplate'][classroomIndex]==undefined?false:!canUploadFile['ClassRoomEvaluationTemplate'][classroomIndex])}">
                    Please choose a zip/7z file
                    <=5 MB. </div>
                  </div>
                  <button class="actionButton marginAfter"
                    [ngClass]="{mouseDisabled:uploadStatus.classRoomEvaluationTemplate[classroomIndex]!='Upload' || isEditDiabled || (!isEditDiabled && canUploadFile['ClassRoomEvaluationTemplate']==undefined?true:(canUploadFile['ClassRoomEvaluationTemplate'][classroomIndex]==undefined?true:!canUploadFile['ClassRoomEvaluationTemplate'][classroomIndex]))}"
                    [disabled]="uploadStatus.classRoomEvaluationTemplate[classroomIndex]!='Upload' || isEditDiabled || (!isEditDiabled && canUploadFile['ClassRoomEvaluationTemplate']==undefined?true:(canUploadFile['ClassRoomEvaluationTemplate'][classroomIndex]==undefined?true:!canUploadFile['ClassRoomEvaluationTemplate'][classroomIndex]))"
                    (click)="
                  uploadFile(
                    'ClassRoomEvaluationTemplate',
                    classroomIndex
                  )">{{uploadStatus.classRoomEvaluationTemplate[classroomIndex]}}</button>
                </div>
              </div>
            </div>
          </div> -->
        <!-- <div fxLayout="row" class="field-data" *ngIf="false">
            <div class="field-label" fxFlex="20">Is Classroom Scheduled?</div>
            <div class="field-label" fxFlex="2">:</div>
            <div class="field-value" fxFlex="75">
              {{classroom.IsClassroomScheduled}}
            </div>
          </div>
          <div fxLayout="row" class="field-data" *ngIf="false">
            <div class="field-label" fxFlex="20">Schedule for Each Classroom Session</div>
            <div class="field-label" fxFlex="2">:</div>
            <div class="field-value" fxFlex="75">
              {{classroom.ClassroomScheduledDateTime|date}}
            </div>
          </div>
          <div fxLayout="row" class="field-data"
            *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no' && 'Yes'===classRoomDetails.ClassRoom[classroomIndex].IsEvaluationRequired">
            <div class="field-label" fxFlex="20">Max Score</div>
            <div class="field-label" fxFlex="2">:</div>
            <div class="field-value" fxFlex="75">
              {{classRoomDetails.ClassRoom[classroomIndex].MaxScore}}
            </div>
          </div> -->

        <!-- <div fxLayout="row" class="field-data"
            *ngIf="classroom.IsAssignmentRequired.toLowerCase()!='no' && 'Yes'===classRoomDetails.ClassRoom[classroomIndex].IsEvaluationRequired">
            <div class="field-label" fxFlex="20">Passing Score</div>
            <div class="field-label" fxFlex="2">:</div>
            <div class="field-value" fxFlex="75">
              {{classRoomDetails.ClassRoom[classroomIndex].PassingScore}}
            </div>
          </div> -->
      </div>
    </div>
  </div>
  <!-- </div>
  </div> -->
</ng-template>

<ng-template #projectWork>
  <div *ngIf="projectWorkDetails && content.ProjectWork">
    <div class="subHeader">
      Project Work
    </div>
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="20"
        [ngClass]="{'fieldEdited':(projectWorkDetails.HasJumbleProject!==content.ProjectGroup.HasJumbleProject)}">Jumble
        Project Problem Statement</div>
      <div class="field-label" fxFlex="2">:</div>
      <div class="field-value" fxFlex="75" [ngClass]="{mouseDisabled: isEditDiabled}">
        <input type="radio" name="projectWorkDetailsHasJumbleProject" [disabled]="isEditDiabled || true"
          [checked]="projectWorkDetails.HasJumbleProject" [value]="true"
          [(ngModel)]="projectWorkDetails.HasJumbleProject" />Yes
        <input type="radio" name="projectWorkDetailsHasJumbleProject" [disabled]="isEditDiabled || true"
          [checked]="!projectWorkDetails.HasJumbleProject" [value]="false"
          [(ngModel)]="projectWorkDetails.HasJumbleProject" />No
      </div>
    </div>

    <div *ngFor="let projectWork of content.ProjectGroup.ProjectWorkDetails;let projectIndex=index">
      <div class="subHeaderSubTitle">Project {{projectIndex+1}}</div>
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Project Description</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          {{projectWork.ProjectDescription}}
        </div>
      </div>
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Max Score</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          {{projectWork.MaxScore}}
        </div>
      </div>
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Passing Score</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          {{projectWork.PassingScore}}
        </div>
      </div>
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Does Course have Demo?</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          {{projectWork.ProjectDemo}}
        </div>
      </div>
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20"
          [ngClass]="{'fieldEdited':editedStatus.projectWorkProjectMaterial[projectIndex]}">Project Reference Material
        </div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          <div fxFlex fxLayout="row" fxLayoutAlign="space-around stretch">

            <div class="space-after-href" *ngIf="''==projectWorkDetails.ProjectWorkDetails[projectIndex].ProjectMaterialLink
            ||null==projectWorkDetails.ProjectWorkDetails[projectIndex].ProjectMaterialLink">
              Not Available
            </div>

            <a class="anchorTag"
               (click)="openDownloadFileWithSasKey(projectWorkDetails.ProjectWorkDetails[projectIndex].ProjectMaterialLink)"
              [ngClass]="{'space-after-href': (projectWorkDetails && projectWorkDetails.ProjectWorkDetails[projectIndex].ProjectMaterialLink != '')}">
              {{projectWorkDetails.ProjectWorkDetails[projectIndex].ProjectMaterialLink}}
            </a>

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="column" fxLayoutAlign="start start">

                <input type="file" id="projProjectMaterialFile" [modifyDOMElements]="false" YorbitWorkspaceFileModel
                  [disabled]="isEditDiabled" [ngClass]="{mouseDisabled: isEditDiabled}"
                  (change)="fileUpdate($event.target.files,'ProjectMaterial',projectIndex)" />
                <div class="file-upload-msg" *ngIf="!isEditDiabled"
                  [ngClass]="{'error-msg':  canUploadFile['ProjectMaterial']==undefined?false:(canUploadFile['ProjectMaterial'][projectIndex]==undefined?false:!canUploadFile['ProjectMaterial'][projectIndex])}">
                  Please choose a zip/7z file of size
                  <=5 MB. </div>
                </div>
                <button class="actionButton marginAfter"
                  [disabled]="uploadStatus.projectWorkProjectMaterial[projectIndex]!='Upload'||isEditDiabled || (!isEditDiabled && canUploadFile['ProjectMaterial']==undefined?true:(canUploadFile['ProjectMaterial'][projectIndex]==undefined?true:!canUploadFile['ProjectMaterial'][projectIndex]))"
                  [ngClass]="{mouseDisabled:uploadStatus.projectWorkProjectMaterial[projectIndex]!='Upload'|| isEditDiabled || (!isEditDiabled && canUploadFile['ProjectMaterial']==undefined?true:(canUploadFile['ProjectMaterial'][projectIndex]==undefined?true:!canUploadFile['ProjectMaterial'][projectIndex]))}"
                  (click)="uploadFile('ProjectMaterial',projectIndex)">{{uploadStatus.projectWorkProjectMaterial[projectIndex]}}</button>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20">Project Problem Statement</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            <a class="anchorTag"  (click)="openDownloadFileWithSasKey(projectWork.ProblemStatementLink)">
              {{projectWork.ProblemStatementLink}}
            </a>
          </div>
        </div>
        <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20"
            [ngClass]="{'fieldEdited':editedStatus.projectWorkEvaluationTemplate[projectIndex]}">Evaluation Template
          </div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            <div fxFlex fxLayout="row" fxLayoutAlign="space-around stretch">
              <div class="space-after-href" *ngIf="''==projectWorkDetails.ProjectWorkDetails[projectIndex].EvaluationTemplateLink
              ||null==projectWorkDetails.ProjectWorkDetails[projectIndex].EvaluationTemplateLink">
                Not Available
              </div>

              <a class="anchorTag"
                 (click)="openDownloadFileWithSasKey(projectWorkDetails.ProjectWorkDetails[projectIndex].EvaluationTemplateLink)"
                [ngClass]="{'space-after-href': (projectWorkDetails && projectWorkDetails.ProjectWorkDetails[projectIndex].EvaluationTemplateLink != '')}">
                {{projectWorkDetails.ProjectWorkDetails[projectIndex].EvaluationTemplateLink}}
              </a>
              <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="start start">

                  <input type="file" id="projEvaluationTemplateFile" [modifyDOMElements]="false"
                    YorbitWorkspaceFileModel [disabled]="isEditDiabled" [ngClass]="{mouseDisabled: isEditDiabled}"
                    (change)="fileUpdate($event.target.files,'ProjectWorkDetailsEvaluationTemplate',projectIndex)" />
                  <div class="file-upload-msg" *ngIf="!isEditDiabled"
                    [ngClass]="{'error-msg': canUploadFile['ProjectWorkDetailsEvaluationTemplate']==undefined?false:(canUploadFile['ProjectWorkDetailsEvaluationTemplate'][projectIndex]==undefined?false:!canUploadFile['ProjectWorkDetailsEvaluationTemplate'][projectIndex])}">
                    Please choose a zip/7z file of size
                    <=5 MB. </div>
                  </div>
                  <button class="actionButton marginAfter"
                    [disabled]="uploadStatus.projectWorkEvaluationTemplate[projectIndex]!='Upload'|| isEditDiabled || (!isEditDiabled && canUploadFile['ProjectWorkDetailsEvaluationTemplate']==undefined?true:(canUploadFile['ProjectWorkDetailsEvaluationTemplate'][projectIndex]==undefined?true:!canUploadFile['ProjectWorkDetailsEvaluationTemplate'][projectIndex]))"
                    [ngClass]="{mouseDisabled:uploadStatus.projectWorkEvaluationTemplate[projectIndex]!='Upload'|| isEditDiabled || (!isEditDiabled && canUploadFile['ProjectWorkDetailsEvaluationTemplate']==undefined?true:(canUploadFile['ProjectWorkDetailsEvaluationTemplate'][projectIndex]==undefined?true:!canUploadFile['ProjectWorkDetailsEvaluationTemplate'][projectIndex]))}"
                    (click)="uploadFile('ProjectWorkDetailsEvaluationTemplate',projectIndex)">{{uploadStatus.projectWorkEvaluationTemplate[projectIndex]}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20"
            [ngClass]="{'fieldEdited':(projectWorkDetails.HasProjectEvaluation!==content.ProjectGroup.HasProjectEvaluation)}">
            Evaluation Required for Project?</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75" [ngClass]="{mouseDisabled: isEditDiabled}">
            <input type="radio" name="projectWorkDetailsHasProjectEvaluation"
              [disabled]="true || isEditDiabled || !content.ProjectGroup.HasProjectEvaluation"
              [checked]="projectWorkDetails.HasProjectEvaluation" [value]="true"
              [(ngModel)]="projectWorkDetails.HasProjectEvaluation" />Yes
            <input type="radio" name="projectWorkDetailsHasProjectEvaluation"
              [disabled]="true || isEditDiabled || !content.ProjectGroup.HasProjectEvaluation"
              [checked]="!projectWorkDetails.HasProjectEvaluation" [value]="false"
              [(ngModel)]="projectWorkDetails.HasProjectEvaluation" />No
          </div>
        </div>

        <div *ngIf="projectWorkDetails.HasProjectEvaluation">
          <div *ngFor="let projectEvaluation of content.ProjectGroup.ProjectEvaluationDetails;let projectIndex=index;">
            <div class="subHeaderSubTitle">Evaluator {{projectIndex+1}}</div>

            <div fxLayout="row" class="field-data">
              <div class="field-label" fxFlex="20"
                [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorType!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorType)}">
                Evaluator Type</div>
              <div class="field-label" fxFlex="2">:</div>
              <div class="field-value" fxFlex="75" [ngClass]="{mouseDisabled: isEditDiabled}">
                <input type="radio" name="projectWorkDetailsEvaluatorType+{{projectIndex}}" [disabled]="isEditDiabled"
                  [checked]="'Internal'===projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorType"
                  [value]="'Internal'"
                  [(ngModel)]="projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorType"
                  (change)="internalExternalRadioChange('project',projectIndex)" />Internal
                <input type="radio" name="projectWorkDetailsEvaluatorType+{{projectIndex}}" [disabled]="isEditDiabled"
                  [checked]="'External'===projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorType"
                  [value]="'External'"
                  [(ngModel)]="projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorType"
                  (change)="internalExternalRadioChange('project',projectIndex)" />External
              </div>
            </div>

            <div *ngIf="'Internal'===projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorType">
              <yorbit-search-evaluator class="mousePointer" *ngIf="!isEditDiabled"
                [evaluatorDetails]="{'type':'project','list':projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID}"
                (updateEvaluatorFromPopup)="updateEvaluatorFromPopup($event,'project',projectIndex)">
              </yorbit-search-evaluator>

              <div fxLayout="row" class="field-data"
                *ngIf="''!=projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName">
                <div class="field-label" fxFlex="20"
                  [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorName)}">
                  Evaluator's Name</div>
                <div class="field-label" fxFlex="2">:</div>
                <div class="field-value" fxFlex="75">
                  <div class="field-label"
                    [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorName)}">
                    {{projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName}}</div>
                </div>
              </div>
              <div fxLayout="row" class="field-data"
                *ngIf="''!=projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName">
                <div class="field-label" fxFlex="20"
                  [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID)}">
                  Evaluator's Email ID</div>
                <div class="field-label" fxFlex="2">:</div>
                <div class="field-value" fxFlex="75">
                  <div class="field-label"
                    [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID)}">
                    {{projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID}}</div>
                </div>
              </div>
              <div fxLayout="row" class="field-data"
                *ngIf="''!=projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName">
                <div class="field-label" fxFlex="20"
                  [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorVendor!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorVendor)}">
                  Evaluator's Vendor</div>
                <div class="field-label" fxFlex="2">:</div>
                <div class="field-value" fxFlex="75">
                  <div class="field-label"
                    [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorVendor!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorVendor)}">
                    {{projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorVendor}}</div>
                </div>
              </div>
            </div>

            <div *ngIf="'External'===projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorType">
              <div fxLayout="row" class="field-data">
                <div class="field-label" fxFlex="20"
                  [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorName)}">
                  Evaluator's Name</div>
                <div class="field-label" fxFlex="2">:</div>
                <div class="field-value" fxFlex="75">
                  <textarea class="textareaField textareaLine" [disabled]="isEditDiabled"
                    [ngClass]="{'textareaEmpty':''==projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName}"
                    placeholder="{{textareaEmptyPlaceholder}}"
                    [(ngModel)]="projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName">
              <!-- (input)="projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorName=$event.target.value" -->
              </textarea>
                </div>
              </div>
              <div fxLayout="row" class="field-data">
                <div class="field-label" fxFlex="20"
                  [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID)}">
                  Evaluator's Email ID</div>
                <div class="field-label" fxFlex="2">:</div>
                <div class="field-value" fxFlex="75">
                  <textarea class="textareaField textareaLine" [disabled]="isEditDiabled"
                    [ngClass]="{'textareaEmpty':''==projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID}"
                    placeholder="{{textareaEmptyPlaceholder}}"
                    [(ngModel)]="projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID">
              <!-- (input)="projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorEmailID=$event.target.value" -->
              </textarea>
                </div>
              </div>
              <div fxLayout="row" class="field-data">
                <div class="field-label" fxFlex="20"
                  [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorVendor!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].EvaluatorVendor)}">
                  Evaluator's Vendor</div>
                <div class="field-label" fxFlex="2">:</div>
                <div class="field-value" fxFlex="75">
                  <textarea class="textareaField textareaLine" [disabled]="isEditDiabled"
                    [ngClass]="{'textareaEmpty':''==projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorVendor}"
                    placeholder="{{textareaEmptyPlaceholder}}"
                    [(ngModel)]="projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorVendor"></textarea>
                  <!-- (input)="projectWorkDetails.ProjectEvaluationDetails[projectIndex].EvaluatorVendor=$event.target.value" -->
                </div>
              </div>
            </div>

            <div fxLayout="row" class="field-data">
              <div class="field-label" fxFlex="20"
                [ngClass]="{'fieldEdited':(projectWorkDetails.ProjectEvaluationDetails[projectIndex].LocationofEvaluator!==content.ProjectGroup.ProjectEvaluationDetails[projectIndex].LocationofEvaluator)}">
                Location of Evaluator</div>
              <div class="field-label" fxFlex="2">:</div>
              <div class="field-value" fxFlex="75" [ngClass]="{mouseDisabled: isEditDiabled}">
                <input type="radio" name="projectWorkDetailsLocationofEvaluator+{{projectIndex}}"
                  [disabled]="isEditDiabled"
                  [checked]="'Onsite '===projectWorkDetails.ProjectEvaluationDetails[projectIndex].LocationofEvaluator"
                  [value]="'Onsite'"
                  [(ngModel)]="projectWorkDetails.ProjectEvaluationDetails[projectIndex].LocationofEvaluator" />Onsite
                <input type="radio" name="projectWorkDetailsLocationofEvaluator+{{projectIndex}}"
                  [disabled]="isEditDiabled"
                  [checked]="'Offshore'===projectWorkDetails.ProjectEvaluationDetails[projectIndex].LocationofEvaluator"
                  [value]="'Offshore'"
                  [(ngModel)]="projectWorkDetails.ProjectEvaluationDetails[projectIndex].LocationofEvaluator" />Offshore
              </div>
            </div>
          </div>
        </div>
      </div>
</ng-template>

<ng-template #assessment>
  <div class="subHeader" *ngIf="content&&content.Assessment">
    Assessment
  </div>
  <div *ngIf="content&&content.Assessment">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="20">Assessment Description</div>
      <div class="field-label" fxFlex="2">:</div>
      <div class="field-value" fxFlex="75">
        {{content.AssessmentGroup.AssessmentDescription}}
      </div>
    </div>
    <div class="subHeaderSubTitle" *ngIf="content.AssessmentGroup.AssesmentInfrastructure">Software Requirement for
      Assessment</div>
    <div *ngFor="let assessment of assessmentInterface.assessment;let assessmentIndex=index">
      <div
        *ngIf="content.AssessmentGroup?.AssesmentInfrastructure&&content.AssessmentGroup.AssesmentInfrastructure[assessmentIndex]">
        <div class="subHeaderListLvl">{{assessment}}</div>
        <div *ngFor="let assessmentType of assessmentInterface.assessmentType;let assessmentTypeIndex=index">
          <div fxLayout="row" class="field-data" *ngIf="assessmentIndex==assessmentType.assessmentId">
            <div class="field-label" fxFlex="20">{{assessmentType.title}}</div>
            <div class="field-label" fxFlex="2">:</div>
            <div class="field-value" fxFlex="75">
              {{content.AssessmentGroup.AssesmentInfrastructureType[assessmentTypeIndex]}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="20">Assessment Type</div>
      <div class="field-label" fxFlex="2">:</div>
      <div class="field-value" fxFlex="75">
        {{content.AssessmentGroup.AssessmentType}}
      </div>
    </div>

    <div *ngIf="'Offline'==content.AssessmentGroup.AssessmentType||
    'Both'==content.AssessmentGroup.AssessmentType">
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Question Paper</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          <a class="anchorTag"  (click)="openDownloadFileWithSasKey(content.AssessmentGroup.QuestionPaperLink)">
            {{content.AssessmentGroup.QuestionPaperLink}}
          </a>
        </div>
      </div>
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Max Score</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          {{content.AssessmentGroup.MaxScore}}
        </div>
      </div>
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Passing Score</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          {{content.AssessmentGroup.PassingScore}}
        </div>
      </div>

      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Evaluator Type</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          {{content.AssessmentGroup.EvaluatorType}}
        </div>
      </div>
      <div *ngFor="let evaluatorObj of content.AssessmentGroup.EvaluatorList;let evaluatorIndex=index">
        <div class="subHeaderSubTitle">Evaluator {{evaluatorIndex+1}}</div>
        <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20">Evaluator's Name</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            {{evaluatorObj.EvaluatorName}}
          </div>
        </div>
        <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20">Evaluator's Email ID</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            {{evaluatorObj.EvaluatorEmail}}
          </div>
        </div>
        <div fxLayout="row" class="field-data">
          <div class="field-label" fxFlex="20">Evaluator's Vendor Name</div>
          <div class="field-label" fxFlex="2">:</div>
          <div class="field-value" fxFlex="75">
            {{evaluatorObj.EvaluatorVendor}}
          </div>
        </div>
      </div>

      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Evaluation Template</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          <a *ngIf="content.AssessmentGroup.EvaluationTemplateLink && content.AssessmentGroup.EvaluationTemplateLink.trim() != ''"
            class="anchorTag"  (click)="openDownloadFileWithSasKey(content.AssessmentGroup.EvaluationTemplateLink)">
            {{content.AssessmentGroup.EvaluationTemplateLink}}
          </a>
          <div
            *ngIf="content.AssessmentGroup && (null==content.AssessmentGroup.EvaluationTemplateLink || content.AssessmentGroup.EvaluationTemplateLink.trim() == '')">
            {{'Not Available'}}
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="'Online'==content.AssessmentGroup.AssessmentType||
  'Both'==content.AssessmentGroup.AssessmentType">
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Tool Name</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          {{content.AssessmentGroup.ToolName}}
        </div>
      </div>
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="20">Online Question Paper</div>
        <div class="field-label" fxFlex="2">:</div>
        <div class="field-value" fxFlex="75">
          <a class="anchorTag"  (click)="openDownloadFileWithSasKey(content.AssessmentGroup.OnlineQuestionPaperLink)"
            *ngIf="content.AssessmentGroup.OnlineQuestionPaperLink && content.AssessmentGroup.OnlineQuestionPaperLink.trim() != ''">
            {{content.AssessmentGroup.OnlineQuestionPaperLink}}
          </a>

          <div class="space-after-href" *ngIf="content.AssessmentGroup.OnlineQuestionPaperLink &&
          content.AssessmentGroup.OnlineQuestionPaperLink.trim() == ''
          ||null==content.AssessmentGroup.OnlineQuestionPaperLink">
            Not Available
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" class="field-data" *ngIf="false">
      <div class="field-label" fxFlex="20">Is Assessment Scheduled?</div>
      <div class="field-label" fxFlex="2">:</div>
      <div class="field-value" fxFlex="75">
        {{(content.AssessmentGroup.IsAssessmentScheduled)?'Yes':'No'}}
      </div>
    </div>
    <div fxLayout="row" class="field-data" *ngIf="false && content.AssessmentGroup.IsAssessmentScheduled">
      <div class="field-label" fxFlex="20">Assessment Schedule</div>
      <div class="field-label" fxFlex="2">:</div>
      <div class="field-value" fxFlex="75">
        {{content.AssessmentGroup.AssessmentScheduledDate|date}}
      </div>
    </div>
  </div>
</ng-template>


<ng-template #quiz>
  <div *ngIf="quizDetails">

    <div class="subHeader" *ngIf="content&&content.Quiz">
      Quiz Details
    </div>
    <div fxLayout="column" class="field-data course-op-details quiz-row">
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="35">
          Is Quiz Time Boxed?</div>
        <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="10">
          {{quizDetails.isQuizTimeBoxed?'Yes':'No'}}
        </div>
        <div fxLayout="row" fxFlex="50" *ngIf="quizDetails.isQuizTimeBoxed">
          <div class="field-label" fxFlex="45">
            Quiz Duration</div>
          <div class="field-label" fxFlex="5">:</div>
          <div class="field-value" fxFlex="50">
            {{quizDetails.DurationHour}}:{{quizDetails.DurationMins}}:{{quizDetails.DurationSeconds}}
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="field-data course-op-details quiz-row">
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="35">
          Is Quiz Randomized?</div>
        <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="10">
          {{quizDetails.isQuizRandomized?'Yes':'No'}}
        </div>
        <div fxLayout="row" fxFlex="50" *ngIf="quizDetails.isQuizRandomized">
          <div class="field-label" fxFlex="45">
            No. Of questions to be picked</div>
          <div class="field-label" fxFlex="5">:</div>
          <div class="field-value" fxFlex="50">
            {{quizDetails.noOfQuestions}}
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="field-data course-op-details quiz-row">
      <div fxLayout="row" class="field-data">
        <div class="field-label" fxFlex="35">
          Is Quiz Attempts Defined?</div>
        <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="10">
          {{quizDetails.isAttemptsDefined?'Yes':'No'}}
        </div>
        <div fxLayout="row" fxFlex="50" *ngIf="quizDetails.isAttemptsDefined">
          <div class="field-label" fxFlex="45">
            Attempts</div>
          <div class="field-label" fxFlex="5">:</div>
          <div class="field-value" fxFlex="50">
            {{quizDetails.Attempts}}
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="field-data course-op-details quiz-row">
      <div fxLayout="row" class="field-data ">
        <div class="field-label" fxFlex="35">
          Cut of marks</div>
        <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="60">
          {{quizDetails.CutOffMarks}}
        </div>
      </div>
    </div>
    <div fxLayout="column" class="field-data course-op-details quiz-row">
      <div fxLayout="row" class="field-data highlight-data quiz-row">
        <div class="field-label" fxFlex="35">
          Questions</div>
        <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="60">
        </div>
      </div>
      <div fxLayout="row" class="field-data quiz-row"
        *ngFor="let question of quizDetails.questionsAndAnswer; let i = index">
        <div fxLayout="row" class="question" fxFlex="35">
          <div class="question-number" fxFlex="10">{{i+1}}.</div>
          <div fxFlex="90" style="margin-left: 5px;">{{question.Question}}</div>
        </div>
        <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="60">
          <div fxLayout="column" class="option" *ngFor="let option of question.options;let i=index"
            [ngStyle]="option.isCorrectAnswer==true?{'background-color':'#f2f6fd'}:{'background-color':'#ffffff'}">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>A{{i+1}}.&nbsp;&nbsp;&nbsp;&nbsp;{{option.option}}</div>
              <div class="yorbitSprite spriteIconCorrect" *ngIf="option.isCorrectAnswer==true"
                style="margin-right: 10px;"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>