<mat-dialog-content>
  <div class="closeAdd">
    <button mat-button mat-icon-button (click)="closeAdd()" [disabled]="createNewLPInProgress || addProcess.loading"
      [ngClass]="{ disabled: createNewLPInProgress || addProcess.loading }">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="courseName">{{ data.Title }}</div>
  <mat-divider></mat-divider>
  <div class="selection-label" (click)="navigateAcrossLP(type)" [innerHTML]="addInfo"></div>
  <mat-divider></mat-divider>

  <div class="action-btn-holder" *ngIf="showActionBtn">
    <button (click)="actionBtnClicked()" class="action-button">OK</button>
  </div>
  <div *ngIf="!addConfirmationPopup">
    <div *ngIf="(getLearningPathLoaded$ | async) as loaded; else loading">
      <div *ngIf="!noLP">
        <div class="existingLpContainer">
          <div *ngFor="let learningPath of existingLearningPath$; let i = index" fxFlexLayout="row" fxLayoutAlign="center center">
            <div fxFlex class="lpName" fxLayout="row">
              <div fxFlex="35px" fxLayoutAlign="end center">
                {{ i + 1 + '.' }}
              </div>
              <div fxFlex fxFlexOffset="5px">
                {{ learningPath.PathName }}
              </div>
            </div>
            <!-- [ngClass.lt-sm]="'ltSmScreen'"
                [ngClass.lt-md]="'ltMdScreen'"
                [ngClass.md]="'onMdScreen'"
                [ngClass.gt-md]="'gtMdScreen'" -->
            <div fxFlex="30px">
              <button (click)="addContentToLearningPath(learningPath)" class="floatRight add-btn">
                <!--add-btn-->
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="noLP" class="noLPMsg">
        You don't have any learning paths. <br />Please create a new learning path to proceed.
      </div>
    </div>
    <ng-template #loading>
      <div>
        <app-preloader></app-preloader>
      </div>
    </ng-template>
    <div>
      <div *ngIf="!createNewClicked" (click)="createNewClicked = true" class="createNew">
        Create New Learning Path
      </div>
      <div *ngIf="createNewClicked">
        <mat-divider></mat-divider>
        <div class="createNewLpScreen">
          <div fxLayout="column" fxLayoutAlign="center center">
            <div style="width:100%">
              <mat-form-field class="newLpInput">
                <input matInput placeholder="Enter Learning Path Title" value="" (keyup)="createLPKeyEvent($event)"
                  [(ngModel)]="learningPathTitle" [formControl]="learningPathFormControl" md-autofocus [disabled]="createNewLPInProgress"
                  [ngClass]="{ disabled: createNewLPInProgress }" autocomplete="off" />
                <!-- [errorStateMatcher]="validateLearningPath"
              <mat-error> {{ learningPathValidateError }} </mat-error> -->
                <button mat-button *ngIf="learningPathTitle" matSuffix mat-icon-button aria-label="Clear" (click)="learningPathTitle=''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="errMsg" *ngIf="learningPathValidateFail" [innerHTML]="learningPathValidateError"></div>
          </div>
          <div class="createButtonContainer action-btn-holder" style="padding-top:10px" *ngIf="!createNewLPInProgress">
            <button class="action-button" [disabled]="learningPathTitle == '' || learningPathTitle == ' '" (click)="createLearningPath()" [ngClass]="{'disabled':learningPathTitle == '' || learningPathTitle == ' '}">
              Create
            </button>
            <button style="margin-left:10px" class="action-button" (click)="actionBtnClicked();" [ngClass]="{'disabled':createNewLPInProgress}">
              Cancel
            </button>
          </div>
          <div class="createButtonContainer action-btn-holder" style="padding-top:10px" *ngIf="createNewLPInProgress">
            <button class="action-button disabled">Creating...</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="action-btn-holder" *ngIf="addConfirmationPopup && !addProcess.loading && !addProcess.loaded">
    <button (click)="ProceedToAdd()" class="action-button">Proceed</button>
    <button *ngIf="riskBrigadeType==false" style="margin-left:10px" (click)="goBackToLPSelection()" class="action-button">
      Cancel
    </button>
  </div>
  <div *ngIf="addConfirmationPopup && addProcess.loading && !addProcess.loaded" class="action-btn-holder">
    Adding...
  </div>
</mat-dialog-content>
