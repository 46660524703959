export class SMEEvaluationDetails {
    Id: number;
    RequestId: number;
    Attempt: number;
    AssociateName: string;
    AssociatePSID: string;
    BUDescription: string;
    Grade: string;
    IRMName: string;
    LeafSkill: string;
    CurrentProficiency: string;
    requiredProficiency: string;
    yearsExperience: string;
    monthsExperience: string;
    interviewDate: string;
    format: string;
    InterviewPanelists: string;
    otherDetails: string;
    workExperience: string;
    workExperienceScore: number;
    workExperienceSpike: string;
    workExperienceGaps: string;
    tooling: string;
    toolingScore: number;
    toolingSpike: string;
    toolingGaps: string;
    procedures: string;
    proceduresScore: number;
    proceduresSpike: string;
    proceduresGaps: string;
    solutioning: string;
    solutioningScore: number;
    solutioningSpike: string;
    solutioningGaps: string;
    situationalQuestions: string;
    situationalScore: number;
    situationalSpike: string;
    situationalGaps: string;
    certificationsScore: string;
    certificationsScoreSelect: number;
    certificationsSpike: string;
    certificationsGaps: string;
    AssociateProficiencyMeet: string;
    AverageScore: string;
    KeyReason: string;
    CourseId: string;
    SMEScore:string;
    ScoreChangeReasons:string;
}

