import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { NxModule } from '@nrwl/workspace';
import { RouterModule } from '@angular/router';
import 'hammerjs';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  FlexLayoutModule,
  BREAKPOINT,
  DEFAULT_BREAKPOINTS,
} from '@angular/flex-layout';
import { StoreModule } from '@ngrx/store';
import {
  StoreRouterConnectingModule,
  FullRouterStateSerializer,
} from '@ngrx/router-store';

import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdalInterceptor } from 'adal-angular4';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutesModule } from './app-routes.module';
import { environment } from '../environments/environment';
import {
  reusableUiRoutes,
  ReusableUiModule,
} from '@YorbitWorkspace/reusable-ui';
import { GlobalEnvironmentsModule } from '@YorbitWorkspace/global-environments';
import { HeaderModule } from './header/header.module';
import { BrowseModule } from './browse/browse.module';
import { RootStoreModule } from './root-store/root-store.module';
import { rootReducer, rootEffect } from './root-store/root-store.model';
import { AppInsightModule } from '@YorbitWorkspace/app-insight';
import { CourseRequestModule } from './course-request/course-request.module';
import { CardFeatureModule } from './shared/card-feature/card-feature.module';
import { ContentTilesModule } from './shared/content-tiles/content-tiles.module';
import { LearningPathModule } from './learning-path/learning-path.module';
import { MyPageModule } from './my-page/my-page.module';
import { GraphModule } from '@YorbitWorkspace/graph';
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { QuizModule } from './quiz/quiz.module';
import { GlobalPopupsModule } from './shared/global-popups/global-popups.module';
import { Globals } from './globals';
import { PowerbiModule } from './powerbi/powerbi.module';
import { YammerModule } from './shared/yammer/yammer.module';
import { HomeModule } from './home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ManagerFeedbackComponent } from './shared/global-popups/manager-feedback/manager-feedback.component';
import { PssModule } from './pss/pss.module';
import { PssService } from './pss/pss.service';
import { CanActivateRouteChange } from './app-router-guard';
import {
  DynamicComponentLoaderModule,
  DynamicComponentManifest,
} from './dynamic-component-loader/dynamic-component-loader.module';
// This array defines which "componentId" maps to which lazy-loaded module.

import { AoModule } from './ao/ao.module';
import { ContentVerificationModule } from './content-verification/content-verification.module';
import { AssignContentModule } from './shared/card-feature/assign-content/assign-content.module';
import { GuardFailPageModule } from './shared/guard-fail-page/guard-fail-page.module';
import { IdpModule } from './idp/idp.module';
import { ToastModule } from './toast/toast.module';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HMMAndSparkComponent } from './hmm-and-spark/hmm-and-spark.component';
import { FutureSkillsComponent } from './future-skills/future-skills.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { ContentUploadGuardDeactivate } from './ao/content-upload/content-upload.service';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { DiversityAndInclusionModule } from './diversity-and-inclusion/diversity-and-inclusion.module';
import { RoleBasedLearningModule } from './role-based-learning/role-based-learning.module';
import { RiskBrigadeModule } from './risk-brigade/risk-brigade.module';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { BitmovinnewcomponentComponent } from './bitmovinnewcomponent/bitmovinnewcomponent.component';
import { FileUploadPopupModule } from './shared/file-upload-popup/file-upload-popup.module';
import { OurAiStrategyModule } from './our-ai-strategy/our-ai-strategy.module';
import { WebformModule } from './participant/webform/webform.module';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [AppComponent, HMMAndSparkComponent, FutureSkillsComponent, BitmovinnewcomponentComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    StoreModule.forRoot(rootReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot(rootEffect),
    FlexLayoutModule,
    DragDropModule,
    BrowserAnimationsModule, // new modules added here
    ServiceWorkerModule.register('/combined-service-worker.js', {
      enabled: environment.production,
    }),
    // ServiceWorkerModule.register('/firebase-messaging-sw.js', {
    //   enabled: true
    // }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    ReusableUiModule,
    GlobalEnvironmentsModule,
    AppInsightModule,
    AppRoutesModule,
    RootStoreModule,
    BrowseModule,
    HeaderModule,
    CourseRequestModule,
    FormsModule,
    MyPageModule,
    GraphModule,
    LearningPathModule,
    CardFeatureModule,
    LearningPathModule,
    ContentTilesModule,
    QuizModule,
    GlobalPopupsModule,
    PowerbiModule,
    YammerModule,
    PssModule,
    HomeModule,
    GuardFailPageModule,
    IdpModule,
    ToastModule,
    DynamicComponentLoaderModule.forRoot(),
    AoModule,
    AssignContentModule,
    NgbModule,
    DiversityAndInclusionModule,
    OurAiStrategyModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    RiskBrigadeModule,
    ContentVerificationModule,
    RoleBasedLearningModule,
    WebformModule,
    FileUploadPopupModule,
    MatRippleModule,
   
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        // clientId: '1b43da47-e691-4ccc-8c2f-9068c24a1700', // dev
        // clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9', //prod
        //  clientId: 'a37e1a24-ee64-4c69-9180-20f04907bade', //LTIM dEV
        // clientId: '96ddf7da-b0b3-45c8-8085-e45788ad0a70', //LTIM prod
        clientId: EnvironmentService.getEnv().authClientId,
        // authority: 'https://login.microsoftonline.com/mindtreeonline.onmicrosoft.com',
        authority: EnvironmentService.getEnv().authority,
        // QA UI URLS
        // redirectUri: 'http://localhost:4200',
        //redirectUri: 'https://yorbitst.azurewebsites.net'
        //redirectUri : 'https://yorbitst-staging.azurewebsites.net'
        //redirectUri: 'https://yorbitdev.azurewebsites.net'
        //redirectUri: 'https://yorbitdev-knights.mindtree.com'
        redirectUri: EnvironmentService.getEnv().appUrl
        //PROd UI URL
        // redirectUri :'https://yorbit.ltimindtree.com'
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        //    scopes: ['https://yorbitapi.azurewebsites.net/user_impersonation'] //MT PROD
        //    scopes: ['https://yorbitapist.azurewebsites.net/user_impersonation'] //OLD QA
        scopes: EnvironmentService.getEnv().scopes //dEV
        // scopes: ['https://yorbitprodapi.ltimindtree.com/user_impersonation'] //LTIM pROD
      }
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>([
        ["https://graph.microsoft.com/", ['User.Read.All', 'profile']],
        //["https://graph.microsoft.com/v1.0/me", ['user.read', 'profile']],
        // OLD PROD URL - not in use
        // ["https://yorbitapi.mindtree.com", ['https://yorbitapi.azurewebsites.net/user_impersonation']], //MT PROD URL

        // UI URLS
        //["https://yorbitst.azurewebsites.net", ['https://yorbitapi.azurewebsites.net/user_impersonation']],

        // QA API URLS
        //["http://localhost:51703", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],
        //["https://yorbitapidev.mindtree.com", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],
        //["https://yorbitapidev.azurewebsites.net", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],
        //["https://yorbitapidev-fort.mindtree.com", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],
        //["https://yorbitapidev-next.mindtree.com", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],
        //["https://yorbitapist.azurewebsites.net", ['https://yorbitqaapi.ltimindtree.com/user_impersonation']],
        [EnvironmentService.getEnv().apiUrl, EnvironmentService.getEnv().scopes],
        // PROD API URL
        // [EnvironmentService.getEnv().apiUrl, ['https://yorbitprodapi.ltimindtree.com/user_impersonation']]   
      ])
    })
  ],
  providers: [
    PssService,
    CanActivateRouteChange,
    MsalGuard,
    ContentUploadGuardDeactivate,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    WINDOW_PROVIDERS,
    Globals,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true}}
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [AppRoutesModule],
  entryComponents: [ManagerFeedbackComponent]
})
export class AppModule { }

