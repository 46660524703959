<mat-dialog-content>
  <div class="closePopupBtn" (click)="close()">
    <!-- <mat-icon class="floatRight close-icon" (click)="close()">close</mat-icon> -->
    <button class="mat-button mat-icon-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class="popup-title title-custom"
    *ngIf="!badgeDetailsLoading && badgeDetails != null && roleBasedBadgeDetails == null">
    <div fxFlex="30" fxLayout="row">
      <div class="representation">
        <ng-container *ngIf="badgeDetails['Skill'] === 'Degreed'; else otherCase">
            D
        </ng-container>
        <ng-template #otherCase>
            C
        </ng-template>
    </div>    
    <div class="expertise" *ngIf="badge.CourseId != null && badgeDetails.Skill != 'Degreed'"> {{ badgeDetails.Expertise }}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="name course-name" fxFlex="70">

      <!-- <div class="expertise" fxFlex="30">
        {{ badgeDetails.Expertise }}
      </div> -->
      <div class="course-name" fxFlex="70" title="{{badgeDetails.CourseName}}">
        {{ badge.CourseId != null ? badgeDetails.CourseName : 'External Learnings' }}
      </div>
    </div>
  </div>
  <div class="badge-details-container" *ngIf="roleBasedBadgeDetails == null" fxLayout="column">
    <div class="content-holder" fxLayout="column" fxLayoutAlign="center center">
      <div *ngIf="badgeDetailsLoading">
        <app-preloader></app-preloader>
      </div>
      <div *ngIf="!badgeDetailsLoading && badgeDetails != null" fxLayout="column" class="course-details-holder">
        <div class="title" fxLayout="row" fxLayoutAlign="start center" *ngIf="false">
          <div class="representation" fxFlex="15">C</div>
          <div fxLayout="row" fxLayoutAlign="center start" class="name" fxFlex="85">
            <div class="expertise" fxFlex="20" *ngIf="badge.CourseId != null">
              {{ badgeDetails.Expertise }}
            </div>
            <div class="course-name" fxFlex="80">
              {{ badgeDetails.CourseName }}
            </div>
          </div>
        </div>
        <div class="details" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="start start" class="skill"
            *ngIf="badgeDetails.Skill !== 'Degreed' && badgeDetails.Skill !== '' && badge.CourseId != null">
            <div class="label" fxFlex="50">
              Skill
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">{{ badgeDetails.Skill }}</div>
          </div>
          <div class="account-name" fxLayout="row" fxLayoutAlign="start start" *ngIf="
              badgeDetails.AccountName !== null &&
              badgeDetails.AccountName !== '' &&
              badge.CourseId != null">
            <div class="label" fxFlex="50">
              Account Name
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">{{ badgeDetails.AccountName }}</div>
          </div>
          <div class="doc" fxLayout="row" fxLayoutAlign="start end" *ngIf="badgeDetails.DateOfCompletion !== null">
            <div class="label" fxFlex="50">
              Date of {{isDeclared?'Declaration':'Completion'}}
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">
              {{ isDateValid(this.badgeDetails.DateOfCompletion) ? (this.badgeDetails.DateOfCompletion | date:
              'badgepopup') : 'NA' }}
            </div>
          </div>
          <div class="doc" fxLayout="row" fxLayoutAlign="start end"
            *ngIf="badgeDetails.ApprovalDate !== null &&  badge.CourseId == null">
            <div class="label" fxFlex="50">
              Date of {{'Approval'}}
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">
              {{ isDateValid(this.badgeDetails.ApprovalDate) ? (this.badgeDetails.ApprovalDate | date: 'badgepopup') :
              'NA' }}
            </div>
          </div>
          <div class="credits" fxLayout="row" fxLayoutAlign="start start"
            *ngIf="badgeDetails.Credit !== null && badgeDetails.Credit !== ''">
            <div class="label" fxFlex="50">
              Credits
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">{{ badgeDetails.Credit }}</div>
          </div>
          <div class="assigned-by" fxLayout="row" fxLayoutAlign="start start" *ngIf="
              badgeDetails.AssignedBy !== null && badgeDetails.AssignedBy !== '' && badge.CourseId != null
            ">
            <div class="label" fxFlex="50">
              Assigned By
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">{{ badgeDetails.AssignerName }}</div>
          </div>
          <div class="quiz-score" fxLayout="row" fxLayoutAlign="start start" *ngIf="
              badgeDetails.QuizPercentage !== null &&
              badgeDetails.QuizPercentage !== '' && badge.CourseId != null
            ">
            <div class="label" fxFlex="50">
              Quiz Score
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">
              {{ badgeDetails.QuizPercentage }}
            </div>
          </div>
        </div>
      </div>
      <div fxLayoutAlign="center center" class="close-btn-holder" *ngIf="!badgeDetailsLoading && badgeDetails != null">
        <button class="action-button" (click)="close()">OK</button>
      </div>
      <div fxLayoutAlign="center center" fxLayout="column"
        *ngIf="!badgeDetailsLoading && badgeDetails == null && roleBasedBadgeDetails == null">
        <div style="padding:10px">
          Data not available
        </div>
        <div class="close-btn-holder">
          <button class="action-button" (click)="close()">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" *ngIf="roleBasedBadgeDetails != null">
    <!-- <div class="rblHeading">Role based learning Badge</div> -->
    <div fxLayout="column" class="popup-title title-custom">
      <div fxLayout="row">
        <div class="salaryGrade">{{roleBasedBadgeDetails.SalaryGrade}}</div>
        <div fxLayout="column">
          <div class="specializationGroup"> {{ roleBasedBadgeDetails.SpecializationGroup }}</div>
          <div class="specialization" title="{{roleBasedBadgeDetails.Specialization}}">
            {{ roleBasedBadgeDetails.Specialization }}
          </div>
        </div>

      </div>
    </div>
    <div class="badge-details-container">
      <div class="content-holder" fxLayout="column">
        <div class="rbl-details-holder">
          <div fxLayout="row" fxLayoutAlign="start start" class="details">
            <div class="label" fxFlex="50">
              Family
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">{{ roleBasedBadgeDetails.Family }}</div>
          </div>
          <div class="details" fxLayout="row" fxLayoutAlign="start start">
            <div class="label" fxFlex="50">
              Role
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">{{ roleBasedBadgeDetails.Role }}</div>
          </div>
          <div class="details" fxLayout="row" fxLayoutAlign="start start">
            <div class="label" fxFlex="50">
              Level
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">{{ roleBasedBadgeDetails.Level }}</div>
          </div>
          <div class="doc" fxLayout="row" class="details" fxLayoutAlign="start end" *ngIf="badge.CompletedDate !== null">
            <div class="label" fxFlex="50">
              Date of Completion
              <span>:</span>
            </div>
            <div class="value" fxFlex="50">
              {{ isDateValid(badge.CompletedDate) ? (badge.CompletedDate | date:
              'badgepopup') : 'NA' }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div fxLayout="row">
        <div fxLayout="column" class="rolesDiv">
          <div class="roleIndication">F</div>
          <div class="roleIndication">R</div>
          <div class="roleIndication">L</div>
        </div>
        <div fxLayout="column" class="dotsDiv">
          <div class="dots dots1"></div>
          <div class="verticalLine"></div>
          <div class="dots dots2"></div>
          <div class="verticalLine"></div>
          <div class="dots dots3"></div>
        </div>
        <div fxLayout="column" class="tree-inputs">
          <div class="tree-values"> {{roleBasedBadgeDetails.Family}}</div>
          <div class="tree-values"> {{roleBasedBadgeDetails.Role}}</div>
          <div class="tree-values"> {{roleBasedBadgeDetails.Level}}</div>
        </div>
      </div> -->
      <div class="ok-btn">
        <button class="action-button" (click)="close()">OK</button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="name course-name" fxFlex="70">

      <!-- <div class="expertise" fxFlex="30">
        {{ badgeDetails.Expertise }}
      </div> -->

    </div>
  </div>
</mat-dialog-content>