<mat-dialog-content class="assignPopup" *ngIf="rolesLoaded" [ngClass]="{'isCDM': 'CDM'===assignAsRole.Label || 'GLOBALCDM1'===assignAsRole.Label,'intentPopup':!intentMode && !isBulkAssignmentOpened, 'bulkIntentPopup': isBulkAssignmentOpened}" >
  <div class="closeAssign">
    <button mat-button mat-icon-button (click)="((showPreloader && !assignInProgress)||(showPreloader && assignInProgress)) ?'': closeAssign()" [ngClass]="{'mouseDisabled': ((showPreloader && !assignInProgress) || (showPreloader && assignInProgress))}" [disabled]="false" [ngClass]="{'disabled':(false)}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title" >
    <div class="heading-custom">{{intentMode ? 'Assignment Intent': 'Assign Course'}}</div>
  </div>
  <div class="courseName" *ngIf="!(showPreloader&&intentMode) && !isBulkAssignmentOpened" [ngClass]="{'fixedCourseNamewidth': intentMode,'invalidMidsCoursename':MidValidation&&!VerificationPreloader&&0==midListForPush.length}" fxLayout="row" fxLayoutGap="1px">
    <span fxFlex="82px" >{{('course'==data.ItemType.toLowerCase()?"Course Name : ":"Package Name : ")}} </span>
    <span >{{data.Name}}</span>
  </div>

  <div *ngIf="intentMode" class="scrollIntent">
    <!-- preloader -->
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="showPreloader" class="container-xs preloaderContainer">
      <div *ngIf="showPreloader">
        <app-preloader></app-preloader>
      </div>
    </div>
<!-- preloader ends -->

    <div class="roleDiv" *ngIf="!showPreloader">
      <div class="selectHeader">Select Role</div>

      <div
      class="assignMenuContainer">
      <mat-menu  class="dropdownOption"
      backdropClass="menuOverlay"
       #roleTypeMenu="matMenu" [overlapTrigger]="false">
        <div
          mat-menu-item
          *ngFor="let role of PPRoles"
          (click)="assignAsRole=role;roleTrigger.closeMenu()"
        >
          <div>{{ role.Label }}</div>
        </div>
      </mat-menu>
      <button
        [matMenuTriggerFor]="roleTypeMenu"
        #roleTrigger="matMenuTrigger"
        fxLayoutAlign="start center"
        class="mousePointer dropdownbtn"
        [disabled]="PPRoles.length<=1"
        [ngClass]="{disabled:PPRoles.length<=1,menuOpened:roleTrigger.menuOpen,menuClosed:!roleTrigger.menuOpen, disabledLabel: PPRoles.length<=1}"
      >
        <div fxFlex>{{assignAsRole.Label}}</div>
      </button>
    </div>
    </div>

    <mat-divider class="marginBottomDivider" *ngIf="('GLOBALCDM'===assignAsRole.Value) &&!showPreloader"></mat-divider>
    <div *ngIf="('GLOBALCDM'===assignAsRole.Value) &&!showPreloader">
      <div  class="search-container roleDiv" fxLayoutAlign="start start" fxLayout="column">
        <div class="selectHeader">Select Requestor</div>
        <div fxLayoutAlign="start center" style="width: 100%">
          <input  class="searchInputBox" [(ngModel)]="searchBy" (ngModelChange)="getUserSuggestionList(searchBy)" placeholder="Search by Name or PSID"
          />
          <mat-icon fxFlexOffset="-30px" class="search-icon" *ngIf="showSearchIcon || searchBy==''">search</mat-icon>
          <mat-icon fxFlexOffset="-30px" class="mousePointer clear-icon" (click)="closeSearch()" *ngIf="!showSearchIcon && searchBy!=''">clear</mat-icon>
        </div>
        
        <div class="backdrop" (click)="showSuggestionsList=false;searchBy='';showSearchIcon=true" *ngIf="showSuggestionsList"></div>
        <div class="suggestion-popover" fxLayout="column" *ngIf="showSuggestionsList">
          <div class="list mousePointer" fxLayout="column" fxLayoutAlign="start stretch">
            <div *ngFor="let mind of suggestionList" fxFlexOffset="5px" fxLayout="column" fxLayoutAlign="start start" fxFlex="50px" (click)="getRequestsOfSelectedUser(mind);showSearchIcon=false">
              <div fxFlex="50px" class="mind-profile">
                <div fxFlex="50px">
                  <img fxFlex="45px" [src]="mind.imageUrl" />
                </div>
                <div fxFlex="205px" fxFlexOffset="10px">
                  {{mind.displayName}}
                </div>
              </div>
              <mat-icon fxFlex="1px"></mat-icon>
            </div>
            <div fxFlex="40px" class="warning-msg" *ngIf="suggestionList.length==0" fxLayoutAlign="center center" style="font-size: 12px;">
              Search with valid LP/ACM/PCM PSID's or Name
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider *ngIf="!showPreloader"></mat-divider>
    <div class="intentList" *ngIf="!showPreloader">
      <label mat-subheader *ngIf="!showPreloader" class="Intentlabel">Select Assignment Intent <span style="color: red;">*</span></label>


      <div class="roleDiv"  *ngIf="!showPreloader">

        <div
        class="assignMenuContainer">
        <mat-menu  class="dropdownOption"
        backdropClass="intentMenuOverlay"
         #intentMenu="matMenu" [overlapTrigger]="false">
          <div
            mat-menu-item
            *ngFor="let intent of IntentList"
            class="textTruncate"
            [ngClass]="{otherIntent: 1==intent.IntentId,limitHeight:intent.ApproverIntentDescription?.length<65}"
            (click)="selectedIntent=intent;intentId=intent.IntentId;intentTrigger.closeMenu()"
            title="{{ intent.ApproverIntentDescription }}"
          >
            <div class="textTransform">{{ intent.IntentName }}</div>
            <div class="intentDescription  breakText">{{ intent.ApproverIntentDescription }}</div>
          </div>
        </mat-menu>
        <button
          [matMenuTriggerFor]="intentMenu"
          #intentTrigger="matMenuTrigger"
          fxLayoutAlign="start center"
          class="mousePointer dropdownbtn textTransform"
          [ngClass]="{menuOpened:intentTrigger.menuOpen,menuClosed:!intentTrigger.menuOpen}"
        >
          <div fxFlex>{{selectedIntent.IntentName}}</div>
        </button>
      </div>

      <div  *ngIf="intentId == 1" class="comment">
        <textarea class="commentTextArea"
        [(ngModel)]="intentComments" placeholder="Please enter comments"></textarea>
      </div>
      </div>

<!--
      <mat-radio-group name="intent" fxLayout="column" fxLayoutAlign="start stretch" [(ngModel)]="intentId">
        <mat-radio-button *ngFor="let item of IntentList;" class="lists" [value]="item.IntentId" required>
          <span class="mat-radio-label">{{item.IntentName}}</span>
          <span *ngIf="item.IntentName.toLowerCase()!='others'"
           class="material-icons infoToolTip" matTooltip="{{item.LearnerIntentDescription}}">
            info
            </span>
        </mat-radio-button>
        <div fxFlex fxLayout="column" fxLayoutAlign="end stretch" *ngIf="intentId == 1" class="comment">
          <textarea fxFlex="70px" style="border-radius: 10px; padding: 8px;outline: none;border:1.3px solid #b6b2b2;"
            [(ngModel)]="intentComments" placeholder="Please enter comments"></textarea>
        </div>
      </mat-radio-group>
    -->

    </div>
    <mat-divider class="marginBottomDivider" *ngIf="('CDM'===assignAsRole.Value || 'GLOBALCDM'===assignAsRole.Value) &&!showPreloader"></mat-divider>
    <!-- Assignment intent list ends -->
    <div *ngIf="('CDM'===assignAsRole.Value || 'GLOBALCDM'===assignAsRole.Value)&&!showPreloader">

      <div class="roleDiv" *ngIf="!('package' === this.data.ItemType.toLowerCase()
      ||'familypackage' === this.data.ItemType.toLowerCase())&&'101'!=expertise"
      >
        <div class="selectHeader">Select Special Intent</div>

        <div
        class="assignMenuContainer">
        <mat-menu  class="dropdownOption"
        backdropClass="menuOverlay"
         #specialIntentMenu="matMenu" [overlapTrigger]="false">
          <div
            mat-menu-item
            *ngFor="let intent of specialIntentGroup"
            (click)="cdmIntent=intent;specialIntentTrigger.closeMenu()"
          >
            <div>{{ intent.viewValue }}</div>
          </div>
        </mat-menu>
        <button
          [matMenuTriggerFor]="specialIntentMenu"
          #specialIntentTrigger="matMenuTrigger"
          fxLayoutAlign="start center"
          class="mousePointer dropdownbtn"
          [ngClass]="{menuOpened:specialIntentTrigger.menuOpen,menuClosed:!specialIntentTrigger.menuOpen}"
        >
          <div fxFlex>{{cdmIntent.viewValue}}</div>
        </button>
      </div>
      </div>

      <!------------------------------------------------------------>

      <div class="infotile" (click)="isMindBetweenProject=!isMindBetweenProject">
        <div class="checkboxBetweenProject">
          <mat-icon *ngIf="isMindBetweenProject">check_box</mat-icon>
          <mat-icon *ngIf="!isMindBetweenProject">check_box_outline_blank</mat-icon>
        </div>
        <div class="labelBetweenProject">
          Assignment made to associates in-between projects
        </div>
      </div>
    </div>


    <mat-divider *ngIf="!showPreloader"></mat-divider>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" *ngIf="!showPreloader">
      <button class="actionButtons" style="margin-right: 15px;"
        (click)="intentId == undefined  || (intentId == 1 && !intentComments.trim()) ?null:next()"
        [ngClass]="{'mouseDisabled': intentId == undefined  || (intentId == 1 && !intentComments.trim())}"
        title="{{(intentId == undefined && (intentId != 1))? tooltip :((intentId == 1 && !intentComments.trim()) ? tooltip2 : '')}}">
        NEXT
      </button>
    </mat-dialog-actions>
  </div>

  <!-- Mid addition popup -->
  <div *ngIf="!intentMode && !isBulkAssignmentOpened" class="assignCourse">
    <div *ngIf="MidValidation&&VerificationPreloader" class="preloaderWidth">
      <app-preloader></app-preloader>
    </div>
    <div class="infoMsgAssign"
    *ngIf="!MidValidation||(MidValidation&&!VerificationPreloader&&0<midListForPush.length)"
    >{{(!intentMode && !MidValidation)?"Enter PSID(s)":"Valid PSID(s)"}} <span class="max10" *ngIf="(!intentMode && !MidValidation)"> (Maximum 25 Associates)</span>
      <span class="errorMsg"
        *ngIf="(showerrmsg || 25<=learnerMids.length) && !MidValidation">
        {{25<=learnerMids.length ? errorMsg : ''}}{{showerrmsg ? errorMsgonPaste: ''}}
      </span>
    </div>
    <button *ngIf="(!intentMode && !MidValidation) && (0<learnerMids.length ||''!=midListInput)" class="deleteBtn" (click)="clearAll()" [ngStyle]="{'display': (0<learnerMids.length)?'inline-block': 'none'}" title="Clear All">
     <span class="material-icons" >
        delete
      </span>
    </button>

    <div *ngIf="!MidValidation||(MidValidation&&!VerificationPreloader&&0<midListForPush.length)">
      <div class="inputBorder midScroll"
      [ngStyle]="{'width':!MidValidation ? '25em' : '33em'}"
      [ngClass]="{'inputborderMobile': !intentMode}" (click)="MidListInput.focus()">
       <mat-form-field
       appearance="none" [ngStyle.gt-xs]="{'width':'100%'}" (click)="MidListInput.focus()" >
      <mat-chip-list #chipList (click)="MidListInput.focus()">
        <mat-chip *ngFor="let learner of (!MidValidation?learnerMids:midListForPush)"
        [disabled]="MidValidation" matTooltip="{{learner.name}}"
        [selectable]="!MidValidation"  [removable]="!MidValidation"
        (removed)="removeLearner(learner)" [ngClass]="{'marginMids': MidValidation,'labelOpacity':MidValidation}">
        {{(!MidValidation)?learner:((learner.name.length<'15')?learner.mid+' - '+ learner.name:learner.mid+' - '+(learner.name.substring(0,15)+'..'))}}
          <span (click)="$event.stopPropagation()" class="material-icons clearbtn" matChipRemove [ngClass]="{'hidden': MidValidation}">clear</span>
        </mat-chip>

        <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addLearner($event)" (paste)="addLearnerOnPaste($event)"
          [(ngModel)]="midListInput" [disabled]="25<=learnerMids.length || MidValidation" class="fillWidth" autofocus #MidListInput>
      </mat-chip-list>
      </mat-form-field>
    </div>
    <!--Upload for bulk assignment (Between 25-1500) Msg -->
    <div *ngIf="!MidValidation" class="bulk-upload-msg-container">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="bulk-upload">
        <div  class="bulk-upload-msg" >
          <div>
            <span class="bulk-upload-msg-bold">Upload for bulk assignment</span> (Between 25-1500) PSID
          </div>
          <div>
            *Assignment will be completed within 48 hours after upload
          </div>
        </div>
        <mat-icon class="bulk-upload-arrow-click" (click)="openBulkAssignment()">keyboard_arrow_right</mat-icon>
      </div>
    </div>
    <mat-divider class="btn-divider"></mat-divider>
    <mat-dialog-actions *ngIf="!MidValidation" class="buttongrp" fxLayout="row" fxLayoutGap="20px">
      <button class="backBtn actionButton" (click)="backAction()">BACK</button>
      <button class="actionButtons" *ngIf="!showPreloader"
        title="{{(0>=learnerMids.length&&''===midListInput)?'Please Enter PSID':'Click here to verify'}}"
        (click)="(0<learnerMids.length ||''!=midListInput)?MidVerification(): ''"
        [ngClass]="{'mouseDisabled': ((0==learnerMids.length) && (''==midListInput))}">VERIFY</button>

    </mat-dialog-actions>
  </div>
  </div>
  <!-- Mid addition popup ends-->

  <!-- Mid Validation -->

  <div class="midvalidation" *ngIf="MidValidation && !VerificationPreloader && failedMIDs&&(0!==failedMIDs?.length)">
     <div *ngIf="!showPreloader">
    <div class="successFailureHeader">Course will not be assigned to the following list of associates as per the reason given below</div>

      <div class="MidHeader" fxLayout="row">
        <div class="midTextHeader  failedHeadersText">USER(S)</div>
        <div class="nameText failedHeadersText">NAME(S)</div>
        <div class="reasonText failedHeadersText">REASON(S)</div>
      </div>
       <div class="failedMidListDiv">
      <div class="scrollbar">
        <div class="failedMidsRow " *ngFor="let failedMid of failedMIDs;" fxLayout="row">
          <div class="midText forMobile" [ngStyle.gt-xs]="{'float': 'left'}" >
            <mat-form-field appearance="none">
              <mat-chip-list #chipList>
                <mat-chip [selectable]="false" [selected]="false" title="{{failedMid.mid}}" class="labelOpacity" [disabled]="true">
                {{(failedMid.mid.length<9)?failedMid.mid:(failedMid.mid.substring(0,7)+'..')}}
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="marginreason floatLeft nameText" [ngClass.xs]="{'leftMar': true}" [ngStyle.gt-xs]="{'margin-left':'-9px'}">{{failedMid.name}}</div>
         <div class="marginreason floatLeft failedReason" [ngClass.xs]="{'leftMar': true}" >{{failedMid.reason}}</div>
        </div>
      </div>
      </div>
      </div>
      <mat-divider class="btn-divider"></mat-divider>
  </div>

   <!-- Bulk Assignment -->
  <div *ngIf="isBulkAssignmentOpened && !MidValidation" class="bulk-assignment-upload">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="bulk-upload">
          <div  class="bulk-upload-msg" >
            <div>
              <span class="bulk-upload-msg-bold">Upload for bulk assignment</span> (Between 25-1500) PSID
            </div>
            <div>
              *Assignment will be completed within 48 hours after upload
            </div>
          </div>
          <button mat-raised-button class="downloadTemplateButton"
          (click)="downloadTemplate()" [disabled]="templateDownloadInProgress"
          [ngClass]="{'disabled':templateDownloadInProgress}">
          Default template
        </button>
        <!-- <mat-icon>download</mat-icon> -->
      </div>
      <div class="uploadAndDownload">          
          <div class="pssFileUploadLabel">
              <input type="file" id="pssFileUpload" #file (change)="onFilesAdded(file.files, $event)" />
              <div class="fileNameLabel">{{fileName}}</div>
              <button mat-raised-button class="browseFileButton" (click)="file.click()">Browse</button>
          </div>
      </div>
      <div class="statusMessage" [ngStyle]="{'color': isUploadSuccess ? 'green' : 'red'}" *ngIf="''!==actionMessage">
        {{actionMessage}}
    </div>
      <!-- <div class="message">
        Note: Please clear browser cache (Ctrl+F5) and rename the file uniquely before every upload.
      </div> -->
      <mat-dialog-actions
        [ngClass]=""
         *ngIf="isBulkAssignmentOpened" class="buttongrp" fxLayout="row" fxLayoutGap="20px">
         <button class="backBtn actionButton" (click)="backAction()">BACK</button>
          <button class="actionButtons" [disabled]="isUploadButtonDisabled || disableProceed || excelUploadInProgress"
          [ngClass]="{'disabled':isUploadButtonDisabled || disableProceed || excelUploadInProgress}" (click)="upload()">
            VERIFY
          </button>
        </mat-dialog-actions>
  </div>
  </div>
  <!-- Bulk Assignment End-->  
  <mat-dialog-actions
  [ngClass]="{'invalidMidsBack':MidValidation&&!VerificationPreloader&&0==midListForPush.length}"
   *ngIf="MidValidation && !intentMode && btnName == 'ASSIGN' && !VerificationPreloader" class="buttongrp2" fxLayout="row" fxLayoutGap="20px">
    <button class="backBtn actionButton" (click)="backFromAssign()" [ngClass]="{'mouseDisabled': ((showPreloader && !assignInProgress) || (showPreloader && assignInProgress))}">BACK</button>
    <button class="actionButtons"
    *ngIf="MidValidation && !intentMode && btnName == 'ASSIGN' && !VerificationPreloader &&0<midListForPush.length"
      title="{{(learnerMids.length == failedMIDs?.length? 'Please enter correct PSID': '')}}"
      (click)="(learnerMids.length==failedMIDs?.length)?'':sendAssignedData()"
      [ngClass]="{'mouseDisabled': (learnerMids.length==failedMIDs?.length)||(showPreloader && assignInProgress)}">
      {{showPreloader && assignInProgress ? 'ASSIGNING..' : (showPreloader && !assignInProgress)? 'VERIFYING...' : 'ASSIGN'}}
    </button>
      <!-- <button *ngIf="showPreloader && !assignInProgress" class="actionButtons">Verifying...</button> -->
  </mat-dialog-actions>

  <mat-divider *ngIf="MidValidation&&VerificationPreloader" class="btn-divider"></mat-divider>

  <mat-dialog-actions
  [ngClass]="{'invalidMidsBack':MidValidation&&!VerificationPreloader&&0==midListForPush.length}"
   *ngIf="MidValidation&&VerificationPreloader" class="buttongrp" fxLayout="row" fxLayoutGap="20px">
   <button class="backBtn actionButton mouseDisabled" >BACK</button>
    <button class="actionButtons mouseDisabled">
      VERIFYING...
    </button>
  </mat-dialog-actions>
  <!-- Mid Validation -->

 
</mat-dialog-content>
