

<div class="cnt">

    <div fxLayout="row"  fxLayoutAlign="end center" style="margin-right: 15px;">
      <div *ngFor="let item of ['all','101','201','301']" class="filter-tag"
        [class.selected-filter-tag]="selectedFilter === item" (click)="clickSelectedFilter(item)">
        <label>{{item}}</label>
      </div>
      <img class="reload-icon" (click)="clickSelectedFilter('all')"
        src="../../../../assets/images/DandI/ic-reload.png" alt="">
    </div>

  <div  fxLayout="column"  class="course-container"  fxLayoutGap="22px">
    <div fxLayout="row wrap" *ngIf="dataList.length>0" fxLayoutGap="23px" class="tile"> 
      <div *ngFor="let item of dataList" style="padding:10px 5px 10px 5px">
        <yorbit-content-tile-browse [cardDetails]="item" [featuredIn]="'Browse'" fxFlex="230px"
          fxLayoutAlign="start start" fxFlexFill>
        </yorbit-content-tile-browse>
      </div>
    </div>
   
    <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isDataLoading==true">
      <app-preloader></app-preloader>
    </div>
    <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="!isDataLoading && dataList.length==0">
      <span  class="no-data-found">
          Foundation Courses are not available.
      </span>
    </div>
    <div  fxLayoutAlign="center" class="show-more" (click)="getMoreRecords()" *ngIf="showMore==true && dataList.length>0">
      Show More
     </div>
  </div>
</div>

