<div class="content-upload-container" *ngIf="!showloading" fxFlex>

  <div class="outer">
    <h2 class="heading"><b>201 <span>Content Upload Form</span></b></h2>
  </div>

  <div fxLayout="column">
    <!-- Tabs -->
    <div class="contentUploadMenuHeader" fxLayout="row" fxLayoutAlign="start center"
      [ngClass.xs]="'contentUploadMenuHeaderXs'">
      <div class="basicCourseBox mousePointer" fxFlex="100" fxLayoutAlign="center center"
        [ngClass]="{ activeTab: basicCourseTabClicked }" title="Basic Course Details Form" (click)="basicCourseClick()">
        <div>Basic Course Details</div>
      </div>
      <div class="operationalCourseBox mousePointer" fxFlex="100" fxLayoutAlign="center center"
        *ngIf="contentForm.get('creditSpecial').value == 'No'"
        [ngClass]="{ activeTab: operationalCourseTabClicked, 'disabled':  (!contentForm.valid && !contentForm.disabled) || !isEdit }"
        title="{{
          (!contentForm.valid && !contentForm.valid && !contentForm.disabled)
            ? 'Please enter all mandatory fields in Basic Course Details Form to proceed.'
            : !isEdit?'Please save the data in Basic Course Details Form to proceed':'Course Operational Details Form'
        }}" (click)="switchTab()">
        <div>Course Operational Details</div>
      </div>
    </div>
    <!-- details -->
    <div class="form-container">
      <form [formGroup]="contentForm" autocomplete="new-password">
        <input autocomplete="false" name="hidden" type="text" style="display:none;">
        <div class="contentUploadDetails" [ngStyle.lt-md]="{ padding: '2px 20px' }">
          <div class="forms">
            <div class="basic-form" *ngIf="basicCourseTabClicked" fxLayout="row">
              <div class="alignLeft" ngClass="courseReqFormFields" [ngStyle.lt-md]="'border:none'">
                <div ngClass="courseReqFormField">
                  <div class="contentDiv">
                    <div ngClass="fieldLabel">
                      <label>
                        Academy
                        <span class="mandatory-field-indicator">*</span>
                      </label>
                    </div>
                    <div ngClass="fieldInput">
                      <select class="contentwidth borderRadius height25" [ngClass]="{mouseDisabled:submitInProgress}"
                        (change)="filterGenre()" formControlName="academy" required>
                        <option value="" disabled selected>Please select an academy</option>
                        <option *ngFor="let academy of academyList" [value]="academy">{{ academy }}</option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.academy.valid &&
                      (contentForm['controls'].academy.dirty ||
                        contentForm['controls'].academy.touched)
                    " class="error-msg">
                        Please choose the academy to which the course belongs.
                      </div>
                    </div>
                  </div>
                  <div class="contentDiv">
                    <div ngClass="fieldLabel">
                      <label>
                        Genre
                        <span class="mandatory-field-indicator">*</span>
                      </label>
                    </div>
                    <div ngClass="fieldInput">
                      <select class="contentwidth borderRadius height25" [ngClass]="{
                      mouseDisabled:
                        contentForm.get('academy').value == '' ||
                        contentForm.get('academy').value == undefined ||
                        contentForm.get('academy').value == null
                    }" (change)="filterSkill()" title="{{
                      contentForm.get('academy').value == ''
                        ? 'Please choose an Academy'
                        : ''
                    }}" formControlName="genre">
                        <option disabled selected value="">Please select a genre</option>
                        <option *ngFor="let genre of genreList" [value]="genre">{{ genre }}
                        </option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.genre.valid &&
                      (contentForm['controls'].genre.dirty ||
                        contentForm.controls.genre.touched)
                    " class="error-msg">
                        Please choose the genre to which the course belongs.
                      </div>
                    </div>
                  </div>
                  <div class="contentDiv">
                    <div ngClass="fieldLabel">
                      <label>
                        Skill
                        <span class="mandatory-field-indicator">*</span>
                      </label>
                    </div>
                    <div ngClass="fieldInput">
                      <select class="contentwidth borderRadius height25" [ngClass]="{
                      mouseDisabled:
                        contentForm.get('genre').value == '' ||
                        contentForm.get('genre').value == undefined ||
                        contentForm.get('genre').value == null
                    }" title="{{
                      contentForm.get('genre').value == ''
                        ? 'Please choose a Genre'
                        : ''
                    }}" formControlName="skill">
                        <option disabled selected value="">Please select a skill
                        </option>
                        <option *ngFor="let skill of skillList" [value]="skill">{{ skill }}
                        </option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.skill.valid &&
                      (contentForm.controls.skill.dirty ||
                        contentForm.controls.skill.touched)
                    " class="error-msg">
                        Please choose the skill to which the course belongs.
                      </div>
                    </div>
                  </div>
                  <div class="contentDiv">
                    <div ngClass="fieldLabel">
                      <label>Course Name
                        <span class="mandatory-field-indicator">*</span>
                      </label>
                    </div>
                    <input YorbitWorkspaceAutocompleteOff class="contentwidth borderRadius height21" matInput
                      placeholder="Please enter a course name" formControlName="name" />
                    <div *ngIf="
                    !contentForm.controls.name.valid &&
                    (contentForm.controls.name.dirty ||
                      contentForm.controls.name.touched)
                  " class="error-msg">
                      <span *ngIf="
                      !contentForm.get('name').errors.isInvalid &&
                      contentForm.get('name').value == ''
                    ">
                        {{errorMsgs.course_name}}</span>
                      <span *ngIf="contentForm.get('name').errors.isInvalid">The course name already exists.
                      </span>
                    </div>
                  </div>
                  <div class="contentDiv">
                    <div ngClass="fieldLabel">
                      <label>Topics Covered
                        <span class="mandatory-field-indicator">*</span>
                      </label>
                    </div>
                    <div class="text-area-desc topics-covered">
                      <div class="">
                        <textarea class="" placeholder="Enter topics covered (minimum 50 characters)"
                          formControlName="topicCovered"></textarea>
                      </div>
                    </div>
                    <div *ngIf="
                    !contentForm.controls.topicCovered.valid &&
                    (contentForm.controls.topicCovered.dirty ||
                      contentForm.controls.topicCovered.touched)
                  " class="error-msg">
                      <span *ngIf="!contentForm.get('topicCovered').errors.isInvalid">Please mention the topics
                        covered.</span>
                      <span *ngIf="contentForm.get('topicCovered').errors.isInvalid">Please enter at least 50
                        characters.</span>
                    </div>
                  </div>
                  <div class="contentDiv">
                    <div ngClass="fieldLabel">
                      <label>Course Description
                        <span class="mandatory-field-indicator">*</span>
                      </label>
                    </div>
                    <div class="text-area-desc description">
                      <div>
                        <textarea class="" placeholder="Enter course description (minimum 250 characters)"
                          formControlName="description"></textarea>
                      </div>
                    </div>
                    <div *ngIf="
                    !contentForm.controls.description.valid &&
                    (contentForm.controls.description.dirty ||
                      contentForm.controls.description.touched)
                  " class="error-msg">
                      <span *ngIf="!contentForm.get('description').errors.isInvalid">Please provide description for the
                        course.</span>
                      <span *ngIf="contentForm.get('description').errors.isInvalid">Please enter at least 250
                        characters.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="alignRight" ngClass="courseReqFormFields" [ngStyle.lt-md]="'border:none'" fxLayout="column">
                <div ngClass="courseReqFormField">
                  <div class="contentDiv divDisplay" fxLayout="row">
                    <div class="alignLeft divWidth" style="width:308px !important">
                      <div ngClass="fieldLabel">
                        <label>
                          Course Type
                          <span class="mandatory-field-indicator">*</span></label>
                      </div>
                      <div ngClass="fieldInput">
                        <select [ngClass]="{greyscaleDropdown:submitInProgress}"
                          class="contentCoursewidth borderRadius height25" style="width:288px !important"
                          formControlName="type" (change)="updateProofOfCompletion()">
                          <option value="" disabled selected>Please select a course type</option>
                          <option *ngFor="let courseType of courseTypeList" [value]="courseType.CourseTypeName">{{
                            courseType.CourseTypeName }}
                          </option>
                        </select>
                        <div *ngIf="
                        !contentForm.controls.type.valid &&
                        (contentForm.controls.type.dirty ||
                          contentForm.controls.type.touched)
                      " class="error-msg">
                          Please choose a course type.
                        </div>
                      </div>
                    </div>
                    <!-- <div class="alignRight divWidth">
                      <div ngClass="fieldLabel">
                        <label>Is Course Internal?
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                        <label class="paddingRight20">
                          <input  class="verticalAlign" type="radio" value="{{ radio }}"
                            formControlName="internal" />{{ radio }}
                        </label>
                      </div>
                      <div *ngIf="
                      !contentForm.controls.internal.valid &&
                      (contentForm.controls.internal.dirty ||
                        contentForm.controls.internal.touched)
                    " class="error-msg">
                        Please choose an option.
                      </div>
                    </div> -->
                    <div class="alignRight" style="width:308px !important">
                      <div ngClass="fieldLabel">
                        <label>Name of the Vendor
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <select [ngClass]="{greyscaleDropdown:submitInProgress}"
                        class="contentCoursewidth borderRadius height25" formControlName="vendor"
                        style="width:288px !important">
                        <option value="" disabled selected>Please select a vendor</option>
                        <option *ngFor="let vendor of vendorList" [value]="vendor.Vendor" title="{{vendor.Vendor}}">
                          {{ (vendor.Vendor.length > 37 ? vendor.Vendor.substr(0,36) + '...' : vendor.Vendor) }}
                        </option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.vendor.valid &&
                      (contentForm.controls.vendor.dirty ||
                        contentForm.controls.vendor.touched)
                    " class="error-msg">
                        Please choose a vendor.
                      </div>
                    </div>
                  </div>

                  <div class="contentDiv divDisplay" fxLayout="row">
                    <div class="alignLeft divWidth" style="width:198px">
                      <div ngClass="fieldLabel">
                        <label>Is Course Paid?
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                        <label class="paddingRight20">
                          <input class="verticalAlign" type="radio" value="{{ radio }}" formControlName="paid"
                            (change)="setPaymentOptions()" />{{ radio }}
                        </label>
                      </div>
                      <div *ngIf="
                      !contentForm.controls.paid.valid &&
                      (contentForm.controls.paid.dirty ||
                        contentForm.controls.paid.touched)
                    " class="error-msg">
                        Please choose an option.
                      </div>
                    </div>
                    <div [ngClass]="{'opticalHide':contentForm.get('paid').value == 'No'}" class="alignRight divWidth">
                      <div ngClass="fieldLabel">
                        <label>Course Price
                          <span class="mandatory-field-indicator"
                            *ngIf="contentForm.get('paid').value == 'Yes'">*</span>
                        </label>
                      </div>
                      <input YorbitWorkspaceAutocompleteOff class="contentCoursewidth borderRadius height21" matInput
                        placeholder="Enter course price" formControlName="price" (input)="numbersOnlyFields('price')" />
                      <div *ngIf="
                      !contentForm.controls.price.valid &&
                      (contentForm.controls.price.dirty ||
                        contentForm.controls.price.touched) &&
                      (contentForm.get('price').errors.isInvalid ||
                        contentForm.get('price').errors.fieldIsInvalid)
                    " class="error-msg">
                        <span *ngIf="!contentForm.get('price').errors.fieldIsInvalid">Please provide course
                          price.</span>
                        <span *ngIf="contentForm.get('price').errors.fieldIsInvalid">Please enter numbers only.</span>
                      </div>
                    </div>
                    <div [ngClass]="{'opticalHide':contentForm.get('paid').value == 'No'}" class="alignRight">
                      <div ngClass="fieldLabel currencydivWidth" style="width:80px">
                        <label>Currency
                          <span class="mandatory-field-indicator"
                            *ngIf="contentForm.get('paid').value == 'Yes'">*</span>
                        </label>
                      </div>
                      <select class="currencyDDWidth borderRadius height25" formControlName="currency">
                        <option disabled selected value="">Please select a currency
                          <span class="mandatory-field-indicator"
                            *ngIf="contentForm.get('paid').value == 'Yes'">*</span>
                        </option>
                        <option *ngFor="let currenyList of currenyList" [value]="currenyList">{{ currenyList }}</option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.currency.valid &&
                      (contentForm.controls.currency.dirty ||
                        contentForm.controls.currency.touched) &&
                      contentForm.get('currency').errors.isInvalid
                    " class="error-msg">
                        Please choose a currency.
                      </div>
                    </div>
                    <div [ngClass]="{'opticalHide':contentForm.get('paid').value == 'No'}" class="alignRight">
                      <div ngClass="fieldLabel">
                        <label>Payment
                          <span class="mandatory-field-indicator"
                            *ngIf="contentForm.get('paid').value == 'Yes'">*</span>
                        </label>
                      </div>
                      <select class="pricewidth borderRadius height25" formControlName="paymentMode">
                        <option disabled selected value="">Please select a mode</option>
                        <option *ngFor="let coursePaymentMode of courseModeOfPaymentList"
                          [value]="coursePaymentMode.Id">
                          {{ coursePaymentMode.Mode }}</option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.paymentMode.valid &&
                      (contentForm.controls.paymentMode.dirty ||
                        contentForm.controls.paymentMode.touched) &&
                      contentForm.get('paymentMode').errors.isInvalid
                    " class="error-msg">
                        Please choose a payment mode.
                      </div>
                    </div>
                  </div>

                  <div class="contentDiv divDisplay" fxLayout="row">
                    <div class="alignLeft divWidth">
                      <div ngClass="fieldLabel">
                        <label>Course Duration
                          <span class="mandatory-field-indicator">*</span>
                          <div class="info-icon" (click)="popInformationBits($event,'Duration')">i</div>
                        </label>

                      </div>
                      <div class="divDisplay">
                        <div class="alignLeft">
                          <input YorbitWorkspaceAutocompleteOff class="borderRadius height21"
                            style="width:65px;text-align:start;margin-right:10px" matInput placeholder="00.00.00"
                            formControlName="duration" (input)="numbersOnlyFields('duration')" />
                        </div>
                        <div ngClass="fieldLabel">
                          <label>Hours</label>
                        </div>
                      </div>
                      <div *ngIf="
                      !contentForm.controls.duration.valid &&
                      (contentForm.controls.duration.dirty ||
                        contentForm.controls.duration.touched) &&
                      contentForm.get('duration').errors.fieldIsInvalid
                    " class="error-msg">
                        <span *ngIf="!contentForm.get('duration').errors.fieldIsInvalid">Please provide course duration.
                        </span>
                        <span
                          *ngIf="contentForm.get('duration').errors.fieldIsInvalid">{{errorMsgs.course_duration}}</span>
                      </div>
                    </div>
                    <div class="alignRight divWidth">
                      <div ngClass="fieldLabel">
                        <label>Is Learning Business Partner Approval Required?
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                        <label class="paddingRight20">
                          <input class="verticalAlign" type="radio" value="{{ radio }}"
                            formControlName="managerApproval" />{{ radio }}
                        </label>
                      </div>
                    </div>
                    <div *ngIf="
                    !contentForm.controls.managerApproval.valid &&
                    (contentForm.controls.managerApproval.dirty ||
                      contentForm.controls.managerApproval.touched)
                  " class="error-msg">
                      Please choose an option.
                    </div>
                    <!-- Alert Required Starts Here -->
                    <div class="alignRight divWidth">
                      <div ngClass="fieldLabel">
                        <label>Is Mail Alert Required?
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <label class="paddingRight20">
                        <input class="verticalAlign" type="radio" value="1" formControlName="IsMailAlertRequired" />Yes
                      </label>

                      <label class="paddingRight20">
                        <input class="verticalAlign" type="radio" value="0" formControlName="IsMailAlertRequired" />No
                      </label>
                    </div>
                    <div *ngIf="
                    !contentForm.controls.IsMailAlertRequired.valid &&
                    (contentForm.controls.IsMailAlertRequired.dirty ||
                      contentForm.controls.IsMailAlertRequired.touched)
                  " class="error-msg">
                      Please choose an option.
                    </div>
                    <!-- Alert Required Ends Here -->
                  </div>

                  <div class="contentDiv divDisplay" fxLayout="row">
                    <div class="alignLeft textareaDiv">
                      <div ngClass="fieldLabel">
                        <label>Pre-requisite Course ID(s)</label>
                      </div>
                      <textarea class="textareaWidth contentheight1 borderRadius"
                        placeholder="Please enter pre-requiste course IDs." formControlName="preRequisiteCourseIDs"
                        style="display:none"></textarea>
                      <div class="textareaWidth contentheight1 borderRadius prereq-control">
                        <div class="selected-courses-box" *ngIf="!isNullOrEmpty(selectedPrereqCourses)">
                          <div *ngFor="let item of selectedPrereqCourses" fxLayout="row">
                            <div class="course-name" fxFlex="90" title="{{item.Name + '-' + item.Expertise}}">{{
                              item.Name + "-" + item.Expertise }}</div>
                            <div fxFlex="10" *ngIf="!isApproved" (click)="removeItem(item, true)"
                              class="del-icon-holder floatLeft mousePointer">
                              <div class="yorbitSprite del-icon-grey-small"></div>
                            </div>
                          </div>
                        </div>
                        <div class="selected-courses-box" *ngIf="isNullOrEmpty(selectedPrereqCourses)" fxLayout="column"
                          fxLayoutAlign="center center">
                          <div class="placeholder-msg">Pre-requisite Courses selected by you will be shown here.</div>
                          <div class="placeholder-msg">Please select from the below link.</div>
                        </div>
                      </div>
                      <div class="back-btn mousePointer" (click)="openYorbitCourses(true)"
                        [ngClass]="{'mouseDisabled':isApproved}">
                        Select Pre-requisite Course(s)</div>
                    </div>
                    <div class="alignRight textareaDiv">
                      <div ngClass="fieldLabel">
                        <label>Post Recommended Course ID(s)</label>
                      </div>
                      <textarea class="textareaWidth contentheight1 borderRadius"
                        placeholder="Please enter post recommended course IDs."
                        formControlName="postRecommendedCourseIDs" style="display:none"></textarea>
                      <div class="textarea Width contentheight1 borderRadius" class="prereq-control">
                        <div class="selected-courses-box" *ngIf="!isNullOrEmpty(selectedPostRecommCourses)">
                          <div *ngFor="let item of selectedPostRecommCourses" fxLayout="row">
                            <div class="course-name" fxFlex="90" title="{{item.Name + '-' + item.Expertise}}">{{
                              item.Name + "-" + item.Expertise }}</div>
                            <div fxFlex="10" *ngIf="!isApproved" (click)="removeItem(item, false)"
                              class="del-icon-holder floatLeft mousePointer">
                              <div class="yorbitSprite del-icon-grey-small"></div>
                            </div>
                          </div>
                        </div>
                        <div class="selected-courses-box" *ngIf="isNullOrEmpty(selectedPostRecommCourses)"
                          fxLayout="column" fxLayoutAlign="center center">
                          <div class="placeholder-msg">Post Recommended Courses selected by you will be shown here.
                          </div>
                          <div class="placeholder-msg">Please select from the below link.</div>
                        </div>
                      </div>
                      <div class="back-btn mousePointer" (click)="openYorbitCourses(false)"
                        [ngClass]="{'mouseDisabled':isApproved}">Select Post Recommended Course(s)</div>
                    </div>
                  </div>

                  <div class="contentDiv divDisplay" fxLayout="row">
                    <div class="alignLeft width230">
                      <div ngClass="fieldLabel">
                        <label>Is Pre-requisites Mandatory?
                          <span class="mandatory-field-indicator" *ngIf="
                      contentForm.get('preRequisiteCourseIDs').value !==null &&
                      contentForm.get('preRequisiteCourseIDs').value.length !== 0
                        ">*</span>
                        </label>
                      </div>
                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                        <label class="paddingRight20">
                          <input class="verticalAlign" type="radio" value="{{ radio }}"
                            formControlName="isPreRequisiteMandatory" />{{ radio }}
                        </label>
                      </div>
                      <div *ngIf="
                  contentForm.get('preRequisiteCourseIDs').value !==null &&
                  contentForm.get('preRequisiteCourseIDs').value.length !== 0 &&
                      contentForm.get('isPreRequisiteMandatory').value == ''
                    " class="error-msg">
                        Pre-requisites cannot be empty.
                      </div>
                    </div>
                    <div class="alignRight divWidth">
                      <div ngClass="fieldLabel">
                        <label>Who can take this course?
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <select [ngClass]="{greyscaleDropdown:submitInProgress}"
                        class="courseDropDown borderRadius height25" formControlName="eligibility">
                        <option value="" disabled selected>Please select a value</option>
                        <option *ngFor="let courseEligibile of courseEligibilityList" [value]="courseEligibile.Id">
                          {{ courseEligibile.EligibleGroup }}</option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.eligibility.valid &&
                      (contentForm.controls.eligibility.dirty ||
                        contentForm.controls.eligibility.touched)
                    " class="error-msg">
                        Please choose an option.
                      </div>
                    </div>
                    <div class="alignRight">
                      <div ngClass="fieldLabel">
                        <label>Course Accessibility
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <select [ngClass]="{greyscaleDropdown:submitInProgress}"
                        class="courseDropDown borderRadius height25" formControlName="accessibility">
                        <option value="" disabled selected>
                          Please select a value</option>
                        <option *ngFor="
                        let courseAccessibility of courseAccessibilityList
                      " [value]="courseAccessibility.Id">{{ courseAccessibility.Access }}</option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.accessibility.valid &&
                      (contentForm.controls.accessibility.dirty ||
                        contentForm.controls.accessibility.touched)
                    " class="error-msg">
                        Please choose an option.
                      </div>
                    </div>
                  </div>

                  <div class="contentDiv divDisplay" fxLayout="row">
                    <div class="alignLeft width70">
                      <div ngClass="fieldLabel">
                        <label>Credits
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <input YorbitWorkspaceAutocompleteOff style="text-align:start"
                        class="currencyDDWidth borderRadius height21" matInput placeholder="" maxlength="1"
                        formControlName="credits" (input)="numbersOnlyFields('credits')" />
                      <div *ngIf="
                      !contentForm.controls.credits.valid &&
                      (contentForm.controls.credits.dirty ||
                        contentForm.controls.credits.touched) &&
                      contentForm.get('credits').errors.isInvalid
                    " class="error-msg">
                        <span *ngIf="contentForm.get('credits').errors.isInvalid">{{errorMsgs.credits}}</span>
                        <span *ngIf="!contentForm.get('credits').errors.isInvalid">
                          Please enter credits.</span>
                      </div>
                    </div>
                    <div class="alignRight width188">
                      <div ngClass="fieldLabel">
                        <label>Course Classification
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <select [ngClass]="{greyscaleDropdown:submitInProgress}"
                        class="contentCoursewidth borderRadius height25" formControlName="classification">
                        <option value="" disabled selected>Please select a value
                        </option>
                        <option *ngFor="
                        let courseClassification of courseClassificationList
                      " [value]="courseClassification.Id">{{ courseClassification.Classification }}
                        </option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.classification.valid &&
                      (contentForm.controls.classification.dirty ||
                        contentForm.controls.classification.touched)
                    " class="error-msg">
                        Please choose an option.
                      </div>
                    </div>

                    <!--course proficiency start-->
                    <div class="alignRight width188">
                      <div ngClass="fieldLabel">
                        <label>Course Proficiency
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <select [ngClass]="{greyscaleDropdown:submitInProgress}"
                        class="contentCoursewidth borderRadius height25" formControlName="proficiency">
                        <option value="" disabled selected>Please select a proficiency
                        </option>
                        <option *ngFor="
                        let coursePro of courseProficiencyList
                      " [value]="coursePro.Proficiency">{{ coursePro.Proficiency }}
                        </option>
                      </select>
                      <div *ngIf="
                      !contentForm.controls.proficiency.valid &&
                      (contentForm.controls.proficiency.dirty ||
                        contentForm.controls.proficiency.touched)
                    " class="error-msg">
                        Please choose a course proficiency.
                      </div>
                    </div>
                    <!--course proficiency start-->

                    <div class="alignRight width170">
                      <div ngClass="fieldLabel">
                        <label>Badge Name
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <input YorbitWorkspaceAutocompleteOff class="width160 borderRadius height21" matInput
                        placeholder="Please enter badge name" formControlName="badge" />
                      <div *ngIf="
                      !contentForm.controls.badge.valid &&
                      (contentForm.controls.badge.dirty ||
                        contentForm.controls.badge.touched)
                    " class="error-msg">
                        {{errorMsgs.badge_name}}
                      </div>
                    </div>
                    <div class="alignRight">
                      <div ngClass="fieldLabel">
                        <label>Expert PSID(s)</label>
                      </div>
                      <input YorbitWorkspaceAutocompleteOff class="courseDropDown borderRadius height21" matInput
                        placeholder="Please enter PSID" formControlName="expertMids" />
                      <div *ngIf="
                      !contentForm.controls.expertMids.valid &&
                      contentForm.controls.expertMids.dirty &&
                      contentForm.get('expertMids').errors.isInvalid
                    " class="error-msg">
                        {{errorMsgs.expert_mids}}
                      </div>
                    </div>
                  </div>

                  <div class="contentDiv divDisplay" fxLayout="row">
                    <div class="alignLeft divWidth">
                      <div ngClass="fieldLabel">
                        <label>Content Partner
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <div ngClass="fieldInput" readonly>
                        <div class="contentPartnerBox contentCoursewidth borderRadius height21 text-overflow"
                          title="{{contentForm.get('ContentOwner').value}}">
                          {{contentForm.get('ContentOwner').value}}
                        </div>
                        <div class="yorbitSprite editSpriteIcon mousePointer" (click)="openSearchCODialog()"></div>
                        <!--
                        <input class="contentCoursewidth borderRadius height21 text-overflow" matInput placeholder="" formControlName="ContentOwner"
                        title="{{contentForm.get('ContentOwner')}}" />
                      -->

                        <div *ngIf="
                        !contentForm.controls.ContentOwner.valid &&
                        (contentForm.controls.ContentOwner.dirty ||
                          contentForm.controls.ContentOwner.touched)
                      " class="error-msg">
                          Please provide Content Partner's name.
                        </div>
                      </div>
                    </div>
                    <div class="alignRight width170">
                      <div ngClass="fieldLabel">
                        <label>Introductory Video
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                        <label class="paddingRight20">
                          <input class="verticalAlign" type="radio" value="{{ radio }}"
                            formControlName="isIntroductoryVideo" (change)="setVideoURLOptions()" />{{ radio }}
                        </label>
                      </div>
                    </div>
                    <div class="alignRight width250">
                      <div ngClass="fieldLabel">
                        <label>Is Course Launched only for Providing Badge and Credits?
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                        <label class="paddingRight20">
                          <input class="verticalAlign" type="radio" value="{{ radio }}"
                            formControlName="creditSpecial" />{{ radio }}
                        </label>
                      </div>
                      <div *ngIf="
                      !contentForm.controls.creditSpecial.valid &&
                      (contentForm.controls.creditSpecial.dirty ||
                        contentForm.controls.creditSpecial.touched)
                    " class="error-msg">
                        Please choose an option.
                      </div>
                    </div>
                  </div>
                  <div class="contentDiv divDisplay" *ngIf="false">

                  </div>

                  <div class="contentDiv divDisplay" fxLayout="row">

                    <div class="alignLeft divWidth" *ngIf="contentForm.get('creditSpecial').value == 'Yes'">

                      <div ngClass="fieldLabel">
                        <label>Course Completion Certificate to be generated by Shoshin School?
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>

                      <label class="paddingRight20">
                        <input class="verticalAlign" type="radio" value="1"
                          formControlName="IsCertificateAvailable" />Yes
                      </label>

                      <label class="paddingRight20">
                        <input class="verticalAlign" type="radio" value="0"
                          formControlName="IsCertificateAvailable" />No
                      </label>

                    </div>

                    <div class="alignLeft divWidth ml30">

                      <div ngClass="fieldLabel">
                        <label>Is Cloud Lab Available?
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>

                      <label class="paddingRight20">
                        <input class="verticalAlign" type="radio" value="1" formControlName="IsCloudLabAvailable" />Yes
                      </label>

                      <label class="paddingRight20">
                        <input class="verticalAlign" type="radio" value="0" formControlName="IsCloudLabAvailable" />No
                      </label>
                    </div>
                    <div class="alignRight width500">
                      <div ngClass="fieldLabel">
                        <label>Skill Prism ID
                        </label>
                      </div>
                      <input YorbitWorkspaceAutocompleteOff style="text-align:start"
                        class="width150 borderRadius height21" matInput placeholder=" Please enter Skill ID"
                        formControlName="SkillIds" />
                    </div>
                  </div>


                  <div class="contentDiv divDisplay" fxLayout="row"
                    *ngIf="contentForm.get('isIntroductoryVideo').value == 'Yes'">
                    <div class="alignLeft divWidth">
                      <div ngClass="fieldLabel">
                        <label>Video Type
                          <span class="mandatory-field-indicator">*</span>
                        </label>
                      </div>
                      <div ngClass="fieldInput">
                        <select class="courseDropDown borderRadius height25" [disabled]="isApproved"
                          formControlName="sourceType"
                          [ngClass]="{greyscaleDropdown:submitInProgress,'mouseDisabled':isApproved}">
                          <option value="" disabled selected>Please select a video type</option>
                          <option value="O365 Video">Azure Media Services</option>
                          <option value="YouTube">YouTube</option>
                          <option value="External Link">External Link</option>
                        </select>
                      </div>
                      <div *ngIf="
                      !contentForm.controls.sourceType.valid &&
                      (contentForm.controls.sourceType.dirty ||
                        contentForm.controls.sourceType.touched) &&
                      contentForm.get('sourceType').errors.isInvalid
                    " class="error-msg">
                        Please choose a video type.
                      </div>
                    </div>

                    <div class="alignRight width250">
                      <div ngClass="fieldLabel">
                        <label>Video Link
                          <span class="mandatory-field-indicator">*</span>
                          <div class="info-icon" (click)="popInformationBits($event,'VideoLink')">i</div>
                        </label>
                      </div>
                      <div class="width350">
                        <div>
                          <input YorbitWorkspaceAutocompleteOff class="width350 borderRadius height21"
                            formControlName="introductoryVideo" matInput placeholder=""
                            [ngClass]="{'mouseDisabled':isApproved}" [disabled]="isApproved" />
                        </div>
                        <div class="info-msg">
                          <div *ngIf="contentForm.get('sourceType').value == 'YouTube'">Sample URL Format :
                            https://www.youtube.com/embed/k5E2AVpwsko</div>
                          <div *ngIf="false && contentForm.get('sourceType').value == 'O365 Video'">Sample URL Format :
                            https://mindtreeonline.sharepoint.com/portals/hub/_api/videoservice/Channels(guid'23f0c1e0-5a56-43ad-b31d-379232d2814f')/Videos(guid'38f5ac5f-48a7-4e70-bb83-c4f0eecc97fe')
                          </div>
                          <div *ngIf="contentForm.get('sourceType').value == 'O365 Video'">Sample URL Format :
                            https://yorbit.streaming.mediaservices.windows.net/xxx/xxx.ism/manifest(encryption=cbc)
                          </div>
                        </div>
                        <div *ngIf="
                        !contentForm.controls.introductoryVideo.valid &&
                        (contentForm.controls.introductoryVideo.dirty ||
                          contentForm.controls.introductoryVideo.touched)
                      " class="error-msg">
                          Please provide a video URL.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="operational-form" *ngIf="operationalCourseTabClicked">
              <mat-accordion>
                <mat-expansion-panel class="panelMargin">
                  <mat-expansion-panel-header [ngClass]="{
                  panelBackground:
                    contentForm.controls['infrastructure'].controls[0].value ==
                      true ||
                    contentForm.controls['infrastructure'].controls[1].value ==
                      true ||
                    contentForm.controls['infrastructure'].controls[2].value ==
                      true ||
                    contentForm.controls['infrastructure'].controls[3].value ==
                      true ||
                    contentForm.controls['infrastructure'].controls[4].value ==
                      true
                }">
                    <mat-panel-title class>
                      Infrastructure Required
                      <div *ngIf="!contentForm.valid && !isInfrastructureValid" class="err-icon-holder"
                        title="Click here to view the detailed error.">
                        <div class="yorbitSprite nonCompliantIcon mousePointer" (click)="openErrPopup(0,$event)"></div>
                      </div>
                      <div *ngIf="!(!contentForm.valid && !isInfrastructureValid)" class="info-icon accord-header"
                        (click)="popInformationBits($event,'InfrastructureRequired')">i</div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <section>
                    <div class="divDisplay padding">
                      <div *ngFor="
                      let infrastructure of contentForm.controls[
                        'infrastructure'
                      ].controls;
                      let i = index
                    ">
                        <mat-checkbox class="paddingRight50" (change)="
                        onInfrastructureChange($event, i, infrastructure)
                      " [formControl]="infrastructure" color="primary">
                          {{ courseInfrastructureList[i].Infrastructure }}
                        </mat-checkbox>

                        <div *ngFor="
                        let infrastructureType of contentForm.controls[
                          'infrastructureType'
                        ].controls;
                        let j = index
                      ">
                          <div *ngIf="
                          (courseInfrastructureList[i].Id == 1 ||
                            courseInfrastructureList[i].Id == 2) &&
                          courseInfrastructureList[i].Id ==
                            courseInfrastructureTypeList[j]
                              .CourseInfrastructureId &&
                          infrastructure.value==true
                        ">
                            <div ngClass="fieldLabel vendorBox">
                              <label>{{
                                courseInfrastructureTypeList[j].InfrastructureType
                                }}
                                <span class="mandatory-field-indicator">*</span>
                              </label>
                              <textarea class="width230 contentheight1 borderRadius"
                                [formControl]="infrastructureType"></textarea>
                            </div>
                          </div>
                          <div *ngIf="
                          (courseInfrastructureList[i].Id == 3 ||
                            courseInfrastructureList[i].Id == 4) &&
                          courseInfrastructureList[i].Id ==
                            courseInfrastructureTypeList[j]
                              .CourseInfrastructureId &&
                              infrastructure.value==true
                        ">
                            <div ngClass="fieldLabel vendorBox">
                              <label>{{
                                courseInfrastructureTypeList[j].InfrastructureType
                                }}
                                <span class="mandatory-field-indicator">*</span>
                              </label>
                              <input YorbitWorkspaceAutocompleteOff class="width230 borderRadius height21" matInput
                                [formControl]="infrastructureType" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </mat-expansion-panel>
                <mat-expansion-panel class="panelMargin">
                  <mat-expansion-panel-header [ngClass]="{
                  panelBackground:
                    contentForm.controls['loginType'].controls[0].value ==
                      true ||
                    contentForm.controls['loginType'].controls[1].value ==
                      true ||
                    contentForm.controls['loginType'].controls[2].value ==
                      true ||
                    contentForm.controls['loginType'].controls[3].value == true
                }">
                    <mat-panel-title>
                      Course Login Type
                      <div *ngIf="!contentForm.valid && !isLoginTypeValid" class="err-icon-holder"
                        title="Click here to view the detailed error.">
                        <div class="yorbitSprite nonCompliantIcon mousePointer" (click)="openErrPopup(1,$event)"></div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <section>
                    <div class="divDisplay padding custom-chkbox">
                      <div *ngFor="
                      let loginType of contentForm.controls['loginType']
                        .controls;
                      let i = index
                    ">
                        <mat-checkbox class="paddingRight50" (change)="onLoginChange($event, i, loginType)"
                          [formControl]="loginType" color="primary">
                          {{ courseLoginList[i].Login }}
                        </mat-checkbox>
                        <div>
                          <div ngClass="fieldLabel learning"
                            *ngIf="courseLoginList[i].Id == 2 && loginType.value==true">
                            <div class="selfBox">
                              <div>
                                <label>Link(s) to Course in Vendor's Site
                                  <span class="mandatory-field-indicator">*</span>
                                </label>
                              </div>
                              <div *ngFor="
                              let linktoCoursesForSelfRegistration of contentForm
                                .controls['linktoCoursesForSelfRegistration']
                                .controls;
                              let j = index; let last=last;let first=first;
                            ">
                                <div class="divDisplay">
                                  <div>
                                    <input YorbitWorkspaceAutocompleteOff
                                      class="width230 borderRadius height21 panelMarginTop" matInput
                                      [formControl]="linktoCoursesForSelfRegistration" />
                                    <div *ngIf="last">
                                      <button class="form-action-btn" title="Add" (click)="addSelfRegistrationLink()"
                                        type="button" [disabled]="isApproved" [ngClass]="{'mouseDisabled':isApproved}">
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      <button class="deleteButton" title="Delete" type="button" [disabled]="isApproved"
                                        (click)="openDeleteConfirmationPopup(j,'selfRegCourseLink')" *ngIf="contentForm
                                      .controls['linktoCoursesForSelfRegistration']
                                      .controls.length > 1">
                                        <div class="yorbitSprite del-icon-grey-small mousePointer"
                                          [ngClass]="{'mouseDisabled':isApproved}"></div>
                                      </button>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="selfBox">
                              <div>
                                <div>
                                  <label>Course Name(s) in Vendor Site
                                    <span class="mandatory-field-indicator">*</span>
                                  </label>
                                </div>
                                <div *ngFor="
                                let coursesNameForSelfRegistration of contentForm
                                  .controls['coursesNameForSelfRegistration']
                                  .controls;
                                let j = index; let last=last;let first=first;
                              ">
                                  <div class="divDisplay">
                                    <div>
                                      <input YorbitWorkspaceAutocompleteOff
                                        class="width230 borderRadius height21 panelMarginTop" matInput
                                        [formControl]="coursesNameForSelfRegistration" />
                                      <div *ngIf="last">
                                        <button class="form-action-btn" title="Add" (click)="addSelfRegistrationName()"
                                          type="button" [disabled]="isApproved"
                                          [ngClass]="{'mouseDisabled':isApproved}">
                                          ADD
                                        </button>
                                      </div>
                                    </div>
                                    <div>
                                      <button class="deleteButton" title="Delete" [disabled]="isApproved" type="button"
                                        (click)="openDeleteConfirmationPopup(j, 'selfRegCourseName')" *ngIf="contentForm
                                      .controls['coursesNameForSelfRegistration']
                                      .controls.length > 1">
                                        <div class="yorbitSprite del-icon-grey-small mousePointer"
                                          [ngClass]="{'mouseDisabled':isApproved}"></div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div ngClass="fieldLabel  learning"
                            *ngIf="courseLoginList[i].Id == 3 && loginType.value==true">
                            <div class="selfBox">
                              <div>
                                <label>Login Details
                                  <span class="mandatory-field-indicator">*</span>
                                </label>
                              </div>
                              <div>
                                <input YorbitWorkspaceAutocompleteOff class="width230 borderRadius height21" matInput
                                  formControlName="loginDetails" />
                              </div>
                            </div>
                            <div class="selfBox">
                              <div>
                                <label>Link(s) to Course
                                  <span class="mandatory-field-indicator">*</span>
                                </label>
                              </div>
                              <div *ngFor="
                              let linktoCoursesForLearningOPMTeam of contentForm
                                .controls['linktoCoursesForLearningOPMTeam']
                                .controls;
                              let j = index;let last=last;let first=first;
                            ">
                                <div class="divDisplay">
                                  <div>
                                    <input YorbitWorkspaceAutocompleteOff
                                      class="width230 borderRadius height21 panelMarginTop" matInput
                                      [formControl]="linktoCoursesForLearningOPMTeam" />
                                    <div *ngIf="last">
                                      <button class="form-action-btn" title="Add" (click)="addLearnerOPMLink()"
                                        type="button" [disabled]="isApproved" [ngClass]="{'mouseDisabled':isApproved}">
                                        ADD
                                      </button>
                                    </div>
                                  </div>
                                  <div>
                                    <button class="deleteButton" title="Delete" type="button" [disabled]="isApproved"
                                      (click)="openDeleteConfirmationPopup(j,'opmCourseLink')" *ngIf="contentForm
                                    .controls['linktoCoursesForLearningOPMTeam']
                                    .controls.length > 1">
                                      <div class="yorbitSprite del-icon-grey-small mousePointer"
                                        [ngClass]="{'mouseDisabled':isApproved}"></div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="selfBox">
                              <div>
                                <div>
                                  <label>Course Name(s) in Vendor Site
                                    <span class="mandatory-field-indicator">*</span>
                                  </label>
                                </div>
                                <div *ngFor="
                                let coursesNameForLearningOPMTeam of contentForm
                                  .controls['coursesNameForLearningOPMTeam']
                                  .controls;
                                let j = index;let last = last;let first=first;
                              ">
                                  <div class="divDisplay">
                                    <div>
                                      <input YorbitWorkspaceAutocompleteOff
                                        class="width230 borderRadius height21 panelMarginTop" matInput
                                        [formControl]="coursesNameForLearningOPMTeam" />
                                      <div *ngIf="last">
                                        <button class="form-action-btn" title="Add" (click)="addLearnerOPMName()"
                                          type="button" [disabled]="isApproved"
                                          [ngClass]="{'mouseDisabled':isApproved}">
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                    <div>
                                      <button class="deleteButton" [disabled]="isApproved" title="Delete" type="button"
                                        (click)="openDeleteConfirmationPopup(j,'opmCourseName')" *ngIf=" contentForm
                                      .controls['coursesNameForLearningOPMTeam']
                                      .controls.length > 1">
                                        <div class="yorbitSprite del-icon-grey-small mousePointer"
                                          [ngClass]="{'mouseDisabled':isApproved}"></div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </mat-expansion-panel>
                <mat-expansion-panel class="panelMargin">
                  <mat-expansion-panel-header [ngClass]="{
                  panelBackground:
                    contentForm.get('isLearningMaterial').value == 'Yes'
                }">
                    <mat-panel-title>
                      <div class="panelExpand" style="padding-right:33px">
                        Learning Material
                        <div *ngIf="!contentForm.valid && !isLearningMaterialValid" class="err-icon-holder"
                          title="Click here to view the detailed error.">
                          <div class="yorbitSprite nonCompliantIcon mousePointer" (click)="openErrPopup(2,$event)">
                          </div>
                        </div>
                      </div>
                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                        <label class="paddingRight20">
                          <input class="verticalAlign" class="radio" type="radio" value="{{ radio }}"
                            formControlName="isLearningMaterial" />{{ radio }}
                        </label>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <section>
                    <div class="divDisplay padding" *ngIf="contentForm.get('isLearningMaterial').value == 'Yes'">
                      <div class="paddingRight100 learning-mat" formGroupName="preSessionGroup">
                        <div ngClass="fieldLabel title">
                          <label>Pre-Session Learning Material</label>
                        </div>
                        <div class="content">
                          <div>
                            <div ngClass="fieldLabel">
                              <label>Name of Learning Material</label>
                            </div>
                            <div>
                              <input YorbitWorkspaceAutocompleteOff class="textareaDiv borderRadius height21" matInput
                                placeholder="" formControlName="preSessionLearningMaterialTextBox" />
                            </div>
                          </div>
                          <div ngClass="fieldLabel selfBox"></div>
                          <div ngClass="fieldLabel selfBox"></div>
                          <div *ngIf="preSessionSelected">
                            <div ngClass="fieldLabel">
                              <label>File containing Learning Material
                                <span class="mandatory-field-indicator" *ngIf="preSessionSelected">*</span>
                              </label>
                            </div>
                            <div>
                              <input class="width220px" type="file" id="PreSessionFileUpload" YorbitWorkspaceFileModel
                                formControlName="preSessionLearningMaterialFileBox" [modifyDOMElements]="false"
                                [inputFor]="'preSessionLearningMaterialFileBox'"
                                (fileModel)="fileModel('learningmaterial', 'Pre', 1,$event)" (change)="showorHideButton(PreSessionFilebtn); showErrorMsgForFileUpload(
                                  'learningmaterial', 'Pre', 1
                                )" />
                              <div class="upload-info-msg"
                                [ngClass]="{'error-msg':fileUploadInvalid['learningmaterial']==undefined?false:(fileUploadInvalid['learningmaterial']['Pre'] ==undefined?false:(fileUploadInvalid['learningmaterial']['Pre'][1]==undefined?false:fileUploadInvalid['learningmaterial']['Pre'][1]))}">
                                {{errorMsgs.file_upload_err + ' 25MB.'}}</div>
                            </div>
                            <button mat-raised-button id="PreSessionlinkbtn" class="anchorButton"
                              (click)="downloadFile('learningmaterial', 'Pre', 1)" type="button"
                              *ngIf="contentForm.get('preSessionGroup.preSessionLearningMaterialLink').value !=null && contentForm.get('preSessionGroup.preSessionLearningMaterialLink').value !=''">
                              Download File
                            </button>
                            <div class="fileNameLabel"></div>
                            <button mat-raised-button #PreSessionFilebtn id="PreSessionFilebtn"
                              class="form-action-btn actionButton"
                              (click)="uploadFile('learningmaterial', 'Pre', 1,$event)" type="button"
                              [ngClass]="{disabled:(fileUploadInvalid['learningmaterial']==undefined?false:(fileUploadInvalid['learningmaterial']['Pre'] ==undefined?false:(fileUploadInvalid['learningmaterial']['Pre'][1]==undefined?false:fileUploadInvalid['learningmaterial']['Pre'][1])))||(eUploadFile['learningmaterial']==undefined?true:(eUploadFile['learningmaterial']['Pre'] ==undefined?true:(eUploadFile['learningmaterial']['Pre'][1]==undefined?true:false)))}"
                              [disabled]="(fileUploadInvalid['learningmaterial']==undefined?false:(fileUploadInvalid['learningmaterial']['Pre'] ==undefined?false:(fileUploadInvalid['learningmaterial']['Pre'][1]==undefined?false:fileUploadInvalid['learningmaterial']['Pre'][1])))||(eUploadFile['learningmaterial']==undefined?true:(eUploadFile['learningmaterial']['Pre'] ==undefined?true:(eUploadFile['learningmaterial']['Pre'][1]==undefined?true:false)))">
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="paddingRight100 learning-mat" formGroupName="duringSessionGroup">
                        <div ngClass="fieldLabel title">
                          <label>During Session Learning Material</label>
                        </div>
                        <div class="content">
                          <div>
                            <div ngClass="fieldLabel">
                              <label>Name of Learning Material</label>
                            </div>
                            <div>
                              <input YorbitWorkspaceAutocompleteOff class="textareaDiv borderRadius height21" matInput
                                placeholder="" formControlName="duringSessionLearningMaterialTextBox" />
                            </div>
                          </div>
                          <div ngClass="fieldLabel selfBox"></div>
                          <div ngClass="fieldLabel selfBox"></div>
                          <div *ngIf="duringSessionSelected">
                            <div class="fieldLabel">
                              <label>File containing Learning Material
                                <span class="mandatory-field-indicator" *ngIf="duringSessionSelected">*</span>
                              </label>
                            </div>
                            <div>
                              <input class="width220px" type="file" id="DuringSessionFileUpload"
                                YorbitWorkspaceFileModel formControlName="duringSessionLearningMaterialFileBox"
                                [modifyDOMElements]="false" [inputFor]="'duringSessionLearningMaterialFileBox'"
                                (fileModel)="fileModel('learningmaterial', 'OnGoing', 1,$event)" (change)="showorHideButton(DuringSessionFilebtn); showErrorMsgForFileUpload(
                                  'learningmaterial', 'OnGoing', 1
                                )" />
                            </div>
                            <div class="upload-info-msg"
                              [ngClass]="{'error-msg':fileUploadInvalid['learningmaterial']==undefined?false:(fileUploadInvalid['learningmaterial']['OnGoing']==undefined?false:(fileUploadInvalid['learningmaterial']['OnGoing'][1]==undefined?false:fileUploadInvalid['learningmaterial']['OnGoing'][1]))}">
                              {{errorMsgs.file_upload_err + ' 25MB.'}}</div>

                            <button mat-raised-button #duringSessionlinkbtn id="duringSessionlinkbtn"
                              class="anchorButton" (click)="downloadFile('learningmaterial', 'OnGoing', 1)"
                              type="button"
                              *ngIf="contentForm.get('duringSessionGroup.duringSessionLearningMaterialLink').value !=null && contentForm.get('duringSessionGroup.duringSessionLearningMaterialLink').value !=''">
                              Download File
                            </button>
                            <div class="fileNameLabel"></div>
                            <button mat-raised-button #DuringSessionFilebtn id="DuringSessionFilebtn"
                              class="form-action-btn actionButton"
                              [ngClass]="{disabled:(fileUploadInvalid['learningmaterial']==undefined?false:(fileUploadInvalid['learningmaterial']['OnGoing']==undefined?false:(fileUploadInvalid['learningmaterial']['OnGoing'][1]==undefined?false:fileUploadInvalid['learningmaterial']['OnGoing'][1])))||(eUploadFile['learningmaterial']==undefined?true:(eUploadFile['learningmaterial']['OnGoing'] ==undefined?true:(eUploadFile['learningmaterial']['OnGoing'][1]==undefined?true:false)))}"
                              [disabled]="(fileUploadInvalid['learningmaterial']==undefined?false:(fileUploadInvalid['learningmaterial']['OnGoing']==undefined?false:(fileUploadInvalid['learningmaterial']['OnGoing'][1]==undefined?false:fileUploadInvalid['learningmaterial']['OnGoing'][1])))||(eUploadFile['learningmaterial']==undefined?true:(eUploadFile['learningmaterial']['OnGoing'] ==undefined?true:(eUploadFile['learningmaterial']['OnGoing'][1]==undefined?true:false)))"
                              (click)="uploadFile('learningmaterial', 'OnGoing', 1,$event)" type="button">
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="paddingRight100 learning-mat" formGroupName="postSessionGroup">
                        <div ngClass="fieldLabel title">
                          <label>Post-Session Learning Material</label>
                        </div>
                        <div class="content">
                          <div>
                            <div ngClass="fieldLabel">
                              <label>Name of Learning Material</label>
                            </div>
                            <div>
                              <input YorbitWorkspaceAutocompleteOff class="textareaDiv borderRadius height21" matInput
                                placeholder="" formControlName="postSessionLearningMaterialTextBox" />
                            </div>
                          </div>
                          <div ngClass="fieldLabel selfBox"></div>
                          <div ngClass="fieldLabel selfBox"></div>
                          <div *ngIf="postSessionSelected">
                            <div class="fieldLabel">
                              <label>File containing Learning Material
                                <span class="mandatory-field-indicator" *ngIf="postSessionSelected">*</span>
                              </label>
                            </div>
                            <div>
                              <input class="width220px" type="file" id="PostSessionFileUpload" YorbitWorkspaceFileModel
                                formControlName="postSessionLearningMaterialFileBox" [modifyDOMElements]="false"
                                [inputFor]="'postSessionLearningMaterialFileBox'"
                                (fileModel)="fileModel('learningmaterial', 'Post', 1,$event)" (change)="showorHideButton(PostSessionFilebtn);showErrorMsgForFileUpload(
                                  'learningmaterial', 'Post', 1
                                )" />
                            </div>
                            <div class="upload-info-msg"
                              [ngClass]="{'error-msg':fileUploadInvalid['learningmaterial']==undefined?false:(fileUploadInvalid['learningmaterial']['Post']==undefined?false:(fileUploadInvalid['learningmaterial']['Post'][1]==undefined?false:fileUploadInvalid['learningmaterial']['Post'][1]))}">
                              {{errorMsgs.file_upload_err + ' 25MB.'}}</div>

                            <button mat-raised-button #PostSessionlinkbtn id="PostSessionlinkbtn" class="anchorButton"
                              (click)="downloadFile('learningmaterial', 'Post', 1)" type="button"
                              *ngIf="contentForm.get('postSessionGroup.postSessionLearningMaterialLink').value !=null && contentForm.get('postSessionGroup.postSessionLearningMaterialLink').value !=''">
                              Download File
                            </button>
                            <div class="fileNameLabel"></div>
                            <button mat-raised-button #PostSessionFilebtn id="PostSessionFilebtn"
                              class="form-action-btn actionButton"
                              (click)="uploadFile('learningmaterial', 'Post', 1,$event)"
                              [ngClass]="{disabled:(fileUploadInvalid['learningmaterial']==undefined?false:(fileUploadInvalid['learningmaterial']['Post']==undefined?false:(fileUploadInvalid['learningmaterial']['Post'][1]==undefined?false:fileUploadInvalid['learningmaterial']['Post'][1])))||(eUploadFile['learningmaterial']==undefined?true:(eUploadFile['learningmaterial']['Post'] ==undefined?true:(eUploadFile['learningmaterial']['Post'][1]==undefined?true:false)))}"
                              [disabled]="(fileUploadInvalid['learningmaterial']==undefined?false:(fileUploadInvalid['learningmaterial']['Post']==undefined?false:(fileUploadInvalid['learningmaterial']['Post'][1]==undefined?false:fileUploadInvalid['learningmaterial']['Post'][1])))||(eUploadFile['learningmaterial']==undefined?true:(eUploadFile['learningmaterial']['Post'] ==undefined?true:(eUploadFile['learningmaterial']['Post'][1]==undefined?true:false)))"
                              type="button">Upload</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </mat-expansion-panel>
                <!--from -->
                <mat-expansion-panel class="panelMargin">
                  <mat-expansion-panel-header [ngClass]="{
                  panelBackground:
                    contentForm.get('isCourseProcedureReferenceDocument').value == 'Yes'
                }">
                    <mat-panel-title>
                      <div class="panelExpand" style="padding-right:33px">
                        Course Procedure Reference Material
                        <div *ngIf="!contentForm.valid && !isCourseProcedureReferenceDocumentValid"
                          class="err-icon-holder" title="Click here to view the detailed error.">
                          <div class="yorbitSprite nonCompliantIcon mousePointer" (click)="openErrPopup(2,$event)">
                          </div>
                        </div>
                      </div>
                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                        <label class="paddingRight20">
                          <input class="verticalAlign" class="radio" type="radio" value="{{ radio }}"
                            formControlName="isCourseProcedureReferenceDocument" />{{ radio }}
                        </label>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="paddingRight100 learning-mat" formGroupName="courseProcedureRefDocGroup"
                    *ngIf="contentForm.get('isCourseProcedureReferenceDocument').value == 'Yes'">
                    <div ngClass="fieldLabel title">
                      <label>Course Procedure Reference Document</label>
                    </div>
                    <div class="content">
                      <div>
                      </div>
                      <div ngClass="fieldLabel selfBox"></div>
                      <div ngClass="fieldLabel selfBox"></div>
                      <div>
                        <div>
                          <input class="width220px" type="file" id="CourseProcedureRefFileUpload"
                            YorbitWorkspaceFileModel formControlName="courseProcedureRefDocFileBox"
                            [modifyDOMElements]="false" [inputFor]="'courseProcedureRefDocFileBox'"
                            (fileModel)="fileModel('CourseProcedureReferenceDocument', '', 1,$event)" (change)="showorHideButton(ReferenceDocbtn);showErrorMsgForFileUpload(
                                'CourseProcedureReferenceDocument', '', 1
                              )" accept=".doc,.docx">
                        </div>
                        <div class="upload-info-msg"
                          [ngClass]="{'error-msg':fileUploadInvalid['CourseProcedureReferenceDocument']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['Post']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['Post'][1]==undefined?false:fileUploadInvalid['CourseProcedureReferenceDocument']['Post'][1]))}">
                          {{errorMsgs.course_ref_doc_err+ ' 25MB.'}}</div>
                        <div class="fileNameLabel"></div>
                        <button mat-raised-button id="courseRefDoclinkbtn" class="anchorButton"
                          (click)="openDownloadFileWithSasKey(courseRefLink)" type="button"
                          *ngIf="courseRefLink!=null && courseRefLink!=''">
                          Download File
                        </button>
                        <br *ngIf="courseRefLink!=null && courseRefLink!=''" />
                        <button mat-raised-button #ReferenceDocbtn id="ReferenceDocbtn"
                          class="form-action-btn actionButton"
                          [ngClass]="{disabled:(fileUploadInvalid['CourseProcedureReferenceDocument']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument'][''][1]==undefined?false:fileUploadInvalid['CourseProcedureReferenceDocument'][''][1])))||(eUploadFile['CourseProcedureReferenceDocument']==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''] ==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''][1]==undefined?true:false)))}"
                          [disabled]="(fileUploadInvalid['CourseProcedureReferenceDocument']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument'][''][1]==undefined?false:fileUploadInvalid['CourseProcedureReferenceDocument'][''][1])))||(eUploadFile['CourseProcedureReferenceDocument']==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''] ==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''][1]==undefined?true:false)))"
                          (click)="uploadFile('CourseProcedureReferenceDocument', '', 1,$event)"
                          type="button">Upload</button>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
                <!--till -->


                <div style="padding:20px;font-weight:bold">
                  Drag and change the sequence of the following items according to your preference.
                </div>
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                  <div class="draggable-box" *ngFor="let order of contentOrder; let i = index" cdkDrag
                    [cdkDragDisabled]="contentForm.get('type').value=='TF3SMEEvaluation'">
                    <div *ngIf="order == 'ProofForCourseCompletion'">
                      <div class="divDisplay draggbleWidth">
                        <div class="panelExpand">{{ i + 1 }}.</div>
                        <div class="panelWidth">
                          <mat-expansion-panel class="panelMargin">
                            <mat-expansion-panel-header [ngClass]="{
                            panelBackground:
                              contentForm.controls['courseCompletionGroup']
                                .controls['courseCompletionFormArray']
                                .controls[0].value == true ||
                              contentForm.controls['courseCompletionGroup']
                                .controls['courseCompletionFormArray']
                                .controls[1].value == true ||
                              contentForm.controls['courseCompletionGroup']
                                .controls['courseCompletionFormArray']
                                .controls[2].value == true ||
                              contentForm.controls['courseCompletionGroup']
                                .controls['courseCompletionFormArray']
                                .controls[3].value == true
                          }">
                              <mat-panel-title>
                                Proof of Course Completion to be Submitted by Learner/Evaluator
                                <div *ngIf="!contentForm.valid && !isProofFieldValid" class="err-icon-holder"
                                  title="Click here to view the detailed error.">
                                  <div class="yorbitSprite nonCompliantIcon mousePointer"
                                    (click)="openErrPopup(3,$event)"></div>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section>
                              <div class="divDisplay padding" formGroupName="courseCompletionGroup" ngControlDefault>
                                <div *ngFor="
                                let courseCompletion of contentForm.controls[
                                  'courseCompletionGroup'
                                ].controls['courseCompletionFormArray']
                                  .controls;
                                let i = index
                              ">
                                  <mat-checkbox class="paddingRight50" [formControl]="courseCompletion" color="primary"
                                    [disabled]="(contentForm.get('type').value=='TF3SMEEvaluation')||(contentForm.get('type').value!='TF3SMEEvaluation' && courseCompletionList[i].Description=='SME Evaluation Upload')
                                  ||
                                  (getAnyCompletionSelected() &&!courseCompletion.value)
                                  ">
                                    {{ courseCompletionList[i].Description }}
                                  </mat-checkbox>
                                  <div *ngFor="
                                  let courseCompletionTypeFormArray of contentForm
                                    .controls['courseCompletionGroup'].controls[
                                    'courseCompletionTypeFormArray'
                                  ].controls;
                                  let j = index
                                ">
                                    <div *ngIf="
                                    courseCompletionList[i].Id ==
                                      courseCompletionTypeList[j]
                                        .CourseCompletionId &&
                                        courseCompletion.value==true
                                  ">
                                      <div ngClass="fieldLabel">
                                        <label>{{
                                          courseCompletionTypeList[j]
                                          .TypeDescription
                                          }}
                                          <span *ngIf="courseCompletion.value==true &&
                                    (courseCompletionTypeList[j].Id != 3 &&
                                    courseCompletionTypeList[j].Id != 6 &&
                                    courseCompletionTypeList[j].Id != 9 &&
                                    courseCompletionTypeList[j].Id != 12 && 
                                    courseCompletionTypeList[j].Id != 13 )" class="mandatory-field-indicator">*</span>
                                        </label>
                                      </div>
                                      <div *ngIf="
                                      courseCompletionTypeList[j].Id != 3 &&
                                      courseCompletionTypeList[j].Id != 6 &&
                                      courseCompletionTypeList[j].Id != 9 &&
                                      courseCompletionTypeList[j].Id != 12 &&
                                      courseCompletionTypeList[j].Id != 13
                                    ">
                                        <input YorbitWorkspaceAutocompleteOff class="width230 borderRadius height21"
                                          matInput [formControl]="
                                        courseCompletionTypeFormArray" placeholder="{{(courseCompletionTypeList[j].Id == 1 ||
                                        courseCompletionTypeList[j].Id == 4 ||
                                        courseCompletionTypeList[j].Id == 7 ||
                                        courseCompletionTypeList[j].Id == 10) ? 'Only Numbers' : ''}}" />
                                      </div>

                                      <div *ngIf="
                                      courseCompletionTypeList[j].Id != 3 &&
                                      courseCompletionTypeList[j].Id != 6 &&
                                      courseCompletionTypeList[j].Id != 9 &&
                                      courseCompletionTypeList[j].Id != 12 &&
                                      courseCompletionTypeList[j].Id != 13" ngClass="fieldLabel selfBox"></div>

                                      <div class="textareaDiv" *ngIf="
                                      courseCompletionTypeList[j].Id == 3 ||
                                      courseCompletionTypeList[j].Id == 6 ||
                                      courseCompletionTypeList[j].Id == 9 ||
                                      courseCompletionTypeList[j].Id == 12 
                                    ">
                                        <input class="width220px" type="file" id="courseCompletionFileUpload_{{j}}"
                                          YorbitWorkspaceFileModel [formControl]="
                                        courseCompletionTypeFormArray
                                      " [modifyDOMElements]="false"
                                          [inputFor]="(j == 2 ? courseCompletionTypeFileBox[0] :(j == 5 ? courseCompletionTypeFileBox[1] : (j == 8 ? courseCompletionTypeFileBox[2] : (j == 11 ? courseCompletionTypeFileBox[3] : 'Upload'  ))) )"
                                          (fileModel)="fileModel('coursecompletiondetails',
                                      courseCompletionTypeList[j].TypeDescription,
                                      j,$event)" (change)="showorHideButton(courseCompletionFilebtn_j);showErrorMsgForFileUpload(
                                        'coursecompletiondetails',
                                        courseCompletionTypeList[j].TypeDescription,
                                        j
                                      )" />
                                        <div class="upload-info-msg"
                                          [ngClass]="{'error-msg':fileUploadInvalid['coursecompletiondetails']==undefined?false:(fileUploadInvalid['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription]==undefined?false:(fileUploadInvalid['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription][j]==undefined?false:fileUploadInvalid['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription][j]))}">
                                          {{errorMsgs.file_upload_err + ' 5MB.'}}</div>

                                        <button mat-raised-button id="courseCompletionLinkbtn_{{j}}"
                                          class="anchorButton"
                                          (click)="downloadFile('coursecompletiondetails', courseCompletionTypeList[j].TypeDescription, j )"
                                          type="button"
                                          *ngIf="(courseCompletionTypeList[j].TypeDescription=='Sample Certificate' && contentForm.get('certificateLink').value !=null && contentForm.get('certificateLink').value !='') ||
                                                           (courseCompletionTypeList[j].TypeDescription=='Sample Badge' && contentForm.get('badgeLink').value !=null && contentForm.get('badgeLink').value !='') ||
                                                           (courseCompletionTypeList[j].TypeDescription=='Sample Screenshot' && contentForm.get('sreenShotLink').value !=null && contentForm.get('sreenShotLink').value !='') ||
                                                           (courseCompletionTypeList[j].TypeDescription=='Sample Letter Head' && contentForm.get('letterHeadLink').value !=null && contentForm.get('letterHeadLink').value !='') ">
                                          Download File
                                        </button>
                                        <button mat-raised-button type="button" class="marginRight10 anchorButton"
                                          style="margin-left:20px"
                                          (click)="deleteFile('ProofOfCompletion', courseCompletionTypeList[j].TypeDescription, 0)"
                                          *ngIf="!isApproved && canDeleteSampleProof(courseCompletionTypeList[j].TypeDescription)">
                                          Delete File
                                        </button>
                                        <div *ngIf="canShowProofDelStatus(courseCompletionTypeList[j].TypeDescription)">
                                          Deleting...</div>
                                        <div class="fileNameLabel"></div>
                                        <button mat-raised-button #courseCompletionFilebtn_j
                                          id="courseCompletionFilebtn_{{j}}" class="form-action-btn actionButton"
                                          (click)="
                                        uploadFile(
                                          'coursecompletiondetails',
                                          courseCompletionTypeList[j].TypeDescription,
                                          j,$event
                                        )"
                                          [ngClass]="{disabled:(fileUploadInvalid['coursecompletiondetails']==undefined?false:(fileUploadInvalid['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription]==undefined?false:(fileUploadInvalid['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription][j]==undefined?false:fileUploadInvalid['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription][j])))||(eUploadFile['coursecompletiondetails']==undefined?true:(eUploadFile['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription] ==undefined?true:(eUploadFile['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription][j]==undefined?true:false)))}"
                                          [disabled]="(fileUploadInvalid['coursecompletiondetails']==undefined?false:(fileUploadInvalid['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription]==undefined?false:(fileUploadInvalid['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription][j]==undefined?false:fileUploadInvalid['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription][j])))||(eUploadFile['coursecompletiondetails']==undefined?true:(eUploadFile['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription] ==undefined?true:(eUploadFile['coursecompletiondetails'][courseCompletionTypeList[j].TypeDescription][j]==undefined?true:false)))"
                                          type="button" YorbitWorkspaceChangeLabel
                                          [label]="(j == 2 ? btnInputCourseCompletionTypeFileBox[0] :(j == 5 ? btnInputCourseCompletionTypeFileBox[1] : (j == 8 ? btnInputCourseCompletionTypeFileBox[2] : (j == 11 ? btnInputCourseCompletionTypeFileBox[3] : 'Upload'  ))) )">
                                          Upload
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </mat-expansion-panel>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="order == 'ClassRoom'">
                      <div class="divDisplay draggbleWidth">
                        <div class="panelExpand">{{ i + 1 }}.</div>
                        <div class="panelWidth">
                          <mat-expansion-panel class="panelMargin"
                            [disabled]="contentForm.get('type').value=='TF3SMEEvaluation'">
                            <mat-expansion-panel-header [ngClass]="{
                            panelBackground:
                              contentForm.get(
                                'classRoomGroup.isClassRoomRequired'
                              ).value == 'Yes'
                          }">
                              <mat-panel-title>
                                <div class="panelExpand" style="padding-right: 48px;">
                                  Class Room
                                  <div *ngIf="!contentForm.valid && !isClassroomFieldValid" class="err-icon-holder"
                                    title="Click here to view the detailed error.">
                                    <div class="yorbitSprite nonCompliantIcon mousePointer"
                                      (click)="openErrPopup(4,$event)"></div>
                                  </div>
                                </div>
                                <div formGroupName="classRoomGroup">
                                  <div class="divDisplay" *ngFor="let radio of yesNoRadio; let radioIndex = index">
                                    <label class="paddingRight20">
                                      <input class="verticalAlign" class="radio" type="radio" value="{{ radio }}"
                                        (change)="onClassroomRequiredChange(radioIndex)"
                                        formControlName="isClassRoomRequired"
                                        [attr.disabled]="(contentForm.get('type').value=='TF3SMEEvaluation')?'':null" />{{
                                      radio }}
                                    </label>
                                  </div>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section>
                              <div formGroupName="classRoomGroup">
                                <div *ngIf="
                                contentForm.get(
                                  'classRoomGroup.isClassRoomRequired'
                                ).value == 'Yes'
                              ">
                                  <div class="panelMargin">
                                    <div ngClass="fieldLabel">
                                      <label>Classroom Execution
                                        <span class="mandatory-field-indicator">*</span>
                                        <div class="info-icon"
                                          (click)="popInformationBits($event,'ClassroomExecution')">i</div>
                                      </label>
                                    </div>
                                    <div ngClass="fieldInput">
                                      <select class="textareaDiv borderRadius height25"
                                        formControlName="classroomExecution">
                                        <option value="" disabled selected>Please select a value</option>
                                        <option id="Managed by Vendor" value="1">Managed by Vendor</option>
                                        <option id="Managed by Learning OPM team" value="2">Managed by Learning OPM team
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div formArrayName="classRoom">
                                    <div *ngFor="
                                    let classRoom of contentForm.get(
                                      'classRoomGroup.classRoom'
                                    )['controls'];
                                    let i = index;let first=first;
                                  ">
                                      <div class="divDisplay">
                                        <div class="classroomDiv" [formGroupName]="i">
                                          <div class="divFloatRight">
                                            <button class="deleteButton deleteborderbutton" title="Delete"
                                              [disabled]="isApproved"
                                              (click)="openDeleteConfirmationPopup(i,'classRoom')" type="button" *ngIf=" contentForm.get(
                                              'classRoomGroup.classRoom'
                                            )['controls'].length > 1">
                                              <div class="yorbitSprite del-icon-grey-small mousePointer"
                                                [ngClass]="{'mouseDisabled':isApproved}"></div>
                                            </button>
                                          </div>
                                          <div class="divDisplay selfBox">
                                            <div class="paddingRight50">
                                              <div ngClass="fieldLabel">
                                                <label>Session No
                                                </label>
                                              </div>
                                              <input YorbitWorkspaceAutocompleteOff
                                                class="textareaDiv borderRadius height21 width400" matInput
                                                [readonly]="true" [value]="i+1" />
                                              <!-- formControlName="noOfClassRoomSessions" -->
                                            </div>
                                            <div class="paddingRight50">
                                              <div ngClass="fieldLabel">
                                                <label>Name of Class room Session
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                              </div>
                                              <input YorbitWorkspaceAutocompleteOff
                                                class="textareaDiv borderRadius height21 width400" matInput
                                                placeholder="" formControlName="nameOfClassRoomSessions" />
                                            </div>
                                          </div>
                                          <div class="divDisplay selfBox">
                                            <div class="paddingRight50">
                                              <div ngClass="fieldLabel">
                                                <label>Topics Covered
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                              </div>
                                              <input YorbitWorkspaceAutocompleteOff
                                                class="textareaDiv borderRadius height21 width400" matInput
                                                placeholder="" formControlName="classroomDescription" />
                                            </div>
                                            <!-- <div class="paddingRight50">
                                              <div ngClass="fieldLabel">
                                                <label>{{
                                                  contentForm.get(
                                                  'classRoomGroup.classroomExecution'
                                                  ).value == 1
                                                  ? 'Name of Vendor'
                                                  : 'Name of Faculty'
                                                  }}
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                              </div>
                                              <input YorbitWorkspaceAutocompleteOff
                                                class="textareaDiv borderRadius height21 width400" matInput
                                                placeholder="" formControlName="vendorOrFaultyName" />
                                            </div> -->
                                          </div>
                                          <div *ngIf="false" class="selfBox">
                                            <div class="selfBox">
                                              <div ngClass="fieldLabel">
                                                <label>Assignment?
                                                  <!--for="isAssignmentRequired_{{ i }}"-->
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                              </div>
                                              <div class="divDisplay">
                                                <input type="radio" id="isAssignmentRequired_{{ '1' }}"
                                                  [ngModel]="classroomAssignment[i]"
                                                  (ngModelChange)="onClassroomAssignmentChange(i)"
                                                  formControlName="isAssignmentRequired" value="Yes" class="radio" />
                                                <label class="paddingRight20">Yes</label>
                                                <input type="radio" id="isAssignmentRequired_{{ '2' }}"
                                                  [ngModel]="classroomAssignment[i]"
                                                  (ngModelChange)="onClassroomAssignmentChange(i)"
                                                  formControlName="isAssignmentRequired" value="No" class="radio" />
                                                <label class="paddingRight20">No</label>
                                              </div>
                                            </div>
                                            <div class="selfBox" *ngIf="
                                                this.contentForm.get(
                                                  'classRoomGroup.classRoom'
                                                )['controls'][i].controls
                                                  .isAssignmentRequired.value == 'Yes'
                                              ">
                                              <div class="divDisplay">
                                                <div class="paddingRight50">
                                                  <div ngClass="fieldLabel">
                                                    <label>Assignment Description
                                                      <span class="mandatory-field-indicator">*</span>
                                                    </label>
                                                  </div>
                                                  <input YorbitWorkspaceAutocompleteOff
                                                    class="textareaDiv borderRadius height21 width400" matInput
                                                    placeholder="" formControlName="assignmentDescription" />
                                                </div>
                                                <div class="">
                                                  <div ngClass="fieldLabel">
                                                    <label>Assignment Problem Statement
                                                      <span class="mandatory-field-indicator">*</span>
                                                    </label>
                                                  </div>
                                                  <div class="divDisplay" style="height:22px">
                                                    <input class="width220px" type="file"
                                                      id="AssignmentFileUpload_{{i}}" YorbitWorkspaceFileModel
                                                      formControlName="assignmentContent" [modifyDOMElements]="false"
                                                      [inputFor]="'assignmentContent'" (fileModel)="fileModel('classroomdetails',
                                                      'ProblemStatement',
                                                      i,$event)" (change)="showorHideButton(AssignmentContentFilebtn_i);showErrorMsgForFileUpload(
                                                        'classroomdetails',
                                                        'ProblemStatement',
                                                        i
                                                      )" />

                                                    <button mat-raised-button id="AssignmentContentLinkbtn_{{i}}"
                                                      class="marginRight10 anchorButton"
                                                      (click)="downloadFile('classroomdetails', 'ProblemStatement', i)"
                                                      type="button" *ngIf="this.contentForm.get(
                                                        'classRoomGroup.classRoom'
                                                      )['controls'][i].controls
                                                        .assignmentContentLink.value !=null && this.contentForm.get(
                                                          'classRoomGroup.classRoom'
                                                        )['controls'][i].controls
                                                          .assignmentContentLink.value !=''">
                                                      Download File
                                                    </button>
                                                    <button mat-raised-button #AssignmentContentFilebtn_i
                                                      id="AssignmentContentFilebtn_{{i}}"
                                                      class="form-action-btn actionButton" (click)="
                                                        uploadFile(
                                                          'classroomdetails',
                                                          'ProblemStatement',
                                                          i,$event
                                                        )"
                                                      [ngClass]="{disabled:(fileUploadInvalid['classroomdetails']==undefined?false:(fileUploadInvalid['classroomdetails']['ProblemStatement']==undefined?false:(fileUploadInvalid['classroomdetails']['ProblemStatement'][i]==undefined?false:fileUploadInvalid['classroomdetails']['ProblemStatement'][i])))||(eUploadFile['classroomdetails']==undefined?true:(eUploadFile['classroomdetails'][ 'ProblemStatement'] ==undefined?true:(eUploadFile['classroomdetails'][ 'ProblemStatement'][i]==undefined?true:false)))}"
                                                      [disabled]="(fileUploadInvalid['classroomdetails']==undefined?false:(fileUploadInvalid['classroomdetails']['ProblemStatement']==undefined?false:(fileUploadInvalid['classroomdetails']['ProblemStatement'][i]==undefined?false:fileUploadInvalid['classroomdetails']['ProblemStatement'][i])))||(eUploadFile['classroomdetails']==undefined?true:(eUploadFile['classroomdetails'][ 'ProblemStatement'] ==undefined?true:(eUploadFile['classroomdetails'][ 'ProblemStatement'][i]==undefined?true:false)))"
                                                      type="button">
                                                      Upload
                                                    </button>
                                                  </div>
                                                  <div class="upload-info-msg"
                                                    [ngClass]="{'error-msg':fileUploadInvalid['classroomdetails']==undefined?false:(fileUploadInvalid['classroomdetails']['ProblemStatement']==undefined?false:(fileUploadInvalid['classroomdetails']['ProblemStatement'][i]==undefined?false:fileUploadInvalid['classroomdetails']['ProblemStatement'][i]))}">
                                                    {{errorMsgs.file_upload_err + ' 5MB.'}}</div>
                                                </div>
                                              </div>
                                            </div>
                                            <div *ngIf="
                                                this.contentForm.get(
                                                  'classRoomGroup.classRoom'
                                                )['controls'][i].controls
                                                  .isAssignmentRequired.value == 'Yes'
                                              ">
                                              <div>
                                                <div class="divDisplay selfBox">
                                                  <label>Evaluation Required for
                                                    Assignment?
                                                    <span class="mandatory-field-indicator">*</span></label>
                                                  <br />
                                                  <div class="divDisplay"
                                                    *ngFor="let radio of yesNoRadio; let k = index">
                                                    <label class="paddingRight20">
                                                      <input class="radio" [disabled]="true" id="isEvaluationRequired{{
                                                          k
                                                        }}" type="radio" value="{{ radio }}"
                                                        formControlName="isEvaluationRequired" />{{ radio }}
                                                    </label>
                                                  </div>
                                                </div>

                                                <div class="selfBox" *ngIf="true &&
                                                    this.contentForm.get(
                                                      'classRoomGroup.classRoom'
                                                    )['controls'][i].controls
                                                      .isEvaluationRequired.value ==
                                                    'Yes'
                                                  ">

                                                  <!-- Evaluator -->
                                                  <div class="selfBox" style="display:inline-block">
                                                    <div ngClass="fieldLabel">
                                                      <label>Evaluator Type
                                                        <span class="mandatory-field-indicator">*</span>
                                                      </label>
                                                    </div>
                                                    <div class="divDisplay" *ngFor="let radio of internalExternalRadio">
                                                      <label class="paddingRight20">
                                                        <input class="radio" type="radio" value="{{ radio }}"
                                                          formControlName="evaluatorType"
                                                          (change)="internalExternalRadioChange('classroom',i)" />{{
                                                        radio }}
                                                      </label>
                                                    </div>
                                                  </div>

                                                  <!-- Internal Evaluator -->
                                                  <!-- EvaluatorNew -->
                                                  <div *ngIf="
                                                  this.contentForm.get(
                                                    'classRoomGroup.classRoom'
                                                  )['controls'][i].controls
                                                    .evaluatorType.value =='Internal'">
                                                    <yorbit-search-evaluator class="mousePointer" *ngIf="!isApproved"
                                                      [evaluatorDetails]="{'type':'classroom','list':this.contentForm.get('classRoomGroup.classRoom')['controls'][i].controls.evaluatorEmailID.value}"
                                                      (updateEvaluatorFromPopup)="updateEvaluatorFromPopup($event,'classroom',i)">
                                                    </yorbit-search-evaluator>

                                                    <div
                                                      *ngIf="null!=this.contentForm.get('classRoomGroup.classRoom')['controls'][i].controls.evaluatorName.value&&
                                                    ''!=this.contentForm.get('classRoomGroup.classRoom')['controls'][i].controls.evaluatorName.value">
                                                      <div class="paddingRight50" style="display:inline-block">
                                                        <div ngClass="fieldLabel selfBox">
                                                          <label class="alignLeft paddingRight10">Evaluator's Name :
                                                          </label>
                                                          <label
                                                            class="alignLeft">{{this.contentForm.get('classRoomGroup.classRoom')['controls'][i].controls.evaluatorName.value}}</label>
                                                        </div>

                                                        <div ngClass="fieldLabel selfBox">
                                                          <label class="alignLeft paddingRight10">Evaluator's Email ID :
                                                          </label>
                                                          <label
                                                            class="alignLeft">{{this.contentForm.get('classRoomGroup.classRoom')['controls'][i].controls.evaluatorEmailID.value}}</label>
                                                        </div>

                                                        <div ngClass="fieldLabel selfBox">
                                                          <label class="alignLeft paddingRight10">Evaluator's Vendor
                                                            Name : </label>
                                                          <label
                                                            class="alignLeft">{{this.contentForm.get('classRoomGroup.classRoom')['controls'][i].controls.evaluatorVendor.value}}</label>
                                                        </div>
                                                      </div>

                                                    </div>
                                                  </div>


                                                  <div *ngIf="
                                                  this.contentForm.get(
                                                    'classRoomGroup.classRoom'
                                                  )['controls'][i].controls
                                                    .evaluatorType.value =='External'">
                                                    <div class="paddingRight50" style="display:inline-block">
                                                      <div ngClass="fieldLabel selfBox">
                                                        <label>Evaluator's Name
                                                          <span class="mandatory-field-indicator">*</span>
                                                        </label>
                                                      </div>
                                                      <input YorbitWorkspaceAutocompleteOff
                                                        class="textareaDiv borderRadius height21 width400" matInput
                                                        placeholder="" formControlName="evaluatorName" />
                                                    </div>
                                                    <div class="paddingRight50" style="display:inline-block">
                                                      <div ngClass="fieldLabel selfBox">
                                                        <label>Evaluator's Email ID
                                                          <span class="mandatory-field-indicator">*</span>
                                                        </label>
                                                      </div>
                                                      <input YorbitWorkspaceAutocompleteOff
                                                        class="textareaDiv borderRadius height21 width400" matInput
                                                        placeholder="" formControlName="evaluatorEmailID" />
                                                    </div>
                                                  </div>

                                                </div>
                                                <div class="selfBox" *ngIf="
                                                    this.contentForm.get(
                                                      'classRoomGroup.classRoom'
                                                    )['controls'][i].controls
                                                      .isEvaluationRequired.value ==
                                                    'Yes'
                                                  ">

                                                  <div *ngIf="
                                                  this.contentForm.get(
                                                    'classRoomGroup.classRoom'
                                                  )['controls'][i].controls
                                                    .evaluatorType.value =='External'">
                                                    <div class="paddingRight50" style="display:inline-block">
                                                      <div ngClass="fieldLabel">
                                                        <label>Evaluator's Vendor
                                                          Name
                                                          <span class="mandatory-field-indicator">*</span></label>
                                                      </div>
                                                      <input YorbitWorkspaceAutocompleteOff
                                                        class="textareaDiv borderRadius height21 width400" matInput
                                                        placeholder="" formControlName="evaluatorVendor" />
                                                    </div>
                                                  </div>

                                                  <div class="selfBox" style="display:inline-block">
                                                    <div ngClass="fieldLabel">
                                                      <label>Location of Evaluator
                                                        <span class="mandatory-field-indicator">*</span>
                                                      </label>
                                                    </div>
                                                    <div class="divDisplay" *ngFor="
                                                        let radio of OnsiteOffsiteRadio
                                                      ">
                                                      <label class="paddingRight20">
                                                        <input class="radio" type="radio" value="{{ radio }}"
                                                          formControlName="locationofEvaluator" />{{ radio }}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="selfBox" *ngIf="
                                                    this.contentForm.get(
                                                      'classRoomGroup.classRoom'
                                                    )['controls'][i].controls
                                                      .isEvaluationRequired.value ==
                                                    'Yes'
                                                  ">
                                                  <div class="divDisplay" style="width:230px">
                                                    <div ngClass="fieldLabel">
                                                      <label>Evaluation Template for
                                                        Assignment
                                                        <span class="mandatory-field-indicator"
                                                          *ngIf="false">*</span></label>
                                                      <div>
                                                        <input class="width220px" type="file"
                                                          id="evaluationTemplateFileUpload_{{i}}"
                                                          YorbitWorkspaceFileModel formControlName="evaluationTemplate"
                                                          [modifyDOMElements]="false" [inputFor]="
                                                          inputForAssignmentEvaluationTemplate
                                                          " (fileModel)="
                                                            fileModel('classroomdetails',
                                                            'evaluator',
                                                            i,$event)
                                                          " (change)="showorHideButton(evaluationTemplateFilebtn_i);showErrorMsgForFileUpload(
                                                            'classroomdetails',
                                                            'evaluator',
                                                            i
                                                          )" />
                                                        <div class="upload-info-msg"
                                                          [ngClass]="{'error-msg':fileUploadInvalid['classroomdetails']==undefined?false:(fileUploadInvalid['classroomdetails']['evaluator']==undefined?false:(fileUploadInvalid['classroomdetails']['evaluator'][i]==undefined?false:fileUploadInvalid['classroomdetails']['evaluator'][i]))}">
                                                          {{errorMsgs.file_upload_err + ' 5MB.'}}</div>

                                                        <button mat-raised-button id="evaluationTemplatelinkbtn_{{i}}"
                                                          class=" anchorButton"
                                                          (click)="downloadFile('classroomdetails', 'evaluator', i)"
                                                          type="button" *ngIf="this.contentForm.get(
                                                            'classRoomGroup.classRoom'
                                                          )['controls'][i].controls
                                                            .evaluationTemplateLink.value !=null && this.contentForm.get(
                                                              'classRoomGroup.classRoom'
                                                            )['controls'][i].controls
                                                              .evaluationTemplateLink.value !=''">
                                                          Download File
                                                        </button>
                                                        <button mat-raised-button [ngStyle]="{'margin-left':'10px'}"
                                                          class="anchorButton"
                                                          (click)="deleteFile('ClassRoom', 'EvaluationTemplate', i)"
                                                          type="button" *ngIf="!isApproved && !classRoomEvalDeletion && this.contentForm.get(
                                                          'classRoomGroup.classRoom'
                                                        )['controls'][i].controls
                                                          .evaluationTemplateLink.value !=null && this.contentForm.get(
                                                            'classRoomGroup.classRoom'
                                                          )['controls'][i].controls
                                                            .evaluationTemplateLink.value !=''">
                                                          Delete File
                                                        </button>
                                                        <div *ngIf="classRoomEvalDeletion">Deleting...</div>
                                                        <div class="fileNameLabel"></div>
                                                        <div class="selfBox"></div>
                                                        <button mat-raised-button #evaluationTemplateFilebtn_i
                                                          id="evaluationTemplateFilebtn_{{i}}"
                                                          class="form-action-btn actionButton" (click)="
                                                            uploadFile(
                                                              'classroomdetails',
                                                              'evaluator',
                                                              i,$event
                                                            )"
                                                          [ngClass]="{disabled:(fileUploadInvalid['classroomdetails']==undefined?false:(fileUploadInvalid['classroomdetails']['evaluator']==undefined?false:(fileUploadInvalid['classroomdetails']['evaluator'][i]==undefined?false:fileUploadInvalid['classroomdetails']['evaluator'][i])))||(eUploadFile['classroomdetails']==undefined?true:(eUploadFile['classroomdetails'][ 'evaluator'] ==undefined?true:(eUploadFile['classroomdetails'][ 'evaluator'][i]==undefined?true:false)))}"
                                                          [disabled]="(fileUploadInvalid['classroomdetails']==undefined?false:(fileUploadInvalid['classroomdetails']['evaluator']==undefined?false:(fileUploadInvalid['classroomdetails']['evaluator'][i]==undefined?false:fileUploadInvalid['classroomdetails']['evaluator'][i])))||(eUploadFile['classroomdetails']==undefined?true:(eUploadFile['classroomdetails'][ 'evaluator'] ==undefined?true:(eUploadFile['classroomdetails'][ 'evaluator'][i]==undefined?true:false)))"
                                                          type="button" YorbitWorkspaceChangeLabel
                                                          [label]="btnInputAssignmentEvaluationTemplate">
                                                          Upload
                                                        </button>
                                                      </div>
                                                    </div>
                                                    <div class="paddingRight50">
                                                      <div ngClass="fieldLabel">
                                                        <label>Max Score
                                                          <span class="mandatory-field-indicator">*</span>
                                                        </label>
                                                      </div>
                                                      <input YorbitWorkspaceAutocompleteOff
                                                        class="textareaDiv borderRadius" matInput
                                                        [ngModel]="classroomMaxScoreValue[i]"
                                                        (ngModelChange)="ValidateMaxScore('ClassRoom',i)" placeholder=""
                                                        formControlName="maxScore" min="0" max="100"
                                                        style="width: 150px;" />
                                                      <div
                                                        *ngIf="classroomMaxScoreValidation[i] && classroomMaxScoreValidation[i].isInvalid"
                                                        class="error-msg">

                                                        {{classroomMaxScoreValidation[i].errMsg}}
                                                      </div>
                                                      <div
                                                        *ngIf="classroomMaxScoreValidation[i] && !classroomMaxScoreValidation[i].isInvalid"
                                                        class="score-validation-info-msg">
                                                        {{ maxScoreUpperLimit }} </div>
                                                      <!-- <div class="error-msg" *ngIf="!maxScore.valid && (maxScore.dirty || maxScore.touched)">{{maxScore.errors.errMsg}}
                                                        </div> -->
                                                    </div>
                                                    <div class="paddingRight50">
                                                      <div ngClass="fieldLabel">
                                                        <label>Passing Score
                                                          <span class="mandatory-field-indicator">*</span>
                                                        </label>
                                                      </div>
                                                      <input YorbitWorkspaceAutocompleteOff
                                                        class="textareaDiv borderRadius" matInput
                                                        [ngModel]="classroomPassingScoreValue[i]"
                                                        (ngModelChange)="ValidatePassingScore('ClassRoom',i)"
                                                        placeholder="" formControlName="passingScore" min="0" max="100"
                                                        style="width: 150px;" />
                                                      <div
                                                        *ngIf="classroomPassingScoreValidation[i] && classroomPassingScoreValidation[i].isInvalid"
                                                        class="error-msg">

                                                        {{classroomPassingScoreValidation[i].errMsg}}
                                                      </div>
                                                      <div
                                                        *ngIf="classroomPassingScoreValidation[i] && !classroomPassingScoreValidation[i].isInvalid"
                                                        class="score-validation-info-msg">
                                                        Passing score should be less than max score.
                                                      </div>
                                                      <!-- <div class="error-msg" *ngIf="!passingScore.valid && (passingScore.dirty || passingScore.touched)">{{passingScore.errors.errMsg}}</div> -->
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div style="display:none">
                                            <div>
                                              <div ngClass="fieldLabel selfBox">
                                                <label>Is Classroom Scheduled?
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                              </div>
                                              <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                                                <label class="paddingRight20">
                                                  <input class="radio verticalAlign" type="radio" value="{{ radio }}"
                                                    formControlName="isClassroomScheduled" />{{ radio }}
                                                </label>
                                              </div>
                                            </div>
                                            <div class="divDisplay selfBox" *ngIf="
                                                this.contentForm.get(
                                                  'classRoomGroup.classRoom'
                                                )['controls'][i].controls
                                                  .isClassroomScheduled.value == 'Yes'
                                              ">
                                              <div>
                                                <div ngClass="fieldLabel selfBox">
                                                  <label>Schedule for Each Classroom
                                                    Session
                                                    <span class="mandatory-field-indicator">*</span></label>
                                                </div>

                                                <div>
                                                  <mat-form-field>
                                                    <input matInput [matDatepicker]="picker" placeholder="Choose a date"
                                                      formControlName="classroomScheduledDateTime" />
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                  </mat-form-field>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button class="form-action-btn add-btn-pos" title="Add new class room"
                                    (click)="addClassRoom()" type="button" [disabled]="isApproved"
                                    [ngClass]="{'mouseDisabled':isApproved}">
                                    Add Class Room
                                  </button>
                                </div>
                              </div>
                            </section>
                          </mat-expansion-panel>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="order == 'ProjectWork'">
                      <div class="divDisplay draggbleWidth">
                        <div class="panelExpand">{{ i + 1 }}.</div>
                        <div class="panelWidth">
                          <mat-expansion-panel class="panelMargin"
                            [disabled]="contentForm.get('type').value=='TF3SMEEvaluation'">
                            <mat-expansion-panel-header [ngClass]="{
                            panelBackground:
                              contentForm.get('projectGroup.hasProjectWork')
                                .value == 'Yes'
                          }">
                              <mat-panel-title>
                                <div class="panelExpand" style="padding-right: 43px;">
                                  Project Work
                                  <div *ngIf="!contentForm.valid && !isProjectFieldValid" class="err-icon-holder"
                                    title="Click here to view the detailed error.">
                                    <div class="yorbitSprite nonCompliantIcon mousePointer"
                                      (click)="openErrPopup(5,$event)"></div>
                                  </div>
                                </div>
                                <div formGroupName="projectGroup">
                                  <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                                    <label class="paddingRight20">
                                      <input class="radio" type="radio" value="{{ radio }}"
                                        formControlName="hasProjectWork"
                                        [attr.disabled]="(contentForm.get('type').value=='TF3SMEEvaluation')?'':null" />{{
                                      radio }}
                                    </label>
                                  </div>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section [ngClass]="{
                            scroll:
                              contentForm.get('projectGroup.hasProjectWork') =='Yes'
                          }">
                              <div formGroupName="projectGroup">
                                <div *ngIf="
                                contentForm.get('projectGroup.hasProjectWork')
                                  .value == 'Yes'
                              ">
                                  <div class="paddingTop10">
                                    <div>
                                      <div ngClass="fieldLabel selfBox">
                                        <label>Jumble Project Problem Statement
                                          <span class="mandatory-field-indicator">*</span>
                                          <div class="info-icon" (click)="popInformationBits($event,'JumbleProject')">i
                                          </div>
                                        </label>
                                      </div>
                                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                                        <label class="paddingRight20">
                                          <input class="radio" type="radio" value="{{ radio }}"
                                            formControlName="hasJumbleProject" />{{ radio }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div formArrayName="projectWorkDetails">
                                      <div *ngFor="
                                      let project of contentForm.get(
                                        'projectGroup.projectWorkDetails'
                                      )['controls'];
                                      let i = index;let first=first;
                                    ">
                                        <div class="classroomDiv" [formGroupName]="i">
                                          <div class="divFloatRight">
                                            <button class="deleteButton deleteborderbutton" title="Delete"
                                              [disabled]="isApproved"
                                              (click)="openDeleteConfirmationPopup(i,'projectWork')" type="button"
                                              *ngIf="
                                                contentForm.get(
                                                  'projectGroup.projectWorkDetails'
                                                )['controls'].length > 1">
                                              <div class="yorbitSprite del-icon-grey-small mousePointer"
                                                [ngClass]="{'mouseDisabled':isApproved}"></div>
                                            </button>
                                          </div>
                                          <div class="divDisplay">
                                            <div class="paddingRight50">
                                              <div>
                                                <label>Project Description
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                              </div>
                                              <textarea YorbitWorkspaceAutocompleteOff
                                                class="textareaDiv borderRadius width400"
                                                style="height:19px;overflow-y:auto" placeholder="Enter the Description"
                                                formControlName="projectDescription"></textarea>
                                            </div>

                                            <div class="paddingRight50">
                                              <div ngClass="fieldLabel">
                                                <label>Max Score
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                              </div>
                                              <input YorbitWorkspaceAutocompleteOff class="textareaDiv borderRadius"
                                                matInput placeholder="" [ngModel]="projectMaxScoreValue[i]"
                                                (ngModelChange)="ValidateMaxScore('Project',i)"
                                                formControlName="maxScore" min="0" max="100" style="width: 120px;" />
                                              <div
                                                *ngIf="projectMaxScoreValidation[i] && projectMaxScoreValidation[i].isInvalid"
                                                class="error-msg">

                                                {{projectMaxScoreValidation[i].errMsg}}
                                              </div>
                                              <div
                                                *ngIf="projectMaxScoreValidation[i] && !projectMaxScoreValidation[i].isInvalid"
                                                class="score-validation-info-msg">
                                                {{ maxScoreUpperLimit }} </div>
                                            </div>
                                            <div class="paddingRight50">
                                              <div ngClass="fieldLabel">
                                                <label>Passing Score
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                              </div>
                                              <input YorbitWorkspaceAutocompleteOff class="textareaDiv borderRadius"
                                                matInput placeholder="" [ngModel]="projectPassingScoreValue[i]"
                                                (ngModelChange)="ValidatePassingScore('Project',i)"
                                                formControlName="passingScore" min="0" max="100"
                                                style="width: 120px;" />
                                              <div
                                                *ngIf="projectPassingScoreValidation[i] && projectPassingScoreValidation[i].isInvalid"
                                                class="error-msg">

                                                {{projectPassingScoreValidation[i].errMsg}}
                                              </div>
                                              <div
                                                *ngIf="projectPassingScoreValidation[i] && !projectPassingScoreValidation[i].isInvalid"
                                                class="score-validation-info-msg">
                                                Passing score should be less than max score.
                                              </div>
                                            </div>
                                            <div>
                                              <!-- <div>
                                                    <div ngClass="fieldLabel selfBox">
                                                      <label>Does Course have Demo?
                                                    <span class="mandatory-field-indicator">*</span>
                                                  </label>
                                                    </div>
                                                    <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                                                      <label class="paddingRight20">
                                                    <input  class="radio" type="radio" value="{{ radio }}"
                                                      formControlName="projectDemo" />{{ radio }}
                                                  </label>
                                                    </div>
                                                  </div> -->
                                            </div>
                                          </div>
                                          <div class="divDisplay">
                                            <div class="paddingRight40">
                                              <div ngClass="fieldLabel selfBox">
                                                <label>Does Course have Demo?
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                              </div>
                                              <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                                                <label class="paddingRight20">
                                                  <input class="radio" type="radio" value="{{ radio }}"
                                                    formControlName="projectDemo" />{{ radio }}
                                                </label>
                                              </div>
                                            </div>
                                            <div class="paddingRight50">
                                              <div class="divWidth">
                                                <div ngClass="fieldLabel selfBox">
                                                  <label>Project Reference Material
                                                  </label>
                                                </div>
                                                <div>
                                                  <input class="width220px" type="file" id="projectMaterialUpload_{{i}}"
                                                    YorbitWorkspaceFileModel formControlName="projectMaterial"
                                                    [modifyDOMElements]="false" [inputFor]="inputForProjectMaterial"
                                                    (fileModel)="fileModel(
                                                      'projectdetails',
                                                      'Material',
                                                      i,$event)" (change)="showorHideButton(projectMaterialFilebtn_i);showErrorMsgForFileUpload(
                                                        'projectdetails',
                                                        'Material',
                                                        i
                                                      )" />
                                                  <div class="upload-info-msg"
                                                    [ngClass]="{'error-msg':fileUploadInvalid['projectdetails']==undefined?false:(fileUploadInvalid['projectdetails']['Material']==undefined?false:(fileUploadInvalid['projectdetails']['Material'][i]==undefined?false:fileUploadInvalid['projectdetails']['Material'][i]))}">
                                                    {{errorMsgs.file_upload_err + ' 5MB.'}}</div>

                                                  <button mat-raised-button id="projectMaterialLinkbtn_{{i}}"
                                                    class=" anchorButton"
                                                    (click)="downloadFile('projectdetails', 'Material', i)"
                                                    type="button" *ngIf="this.contentForm.get(
                                                            'projectGroup.projectWorkDetails'
                                                          )['controls'][i].controls
                                                            .projectMaterialLink.value !=null && this.contentForm.get(
                                                              'projectGroup.projectWorkDetails'
                                                            )['controls'][i].controls
                                                              .projectMaterialLink.value !=''">
                                                    Download File
                                                  </button>
                                                  <button mat-raised-button [ngStyle]="{'margin-left':'10px'}"
                                                    class="anchorButton"
                                                    (click)="deleteFile('Project', 'ReferenceMaterial', i)"
                                                    type="button" *ngIf="!isApproved && !projectRefMaterialDeletion && this.contentForm.get(
                                                      'projectGroup.projectWorkDetails'
                                                    )['controls'][i].controls
                                                      .projectMaterialLink.value !=null && this.contentForm.get(
                                                        'projectGroup.projectWorkDetails'
                                                      )['controls'][i].controls
                                                        .projectMaterialLink.value !=''">
                                                    Delete File
                                                  </button>
                                                  <div *ngIf="projectRefMaterialDeletion">Deleting...</div>
                                                  <!-- <div class="selfBox"></div> -->
                                                  <div class="selfBox"></div>
                                                  <button mat-raised-button #projectMaterialFilebtn_i
                                                    id="projectMaterialFilebtn_{{i}}"
                                                    class="form-action-btn actionButton" (click)="
                                                  uploadFile(
                                                    'projectdetails',
                                                    'Material',
                                                    i,$event
                                                  )"
                                                    [ngClass]="{disabled:(fileUploadInvalid['projectdetails']==undefined?false:(fileUploadInvalid['projectdetails']['Material']==undefined?false:(fileUploadInvalid['projectdetails']['Material'][i]==undefined?false:fileUploadInvalid['projectdetails']['Material'][i])))||(eUploadFile['projectdetails']==undefined?true:(eUploadFile['projectdetails'][ 'Material'] ==undefined?true:(eUploadFile['projectdetails'][ 'Material'][i]==undefined?true:false)))}"
                                                    [disabled]="(fileUploadInvalid['projectdetails']==undefined?false:(fileUploadInvalid['projectdetails']['Material']==undefined?false:(fileUploadInvalid['projectdetails']['Material'][i]==undefined?false:fileUploadInvalid['projectdetails']['Material'][i])))||(eUploadFile['projectdetails']==undefined?true:(eUploadFile['projectdetails'][ 'Material'] ==undefined?true:(eUploadFile['projectdetails'][ 'Material'][i]==undefined?true:false)))"
                                                    type="button" YorbitWorkspaceChangeLabel
                                                    [label]="btnInputProjectMaterial">
                                                    Upload
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="paddingRight50">
                                              <div class="divWidth">
                                                <div ngClass="fieldLabel selfBox">
                                                  <label>Problem Statement
                                                    <span class="mandatory-field-indicator">*</span>
                                                  </label>
                                                </div>
                                                <input type="file" id="problemStatementFileUpload_{{i}}"
                                                  YorbitWorkspaceFileModel formControlName="problemStatement"
                                                  [inputFor]="'problemStatement'" [modifyDOMElements]="false"
                                                  (fileModel)="fileModel('projectdetails',
                                                  'ProblemStatement',
                                                  i,$event)" (change)="showorHideButton(problemStatementFilebtn_i);showErrorMsgForFileUpload(
                                                    'projectdetails',
                                                    'ProblemStatement',
                                                    i
                                                  )" />
                                                <div class="upload-info-msg"
                                                  [ngClass]="{'error-msg':fileUploadInvalid['projectdetails']==undefined?false:(fileUploadInvalid['projectdetails']['ProblemStatement']==undefined?false:(fileUploadInvalid['projectdetails']['ProblemStatement'][i]==undefined?false:fileUploadInvalid['projectdetails']['ProblemStatement'][i]))}">
                                                  {{errorMsgs.file_upload_err + ' 5MB.'}}</div>

                                                <button mat-raised-button id="problemStatementLinkbtn_{{i}}"
                                                  class=" anchorButton"
                                                  (click)="downloadFile('projectdetails', 'ProblemStatement', i)"
                                                  type="button" *ngIf="this.contentForm.get(
                                                            'projectGroup.projectWorkDetails'
                                                          )['controls'][i].controls
                                                            .problemStatementLink.value !=null && this.contentForm.get(
                                                              'projectGroup.projectWorkDetails'
                                                            )['controls'][i].controls
                                                              .problemStatementLink.value !=''">
                                                  Download File
                                                </button>
                                                <!-- <div class="selfBox"></div> -->
                                                <div class="selfBox"></div>
                                                <button mat-raised-button #problemStatementFilebtn_i
                                                  id="problemStatementFilebtn_{{i}}"
                                                  class="form-action-btn actionButton" (click)="
                                                uploadFile(
                                                  'projectdetails',
                                                  'ProblemStatement',
                                                  i,$event
                                                )"
                                                  [ngClass]="{disabled:(fileUploadInvalid['projectdetails']==undefined?false:(fileUploadInvalid['projectdetails']['ProblemStatement']==undefined?false:(fileUploadInvalid['projectdetails']['ProblemStatement'][i]==undefined?false:fileUploadInvalid['projectdetails']['ProblemStatement'][i])))||(eUploadFile['projectdetails']==undefined?true:(eUploadFile['projectdetails'][ 'ProblemStatement'] ==undefined?true:(eUploadFile['projectdetails'][ 'ProblemStatement'][i]==undefined?true:false)))}"
                                                  [disabled]="(fileUploadInvalid['projectdetails']==undefined?false:(fileUploadInvalid['projectdetails']['ProblemStatement']==undefined?false:(fileUploadInvalid['projectdetails']['ProblemStatement'][i]==undefined?false:fileUploadInvalid['projectdetails']['ProblemStatement'][i])))||(eUploadFile['projectdetails']==undefined?true:(eUploadFile['projectdetails'][ 'ProblemStatement'] ==undefined?true:(eUploadFile['projectdetails'][ 'ProblemStatement'][i]==undefined?true:false)))"
                                                  type="button">
                                                  Upload
                                                </button>
                                              </div>
                                            </div>
                                            <div class="divWidth">
                                              <div ngClass="fieldLabel selfBox">
                                                <label>Evaluation Template for
                                                  Project
                                                  <span class="mandatory-field-indicator" *ngIf="false">*</span></label>
                                              </div>
                                              <input type="file" id="projectEvaluationTemplateFileUpload_{{i}}"
                                                YorbitWorkspaceFileModel formControlName="evaluationTemplate"
                                                [modifyDOMElements]="false" [inputFor]="
                                                    inputForProjectEvaluationTemplateFileUpload
                                            " (fileModel)="fileModel('projectdetails',
                                            'evaluator',
                                            i,$event)" (change)="showorHideButton(projectEvaluationTemplateFileUploadFilebtn_i);showErrorMsgForFileUpload(
                                              'projectdetails',
                                              'evaluator',
                                              i
                                            )" />
                                              <div class="upload-info-msg"
                                                [ngClass]="{'error-msg':fileUploadInvalid['projectdetails']==undefined?false:(fileUploadInvalid['projectdetails']['evaluator']==undefined?false:(fileUploadInvalid['projectdetails']['evaluator'][i]==undefined?false:fileUploadInvalid['projectdetails']['evaluator'][i]))}">
                                                {{errorMsgs.file_upload_err + ' 5MB.'}}</div>

                                              <button mat-raised-button id="projectEvaluationTemplateLinkbtn_{{i}}"
                                                class=" anchorButton"
                                                (click)="downloadFile('projectdetails', 'evaluator', i)" type="button"
                                                *ngIf="this.contentForm.get(
                                              'projectGroup.projectWorkDetails'
                                            )['controls'][i].controls
                                              .evaluationTemplateLink.value !=null && this.contentForm.get(
                                                'projectGroup.projectWorkDetails'
                                              )['controls'][i].controls
                                                .evaluationTemplateLink.value !=''">
                                                Download File
                                              </button>
                                              <button mat-raised-button [ngStyle]="{'margin-left':'10px'}"
                                                class="anchorButton"
                                                (click)="deleteFile('Project', 'EvaluationTemplate', i)" type="button"
                                                *ngIf="!isApproved && !projectEvalDeletion && this.contentForm.get(
                                        'projectGroup.projectWorkDetails'
                                      )['controls'][i].controls
                                        .evaluationTemplateLink.value !=null && this.contentForm.get(
                                          'projectGroup.projectWorkDetails'
                                        )['controls'][i].controls
                                          .evaluationTemplateLink.value !=''">
                                                Delete File
                                              </button>
                                              <div *ngIf="projectEvalDeletion">Deleting...</div>
                                              <!-- <div class="selfBox"></div> -->
                                              <div class="selfBox"></div>
                                              <button mat-raised-button #projectEvaluationTemplateFileUploadFilebtn_i
                                                id="projectEvaluationTemplateFileUploadFilebtn_{{i}}"
                                                class="form-action-btn actionButton" (click)="
                                              uploadFile(
                                                'projectdetails',
                                                'evaluator',
                                                i,$event
                                              )"
                                                [ngClass]="{disabled:(fileUploadInvalid['projectdetails']==undefined?false:(fileUploadInvalid['projectdetails']['evaluator']==undefined?false:(fileUploadInvalid['projectdetails']['evaluator'][i]==undefined?false:fileUploadInvalid['projectdetails']['evaluator'][i])))||(eUploadFile['projectdetails']==undefined?true:(eUploadFile['projectdetails'][ 'evaluator'] ==undefined?true:(eUploadFile['projectdetails'][ 'evaluator'][i]==undefined?true:false)))}"
                                                [disabled]="(fileUploadInvalid['projectdetails']==undefined?false:(fileUploadInvalid['projectdetails']['evaluator']==undefined?false:(fileUploadInvalid['projectdetails']['evaluator'][i]==undefined?false:fileUploadInvalid['projectdetails']['evaluator'][i])))||(eUploadFile['projectdetails']==undefined?true:(eUploadFile['projectdetails'][ 'evaluator'] ==undefined?true:(eUploadFile['projectdetails'][ 'evaluator'][i]==undefined?true:false)))"
                                                type="button" YorbitWorkspaceChangeLabel
                                                [label]="btnInputProjectEvaluationTemplateFileUpload">
                                                Upload
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button class="form-action-btn" title="Add Project Details"
                                      (click)="addProjectWork()" type="button" *ngIf="
                                    contentForm.get(
                                      'projectGroup.hasJumbleProject'
                                    ).value == 'Yes'" [disabled]="isApproved" [ngClass]="{'mouseDisabled':isApproved}">
                                      Add Project Details
                                    </button>
                                  </div>
                                  <div>
                                    <div class="selfBox">
                                      <label>Evaluation Required for Project?
                                        <span class="mandatory-field-indicator">*</span>
                                      </label>
                                      <br />
                                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                                        <label class="paddingRight20">
                                          <input class="radio" type="radio" value="{{ radio }}"
                                            formControlName="hasProjectEvaluation" />{{ radio }}
                                        </label>
                                      </div>
                                    </div>
                                    <div formArrayName="projectEvaluationDetails">
                                      <div *ngFor="
                                      let project of contentForm.get(
                                        'projectGroup.projectEvaluationDetails'
                                      )['controls'];
                                      let i = index;let first=first;
                                    ">
                                        <div class="classroomDiv" [formGroupName]="i" *ngIf="
                                        contentForm.get(
                                          'projectGroup.hasProjectEvaluation'
                                        ).value == 'Yes'
                                      ">
                                          <div class="divFloatRight">
                                            <button class="deleteButton deleteborderbutton" title="Delete"
                                              [disabled]="isApproved"
                                              (click)="openDeleteConfirmationPopup(i,'projectEvaluation')" type="button"
                                              *ngIf="
                                              contentForm.get(
                                                'projectGroup.projectEvaluationDetails'
                                              )['controls'].length > 1">
                                              <div class="yorbitSprite del-icon-grey-small mousePointer"
                                                [ngClass]="{'mouseDisabled':isApproved}"></div>
                                            </button>
                                          </div>

                                          <div class="">


                                            <div class="divDisplay selfBox" *ngIf="
                                            contentForm.get(
                                              'projectGroup.hasProjectEvaluation'
                                            ).value == 'Yes'
                                          ">
                                              <div class="paddingRight50">
                                                <div ngClass="fieldLabel selfBox">
                                                  <label>Evaluator Type
                                                    <span class="mandatory-field-indicator">*</span>
                                                  </label>
                                                </div>
                                                <div class="divDisplay" *ngFor="
                                                let radio of internalExternalRadio
                                              ">
                                                  <label class="paddingRight10">
                                                    <input class="radio" type="radio" value="{{ radio }}"
                                                      (change)="internalExternalRadioChange('project',i)"
                                                      formControlName="evaluatorType" />{{ radio }}
                                                  </label>
                                                </div>
                                              </div>
                                              <div class="paddingRight50">
                                                <div ngClass="fieldLabel selfBox">
                                                  <label>Location of Evaluator
                                                    <span class="mandatory-field-indicator">*</span>
                                                  </label>
                                                </div>
                                                <div class="divDisplay" *ngFor="
                                                let radio of OnsiteOffsiteRadio
                                              ">
                                                  <label class="paddingRight10">
                                                    <input class="radio" type="radio" value="{{ radio }}"
                                                      formControlName="locationofEvaluator" />{{ radio }}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- Internal Evaluator -->
                                            <!-- EvaluatorNew -->
                                            <div *ngIf="
                                                  this.contentForm.get(
                                                    'projectGroup.projectEvaluationDetails'
                                                  )['controls'][i].controls
                                                    .evaluatorType.value =='Internal'">
                                              <yorbit-search-evaluator class="mousePointer" *ngIf="!isApproved"
                                                [evaluatorDetails]="{'type':'project','list':this.contentForm.get('projectGroup.projectEvaluationDetails')['controls'][i].controls.evaluatorEmailID.value}"
                                                (updateEvaluatorFromPopup)="updateEvaluatorFromPopup($event,'project',i)">
                                              </yorbit-search-evaluator>

                                              <div
                                                *ngIf="null!=this.contentForm.get('projectGroup.projectEvaluationDetails')['controls'][i].controls.evaluatorName.value&&
                                                    ''!=this.contentForm.get('projectGroup.projectEvaluationDetails')['controls'][i].controls.evaluatorName.value">
                                                <div class="paddingRight50" style="display:inline-block">
                                                  <div ngClass="fieldLabel selfBox">
                                                    <label class="alignLeft paddingRight10">Evaluator's Name :
                                                    </label>
                                                    <label
                                                      class="alignLeft">{{this.contentForm.get('projectGroup.projectEvaluationDetails')['controls'][i].controls.evaluatorName.value}}</label>
                                                  </div>

                                                  <div ngClass="fieldLabel selfBox">
                                                    <label class="alignLeft paddingRight10">Evaluator's Email ID :
                                                    </label>
                                                    <label
                                                      class="alignLeft">{{this.contentForm.get('projectGroup.projectEvaluationDetails')['controls'][i].controls.evaluatorEmailID.value}}</label>
                                                  </div>

                                                  <div ngClass="fieldLabel selfBox">
                                                    <label class="alignLeft paddingRight10">Evaluator's Vendor Name :
                                                    </label>
                                                    <label
                                                      class="alignLeft">{{this.contentForm.get('projectGroup.projectEvaluationDetails')['controls'][i].controls.evaluatorVendor.value}}</label>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>

                                            <!-- external Evaluator -->
                                            <div *ngIf="
                                            this.contentForm.get(
                                              'projectGroup.projectEvaluationDetails'
                                            )['controls'][i].controls
                                              .evaluatorType.value =='External'">
                                              <div class="divDisplay">
                                                <div class="paddingRight50">
                                                  <div ngClass="fieldLabel selfBox">
                                                    <label>Evaluator's Name
                                                      <span class="mandatory-field-indicator">*</span>
                                                    </label>
                                                  </div>
                                                  <input YorbitWorkspaceAutocompleteOff
                                                    class="textareaDiv borderRadius height21 width400" matInput
                                                    placeholder="" formControlName="evaluatorName" />
                                                </div>
                                                <div class="paddingRight50">
                                                  <div ngClass="fieldLabel selfBox">
                                                    <label>Evaluator's Email ID
                                                      <span class="mandatory-field-indicator">*</span>
                                                    </label>
                                                  </div>
                                                  <input YorbitWorkspaceAutocompleteOff
                                                    class="textareaDiv borderRadius height21 width400" matInput
                                                    placeholder="" formControlName="evaluatorEmailID" />
                                                </div>
                                              </div>
                                              <div class="divDisplay selfBox">
                                                <div class="paddingRight50">
                                                  <div ngClass="fieldLabel">
                                                    <label>Evaluator's Vendor
                                                      <span class="mandatory-field-indicator">*</span>
                                                    </label>
                                                  </div>
                                                  <input YorbitWorkspaceAutocompleteOff
                                                    class="textareaDiv borderRadius height21 width400" matInput
                                                    placeholder="" formControlName="evaluatorVendor" />
                                                </div>
                                              </div>

                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                    <button class="form-action-btn add-btn-pos" (click)="addProjectEvaluation()"
                                      type="button" *ngIf="
                                    contentForm.get(
                                      'projectGroup.hasProjectEvaluation'
                                    ).value == 'Yes'
                                  " title="{{isApproved?'Cannot edit approved course':(((this.contentForm.get('projectGroup.projectEvaluationDetails').value &&
                                  null !=
                                    this.contentForm.get('projectGroup.projectEvaluationDetails').value &&
                                  5<=
                                    this.contentForm.get('projectGroup.projectEvaluationDetails').value.length))?'Cannot add more than 5 Evaluator':'Add Project Evaluation Details')}}"
                                      [disabled]="isApproved||((this.contentForm.get('projectGroup.projectEvaluationDetails').value &&
                                  null !=
                                    this.contentForm.get('projectGroup.projectEvaluationDetails').value &&
                                  5<=
                                    this.contentForm.get('projectGroup.projectEvaluationDetails').value.length))"
                                      [ngClass]="{'mouseDisabled':isApproved||((this.contentForm.get('projectGroup.projectEvaluationDetails').value &&
                                    null !=
                                      this.contentForm.get('projectGroup.projectEvaluationDetails').value &&
                                    5<=
                                      this.contentForm.get('projectGroup.projectEvaluationDetails').value.length))}">
                                      Add Project Evaluation Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </mat-expansion-panel>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="order == 'Assessment'">
                      <div class="divDisplay draggbleWidth">
                        <div class="panelExpand">{{ i + 1 }}.</div>
                        <div class="panelWidth">
                          <mat-expansion-panel class="panelMargin"
                            [disabled]="contentForm.get('type').value=='TF3SMEEvaluation'">
                            <mat-expansion-panel-header [ngClass]="{
                            panelBackground:
                              contentForm.get('assessmentGroup.assessmentRadio')
                                .value == 'Yes'
                          }">
                              <mat-panel-title>
                                <div class="panelExpand" style="padding-right:47px;">
                                  Assessment
                                  <div *ngIf="!isAssessmentFieldValid" class="err-icon-holder"
                                    title="Click here to view the detailed error.">
                                    <div class="yorbitSprite nonCompliantIcon mousePointer"
                                      (click)="openErrPopup(6,$event)"></div>
                                  </div>
                                </div>
                                <div formGroupName="assessmentGroup">
                                  <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                                    <label class="paddingRight20">
                                      <input class="radio" type="radio" value="{{ radio }}"
                                        formControlName="assessmentRadio"
                                        [attr.disabled]="(contentForm.get('type').value=='TF3SMEEvaluation')?'':null" />{{
                                      radio }}
                                    </label>
                                  </div>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section>
                              <div formGroupName="assessmentGroup">
                                <div *ngIf="
                                contentForm.get(
                                  'assessmentGroup.assessmentRadio'
                                ).value == 'Yes'
                              ">
                                  <div>
                                    <div class="paddingRight50 paddingTop10">
                                      <div ngClass="fieldLabel selfBox">
                                        <label>Assessment Description
                                          <span class="mandatory-field-indicator">*</span>
                                        </label>
                                      </div>
                                      <textarea YorbitWorkspaceAutocompleteOff class="textareaAssessment borderRadius"
                                        placeholder="Enter the Description"
                                        formControlName="assessmentDescription"></textarea>
                                    </div>
                                    <div>
                                      <div class="fieldLabel selfBox">
                                        <label>Software Requirement for
                                          Assessment
                                          <span class="mandatory-field-indicator">*</span></label>
                                      </div>
                                      <div class="divDisplay">
                                        <div *ngFor="
                                        let assesmentInfrastructure of contentForm[
                                          'controls'
                                        ].assessmentGroup['controls']
                                          .assesmentInfrastructure['controls'];
                                        let i = index
                                      ">
                                          <mat-checkbox class="paddingRight50" color="primary"
                                            [formControl]="assesmentInfrastructure" (change)="
                                          onAssessmentInfrastructureChange(
                                            $event,
                                            i,
                                            assesmentInfrastructure
                                          )
                                        ">
                                            {{ courseInfrastructureList[i].Infrastructure }}
                                          </mat-checkbox>
                                          <div *ngFor="
                                          let assesmentInfrastructureType of contentForm[
                                            'controls'
                                          ].assessmentGroup['controls']
                                            .assesmentInfrastructureType[
                                            'controls'
                                          ];
                                          let j = index
                                        ">
                                            <div *ngIf="
                                            (courseInfrastructureList[i].Id ==
                                              1 ||
                                              courseInfrastructureList[i].Id ==
                                                2) &&
                                            courseInfrastructureList[i].Id ==
                                              courseInfrastructureTypeList[j]
                                                .CourseInfrastructureId &&
                                                assesmentInfrastructure.value==true
                                          ">
                                              <div ngClass="fieldLabel vendorBox">
                                                <label>{{
                                                  courseInfrastructureTypeList[j]
                                                  .InfrastructureType
                                                  }}
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                                <textarea class="width230 contentheight1 borderRadius" matInput
                                                  [formControl]="
                                                assesmentInfrastructureType
                                              "></textarea>
                                              </div>
                                            </div>
                                            <div *ngIf="
                                            (courseInfrastructureList[i].Id ==
                                              3 ||
                                              courseInfrastructureList[i].Id ==
                                                4) &&
                                            courseInfrastructureList[i].Id ==
                                              courseInfrastructureTypeList[j]
                                                .CourseInfrastructureId &&
                                                assesmentInfrastructure.value==true
                                          ">
                                              <div ngClass="fieldLabel vendorBox">
                                                <label>{{
                                                  courseInfrastructureTypeList[j]
                                                  .InfrastructureType
                                                  }}
                                                  <span class="mandatory-field-indicator">*</span>
                                                </label>
                                                <input class="width230 borderRadius height21" matInput [formControl]="
                                                assesmentInfrastructureType
                                              " />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="
                                contentForm.get(
                                  'assessmentGroup.assessmentRadio'
                                ).value == 'Yes'
                              ">
                                  <div class="">
                                    <div ngClass="fieldLabel selfBox">
                                      <label>Assessment Type
                                        <span class="mandatory-field-indicator">*</span>
                                      </label>
                                    </div>
                                    <div ngClass="fieldInput">
                                      <select class="textareaDiv borderRadius height21"
                                        formControlName="assessmentType">
                                        <option value="" disabled selected>Please select a value</option>
                                        <option value="Offline">Offline</option>
                                        <option value="Online">Online</option>
                                        <option value="Both">Both</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div *ngIf="
                                  contentForm.get(
                                    'assessmentGroup.assessmentType'
                                  ).value == 'Offline' ||
                                  contentForm.get(
                                    'assessmentGroup.assessmentType'
                                  ).value == 'Both'
                                ">
                                    <div class="divDisplay selfBox">
                                      <div class="width360">
                                        <div ngClass="fieldLabel selfBox">
                                          <label>Question Paper
                                            <span class="mandatory-field-indicator">*</span>
                                          </label>
                                        </div>
                                        <div ngClass="fieldInput">
                                          <input class="width220px" type="file" id="questionPaperFileUpload"
                                            YorbitWorkspaceFileModel formControlName="questionPaper"
                                            [modifyDOMElements]="false" [inputFor]="'questionPaper'" (fileModel)="fileModel('assessmentdetails',
                                            'OfflineProblemStatement',
                                            0,$event)" (change)="showorHideButton(questionPaperFilebtn);showErrorMsgForFileUpload(
                                              'assessmentdetails',
                                              'OfflineProblemStatement',
                                              0
                                            )" />

                                          <button mat-raised-button id="assesmentEvaluationTemplateLinkbtn"
                                            class="marginRight10 anchorButton"
                                            (click)="downloadFile('assessmentdetails', 'OfflineProblemStatement', 0)"
                                            type="button" *ngIf="this.contentForm.get(
                                              'assessmentGroup.questionPaperLink'
                                            ).value !=null && this.contentForm.get(
                                                'assessmentGroup.questionPaperLink'
                                              ).value !=''">
                                            Download File
                                          </button>
                                          <div class="upload-info-msg"
                                            [ngClass]="{'error-msg':fileUploadInvalid['assessmentdetails']==undefined?false:(fileUploadInvalid['assessmentdetails']['OfflineProblemStatement']==undefined?false:(fileUploadInvalid['assessmentdetails']['OfflineProblemStatement'][0]==undefined?false:fileUploadInvalid['assessmentdetails']['OfflineProblemStatement'][0]))}">
                                            {{errorMsgs.file_upload_err + ' 5MB.'}}</div>

                                          <div class="selfBox"></div>
                                          <button mat-raised-button #questionPaperFilebtn id="questionPaperFilebtn"
                                            class="form-action-btn actionButton paddingRight50" (click)="
                                          uploadFile(
                                            'assessmentdetails',
                                            'OfflineProblemStatement',
                                            0,$event
                                          )"
                                            [ngClass]="{disabled:(fileUploadInvalid['assessmentdetails']==undefined?false:(fileUploadInvalid['assessmentdetails']['OfflineProblemStatement']==undefined?false:(fileUploadInvalid['assessmentdetails']['OfflineProblemStatement'][0]==undefined?false:fileUploadInvalid['assessmentdetails']['OfflineProblemStatement'][0])))||(eUploadFile['assessmentdetails']==undefined?true:(eUploadFile['assessmentdetails'][ 'OfflineProblemStatement'] ==undefined?true:(eUploadFile['assessmentdetails'][ 'OfflineProblemStatement'][0]==undefined?true:false)))}"
                                            [disabled]="(fileUploadInvalid['assessmentdetails']==undefined?false:(fileUploadInvalid['assessmentdetails']['OfflineProblemStatement']==undefined?false:(fileUploadInvalid['assessmentdetails']['OfflineProblemStatement'][0]==undefined?false:fileUploadInvalid['assessmentdetails']['OfflineProblemStatement'][0])))||(eUploadFile['assessmentdetails']==undefined?true:(eUploadFile['assessmentdetails'][ 'OfflineProblemStatement'] ==undefined?true:(eUploadFile['assessmentdetails'][ 'OfflineProblemStatement'][0]==undefined?true:false)))"
                                            type="button">
                                            Upload
                                          </button>
                                        </div>
                                      </div>
                                      <div class="paddingRight50">
                                        <div ngClass="fieldLabel selfBox">
                                          <label>Max Score
                                            <span class="mandatory-field-indicator">*</span>
                                          </label>
                                        </div>
                                        <input YorbitWorkspaceAutocompleteOff class="textareaDiv borderRadius" matInput
                                          placeholder="" [ngModel]="assessmentMaxScoreValue"
                                          (ngModelChange)="ValidateMaxScore('Assessment',null)"
                                          formControlName="maxScore" min="0" max="100" style="width: 120px;" />
                                        <div
                                          *ngIf="assessmentMaxScoreValidation && assessmentMaxScoreValidation.isInvalid"
                                          class="error-msg">
                                          {{assessmentMaxScoreValidation.errMsg}}
                                        </div>
                                        <div
                                          *ngIf="assessmentMaxScoreValidation && !assessmentMaxScoreValidation.isInvalid"
                                          class="score-validation-info-msg">
                                          {{ maxScoreUpperLimit }} </div>
                                      </div>
                                      <div class="paddingRight50">
                                        <div ngClass="fieldLabel selfBox">
                                          <label>Passing Score
                                            <span class="mandatory-field-indicator">*</span>
                                          </label>
                                        </div>
                                        <input YorbitWorkspaceAutocompleteOff class="textareaDiv borderRadius height21"
                                          matInput placeholder="" [ngModel]="assessmentPassingScoreValue"
                                          (ngModelChange)="ValidatePassingScore('Assessment',null)"
                                          formControlName="passingScore" style="width: 120px;" />
                                        <div
                                          *ngIf="assessmentPassingScoreValidation && assessmentPassingScoreValidation.isInvalid"
                                          class="error-msg">
                                          {{assessmentPassingScoreValidation.errMsg}}
                                        </div>
                                        <div
                                          *ngIf="assessmentPassingScoreValidation && !assessmentPassingScoreValidation.isInvalid"
                                          class="score-validation-info-msg">
                                          Passing score should be less than max score.
                                        </div>
                                      </div>
                                      <!--
                                      <div class="paddingRight50">
                                        <div ngClass="fieldLabel selfBox">
                                          <label>Evaluator's Name
                                        <span class="mandatory-field-indicator">*</span>
                                      </label>
                                        </div>
                                        <input  class="textareaDiv borderRadius height21 divWidth" matInput placeholder="" formControlName="evaluatorName" />
                                      </div>
                                    -->

                                    </div>
                                    <div class="divDisplay">
                                      <div class="width360">
                                        <div ngClass="fieldLabel selfBox">
                                          <label>Evaluation Template
                                            <span class="mandatory-field-indicator" *ngIf="false">*</span>
                                          </label>
                                        </div>
                                        <div ngClass="fieldInput">
                                          <input class="width220px" type="file"
                                            id="assessmentevaluationTemplateFileUpload" YorbitWorkspaceFileModel
                                            formControlName="evaluationTemplate" [modifyDOMElements]="false"
                                            [inputFor]="inputForAssessmentEvaluationTemplate" (fileModel)="fileModel('assessmentdetails',
                                            'evaluator',
                                            0,$event)" (change)="showorHideButton(assessmentevaluationTemplateFilebtn);showErrorMsgForFileUpload(
                                              'assessmentdetails',
                                              'evaluator',
                                              0
                                            )" />

                                          <button mat-raised-button id="assessmentevaluationTemplateLinkbtn"
                                            class="marginRight10 anchorButton"
                                            (click)="downloadFile('assessmentdetails', 'evaluator', 0)" type="button"
                                            *ngIf="this.contentForm.get(
                                              'assessmentGroup.evaluationTemplateLink'
                                            ).value !=null && this.contentForm.get(
                                                'assessmentGroup.evaluationTemplateLink'
                                              ).value !=''">
                                            Download File
                                          </button>
                                          <button mat-raised-button class="marginRight10 anchorButton"
                                            (click)="deleteFile('Assessment', 'EvaluationTemplate', 0)" type="button"
                                            *ngIf="!isApproved && !assessmentEvalDeletion && this.contentForm.get(
                                        'assessmentGroup.evaluationTemplateLink'
                                      ).value !=null && this.contentForm.get(
                                          'assessmentGroup.evaluationTemplateLink'
                                        ).value !=''">
                                            Delete File
                                          </button>
                                          <div *ngIf="assessmentEvalDeletion">Deleting...</div>
                                          <div class="upload-info-msg"
                                            [ngClass]="{'error-msg':fileUploadInvalid['assessmentdetails']==undefined?false:(fileUploadInvalid['assessmentdetails']['evaluator']==undefined?false:(fileUploadInvalid['assessmentdetails']['evaluator'][0]==undefined?false:fileUploadInvalid['assessmentdetails']['evaluator'][0]))}">
                                            {{errorMsgs.file_upload_err + ' 5MB.'}}</div>

                                          <div class="selfBox"></div>
                                          <button mat-raised-button #assessmentevaluationTemplateFilebtn
                                            id="assessmentevaluationTemplateFilebtn"
                                            class="form-action-btn actionButton paddingRight50" (click)="
                                          uploadFile(
                                            'assessmentdetails',
                                            'evaluator',
                                            0,$event
                                          )"
                                            [ngClass]="{disabled:(fileUploadInvalid['assessmentdetails']==undefined?false:(fileUploadInvalid['assessmentdetails']['evaluator']==undefined?false:(fileUploadInvalid['assessmentdetails']['evaluator'][0]==undefined?false:fileUploadInvalid['assessmentdetails']['evaluator'][0])))||(eUploadFile['assessmentdetails']==undefined?true:(eUploadFile['assessmentdetails'][ 'evaluator'] ==undefined?true:(eUploadFile['assessmentdetails'][ 'evaluator'][0]==undefined?true:false)))}"
                                            [disabled]="(fileUploadInvalid['assessmentdetails']==undefined?false:(fileUploadInvalid['assessmentdetails']['evaluator']==undefined?false:(fileUploadInvalid['assessmentdetails']['evaluator'][0]==undefined?false:fileUploadInvalid['assessmentdetails']['evaluator'][0])))||(eUploadFile['assessmentdetails']==undefined?true:(eUploadFile['assessmentdetails'][ 'evaluator'] ==undefined?true:(eUploadFile['assessmentdetails'][ 'evaluator'][0]==undefined?true:false)))"
                                            type="button" YorbitWorkspaceChangeLabel
                                            [label]="btnInputAssessmentEvaluationTemplate">
                                            Upload
                                          </button>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                  <!-- Assessment Evaluator starts-->
                                  <div class="divDisplay">
                                    <div class="">
                                      <div class="paddingRight50 width360 vert-spacing" *ngIf="
                                      contentForm.get(
                                        'assessmentGroup.assessmentType'
                                      ).value == 'Offline' ||
                                      contentForm.get(
                                        'assessmentGroup.assessmentType'
                                      ).value == 'Both'
                                    ">
                                        <div ngClass="fieldLabel selfBox">
                                          <label>Evaluator Type
                                            <span class="mandatory-field-indicator">*</span>
                                          </label>
                                        </div>
                                        <div class="divDisplay" *ngFor="
                                      let radio of internalExternalRadio
                                    ">
                                          <label class="paddingRight10">
                                            <input class="radio" type="radio" value="{{radio}}"
                                              (change)="internalExternalRadioChange('assessment',0)"
                                              formControlName="evaluatorType" />{{ radio }}
                                          </label>
                                        </div>
                                      </div>

                                      <!-- Internal and External -->
                                      <!-- Internal -->
                                      <div
                                        *ngIf="'Internal'==this.contentForm.get('assessmentGroup.evaluatorType').value">
                                        <div class="width360" *ngIf="!isApproved">
                                          <yorbit-search-evaluator class="mousePointer"
                                            [evaluatorDetails]="{'type':'assessment','list':this.contentForm.get('assessmentGroup.evaluatorList').value}"
                                            (updateEvaluatorFromPopup)="updateEvaluatorFromPopup($event,'assessment',0)">
                                          </yorbit-search-evaluator>
                                        </div>

                                        <!-- assessment evaluator list-->

                                        <div *ngIf="null!=this.contentForm.get('assessmentGroup.evaluatorList').value&&
                                    ''!=this.contentForm.get('assessmentGroup.evaluatorList').value&&
                                    0<this.contentForm.get('assessmentGroup.evaluatorList').value.length">
                                          <div class="evaluatorList"
                                            *ngFor="let evaluatorList of this.contentForm.get('assessmentGroup.evaluatorList').value;let listIndex=index">
                                            <div class="evaluatorListObj" style="display:inline-block">
                                              <div class="divFloatRight">
                                                <button class="deleteButton deleteborderbutton assessmentDeleteButton"
                                                  title="Delete" [disabled]="isApproved"
                                                  (click)="openDeleteConfirmationPopup(listIndex, 'assessmentEvaluator')"
                                                  type="button">
                                                  <div class="yorbitSprite del-icon-grey-small mousePointer"
                                                    [ngClass]="{'mouseDisabled':isApproved}"></div>
                                                </button>
                                              </div>

                                              <div ngClass="fieldLabel selfBox">
                                                <label class="alignLeft paddingRight10">Evaluator's Name :
                                                </label><label class="alignLeft">{{evaluatorList.evaluatorName}}</label>
                                              </div>
                                              <br />

                                              <div ngClass="fieldLabel selfBox">
                                                <label class="alignLeft paddingRight10">Evaluator's Email ID :
                                                </label><label
                                                  class="alignLeft">{{evaluatorList.evaluatorEmail}}</label>
                                              </div>
                                              <br />

                                              <div ngClass="fieldLabel selfBox">
                                                <label class="alignLeft paddingRight10">Evaluator's Vendor Name :
                                                </label><label
                                                  class="alignLeft">{{evaluatorList.evaluatorVendor}}</label>
                                              </div>

                                              <div></div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>

                                      <!-- External -->
                                      <div
                                        *ngIf="'External'==this.contentForm.get('assessmentGroup.evaluatorType').value">
                                        <div formArrayName="evaluatorList">
                                          <div
                                            *ngFor="let evaluatorList of contentForm.get('assessmentGroup.evaluatorList')['controls']; let listIndex=index">
                                            <div [formGroupName]="[listIndex]" class="assessmentEvaluatorDiv">
                                              <div class="divFloatRight">
                                                <button class="deleteButton deleteborderbutton" title="Delete"
                                                  [disabled]="isApproved"
                                                  (click)="openDeleteConfirmationPopup(listIndex, 'assessmentEvaluator')"
                                                  type="button">
                                                  <div class="yorbitSprite del-icon-grey-small mousePointer"
                                                    [ngClass]="{'mouseDisabled':isApproved}"></div>
                                                </button>
                                              </div>
                                              <div class="divDisplay">
                                                <div class="paddingRight50">
                                                  <div ngClass="fieldLabel selfBox">
                                                    <label>Evaluator's Name
                                                      <span class="mandatory-field-indicator">*</span>
                                                    </label>
                                                  </div>
                                                  <input YorbitWorkspaceAutocompleteOff
                                                    class="textareaDiv borderRadius height21 width400" matInput
                                                    placeholder="" formControlName="evaluatorName" />
                                                </div>
                                                <div class="paddingRight50">
                                                  <div ngClass="fieldLabel selfBox">
                                                    <label>Evaluator's Email ID
                                                      <span class="mandatory-field-indicator">*</span>
                                                    </label>
                                                  </div>
                                                  <input YorbitWorkspaceAutocompleteOff
                                                    class="textareaDiv borderRadius height21 width400" matInput
                                                    formControlName="evaluatorEmail" placeholder="" />
                                                </div>
                                              </div>
                                              <div class="divDisplay selfBox">
                                                <div class="paddingRight50">
                                                  <div ngClass="fieldLabel">
                                                    <label>Evaluator's Vendor
                                                      <span class="mandatory-field-indicator">*</span>
                                                    </label>
                                                  </div>
                                                  <input YorbitWorkspaceAutocompleteOff
                                                    class="textareaDiv borderRadius height21 width400" matInput
                                                    placeholder="" formControlName="evaluatorVendor" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <button class="form-action-btn marginBlock add-btn-pos addEvaluatorButton"
                                          [ngClass]="{mouseDisabled:(this.contentForm.get('assessmentGroup.evaluatorList').value &&
                                        null !=
                                          this.contentForm.get('assessmentGroup.evaluatorList').value &&
                                        5<=
                                          this.contentForm.get('assessmentGroup.evaluatorList').value.length)}"
                                          [disabled]="(this.contentForm.get('assessmentGroup.evaluatorList').value &&
                                        null !=
                                          this.contentForm.get('assessmentGroup.evaluatorList').value &&
                                        5<=
                                          this.contentForm.get('assessmentGroup.evaluatorList').value.length)"
                                          (click)="addAssessmentEvaluation()"
                                          title="{{(this.contentForm.get('assessmentGroup.evaluatorList').value &&
                                          null !=
                                            this.contentForm.get('assessmentGroup.evaluatorList').value &&
                                          5<=
                                            this.contentForm.get('assessmentGroup.evaluatorList').value.length)?'Cannot add more than 5 Evaluator':''}}"
                                          type="button">
                                          Add Evaluator
                                        </button>
                                      </div>
                                      <!-- Assessment evaluator ends-->
                                    </div>
                                  </div>

                                  <div *ngIf="
                                  contentForm.get(
                                    'assessmentGroup.assessmentType'
                                  ).value == 'Online' ||
                                  contentForm.get(
                                    'assessmentGroup.assessmentType'
                                  ).value == 'Both'
                                ">
                                    <div class="divDisplay selfBox">
                                      <div class="">
                                        <div class="paddingRight50">
                                          <div ngClass="fieldLabel selfBox">
                                            <label>Tool Name
                                              <span class="mandatory-field-indicator">*</span>
                                            </label>
                                          </div>
                                          <input YorbitWorkspaceAutocompleteOff class="textareaDiv borderRadius"
                                            matInput placeholder="" formControlName="toolName" />
                                        </div>
                                      </div>
                                      <div class="width360">
                                        <div ngClass="fieldLabel selfBox">
                                          <label>Online Question Paper
                                            <!-- <span class="mandatory-field-indicator">*</span> -->
                                          </label>
                                        </div>
                                        <div ngClass="fieldInput">
                                          <input class="width220px" type="file" id="onlineQuestionPaper"
                                            YorbitWorkspaceFileModel formControlName="onlineQuestionPaper"
                                            [modifyDOMElements]="false" [inputFor]="inputForOnlineQuestionPaper"
                                            (fileModel)="fileModel( 'assessmentdetails',
                                            'OnlineProblemStatement',
                                            0,$event)" (change)="showorHideButton(onlineQuestionPaperFilebtn);showErrorMsgForFileUpload(
                                              'assessmentdetails',
                                              'OnlineProblemStatement',
                                              0
                                            )" />

                                          <button mat-raised-button id="assessmentevaluationTemplateLinkbtn"
                                            class="marginRight10 anchorButton"
                                            (click)="downloadFile('assessmentdetails', 'OnlineProblemStatement', 0)"
                                            type="button" *ngIf="this.contentForm.get(
                                          'assessmentGroup.onlineQuestionPaperLink'
                                        ).value !=null && this.contentForm.get(
                                            'assessmentGroup.onlineQuestionPaperLink'
                                          ).value !=''">
                                            Download File
                                          </button>
                                          <button mat-raised-button class="marginRight10 anchorButton"
                                            (click)="deleteFile('Assessment', 'OnlineQuestionPaper', 0)" type="button"
                                            *ngIf="!isApproved && !assessmentQPDeletion && this.contentForm.get(
                              'assessmentGroup.onlineQuestionPaperLink'
                            ).value !=null && this.contentForm.get(
                                'assessmentGroup.onlineQuestionPaperLink'
                              ).value !=''">
                                            Delete File
                                          </button>
                                          <div *ngIf="assessmentQPDeletion">Deleting...</div>
                                          <div class="upload-info-msg" [ngClass]="{'error-msg':fileUploadInvalid['assessmentdetails']==undefined?false:
                                        (fileUploadInvalid['assessmentdetails']['OnlineProblemStatement']==undefined?false:
                                          (fileUploadInvalid['assessmentdetails']['OnlineProblemStatement'][0]==undefined?false:
                                            fileUploadInvalid['assessmentdetails']['OnlineProblemStatement'][0]))}">
                                            {{errorMsgs.file_upload_err + ' 5MB.'}}
                                          </div>

                                          <div class="selfBox" *ngIf="this.contentForm.get(
                                        'assessmentGroup.onlineQuestionPaperLink'
                                      ).value !=null && this.contentForm.get(
                                          'assessmentGroup.onlineQuestionPaperLink'
                                        ).value !=''"></div>
                                          <button mat-raised-button #onlineQuestionPaperFilebtn
                                            id="onlineQuestionPaperFilebtn"
                                            class="form-action-btn actionButton paddingRight50" (click)="
                                          uploadFile(
                                            'assessmentdetails',
                                            'OnlineProblemStatement',
                                            0,$event
                                          )"
                                            [ngClass]="{disabled:(fileUploadInvalid['assessmentdetails']==undefined?false:(fileUploadInvalid['assessmentdetails']['OnlineProblemStatement']==undefined?false:(fileUploadInvalid['assessmentdetails']['OnlineProblemStatement'][0]==undefined?false:fileUploadInvalid['assessmentdetails']['OnlineProblemStatement'][0])))||(eUploadFile['assessmentdetails']==undefined?true:(eUploadFile['assessmentdetails']['OnlineProblemStatement'] ==undefined?true:(eUploadFile['assessmentdetails'][ 'OnlineProblemStatement'][0]==undefined?true:false)))}"
                                            [disabled]="(fileUploadInvalid['assessmentdetails']==undefined?false:(fileUploadInvalid['assessmentdetails']['OnlineProblemStatement']==undefined?false:(fileUploadInvalid['assessmentdetails']['OnlineProblemStatement'][0]==undefined?false:fileUploadInvalid['assessmentdetails']['OnlineProblemStatement'][0])))||(eUploadFile['assessmentdetails']==undefined?true:(eUploadFile['assessmentdetails']['OnlineProblemStatement'] ==undefined?true:(eUploadFile['assessmentdetails'][ 'OnlineProblemStatement'][0]==undefined?true:false)))"
                                            type="button" YorbitWorkspaceChangeLabel
                                            [label]="btnInputOnlineQuestionPaper">
                                            Upload
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style="display:none">
                                    <div class="">
                                      <div ngClass="fieldLabel selfBox">
                                        <label>Is Assessment Scheduled?
                                          <span class="mandatory-field-indicator">*</span>
                                        </label>
                                      </div>
                                      <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                                        <label class="paddingRight20">
                                          <input class="radio" type="radio" value="{{ radio }}"
                                            formControlName="isAssessmentScheduled" />{{ radio }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="divDisplay" *ngIf="
                                    this.contentForm.get(
                                      'assessmentGroup.isAssessmentScheduled'
                                    ).value == 'Yes'
                                  ">
                                      <div>
                                        <div ngClass="fieldLabel selfBox">
                                          <label>Schedule for Each Assessment
                                            <span class="mandatory-field-indicator">*</span>
                                          </label>
                                        </div>

                                        <div>
                                          <mat-form-field>
                                            <input matInput [matDatepicker]="picker" placeholder="Choose a date"
                                              formControlName="assessmentScheduledDate" />
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                          </mat-form-field>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </mat-expansion-panel>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="order == 'Quiz'">
                      <div class="divDisplay draggbleWidth">
                        <div class="panelExpand">{{ i + 1 }}.</div>
                        <div class="panelWidth">
                          <mat-expansion-panel class="panelMargin"
                            [disabled]="contentForm.get('type').value=='TF3SMEEvaluation'">
                            <mat-expansion-panel-header [ngClass]="{
                            panelBackground:
                              contentForm.get('assessmentGroup.assessmentRadio')
                                .value == 'Yes'
                          }">
                              <mat-panel-title>
                                <div class="panelExpand" style="padding-right:103px;">
                                  Quiz
                                  <div *ngIf="!isAssessmentFieldValid" class="err-icon-holder"
                                    title="Click here to view the detailed error.">
                                    <div class="yorbitSprite nonCompliantIcon mousePointer"
                                      (click)="openErrPopup(6,$event)"></div>
                                  </div>
                                </div>
                                <div formGroupName="quizGroup">
                                  <div class="divDisplay" *ngFor="let radio of yesNoRadio">
                                    <label class="paddingRight20">
                                      <input class="radio" type="radio" value="{{ radio }}" formControlName="quizRadio"
                                        [attr.disabled]="(contentForm.get('type').value=='TF3SMEEvaluation')?'':null" />{{
                                      radio }}
                                    </label>
                                  </div>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section>
                              <div formGroupName="quizGroup">
                                <div *ngIf="
                                contentForm.get(
                                  'quizGroup.quizRadio'
                                ).value == 'Yes'
                              ">
                                  <yorbit-quiz-form101 [course]="courseFor201Quiz"
                                    (enableContentVerificationComponent)="exitFromContentUploadForm($event)"
                                    (enable101ContentUploadComponent)="enable101ContentUpload($event)"
                                    (enableUnitsComponent)="enableUnitsAndTopics($event)">
                                  </yorbit-quiz-form101>
                                </div>
                              </div>
                            </section>
                          </mat-expansion-panel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-accordion>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Submit buttons-->
    <div fxLayout="row">
      <div fxFlex="40" fxLayout="row" class="divDisplay">
        <div fxFlex="30" (click)="checkBeforeExit()" class="back-btn mousePointer" *ngIf="isEdit"
          title="{{'Back to Saved Courses'}}">
          Exit Form
        </div>
        <div fxFlex="30" (click)="checkBeforeExit()" class="back-btn mousePointer" *ngIf="!isEdit"
          title="{{'Back to Saved Courses'}}">
          Exit Form
        </div>
      </div>
      <!-- <div fxFlex="33" class="divDisplay paddingTop20" fxLayoutAlign="center center">

      </div> -->
      <div fxFlex="60" fxLayout="column" class="divDisplay">
        <div fxLayout="row" fxLayoutAlign="end center" class="divFloatRight"
          *ngIf="basicCourseTabClicked && !operationalCourseTabClicked">
          <div class="error-msg" [ngStyle]="{'margin-right': '15px', 'font-size':'12px'}" *ngIf="formErr">
            {{formErrMsg}}
          </div>
          <div *ngIf="insertInProgress">
            <button id="btnSubmit" type="submit" class="form-action-btn btn-enlarged" [disabled]="true"
              mat-raised-button style="margin-right:15px;cursor:'not-allowed'">
              {{"Saving..."}}
            </button>
          </div>
          <div *ngIf="hasInsertFailed" class="error-msg" style="margin-right:15px;">
            {{"Something went wrong. Please try again."}}
          </div>
          <div *ngIf="!insertInProgress">
            <button id="btnSubmit" type="submit" class="form-action-btn btn-enlarged" [disabled]="!contentForm.valid "
              mat-raised-button [ngClass]="{ disabled: !contentForm.valid  }" (click)="save('save');" title="{{
            !contentForm.valid
              ? 'One or more Mandatory Fields might be wrong or empty'
              : ''
            }}" *ngIf="contentForm.get('creditSpecial').value == 'Yes' && isApproved==false"
              style="margin-right:15px;">
              Save
            </button>
            <button mat-raised-button class="form-action-btn btn-enlarged" [disabled]="!contentForm.valid"
              (click)="save('save')" *ngIf="contentForm.get('creditSpecial').value == 'No' && isApproved==false"
              [ngClass]="{ disabled: !contentForm.valid   }" title="{{
            !contentForm.valid
              ? 'One or more Mandatory Fields might be wrong or empty'
              : ''
            }}" style="margin-right:15px;">
              Save
            </button>
            <button mat-raised-button class="form-action-btn btn-enlarged"
              [disabled]="!contentForm.valid  && !contentForm.disabled" (click)="next()"
              *ngIf="contentForm.get('creditSpecial').value == 'No'"
              [ngClass]="{ disabled: (!contentForm.valid && !contentForm.disabled) || !isEdit }" title="{{
              (!contentForm.valid && !contentForm.disabled)
                ? 'Please enter all mandatory fields'
                : !isEdit?'Please save the data to proceed':''
            }}">
              Next
            </button>
            <button id="btnSubmit" type="submit" class="form-action-btn btn-enlarged" [disabled]="!contentForm.valid"
              mat-raised-button [ngClass]="{ disabled: !contentForm.valid   }"
              (click)="valdiateCourseOperationalDetails('submit')" title="{{
            !contentForm.valid
              ? 'One or more Mandatory Fields might be wrong or empty'
              : ''
            }}" *ngIf="contentForm.get('creditSpecial').value == 'Yes' && isApproved==false">
              Preview
            </button>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" *ngIf="!basicCourseTabClicked && operationalCourseTabClicked"
          class="btn-holder divFloatRight">
          <div *ngIf="!insertInProgress">
            <button mat-raised-button class="form-action-btn btn-space btn-enlarged"
              (click)="basicCourseClick()">Back</button>
            <button id="btnSave" type="button" class="form-action-btn btn-enlarged" mat-raised-button
              (click)="valdiateCourseOperationalDetails('save')" title="{{
                !contentForm.valid
                  ? 'One or more Mandatory Fields might be wrong or empty'
                  : ''
              }}" [disabled]="!contentForm.valid" [ngClass]="{ disabled: !contentForm.valid   }"
              style="margin-right:15px;" *ngIf="isApproved==false">
              Save
            </button>
            <button id="btnSubmit" type="submit" class="form-action-btn btn-enlarged" mat-raised-button
              (click)="valdiateCourseOperationalDetails('submit')" title="{{
                !contentForm.valid
                  ? 'One or more Mandatory Fields might be wrong or empty'
                  : ''
              }}" [disabled]="!contentForm.valid" [ngClass]="{ disabled: !contentForm.valid   }"
              *ngIf="isApproved==false">
              Preview
            </button>
          </div>
          <div *ngIf="insertInProgress">
            <button id="btnSubmit" type="submit" class="form-action-btn btn-enlarged" [disabled]="true"
              mat-raised-button style="margin-right:15px;cursor:'not-allowed'">
              {{"Saving..."}}
            </button>
          </div>
          <div *ngIf="hasInsertFailed" class="error-msg" style="margin-right:15px;">
            {{"Something went wrong. Please try again."}}
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="content-upload-container" *ngIf="showloading" fxFlex fxLayout="column" fxLayoutAlign="center center">
  <div>
    <app-preloader></app-preloader>
  </div>
</div>