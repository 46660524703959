import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LearningPathService } from 'apps/yorbit-ui/src/app/learning-path/learning-path.service';
import { PlaylistService } from '../playlist.service';
import { AddedContentDataService } from '../../services/added-content-data.service';
import * as fromUserDetailsStore from 'apps/yorbit-ui/src/app/shared/user-details/store';
import { Store } from '@ngrx/store';
import { LPBreadcrumbsStateModel } from 'apps/yorbit-ui/src/app/learning-path/learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from 'apps/yorbit-ui/src/app/learning-path/learning-path-breadcrumbs.interface';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccountContentCompletionConfirmationComponent } from '../account-content-completion-confirmation/account-content-completion-confirmation.component';
import { saveAs } from 'file-saver';
import { Subscriber } from 'rxjs';
import { SystemService } from '../../services/system.service';
@Component({
  selector: 'yorbit-playlist-account-course',
  templateUrl: './playlist-account-course.component.html',
  styleUrls: ['./playlist-account-course.component.scss']
})
export class PlaylistAccountCourseComponent implements OnInit {
  @Input() courseMaterial;
  progressList: Array<any>;
  units: Array<any>;
  unitContents: any;
  accountPlaylistCompSubscriptions: any;
  LPBreadcrumbs: ILPBreadcrumbs;
  isFirstLoad: boolean;
  courseMatAvailable: any;
  courseMaterialType: any;
  filterPipe: any;
  loading: boolean;
  loadedSuccessFully: boolean;
  selectedUnitId: string;
  constructor(
    private addedContentDataService: AddedContentDataService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private playlistService: PlaylistService,
    private lpService: LearningPathService,
    private dialog: MatDialog,
    private _systemSvc :SystemService
  ) {
    this.units = [];
    this.unitContents = {};
    this.progressList = [];
    this.accountPlaylistCompSubscriptions = {};
    this.LPBreadcrumbs = { ...LPBreadcrumbsStateModel };
    this.isFirstLoad = true;
    this.filterPipe = new ArrayPropertyFilterPipe();
    this.loading = true;
    this.loadedSuccessFully = false;
    this.selectedUnitId = '';
  }

  ngOnInit() {
    this.subscribeToBreadCrumbEvents();
    //////console.log('fasd', this.courseMaterial);
    this.courseMatAvailable =
      this.courseMaterial.CourseMaterialLink != '' ? true : false;
    this.courseMaterialType = this.courseMaterial.CourseMaterialType;
  }
  subscribeToBreadCrumbEvents() {
    //create breadcrumbs model and interface to init it in different components as well instead in the service
    this.accountPlaylistCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        this.LPBreadcrumbs = breadcrumbs;

        if (
          this.LPBreadcrumbs.CourseDetails.CourseId != null &&
          this.isFirstLoad
        ) {
          this.isFirstLoad = false;
          //this.subscribeToCaptureprogress();
          this.getProgressList();
          this.getContentOfProjectCourse(this.LPBreadcrumbs.CourseDetails);
        }
      });
  }
  getProgressList() {
    this.accountPlaylistCompSubscriptions.getLearningPathByIdEntitiesSub = this.userDetailsStore
      .select(
        fromUserDetailsStore.getLearningPathByIdEntities(
          this.LPBreadcrumbs.LPDetails.IsLPMandatory
            ? this.LPBreadcrumbs.LPDetails.LPName.toUpperCase()
            : this.LPBreadcrumbs.LPDetails.LPId
          //this.LPBreadcrumbs.LPDetails.LPId
        )
      )
      .subscribe(lp => {
        let itemId;
        if (this.LPBreadcrumbs.PackageDetails.PackageId != null) {
          itemId = this.LPBreadcrumbs.PackageDetails.PackageId;
        } else {
          itemId = this.LPBreadcrumbs.CourseDetails.CourseId;
        }
        let contentDetails = this.filterPipe.transform(lp.PackageList, {
          property: 'ItemId',
          flag: itemId
        });
        contentDetails = this.filterPipe.transform(contentDetails, {
          property: 'IsDeleted',
          flag: false
        })[0];
        if (
          contentDetails != null &&
          contentDetails != undefined &&
          contentDetails.ProgressList != undefined
        ) {
          contentDetails.ProgressList.forEach(progresslist => {
            progresslist.Progress = parseInt(progresslist.Progress);
            this.progressList[progresslist.ContentId] = progresslist;
          });
        }
      });
  }
  loadPlaylist(course) {
    //account courses
  }
  getContentOfProjectCourse(course) {
    let payload = {
      ItemId: course.CourseId,
      AccountPackage: course.IsAccount,
      ProjectPackage: course.IsProject,
      AccountId: course.AccountId,
      ProjectId: course.ProjectId
    };
    this.accountPlaylistCompSubscriptions.getPPCourseContentSub = this.playlistService
      .getContentInsideCourse_PP(payload)
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.loadedSuccessFully = true;
          if (data.packages != null) {
            this.units = data.packages;
            this.selectedUnitId = this.units[0].UniqueId;
            this.units.forEach((unit, index) => {
              this.unitContents[unit.UniqueId] = [];
              this.getContentInProjectUnit(course, unit);
            });
          }
        },
        error => {
          this.loading = false;
          this.loadedSuccessFully = false;
        }
      );
  }
  getContentInProjectUnit(course, unit) {
    unit.Id = unit.UniqueId;
    let payload = {
      ItemId: unit.UniqueId,
      AccountPackage: course.IsAccount,
      ProjectPackage: course.IsProject,
      AccountId: course.AccountId,
      ProjectId: course.ProjectId
    };
    this.accountPlaylistCompSubscriptions.getPPUnitsRequestSub = this.playlistService
      .getContentInsideUnit_PP(payload)
      .subscribe(
        (data: any) => {
          if (data.ContentList != null) {
            if (data.ContentList.length > 0) {
              data.ContentList.forEach(content => {
                if (this.progressList[content.UniqueId] == undefined) {
                  let contentProgress = {};
                  contentProgress['Progress'] = 0;
                  contentProgress['ContentId'] = content.UniqueId;
                  contentProgress[
                    'CourseId'
                  ] = this.LPBreadcrumbs.CourseDetails.CourseId;
                  this.progressList[content.UniqueId] = contentProgress;
                  content.Completed = false;
                } else {
                  if (this.progressList[content.UniqueId].Progress >= 95) {
                    content.Completed = true;
                  }
                }
              });
              this.unitContents[unit.UniqueId] = data.ContentList;
            }
          }
        },
        error => {}
      );
  }
  showPopupToConfirmAccountContentCompletion(content) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'expertDialogContainer';

    const dialogRef = this.dialog.open(
      AccountContentCompletionConfirmationComponent,
      {
        panelClass: 'popupDialogContainer',
        //dialogue-box-data
        disableClose: true,
        data: { title: content.Title }
      }
    );
    this.accountPlaylistCompSubscriptions.accountCourseDialogRef = dialogRef
      .afterClosed()
      .subscribe(confirmStatus => {
        if (confirmStatus == true) {
          this.progressList[content.UniqueId].Progress = 100;
          content.Completed = true;
          let itemId = this.LPBreadcrumbs.CourseDetails.CourseId;
          let itemType = 'Course';
          if (this.LPBreadcrumbs.PackageDetails.PackageId != null) {
            itemId = this.LPBreadcrumbs.PackageDetails.PackageId;
            itemType = 'FamilyPackage';
          }
          let payload = {
            learningPathId: this.LPBreadcrumbs.LPDetails.LPId,
            itemId: itemId,
            itemType: itemType,
            courseId: this.LPBreadcrumbs.CourseDetails.CourseId,
            contentId: content.UniqueId
          };
          if (this.LPBreadcrumbs.LPDetails.IsLPMandatory) {
            this.userDetailsStore
              .select(fromUserDetailsStore.getMandatoryLPsSelector)
              .subscribe(mandatoryLps => {
                mandatoryLps.forEach(lp => {
                  if (
                    lp.PathName.toLowerCase() ==
                    this.LPBreadcrumbs.LPDetails.LPId.toString().toLowerCase()
                  ) {
                    payload.learningPathId = lp.PathId;
                  }
                });
              });
          }

          this.accountPlaylistCompSubscriptions.captureAccountProgressSub = this.playlistService
            .captureAccountContentProgress(payload)
            .then((response: any) => {
              if (null == response) {
                this.progressList[content.UniqueId].Progress = 0;
              } else {
                this.progressList[content.UniqueId].Progress = 100;
                this.userDetailsStore.dispatch(
                  new fromUserDetailsStore.UpdateUnitProgress({
                    LPId: this.LPBreadcrumbs.LPDetails.LPId,
                    ItemId: payload.itemId,
                    Progress: {
                      ContentId: payload.contentId,
                      CourseId: payload.courseId,
                      CurrentTime: null,
                      Progress: '100'
                    }
                  })
                );
                this.userDetailsStore.dispatch(
                  new fromUserDetailsStore.UpdateCourseProgress({
                    LPId: this.LPBreadcrumbs.LPDetails.LPId,
                    PackageId: this.LPBreadcrumbs.PackageDetails.PackageId,
                    CourseId: this.LPBreadcrumbs.CourseDetails.CourseId,
                    Progress: response.CourseProgress
                  })
                );
                if (itemType == 'FamilyPackage') {
                  this.addedContentDataService.updateCourseProgressInPackage(
                    this.LPBreadcrumbs.PackageDetails.PackageId,
                    this.LPBreadcrumbs.CourseDetails.CourseId,
                    response.CourseProgress
                  );
                  // this.userDetailsStore.dispatch(
                  //   new fromUserDetailsStore.UpdateCourseProgressInPackage({
                  //     PackageId: this.LPBreadcrumbs.PackageDetails.PackageId,
                  //     CourseId: this.LPBreadcrumbs.CourseDetails.CourseId,
                  //     Progress: response.CourseProgress
                  //   })
                  // );
                }
              }
            });
        }
      });
  }
  openExternalLink(content) {
    var fileCheck = content.SourceUrl.split(':');
    if (fileCheck[0] == 'file') {
      var start =
        '<!DOCTYPE html><html><head><title></title><meta charset="utf-8" />';
      var style =
        '<style>.message{width: 600px; height: 180px;margin: auto;margin-top: 12%;padding: 10px 10px;text-align: justify;font-size: 16px; font-family: calibri;border: 1px solid #000; } .buttons{margin-left: 120px;margin-top: 35px;} button{cursor:pointer;} .btn-margin-left{margin-left: 235px;}</style></head><body class="message">';
      var script =
        '<script> function openURL(){ window.location = "' +
        content.SourceUrl +
        '";}</script>';
      var message =
        '<div>This link might be pointing to a protected document in a secure repository. The access restrictions defined by the target repository will be in effect. </div> <p>The document’s IP confidentiality should be respected. Speak to your reporting manager in case of doubt.</p>';
      var end =
        '<div class="buttons"><button onclick="openURL();">Proceed</button> <button class="btn-margin-left" onclick="self.close();">Cancel</button></div></body></html>';
      var htmlFile = start + style + script + message + end;
      var fileName = content.Title + '.html';
      var data = new Blob([htmlFile], { type: 'text/html;charset=utf-8' });
      saveAs(data, fileName);
    } else {
      window.open(content.SourceUrl);
    }
  }
  downloadCourseMaterial() {
    if(this.courseMatAvailable && this.courseMaterial.CourseMaterialType == 'Internal')
    {
      this._systemSvc.getBlobSasKey(this.courseMaterial.CourseMaterialLink)
      .then((response:any)=>{
        try{
          let decodedUri = this._systemSvc.getAtoBDecode(response);
          window.open(decodedUri,'_blank');
        }
        catch{}
      });
    }
    else{
    window.open(this.courseMaterial.CourseMaterialLink, '_blank');
     }
  }
}
