<div
  fxLayout="column"
  fxLayoutGap="10px"
  class="tree"
  fxLayoutAlign="center start"
>
  <div class="form-header">Evaluation Form</div>
  <mat-horizontal-stepper
    
    [animationDuration]="'0'"
    #stepper
    class="stepper"
    [disableRipple]="true"
  >
    <ng-template matStepperIcon="number" let-index="index">
      <div
        *ngIf="index != stepper.selectedIndex"
        class="step-number"
        (click)="goToStep(index)"
      >
        {{ index + 1 }}
      </div>
      <div *ngIf="index == stepper.selectedIndex">
        <mat-icon class="step-number">edit</mat-icon>
      </div>
    </ng-template>
    <mat-step [stepControl]="participantsDetails" class="fixed-width-step">
      <div class="participant-page">
        <div class="content">
          <!-- <header>
          <span class="text-bold">Participants details</span>
        </header> -->
          <!-- <ng-template matStepperIcon="number" let-index="index">
          <div class="step-number">{{index + 1}}</div>
          </ng-template> -->
          <form [formGroup]="participantsDetails">
            <ng-template matStepLabel>
              <div (click)="$event.stopPropagation()">Participants details</div>
            </ng-template>
            <mat-card class="mb-4">
              <mat-card-content>
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayout.sm="column"
                  fxLayoutGap="50px"
                  fxLayoutGap.xs="0px"
                  fxLayoutGap.sm="0px"
                >
                  <!-- Column 1 -->
                  <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div fxLayout="column">
                      <div fxFlex="100%" fxLayout="column">
                        <label
                          >Associate Name
                         </label
                        >
                        <input
                          type="text"
                          class="form-control"
                          formControlName="associateName"
                          placeholder="Associate Name"
                        />
                      </div>
                      <div fxFlex="100%" fxLayout="column" class="p-10">
                        <label for="associate-ps-id" class="form-label"
                          >Associate PSID
                          </label
                        >
                        <input
                          type="text"
                          class="form-control"
                          formControlName="associatePsId"
                          placeholder="Associate PSID"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Column 2 -->
                  <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div fxLayout="column">
                      <div fxLayout="column">
                        <label for="grade" class="form-label"
                          >Grade</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          formControlName="grade"
                          placeholder="Grade"
                        />
                      </div>
                      <div fxLayout="column" class="p-10">
                        <label for="manager-name" class="form-label"
                          >Immediate Reporting Manager Name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          formControlName="managerName"
                          placeholder="Manager Name"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Column 3 -->
                  <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div fxLayout="column">
                      <div fxLayout="column">
                        <label for="practice-unit" class="form-label"
                          >Practice/Business Unit Description</label
                        >
                        <textarea
                          class="form-control"
                          formControlName="practiceUnit"
                          placeholder="Practice Unit"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
              <div
                fxLayout="row"
                fxLayoutAlign="end"
                class="navigation-buttons"
                fxLayoutGap="25px"
              >
                <!-- <button mat-button (click)="prevStep()" [disabled]="step === 0">Previous</button>
        <button mat-button (click)="save()">Save as Draft</button>
        <button mat-button  (click)="nextStep()">Next</button> -->

                <button
                  mat-button
                  
                  (click)="prevStep(stepper)"
                  [disabled]="stepper.selectedIndex == 0"
                >
                  Previous
                </button>
                <button
                  mat-button
                  [disabled]="isInProgress || isFormSubmitted"
                  [ngClass]="{ disabled: isInProgress || isFormSubmitted }"
                  (click)="save(false)"
                >
                  {{ saveLabel }}
                </button>
                <button mat-button  (click)="nextStep(stepper,'participantDetails')">
                  Next
                </button>
              </div>
            </mat-card>
          </form>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="skillEvaluationDetails">
      <div class="participant-page">
        <div class="content">
          <!-- <header>
          <span class="text-bold">Skill Evaluation Details</span>
        </header> -->
          <!-- <ng-template matStepperIcon="edit">
          <mat-icon *ngIf="stepper.selectedIndex === 1">edit</mat-icon>
          <mat-icon *ngIf="stepper.selectedIndex !== 0">done</mat-icon>
        </ng-template> -->
          <!-- <ng-template matStepperIcon="number" let-index="index">
          <div class="step-number">{{index + 1}}</div>     
          
          </ng-template> -->
          <form [formGroup]="skillEvaluationDetails">
            <ng-template matStepLabel>
              <div (click)="$event.stopPropagation()">
                Skill Evaluation Details
              </div>
            </ng-template>
            <mat-card class="mb-4">
              <div class= "alert" *ngIf="skillEvaluationErrorFlag">{{fieldValidationErrMsg}}</div>
              <mat-card-content>               
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayout.sm="column"
                  fxLayoutGap="50px"
                  fxLayoutGap.xs="0px"
                  fxLayoutGap.sm="0px"
                >
                  <!-- Column 1 -->
                  <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div fxLayout="column">
                      <div fxLayout="column">
                        <label for="leaf-skill" class="form-label"
                          >Leaf Skill Evaluated</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="leaf-skill"
                          formControlName="leafSkill"
                          placeholder="Enter Leaf Skill"
                          readonly
                        />
                      </div>
                      <div fxLayout="column" class="p-10">
                        <label for="current-proficiency" class="form-label"
                          >Current Level of Proficiency<span class="mandatory-field-indicator">
                            *
                          </span></label
                        >
                        <!-- <input
                          type="text"
                          class="form-control"
                          id="current-proficiency"
                          formControlName="currentProficiency"
                          placeholder="Current Proficiency"
                        /> -->
                        <select
                          class="form-select"
                          id="current-proficiency"
                          formControlName="currentProficiency"
                        >
                          <option value="" disabled selected>
                            Select Current Level of Proficiency
                          </option>
                          <option [value]="'L1'">L1</option>
                          <option [value]="'L2'">L2</option>
                          <option [value]="'L3'">L3</option>
                          <option [value]="'L4'">L4</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- Column 2 -->
                  <!-- <div fxFlex="33%" fxFlex.xs="100%">
                  <div fxLayout="column">
                    <div fxLayout="column">
                      <label for="skill-gap" class="form-label">Current Skill Gap<span class="mandatory-field-indicator"> * </span></label>
                      <input type="text" class="form-control" id="skill-gap" formControlName="skillGap"
                        placeholder="Enter Skill Gap" readonly>
                    </div>
                    <div fxLayout="column" class="p-10">
                      <label for="interview-proficiency" class="form-label">Proficiency Level for
                        Interview<span class="mandatory-field-indicator"> * </span></label>
                      <input type="text" class="form-control" id="interview-proficiency"
                        formControlName="interviewProficiency" placeholder="Enter Interview Proficiency" readonly>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" class="p-10">
                      <div fxFlex="100%" fxLayout="column">
                        <label for="gap-yes" class="form-label">If Skill Gap is Yes<span class="mandatory-field-indicator"> * </span></label>
                        <input type="text" class="form-control" id="gap-yes" formControlName="gapYes"
                          placeholder="If Gap is Yes" readonly>
                      </div>
                      <div fxFlex="100%" fxLayout="column">
                        <label for="gap-no" class="form-label">If No Gap<span class="mandatory-field-indicator"> * </span></label>
                        <input type="text" class="form-control" id="gap-no" formControlName="gapNo"
                          placeholder="Enter Details if No Gap" readonly>
                      </div>
                    </div>
                  </div>
                </div> -->
                  <!-- Column 3 -->
                  <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div fxLayout="column">
                      <!-- <div fxLayout="column">
                      <label for="proficiency-definitions" class="form-label">Proficiency Definitions<span class="mandatory-field-indicator"> * </span></label>
                      <textarea class="form-control" id="proficiency-definitions"
                        formControlName="proficiencyDefinitions" placeholder="Enter Proficiency Definitions"
                        readonly></textarea>
                    </div> -->
                      <div
                        fxLayout="row"
                        fxLayout.xs="column"
                        fxLayoutGap="16px"
                      >
                        <div fxFlex="100%" fxLayout="column">
                          <label for="years-experience" class="form-label"
                            >Year(s)<span class="mandatory-field-indicator">
                              *
                            </span></label
                          >
                          <select
                            class="form-select"
                            id="years-experience"
                            formControlName="yearsExperience"
                          >
                            <option value="" disabled selected>
                              Select Year(s)
                            </option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                        <div fxFlex="100%" fxLayout="column">
                          <label for="months-experience" class="form-label"
                            >Month(s)<span class="mandatory-field-indicator">
                              *
                            </span></label
                          >
                          <select
                            class="form-select"
                            id="months-experience"
                            formControlName="monthsExperience"
                          >
                            <option value="" disabled selected>
                              Select Month(s)
                            </option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                          </select>
                        </div>
                      </div>
                      <div fxLayout="column" class="p-10">
                        <label for="required-proficiency" class="form-label"
                          >Proficiency Level Required<span
                            class="mandatory-field-indicator"
                          >
                            *
                          </span></label
                        >
                        <select
                          class="form-select"
                          id="required-proficiency"
                          formControlName="requiredProficiency"
                        >
                          <option value="" disabled selected>
                            Select Proficiency Level
                          </option>
                          <option [value]="'L1'">L1</option>
                          <option [value]="'L2'">L2</option>
                          <option [value]="'L3'">L3</option>
                          <option [value]="'L4'">L4</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>

              <div
                fxLayout="row"
                fxLayoutAlign="end"
                class="navigation-buttons"
                fxLayoutGap="25px"
                fxLayoutGap.xs="0px"
                fxLayoutGap.sm="0px"
              >
                <button
                  mat-button
                  
                  (click)="prevStep(stepper)"
                >
                  Previous
                </button>
                <button
                  mat-button
                  [disabled]="isInProgress || isFormSubmitted"
                  [ngClass]="{ disabled: isInProgress || isFormSubmitted }"
                  (click)="save(false)"
                >
                  {{ saveLabel }}
                </button>
                <button mat-button
                (click)="nextStep(stepper, 'skillEvaluation')">
                  Next
                </button>
              </div>
              <span class="errorMsg" *ngIf="false && skillEvaluationErrorFlag">Please fill the mandatory fields <sup>*</sup></span>
            </mat-card>
          </form>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="interviewDetails">
      <div class="participant-page">
        <div class="content">
          <!-- <header>
          <span class="text-bold">Interview Details </span>
        </header> -->
          <!-- <ng-template matStepperIcon="edit">
          <mat-icon *ngIf="stepper.selectedIndex === 2">edit</mat-icon>
          <mat-icon *ngIf="stepper.selectedIndex !== 0">done</mat-icon>
        </ng-template> -->
          <!-- <ng-template matStepperIcon="number" let-index="index">
          <div class="step-number">{{index + 1}}</div>     
          
          </ng-template> -->
          <form [formGroup]="interviewDetails">
            <ng-template matStepLabel>
              <div (click)="$event.stopPropagation()">Interview Details</div>
            </ng-template>
            <mat-card class="mb-4">
              <div class= "alert" *ngIf="interviewDetailsErrorFlag">{{fieldValidationErrMsg}}</div>
              <mat-card-content>
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayout.sm="column"
                  fxLayoutGap="50px"
                  fxLayoutGap.xs="0px"
                  fxLayoutGap.sm="0px"
                >
                  <!-- Column 1 -->
                  <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div fxLayout="column">
                      <div fxLayout="column">
                        <label for="interview-date" class="form-label"
                          >Date of Interview<span
                            class="mandatory-field-indicator"
                          >
                            *
                          </span><span class="label-hint"> (MM/DD/YYYY)</span></label
                        >
                        <!-- <input
                        type="date"
                        class="form-control calendar-input"
                        id="interview-date"
                        formControlName="interviewDate"
                        [value]="interviewDate"
                        [(ngModel)]="interviewDate"
                        placeholder="Select Date"
                        [max] = "maxDate"
                      />   -->

                        <div class="datepicker-container">
                          <mat-form-field
                            class="custom-datepicker"
                            [floatLabel]="'never'"
                          >
                            <input
                              class="mat-datepicker-input"
                              matInput
                              [matDatepicker]="picker"
                              id="interview-date"
                              formControlName="interviewDate"
                              [value]="interviewDate"
                              [(ngModel)]="interviewDate"
                              placeholder="Select a Date"
                              [max]="maxDate"
                              readonly
                            />

                            <mat-datepicker-toggle
                              matSuffix
                              [for]="picker"
                            ></mat-datepicker-toggle>

                            <mat-datepicker #picker></mat-datepicker>
                            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                          </mat-form-field>
                          <mat-icon
                            matDatepickerToggleIcon
                            (click)="clearDate($event)"
                            >clear</mat-icon
                          >
                        </div>
                      </div>

                      <div fxLayout="column" class="p-10">
                        <label for="format" class="form-label"
                          >Format<span class="mandatory-field-indicator">
                            *
                          </span></label
                        >
                        <select
                          class="form-select"
                          id="format"
                          formControlName="format"
                        >
                          <option disabled selected value="">
                            Select Format
                          </option>
                          <option value="In-person">In-person</option>
                          <option value="Online">Online</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- Column 2 -->
                  <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div fxLayout="column">
                      <div fxLayout="column">
                        <label for="panel-details" class="form-label"
                          >Panel Details   
                          </label
                        >
                        <div fxLayout="row" fxLayoutGap="16px">
                          <div fxFlex="100%" fxLayout="column" class = "tooltip">
                            <input
                              type="text"
                              class="form-control tooltip"
                              id="panelists-name"
                              formControlName="panelistsName"
                              placeholder="Names of panelists"
                              />
                              <!-- title="{{this.interviewDetails.get('panelistsName').value}}" -->
                            <span class="tooltiptext panelist-email" *ngIf="panelistEmails.length > 0">
                              <ul>
                               <li *ngFor="let panelist of panelistEmails" >
                                <div class="">{{panelist.EmailID}}</div>
                              </li>
                              </ul>
                            </span>
                          </div>
                          <div fxFlex="100%" fxLayout="column" *ngIf="false">
                            <select
                              class="form-select"
                              id="ps1"
                              formControlName="ps1"
                            >
                              <option value="" disabled selected>PSIDs</option>
                              <option value="6108400">6108400</option>
                            </select>
                          </div>
                          <!-- <div fxFlex="100%" fxLayout="column">
                          <select class="form-select" id="ps2" formControlName="ps2">
                            <option value="" disabled selected>PSIDs</option>
                            <option value="6108400">6108400</option>
                          </select>
                        </div> -->
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <!-- Column 3 -->
                  <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div fxLayout="column">
                      <div fxLayout="column">
                        <label for="other-details" class="form-label"
                          >Any Other Details </label
                        >
                        <textarea
                          class="form-control"
                          id="other-details"
                          formControlName="otherDetails"
                          placeholder="Enter any other details"
                          maxlength="500"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>

              <div
                fxLayout="row"
                fxLayoutAlign="end"
                class="navigation-buttons"
                fxLayoutGap="25px"
              >
                <button
                  mat-button
                  
                  (click)="prevStep(stepper)"
                >
                  Previous
                </button>
                <button
                  mat-button
                  [disabled]="isInProgress || isFormSubmitted"
                  [ngClass]="{ disabled: isInProgress || isFormSubmitted }"
                  (click)="save(false)"
                >
                  {{ saveLabel }}
                </button>
                <button mat-button  (click)="nextStep(stepper, 'interviewDetails')">
                  Next
                </button>
              </div>
              <span class="errorMsg" *ngIf="false && interviewDetailsErrorFlag">Please fill the mandatory fields <sup>*</sup></span>
            </mat-card>
          </form>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="evaluationParameters">
      <div class="participant-page">
        <div class="content">
          <!-- <header>
          <span class="text-bold">Evaluation Parameters </span>
        </header> -->
          <!-- <ng-template matStepperIcon="edit">
          <mat-icon *ngIf="stepper.selectedIndex === 3">edit</mat-icon>
          <mat-icon *ngIf="stepper.selectedIndex !== 0">done</mat-icon>
        </ng-template> -->
          <!-- <ng-template matStepperIcon="number" let-index="index">
          <div class="step-number">{{index + 1}}</div>
          </ng-template> -->
          <form [formGroup]="evaluationParameters">
            <ng-template matStepLabel>
              <div (click)="$event.stopPropagation()">Parameters</div>
            </ng-template>
            <mat-card class="mb-4">
             
              <mat-card-content>
                <div fxLayout="column">
                  <div class= "alert" *ngIf="isWorkExp() && evaluationParametersErrorFlag">{{errFieldsWorkExpMsg}}</div>
                  <div fxLayout="column">
                    <label
                    for="work-experience"
                    class="form-label fw-bold tooltip"
                    >Work Experience<span class="mandatory-field-indicator">
                      *
                    </span>

                    <span class="tooltiptext">
                      <ul>
                        <li>Work exp. description – e.g. implementation, upgrade, integration, consulting, etc</li>
                        <li>Techniques/ tips used based on the type of workex (to gauge practical experience vs theoretical knowledge)</li>
                        <li>Contributions in IP/ PoC development, pre-sales support linked to the leaf skill</li>
                      </ul>
                    </span>
                    </label>
                    <textarea
                      class="form-control"
                      id="work-experience"
                      formControlName="workExperience"
                      placeholder="Describe your work experience"
                      maxlength="1000"
                    ></textarea>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxLayoutGap="50px"
                    class="p-10"
                    fxLayoutGap.xs="0px"
                    fxLayoutGap.sm="0px"
                  >
                    <!-- Column 1 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label
                          for="work-experience-score"
                          class="form-label mt-2"
                          >Score<span class="mandatory-field-indicator">
                            *
                          </span></label
                        >
                        <select
                          class="form-select"
                          id="work-experience-score"
                          formControlName="workExperienceScore"
                        >
                          <option value="0" disabled selected>
                            Select Score
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                    </div>
                    <!-- Column 2 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label
                          for="work-experience-spike"
                          class="form-label mt-2"
                          >Key Spike/Positives</label
                        >
                        <textarea
                          class="form-control"
                          id="work-experience-spike"
                          formControlName="workExperienceSpike"
                          placeholder="Describe key spikes/positives"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                    <!-- Column 3 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label
                          for="work-experience-gaps"
                          class="form-label mt-2"
                          >Areas that are Gaps</label
                        >
                        <textarea
                          class="form-control"
                          id="work-experience-gaps"
                          formControlName="workExperienceGaps"
                          placeholder="Describe areas that are gaps"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <!-- Tooling -->
                  <div fxLayout="column">
                    <label for="tooling" class="form-label fw-bold tooltip"
                      >Tooling
                      <span class="tooltiptext">
                        <ul>
                          <li> Tools, functional modules</li>
                        </ul>
                      </span>
                        </label>
                    <textarea
                      class="form-control"
                      id="tooling"
                      formControlName="tooling"
                      placeholder="Describe your tooling experience"
                      maxlength="1000"
                    ></textarea>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxLayoutGap="50px"
                    fxLayoutGap.xs="0px"
                    fxLayoutGap.sm="0px"
                    class="p-10"
                  >
                    <!-- Column 1 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="tooling-score" class="form-label mt-2"
                          >Score</label
                        >
                        <select
                          class="form-select"
                          id="tooling-score"
                          formControlName="toolingScore"
                        >
                          <option value="0" disabled selected>
                            Select Score
                          </option>
                          <option value="0">NA</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                    </div>
                    <!-- Column 2 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="tooling-spike" class="form-label mt-2"
                          >Key Spike/Positives</label
                        >
                        <textarea
                          class="form-control"
                          id="tooling-spike"
                          formControlName="toolingSpike"
                          placeholder="Describe key spikes/positives"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                    <!-- Column 3 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="tooling-gaps" class="form-label mt-2"
                          >Areas that are Gaps</label
                        >
                        <textarea
                          class="form-control"
                          id="tooling-gaps"
                          formControlName="toolingGaps"
                          placeholder="Describe areas that are gaps"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <!-- Procedures/Methodologies -->
                  <div fxLayout="column">
                    <div class= "alert" *ngIf="isProc() && evaluationParametersErrorFlag">{{errFieldsProcMsg}}</div>
                    <label for="procedures" class="form-label fw-bold tooltip"
                    >Procedures/Methodologies<span
                      class="mandatory-field-indicator"
                    >
                      *
                    </span>
                    <span class="tooltiptext">
                      <ul>
                        <li> Knowledge of procedures/ methodologies – review processes, quality standards, etc</li>
                      </ul>
                      </span>
                     
                    </label
                  >
                    <textarea
                      class="form-control"
                      id="procedures"
                      formControlName="procedures"
                      placeholder="Describe your experience with procedures/methodologies"
                      maxlength="1000"
                    ></textarea>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxLayoutGap="50px"
                    fxLayoutGap.xs="0px"
                    fxLayoutGap.sm="0px"
                    class="p-10"
                  >
                    <!-- Column 1 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="procedures-score" class="form-label mt-2"
                          >Score<span class="mandatory-field-indicator">
                            *
                          </span></label
                        >
                        <select
                          class="form-select"
                          id="procedures-score"
                          formControlName="proceduresScore"
                        >
                          <option value="0" disabled selected>
                            Select Score
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                    </div>
                    <!-- Column 2 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="procedures-spike" class="form-label mt-2"
                          >Key Spike/Positives</label
                        >
                        <textarea
                          class="form-control"
                          id="procedures-spike"
                          formControlName="proceduresSpike"
                          placeholder="Describe key spikes/positives"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                    <!-- Column 3 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="procedures-gaps" class="form-label mt-2"
                          >Areas that are Gaps</label
                        >
                        <textarea
                          class="form-control"
                          id="procedures-gaps"
                          formControlName="proceduresGaps"
                          placeholder="Describe areas that are gaps"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <!-- Solutioning -->
                  <div fxLayout="column">
                    <div class= "alert" *ngIf="isSolution() && evaluationParametersErrorFlag">{{errFieldsSolutioningMsg}}</div>
                    <label for="solutioning" class="form-label fw-bold tooltip"
                    >Solutioning<span class="mandatory-field-indicator">
                      *
                    </span>
                    <span class="tooltiptext">
                      <ul>
                        <li> End to end solutioning – understanding functional requirements, solution/ architecture design, understanding of related databases & integration techniques, interoperability with other tools/ modules, etc </li>
                      </ul>
                     </span>
                    </label
                  >
                    <textarea
                      class="form-control"
                      id="solutioning"
                      formControlName="solutioning"
                      placeholder="Describe your solutioning experience"
                      maxlength="1000"
                    ></textarea>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxLayoutGap="50px"
                    fxLayoutGap.xs="0px"
                    fxLayoutGap.sm="0px"
                    class="p-10"
                  >
                    <!-- Column 1 -->
                    <div fxFlex="33%" fxFlex.xs="100%">
                      <div fxLayout="column">
                        <label for="solutioning-score" class="form-label mt-2"
                          >Score<span class="mandatory-field-indicator">
                            *
                          </span></label
                        >
                        <select
                          class="form-select"
                          id="solutioning-score"
                          formControlName="solutioningScore"
                        >
                          <option value="0" disabled selected>
                            Select Score
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                    </div>
                    <!-- Column 2 -->
                    <div fxFlex="33%" fxFlex.xs="100%">
                      <div fxLayout="column">
                        <label for="solutioning-spike" class="form-label mt-2"
                          >Key Spike/Positives</label
                        >
                        <textarea
                          class="form-control"
                          id="solutioning-spike"
                          formControlName="solutioningSpike"
                          placeholder="Describe key spikes/positives"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                    <!-- Column 3 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="solutioning-gaps" class="form-label mt-2"
                          >Areas that are Gaps</label
                        >
                        <textarea
                          class="form-control"
                          id="solutioning-gaps"
                          formControlName="solutioningGaps"
                          placeholder="Describe areas that are gaps"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <!-- Situational Questions -->
                  <div fxLayout="column">
                    <div class= "alert" *ngIf="isSituational() && evaluationParametersErrorFlag">{{errFieldsSituationalQuesMsg}}</div>
                    <label
                    for="situational-questions"
                    class="form-label fw-bold tooltip"
                    >Situational Questions<span
                      class="mandatory-field-indicator"
                    >
                      *
                    </span>
                    <span class="tooltiptext">Mock client situations / case studies</span>
                    </label
                  >
                    <textarea
                      class="form-control"
                      id="situational-questions"
                      formControlName="situationalQuestions"
                      placeholder="Describe situational questions"
                      maxlength="1000"
                    ></textarea>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxLayoutGap="50px"
                    fxLayoutGap.xs="0px"
                    fxLayoutGap.sm="0px"
                    class="p-10"
                  >
                    <!-- Column 1 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="situational-score" class="form-label mt-2"
                          >Score<span class="mandatory-field-indicator">
                            *
                          </span></label
                        >
                        <select
                        class="form-select"
                        id="situational-score"
                        formControlName="situationalScore"
                      >
                        <option value="0" disabled selected>
                          Select Score
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      </div>
                    </div>
                    <!-- Column 2 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="situational-spike" class="form-label mt-2"
                          >Key Spike/Positives</label
                        >
                        <textarea
                          class="form-control"
                          id="situational-spike"
                          formControlName="situationalSpike"
                          placeholder="Describe key spikes/positives"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                    <!-- Column 3 -->
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%">
                      <div fxLayout="column">
                        <label for="situational-gaps" class="form-label mt-2"
                          >Areas that are Gaps</label
                        >
                        <textarea
                          class="form-control"
                          id="situational-gaps"
                          formControlName="situationalGaps"
                          placeholder="Describe areas that are gaps"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <!-- Certifications -->
                  <div fxLayout="column">
                    <!-- <div class= "alert" *ngIf="isCert() && evaluationParametersErrorFlag">{{errFieldsCertMsg}}</div> -->
                    <label for="certifications-score" class="form-label fw-bold tooltip"
                    >Certifications
                    <span class="tooltiptext">
                      <ul>
                        <li> List of external certifications, internal programs / trainings completed</li>
                        <li>Key learnings / insights from the certifications/ programs</li>
                      </ul>
                      <p>Pass – 4</p>
                      <p>Appeared and Not Cleared – 2</p>
                      <p>Yet to appear – 1</p>
                    </span>
                    </label
                  >
                    <textarea
                      class="form-control"
                      id="certifications-score"
                      formControlName="certificationsScore"
                      placeholder="Describe certifications score"
                      maxlength="1000"
                    ></textarea>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxLayoutGap="50px"
                    fxLayoutGap.xs="0px"
                    fxLayoutGap.sm="0px"
                    class="p-10"
                  >
                    <!-- Column 1 -->
                    <div fxFlex="33%" fxFlex.xs="100%">
                      <div fxLayout="column">
                        <label
                          for="certifications-score-select"
                          class="form-label mt-2"
                          >Score</label
                        >
                        <select
                          class="form-select"
                          id="certifications-score-select"
                          formControlName="certificationsScoreSelect"
                        >
                          <option value="0" disabled selected>
                            Select Score
                          </option>
                          <option value="0">NA</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <!-- <option value="3">3</option> -->
                          <option value="4">4</option>
                        </select>
                      </div>
                    </div>
                    <!-- Column 2 -->
                    <div fxFlex="33%" fxFlex.xs="100%">
                      <div fxLayout="column">
                        <label
                          for="certifications-spike"
                          class="form-label mt-2"
                          >Key Spike/Positives</label
                        >
                        <textarea
                          class="form-control"
                          id="certifications-spike"
                          formControlName="certificationsSpike"
                          placeholder="Describe key spikes/positives"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                    <!-- Column 3 -->
                    <div fxFlex="33%" fxFlex.xs="100%">
                      <div fxLayout="column">
                        <label for="certifications-gaps" class="form-label mt-2"
                          >Areas that are Gaps</label
                        >
                        <textarea
                          class="form-control"
                          id="certifications-gaps"
                          formControlName="certificationsGaps"
                          placeholder="Describe areas that are gaps"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>

              <div
                fxLayout="row"
                fxLayoutAlign="end"
                class="navigation-buttons"
                fxLayoutGap="25px"
              >
                <button
                  mat-button
                  
                  (click)="prevStep(stepper)"
                >
                  Previous
                </button>
                <button
                  mat-button
                  [disabled]="isInProgress || isFormSubmitted"
                  [ngClass]="{ disabled: isInProgress || isFormSubmitted }"
                  (click)="save(false)"
                >
                  {{ saveLabel }}
                </button>
                <button mat-button  (click)="nextStep(stepper, 'evaluationParams')">
                  Next
                </button>
              </div>
              <span class="errorMsg" *ngIf="false && evaluationParametersErrorFlag">Please fill the mandatory fields <sup>*</sup></span>
            </mat-card>
          </form>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <div class="participant-page">
        <div class="content">
          <!-- <header>
          <span class="text-bold">Final Evaluation </span>
        </header> -->
          <!-- <ng-template matStepperIcon="edit">
          <mat-icon *ngIf="stepper.selectedIndex === 4">edit</mat-icon>
          <mat-icon *ngIf="stepper.selectedIndex !== 0">done</mat-icon>
        </ng-template> -->
          <!-- <ng-template matStepperIcon="number" let-index="index">
          <div class="step-number">{{index + 1}}</div>     
          
          </ng-template> -->
          <form [formGroup]="finalEvaluation">
            <ng-template matStepLabel>
              <div (click)="$event.stopPropagation()">Final Evaluation</div>
            </ng-template>
            <mat-card class="mb-4">
              <div class= "alert" *ngIf="finalEvaluationErrorFlag">{{fieldValidationErrMsg}}</div>
              <div fxLayout="row">
                <div fxFlex="100%" fxFlex.xs="100%">
                  <table>
                    <tr class="header">
                      <th>Parameter</th>
                      <th>Work Experience</th>
                      <th>Tooling</th>
                      <th>Procedures/ Methodologies</th>
                      <th>Solutioning</th>
                      <th>Situational Questions</th>
                      <th>Certifications</th>
                      <th>Average Score Across Parameteres</th>
                    </tr>
                    <tr>
                      <td><b>Score</b></td>
                      <td>
                        {{
                          evaluationParameters.get('workExperienceScore').value
                        }}
                      </td>
                      <td>
                        {{
                          evaluationParameters.get('toolingScore').value == '0'
                            ? 'Not Applicable'
                            : evaluationParameters.get('toolingScore').value
                        }}
                      </td>
                      <td>
                        {{ evaluationParameters.get('proceduresScore').value }}
                      </td>
                      <td>
                        {{ evaluationParameters.get('solutioningScore').value }}
                      </td>
                      <td>
                        {{ evaluationParameters.get('situationalScore').value }}
                      </td>
                      <td>
                        {{
                          evaluationParameters.get('certificationsScoreSelect').value == '0'
                            ? 'Not Applicable'
                            : evaluationParameters.get('certificationsScoreSelect').value
                        }}
                      </td>
                      <td>{{ num }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <mat-card-content>
                <!-- Final Evaluation -->
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayout.sm="column"
                  fxLayoutGap="50px"
                  fxLayoutGap.xs="0px"
                  fxLayoutGap.sm="0px"
                  class="p-10"
                >
                  <!-- Column 1 -->
                  <div fxFlex="33%" fxFlex.xs="100%">
                    <div fxLayout="column">
                      <label for="proficiency-met" class="form-label"
                        >Does the Associate Meet the Proficiency Definition<span
                          class="mandatory-field-indicator"
                        >
                          *
                        </span></label
                      >
                      <select
                        class="form-select"
                        id="proficiency-met"
                        formControlName="proficiencyMet"
                      >
                        <option value="" selected disabled>
                          Select a value
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>

                    <div fxLayout="column" class="p-10">
                      <label for="final-status" class="form-label"
                        >Result</label
                      >
                      <input type="text" class="form-control"
                      id="final-status"
                      formControlName="finalStatus"
                      placeholder="Result"
                      readonly
                      />
                      <!-- <select
                        class="form-select"
                        id="final-status"
                        formControlName="finalStatus"
                        placeholder="Select a Result"
                      >
                         <option value="" selected disabled>
                          Select a Result
                        </option> 
                        <option value="Pass">Pass</option>
                        <option value="Not Cleared">Not Cleared</option>
                      </select> -->
                    </div>
                    <br/><br/><br/>
                    <!-- <table>
                      <tr>
                        <th>Parameter</th>
                        <th>Work Experience</th>
                        <th>Tooling</th>
                        <th>Procedures/ Methodologies</th>
                        <th>Solutioning</th>
                        <th>Situational Questions</th>
                        <th>Certifications</th>
                        <th>Average Score Across Parameteres</th>
                      </tr>
                      <tr>
                        <td><b>Score</b></td>
                        <td>{{evaluationParameters.get('workExperienceScore').value}}</td>
                        <td>{{evaluationParameters.get('toolingScore').value == '0'? 'Not Applicable': evaluationParameters.get('toolingScore').value}}</td>
                        <td>{{evaluationParameters.get('proceduresScore').value}}</td>
                        <td>{{evaluationParameters.get('solutioningScore').value}}</td>
                        <td>{{evaluationParameters.get('situationalScore').value}}</td>
                        <td>{{evaluationParameters.get('certificationsScoreSelect').value}}</td>
                        <td>{{num}}</td>
                      </tr>
                    </table> -->

                  </div>
                  <!-- Column 2 -->
                  <div fxFlex="33%" fxFlex.xs="100%">
                    <div fxLayout="column">
                      <label for="average-score" class="form-label"
                        >Average Score Across Parameters</label
                      >
                      <input type="text" class="form-control"
                      id="average-score"
                      formControlName="averageScore"
                      placeholder="Average Score"
                      readonly
                      disabled
                      />
                    </div>

                    <div fxLayout="column" class="p-10">
                      <label for="sme-score" class="form-label"
                        >Overall SME score <span class="label-hint"> (Recalibration score)</span></label
                      >
                      <input type="number" class="form-control"
                      id="sme-score"
                      formControlName="smeScore"
                      placeholder="Overall SME Score"
                      autocomplete="off"
                      step="any"
                      min="1"
                      max="4"
                      />
                      <div *ngIf="finalEvaluation.get('smeScore').invalid && (finalEvaluation.get('smeScore').dirty || finalEvaluation.get('smeScore').touched)">
                        <span class="error-message" *ngIf="finalEvaluation.get('smeScore').errors?.invalidNumber">Invalid number. Please enter a number between 1 and 4.</span>
                      </div>
                    </div>

                    
                  </div>
                  <!-- Column 3 -->
                  <div fxFlex="33%" fxFlex.xs="100%">
                    <div fxLayout="column">
                      <label for="key-reasons" class="form-label"
                        >Key Reasons/Drivers for the Same<span
                          class="mandatory-field-indicator"
                        >
                          *
                        </span></label
                      >
                      <textarea
                        class="form-control"
                        id="key-reasons"
                        formControlName="keyReasons"
                        maxlength="500"
                        placeholder="Enter reasons"
                      ></textarea>
                    </div>

                    <div *ngIf="showScoreChangeReason" fxLayout="column">
                      <label for="key-reasons" class="form-label"
                        >Reason for providing Overall SME Score<span
                        class="mandatory-field-indicator"
                      >
                        *
                      </span></label
                      >
                      <textarea
                        class="form-control"
                        id="scoreChange-reasons"
                        formControlName="scoreChangeReasons"
                        maxlength="500"
                        placeholder="Enter reason for score update"
                      ></textarea>
                    </div>

                  </div>
                </div>
              </mat-card-content>

              <div
                fxLayout="row"
                fxLayoutAlign="end"
                class="navigation-buttons"
                fxLayoutGap="25px"
              >
                <button
                  mat-button
                  
                  (click)="prevStep(stepper)"
                >
                  Previous
                </button>
                <button
                  mat-button
                  [disabled]="isInProgress || isFormSubmitted || errorMessage"
                  [ngClass]="{ disabled: isInProgress || isFormSubmitted }"
                  [title]="errorMessage ? 'Fill in correct details to proceed' : ''"
                  (click)="save(false)"
                >
                  {{ saveLabel }}
                </button>
                <button
                  mat-button
                  [disabled]="isInProgress || isFormSubmitted"
                  [ngClass]="{ disabled: isInProgress || isFormSubmitted }"
                  (click)="submit(stepper, 'finalEvaluation')"
                >
                  {{ submitLabel }}
                </button>              
              </div>
              <span class="errorMsg" *ngIf="false && finalEvaluationErrorFlag">Please fill the mandatory fields <sup>*</sup></span>
            </mat-card>
          </form>
        </div>
      </div>
    </mat-step>


     <!-- Icon overrides. -->
  <!-- <ng-template matStepperIcon="one">
    <div (click)="resetSteps(stepper)">1</div>
   </ng-template>
   <ng-template matStepperIcon="two">
     <div (click)="resetSteps(stepper)">2</div>
   </ng-template>
   <ng-template matStepperIcon="three">
     <div (click)="resetSteps(stepper)">3</div>
   </ng-template>
   <ng-template matStepperIcon="four">
     <div (click)="resetSteps(stepper)">4</div>
   </ng-template>
   <ng-template matStepperIcon="five">
     <div (click)="resetSteps(stepper)">5</div>
   </ng-template> -->
  </mat-horizontal-stepper>
</div>


<!-- <mat-horizontal-stepper #stepper2>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Fill out your name</ng-template>
      <mat-form-field>
        <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
      </mat-form-field>
      <div>
        <button mat-button >Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Fill out your address</ng-template>
      <mat-form-field>
        <input matInput placeholder="Address" formControlName="secondCtrl" required>
      </mat-form-field>
      <div>
        <button mat-button >Back</button>
        <button mat-button >Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    You are now done.
    <div>
      <button mat-button >Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>


<mat-horizontal-stepper [linear]="false" #stepper1>
  <mat-step label="Step 1" state="one">
    <p>Put down your phones.</p>
    <div>
      <button mat-button >Next</button>
    </div>
  </mat-step>
  <mat-step label="Step 2" state="two">
    <p>Socialize with each other.</p>
    <div>
      <button mat-button >Back</button>
      <button mat-button >Next</button>
    </div>
  </mat-step>
  <mat-step label="Step 3" state="four">
    <p>You're welcome.</p>
  </mat-step> -->

  <!-- Icon overrides. -->
  <!-- <ng-template matStepperIcon="one">
   <div (click)="resetSteps(stepper1)">1</div>
  </ng-template>
  <ng-template matStepperIcon="two">
    <div (click)="resetSteps(stepper1)">2</div>
  </ng-template>
  <ng-template matStepperIcon="three">
    <div (click)="resetSteps(stepper1)">3</div>
  </ng-template>
  <ng-template matStepperIcon="four">
    <div (click)="resetSteps(stepper1)">4</div>
  </ng-template>
  <ng-template matStepperIcon="chat">
    <div (click)="resetSteps(stepper1)">5</div>
  </ng-template>

</mat-horizontal-stepper>  -->