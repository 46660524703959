import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaylistService } from './playlist.service';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { DirectivesModule } from '@YorbitWorkspace/directives';
import { LinkyModule } from 'angular-linky';

import { AccountContentCompletionConfirmationComponent } from './account-content-completion-confirmation/account-content-completion-confirmation.component';
import { QuizConfirmationPopupComponent } from './quiz-confirmation-popup/quiz-confirmation-popup.component';
import { PlaylistExternalCourseComponent } from './playlist-external-course/playlist-external-course.component';
import { PlaylistInternalCourseComponent } from './playlist-internal-course/playlist-internal-course.component';
import { PlaylistAccountCourseComponent } from './playlist-account-course/playlist-account-course.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { ICertificatePopupComponent } from './learners-workflow/i-certificate-popup/i-certificate-popup.component';
import { LearnersWorkflowComponent } from './learners-workflow/learners-workflow.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressBarModule,
    MatInputModule,

    FlexLayoutModule,
      ReusableUiModule,
      DirectivesModule,
      LinkyModule
  ],
  declarations: [
    AccountContentCompletionConfirmationComponent,
    QuizConfirmationPopupComponent,
    PlaylistInternalCourseComponent,
    PlaylistExternalCourseComponent,
    PlaylistAccountCourseComponent,
    ICertificatePopupComponent,
    LearnersWorkflowComponent
  ],
  exports: [
    PlaylistInternalCourseComponent,
    PlaylistExternalCourseComponent,
    PlaylistAccountCourseComponent
  ],
  providers: [PlaylistService]
})
export class PlaylistModule {}
