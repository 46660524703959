import { Component, OnInit, OnDestroy } from '@angular/core';
import { PssService } from './pss.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CertDateChangeAlertComponent } from './cert-date-change-alert/cert-date-change-alert.component';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterEvent
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscriber } from 'rxjs';
import { EditPopupComponent } from './edit-popup/edit-popup.component';
@Component({
  selector: 'yorbit-pss',
  templateUrl: './pss.component.html',
  styleUrls: ['./pss.component.scss']
})
export class PssComponent implements OnInit, OnDestroy {
  url: any;
  pssCompSub: any;

  groupList = [
    {
      name: 'Course Request',
      subGroup: []
    },
    {
      name: 'Certificate Request',
      subGroup: ['Pending', 'Actioned']
    },
    {
      name: 'External Certificate Request',
      subGroup: ['Pending', 'Actioned']
    },
    {
      name: 'Evaluation Request',
      subGroup: []
    },
    {
      name: 'Content Verification',
      subGroup: []
    },
    {
      name: 'Course Procedure Upload',
      subGroup: []
    }
  ];
  pssSelected: {
    group: string;
    subGroup: string;
  };

  constructor(
    private _pssService: PssService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.pssCompSub = {};
    //this.pssInit();
  }
  ngOnInit() {
    this.url = window.location.href;
    this.getPendingCertDateChangesStatus();
    this.subscribeToRouterEvent();
  }
  subscribeToRouterEvent() {
    this.pssCompSub.routerEventsSub = this.router.events
      .pipe(filter(events => events instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        this.url = event.url;
        //if navigation is from certification pending tab and
        //if certifcation tab have any unsaved data of certification date changes then show popup to alert user
      });
  }
  getPendingCertDateChangesStatus() {
    this.pssCompSub.pendingCertChangeSub = this._pssService
      .getPendingCertDateChangesStatus()
      .subscribe(response => {
        if (response.status) {
          //show popup
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'popupDialogContainer';
          //pssMessageDialogContainer
          dialogConfig.data = {};
          const popup = this.dialog.open(
            CertDateChangeAlertComponent,
            dialogConfig
          );
          this.pssCompSub.certDateChangePopupCloseSub1 = popup
            .afterClosed()
            .subscribe(data => {
              //status - if true then cleear unsaved data and continue navigation
              if (data) {
                this._pssService.certDateChangesList = [];
                this.router.navigate([response.url]);
              } else {
                //else stop navigation
              }
            });
        }
      });
  }
  selectGroup(group, subGroup) {
    //add condition check for any unsaved data
    if (
      this._pssService.certDateChangesList.length != 0 &&
      (group.name == 'Course Request' || subGroup == 'Actioned')
    ) {
      //show popup
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //pssMessageDialogContainer
      dialogConfig.data = {};
      const popup = this.dialog.open(
        CertDateChangeAlertComponent,
        dialogConfig
      );
      this.pssCompSub.certDateChangePopupCloseSub2 = popup
        .afterClosed()
        .subscribe(status => {
          //status - if true then cleear unsaved data and continue navigation
          if (status) {
            this._pssService.certDateChangesList = [];
          } else {
            //else stop navigation
          }
        });
    } else {
      this._pssService.certDateChangesList = [];
    }
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.pssCompSub) {
      let subscriber = this.pssCompSub[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  onCertificationPanelOpen(event) {
    //console.log(event);  
    if (this.url.indexOf('/certification') == -1) {
      this.router.navigate(['operations/certification/pending']);
    }
  }

  onExternalCertificationPanelOpen(event) {
    //console.log(event);  

    if (this.url.indexOf('externalCertificate') == -1) {
      this.router.navigate(['operations/externalCertificate/pending']);
    }
  }

  confirmChangeTab(event, type) {
    if (this.url.indexOf('content-verification') != -1 && this._pssService.courseApprovalPage) {
      //event.preventDefault();
      //show popup
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //pssMessageDialogContainer
      dialogConfig.data = {};
      const popup = this.dialog.open(EditPopupComponent, dialogConfig);
      this.pssCompSub.editPopupsub = popup.afterClosed().subscribe(status => {
        if (status) {
          this.redirectToUrl(type);
        } else {
        }
      });
    } else {
      this.redirectToUrl(type);
    }
  }
  redirectToUrl(type) {
    this._pssService.linkType = type;
    switch (type) {
      case 'course-requests':
        this.router.navigate(['operations/course-requests']);
        break;
      case 'pending':
        this.router.navigate(['operations/certification/pending']);
        break;
      case 'actioned':
        this.router.navigate(['operations/certification/actioned']);
        break;
      case 'evaluation-requests':
        this.router.navigate(['operations/evaluation-requests']);
        break;
      case 'content-verification':
        this.router.navigate(['operations/content-verification/opm']);
        break;
      case 'expending':
        this.router.navigate(['operations/externalCertificate/pending']);
        break;
      case 'exactioned':
        this.router.navigate(['operations/externalCertificate/actioned']);
        break;
      case 'course-procedure-upload':
        this.router.navigate(['operations/course-procedure-upload']);
        break;
      default:
        break;
    }
  }

  // selectGroup(group, subGroup) {
  //     ////console.log(group, subGroup);
  //     this.pssSelected.group = group.name
  //     this.pssSelected.subGroup = subGroup;
  //     if (0 !== group.subGroup.length && '' === subGroup) this.pssSelected.subGroup = group.subGroup[0];
  // }
}
