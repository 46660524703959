<div fxLayout="column" fxFlex="1 1 auto" [ngStyle.gt-xs]="{'padding':'2% 13%'}" fxLayoutAlign="start center" [ngClass]="'newCourseReqPage'">
  <!-- [ngClass.xs]="{ newCourseReqPagexs: true, newCourseReqPage: false }" -->
  <div [ngClass]="'newCourseReqTitleHolder'" [ngClass.lt-md]="'newCourseReqTitleHolderSmScreen'">
    <span [ngClass]="'newCourseReqTitle'" [ngClass.lt-md]="{
        newCourseReqTitle: false,
        newCourseReqTitleSmScreen: true
      }">
      New Course Request</span>
  </div>

  <div class="newCourseReqText" fxLayout="column" fxLayoutAlign="center start">
    <!-- [ngClass]="'newCourseReqText padding15'"
    [ngClass.lt-md]="{ padding15: false }" -->
    <div [ngClass]="'newCourseReqInfo'">
      Please fill the below details to request for a new course that is
      currently unavailable on SHOSHIN SCHOOL.
    </div>
    <div [ngClass]="'newCourseReqInfo'">
      On submission of request you will receive timely updates and notification
      regarding the status of the same.
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" *ngIf="taxonomyLoading">
    <app-preloader></app-preloader>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" *ngIf="!taxonomyLoading" style="width:100%">
    <div fxLayout="column" fxFlex.lt-md="100%" fxFlex>
      <div [ngClass]="'courseReqFormHeader setRightBorder'">
        Select the options below
      </div>
      <div [ngClass]="'courseReqFormFields'" [ngStyle.lt-md]="'border:none'">
        <div [ngClass]="'courseReqFormField'" fxLayout="row" fxLayoutAlign="start start">
          <div [ngClass]="'fieldLabel'">
            <label>Academy <span ngClass="mandatoryHighlight">*</span> </label>
          </div>
          <div [ngClass]="'fieldInput'">
            <select [(ngModel)]="selectedAcademy" [ngClass]="{ mouseDisabled: submitInProgress }" (change)="validateCourseRequestForm(); resetGenre()"
              [disabled]="submitInProgress">
              <option value="" disabled selected>Select an Academy</option>
              <option *ngFor="let academy of filterAcademy()" [value]="academy">{{ academy }}</option>
            </select>
            <div [ngClass]="'no-results-msg-course-req'">
              <span *ngIf="isDataLoading">
                Loading...
              </span>
              <!-- <span ngClass="textHighlight" *ngIf="noResultsForAcademy">No results found</span> -->
            </div>
          </div>
        </div>
        <div [ngClass]="'courseReqFormField'" fxLayout="row" fxLayoutAlign="start start">
          <div [ngClass]="'fieldLabel'">
            <label>Genre <span [ngClass]="'mandatoryHighlight'">*</span>
            </label>
          </div>
          <div [ngClass]="'fieldInput'">
            <div [ngClass]="'course-req-dropdown'" id="genre-dropdown"></div>
            <select [(ngModel)]="selectedGenre" [ngClass]="{
                mouseDisabled: selectedAcademy == '' || submitInProgress
              }"
              (change)="validateCourseRequestForm(); resetSkill()" [disabled]="selectedAcademy == '' || submitInProgress"
              title="{{
                selectedAcademy == '' ? 'Please Choose Academy' : ''
              }}">
              <option value="" disabled selected>Select a Genre</option>
              <option *ngFor="let genre of filterGenre()" [value]="genre">{{
                genre
                }}</option>
            </select>
            <div [ngClass]="'no-results-msg-course-req'">
              <span *ngIf="isDataLoading">Loading...</span>
              <!-- <span ngClass="textHighlight" *ngIf="noResultsForGenre">No results found</span> -->
            </div>
          </div>
        </div>
        <div [ngClass]="'courseReqFormField'" fxLayout="row" fxLayoutAlign="start start">
          <div [ngClass]="'fieldLabel'">
            <label>Skill <span ngClass="mandatoryHighlight">*</span> </label>
          </div>
          <div [ngClass]="'fieldInput'">
            <select [(ngModel)]="selectedSkill" [ngClass]="{
                mouseDisabled:
                  skillNotFound || selectedGenre == '' || submitInProgress
              }"
              (change)="validateCourseRequestForm()" [disabled]="
                skillNotFound || selectedGenre == '' || submitInProgress
              "
              title="{{
                skillNotFound
                  ? 'Not allowed'
                  : selectedGenre == ''
                  ? 'Please Choose Genre'
                  : ''
              }}">
              <option value="" disabled selected>Select a Skill</option>
              <option *ngFor="let skill of filterSkill()" [value]="skill">{{
                skill
                }}</option>
            </select>
            <div ngClass="no-results-msg-course-req">
              <span *ngIf="isDataLoading">Loading...</span>
            </div>
          </div>
        </div>
        <div ngClass="courseReqFormField" fxLayout="row" fxLayoutAlign="end start">
          <div ngClass="fieldInput">
            <span ngClass="floatRight skill-not-found-text">
              <label for="no-skill-box">Can't find the skill ?</label>
            </span>
            <span ngClass="floatRight">
              <input type="checkbox" id="no-skill-box" [(ngModel)]="skillNotFound" [disabled]="submitInProgress"
                (change)="resetSkill(); validateCourseRequestForm()" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex.lt-md="100%" fxFlex>
      <div ngClass="courseReqFormHeader">
        Enter details
      </div>
      <div [ngClass]="'courseReqFormFields'" [ngStyle.lt-md]="'border:none'" [ngStyle.gt-sm]="'border-right:1px solid #8a91a3'">
        <div [ngClass]="'courseReqFormField'" style="height:70px !important" fxLayout="row" fxLayoutAlign="start start">
          <div [ngClass]="'fieldLabel'">
            <label [ngClass]="'desc-height'">Description <span [ngClass]="'mandatoryHighlight'">*</span>
            </label>
          </div>
          <div [ngClass]="'fieldInput'">
            <textarea style="resize:none" [(ngModel)]="description" (keyup)="validateCourseRequestForm()" [disabled]="submitInProgress"
              [ngClass]="{ mouseDisabled: submitInProgress }" placeholder="Enter Course Title/ Course Description/ Reference Links etc."></textarea>
          </div>
        </div>
        <div [ngClass]="'courseReqFormField'" fxLayout="row" fxLayoutAlign="start start">
          <div [ngClass]="'fieldLabel'">
            <label>Reason <span ngClass="mandatoryHighlight">*</span> </label>
          </div>
          <div [ngClass]="'fieldInput'">
            <select [(ngModel)]="selectedReason" [ngClass]="{ mouseDisabled: submitInProgress }" (change)="validateCourseRequestForm()"
              [disabled]="submitInProgress">
              <option value="" disabled selected>Select a reason</option>
              <option *ngFor="let reason of reasons" [value]="reason">{{
                reason
                }}</option>
            </select>
          </div>
        </div>
        <div [ngClass]="'courseReqFormField'" style="height:40px !important" fxLayout="row" fxLayoutAlign="end start">
          <div [ngClass]="'fieldInput'">
            <button *ngIf="!submitInProgress" (click)="submitCourseRequest()" [disabled]="!isFormValid" title="{{ !isFormValid ? 'Please Enter All Mandatory Fields' : 'Click To Submit Request' }}"
              [ngClass]="{
                buttonActive: isFormValid,
                buttonDisabled: !isFormValid
              }">
              Submit
            </button>
            <button *ngIf="submitInProgress" [disabled]="true" [ngClass]="'buttonDisabled'">
              In Progress...
            </button>
          </div>
        </div>
        <div ngClass="courseReqFormField" style="height:15px" fxLayout="row" fxLayoutAlign="end start">
          <div ngClass="mandatoryHighlight" style="text-align: right;">
            <span>*</span> - Mandatory fields
          </div>
        </div>
      </div>
    </div>
  </div>
</div>