import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-content-upload101-popups',
  templateUrl: './content-upload101-popups.component.html',
  styleUrls: ['./content-upload101-popups.component.scss']
})
export class ContentUpload101PopupsComponent implements OnInit {

  description: string = '';
  showErrorFields: boolean = false;
  showUnitsInfo: boolean = false;
  isConfirmation: boolean;
  showStackAndSkillInfo: boolean = false;

  constructor(public dialogRef: MatDialogRef<ContentUpload101PopupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.isConfirmation = false;
    this.showUnitsInfo = false;
    this.showErrorFields = false;
    if (this.data == 'Confirmation') {
      this.isConfirmation = true;
      this.description = 'Do you want to proceed ?';
    }
    else if (this.data == 'QuizSubmitMessage') {
      this.isConfirmation = false;
      this.description = 'Content submitted successfully for verification';
    }
    else if (this.data == 'OnlyQuizSubmitMessage') {
      this.isConfirmation = false;
      this.description = 'Quiz data updated successfully';
    }
    else if (this.data == 'QuizSaveMessage') {
      this.isConfirmation = false;
      this.description = 'Quiz data saved successfully';
    }
    else if (this.data == 'SourceLinkClick') {
      this.isConfirmation = false;
      this.description = 'Please click on \'VALIDATE LINKS\' button before saving the data';
    }
    else if (this.data == 'SourceLinkInvalid') {
      this.isConfirmation = false;
      this.description = 'One or more source link is invalid. Please validate all the source links.';
    }
    else if (this.data == 'UnitsDataInvalid') {
      this.isConfirmation = false;
      this.description = 'One or more field is invalid. Please validate all the inputs.';
    }
    else if (this.data == 'ContentMessage') {
      this.isConfirmation = false;
      this.description = 'Content submitted successfully for verification.';
    }
    else if (this.data == 'ContentSaveMessage') {
      this.isConfirmation = false;
      this.description = 'Content saved successfully';
    }
    else if (this.data == 'UnitsAndTopicsInformation') {
      this.isConfirmation = false;
      this.showUnitsInfo = true;
    }
    else if (this.data == 'UnitsMessage') {
      this.isConfirmation = false;
      this.description = 'Units data saved successfully';
    }
    else if(this.data == 'ContentMessageFail') {
      this.isConfirmation = false;
      this.description = 'Content failed to upload'
    }
    else if(this.data == 'ContentExists') {
      this.isConfirmation = false;
      this.description = 'Content exists on shoshin school'
    }
    else if(this.data == 'PackageSuccess') {
      this.isConfirmation = false;
      this.showErrorFields=false;
      this.description = "Content submitted successfully for verification."
    }
    else if(this.data == 'VerificationFail') {
      this.isConfirmation = false;
      this.showErrorFields=false;
      this.description = "Verification failed! Please try again."
    }
    else if(this.data == 'VerificationSuccess') {
      this.isConfirmation = false;
      this.showErrorFields=false;
      this.description = "The content has been verified and successfully launched on Shoshin School"
    }
    else if(this.data == 'PackageFail') {
      this.isConfirmation = false;
      this.showErrorFields=false;
      this.description = "Package upload failed!";
    }
    else if(this.data == 'StackAndSkillInfo') {
      this.isConfirmation = false;
      this.showStackAndSkillInfo = true;
    }
    else {
      this.isConfirmation = false;
      this.showErrorFields = true;
    }
  }

  onClick(value) {
    this.dialogRef.close(value);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
