import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LearningPathComponent } from '../learning-path.component';
import { LPDetailedComponent } from '../lp-detailed/lp-detailed.component';
import { LPPackageViewComponent } from '../lp-package-view/lp-package-view.component';
import { LPCourseViewComponent } from '../lp-course-view/lp-course-view.component';
import { YoutubeVideoPlayerComponent } from '../../shared/video-players/youtube-video-player/youtube-video-player.component';
import { O365VideoPlayerComponent } from '../../shared/video-players/o365-video-player/o365-video-player.component';
import {LpCourseDescriptionComponent} from '../lp-course-view/lp-course-description/lp-course-description.component';
import { DiversityAndInclusionComponent } from '../../diversity-and-inclusion/diversity-and-inclusion.component';
import { ScormPlayerComponent } from '../../shared/video-players/scorm-player/scorm-player.component';
import { OtherContentsComponent } from '../../shared/video-players/other-contents/other-contents.component';
const routes: Routes = [
  {
    path: '',
    component: LearningPathComponent,
    children: [
      {
        path: 'category/:category/id/:lpId',
        component: LPDetailedComponent,
        data: {
          title: 'LearningPath Content'
        }
      },
      {
        path: 'category/:category/id/:lpId/package/:packageId',
        component: LPPackageViewComponent,
        data: {
          title: 'Package view'
        }
      },
      {
        path:'category/:category/id/:lpId/package/:packageId/course/:courseId/account/:accountPackage/project/:projectPackage/accountId/:accountId/projectId/:projectId/tabs/:tab',
        component: LPCourseViewComponent,
        data: {
          title: 'Course view'
        },
        children: [
          {
            path: 'youtube/videoId/:videoId',
            component: YoutubeVideoPlayerComponent,
            data: {
              title: 'Youtube comp'
            }
          },
          {
            path: 'o365/videoId/:videoId',
            component: O365VideoPlayerComponent,
            data: {
              title: 'O365 comp'
            }
          },
          {
            path: 'scorm/videoId/:videoId',
            component: ScormPlayerComponent,
            data: {
              title: 'Scorm Player'
            }
          },
          {
            path: 'others/videoId/:videoId',
            component: OtherContentsComponent,
            data: {
              title: 'Other Types'
            }
          },
          {
            path:'type/external',
            component:LpCourseDescriptionComponent,
            data:{
              title:'External course description'
            }
          }
        ]
      },
      {
        path: 'behavioral-assessment',
        loadChildren: () =>
          import('../behavioral-assessment/behavioral-assessment.module').then(
            m => m.BehavioralAssessmentModule
          ),
        data: { title: 'Behavioral Asessment' }
      }
    ]
  }

];
@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports:[RouterModule]
})
export class LearningPathRoutingModule {}
