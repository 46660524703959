<div
  fxLayout="column"
  fxLayoutAlign="center center"
  [ngStyle.gt-xs]="{ padding: '2% 10%' }"
  class="feedback"
  flex="1 1 auto"
>
  <div class="feedback-header" fxLayoutAlign="start center" flex="1 1 auto">
    Feedback / Contact Us
  </div>
  <div class="feedbackNote">{{feedbackNote}}</div>
  <div class="feedbackNoteExtend">{{feedbackNoteExtend}}</div>

  <div class="feedback-content" fxLayoutAlign="center center" flex="1 1 auto">
    <textarea placeholder="Write your feedback here"  class="feedbackTextarea" [(ngModel)]="feedback"></textarea>
  </div>
  <div class="submit-btn" fxLayoutAlign="center center" flex="1 1 auto" *ngIf="!sendingFeedback">
    <button (click)="callFeedbackService(feedback)" [disabled]="feedback == '' || sendingFeedback"
    [ngClass]="{'disabled':feedback == '' || sendingFeedback}"
    title="{{feedback == ''?'Please give your feedback':''}}">Send
    </button>
  </div>
</div>
