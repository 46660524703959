import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subscription, interval } from 'rxjs';
import { IuserDetails } from '../../../user-details/store/user-details.interface';
import { COMMA, ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { AssignContentComponent } from '../assign-content.component';
import { Store } from '@ngrx/store';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { AssignContentService } from '../assign-content.service';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';
import * as fromUserDetailsStore from '../../../user-details/store';
import { FormControl } from '@angular/forms';
import { ArrayPropertyFilterPipe } from '@YorbitWorkspace/pipes';
import { IntentListService } from '../../../services/Intent-list.service';
import { CdmService } from 'apps/yorbit-ui/src/app/cdm/cdm.service';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '../../../services/window.service';
import { RoleAccessService } from '@YorbitWorkspace/role-access';
import { FileSaverService } from 'ngx-filesaver';
import { Globals } from 'apps/yorbit-ui/src/app/globals';
import * as XLSX from 'xlsx';


@Component({
  selector: 'yorbit-assign-content-popup',
  templateUrl: './assign-content-popup.component.html',
  styleUrls: ['./assign-content-popup.component.scss']
})
export class AssignContentPopupComponent implements OnInit {
  userDetails$: Observable<IuserDetails>;
  userId: string;
  CDMDepartments: any[];
  PPRoles: any[];
  assignAsRole: {
    Value: string;
    Label: string;
  };
  userRoleAccess$: any;
  failedMIDs: any[];
  assignButton: boolean;
  userDetailsLoaded$: Observable<boolean>;
  userRoleAccessLoaded$: Observable<boolean>;
  rolesLoaded: boolean;
  mindsList = [];
  learnerMids: any[];
  lpAcmPcmMid=[];
  readonly separatorKeysCodes: number[] = [COMMA, ENTER, SEMICOLON, SPACE];
  midListInput: string;
  apiCallTimer: Subscription;
  totalCall: any;
  successCall: any;
  errorCall: any;
  unit: any;
  noTechAcademyCDM: any;
  requestSent: boolean;
  costConversionLink =
    'https://peoplehubcontent.mindtree.com/sites/Finance/Pages/Exchange%20Rates/2013-2014.aspx';
  NoteMessageForAssign: string;
  successMIDs: any;
  showPopupPreloader: boolean = false;
  PPCheck: boolean;
  assignDetails: any;
  courseName: any;
  specialIntentGroup: {
    value: string;
    viewValue: string;
  }[];
  cdmIntent: {
    viewValue: string;
    value: string;
  };
  isMindBetweenProject: boolean;
  expertise: any;
  assignInProgress: boolean;
  midListForPush: any[];
  selectRole: string;
  IntentList: {
    IntentId: number;
    IntentName: string;
    LearnerIntentDescription: string;
    ApproverIntentDescription: string;
  }[] = [];
  popupRefresh: string;
  showPreloader: boolean;
  intentId: number;
  selectedIntent: {
    IntentId: number;
    IntentName: string;
    LearnerIntentDescription: string;
    ApproverIntentDescription: string;
  };
  intentComments = '';
  tooltip = 'Please select assignment intent.';
  tooltip2 = 'Please enter comments.';
  intentMode: boolean;
  showerrmsg: boolean;
  errorMsg = 'You can assign course to 25 associates at a time.';
  errorMsgonPaste = ' Only the first 25 values have been pasted.';
  MidValidation: boolean;
  assignedData: any;
  btnName: string;
  VerificationPreloader: boolean;

  suggestionList: any;
  searchBy: string='';
  showSuggestionsList: boolean;
  showSearchIcon: boolean=true;
  isBulkAssignmentOpened = false;
  actionMessage: string;
  disableProceed: boolean;
  fileToBeUploaded: FormData;
  fileName: any;
  templateDownloadInProgress: boolean;
  excelUploadInProgress: boolean;
  isUploadButtonDisabled: boolean = true;
  storeData: string | ArrayBuffer;
  secondSheetName: string;
  isUploadSuccess: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AssignContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    private assignContentService: AssignContentService,
    private graphDataService: GraphDataService,
    private intentlistService: IntentListService,
    private cdmService: CdmService,
    private sanitizer: DomSanitizer,
    private _roleService:RoleAccessService,
    private _FileSaverService: FileSaverService,
    private globals: Globals,
    @Inject(WINDOW) private _window: any
  ) { }

  ngOnInit() {
    this.firstLoadInit();
    this.showPreloader = true;
    this.intentMode = true;
    this.MidValidation = false;
    this.showerrmsg = false;
    this.assignInProgress = false;
    this.btnName = 'VERIFY';
    this.VerificationPreloader = true;
    this.fileName = 'Select a File';
    this.templateDownloadInProgress = false;
    this.excelUploadInProgress = false;
    this.isUploadButtonDisabled = true;

    this.popupRefresh = localStorage.getItem('PopupRefresh');
    if (this.popupRefresh != null && this.popupRefresh != undefined) {
      localStorage.removeItem('PopupRefresh');
      this.closeAssign();
    } else {
      this.getLearningIntentList();
    }
  }

  firstLoadInit() {
    // this.assignInProgress = false;
    this.rolesLoaded = false;
    this.PPRoles = [];
    this.learnerMids = [];
    this.midListInput = '';
    if (!this.data.Name) {
      this.courseName = this.data.ItemName;
    } else {
      this.courseName = this.data.Name;
    }

    if (!this.data.Expertise) {
      this.expertise = this.data.ItemExpertise;
    } else {
      this.expertise = this.data.Expertise;
    }

    this.isMindBetweenProject = false;
    this.selectedIntent = {
      IntentId: 0,
      IntentName: 'Select Assignment Intent',
      LearnerIntentDescription: '',
      ApproverIntentDescription: ''
    };
    this.specialIntentGroup = [
      {
        value: '',
        viewValue: 'None'
      },
      {
        value: 'course already completed',
        viewValue: 'Course already completed'
      },
      {
        value: 'learner has maximum courses in progress',
        viewValue: 'Learner has maximum courses in-progress'
      }
    ];
    if (
      '101' !== this.expertise &&
      !(
        'package' === this.data.ItemType.toLowerCase() ||
        'familypackage' === this.data.ItemType.toLowerCase()
      )
    ) {
    }
    this.cdmIntent = {
      viewValue: 'None',
      value: ''
    };
    this.userDetails$ = this.userDetailsStore.select(
      fromUserDetailsStore.getUserDetailObject
    );

    this.userDetails$.subscribe(userData => {
      this.userId = userData.id.toUpperCase();
      this.CDMDepartments = [];
      if (userData.CDMDepartments == null) {
        userData.CDMDepartments = [];
      }
      userData.CDMDepartments.forEach(element => {
        this.CDMDepartments.push(element.toUpperCase());
      });

      this.userRoleAccess$ = this.userRoleAccessStore.select(
        fromRoleAccessStore.getRoleAccessList
      );

      // this.userRoleAccessStore.dispatch(
      //   new fromRoleAccessStore.RoleGetAccessList()
      // );

      this.userRoleAccess$.subscribe(userRole => {
        if (
          this.data.AccountPackage === true ||
          this.data.ProjectPackage === true ||
          this.data.AccountPackage === 'true' ||
          this.data.ProjectPackage === 'true'
        ) {
        } else if (userRole.CDM) {
          this.PPRoles.push({
            Value: 'CDM',
            Label: 'Learning Business Partner'
          });
        }
        //update acm/pcm role
        if (userRole.ACM && this.data.availableRoles.ACM) {
          this.PPRoles.push({
            Value: 'ACM',
            Label: 'Account Learning Manager'
          });
        }
        if (userRole.PCM && this.data.availableRoles.PCM) {
          this.PPRoles.push({
            Value: 'PCM',
            Label: 'Project Learning Manager'
          });
        }

        if (
          this.data.AccountPackage === true ||
          this.data.ProjectPackage === true ||
          this.data.AccountPackage === 'true' ||
          this.data.ProjectPackage === 'true'
        ) {
        } else if(userRole.GLOBALCDM){
          this.PPRoles.push({
            Value: 'GLOBALCDM',
            Label: 'Global Learning Partner'
          });

          //Get All Learning Partener,ACM and PCM
          this.getLpAcmAndPCm();
        }

        this.assignAsRole = this.PPRoles[0];
        this.rolesLoaded = true;
      });
    });
  }

  addLearner(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.learnerMids.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
      this.midListInput = '';
    }
  }
  addLearnerOnPaste(event) {
    event.preventDefault();
    let clipboardData = '';
    if (event.clipboardData) {
      clipboardData = event.clipboardData.getData('Text');
    } else {
      clipboardData = window['clipboardData'].getData('text');
    }
    const learnerList = clipboardData
      .replace(/\n|\t|" "/g, ',')
      .replace(/(\r\n|\n|\r)/gm, '')
      .split(/,|;| /)
      .filter(mid => mid);
    learnerList.filter((mid, i) => learnerList.indexOf(mid) === i);
    this.learnerMids = [...this.learnerMids, ...learnerList];
    if (this.learnerMids && this.learnerMids.length > 25) {
      //this.learnerMids.length = 25;
      this.learnerMids=this.learnerMids.slice(0,25)
      this.showerrmsg = true;
      setTimeout(() => {
        this.showerrmsg = false;
      }, 7000);
    }
  }

  removeLearner(learner) {
    const index = this.learnerMids.indexOf(learner);

    if (index >= 0) {
      this.learnerMids.splice(index, 1);
    }
  }

  MidVerification() {
    this.assignInProgress = false;
    this.failedMIDs = [];
    this.assignButton = false;
    this.midListForPush = [];
    const checklist = [];
    this.MidValidation = true;
    this.btnName = 'ASSIGN';
    let graphRequestsForDepartmentAndName = [];
    let learnerDetails = [];
    (this.totalCall = 0), (this.successCall = 0), (this.errorCall = 0);
    this.VerificationPreloader = true;
    let index = 0;

    /**
     *
     const regExMid = new RegExp(/^(([Mm]{1}[0-9]{7}\s{0})+)$/, 'g');
     const regExNonMid = new RegExp(/^(?:(?!:[Mm]{1}[0-9]{7}\s{0})+)$/, 'g');

    let  midList= this.learnerMids.filter(id=> regExMid.test(id));
    let  nonMidList= this.learnerMids.filter(id=> !regExMid.test(id));

    console.log("*************************************")
    console.log("mid",midList)
    console.log("nonmid",nonMidList)
    */

   // Interoperability Temp implementation Starts
   this.learnerMids = [...new Set(this.learnerMids)];
     this.graphDataService.getDetailsFromCapacityReportBatchCall(this.learnerMids).toPromise().then(response => {

       let learnerList = [];
       graphRequestsForDepartmentAndName = [];

       learnerList=this.learnerMids;

       let selfId=this.learnerMids.indexOf(this.userId);

       for (let i = 0; i < response.length; i++) {

         if (i!=selfId) {
           if (this.assignAsRole.Value === 'CDM') {
             this.noTechAcademyCDM = false;
             if (this.CDMDepartments.indexOf(response[i].department.toUpperCase()) !== -1) {
               this.midListForPush.push({
                 mid: this.learnerMids[i].trim().toUpperCase(),
                 name: response[i].displayName
               });
              } else {
                this.failedMIDs.push({
                  mid: this.learnerMids[i].trim().toUpperCase(),
                  name: response[i].displayName,
                  reason: 'Does not come under your department'
                });
              }
            } else if (
              this.assignAsRole.Value === 'ACM' ||
              this.assignAsRole.Value === 'PCM' ||
              this.assignAsRole.Value ==='GLOBALCDM'
            ) {            
              if(response[i].givenName != ""){
                this.midListForPush.push({
                  mid: this.learnerMids[i].trim().toUpperCase(),
                  name:response[i].displayName
                });
              } else {
                this.failedMIDs.push({
                  mid: this.learnerMids[i].trim().toUpperCase(),
                  name:response[i].displayName,
                  reason: 'Associates PSID does not Exist/Invalid'
                });
              }         
            }
          } else {
            this.failedMIDs.push({
              mid: this.learnerMids[i].trim().toUpperCase(),
              name: response[i].displayName,
              reason: 'Cannot assign to yourself'
            });
          }
        }

        this.repeatTillSuccess();

        })//End call


    // Interoperability Temp implementation Ends


    // Revert after Merged Graph update Start


    // let numberOfIteration = (Math.floor(midList.length / 20)) + 1;

    // for (let m = 0; m < numberOfIteration; m++) {
    //   let learnerList = [];
    //   graphRequestsForDepartmentAndName = [];
    //   learnerList = midList.slice(m * 20, (m * 20) + 20);

    //   learnerList.forEach((element, j) => {
    //     const number = j;
    //     if (
    //       learnerList[number] !== '' &&
    //       checklist.indexOf(learnerList[number].trim().toUpperCase()) === -1
    //     ) {
    //       checklist.push(learnerList[number].trim().toUpperCase());

    //       learnerDetails.push({
    //         id: index + 1,
    //         mid: learnerList[number].trim().toUpperCase(),
    //         name: '',
    //         department: ''
    //       })
    //       graphRequestsForDepartmentAndName.push({
    //         id: index + 1,
    //         method: 'GET',
    //         url:
    //           '/users/' +
    //           learnerList[number].trim().toUpperCase() +
    //           '@mindtree.com/?$select=displayName,department'
    //       });
    //     };
    //     index++;
    //   });
    //   let payload = {
    //     requests: graphRequestsForDepartmentAndName,
    //     headers: {
    //       "Content-Type": "application/json; odata=minimalmetadata; streaming=true; charset=utf-8",
    //       "Accept": "application/json, text/plain, */*"
    //     }
    //   };
    //   this.graphDataService.batchRequestsForDepartmentAndName(payload)
    //     .then(response => {
    //       for (let i = 0; i < response.responses.length; i++) {
    //         let k = learnerDetails.indexOf(learnerDetails.find(x => x.id == response.responses[i].id));
    //         learnerDetails[k].department = response.responses[i].body.department;
    //         learnerDetails[k].name = response.responses[i].body.displayName;
    //         if (response.responses[i].status == '404') {
    //           this.failedMIDs.push({
    //             mid: learnerDetails[k].mid.trim().toUpperCase(),
    //             name: 'NA',
    //             reason: 'Invalid user id'
    //           });
    //           continue;
    //         }
    //         if (this.userId !== learnerDetails[k].mid.trim().toUpperCase() && response.responses[i].status == '200') {
    //           this.totalCall++;
    //           if (this.assignAsRole.Value === 'CDM') {
    //             this.noTechAcademyCDM = false;
    //             if (this.CDMDepartments.indexOf(learnerDetails[k].department.toUpperCase()) !== -1) {
    //               this.midListForPush.push({
    //                 mid: learnerDetails[k].mid.trim().toUpperCase(),
    //                 name: learnerDetails[k].name
    //               });
    //             } else {
    //               this.failedMIDs.push({
    //                 mid: learnerDetails[k].mid.trim().toUpperCase(),
    //                 name: learnerDetails[k].name,
    //                 reason: 'Does not come under your department'
    //               });
    //             }
    //           } else if (
    //             this.assignAsRole.Value === 'ACM' ||
    //             this.assignAsRole.Value === 'PCM' ||
    //             this.assignAsRole.Value ==='GLOBALCDM'
    //           ) {
    //             this.midListForPush.push({
    //               mid: learnerDetails[k].mid.trim().toUpperCase(),
    //               name: learnerDetails[k].name
    //             });
    //           }
    //         } else {
    //           this.failedMIDs.push({
    //             mid: learnerDetails[k].mid.trim().toUpperCase(),
    //             name: learnerDetails[k].name,
    //             reason: 'Cannot assign to yourself'
    //           });
    //         }
    //       }
    //     });
    // }
    // this.repeatTillSuccess();

    // Revert after Merged Graph update Ends


  }
  Block301AssignmentByOtherCDM(): any {
    throw new Error('Method not implemented.');
  }
  repeatTillSuccess() {
    this.apiCallTimer = interval(1000)
      .pipe()
      .subscribe(() => {
        this.VerificationPreloader = false;
        let id, duration, role;
        if (
          this.data.AccountPackage === true ||
          this.data.ProjectPackage === true ||
          this.data.AccountPackage === 'true' ||
          this.data.ProjectPackage === 'true'
        ) {
          //For assignment from browse and detail page, use UniqueId ; assignment from lp assigned tab, use ItemId
          if (!!this.data.ItemId) {
            id = this.data.ItemId;
          } else if (!!this.data.UniqueId) {
            id = this.data.UniqueId;
          } else {
            id = this.data.Id;
          }
        } else {
          if (!this.data.Id) {
            id = this.data.ItemId;
          } else {
            id = this.data.Id;
          }
        }
        if (!this.data.Duration) {
          duration = this.data.ItemDuration;
        } else {
          duration = this.data.Duration;
        }
        // let assignData;
        //if (this.assignAsRole == "ACM" || this.assignAsRole == "PCM") {
        if (
          this.data.AccountPackage === true ||
          this.data.ProjectPackage === true ||
          this.data.AccountPackage === 'true' ||
          this.data.ProjectPackage === 'true'
        ) {
          this.assignedData = {
            UserIds: this.midListForPush.map(function (a) { return a.mid; }),
            itemId: id,
            itemType: this.data.ItemType,
            itemName: this.courseName,
            itemExpertise: this.expertise,
            duration: duration,
            accountId: this.data.Account,
            accountName: this.data.Account,
            projectId: this.data.Project,
            projectName: this.data.Project,
            isAccount: this.data.AccountPackage,
            dueDate: null
          };
          this.PPCheck = true;
        } else {
          this.assignedData = {
            UserIds: this.midListForPush.map(function (a) { return a.mid; }),
            itemId: id,
            itemType: this.data.ItemType,
            itemName: this.courseName,
            itemExpertise: this.expertise,
            duration: duration,
            DueDate: null
          };
          this.PPCheck = false;
        }
        this.assignDetails = this.assignedData;
        role = this.assignAsRole.Value;
        // sendCdmPushData();
        this.assignButton = true;
        //$( AssignPopUp ).trigger( "hide" );
        // if 301 course is assigned by some other department except Tech Academy
        if (this.noTechAcademyCDM) {
          this.Block301AssignmentByOtherCDM();
        } else {
          //this.sendCdmPushData(assignData,PPCheck,role)
          this.cdmPushValidation(this.assignedData);
          //$state.go("NoAutoCloseModal.popupTmpl", { selectedContext: "cdmPushValidation", data: { 'assignData': assignData, 'validation': true, 'failedMIDs': this.failedMIDs, 'isProjectPackage': PPCheck, 'assignerRole': role } });
        }
        if (!!this.apiCallTimer) {
          this.apiCallTimer.unsubscribe();
        }
      });
    this.showPreloader = true;
  }

  cdmPushValidation(assignData) {
    this.requestSent = false;
    const coursesList = [];
    //this.showPopupPreloader = true;
    switch (this.assignAsRole.Value) {
      case 'CDM':
        if (assignData.itemType === 'Course') {
          this.NoteMessageForAssign =
            'Note: Assigned courses by Learning Business Partners are approved';
        } else if (assignData.itemType === 'FamilyPackage') {
          this.NoteMessageForAssign =
            'Note: Assigned courses by Learning Business Partners are pre-approved, Learner can request for schedule';
        }
        break;
      case 'ACM':
      case 'PCM':
        if (assignData.itemType === 'Course') {
          this.NoteMessageForAssign =
            'Note: Assigned 201/301 courses by ACM/PCM may require approval by Learning Business Parter/RM';
        } else if (assignData.itemType === 'FamilyPackage') {
          this.NoteMessageForAssign =
            'Note: 201 courses in assigned package by ACM/PCM may require approval by Learning Business Partner/RM';
        }
        break;
      default:
        this.NoteMessageForAssign = '';
    }
    if (
      assignData.itemExpertise === '201' ||
      assignData.itemType === 'FamilyPackage'
    ) {
      const checkAndUpdate201List = function (response) {
        if (assignData.itemType === 'Course') {
          if (response.Price === '') {
            response.Price = 'Cost not available';
          }
          coursesList.push(response);
          this.footerMessage =
            'If you want to check latest conversion rates, click ';
          this.showPopupPreloader = false;
        } else if (assignData.itemType === 'FamilyPackage') {
          const update201CourseList = function (courses) {
            if (!!courses) {
              courses.forEach((course, key) => {
                if (course.Course.Expertise === '201') {
                  if (
                    course.Course.Price === '' ||
                    course.Course.Price == null
                  ) {
                    course.Course.Price = 'Cost not available';
                  }
                  coursesList.push(course.Course);
                }
              });
              if (coursesList.length !== 0) {
                this.footerMessage =
                  'If you want to check latest conversion rates, click ';
              }
            }
          };
          if (
            this.data.isProjectPackage === 'true' ||
            this.data.isProjectPackage === true
          ) {
            this.assignContentService
              .getContentOfPackage_PP(assignData)
              .then(getPPresponse => {
                update201CourseList(getPPresponse.data.Courses);
                this.showPopupPreloader = false;
              })
              .catch(function (error) {
                this.showPopupPreloader = false;
              });
          } else {
            this.assignContentService
              .getContentInsideFamilyPackage(assignData.itemId)
              .then(function (FPresponse) {
                update201CourseList(FPresponse.data.Courses);
                this.showPopupPreloader = false;
              })
              .catch(function (error) {
                this.showPopupPreloader = false;
              });
          }
        }
      };
      if (
        this.data.isProjectPackage === 'true' ||
        this.data.isProjectPackage === true
      ) {
        const payload = {
          ItemId: assignData.itemId,
          AccountPackage: assignData.accountName,
          ProjectPackage: assignData.projectName,
          AccountId: assignData.accountId,
          ProjectId: assignData.projectId
        };
        this.assignContentService
          .getProjectPackageDetails(payload, assignData.itemType)
          .then(function (response) {
            checkAndUpdate201List(response);
          });
      } else {
        this.assignContentService
          .getPackageDetails(assignData.itemId, assignData.itemType)
          .then(function (response) {
            checkAndUpdate201List(response);
          })
          .catch(function (error) {
            this.showPopupPreloader = false;
          });
      }
    } else {
      this.showPopupPreloader = false;
    }
    if ('CDM' === this.assignAsRole.Value || this.assignAsRole.Value=='GLOBALCDM') {
      assignData.CDMIntentOfAssigning = this.cdmIntent.value;
      assignData.IsAssigneeOnBench = this.isMindBetweenProject;
    }
    // this.dialogRef.close({
    //   event: 'push',
    //   data: {
    //     assignData: assignData,
    //     failedMIDs: this.failedMIDs,
    //     PPCheck: this.PPCheck,
    //     assignAsRole: this.assignAsRole,
    //     NoteMessageForAssign: this.NoteMessageForAssign
    //   }
    // });
    this.showPreloader = false;
  }

  sendAssignedData() {
    const assignData = this.assignedData;
    assignData.IntentId = this.intentId;

    assignData.IntentDescription =
      this.intentComments == '' ? '' : this.intentComments;

    const isPP = this.PPCheck;
    const assignAs = this.assignAsRole.Value;
    let isProjectPackage;

    if (null != assignData.UserIds) {
      if (this.data.AccountPackage != null) {
        if (
          this.data.AccountPackage === true ||
          this.data.ProjectPackage === true ||
          this.data.AccountPackage === 'true' ||
          this.data.ProjectPackage === 'true'
        ) {
          isProjectPackage = true;
        } else {
          isProjectPackage = false;
        }
      } else {
        isProjectPackage = false;
      }

      if(this.searchBy!=''){
        assignData.assignorId=this.searchBy;
      }


      if (!this.assignInProgress) {
        this.assignInProgress = true;
        this.showPreloader = true;
        this.assignContentService
          .cdmPushContent(assignData, assignAs, isPP)
          .then((r: any) => {
            if (null !== r) {
              const cdmStatusResult = [];
              this.assignInProgress = false;

              r.forEach(field => {
                for(let i = 0; i<this.midListForPush.length; i++) {
                  if(field.userId == this.midListForPush[i].mid)
                  cdmStatusResult.push({ Mid: field.userId, Name: this.midListForPush[i].name, Status: field.status ,RequestId:field.requestId });
                }
              });

              for (let i = 0; i < cdmStatusResult.length; i++) {
                if ('Success' === cdmStatusResult[i].Status) {
                  break;
                }
              }
              // this.showPreloader = true;
              this.dialogRef.close({
                event: 'push',
                data: {
                  assignData: this.assignedData,
                  responseData: cdmStatusResult,
                  NoteMessageForAssign: this.NoteMessageForAssign
                }
              });
            } else {
              this.dialogRef.close({
                event: 'push',
                data: {
                  assignData: this.assignedData,
                  responseData: null,
                  NoteMessageForAssign: this.NoteMessageForAssign
                }
              });
            }
            this.showPreloader = false;
          })
          .catch(err => {
            this.assignInProgress = false;
            this.showPreloader = false;
            this.dialogRef.close({
              event: 'push',
              data: {
                assignData: this.assignedData,
                responseData: null,
                NoteMessageForAssign: this.NoteMessageForAssign
              }
            });
          });
      }
    }
  }

  getTitle(assignAsRole) {
    const filterPipe = new ArrayPropertyFilterPipe();
    const getRole = filterPipe.transform(this.PPRoles, {
      property: 'Value',
      flag: assignAsRole
    });
    return getRole[0].Label;
  }
  backAction() {
    this.fileToBeUploaded=new FormData();
    this.fileName="Select a File";
    this.isUploadButtonDisabled = true
    if (!this.intentMode && this.btnName == 'VERIFY') {
      this.intentMode = true;
      this.midListInput = '';
      this.MidValidation = false;
      this.assignInProgress = false;
      this.btnName = 'VERIFY';
    }
    if(this.isBulkAssignmentOpened) {
      this.isBulkAssignmentOpened = false;
      this.intentMode = false;
      this.midListInput = '';
      this.MidValidation = false;
      this.assignInProgress = true;
      this.btnName = 'VERIFY';
      this.isUploadSuccess= false;
      this.actionMessage='';
    }
  }
  closeAssign() {
    this.dialogRef.close({
      openApproval: false,
      approvalData: {
        LearnerIntentId: this.intentId,
        LearnerIntentRemarks:
          this.intentComments == '' ? null : this.intentComments,
        GetIntent: true
      }
    });
  }
  backFromAssign() {
    if (this.btnName == 'ASSIGN' && this.MidValidation) {
      this.intentMode = false;
      this.MidValidation = false;
      this.btnName = 'VERIFY';
    }
  }
  clearAll() {
    if (this.learnerMids) {
      this.learnerMids.length = 0;
    }
  }
  getLearningIntentList() {
    this.intentlistService.getLearningIntentList().then((res: any) => {
      const intentList = res;
      intentList.forEach((ele: any) => {
        this.IntentList.push({
          IntentId: ele.IntentId,
          IntentName: ele.IntentName,
          LearnerIntentDescription: ele.LearnerIntentDescription,
          ApproverIntentDescription: ele.ApproverIntentDescription
        });
        this.showPreloader = false;
      });
    });
  }
  next() {
    if (this.intentId != 1) {
      this.intentComments = '';
    }
    this.intentMode = false;
    this.assignInProgress = false;
  }


  getUserSuggestionList(name) {

    if (name.length > 2) {
      this.cdmService.searchUser(name).then((result: any) => {

       var tmpLPACMPCM=this.lpAcmPcmMid;
       var array4= result.value.filter(user=>{return tmpLPACMPCM.indexOf(user.userPrincipalName.split('@')[0]) !=-1 || !user.mail.includes('@mindtree.com')})
        this.showSuggestionsList = true;
        this.suggestionList = array4;
        this.suggestionList.forEach(user => {
          user.userImage = null;
          user.MId = user.userPrincipalName.split('@')[0].split('.')[1];
          this.graphDataService.getUserImage(user.MId).subscribe(
            data => {
              user.imageUrl = this.createImageURL(data);
            },
            error => {
              user.userImage = null;
            }
          );
        });
      });
    } else if (name.length == 0 && this.searchBy.length != 0) {
      ////console.log('1');
      this.showSuggestionsList = false;
      this.closeSearch();
    } else {
      ////console.log('2');
      this.showSuggestionsList = false;
      this.showSearchIcon = false;
    }
  }
  

  closeSearch() {
    this.showSuggestionsList = false;
    this.showSearchIcon = true;
    this.searchBy = '';
    this.cdmService.updateSearchStatus(false);
  }
  createImageURL(imageBlob) {
    const imageURL = this._window.URL.createObjectURL(imageBlob);
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }

  getRequestsOfSelectedUser(user) {
    this.cdmService.updateSearchStatus(true);
    this.searchBy = user.MId;
    this.showSuggestionsList = false;
  }

  getLpAcmAndPCm(){
    this._roleService.getAllLpAcmPcm().subscribe(
      data => {
        this.lpAcmPcmMid=data;
      },
      error => {
       //No Records Found
      }
    );
  }

  /* Begin - Bulk Assignment */

  openBulkAssignment() {
    this.isBulkAssignmentOpened = true;
  }
  downloadTemplate() {
    this.templateDownloadInProgress = true;
    this.assignContentService.downloadTemplateForBulkUpload().subscribe({ next : (res) => {
        this.templateDownloadInProgress = false;
        this.downloadExcel(res);
      },
      error: error => {
        this.templateDownloadInProgress = false;
        this.errorDownload(error);
      }
    })
  }

  downloadExcel(res) {
    const blob = new Blob([<any>res.body], {
      type: 'application/octet-stream'
    });
    this._FileSaverService.save(blob, 'BulkAssignmentUploadTemplate.xlsx');
    this.disableProceed = false;
    this.actionMessage = 'Download Completed';
    this.hideActionMessage();
  }
  errorDownload(error) {
    this.disableProceed = false;
    this.actionMessage = "'Download Failed or there is no data available.";
  }

  hideActionMessage() {
    setTimeout(() => {
      this.actionMessage = '';
    }, 3000);
  }

  closePopup() {
    this.dialogRef.close();
  }
  onFilesAdded(files, event){
    const target: DataTransfer = <DataTransfer>(event.target);
    if (files.length === 0){
      this.fileName="Select a File";
      this.fileToBeUploaded=new FormData();
      return;
    }
    const validExtensions = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const formData = new FormData();
    for (const file of files) {
      if(!validExtensions.includes(file.type)){
        this.actionMessage="Please upload a .xslx file";
        this.isUploadSuccess = false;
        this.isUploadButtonDisabled = true
        this.fileName = "Select a File";
        this.fileToBeUploaded = new FormData();
        return;
      }
      formData.append(file.name, file, file.name);
      this.fileName = file.name;
    }
    let reader = new FileReader(); 
    this.isUploadButtonDisabled = false;        
    // reader.onload = (e: any) => {
    //   var bstr = e.target.result;  
    //   var workbook = XLSX.read(bstr, { type: "binary" }); 
    //   this.secondSheetName = workbook.SheetNames[1];  
    //   if(this.secondSheetName == 'MetaData') {
    //     this.actionMessage = "";
    //   }
    //   else {
    //     this.actionMessage = "Sorry! Unable to upload, please re-upload the file.";
    //     this.isUploadButtonDisabled = true;
    //   }
    // };
    reader.readAsBinaryString(target.files[0]); 
    this.fileToBeUploaded = formData;
    this.actionMessage = "File ready for upload"; 
    this.isUploadSuccess = true;
  }

  upload() {
    this.actionMessage = 'Uploading...';
    this.excelUploadInProgress = true;
    const bulkPayloadData = {
      ItemUniqueId: this.data.UniqueId,
      ItemType: this.data.ItemType,
      ItemName: this.data.Name,
      ItemExpertise: this.data.Expertise,
      AssignerID:  this.searchBy.length != 0 ? this.searchBy : this.globals.MId,
      IntentID: this.selectedIntent.IntentId,
      IntentDescription: this.selectedIntent.IntentId == 1 ? this.intentComments : this.selectedIntent.ApproverIntentDescription,
      Duration: this.data.Duration,
      Role: this.assignAsRole.Value,
      AccountId: this.data.Account,
      ProjectId: this.data.Project,
      RepoType: this.data.RepoType
    }
    this.assignContentService
      .uploadBulkAssignment(this.fileToBeUploaded, bulkPayloadData)
      .subscribe(res => {
      var response = res as string[];
      if(response.length == 0) {
        this.isUploadSuccess = true;
        this.excelUploadInProgress = false;
        this.actionMessage="File has been successfully uploaded! For more details please refer to the log file sent to your email.";
      } else {
        this.excelUploadInProgress = false;
        this.actionMessage = "Sorry, upload was not successful. For more details please refer to the log file sent to your email."
      }
      // else if(response.length > 5) {
      //   var firstFiveElements = response.slice(0,5);
      //   this.excelUploadInProgress = false;
      //   this.actionMessage = "Sorry, upload has failed for the following request IDs "+firstFiveElements.toString()+" and more. For more details please refer to the log file sent to your email."
      // }
    }, error => {
      this.excelUploadInProgress = false;
      this.actionMessage = "Sorry, upload was not successful."
    });
  }

  /* End - Bulk Assignment */
}
