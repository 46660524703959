<!--new design-->
<div class="pss-certificate-request" fxLayoutGap="20px" fxLayout="column">
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    *ngFor="let request of requests; let index = index"
  >
  <div *ngIf="request.showCourseName" class="course-name-header" fxFlex="100%">
      {{ request.CourseName }}
  </div>
    <div *ngIf="selectedTab=='pending'" fxFlex="100%">
      <div class="request-index" fxFlex="20px">
        {{ contentIndex+index+1 }}.
      </div>
      <div class="request-details" fxFlex>
        <div fxFlex="170px" fxLayout="column" class="user-profile" fxLayoutAlign="start stretch">
          <div class="section request-id" fxLayout="row wrap">
            <div class="header" fxFlex="80px">Request ID :</div>
            <div class="value" fxFlex="70px">{{ request.RequestId }}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="section" fxLayout="column" fxLayoutGap="10px">
            <div class="name" title="{{ request.Name }}">{{ request.Name }}</div>
            <div class="mid">
              <div class="header" fxFlex="50px">PSID :</div>
              <div class="value">{{ request.MID }}</div>
            </div>
            <div
              class="joining-date"
              fxFlexOffset="5px"
              fxLayout="column"
              fxLayoutGap="5px"
            >
              <div class="header">Joining Date :</div>
              <div class="value">
                {{ request.JoiningDate | date: "MM/dd/yyyy" }}
              </div>
            </div>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div
          class="section course-details"
          fxLayout="column"
          fxLayoutGap="10px"
          fxFlex
        >
          <div fxLayout="row">
            <div class="header" fxFlex="120px">Course Name</div>
            <div  class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseName }}"  fxFlexOffset="5px">
              <a class="hyperlink" target="_blank"
              href="{{appUrl+'detailsPage/Course/' + request.Expertise + '/' + request.CourseId}}"  rel="noreferrer">
                {{ request.CourseName }}
              </a>
            </div>
          </div>
          <div fxLayout="row">
            <div class="header"  fxFlex="120px">Course ID</div>
            <div  class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseId }}"  fxFlexOffset="5px">
              {{ request.CourseId }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header"  fxFlex="120px">Expertise</div>
            <div  class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.Expertise }}"  fxFlexOffset="5px">
              {{ request.Expertise }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header"  fxFlex="120px">Course Type</div>
            <div  class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseType }}"  fxFlexOffset="5px">
              {{ request.CourseType }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header"  fxFlex="120px">Academy</div>
            <div  class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.Academy }}"  fxFlexOffset="5px">
              {{ request.Academy }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header"  fxFlex="120px">Vendor Name</div>
            <div  class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.VendorName }}"  fxFlexOffset="5px">
              {{ request.VendorName }}
            </div>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div fxFlex="170px" class="date-details">
          <div
            class="section certification-date"
            fxLayout="column"
            fxLayoutGap="5px"
          >
            <div class="header">Date of Certification</div>
            <div
              *ngIf="showCertificationDate[request.RequestId]"
              fxFlex="30px"
              fxLayoutAlign="start center"
            >
              <div
                fxFlex
                [ngClass]="{ fieldEdited: request.IsCertificationDateChanged }"
              >
                {{ request.Certification_CompletionDate | date: "MM/dd/yyyy" }}
              </div>
              <div
                fxFlex="25px"
                class="yorbitSprite editSpriteIcon mousePointer"
                (click)="!tileSetup.requestInProgress[request.RequestId]?(showCertificationDate[request.RequestId] = false):''"
              ></div>
            </div>
            <div
              *ngIf="!showCertificationDate[request.RequestId]"
              fxLayoutGap="10px"
              fxLayoutAlign="start center"
            >
              <div class="date-picker">
                <div
                  class="date-picker-holder"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                >
                  <mat-form-field class="datepickerForm">
                    <input
                      matInput
                      [matDatepicker]="certificationDatePicker"
                      [min]=""
                      [max]="maxDate"
                      (dateChange)="
                        onDateChangeEvent($event, index, 'certfication-date')
                      "
                    />
                    <mat-datepicker #certificationDatePicker></mat-datepicker>
                  </mat-form-field>
                  <div
                    class="date-value"
                    fxFlex="90px"
                    fxLayoutAlign="start center"
                  >
                    {{ request.Certification_CompletionDate | date: "MM/dd/yyyy" }}
                  </div>
                  <mat-divider vertical="true"></mat-divider>
                  <div fxFlex="25px" fxLayoutAlign="center center">
                    <mat-icon
                      class="mousePointer"
                      mat-raised-button
                      (click)="certificationDatePicker.open()"
                    >
                      date_range
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div
                fxFlex="25px"
                class="yorbitSprite roundedCrossSpriteIcon mousePointer"
                (click)="showCertificationDate[request.RequestId] = true"
              ></div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="section" fxLayout="column" fxLayoutGap="10px">
            <div class="expiry-date" fxLayout="column" fxLayoutGap="5px">
              <div class="header">Expiry Date :</div>
              <div *ngIf="request.Expiry_Dt == '0001-01-01T00:00:00'">
                Not Applicable
              </div>
              <div *ngIf="request.Expiry_Dt != '0001-01-01T00:00:00'">
                {{ request.Expiry_Dt | date: "MM/dd/yyyy" }}
              </div>
            </div>
            <div class="upload-date" fxLayout="column" fxLayoutGap="5px">
              <div class="header">Date of Upload :</div>
              <div>
                {{ request.Certification_UploadDate | date: "MM/dd/yyyy" }}
              </div>
            </div>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div
          class="section attachment-download"
          fxFlex="170px"
          fxLayout="column"
          fxLayoutGap="10px"
        >
          <div class="header">Attachment</div>
          <div
            class="download-attachement"
            fxFlex="50px"
            fxLayout="column"
            fxLayoutAlign="start stretch"
          >
            <button
              fxFlex="35px"
              class="downloadButton mousePointer"
              aria-label="Reset"
              (click)="downloadCertificate(request.filepath)"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="15px"
            >
              <div>Download</div>
              <div class="mousePointer yorbitSprite downloadSpriteIconWhite "></div>
            </button>
          </div>
        </div>
      </div>
      <div
        class="request-actions"
        fxLayout="column"
        fxLayoutGap="15px"
        fxFlexOffset="20px"
        fxFlex
      >
        <textarea
          fxFlex="140px"
          placeholder="Write comments"
          [ngClass]="{
            mouseDisabled:
              tileSetup.requestInProgress[request.RequestId] || disableOtherRequest
          }"
          [disabled]="
            tileSetup.requestInProgress[request.RequestId] || disableOtherRequest
          "
          [(ngModel)]="tileSetup.textAreaInput[request.RequestId]"
        ></textarea>
        <div
          class="action-buttons"
          fxFlex="30px"
          fxLayoutGap="15px"
          F
          fxLayoutAlign="end end"
        >
          <button
            class="accept"
            fxFlex="100px"
            [ngClass]="{
              active: tileSetup.acceptButtonTitle[request.RequestId] == 'accepted',
              mouseDisabled:
              (request.IsCertificationDateChanged && (
                tileSetup.textAreaInput[request.RequestId] == null ||
                tileSetup.textAreaInput[request.RequestId] == ''
              ) )|| tileSetup.requestInProgress[request.RequestId] ||
                disableOtherRequest ||
                (tileSetup.acceptButtonTitle[request.RequestId] | lowercase) ==
                  'failed'
            }"
            title="{{
              (request.IsCertificationDateChanged && (
                tileSetup.textAreaInput[request.RequestId] == null ||
                tileSetup.textAreaInput[request.RequestId] == ''
              ) )
                ? 'Please enter comments to accept the request !'
                : 'Accept This Request'
            }}"
            [disabled]="
            (request.IsCertificationDateChanged && (
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''
            ) )|| tileSetup.requestInProgress[request.RequestId] ||
              disableOtherRequest ||
              (tileSetup.acceptButtonTitle[request.RequestId] | lowercase) ==
                'failed'
            "
            (click)="accept(request)"
          >
            {{ tileSetup.acceptButtonTitle[request.RequestId] }}
          </button>
          <button
            class="deny"
            fxFlex="100px"
            [ngClass]="{
              active: tileSetup.denyButtonTitle[request.RequestId] == 'denied',
              mouseDisabled:
                tileSetup.textAreaInput[request.RequestId] == null ||
                tileSetup.textAreaInput[request.RequestId] == ''||
                tileSetup.requestInProgress[request.RequestId] ||
                disableOtherRequest ||
                (tileSetup.denyButtonTitle[request.RequestId] | lowercase) ==
                  'failed'
            }"
            title="{{
              (
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''
             )
                ? 'Please enter comments to deny the request !'
                : 'Deny this request'
            }}"
            [disabled]="
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''||
              tileSetup.requestInProgress[request.RequestId] ||
              disableOtherRequest ||
              (tileSetup.denyButtonTitle[request.RequestId] | lowercase) == 'failed'
            "
            (click)="deny(request)"
          >
            {{ tileSetup.denyButtonTitle[request.RequestId] }}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="selectedTab=='actioned'" fxFlex="100%">
        <div class="request-index" fxFlex="20px">
            {{ contentIndex+index+1 }}.
          </div>
          <div class="request-details" fxFlex>
              <div fxLayout="column"  class="user-profile" fxLayoutAlign="start stretch" fxFlex>
                <div class="section request-id" fxLayout="row wrap">
                  <div class="header" fxFlex="80px">Request ID :</div>
                  <div class="value" fxFlex="70px">{{ request.RequestId }}</div>
                </div>
                <mat-divider></mat-divider>
                <div class="section" fxLayout="column" fxLayoutGap="10px">
                  <div class="mid">
                    <div class="value">{{ request.MID }}</div>
                  </div>
                  <div class="name" title="{{ request.Name }}">{{ request.Name }}</div>
                </div>
              </div>
              <mat-divider vertical="true"></mat-divider>
              <div
                class="section course-details"
                fxFlex
                fxLayout="column"
                fxLayoutGap="10px"
              >
                <div fxLayout="row">
                  <div class="header" fxFlex>Course Name</div>
                  <div fxFlex="10px">:</div>
                  <div class="value" fxFlex title="{{ request.CourseName }}">
              <a (click)="gotoCourseDetailpage(request)" class="hyperlink" >
                {{ request.CourseName }}
              </a>
                  </div>
                </div>
                <div fxLayout="row">
                  <div class="header" fxFlex>Course ID</div>
                  <div fxFlex="10px">:</div>
                  <div class="value" fxFlex title="{{ request.CourseId }}">
                    {{ request.CourseId }}
                  </div>
                </div>
                <div fxLayout="row">
                  <div class="header" fxFlex>Expertise</div>
                  <div fxFlex="10px">:</div>
                  <div class="value" fxFlex title="{{ request.Expertise }}">
                    {{ request.Expertise }}
                  </div>
                </div>
                <div fxLayout="row">
                  <div class="header" fxFlex>Course Type</div>
                  <div fxFlex="10px">:</div>
                  <div class="value" fxFlex title="{{ request.CourseType }}">
                    {{ request.CourseType }}
                  </div>
                </div>
                <div fxLayout="row">
                  <div class="header" fxFlex>Academy</div>
                  <div fxFlex="10px">:</div>
                  <div class="value" fxFlex title="{{ request.Academy }}">
                    {{ request.Academy }}
                  </div>
                </div>
                <div fxLayout="row">
                  <div class="header" fxFlex>Vendor Name</div>
                  <div fxFlex="10px">:</div>
                  <div class="value" fxFlex title="{{ request.VendorName }}">
                    {{ request.VendorName }}
                  </div>
                </div>
              </div>
              <mat-divider vertical="true"></mat-divider>
              <div fxFlex  class="date-details">
                <div
                  class="section"
                  fxLayout="column"
                  fxLayoutGap="10px"
                >
                <div class="certification-date" fxLayout="column" fxLayoutGap="5px">
                  <div class="header">Date of Certification</div>
                  <div class="value">
                      {{ request.Certification_CompletionDate | date: "MM/dd/yyyy" }}
                  </div>
                </div>
                <div class="expiry-date" fxLayout="column" fxLayoutGap="5px">
                    <div class="header">Expiry Date :</div>
                    <div *ngIf="request.Expiry_Dt == '0001-01-01T00:00:00'">
                      Not Applicable
                    </div>
                    <div *ngIf="request.Expiry_Dt != '0001-01-01T00:00:00'">
                      {{ request.Expiry_Dt | date: "MM/dd/yyyy" }}
                    </div>
                  </div>
                  <div class="upload-date" fxLayout="column" fxLayoutGap="5px">
                    <div class="header">Date of Upload :</div>
                    <div>
                      {{ request.Certification_UploadDate | date: "MM/dd/yyyy" }}
                    </div>
                  </div>
                  <div class="actioned-date" fxLayout="column" fxLayoutGap="5px">
                      <div class="header">Date of Action :</div>
                      <div>
                        {{ request.Certificate_ActionDate | date: "MM/dd/yyyy" }}
                      </div>
                    </div>
                </div>
              </div>
              <mat-divider vertical="true"></mat-divider>
              <div
                class="section remarks"
                fxFlex
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="10px"
              >
                <div class="header">Your Comment :</div>
                <div
                  class="comment-box"
                >
                {{ request.Remarks == null || request.Remarks ==''? 'No comments' : request.Remarks}}
                </div>
              </div>
              <mat-divider vertical="true"></mat-divider>
              <div
                class="section status-and-substatus"
                fxFlex
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="10px"
              >
              <div class="status">
                  {{ request.certificatestatus }}
                </div>
                <div class="substatus">
                  {{ request.SubStatus }}
                </div>
              </div>
            </div>
    </div>
  </div>
   <!--preloader section-->
  <div *ngIf="showPreloader"  fxFlexFill fxLayoutAlign="center center">
      <app-preloader></app-preloader>
  </div>
        <!--No data info section-->
  <div *ngIf="!showPreloader && requests.length==0 && filtersApplied" class="noRequestsInfo" fxFlexFill fxLayoutAlign="center center">
          No requests available.
  </div>
  </div>
