<mat-dialog-content>
  <div class="closePopupBtn" (click)="close(false,true);">
    <button class="mat-button mat-icon-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title" *ngIf="formData.title != null && formData.title != ''">
    <div class="heading">{{ formData.title }}</div>
  </div>

  <div class="popup-content" *ngIf="type !== 'error'&& type !== 'submitted' && type !=='alertMsg' && type !== 'info' && type != 'saveAlert' && type != 'courseRequest'">
    <div *ngIf="type != 'exit' && type != 'save' && type != 'deleteConfirmation' && type != 'submit'" class="desc" [innerHTML]="formData">
    </div>
    <div *ngIf="type == 'exit' || type == 'save' || type == 'deleteConfirmation' || type == 'submit'" class="desc" [innerHTML]="formData.msg">
    </div>
    <div class="action-buttons">
      <button class="action-button" (click)="close(false,false)">Yes</button>
      <button class="action-button space-left" (click)="close(true,false)">No</button>
    </div>
  </div>

  <div class="popup-content" *ngIf="type == 'error'">
    <div class="desc" fxLayout="column">
      <!-- <div>The following mandatory field(s) {{formData.list.length > 1 ? ' are ' : ' is '}} empty under <b>{{formData.group}}</b>.</div> -->
      <div>The following mandatory field(s) may be empty or invalid under <b>{{formData.group}}</b>.</div>      
      <div fxLayout="column" class="errorList">
        <div *ngFor="let err of formData.list; let i =index" fxLayout="row">
          <div fxFlex="5">{{i+1 + '.'}}</div>
          <div fxFlex="95" [innerHTML]="err"></div>
        </div>
      </div>
    </div>
    <div class="action-buttons">
      <button class="action-button" (click)="close(false,false)">OK</button>
    </div>
  </div>

  <div class="popup-content" *ngIf="type == 'submitted' || type == 'alertMsg' || type == 'info' || type == 'saveAlert'">
    <div class="desc" [ngClass]="{'info-width':type == 'info' , 'text-left': (field == 'InfrastructureRequired' ||  field == 'ClassroomExecution')}" fxLayout="column">
      <div style="margin:auto"  [innerHTML]="formData.msg"></div>
    </div>
    <div class="action-buttons">
      <button class="action-button" (click)="close(false,false)">OK</button>
    </div>
  </div>

  <div class="popup-content" *ngIf="type == 'courseRequest'">  
    <div class="desc" [innerHTML]="formData.msg"></div>
    <div class="action-buttons">
      <button class="action-button" (click)="close(true,false)">Yes</button>
      <button class="action-button space-left" (click)="close(false,false)">No</button>
    </div>
  </div>
</mat-dialog-content>