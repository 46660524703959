<mat-dialog-content>
  <div class="popup-title">
    <div class="heading notifications-header">Cookie Suggestion</div>
  </div>
  <div class="description message" >
   Please enable the cookie settings
    </div>
    <mat-divider></mat-divider>
    <div class="action-btn" style="margin-bottom: 10px;" fxLayout="row wrap" fxLayoutAlign="center center">
      <button class="action-button" style="font-size: 11px;" mat-dialog-close>Close</button>
    
    </div>
</mat-dialog-content>