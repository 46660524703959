<mat-dialog-content>
  <!--Title-->
  <div class="popup-title">
    <div class="heading notifications-header">Session Information</div>
  </div>
  <div class="description message"  *ngIf="sessionActive">
  Your session will expire in {{minutes}} Min {{seconds}} Sec.
  </div>
  <div class="description message" *ngIf="!sessionActive">
    Your session has expired.
  </div>
  <div class="description message" style="font-weight:bold" *ngIf="message!=null">
   Note: {{message}}
    </div>

  <!--Mat divider-->
  <mat-divider></mat-divider>
  <!--Action buttons-->
  <div class="action-btn" fxLayout="row wrap" fxLayoutAlign="center center">
    <button class="action-button" *ngIf="sessionActive" (click)="closeDialog('logout')">Leave Page</button>
    <button class="action-button" *ngIf="sessionActive" (click)="closeDialog('keeplearning')" cdkFocusInitial>Stay on Page</button>
    <button class="action-button" *ngIf="!sessionActive" (click)="closeDialog('logout')" cdkFocusInitial>Log In</button>
  </div>
</mat-dialog-content>