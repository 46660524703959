<div fxLayout="row" fxFlexFill class="ao-page">
  <ng-template [ngTemplateOutlet]="leftPane"></ng-template>
  <ng-template [ngTemplateOutlet]="rightPane"></ng-template>
</div>



<ng-template #leftPane>
  <div fxLayout="column" fxLayoutAlign="start start" fxFlex="200px" class="left-pane-menu">
    <div (click)="openContentUpload(2)" [ngClass]="{ 'active-menu': menuSelection[2] }" class="menu">
      Content Upload
    </div>
    <div (click)="selectionHandler(0)">
      <div class="menu" [ngClass]="{ 'bold': menuSelection[0] }">Course Request</div>
      <div *ngIf="menuSelection[0]" class="nested-menu">
        <div (click)="selectCourseRequestTab('Pending',true)" class="menu-item"
          [ngClass]="{ 'active-sub-menu': showPending }">
          Pending
        </div>
        <div (click)="selectCourseRequestTab('Actioned',true);$event.stopPropagation()" class="menu-item"
          [ngClass]="{ 'active-sub-menu': !showPending }">
          Actioned
        </div>
      </div>
    </div>
    <div (click)="selectionHandler(1)" [ngClass]="{ 'active-menu': menuSelection[1] }" class="menu">
      Send Notifications
    </div>
    <div (click)="selectionHandler(7)" [ngClass]="{ 'active-menu': menuSelection[7] }" class="menu">
      Content Bulk Upload
    </div>
  </div>
</ng-template>
<ng-template #rightPane>
  <div fxFlex fxLayout="column" class="right-pane-data">
    <div *ngIf="menuSelection[0]&&!isRequestsLoading&&(this.contentLength !== 0)">
      <div fxFlex class="tol-req RequestCountPadding">
        No. of requests : {{ contentLength }}
      </div>
      <!-- <div fxFlex class="tol-req" *ngIf="actionedRequests.length > 0">
          No. of requests : {{ actionedRequests.length }}
        </div> -->
      <div class="pageSizeDisplayData floatRight">
        <mat-paginator class="aoPaginate" [length]="contentLength" [pageSize]="pageSizeLimit" [hidePageSize]="true"
          [pageIndex]="pageIndex" (page)="onPaginate($event)">
        </mat-paginator>
      </div>
      <div class="pageSizeContainer floatRight">
        <div class="pageSizeLabel">
          Number of items per page
        </div>
        <mat-form-field class="pageSizeSelect">
          <mat-select [(value)]="pageSizeLimit" panelClass="aoPaginateSelect"
            (selectionChange)="onPageSizeLimitChange()" disableOptionCentering>
            <mat-option *ngFor="let value of pageSizeOptions" [value]="value">
              {{ value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="menuSelection[0] && showPending" fxLayout="column">
      <div *ngIf="isRequestsLoading" fxLayoutAlign="center center">
        <app-preloader></app-preloader>
      </div>
      <div *ngIf="!isRequestsLoading" fxLayout="column" fxFlex="1 1 auto">
        <!-- <div fxFlex class="tol-req" *ngIf="this.contentLength !== 0">
          No. of requests : {{ contentLength }}
        </div> -->
        <div *ngFor="let request of pendingRequests; let i = index">
          <yorbit-new-course-request-approval [pendingTab]="showPending" [requestData]="request"
            [taxonomyList]="taxonomyList" [taxonomy]="reassignAcademyList"
            [requestStatus]="newRequestStatus[request.Id]" [reassignAcademy]="reassignAcademy[request.Id]"
            [reassignStatus]="reassignStatus[request.Id]" [showAccepted]="showAccepted" [showDenied]="showDenied"
            [showOnHold]="showOnHold" [showCompleted]="showCompleted"></yorbit-new-course-request-approval>
        </div>
        <div fxFlex *ngIf="contentLength == 0" fxLayoutAlign="center center">
          No Requests Found
        </div>
      </div>
    </div>
    <div *ngIf="menuSelection[0] && !showPending" fxLayout="column">
      <div class="nested-tabs" fxLayout="row">
        <div class="nested-tab" (click)="actionTabsHandler(actionedTabsIndex.ACCEPTED,true)"
          [ngClass]="{ 'nested-tab-active': showAccepted }">
          Accepted
        </div>
        <div class="nested-tab" (click)="actionTabsHandler(actionedTabsIndex.DENIED,true)"
          [ngClass]="{ 'nested-tab-active': showDenied }">
          Denied
        </div>
        <div class="nested-tab" (click)="actionTabsHandler(actionedTabsIndex.ONHOLD,true)"
          [ngClass]="{ 'nested-tab-active': showOnHold }">
          On Hold
        </div>
        <div class="nested-tab" (click)="actionTabsHandler(actionedTabsIndex.COMPLETED,true)"
          [ngClass]="{ 'nested-tab-active': showCompleted }">
          Completed
        </div>
      </div>
      <div *ngIf="isRequestsLoading" fxLayoutAlign="center center">
        <app-preloader></app-preloader>
      </div>
      <div *ngIf="!isRequestsLoading" fxLayout="column" fxFlex="1 1 auto">
        <!-- <div fxFlex class="tol-req" *ngIf="actionedRequests.length > 0">
          No. of requests : {{ actionedRequests.length }}
        </div> -->
        <div *ngFor="let request of actionedRequests; let i = index">
          <yorbit-new-course-request-approval [pendingTab]="showPending" [requestData]="request"
            [taxonomyList]="taxonomyList" [taxonomy]="reassignAcademyList"
            [requestStatus]="newRequestStatus[request.Id]" [reassignAcademy]="reassignAcademy[request.Id]"
            [reassignStatus]="reassignStatus[request.Id]" [showAccepted]="showAccepted" [showDenied]="showDenied"
            [showOnHold]="showOnHold" [showCompleted]="showCompleted"></yorbit-new-course-request-approval>
        </div>
        <div fxFlex *ngIf="actionedRequests.length == 0" fxLayoutAlign="center center">
          No Requests Found
        </div>
      </div>
    </div>
    <div fxFlex fxLayout="column" *ngIf="menuSelection[1]">
      <div fxFlexOffset="40px">
        Clicking 'Push Notifications' will redirect you to the Push/Bell Notification Admin Page. You will be able to
        send customized
        push/bell notifications to LTIMindtree Limited Associates via this page.
      </div>
      <div fxFlexOffset="20px">
        <a [href]="globals.yorbitUrl + 'admin'"> Push Notifications</a>
      </div>
    </div>
    <div fxFlex fxLayout="column" *ngIf="menuSelection[7]">
       <yorbit-bulk-upload-template></yorbit-bulk-upload-template>
    </div>
    <div
      *ngIf="menuSelection[2] && showContentVerification && !showQuizUpload && !show101ContentUpload &&
      cuuserroles &&
      (-1!=cuuserroles.indexOf('101')||-1!=cuuserroles.indexOf('201')|| -1!=cuuserroles.indexOf('301')||-1!=cuuserroles.indexOf('package')) "style="width:100%;height:100%">
      <yorbit-content-verification [role]="'AO'" [cuuserroles]="cuuserroles" (enableQuizUploadComponent)="enableQuizUpload($event)"
        (enableContentUploadComponent)="enableContentUpload($event)"
        (enable101ContentUploadComponent)="enable101ContentUpload($event)"
        (enable301ContentUploadComponent)="enable301ContentUpload($event)"
        (enablePackageContentUploadComponent)="enablePackageContentUpload($event)">
      </yorbit-content-verification>

    </div>

    <div
      *ngIf="menuSelection[2] && showContentVerification && !showQuizUpload &&  cuuserroles && (-1==cuuserroles.indexOf('101') && -1==cuuserroles.indexOf('201')&&
      -1==cuuserroles.indexOf('301') && -1==cuuserroles.indexOf('package'))"
      class="inheritHeight">
      <div class="background">
        <div class=message>
          <app-tooltip
          [showsTooltip]="showsTooltip"
          [topPosition]="topPosition"
          [leftPosition]="leftPosition">
        </app-tooltip>
          <div>Please reach out to   <span  (mouseenter)="openiSupportMenu($event)"
            (mouseout)="closeiSupportMenu()"><a 
            href={{globals.isupportUrl}} target="_blank">
            {{globals.isupportUrl}}</a> </span> to request access to the Content Upload Webforms.
             
          
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="menuSelection[2] && !showContentVerification && !showQuizUpload && !show101ContentUpload && !showPackageContentUpload && !show301ContentUpload"
      style="width:100%;height:100%">
      <yorbit-content-upload [ContentData]="selectedContent"
        (enableContentVerificationComponent)="fromExitContentUpload201toVerification($event)">
      </yorbit-content-upload>
    </div>
    <div *ngIf="menuSelection[2] && showQuizUpload && !showContentVerification && !show101ContentUpload && !showPackageContentUpload && !show301ContentUpload" style="width:100%;height:100%">
      <yorbit-quiz-form101 [course]="selectedContent" (enableContentVerificationComponent)="exitFromContentUploadForm($event)"
      (enable101ContentUploadComponent)="enable101ContentUpload($event)" (enableUnitsComponent)="enableUnitsAndTopics($event)">
      </yorbit-quiz-form101>
    </div>
    <div
      *ngIf="menuSelection[2] && !showQuizUpload && !showContentVerification && show101ContentUpload && !showPackageContentUpload && !show301ContentUpload">
      <yorbit-coursedetails-form101 [courseUniqueIdData]="selectedContent"
        (enableQuizUploadComponent)="enableQuizUpload($event)" (enableUnitsComponent)="enableUnitsAndTopics($event)"
        (enableContentVerificationComponent)="exitFromContentUploadForm($event)">
      </yorbit-coursedetails-form101>
    </div>
    <div *ngIf="menuSelection[3] && showUnitandTopicsUpload">
      <yorbit-units-and-topics [course]="selectedContent" (enableQuizUploadComponent)="enableQuizUpload($event)"
      (enableContentVerificationComponent)="exitFromContentUploadForm($event)" (enable101ContentUploadComponent)="enable101ContentUpload($event)">
      </yorbit-units-and-topics>
    </div>

    <div *ngIf="menuSelection[2] && !showQuizUpload && !showContentVerification && !show101ContentUpload && showPackageContentUpload && !show301ContentUpload">
    <yorbit-content-upload-package [selectedContent]='selectedContent' (enableContentVerificationComponent)="exitFromContentUploadForm($event)">
    </yorbit-content-upload-package>
  </div>


  <div *ngIf="menuSelection[2] && !showQuizUpload && !showContentVerification && !show101ContentUpload && !showPackageContentUpload && show301ContentUpload">
    <yorbit-content-upload301 [selectedCourse] = 'selectedContent' (enableContentVerificationComponent)="exitFromContentUploadForm($event)">
    </yorbit-content-upload301>
  </div>

  </div>
</ng-template>
