import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ReactiveFormsModule, AbstractControl } from '@angular/forms';
import * as _ from "lodash"
import {
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
  Validators
} from '@angular/forms';
import { ContentUploadService } from 'apps/yorbit-ui/src/app/ao/content-upload/content-upload.service';
//import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ContentUploadPopupComponent } from '../content-upload-popup/content-upload-popup.component';
import { ContentUploadStatusPopupComponent } from '../content-upload-status-popup/content-upload-status-popup.component';
//import { constants } from 'zlib';
import { Globals } from '../../globals';
import {
  ValidatePaymentControls,
  ValidateVideoControls,
  ValidatePreReqControl,
  ValidateCredits,
  ValidatePrice,
  ValidateDuration,
  ValidateExpert,
  ValidateMinLength,
  ValidateMaxLength
} from './content-upload-validators';
import { Observable } from 'rxjs';
//import { invalid } from '@angular/compiler/src/render3/view/util';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
//import { truncate } from 'fs';
//import { splitMatchedQueriesDsl } from '@angular/core/src/view/util';
import { ShareContentPopupService } from '../../shared/card-feature/share-content/share-content-popup/share-content-popup.service';
import { MediaObserver } from '@angular/flex-layout';
import { CoursesPopupComponent } from '../../shared/yorbit-courses/courses-popup/courses-popup.component';
import * as fromGraphStore from '@YorbitWorkspace/graph';
import { Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { PssContentApprovalService } from '../../pss/pss-content-approval/pss-content-approval.service';
import { DownloadBlobPopupComponent } from '../../shared/download-blob-popup/download-blob-popup.component';
import { SearchEvaluatorPopupComponent } from './search-evaluator/search-evaluator-popup/search-evaluator-popup.component';
import { QuizForm101Component } from '../content-upload-101/quiz-form101/quiz-form101.component'
import { YorbitCoursesService } from '../../shared/services/yorbit-courses.service';

@Component({
  selector: 'yorbit-content-upload',
  templateUrl: './content-upload.component.html',
  styleUrls: ['./content-upload.component.scss']
})
export class ContentUploadComponent implements OnInit, OnDestroy {
  contentOrder = [
    'ProofForCourseCompletion',
    'ClassRoom',
    'ProjectWork',
    'Assessment',
    'Quiz'
  ];

  contentUploadCompSubscriptions: any;
  contentForm: FormGroup;
  courseRefLink: any;
  taxonomy: any[];
  academyList: any[];
  genreList: any[];
  skillList: any[];
  genreSkillList: any[];
  vendorList: any[];
  courseTypeList: any[];
  courseModeOfPaymentList: any[];
  courseClassificationList: any[];
  courseInfrastructureList: any[];
  courseInfrastructureTypeList: any[];
  courseLoginList: any[];
  courseLoginTypeList: any[];
  courseSessionTypeList: any[];
  courseCompletionList: any[];
  courseCompletionTypeList: any[];
  courseEligibilityList: any[];
  courseAccessibilityList: any[];
  basicCourseTabClicked = true;
  operationalCourseTabClicked: boolean;
  yesNoRadio = ['Yes', 'No'];
  internalExternalRadio = ['Internal', 'External'];
  OnsiteOffsiteRadio = ['Onsite', 'Offshore'];
  currenyList = ['INR', 'USD'];
  fileNotSelected: boolean;
  eUploadFile: any;
  preRequisiteCourseNames: any;
  courseNames: any[];
  yorbitCourses: any[];
  yorbitCoursesLoading: boolean;
  filteredPreReqCourses: any[];
  filteredPostRecommCourses: any[];
  selectedPrereqCourses: any[];
  selectedPostRecommCourses: any[];
  yorbitCourseNamePre: string;
  yorbitCourseNamePost: string;
  isInfrastructureValid: boolean;
  isLoginTypeValid: boolean;
  isLearningMaterialValid: boolean;
  isCourseProcedureReferenceDocumentValid: boolean;
  isProofFieldValid: boolean;
  isClassroomFieldValid: boolean;
  isProjectFieldValid: boolean;
  isAssessmentFieldValid: boolean;
  isQuizValid: boolean
  isEdit = false;
  isApproved = false;
  courseUniqueId = '';
  courseStatus = '';
  showloading = true;
  preSessionSelected: boolean;
  postSessionSelected: boolean;
  duringSessionSelected: boolean;
  preGroupSubs: any;
  duringGroupSubs: any;
  postGroupSubs: any;
  @Input() ContentData;
  @Output() enableContentVerificationComponent = new EventEmitter<any>();
  isSaveClicked = false;
  formErr: boolean = false;
  formErrMsg: string = '';
  operationalErrMsg: any = {
    infrastructure: [],
    login: [],
    learningMaterial: [],
    CourseProcedureReferenceDocument: [],
    proofOfCourseCompletion: [],
    classroom: [],
    project: [],
    assessment: []
  };
  graphStoreSub: any = null;
  insertInProgress: boolean = false;
  hasInsertFailed: boolean = false;
  classroomMaxScoreValidation: any;
  projectMaxScoreValidation: any;
  assessmentMaxScoreValidation: any;
  classroomPassingScoreValidation: any;
  projectPassingScoreValidation: any;
  assessmentPassingScoreValidation: any;
  fileUploadInvalid: any;
  classroomMaxScoreValue: any[];
  projectMaxScoreValue: any[];
  assessmentMaxScoreValue: any;
  classroomPassingScoreValue: any[];
  classroomRequiredValue: any[];
  projectPassingScoreValue: any[];
  assessmentPassingScoreValue: any;
  contentUploadData: any;
  errorMsgs: any;
  classroomAssignment: any[];
  isClassroomRequired: boolean;
  config: any;
  saveAlertSubscription: any;
  assessmentEvalDeletion: boolean;
  assessmentQPDeletion: boolean;
  projectRefMaterialDeletion: boolean;
  projectEvalDeletion: boolean;
  classRoomEvalDeletion: boolean;
  inputForAssignmentEvaluationTemplate: string;
  inputForProjectMaterial: string;
  inputForProjectEvaluationTemplateFileUpload: string;
  inputForAssessmentEvaluationTemplate: string;
  inputForOnlineQuestionPaper: string;
  btnInputAssignmentEvaluationTemplate: string;
  btnInputProjectMaterial: string;
  btnInputProjectEvaluationTemplateFileUpload: string;
  btnInputAssessmentEvaluationTemplate: string;
  btnInputOnlineQuestionPaper: string;
  maxScoreUpperLimit: string;
  courseCompletionDataForPreview: any = [];
  epochDiffInMins: any;
  offsetTimeoutForAlert: any;

  sampleCertDeletion: boolean;
  sampleBadgeDeletion: boolean;
  sampleScreenshotDeletion: boolean;
  sampleLetterHeadDeletion: boolean;
  courseCompletionTypeFileBox: any;
  btnInputCourseCompletionTypeFileBox: any = [];
  contentOwnerMid: string;
  courseFor201Quiz;
  courseProficiencyList: any[];
  courseskillprismid: any[];
  @ViewChild(QuizForm101Component) quizFrom101Component: QuizForm101Component;

  constructor(
    private _contentUploadSvc: ContentUploadService,
    private fb: FormBuilder,
    private _popup: MatDialog,
    private globals: Globals,
    private route: ActivatedRoute,
    private router: Router,
    private graphStore: Store<fromGraphStore.IGraphReducerState>,
    private _envSvc: EnvironmentService,
    private pssContentApprovalService: PssContentApprovalService,
    private _shareContentPopupService: ShareContentPopupService,
    private courseSvc: YorbitCoursesService
  ) {
    this.errorMsgs = {
      course_duration:
        // 'Please enter numbers in the format of xxx.xx.xx or xx.xx.xx.',
        'Please enter numbers in the format of HHH.MM.SS or HH.MM.SS.',
      course_name: 'Please enter a course name.',
      credits: 'Please enter number in the format of x.',
      badge_name: 'Please enter badge name.',
      expert_mids: 'Please enter PSID in XXXXXXXX format.',
      file_upload_err: 'Please choose a zip or 7zip file of size <=',
      course_ref_doc_err: 'Please choose a eml or msg file of size <=',
      max_score: {
        alphabet_err: 'Please enter numbers only.',
        number_err: 'Please enter a value <= 500.'
      },
      passing_score: {
        alphabet_err: 'Please enter numbers only.',
        number_err: 'Please enter a value <= 500.'
      },
      evaluator: 'Please enter a valid PSID or name.'
    };
    this.config = this._envSvc.getEnvironment();
  }

  ngOnInit() {
    this.maxScoreUpperLimit = 'Please enter a value <= 500.';
    this.classroomMaxScoreValidation = [];
    this.projectMaxScoreValidation = [];
    this.fileUploadInvalid = {};
    this.assessmentMaxScoreValidation = {
      isInvalid: false,
      errMsg: ''
    };
    this.classroomPassingScoreValidation = [];
    this.projectPassingScoreValidation = [];
    this.assessmentPassingScoreValidation = {
      isInvalid: false,
      errMsg: ''
    };
    this.classroomMaxScoreValue = [''];
    this.projectMaxScoreValue = [''];
    this.assessmentMaxScoreValue = '';

    this.classroomRequiredValue = [''];
    this.classroomPassingScoreValue = [''];
    this.projectPassingScoreValue = [''];
    this.assessmentPassingScoreValue = '';
    ////console.log(this.ContentData);
    this.contentUploadCompSubscriptions = null;
    this.yorbitCourses = [];
    this.filteredPreReqCourses = [];
    this.filteredPostRecommCourses = [];
    this.selectedPrereqCourses = [];
    this.selectedPostRecommCourses = [];
    this.yorbitCourseNamePre = '';
    this.yorbitCourseNamePost = '';
    this.isInfrastructureValid = true;
    this.isLoginTypeValid = true;
    this.isLearningMaterialValid = true;
    this.isProofFieldValid = true;
    this.isClassroomFieldValid = true;
    this.isProjectFieldValid = true;
    this.isAssessmentFieldValid = true;
    this.isQuizValid = true;
    this.preSessionSelected = false;
    this.postSessionSelected = false;
    this.duringSessionSelected = false;
    this.classroomAssignment = [''];
    this.isClassroomRequired = false;
    this.epochDiffInMins = null;
    this.offsetTimeoutForAlert = null;
    this._contentUploadSvc.isInCUComponent = true;
    this.isApproved = false;
    this._contentUploadSvc.isApproved = false;

    this.contentForm = new FormGroup({
      academy: new FormControl('', Validators.required),
      genre: new FormControl('', Validators.required),
      skill: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      SkillIds: new FormControl(''),
      topicCovered: new FormControl('', [
        Validators.required,
        ValidateMinLength(50)
      ]),
      description: new FormControl('', [
        Validators.required,
        ValidateMinLength(250)
      ]),
      type: new FormControl('', Validators.required),
      internal: new FormControl('No', Validators.required),
      vendor: new FormControl('', Validators.required),
      paid: new FormControl('No', Validators.required),
      price: new FormControl({ value: '', disabled: true }, [
        ValidatePaymentControls,
        ValidatePrice
      ]),
      currency: new FormControl({ value: '', disabled: true }, [
        ValidatePaymentControls
      ]),
      paymentMode: new FormControl({ value: '', disabled: true }, [
        ValidatePaymentControls
      ]),
      duration: new FormControl('', [Validators.required, ValidateDuration]),
      managerApproval: new FormControl('No', Validators.required),
      preRequisiteCourseIDs: new FormControl({ value: '', disabled: true }),
      postRecommendedCourseIDs: new FormControl(''),
      isPreRequisiteMandatory: new FormControl({ value: '', disabled: true }, [
        ValidatePreReqControl
      ]),
      eligibility: new FormControl('', Validators.required),
      accessibility: new FormControl('', Validators.required),
      credits: new FormControl('', [Validators.required, ValidateCredits]),
      classification: new FormControl('', Validators.required),
      badge: new FormControl('', Validators.required),
      badgeId: new FormControl(null),
      expertMids: new FormControl('', [ValidateExpert]),
      ContentOwner: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      isIntroductoryVideo: new FormControl('No', Validators.required),
      sourceType: new FormControl('', [ValidateVideoControls]),
      introductoryVideo: new FormControl(null, [ValidateVideoControls]),
      creditSpecial: new FormControl('No', Validators.required),
      IsCertificateAvailable: new FormControl('0'),
      IsCloudLabAvailable: new FormControl('0'),
      isInfrastructure: new FormControl(false),
      infrastructure: new FormArray([]),
      infrastructureType: new FormArray([]),
      isLoginRequired: new FormControl(false),
      loginType: new FormArray([]),
      coursesNameForSelfRegistration: new FormArray([]),
      linktoCoursesForSelfRegistration: new FormArray([]),
      loginDetails: new FormControl(null),
      coursesNameForLearningOPMTeam: new FormArray([]),
      linktoCoursesForLearningOPMTeam: new FormArray([]),
      isLearningMaterial: new FormControl('No'),
      isCourseProcedureReferenceDocument: new FormControl('No'),
      proficiency: new FormControl('', Validators.required),
      preSessionGroup: new FormGroup({
        preSessionLearningMaterialTextBox: new FormControl(null),
        preSessionLearningMaterialFileBox: new FormControl(null),
        preSessionLearningMaterialLink: new FormControl(null)
      }),
      duringSessionGroup: new FormGroup({
        duringSessionLearningMaterialTextBox: new FormControl(null),
        duringSessionLearningMaterialFileBox: new FormControl(null),
        duringSessionLearningMaterialLink: new FormControl(null)
      }),
      postSessionGroup: new FormGroup({
        postSessionLearningMaterialTextBox: new FormControl(null),
        postSessionLearningMaterialFileBox: new FormControl(null),
        postSessionLearningMaterialLink: new FormControl(null)
      }),
      courseProcedureRefDocGroup: new FormGroup({
        courseProcedureRefDocTextBox: new FormControl(null),
        courseProcedureRefDocFileBox: new FormControl(null),
        courseProcedureRefDocLink: new FormControl(null)
      }),
      courseCompletionGroup: new FormGroup({
        courseCompletionFormArray: new FormArray([]),
        courseCompletionTypeFormArray: new FormArray([])
      }),
      classRoomGroup: new FormGroup({
        isClassRoomRequired: new FormControl('No'),
        classroomExecution: new FormControl(1),
        classRoom: new FormArray([])
      }),
      projectGroup: new FormGroup({
        hasProjectWork: new FormControl('No'),
        hasJumbleProject: new FormControl('No'),
        projectWorkDetails: new FormArray([]),
        hasProjectEvaluation: new FormControl({ value: 'Yes', disabled: true }),
        projectEvaluationDetails: new FormArray([])
      }),
      assessmentGroup: new FormGroup({
        assessmentRadio: new FormControl('No'),
        assessmentDescription: new FormControl(null),
        assesmentInfrastructure: new FormArray([]),
        assesmentInfrastructureType: new FormArray([]),
        assessmentType: new FormControl('Offline'),
        questionPaper: new FormControl(''),
        questionPaperLink: new FormControl(''),
        evaluationTemplate: new FormControl(''),
        evaluationTemplateLink: new FormControl(''),
        maxScore: new FormControl(''),
        passingScore: new FormControl({ value: '', disabled: true }),
        evaluatorType: new FormControl(''),
        evaluatorList: new FormArray([]),
        toolName: new FormControl(''),
        onlineQuestionPaper: new FormControl(''),
        onlineQuestionPaperLink: new FormControl(''),
        isAssessmentScheduled: new FormControl('No'),
        assessmentScheduledDate: new FormControl('')
      }), quizGroup: new FormGroup({
        quizRadio: new FormControl('No'),

      }),
      contentOrder: new FormArray([]),
      certificateLink: new FormControl(''),
      badgeLink: new FormControl(''),
      sreenShotLink: new FormControl(''),
      letterHeadLink: new FormControl(''),
      IsMailAlertRequired: new FormControl('1')
    });

    this.assessmentEvalDeletion = false;
    this.assessmentQPDeletion = false;
    this.projectRefMaterialDeletion = false;
    this.projectEvalDeletion = false;
    this.classRoomEvalDeletion = false;

    this.sampleCertDeletion = false;
    this.sampleBadgeDeletion = false;
    this.sampleScreenshotDeletion = false;
    this.sampleLetterHeadDeletion = false;

    this.btnInputAssignmentEvaluationTemplate = 'Upload';
    this.btnInputProjectMaterial = 'Upload';
    this.btnInputProjectEvaluationTemplateFileUpload = 'Upload';
    this.btnInputAssessmentEvaluationTemplate = 'Upload';
    this.btnInputOnlineQuestionPaper = 'Upload';

    this.inputForAssignmentEvaluationTemplate = 'evaluationTemplate';
    this.inputForProjectMaterial = 'projectMaterial';
    this.inputForProjectEvaluationTemplateFileUpload =
      'projectEvaluationTemplateFileUpload';
    this.inputForAssessmentEvaluationTemplate = 'evaluationTemplate';
    this.inputForOnlineQuestionPaper = 'onlineQuestionPaper';

    this.courseCompletionTypeFileBox = [
      'sampleCert',
      'sampleBadge',
      'sampleScreenShot',
      'sampleLetterHead'
    ];
    this.btnInputCourseCompletionTypeFileBox[0] = 'Upload';
    this.btnInputCourseCompletionTypeFileBox[1] = 'Upload';
    this.btnInputCourseCompletionTypeFileBox[2] = 'Upload';
    this.btnInputCourseCompletionTypeFileBox[3] = 'Upload';

    if (this.contentForm.get('academy').value === '' || this.isApproved) {
      this.contentForm.get('genre').disable();
    }
    if (
      (this.contentForm.get('genre').value === '' &&
        !this.contentForm.get('genre').dirty) ||
      this.isApproved
    ) {
      this.contentForm.get('skill').disable();
    }

    this.contentForm.controls['paymentMode'].setValue('2');
    this.contentForm.controls['currency'].setValue('INR');
    this.graphStoreSub = this.graphStore
      .select(fromGraphStore.getGraphUserName)
      .subscribe(res => {
        this.contentOwnerMid = this.globals.MId;
        if (res) {
          this.contentForm.controls['ContentOwner'].setValue(res);
        } else {
          this.contentForm.controls['ContentOwner'].setValue(this.globals.MId);
        }
      });
    // this.contentForm.controls['ContentOwner'].setValue(this.globals.userName);
    this.contentForm.controls['preRequisiteCourseIDs'].setValue(new Array());
    this.contentForm.controls['isPreRequisiteMandatory'].setValue('No');
    this.contentForm.controls['assessmentGroup']
      .get('passingScore')
      .disable({ onlySelf: true });
    //caling all the methods to bind drop downs
    this.getTaxonomy();
    this.getVendor();
    this.getCourseTypeList('201');
    this.getCourseModeOfPayment();
    this.getCourseClassification();
    this.getCourseInfrastructure();
    this.getCourseInfrastructureType();
    this.getCourseLogin();
    this.getCourseLoginType();
    this.getCourseSessionType();
    this.getCourseCompletion();
    this.getCourseCompletionType();
    this.getCourseEligibility();
    this.getCourseAccessibility();
    this.addClassRoom();
    this.addProjectWork();
    this.addProjectEvaluation();
    this.onPreReqControlChange();
    this.onLearningMaterialSelect();
    this.onHasProjectWorkChange();
    this.getCourseProficiencyList();
    //upload files prop
    this.eUploadFile = {};
    //subscribe to get the status on content verification comp selection
    this.subscribeToCanLoadContentUploadComp();
    this.onJumbleProjectProblemStatementChange();
    // this.saveAlertTimer(); --Commented in MSAL build

    // const token = window.localStorage.getItem(
    //   'adal.access.token.key' + this.config.adalConfig.clientId
    // );
    // const decodedToken = jwt_decode(token);
    // const tokenExpiresIn = decodedToken['exp'];
    // const currentEpochTime = Math.floor(new Date().getTime() / 1000.0);
    // const epochDiffInMins = Math.floor(
    //   (tokenExpiresIn - currentEpochTime) / 60
    // ); // Epoch time difference in minutes

    // const offsetTimeoutForAlert = 10;
    // this.saveAlertSubscription = setTimeout(() => {
    //   this.showSaveAlertPopup(epochDiffInMins);
    // }, (epochDiffInMins - offsetTimeoutForAlert) * 60 * 1000);

    //setTimeout(showPopupFn,(epochDiffInMins-10));

  }

  saveAlertTimer() {
    const token = window.localStorage.getItem(
      'adal.access.token.key' + this.config.adalConfig.clientId
    );
    const decodedToken = jwt_decode(token);
    const tokenExpiresIn = decodedToken['exp'];
    const currentEpochTime = Math.floor(new Date().getTime() / 1000.0);
    const epochDiffInMins = Math.floor(
      (tokenExpiresIn - currentEpochTime) / 60
    );
    // Epoch time difference in minutes
    //const offsetTimeoutForAlert = 10;
    this.epochDiffInMins = epochDiffInMins;
    this.offsetTimeoutForAlert = 10;
  }

  showSaveAlertPopup(epochDiffInMins) {
    // if((epochDiffInMins-10) > 1){
    // if (epochDiffInMins >= 5 && epochDiffInMins < 15) {
    if (!this.isApproved) {
      let type = 'saveAlert';
      let data = {
        msg: 'Session will expire soon. Please save the data.',
        title: 'Information'
      };
      this.openStatusPopup(data, type);
    }
    // }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.contentOrder, event.previousIndex, event.currentIndex);
  }

  buildContentOrder() {
    this.contentForm.setControl(
      'contentOrder',
      new FormControl(this.contentOrder)
    );
  }

  fileModel(fileType: string, fileSubType: string, index: number, event) {
    //console.log('filemodel', fileType, fileSubType, index, event);
    this.fileNotSelected = false;
    if (this.eUploadFile[fileType] != undefined) {
      if (this.eUploadFile[fileType][fileSubType] != undefined) {
        this.eUploadFile[fileType][fileSubType][index] = event.file;

      } else {
        this.eUploadFile[fileType][fileSubType] = {};
        this.eUploadFile[fileType][fileSubType][index] = event.file;
        //console.log('euploadfile', this.eUploadFile);
      }
    } else {
      this.eUploadFile[fileType] = {};
      this.eUploadFile[fileType][fileSubType] = {};
      this.eUploadFile[fileType][fileSubType][index] = event.file;
    }
    //this.eUploadFile = event.file;
    this.validateAllCondition('All', 'All', 0, event);
    //console.log("file : ",this.eUploadFile);
  }

  //build infrastructure check box
  buildInfrastructure() {
    const control = <FormArray>this.contentForm.controls['infrastructure'];
    this.courseInfrastructureList.forEach(infrastructure => {
      control.push(new FormControl(false));
    });
    return control;
  }

  //build infrastructure textboxes
  buildInfrastructureType() {
    const control = <FormArray>this.contentForm.controls['infrastructureType'];
    this.courseInfrastructureTypeList.forEach(infrastructureType => {
      control.push(new FormControl(null));
    });
  }
  buildAssesmentInfrastructure() {
    const control = <FormArray>(
      this.contentForm.get('assessmentGroup.assesmentInfrastructure')
    );
    this.courseInfrastructureList.forEach((infrastructure, key) => {
      control.push(new FormControl(false));
    });
    return control;
  }

  //build infrastructure textboxes
  buildAssesmentInfrastructureType() {
    const control = <FormArray>(
      this.contentForm.get('assessmentGroup.assesmentInfrastructureType')
    );
    this.courseInfrastructureTypeList.forEach(infrastructureType => {
      control.push(new FormControl(null));
    });
    return control;
  }
  //build login type checkboxes
  buildLoginType() {
    const control = <FormArray>this.contentForm.controls['loginType'];
    this.courseLoginList.forEach(loginType => {
      control.push(new FormControl(false));
    });
    return control;
  }

  //build login description texboxes
  buildLearnertoSelfRegistrationForLink() {
    const control = <FormArray>(
      this.contentForm.controls['linktoCoursesForSelfRegistration']
    );
    control.push(new FormControl(null));
  }

  buildLearnertoSelfRegistrationForName() {
    const control = <FormArray>(
      this.contentForm.controls['coursesNameForSelfRegistration']
    );
    control.push(new FormControl(null));
  }

  buildLearnertoOPMRegistrationForLink() {
    const control = <FormArray>(
      this.contentForm.controls['linktoCoursesForLearningOPMTeam']
    );
    control.push(new FormControl(null));
  }

  buildLearnertoOPMRegistrationForName() {
    const control = <FormArray>(
      this.contentForm.controls['coursesNameForLearningOPMTeam']
    );
    control.push(new FormControl(null));
  }

  buildCourseCompletion() {
    const control = <FormArray>(
      this.contentForm.get('courseCompletionGroup.courseCompletionFormArray')
    );
    this.courseCompletionList.forEach(item => {
      control.push(new FormControl(false));
    });
  }
  buildCourseCompletionType() {
    const control = <FormArray>(
      this.contentForm.get(
        'courseCompletionGroup.courseCompletionTypeFormArray'
      )
    );
    this.courseCompletionTypeList.forEach(item => {
      control.push(new FormControl(null));
    });
  }

  addSelfRegistrationLink(): void {
    this.buildLearnertoSelfRegistrationForLink();
  }
  deleteSelfRegistrationLink(index: number) {
    const control = <FormArray>(
      this.contentForm.controls['linktoCoursesForSelfRegistration']
    );
    control.removeAt(index);
  }
  addSelfRegistrationName(): void {
    this.buildLearnertoSelfRegistrationForName();
  }
  deleteSelfRegistrationName(index: number) {
    const control = <FormArray>(
      this.contentForm.controls['coursesNameForSelfRegistration']
    );
    control.removeAt(index);
  }
  addLearnerOPMLink(): void {
    this.buildLearnertoOPMRegistrationForLink();
  }
  deleteLearnerOPMLink(index: number) {
    const control = <FormArray>(
      this.contentForm.controls['linktoCoursesForLearningOPMTeam']
    );
    control.removeAt(index);
  }
  addLearnerOPMName(): void {
    this.buildLearnertoOPMRegistrationForName();
  }
  deleteLearnerOPMName(index: number) {
    const control = <FormArray>(
      this.contentForm.controls['coursesNameForLearningOPMTeam']
    );
    control.removeAt(index);
  }

  addClassRoom() {
    const control = <FormArray>this.contentForm.get('classRoomGroup.classRoom');
    let formgroup = this.buildClassRoom();
    control.push(formgroup);
    this.classroomAssignment[control.length - 1] = 'No';
    this.classroomMaxScoreValue[control.length - 1] = '';
    this.classroomPassingScoreValue[control.length - 1] = '';
  }
  deleteClassRoom(index: number) {
    const control = <FormArray>this.contentForm.get('classRoomGroup.classRoom');
    control.removeAt(index);
  }
  public buildClassRoom() {
    let form = this.fb.group({
      noOfClassRoomSessions: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      nameOfClassRoomSessions: new FormControl(null),
      classroomDescription: new FormControl(null),
      // vendorOrFaultyName: new FormControl(null),
      isAssignmentRequired: new FormControl('No', Validators.required),
      assignmentDescription: new FormControl(null),
      assignmentContent: new FormControl(null),
      assignmentContentLink: new FormControl(null),
      isEvaluationRequired: new FormControl({ value: 'Yes', disabled: true }),
      evaluatorName: new FormControl(null),
      evaluatorEmailID: new FormControl(null),
      evaluatorVendor: new FormControl(null),
      evaluatorType: new FormControl(null),
      locationofEvaluator: new FormControl(''),
      evaluationTemplate: new FormControl(null),
      evaluationTemplateLink: new FormControl(null),
      maxScore: new FormControl(''),
      passingScore: new FormControl({ value: '', disabled: true }),
      isClassroomScheduled: new FormControl('No'),
      classroomScheduledDateTime: new FormControl(null)
    });
    form.get('passingScore').disable({ onlySelf: true });
    return form;
  }

  addProjectWork() {
    const control = <FormArray>(
      this.contentForm.get('projectGroup.projectWorkDetails')
    );
    control.push(this.buildProjectWork());
  }
  deleteProjectWork(index: number) {
    const control = <FormArray>(
      this.contentForm.get('projectGroup.projectWorkDetails')
    );
    control.removeAt(index);
    this.projectMaxScoreValue[index] = '';
    this.projectPassingScoreValue[index] = '';
  }
  public buildProjectWork() {
    let form = this.fb.group({
      projectDescription: new FormControl(null),
      problemStatement: new FormControl(null),
      problemStatementLink: new FormControl(null),
      projectDemo: new FormControl('No'),
      projectMaterial: new FormControl(null),
      projectMaterialLink: new FormControl(null),
      maxScore: new FormControl(''),
      passingScore: new FormControl({ value: '', disabled: true }),
      evaluationTemplate: new FormControl(null),
      evaluationTemplateLink: new FormControl(null)
    });
    form.get('passingScore').disable({ onlySelf: true });
    return form;
  }

  addAssessmentEvaluation() {
    const control = <FormArray>(
      this.contentForm.get('assessmentGroup.evaluatorList')
    );
    control.push(this.buildAssessmentEvaluation());
  }
  deleteAssessmentEvaluation(index: number) {
    const control = <FormArray>(
      this.contentForm.get('assessmentGroup.evaluatorList')
    );
    control.removeAt(index);
  }
  public buildAssessmentEvaluation() {
    return this.fb.group({
      evaluatorName: new FormControl(null),
      evaluatorEmail: new FormControl(null),
      evaluatorVendor: new FormControl(null)
    });
  }

  addProjectEvaluation() {
    const control = <FormArray>(
      this.contentForm.get('projectGroup.projectEvaluationDetails')
    );
    control.push(this.buildProjectEvaluation());
  }
  deleteProjectEvaluation(index: number) {
    const control = <FormArray>(
      this.contentForm.get('projectGroup.projectEvaluationDetails')
    );
    control.removeAt(index);
  }
  public buildProjectEvaluation() {
    return this.fb.group({
      evaluatorName: new FormControl(null),
      evaluatorEmailID: new FormControl(null),
      evaluatorVendor: new FormControl(null),
      evaluatorType: new FormControl(null),
      locationofEvaluator: new FormControl(null)
    });
  }

  ValidateMaxScore(type, index) {
    const regEx = new RegExp(/^[0-9]*$/, 'g');
    if (type == 'Assessment') {
      this.assessmentPassingScoreValue = this.contentForm.get(
        'assessmentGroup.passingScore'
      ).value;
      this.assessmentMaxScoreValue = this.contentForm.get(
        'assessmentGroup.maxScore'
      ).value;
      this.contentForm.get('assessmentGroup.passingScore').disable();
      const value = this.contentForm.get('assessmentGroup.maxScore').value;
      if (value == '' || (value && value.trim() == '')) {
        this.contentForm.get('assessmentGroup.passingScore').disable();
        this.assessmentPassingScoreValidation = {
          isInvalid: true,
          errMsg: 'Please enter max score.'
        };
        this.assessmentMaxScoreValidation = {
          isInvalid: false,
          errMsg: 'Please enter numbers only.'
        };
      } else {
        if (value && value.toString().length != 0) {
          if (!regEx.test(value)) {
            this.assessmentMaxScoreValidation = {
              isInvalid: true,
              errMsg: 'Please enter numbers only.'
            };
          } else if (value > 500) {
            this.assessmentMaxScoreValidation = {
              isInvalid: true,
              errMsg: 'Please enter a value <= 500.'
            };
          } else if (value == 0) {
            this.assessmentMaxScoreValidation = {
              isInvalid: true,
              errMsg: 'Please enter a value greater than zero.'
            };
          } else {
            this.assessmentMaxScoreValidation = {
              isInvalid: false,
              errMsg: ''
            };
          }
        } else {
          this.assessmentMaxScoreValidation = {
            isInvalid: false,
            errMsg: ''
          };
        }
        if (!this.assessmentMaxScoreValidation.isInvalid) {
          this.contentForm.get('assessmentGroup.passingScore').enable();
        }

        if (
          this.contentForm.get('assessmentGroup.passingScore').value !=
          undefined
        ) {
          this.ValidatePassingScore(type, index);
        }
      }
    } else if (type == 'Project') {
      const projectDetailsControl = <FormArray>(
        this.contentForm.get('projectGroup.projectWorkDetails')
      );
      const value = projectDetailsControl.controls[index].get('maxScore').value;
      projectDetailsControl.controls[index]
        .get('passingScore')
        .disable({ onlySelf: true });

      this.projectPassingScoreValue[index] = projectDetailsControl.controls[
        index
      ].get('passingScore').value;
      this.projectMaxScoreValue[index] = projectDetailsControl.controls[
        index
      ].get('maxScore').value;

      if (value == '' || (value && value.trim() == '')) {
        projectDetailsControl.controls[index].get('passingScore').disable();
        this.projectPassingScoreValidation[index] = {
          isInvalid: true,
          errMsg: 'Please enter max score.'
        };
        this.projectMaxScoreValidation[index] = {
          isInvalid: false,
          errMsg: 'Please enter only numbers.'
        };
      } else {
        if (value && value.toString().length != 0) {
          if (!regEx.test(value)) {
            this.projectMaxScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter numbers only.'
            };
          } else if (value > 500) {
            this.projectMaxScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter a value <= 500.'
            };
          } else if (value == 0) {
            this.projectMaxScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter a value greater than zero.'
            };
          } else {
            this.projectMaxScoreValidation[index] = {
              isInvalid: false,
              errMsg: ''
            };
          }
        } else {
          this.projectMaxScoreValidation[index] = {
            isInvalid: false,
            errMsg: ''
          };
        }
        if (!this.projectMaxScoreValidation[index].isInvalid) {
          projectDetailsControl.controls[index].get('passingScore').enable();
        }

        if (
          projectDetailsControl.controls[index].get('passingScore').value !=
          undefined
        ) {
          this.ValidatePassingScore(type, index);
        }
      }
    } else if (type == 'ClassRoom') {
      const classRoomControl = <FormArray>(
        this.contentForm.get('classRoomGroup.classRoom')
      );
      classRoomControl.controls[index]
        .get('passingScore')
        .disable({ onlySelf: true });
      const value = classRoomControl.controls[index].get('maxScore').value;

      this.classroomPassingScoreValue[index] = classRoomControl.controls[
        index
      ].get('passingScore').value;
      this.classroomMaxScoreValue[index] = classRoomControl.controls[index].get(
        'maxScore'
      ).value;

      if (value == '' || (value && value.trim() == '')) {
        classRoomControl.controls[index].get('passingScore').disable();
        this.classroomPassingScoreValidation[index] = {
          isInvalid: true,
          errMsg: 'Please enter max score.'
        };
        this.classroomMaxScoreValidation[index] = {
          isInvalid: false,
          errMsg: 'Please enter only numbers.'
        };
      } else {
        if (value && value.toString().length != 0) {
          if (!regEx.test(value)) {
            this.classroomMaxScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter numbers only.'
            };
          } else if (value > 500) {
            this.classroomMaxScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter a value <= 500.'
            };
          } else if (value == 0) {
            this.classroomMaxScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter a value greater than zero.'
            };
          } else {
            this.classroomMaxScoreValidation[index] = {
              isInvalid: false,
              errMsg: ''
            };
          }
        } else {
          this.classroomMaxScoreValidation[index] = {
            isInvalid: false,
            errMsg: ''
          };
        }
        if (!this.classroomMaxScoreValidation[index].isInvalid) {
          classRoomControl.controls[index].get('passingScore').enable();
        }
        if (
          classRoomControl.controls[index].get('passingScore').value !=
          undefined
        ) {
          this.ValidatePassingScore(type, index);
        }
      }
    }
  }

  ValidatePassingScore(type, index) {
    //  this.contentForm.setErrors({'isInvalid':false});
    const digitsRegEx = new RegExp(/^\d+(\.\d+)?$/, 'g');
    const regEx = new RegExp(/^\d+(\.{0,1}\d{0,2})?$/, 'g');
    if (type == 'Assessment') {
      this.assessmentPassingScoreValue = this.contentForm.get(
        'assessmentGroup.passingScore'
      ).value;
      this.assessmentMaxScoreValue = this.contentForm.get(
        'assessmentGroup.maxScore'
      ).value;
      const value = this.contentForm.get('assessmentGroup.passingScore').value;
      if (value != undefined && value.length != 0) {
        if (!digitsRegEx.test(value)) {
          this.assessmentPassingScoreValidation = {
            isInvalid: true,
            errMsg: 'Please enter numbers only. Upto 2 decimals are allowed.'
          };
        } else if (
          Math.ceil(value) >
          this.contentForm.get('assessmentGroup.maxScore').value
        ) {
          if (
            this.contentForm.get('assessmentGroup.maxScore').value.length == 0
          ) {
            // this.assessmentPassingScoreValidation = {
            //   isInvalid: true,
            //   errMsg: 'Max score is empty. Please set a valid max score.'
            // };
            this.assessmentMaxScoreValidation = {
              isInvalid: true,
              errMsg: 'Please enter a value <= 500.'
            };
            this.assessmentPassingScoreValidation = {
              isInvalid: true,
              errMsg: 'Please enter max score.'
            };
          } else {
            this.assessmentPassingScoreValidation = {
              isInvalid: true,
              errMsg: 'Passing score should be less than max score.'
            };
          }
        } else if (
          Math.ceil(value) >=
          this.contentForm.get('assessmentGroup.maxScore').value
        ) {
          this.assessmentPassingScoreValidation = {
            isInvalid: true,
            errMsg: 'Passing score should be less than max score.'
          };
        } else if (value == 0) {
          this.assessmentPassingScoreValidation = {
            isInvalid: true,
            errMsg: 'Please enter a value greater than zero.'
          };
        } else {
          if (!regEx.test(value)) {
            this.assessmentPassingScoreValidation = {
              isInvalid: true,
              errMsg: 'Please enter score upto 2 decimal points.'
            };
          } else {
            this.assessmentPassingScoreValidation = {
              isInvalid: false,
              errMsg: ''
            };
          }
        }
      } else {
        this.assessmentPassingScoreValidation = {
          isInvalid: false,
          errMsg: ''
        };
      }
    } else if (type == 'Project') {
      const projectDetailsControl = <FormArray>(
        this.contentForm.get('projectGroup.projectWorkDetails')
      );
      const value = projectDetailsControl.controls[index].get('passingScore')
        .value;

      this.projectPassingScoreValue[index] = projectDetailsControl.controls[
        index
      ].get('passingScore').value;
      this.projectMaxScoreValue[index] = projectDetailsControl.controls[
        index
      ].get('maxScore').value;

      if (value != undefined && value.length != 0) {
        if (!digitsRegEx.test(value)) {
          this.projectPassingScoreValidation[index] = {
            isInvalid: true,
            errMsg: 'Please enter numbers only.'
          };
        } else if (
          Math.ceil(value) >
          projectDetailsControl.controls[index].get('maxScore').value
        ) {
          if (
            projectDetailsControl.controls[index].get('maxScore').value
              .length == 0
          ) {
            // this.projectPassingScoreValidation[index] = {
            //   isInvalid: true,
            //   errMsg: 'Max score is empty. Please set a valid max score.'
            // };
            this.projectMaxScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter a value <= 500.'
            };
            this.projectPassingScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter max score.'
            };
          } else {
            this.projectPassingScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Passing score should be less than max score.'
            };
          }
        } else if (
          Math.ceil(value) >=
          projectDetailsControl.controls[index].get('maxScore').value
        ) {
          this.projectPassingScoreValidation[index] = {
            isInvalid: true,
            errMsg: 'Passing score should be less than max score.'
          };
        } else if (value == 0) {
          this.projectPassingScoreValidation[index] = {
            isInvalid: true,
            errMsg: 'Please enter a value greater than zero.'
          };
        } else {
          if (!regEx.test(value)) {
            this.projectPassingScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter score upto 2 decimal points.'
            };
          } else {
            this.projectPassingScoreValidation[index] = {
              isInvalid: false,
              errMsg: ''
            };
          }
        }
      } else {
        this.projectPassingScoreValidation[index] = {
          isInvalid: false,
          errMsg: ''
        };
      }
    } else if (type == 'ClassRoom') {
      const classRoomControl = <FormArray>(
        this.contentForm.get('classRoomGroup.classRoom')
      );
      const value = classRoomControl.controls[index].get('passingScore').value;

      this.classroomPassingScoreValue[index] = classRoomControl.controls[
        index
      ].get('passingScore').value;
      this.classroomMaxScoreValue[index] = classRoomControl.controls[index].get(
        'maxScore'
      ).value;

      if (value != undefined && value.length != 0) {
        if (!digitsRegEx.test(value)) {
          this.classroomPassingScoreValidation[index] = {
            isInvalid: true,
            errMsg: 'Please enter numbers only.'
          };
        } else if (
          Math.ceil(value) >
          classRoomControl.controls[index].get('maxScore').value
        ) {
          if (
            classRoomControl.controls[index].get('maxScore').value.length == 0
          ) {
            // this.classroomPassingScoreValidation[index] = {
            //   isInvalid: true,
            //   errMsg: 'Max score is empty. Please set a valid Max score.'
            // };
            this.classroomMaxScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter a value <= 500.'
            };
            this.classroomPassingScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter max score.'
            };
          } else {
            this.classroomPassingScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Passing score should be less than max score.'
            };
          }
        } else if (
          Math.ceil(value) >=
          classRoomControl.controls[index].get('maxScore').value
        ) {
          this.classroomPassingScoreValidation[index] = {
            isInvalid: true,
            errMsg: 'Passing score should be less than max score.'
          };
        } else if (value == 0) {
          this.classroomPassingScoreValidation[index] = {
            isInvalid: true,
            errMsg: 'Please enter a value greater than zero.'
          };
        } else {
          if (!regEx.test(value)) {
            this.classroomPassingScoreValidation[index] = {
              isInvalid: true,
              errMsg: 'Please enter score upto 2 decimal points.'
            };
          } else {
            this.classroomPassingScoreValidation[index] = {
              isInvalid: false,
              errMsg: ''
            };
          }
        }
      } else {
        this.classroomPassingScoreValidation[index] = {
          isInvalid: false,
          errMsg: ''
        };
      }
    }
  }
  basicCourseClick() {
    this.basicCourseTabClicked = true;
    this.operationalCourseTabClicked = false;
  }

  operationalCourseClick() {
    if (this.contentForm.valid || this.contentForm.disabled) {
      this.basicCourseTabClicked = false;
      this.operationalCourseTabClicked = true;
    }
  }

  getTaxonomy() {
    this.academyList = [];
    this._contentUploadSvc.getTaxonomy().subscribe(
      (response: any) => {
        if (
          response != null &&
          response.AcademyList != undefined &&
          response.AcademyList.length > 0
        ) {
          this.taxonomy = response.AcademyList;
          this.taxonomy.forEach(value => {
            this.academyList.push(value.Academy);
          });
        } else {
          this.taxonomy = [];
        }
        this.getYorbitCourses();
      },
      (error: any) => {
        this.taxonomy = [];
      }
    );
  }

  filterGenre() {
    this.genreList = [];
    this.genreSkillList = [];
    this.contentForm.controls['genre'].setValue('');
    if (this.taxonomy !== undefined) {
      this.taxonomy.forEach(value => {
        if (this.contentForm.get('academy').value !== '') {
          this.contentForm.get('genre').enable();
          if (
            value.Academy.toLowerCase() ===
            this.contentForm.get('academy').value.toLowerCase()
          ) {
            this.genreSkillList = value.GenreSkillList;
          }
        }
      });
      if (this.genreSkillList.length > 0) {
        this.genreSkillList.forEach(value => {
          this.genreList.push(value.Genre);
        });
        this.contentForm.controls['skill'].setValue('');
        this.contentForm.get('skill').disable();

        this.genreList = this.genreList.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));

        return this.genreList;
      } else {
        return [];
      }
    }
  }

  filterSkill() {
    this.skillList = [];
    this.contentForm.controls['skill'].setValue('');
    if (this.genreSkillList.length > 0) {
      this.genreSkillList.forEach(value => {
        if (this.contentForm.get('genre').value !== '') {
          this.contentForm.get('skill').enable();
          if (
            value.Genre.toLowerCase() ===
            this.contentForm.get('genre').value.toLowerCase()
          ) {
            this.skillList = value.Skills;
          }
        }
      });
      if (this.skillList.length > 0) {
        this.skillList = this.skillList.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));

        return this.skillList;
      } else {
        // this.noResultsForSkill = true;
        return [];
      }
    } else {
      return [];
    }
  }

  getVendor() {
    this._contentUploadSvc.getVendor().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.vendorList = response.sort((a, b) => (a.Vendor.toLowerCase() > b.Vendor.toLowerCase() ? 1 : -1));
        } else {
          this.vendorList = [];
        }
      },
      (error: any) => {
        this.vendorList = [];
      }
    );
  }

  getCourseTypeList(expertise) {
    this._contentUploadSvc.getCourseTypeList(expertise).subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseTypeList = response;
        } else {
          this.courseTypeList = [];
        }
      },
      (error: any) => {
        this.courseTypeList = [];
      }
    );
  }

  getCourseModeOfPayment() {
    this._contentUploadSvc.getCourseModeOfPayment().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseModeOfPaymentList = response;
        } else {
          this.courseModeOfPaymentList = [];
        }
      },
      (error: any) => {
        this.courseModeOfPaymentList = [];
      }
    );
  }
  getCourseClassification() {
    this._contentUploadSvc.getCourseClassification().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseClassificationList = response;
        } else {
          this.courseClassificationList = [];
        }
      },
      (error: any) => {
        this.courseClassificationList = [];
      }
    );
  }
  getCourseInfrastructure() {
    this._contentUploadSvc.getCourseInfrastructure().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseInfrastructureList = response;
          this.buildInfrastructure();
          this.buildAssesmentInfrastructure();

          const control = <FormArray>(
            this.contentForm.controls['infrastructure']
          );
          control.controls[4].setValue(true);

          const assessmentControl = <FormArray>(
            this.contentForm.get('assessmentGroup.assesmentInfrastructure')
          );
          assessmentControl.controls[4].setValue(true);
        } else {
          this.courseInfrastructureList = [];
        }
      },
      (error: any) => {
        this.courseInfrastructureList = [];
      }
    );
  }
  getCourseInfrastructureType() {
    this._contentUploadSvc.getCourseInfrastructureType().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseInfrastructureTypeList = response;
          this.buildInfrastructureType();
          this.buildAssesmentInfrastructureType();
          const control = <FormArray>(
            this.contentForm.controls['infrastructureType']
          );
          control.controls[6].setValue('None of the Above');
          this.contentForm.controls['isInfrastructure'].setValue(true);

          const assessmentControl = <FormArray>(
            this.contentForm.get('assessmentGroup.assesmentInfrastructureType')
          );
          if (assessmentControl)
            assessmentControl.controls[6].setValue('None of the Above');
        } else {
          this.courseInfrastructureTypeList = [];
        }
      },
      (error: any) => {
        this.courseInfrastructureTypeList = [];
      }
    );
  }
  getCourseLogin() {
    this._contentUploadSvc.getCourseLogin().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseLoginList = response;
          this.buildLoginType();
          const controls = <FormArray>this.contentForm.controls['loginType'];
          controls.controls[0].setValue(true);
          this.contentForm.controls['isLoginRequired'].setValue(true);
        } else {
          this.courseLoginList = [];
        }
      },
      (error: any) => {
        this.courseLoginList = [];
      }
    );
  }
  getCourseLoginType() {
    this._contentUploadSvc.getCourseLoginType().subscribe(
      (response: any) => {
        this.contentForm.controls['isLoginRequired'].setValue(true);
        if (response != null && response.length > 0) {
          this.courseLoginTypeList = response;
          this.buildLearnertoSelfRegistrationForLink();
          this.buildLearnertoSelfRegistrationForName();
          this.buildLearnertoOPMRegistrationForLink();
          this.buildLearnertoOPMRegistrationForName();
        } else {
          this.courseLoginTypeList = [];
        }
      },
      (error: any) => {
        this.courseLoginTypeList = [];
      }
    );
  }
  getCourseSessionType() {
    this._contentUploadSvc.getCourseSessionType().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseSessionTypeList = response;
        } else {
          this.courseSessionTypeList = [];
        }
      },
      (error: any) => {
        this.courseSessionTypeList = [];
      }
    );
  }
  getCourseCompletion() {
    this._contentUploadSvc.getCourseCompletion().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseCompletionList = response;
          this.buildCourseCompletion();
        } else {
          this.courseCompletionList = [];
        }
      },
      (error: any) => {
        this.courseCompletionList = [];
      }
    );
  }
  getCourseCompletionType() {
    this._contentUploadSvc.getCourseCompletionType().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseCompletionTypeList = this.pssContentApprovalService.constructcourseCompletionType(
            response
          );
          this.buildCourseCompletionType();
        } else {
          this.courseCompletionTypeList = [];
        }
      },
      (error: any) => {
        this.courseCompletionTypeList = [];
      }
    );
  }

  getCourseEligibility() {
    this._contentUploadSvc.getCourseEligibility().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseEligibilityList = response;
          this.courseEligibilityList.forEach(value => {
            if (value.EligibleGroup === 'Offshore Minds')
              this.contentForm.controls['eligibility'].setValue(value.Id);
          });
        } else {
          this.courseEligibilityList = [];
        }
      },
      (error: any) => {
        this.courseEligibilityList = [];
      }
    );
  }

  getCourseAccessibility() {
    this._contentUploadSvc.getCourseAccessibility().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseAccessibilityList = response;
          this.courseAccessibilityList.forEach(value => {
            if (value.Access === 'Open')
              this.contentForm.controls['accessibility'].setValue(value.Id);
          });
        } else {
          this.courseAccessibilityList = [];
        }
      },
      (error: any) => {
        this.courseAccessibilityList = [];
      }
    );
  }
  uploadFile(fileType: string, fileSubType: string, index: number, event) {
    //console.log('filtype', fileType, fileSubType, index, event);
    this.validateAllCondition(fileType, fileSubType, index, event);
  }
  showErrorMsgForFileUpload(
    fileType: string,
    fileSubType: string,
    index: number
  ) {
    if (fileType == 'assessmentdetails') {
      if (fileSubType == 'OnlineProblemStatement') {
        this.inputForOnlineQuestionPaper = 'onlineQuestionPaper';
      } else if (fileSubType == 'evaluator') {
        this.inputForAssessmentEvaluationTemplate = 'evaluationTemplate';
      }
    } else if (fileType == 'projectdetails') {
      if (fileSubType == 'evaluator') {
        this.inputForProjectEvaluationTemplateFileUpload =
          'projectEvaluationTemplateFileUpload';
      } else if (fileSubType == 'Material') {
        this.inputForProjectMaterial = 'projectMaterial';
      }
    } else if (fileType == 'classroomdetails') {
      if (fileSubType == 'evaluator') {
        this.inputForAssignmentEvaluationTemplate = 'evaluationTemplate';
      }
    }
    if (fileType != 'All') {
      if (this.fileUploadInvalid[fileType] == undefined) {
        this.fileUploadInvalid[fileType] = {};
      }
      if (this.fileUploadInvalid[fileType][fileSubType] == undefined) {
        this.fileUploadInvalid[fileType][fileSubType] = {};
      }
      if (this.fileUploadInvalid[fileType][fileSubType][index] == undefined) {
        this.fileUploadInvalid[fileType][fileSubType][index] = {};
      }
    }
    const extension = this.eUploadFile[fileType][fileSubType][index].name
      .split('.')
      .pop()
      .toLowerCase();
    if (
      fileType != 'CourseProcedureReferenceDocument' &&
      fileType != 'All' &&
      this.eUploadFile[fileType] &&
      this.eUploadFile[fileType][fileSubType] &&
      this.eUploadFile[fileType][fileSubType][index] &&
      (extension == 'zip' || extension == '7z')
    ) {
      if (
        (fileType == 'learningmaterial' &&
          this.eUploadFile[fileType][fileSubType][index].size / (1024 * 1024) >
          25) ||
        (fileType != 'learningmaterial' &&
          this.eUploadFile[fileType][fileSubType][index].size / (1024 * 1024) >
          5)
      ) {
        this.fileUploadInvalid[fileType][fileSubType][index] = true;
        return true;
      } else {
        this.fileUploadInvalid[fileType][fileSubType][index] = false;

        return false;
      }
    } else if (
      fileType == 'CourseProcedureReferenceDocument' &&
      this.eUploadFile[fileType] &&
      this.eUploadFile[fileType][fileSubType] &&
      this.eUploadFile[fileType][fileSubType][index] &&
      (extension == 'eml' || extension == 'msg')
    ) {
      if (
        this.eUploadFile[fileType][fileSubType][index].size / (1024 * 1024) > 10
      ) {
        this.fileUploadInvalid[fileType][fileSubType][index] = true;
        return true;
      } else {
        this.fileUploadInvalid[fileType][fileSubType][index] = false;

        return false;
      }
    } else {
      this.fileUploadInvalid[fileType][fileSubType][index] = true;
      return false;
    }
  }
  validateAllCondition(fileType: string, fileSubType: string, index: number, event) {
    let isButtonControl = false;
    if (event !== undefined) {
      if (event.currentTarget !== undefined && event.currentTarget.type === 'button')
        isButtonControl = true;
    }
    //console.log('hit', fileType, this.eUploadFile);
    if (
      fileType != 'All' &&
      this.eUploadFile[fileType][fileSubType][index] !== null &&
      this.eUploadFile[fileType][fileSubType][index] !== undefined
    ) {
      const uploadedFileSize =
        this.eUploadFile[fileType][fileSubType][index].size / (1024 * 1024);
      const extension = this.eUploadFile[fileType][fileSubType][index].name
        .split('.')
        .pop()
        .toLowerCase();
      // console.log('extension', extension);
      if ((extension == 'zip' || extension == '7z') && fileSubType !== 'All' && fileType != 'CourseProcedureReferenceDocument') {
        if (
          (fileType == 'learningmaterial' && uploadedFileSize <= 25) ||
          (fileType != 'learningmaterial' &&
            uploadedFileSize <= 5 &&
            fileSubType !== 'All')
        ) {
          this.fileNotSelected = false;
        } else {
          this.fileNotSelected = true;
          if (isButtonControl) {
            event.target.title = 'Upload';
            event.target.innerText = 'Upload';
          }
        }
      } else if (fileType == 'CourseProcedureReferenceDocument' && ((extension == 'eml' || extension == 'msg'))) {
        if (
          (fileType == 'CourseProcedureReferenceDocument' && uploadedFileSize <= 10) ||
          (fileType != 'CourseProcedureReferenceDocument' &&
            uploadedFileSize <= 5 &&
            fileSubType !== 'All')
        ) {
          this.fileNotSelected = false;
        } else {
          this.fileNotSelected = true;
          if (isButtonControl) {
            event.target.title = 'Upload';
            event.target.innerText = 'Upload';
          }
        }
      }
      else {
        this.fileNotSelected = true;
        if (isButtonControl) {
          event.target.title = '';
          let sizeLimit = '5MB';
          if (fileType == 'learningmaterial') {
            sizeLimit = '25MB';
            event.target.innerText = 'Please upload a zip file of size less than ' + sizeLimit + '.';
            event.target.className = 'buttonError actionButton marginTop10';
          }
          else if (fileType == 'CourseProcedureReferenceDocument') {
            sizeLimit = '10MB';
            event.target.innerText = 'Please upload a eml or msg file of size less than ' + sizeLimit + '.';
            event.target.className = 'buttonError actionButton marginTop10';
          }
          else {
            event.target.innerText = 'Please upload a zip file of size less than ' + sizeLimit + '.';
            event.target.className = 'buttonError actionButton marginTop10';
          }
        }
      }
      if (fileSubType !== 'All' && !this.fileNotSelected) {
        if (isButtonControl) {
          event.target.title = 'Uploading...';
          event.target.innerText = 'Uploading...';
        }
        this.apiCallForBlob(fileType, fileSubType, index, event);
      }
    }
  }
  apiCallForBlob(fileType: string, fileSubType: string, index: number, event) {
    this._contentUploadSvc
      .uploadToBlob(
        this.eUploadFile[fileType][fileSubType][index],
        this.courseUniqueId,
        this.contentForm.get('academy').value,
        this.contentForm.get('name').value,
        fileType,
        fileSubType,
        fileType === 'classroomdetails' || fileType === 'projectdetails'
          ? String(index + 1)
          : null
      )
      .then((response: any) => {
        event.target.title = 'Uploaded';
        event.target.innerText = 'Uploaded';
        if (fileSubType === 'Pre') {
          this.contentForm
            .get('preSessionGroup.preSessionLearningMaterialLink')
            .setValue(response.Msg);
        } else if (fileSubType === 'OnGoing') {
          this.contentForm
            .get('duringSessionGroup.duringSessionLearningMaterialLink')
            .setValue(response.Msg);
        } else if (fileType !== 'CourseProcedureReferenceDocument' && fileSubType === 'Post') {
          const postValue = this.contentForm.get(
            'postSessionGroup.postSessionLearningMaterialLink'
          );
          postValue.patchValue(response.Msg);
        } else if (
          fileSubType === 'Sample Certificate' ||
          fileSubType === 'Sample Badge' ||
          fileSubType === 'Sample Screenshot' ||
          fileSubType === 'Sample Letter Head'
        ) {
          const controls = <FormArray>(
            this.contentForm.get(
              'courseCompletionGroup.courseCompletionTypeFormArray'
            )
          );
          if (fileSubType === 'Sample Certificate') {
            this.contentForm.get('certificateLink').setValue(response.Msg);
            this.btnInputCourseCompletionTypeFileBox[0] = 'Uploaded';
          } else if (fileSubType === 'Sample Badge') {
            this.contentForm.get('badgeLink').setValue(response.Msg);
            this.btnInputCourseCompletionTypeFileBox[1] = 'Uploaded';
          } else if (fileSubType === 'Sample Screenshot') {
            this.contentForm.get('sreenShotLink').setValue(response.Msg);
            this.btnInputCourseCompletionTypeFileBox[2] = 'Uploaded';
          } else if (fileSubType === 'Sample Letter Head') {
            this.contentForm.get('letterHeadLink').setValue(response.Msg);
            this.btnInputCourseCompletionTypeFileBox[3] = 'Uploaded';
          }
        } else if (
          (fileSubType === 'ProblemStatement' || fileSubType === 'evaluator') &&
          fileType === 'classroomdetails'
        ) {
          const classRoomcontrols = <FormArray>(
            this.contentForm.get('classRoomGroup.classRoom')
          );
          fileSubType === 'ProblemStatement'
            ? classRoomcontrols.controls[index]
              .get('assignmentContentLink')
              .setValue(response.Msg)
            : classRoomcontrols.controls[index]
              .get('evaluationTemplateLink')
              .setValue(response.Msg);
          if (fileSubType == 'evaluator') {
            this.btnInputAssignmentEvaluationTemplate = 'Uploaded';
          }
        } else if (
          fileSubType === 'Material' ||
          fileSubType === 'ProblemStatement' ||
          (fileSubType === 'evaluator' && fileType === 'projectdetails')
        ) {
          const projectControls = <FormArray>(
            this.contentForm.get('projectGroup.projectWorkDetails')
          );
          if (fileSubType === 'Material') {
            projectControls.controls[index]
              .get('projectMaterialLink')
              .setValue(response.Msg);
            this.btnInputProjectMaterial = 'Uploaded';
          } else if (fileSubType === 'ProblemStatement') {
            projectControls.controls[index]
              .get('problemStatementLink')
              .setValue(response.Msg);
          } else if (fileSubType === 'evaluator') {
            projectControls.controls[index]
              .get('evaluationTemplateLink')
              .setValue(response.Msg);
            this.btnInputProjectEvaluationTemplateFileUpload = 'Uploaded';
          }
        } else if (
          (fileSubType === 'OnlineProblemStatement' ||
            fileSubType === 'OfflineProblemStatement' ||
            fileSubType === 'evaluator') &&
          fileType === 'assessmentdetails'
        ) {
          if (fileSubType === 'OnlineProblemStatement') {
            this.contentForm
              .get('assessmentGroup.onlineQuestionPaperLink')
              .setValue(response.Msg);
            this.btnInputOnlineQuestionPaper = 'Uploaded';
          } else if (fileSubType === 'OfflineProblemStatement') {
            this.contentForm
              .get('assessmentGroup.questionPaperLink')
              .setValue(response.Msg);
          } else {
            this.contentForm
              .get('assessmentGroup.evaluationTemplateLink')
              .setValue(response.Msg);
            this.btnInputAssessmentEvaluationTemplate = 'Uploaded';
          }
        }
        else {
          if (fileType === 'CourseProcedureReferenceDocument') {
            this.courseRefLink = response.Msg;
            this.contentForm.get('courseProcedureRefDocGroup.courseProcedureRefDocLink').setValue(this.courseRefLink);
          }
        }
        delete this.eUploadFile[fileType][fileSubType][index];
      })
      .catch((error: any) => {
        //this.openpopup('failure', 'some thing went wrong !');
      });
  }

  openConfirmationPopup(type) {
    //console.log(this.contentForm.getRawValue());
    this.openpopup('', this.contentForm.getRawValue(), type);

  }

  OnContentFormSubmit(type) {
    const headers = new HttpHeaders().set('content-type', 'application/json');

    this.buildContentOrder();

    //Set Course Completion Array
    const controls = <FormArray>(
      this.contentForm.get(
        'courseCompletionGroup.courseCompletionTypeFormArray'
      )
    );
    let counter = 0;
    for (let controlItem of controls.controls) {
      if (counter == 2 || counter == 5 || counter == 8 || counter == 11) {
        if (controlItem.value == null || controlItem.value == '') {
          if (counter == 2) {
            controlItem.setValue(this.contentForm.get('certificateLink').value);
            this.courseCompletionDataForPreview[2] = this.contentForm.get('certificateLink').value;
          } else if (counter == 5) {
            controlItem.setValue(this.contentForm.get('badgeLink').value);
            this.courseCompletionDataForPreview[5] = this.contentForm.get('badgeLink').value;
          } else if (counter == 8) {
            controlItem.setValue(this.contentForm.get('sreenShotLink').value);
            this.courseCompletionDataForPreview[8] = this.contentForm.get('sreenShotLink').value;
          } else if (counter == 11) {
            controlItem.setValue(this.contentForm.get('letterHeadLink').value);
            this.courseCompletionDataForPreview[11] = this.contentForm.get('letterHeadLink').value;
          }
        }
      }
      counter++;
    }


    this.contentUploadData = JSON.parse(
      JSON.stringify(this.contentForm.getRawValue())
    );

    if (this.contentUploadData.creditSpecial == "Yes") {
      this.contentUploadData.IsCertificateAvailable = Number(this.contentUploadData.IsCertificateAvailable);
    }
    else {
      this.contentUploadData.IsCertificateAvailable = 0;
    }

    this.contentUploadData.IsCloudLabAvailable = Number(this.contentUploadData.IsCloudLabAvailable);
    this.setRadioValue();

    this.contentUploadData.IsMailAlertRequired = Number(this.contentUploadData.IsMailAlertRequired);
    this.setRadioValue();


    this.contentUploadData.ContentOwner = this.contentOwnerMid;

    this.resetPayloadLoginDetails();

    if (this.contentUploadData.assessmentGroup.assessmentRadio == false) {
      this.resetPayloadAssessmentGroup();
    }
    if (this.contentUploadData.courseProcedureRefDocGroup.courseProcedureRefDocLink == null) {
      this.resetPayloadCourseProcedureReferenceDocumentGroup();
    }
    if (this.contentUploadData.classRoomGroup.isClassRoomRequired == false) {
      this.resetPayloadClassroomGroup();
    }
    if (
      this.contentUploadData.projectGroup.hasProjectWork == false ||
      this.contentUploadData.projectGroup.hasProjectEvaluation == false
    ) {
      this.resetPayloadProjectGroup();
    }

    if (
      this.contentForm.get('paid').value == 'Yes' ||
      this.contentForm.get('paid').value == true
    ) {
      this.contentUploadData.price =
        this.contentForm.get('price').value +
        ' ' +
        this.contentForm.get('currency').value;
    }
    this.insertInProgress = true;
    this.hasInsertFailed = false;
    this.contentUploadData.courseCompletionGroup.Status = null;
    this.contentUploadData.courseCompletionGroup.SubStatus = null;

    // this.contentForm.disable();
    this._contentUploadSvc
      .insertContentFormFor201(
        this.contentUploadData,
        { headers },
        this.courseUniqueId,
        type
      )
      .subscribe(
        (response: any) => {
          this.insertInProgress = false;
          //  this.contentForm.enable();
          if (response && !this.isNullOrEmpty(response)) {
            this.courseUniqueId = response;
            if (type === 'save') {
              const data = {
                msg:
                  'Your inputs have been saved successfully. <br/> Do you want to continue?',
                title: 'Confirmation'
              };
              this.openStatusPopup(data, type);
              this.courseFor201Quiz = {
                'UniqueId': this.courseUniqueId,
                'IsSubmittedOrApprovedCourse': this.courseStatus.toLowerCase() === 'submitted',
                'Name': '',
                'FromHomePage': true,
                'expertise': '201',
                'fromContentUpload201': true
              }
            } else if (type == 'submit') {
              const data = (this.contentUploadData.classRoomGroup.isClassRoomRequired == 1) ? {
                msg:
                  'Your requests have been submitted successfully and is pending for verification.',
                title: 'Information'
              } : {
                msg:
                  'Your inputs have been submitted to Operations team for verification.',
                title: 'Information'
              }
              type = 'submitted';
              this.openStatusPopup(data, type);
            } else {
              this.courseUniqueId = null;
              // this.router.navigate(['contentuploadGrid/' + 'AO']);
              this.enableContentVerificationComponent.emit('201');
            }
          } else {
            this.courseUniqueId = null;
            this.ngOnInit();
            this.basicCourseClick();
            this.contentForm.reset();
          }
        },
        (error: any) => {
          this.insertInProgress = false;
          this.hasInsertFailed = true;
          this.courseAccessibilityList = [];
          //  this.contentForm.enable();
          const data =
            'Something went wrong while saving the content.<br/>Please try later.';
          this.openStatusPopup(data, 'failed');
        }
      );
  }
  setRadioValue() {
    this.contentForm.get('internal').value === 'No'
      ? (this.contentUploadData.internal = false)
      : (this.contentUploadData.internal = true);
    this.contentForm.get('paid').value === 'No'
      ? (this.contentUploadData.paid = false)
      : (this.contentUploadData.paid = true);
    this.contentForm.get('managerApproval').value === 'No'
      ? (this.contentUploadData.managerApproval = false)
      : (this.contentUploadData.managerApproval = true);
    this.contentForm.get('isPreRequisiteMandatory').value === 'No'
      ? (this.contentUploadData.isPreRequisiteMandatory = false)
      : (this.contentUploadData.isPreRequisiteMandatory = true);
    this.contentForm.get('isIntroductoryVideo').value === 'No'
      ? (this.contentUploadData.isIntroductoryVideo = false)
      : (this.contentUploadData.isIntroductoryVideo = true);
    this.contentForm.get('creditSpecial').value === 'No'
      ? (this.contentUploadData.creditSpecial = false)
      : (this.contentUploadData.creditSpecial = true);
    this.contentForm.get('isLearningMaterial').value === 'No'
      ? (this.contentUploadData.isLearningMaterial = false)
      : (this.contentUploadData.isLearningMaterial = true);
    this.contentForm.get('isCourseProcedureReferenceDocument').value === 'No'
      ? (this.contentUploadData.isCourseProcedureReferenceDocument = false)
      : (this.contentUploadData.isCourseProcedureReferenceDocument = true);
    this.contentForm.get('classRoomGroup.isClassRoomRequired').value === 'No'
      ? (this.contentUploadData.classRoomGroup.isClassRoomRequired = false)
      : (this.contentUploadData.classRoomGroup.isClassRoomRequired = true);
    this.contentForm.get('projectGroup.hasProjectWork').value === 'No'
      ? (this.contentUploadData.projectGroup.hasProjectWork = false)
      : (this.contentUploadData.projectGroup.hasProjectWork = true);
    this.contentForm.get('projectGroup.hasJumbleProject').value === 'No'
      ? (this.contentUploadData.projectGroup.hasJumbleProject = false)
      : (this.contentUploadData.projectGroup.hasJumbleProject = true);
    this.contentForm.getRawValue().projectGroup.hasProjectEvaluation === 'No'
      ? (this.contentUploadData.projectGroup.hasProjectEvaluation = false)
      : (this.contentUploadData.projectGroup.hasProjectEvaluation = true);
    this.contentForm.get('assessmentGroup.assessmentRadio').value === 'No'
      ? (this.contentUploadData.assessmentGroup.assessmentRadio = false)
      : (this.contentUploadData.assessmentGroup.assessmentRadio = true);
    this.contentForm.get('assessmentGroup.isAssessmentScheduled').value === 'No'
      ? (this.contentUploadData.assessmentGroup.isAssessmentScheduled = false)
      : (this.contentUploadData.assessmentGroup.isAssessmentScheduled = true);
    this.contentForm.get('quizGroup.quizRadio').value === 'No'
      ? (this.contentUploadData.quizGroup.quizRadio = false)
      : (this.contentUploadData.quizGroup.quizRadio = true);
  }

  onInfrastructureChange(event, index, item) {
    const controls = <FormArray>this.contentForm.controls['infrastructure'];
    let counter = 0;
    if (item && item.value == false) {
      // this.clearInfrastructureTextBoxValues(index);
    }

    for (let controlItem of controls.controls) {
      if (index === 4) {
        controls.controls[0].setValue(false);
        //  this.clearInfrastructureTextBoxValues(0);
        controls.controls[1].setValue(false);
        //  this.clearInfrastructureTextBoxValues(1);
        controls.controls[2].setValue(false);
        //    this.clearInfrastructureTextBoxValues(2);
        controls.controls[3].setValue(false);
      } else {
        controls.controls[4].setValue(false);
      }
      if (controlItem.value === true) {
        this.contentForm.controls['isInfrastructure'].setValue(true);
      }
      counter++;
    }
  }
  clearInfrastructureTextBoxValues(index) {
    const controls = <FormArray>this.contentForm.controls['infrastructureType'];
    let counter = 0;
    if (index === 0 || index === 1) {
      controls.controls[index].setValue('');
    } else if (index === 2) {
      controls.controls[index].setValue('');
      controls.controls[index + 1].setValue('');
    } else if (index === 3) {
      controls.controls[index + 1].setValue('');
      controls.controls[index + 2].setValue('');
    }
  }

  onAssessmentInfrastructureChange(event, index, item) {
    const controls = <FormArray>(
      this.contentForm.get('assessmentGroup.assesmentInfrastructure')
    );
    let counter = 0;
    if (item && item.value == false) {
      this.clearAssessmentInfrastructureTextBoxValues(index);
    }
    for (let controlItem of controls.controls) {
      if (index === 4) {
        controls.controls[0].setValue(false);
        this.clearAssessmentInfrastructureTextBoxValues(0);
        controls.controls[1].setValue(false);
        this.clearAssessmentInfrastructureTextBoxValues(1);
        controls.controls[2].setValue(false);
        this.clearAssessmentInfrastructureTextBoxValues(2);
        controls.controls[3].setValue(false);
        this.clearAssessmentInfrastructureTextBoxValues(3);
      } else {
        controls.controls[4].setValue(false);
      }
      counter++;
    }
  }
  clearAssessmentInfrastructureTextBoxValues(index) {
    const controls = <FormArray>(
      this.contentForm.get('assessmentGroup.assesmentInfrastructureType')
    );
    let counter = 0;
    if (index === 0 || index === 1) {
      controls.controls[index].setValue('');
    } else if (index === 2) {
      controls.controls[index].setValue('');
      controls.controls[index + 1].setValue('');
    } else if (index === 3) {
      controls.controls[index + 1].setValue('');
      controls.controls[index + 2].setValue('');
    }
  }

  onLoginChange(event, index, item) {
    const controls = <FormArray>this.contentForm.controls['loginType'];
    let counter = 0;
    for (let controlItem of controls.controls) {
      if (counter !== index) {
        controlItem.setValue(false);
        //  this.clearLoginDetails(counter);
      }
      if (controlItem.value === true) {
        this.contentForm.controls['isLoginRequired'].setValue(true);
      }
      counter++;
    }
  }
  clearLoginDetails(index) {
    let control;
    if (index === 1) {
      control = <FormArray>(
        this.contentForm.controls['coursesNameForSelfRegistration']
      );

      this.ClearArrayData(control);
      control = <FormArray>(
        this.contentForm.controls['linktoCoursesForSelfRegistration']
      );
      this.ClearArrayData(control);
    }
    if (index === 2) {
      this.contentForm.get('loginDetails').setValue('');
      control = <FormArray>(
        this.contentForm.controls['coursesNameForLearningOPMTeam']
      );
      this.ClearArrayData(control);
      control = <FormArray>(
        this.contentForm.controls['linktoCoursesForLearningOPMTeam']
      );
      this.ClearArrayData(control);
    }
  }
  ClearArrayData(control) {
    for (let i = control.length - 1; i >= 0; i--) {
      if (i !== 0) {
        control.removeAt(i);
      } else {
        control.controls[0].setValue('');
      }
    }
  }

  resetPayloadLoginDetails() {
    if (
      this.contentUploadData.loginType &&
      this.contentUploadData.loginType.length > 0
    ) {
      this.contentUploadData.loginType.forEach((loginType, index) => {
        if (loginType == false) {
          this.clearLoginDetails(index);
        }
      });
    }
  }

  openpopup(status, data, type) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    data.courseCompletionGroup.courseCompletionTypeFormArrayForPreview = this.courseCompletionDataForPreview;

    dialogConfig.data = {
      status: status,
      data: data,
      courseLoginType: this.courseLoginTypeList,
      courseLoginList: this.courseLoginList,
      paymentModeList: this.courseModeOfPaymentList,
      courseEligibilityList: this.courseEligibilityList,
      courseAccessibilityList: this.courseAccessibilityList,
      courseClassificationList: this.courseClassificationList,
      courseUniqueId: this.courseUniqueId
    };
    //log('test...................................',data);
    const response = this._popup.open(
      ContentUploadPopupComponent,
      dialogConfig
    );
    response.afterClosed().subscribe(res => {
      //do something after pop up close
      if (res) {
        const data = {
          msg: 'Are you sure you want to submit?',
          title: 'Submit Form'
        };
        this.openStatusPopup(data, type);
      } else {
        //do nothing
      }
    });
  }
  setPaymentOptions() {
    if (this.contentForm.get('paid').value === 'Yes') {
      this.contentForm.get('price').enable();
      this.contentForm.get('currency').enable();
      this.contentForm.get('paymentMode').enable();
      this.contentForm.get('paymentMode').enable();
    } else {
      this.contentForm.get('price').reset();
      this.contentForm.get('price').disable();
      this.contentForm.get('currency').reset();
      this.contentForm.get('currency').disable();
      this.contentForm.get('paymentMode').reset();
      this.contentForm.get('paymentMode').disable();
      this.contentForm.get('paymentMode').reset();
      this.contentForm.get('paymentMode').disable();
    }
    this.contentForm.controls['paymentMode'].setValue('2');
    this.contentForm.controls['currency'].setValue('INR');
  }
  setVideoURLOptions() {
    if (this.contentForm.get('isIntroductoryVideo').value === 'Yes') {
      this.contentForm.get('sourceType').enable();
      this.contentForm.get('sourceType').setValue('');
      this.contentForm.get('introductoryVideo').enable();
    } else {
      this.contentForm.get('sourceType').reset();
      this.contentForm.get('sourceType').disable();
      this.contentForm.get('introductoryVideo').reset();
      this.contentForm.get('introductoryVideo').disable();
    }
  }

  onPreReqControlChange() {
    this.contentForm
      .get('preRequisiteCourseIDs')
      .valueChanges.subscribe(val => {
        if (val !== '' && val !== null && val.length > 0) {
          this.contentForm.get('isPreRequisiteMandatory').enable();
          //this.contentForm.get('isPreRequisiteMandatory').setValidators(Validators.required);
        } else {
          this.contentForm.get('isPreRequisiteMandatory').setValue('No');
          this.contentForm.get('isPreRequisiteMandatory').disable();
        }
      });
  }

  next() {
    const name = this.contentForm.get('name').value;
    this.findInvalidControls();
    this.contentForm.setErrors({ invalid: true });
    this.setFormErrorMsg(false, '');
    if (this.isEdit === true) {
      this._contentUploadSvc
        .checkCourseNameExistsForEdit(name, this.courseUniqueId)
        .subscribe(isExists => {
          if (!isExists) {
            this.contentForm.setErrors(null);
            this.operationalCourseClick();
          } else {
            this.setFormErrorMsg(true, 'The course name already exists');
            this.contentForm.get('name').setErrors({ isInvalid: true });
            this.contentForm.setErrors({ invalid: true });
          }
        });
    } else {
      this.isEdit = false;
      //this.contentForm.get('name').setErrors({ isInvalid: true });
      this._contentUploadSvc
        .checkIfCourseNameExists(name)
        .subscribe(isExists => {
          if (!isExists) {
            this.contentForm.setErrors(null);
            this.operationalCourseClick();
          } else {
            this.setFormErrorMsg(true, 'The course name already exists');
            this.contentForm.get('name').setErrors({ isInvalid: true });
            this.contentForm.setErrors({ invalid: true });
          }
        });
    }
  }

  save(type) {
    const name = this.contentForm.get('name').value;
    const badge = this.contentForm.get('badge').value;
    const topcisCovered = this.contentForm.get('topicCovered').value;
    const description = this.contentForm.get('description').value;
    this.findInvalidControls();
    if (!name || (name && (name.trim() == '' || name == null))) {
      this.contentForm.setErrors({ invalid: true });
      this.setFormErrorMsg(true, 'Course Name field is empty.');
    } else if (!badge || (badge && (badge.trim() == '' || badge == null))) {
      this.contentForm.setErrors({ invalid: true });
      this.setFormErrorMsg(true, 'Badge Name cannot be empty.');
    } else if (
      !topcisCovered ||
      (topcisCovered && (topcisCovered.trim() == '' || topcisCovered == null))
    ) {
      this.contentForm.setErrors({ invalid: true });
      this.setFormErrorMsg(true, 'Topics Covered cannot be empty.');
    } else if (
      !description ||
      (description && (description.trim() == '' || description == null))
    ) {
      this.contentForm.setErrors({ invalid: true });
      this.setFormErrorMsg(true, 'Description cannot be empty.');
    } else {
      this.contentForm.setErrors({ invalid: true });
      this.setFormErrorMsg(false, '');
      if (this.isEdit === true) {
        this._contentUploadSvc
          .checkCourseNameExistsForEdit(name, this.courseUniqueId)
          .subscribe(isExists => {
            if (!isExists) {
              this.contentForm.setErrors(null);
              this.OnContentFormSubmit(type);
            } else {
              this.setFormErrorMsg(
                true,
                'Sorry we were unable to save the form! Please review the entered data.'
              );
              this.contentForm.get('name').setErrors({ isInvalid: true });
              this.contentForm.setErrors({ invalid: true });
            }
          });
      } else {
        this.isEdit = false;
        //this.contentForm.get('name').setErrors({ isInvalid: true });
        this._contentUploadSvc
          .checkIfCourseNameExists(name)
          .subscribe(isExists => {
            if (!isExists) {
              this.contentForm.setErrors(null);
              this.OnContentFormSubmit(type);
            } else {
              this.setFormErrorMsg(
                true,
                'Sorry we were unable to save the form! Please review the entered data.'
              );
              this.contentForm.get('name').setErrors({ isInvalid: true });
              this.contentForm.setErrors({ invalid: true });
            }
          });
      }
    }
  }
  getYorbitCourses() {
    this.yorbitCoursesLoading = true;
    this._contentUploadSvc.getAllYorbitcourses().subscribe(data => {

      //loading course data to show in the pre and post requisite drop down
      this.courseSvc.YorbitCoursesList = data.filter(x => x.CourseStatus.toLowerCase() == "approved");

      //Added this snippet here to show save alert popup after the form is loaded
      this.yorbitCoursesLoading = false;

      //commented in MSAL build
      // this.saveAlertSubscription = setTimeout(() => {
      //   this.showSaveAlertPopup(this.epochDiffInMins);
      // }, (this.epochDiffInMins - this.offsetTimeoutForAlert) * 60 * 1000);

      if (data != null && data.length > 0) {
        data.forEach(datum => {
          datum.checkedPreReq = false;
          datum.checkedPostRecomm = false;
        });
        this.yorbitCourses = data;
        this.filteredPreReqCourses = data.map(x => x);
        this.filteredPostRecommCourses = data.map(x => x);
        //this.filteredYorbitCourses=[];
        //  this.route.paramMap.subscribe(params => {
        // this.courseUniqueId = params.get('courseUniqueId');
        // this.courseStatus = params.get('status');
        if (this.ContentData !== undefined && this.ContentData !== null) {
          this.courseUniqueId = this.ContentData.UniqueId;
          this.courseStatus = this.ContentData.CourseStatus;
          this.ContentData.IsCertificateAvailable = this.ContentData.IsCertificateAvailable == false ? "0" : "1";
          if (
            this.courseUniqueId &&
            (this.courseStatus.toLowerCase() === 'saved' ||
              this.courseStatus.toLowerCase() === 'denied' ||
              this.courseStatus.toLowerCase() === 'needs review')
          ) {
            this.isEdit = true;
            this.getContentUpload201Details(this.courseUniqueId);
          } else if (
            this.courseUniqueId &&
            (this.courseStatus.toLowerCase() === 'submitted' ||
              this.courseStatus.toLowerCase() === 'uploaded' ||
              this.courseStatus.toLowerCase() === 'approved' ||
              this.courseStatus.toLowerCase() === 'launched')
          ) {
            this.isApproved = true;
            this._contentUploadSvc.isApproved = true;
            this.getContentUpload201Details(this.courseUniqueId);
            this.contentForm.disable();
          } else {
            this.showloading = false;
          }
        } else {
          this.courseUniqueId = null;
          this.showloading = false;
          this.contentForm.get('classification').setValue('1');
        }

        //   });
      } else {
        this.yorbitCourses = [];
        this.filteredPreReqCourses = [];
        this.filteredPostRecommCourses = [];
        this.courseUniqueId = null;
        this.contentForm.get('classification').setValue('1');
      }
      if (this.ContentData) {
        this.courseFor201Quiz = {
          'UniqueId': this.ContentData.UniqueId,
          'IsSubmittedOrApprovedCourse': this.courseStatus.toLowerCase() === 'submitted',
          'Name': this.ContentData.name,
          'FromHomePage': true,
          'expertise': '201',
          'fromContentUpload201': true
        }
      }


    });
  }

  watchChange(event, course, isPreReq) {
    let itemInList = [];
    let courseList1 = [];
    let courseList2 = [];
    if (isPreReq) {
      courseList1 = this.selectedPrereqCourses;
      itemInList = courseList1.filter(x => x.Id === course.Id)[0];
    } else {
      courseList2 = this.selectedPostRecommCourses;
      itemInList = courseList2.filter(x => x.Id === course.Id)[0];
    }
    if (event.target.checked) {
      if (itemInList === undefined || itemInList.length === 0) {
        if (isPreReq)
          courseList1.push({
            Id: course.Id,
            Name: course.Name + ' - ' + course.Expertise
          });
        else
          courseList2.push({
            Id: course.Id,
            Name: course.Name + ' - ' + course.Expertise
          });
      }
    } else if (!event.target.checked) {
      if (itemInList.length !== 0) {
        if (isPreReq) {
          const pos = courseList1
            .map(function (e) {
              return e.Id;
            })
            .indexOf(course.Id);
          courseList1.splice(pos, 1);
        } else {
          const pos = courseList2
            .map(function (e) {
              return e.Id;
            })
            .indexOf(course.Id);
          courseList2.splice(pos, 1);
        }
      }
    }
    if (isPreReq) {
      this.selectedPrereqCourses = courseList1.map(x => x);
      this.contentForm
        .get('preRequisiteCourseIDs')
        .setValue(this.selectedPrereqCourses.map(x => x.Id));
    } else if (!isPreReq) {
      this.selectedPostRecommCourses = courseList2.map(x => x);
      this.contentForm
        .get('postRecommendedCourseIDs')
        .setValue(this.selectedPostRecommCourses.map(x => x.Id));
    }
  }

  filterName(course) {
    let courses = [];
    courses = this.yorbitCourses.filter(x => {
      const name = x.Name.toLowerCase();
      if (name.indexOf(course.toLowerCase()) !== -1) return true;
    });
    if (courses.length > 0) {
      return courses;
    }
    return [];
  }

  removeItem(course, isPreReq) {
    let itemInList = [];
    let courseList1 = [];
    let courseList2 = [];
    if (isPreReq) {
      courseList1 = this.selectedPrereqCourses;
      itemInList = courseList1.filter(x => x.Id === course.Id);
    } else {
      courseList2 = this.selectedPostRecommCourses;
      itemInList = courseList2.filter(x => x.Id === course.Id);
    }
    if (itemInList || itemInList.length !== 0) {
      if (isPreReq) {
        const pos = courseList1
          .map(function (e) {
            return e.Id;
          })
          .indexOf(course.Id);
        courseList1.splice(pos, 1);
      } else {
        const pos = courseList2
          .map(function (e) {
            return e.Id;
          })
          .indexOf(course.Id);
        courseList2.splice(pos, 1);
      }
    }
    if (isPreReq) {
      this.selectedPrereqCourses = courseList1.map(x => x);
      this.contentForm
        .get('preRequisiteCourseIDs')
        .setValue(this.selectedPrereqCourses.map(x => x.Id));
      this.filteredPreReqCourses.filter(
        x => x.Id === course.Id
      )[0].checkedPreReq = false;
    } else if (!isPreReq) {
      this.selectedPostRecommCourses = courseList2.map(x => x);
      this.contentForm
        .get('postRecommendedCourseIDs')
        .setValue(this.selectedPostRecommCourses.map(x => x.Id));
      this.filteredPostRecommCourses.filter(
        x => x.Id === course.Id
      )[0].checkedPostRecomm = false;
    }
  }

  findInvalidControls() {
    const invalidctrl = [];
    const controls = this.contentForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalidctrl.push(name);
      }
    }
    //  return invalid;
  }
  filterCourses(isPreReq) {
    if (isPreReq) {
      this.filteredPreReqCourses = this.filterName(this.yorbitCourseNamePre);
    } else {
      this.filteredPostRecommCourses = this.filterName(
        this.yorbitCourseNamePost
      );
    }
  }
  //1. save 2. submit
  valdiateCourseOperationalDetails(type) {
    this.isInfrastructureValid = false;
    this.isLoginTypeValid = false;
    this.isLearningMaterialValid = false;
    this.isCourseProcedureReferenceDocumentValid = false;
    this.isProofFieldValid = false;
    this.isClassroomFieldValid = false;
    this.isProjectFieldValid = false;
    this.isAssessmentFieldValid = false;


    this.validateInfrastructure();
    this.validateLoginDetails();
    this.validateLearningMaterial();
    this.validateProofofCompletion();
    this.validateClassRoomDetails();
    this.validateProjectDetails();
    this.validateAssessmentDetails();
    this.validateCourseProcedureRefDocument();

    if (type === 'save' && this.contentForm.getRawValue().quizGroup.quizRadio ===
      'Yes') {
      this.isQuizValid = false;
      this.isQuizValid = this.quizFrom101Component.onSave('save');
      if (!this.isQuizValid) {
        return;
      }
    }


    if (
      this.isInfrastructureValid &&
      this.isLoginTypeValid &&
      this.isLearningMaterialValid &&
      this.isCourseProcedureReferenceDocumentValid &&
      this.isProofFieldValid &&
      this.isClassroomFieldValid &&
      this.isProjectFieldValid &&
      this.isAssessmentFieldValid
    ) {
      if (type === 'submit') {
        this.openConfirmationPopup(type);
      } else {
        this.OnContentFormSubmit(type);
      }
    } else {
      this.contentForm.setErrors({ isInvalid: true });
    }
  }
  validateInfrastructure() {
    this.operationalErrMsg.infrastructure = [];
    if (this.contentForm.get('isInfrastructure')) {
      if (this.contentForm.value.infrastructure[4]) {
        const infraTypecontrol = <FormArray>(
          this.contentForm.controls['infrastructureType']
        );
        infraTypecontrol.controls[6].setValue('None of the Above');

        this.isInfrastructureValid = true;
        this.contentForm.setErrors(null);
      } else {
        let counter = 0;
        const infrastructureType = this.contentForm.value.infrastructureType;
        let trueCount = 0;
        this.contentForm.value.infrastructure.forEach((value, key) => {
          if (key !== 4 && value) {
            trueCount += 1;
            switch (key) {
              case 0:
                if (!this.isNullOrEmpty(infrastructureType[0])) {
                  counter++;
                } else {
                  this.operationalErrMsg.infrastructure.push(
                    'Internal Cloud Lab Description.'
                  );
                }
                break;
              case 1:
                if (!this.isNullOrEmpty(infrastructureType[1])) {
                  counter++;
                } else {
                  this.operationalErrMsg.infrastructure.push(
                    'Vendor Provided Cloud Lab Description.'
                  );
                }
                break;
              case 2:
                if (
                  !this.isNullOrEmpty(infrastructureType[2]) &&
                  !this.isNullOrEmpty(infrastructureType[3])
                ) {
                  counter++;
                } else {
                  if (this.isNullOrEmpty(infrastructureType[2]))
                    this.operationalErrMsg.infrastructure.push(
                      'Name of the White Listed Software.'
                    );
                  if (this.isNullOrEmpty(infrastructureType[3]))
                    this.operationalErrMsg.infrastructure.push(
                      'Link to Download White Listed Software.'
                    );
                }
                break;
              case 3:
                if (
                  !this.isNullOrEmpty(infrastructureType[4]) &&
                  !this.isNullOrEmpty(infrastructureType[5])
                ) {
                  counter++;
                } else {
                  if (this.isNullOrEmpty(infrastructureType[4]))
                    this.operationalErrMsg.infrastructure.push(
                      'Name of the Software.'
                    );
                  if (this.isNullOrEmpty(infrastructureType[5]))
                    this.operationalErrMsg.infrastructure.push(
                      'Version of the Software.'
                    );
                }
                break;
            }
          } else if (key == 4 && !value && counter == 0) {
            const control = <FormArray>(
              this.contentForm.controls['infrastructure']
            );
            control.controls[4].setValue(true);

            const infraTypecontrol = <FormArray>(
              this.contentForm.controls['infrastructureType']
            );
            infraTypecontrol.controls[6].setValue('None of the Above');
            this.contentForm.controls['isInfrastructure'].setValue(true);
          }
        });
        if (counter === trueCount) {
          this.isInfrastructureValid = true;
          this.contentForm.setErrors(null);
        } else {
          this.isInfrastructureValid = false;
          this.contentForm.setErrors({ invalid: true });
        }
      }
    }
  }
  validateLoginDetails() {
    this.operationalErrMsg.login = [];
    if (this.contentForm.get('isLoginRequired')) {
      let loginTypeId;
      let loginTypes = [];
      loginTypes = this.contentForm.value.loginType;
      loginTypes.forEach((type, key) => {
        if (type) {
          loginTypeId = key + 1;
        }
      });
      switch (loginTypeId) {
        case 1: // return 'No Login Required';
        case 4: // return 'Learning Partner to Provide Credentials';
        default:
          this.isLoginTypeValid = true;
          break;
        case 2:
          this.isLoginTypeValid = true;
          this.contentForm.setErrors(null);
          // return 'Learner to Self Register and make Payment';
          {
            let linkList = this.contentForm.value
              .linktoCoursesForSelfRegistration;
            let courseList = this.contentForm.value
              .coursesNameForSelfRegistration;
            let validLinkCount = 0;
            let invalidLinkCount = 0;
            let validCourseCount = 0;
            let invalidCourseCount = 0;

            linkList.forEach((value, key) => {
              if (!this.isNullOrEmpty(value)) {
                validLinkCount += 1;
              } else {
                invalidLinkCount += 1;
              }
            });

            courseList.forEach((value, key) => {
              if (!this.isNullOrEmpty(value)) {
                validCourseCount += 1;
              } else {
                invalidCourseCount += 1;
              }
            });

            //If link to courses <= 1
            if (
              this.contentForm.value.linktoCoursesForSelfRegistration.length <=
              1
            ) {
              let length = this.contentForm.value
                .linktoCoursesForSelfRegistration.length;
              if (
                length == 0 ||
                (length == 1 &&
                  this.isNullOrEmpty(
                    this.contentForm.value.linktoCoursesForSelfRegistration[0]
                  ))
              ) {
                this.operationalErrMsg.login.push(
                  "Link(s) to Course in Vendor's Site."
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              }
            } else {
              if (validLinkCount >= 1 && invalidLinkCount >= 1) {
                this.operationalErrMsg.login.push(
                  "Please delete empty fields under Link(s) to Course in Vendor's Site."
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              } else if (validLinkCount == 0) {
                this.operationalErrMsg.login.push(
                  "Link(s) to Course in Vendor's Site - Minimum one value is required. Please delete other empty fields. "
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              }
            }
            //If course names <= 1
            if (
              this.contentForm.value.coursesNameForSelfRegistration.length <= 1
            ) {
              let length = this.contentForm.value.coursesNameForSelfRegistration
                .length;
              if (
                length == 0 ||
                (length == 1 &&
                  this.isNullOrEmpty(
                    this.contentForm.value.coursesNameForSelfRegistration[0]
                  ))
              ) {
                this.operationalErrMsg.login.push(
                  'Course Name(s) in Vendor Site.'
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              }
            } else {
              if (validCourseCount >= 1 && invalidCourseCount >= 1) {
                this.operationalErrMsg.login.push(
                  'Please delete empty fields under Course Name(s) in Vendor Site.'
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              } else if (validCourseCount == 0) {
                this.operationalErrMsg.login.push(
                  'Course Name(s) in Vendor Site - Minimum one value is required. Please delete other empty fields.'
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              }
            }
          }
          break;
        case 3:
          this.isLoginTypeValid = true;
          this.contentForm.setErrors(null);
          // return 'Learning OPM Team to Provide Credentials';
          {
            let linkList = this.contentForm.value
              .linktoCoursesForLearningOPMTeam;
            let courseList = this.contentForm.value
              .coursesNameForLearningOPMTeam;
            let validLinkCount = 0;
            let invalidLinkCount = 0;
            let validCourseCount = 0;
            let invalidCourseCount = 0;

            linkList.forEach((value, key) => {
              if (!this.isNullOrEmpty(value)) {
                validLinkCount += 1;
              } else {
                invalidLinkCount += 1;
              }
            });

            courseList.forEach((value, key) => {
              if (!this.isNullOrEmpty(value)) {
                validCourseCount += 1;
              } else {
                invalidCourseCount += 1;
              }
            });

            //Check if login details field is valid
            if (this.isNullOrEmpty(this.contentForm.value.loginDetails)) {
              this.operationalErrMsg.login.push('Login Details.');
              this.isLoginTypeValid = false;
              this.contentForm.setErrors({ invalid: true });
            }
            //Check if link to courses fields are valid
            if (
              this.contentForm.value.linktoCoursesForLearningOPMTeam.length <= 1
            ) {
              let length = this.contentForm.value
                .linktoCoursesForLearningOPMTeam.length;
              if (
                length == 0 ||
                (length == 1 &&
                  this.isNullOrEmpty(
                    this.contentForm.value.linktoCoursesForLearningOPMTeam[0]
                  ))
              ) {
                this.operationalErrMsg.login.push('Link(s) to Course.');
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              }
            } else {
              if (validLinkCount >= 1 && invalidLinkCount >= 1) {
                this.operationalErrMsg.login.push(
                  'Please delete empty fields under Link(s) to Course.'
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              } else if (validLinkCount == 0) {
                this.operationalErrMsg.login.push(
                  'Link(s) to Course - Minimum one value is required. Please delete other empty fields.'
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              }
            }
            //Check if courses names fields are valid
            if (
              this.contentForm.value.coursesNameForLearningOPMTeam.length <= 1
            ) {
              let length = this.contentForm.value.coursesNameForLearningOPMTeam
                .length;
              if (
                length == 0 ||
                (length == 1 &&
                  this.isNullOrEmpty(
                    this.contentForm.value.coursesNameForLearningOPMTeam[0]
                  ))
              ) {
                this.operationalErrMsg.login.push(
                  'Course Name(s) in Vendor Site.'
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              }
            } else {
              if (validCourseCount >= 1 && invalidCourseCount >= 1) {
                this.operationalErrMsg.login.push(
                  'Please delete empty fields under Course Name(s) in Vendor Site.'
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              } else if (validCourseCount == 0) {
                this.operationalErrMsg.login.push(
                  'Course Name(s) in Vendor Site - Minimum one value is required. Please delete other empty fields.'
                );
                this.isLoginTypeValid = false;
                this.contentForm.setErrors({ invalid: true });
              }
            }
          }
          break;
      }
    }
  }
  validateLearningMaterial() {
    this.operationalErrMsg.learningMaterial = [];
    let errorList = [];
    if (this.contentForm.get('isLearningMaterial').value === 'Yes') {
      let validFieldsCounter = 0;
      let inputCounter = 0;
      const presession = this.contentForm.value.preSessionGroup;
      const duringsession = this.contentForm.value.duringSessionGroup;
      const postsession = this.contentForm.value.postSessionGroup;
      if (!this.isNullOrEmpty(presession.preSessionLearningMaterialTextBox)) {
        inputCounter++;
        if (!this.isNullOrEmpty(presession.preSessionLearningMaterialLink)) {
          validFieldsCounter++;
        } else {
          if (
            this.isNullOrEmpty(presession.preSessionLearningMaterialFileBox)
          ) {
            errorList.push(
              'Please choose a file under Pre-Session Learning Material.'
            );
          } else {
            errorList.push(
              'Please choose a valid file under Pre-Session Learning Material.'
            );
          }
        }
      } else {
        this.contentForm
          .get('preSessionGroup.preSessionLearningMaterialTextBox')
          .setValue(null);
        this.contentForm
          .get('preSessionGroup.preSessionLearningMaterialFileBox')
          .setValue(null);
        this.contentForm
          .get('preSessionGroup.preSessionLearningMaterialLink')
          .setValue(null);
      }
      if (
        !this.isNullOrEmpty(duringsession.duringSessionLearningMaterialTextBox)
      ) {
        inputCounter++;
        if (
          !this.isNullOrEmpty(duringsession.duringSessionLearningMaterialLink)
        ) {
          validFieldsCounter++;
        } else {
          if (
            this.isNullOrEmpty(
              duringsession.duringSessionLearningMaterialFileBox
            )
          ) {
            errorList.push(
              'Please choose a file under During-Session Learning Material.'
            );
          } else {
            errorList.push(
              'Please choose a valid file under During-Session Learning Material.'
            );
          }
        }
      } else {
        this.contentForm
          .get('duringSessionGroup.duringSessionLearningMaterialTextBox')
          .setValue(null);
        this.contentForm
          .get('duringSessionGroup.duringSessionLearningMaterialFileBox')
          .setValue(null);
        this.contentForm
          .get('duringSessionGroup.duringSessionLearningMaterialLink')
          .setValue(null);
      }
      if (!this.isNullOrEmpty(postsession.postSessionLearningMaterialTextBox)) {
        inputCounter++;
        if (!this.isNullOrEmpty(postsession.postSessionLearningMaterialLink)) {
          validFieldsCounter++;
        } else {
          if (
            this.isNullOrEmpty(postsession.postSessionLearningMaterialFileBox)
          ) {
            errorList.push(
              'Please choose a file under Post-Session Learning Material.'
            );
          } else {
            errorList.push(
              'Please choose a valid file under Post-Session Learning Material.'
            );
          }
        }
      } else {
        this.contentForm
          .get('postSessionGroup.postSessionLearningMaterialTextBox')
          .setValue(null);
        this.contentForm
          .get('postSessionGroup.postSessionLearningMaterialFileBox')
          .setValue(null);
        this.contentForm
          .get('postSessionGroup.postSessionLearningMaterialLink')
          .setValue(null);
      }

      if (inputCounter >= 1 && validFieldsCounter == inputCounter) {
        this.isLearningMaterialValid = true;
        this.contentForm.setErrors(null);
      } else {
        if (inputCounter == 0) {
          errorList.push(
            'Please provide at least one learning material details. (Name and a respective file)'
          );
        }
        this.operationalErrMsg.learningMaterial = errorList;
        this.isLearningMaterialValid = false;
        this.contentForm.setErrors({ invalid: true });
      }
    } else {
      this.isLearningMaterialValid = true;
      this.contentForm.setErrors(null);
    }
  }
  validateProofofCompletion() {
    this.operationalErrMsg.proofOfCourseCompletion = [];
    let proofCount = 0;
    let validateCount = 0;
    validateCount = this.contentForm.value.courseCompletionGroup.courseCompletionFormArray.filter(
      x => x == true
    ).length;
    this.contentForm.value.courseCompletionGroup.courseCompletionFormArray.forEach(
      (value, key) => {
        const typeArray = this.contentForm.value.courseCompletionGroup
          .courseCompletionTypeFormArray;
        const regDigitsOnly = /^\d+$/;
        if (value) {
          switch (key) {
            case 0:
              let res1 = regDigitsOnly.test(typeArray[0]);
              if (
                res1 &&
                typeArray[0] >= 1 &&
                !this.isNullOrEmpty(typeArray[0])
              ) {
                proofCount += 1;
              } else {
                if (typeArray[0] < 1 || typeArray[0] == '') {
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'No. of course completion certificate(s)'
                  );
                } else if (!res1) {
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'No. of course completion certificate(s) - Please enter only numbers.'
                  );
                }
                if (typeArray[1] == '' || typeArray[1] == null)
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'Certificate Description'
                  );
              }
              break;
            case 1:
              let res2 = regDigitsOnly.test(typeArray[3]);
              if (
                res2 &&
                typeArray[3] >= 1 &&
                !this.isNullOrEmpty(typeArray[4])
              ) {
                proofCount += 1;
              } else {
                if (typeArray[3] < 1 || typeArray[3] == '') {
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'No. of badge(s)'
                  );
                } else if (!res2) {
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'No. of badges - Please enter only numbers.'
                  );
                }
                if (typeArray[4] == '' || typeArray[4] == null)
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'Badge Description'
                  );
              }
              break;
            case 2:
              let res3 = regDigitsOnly.test(typeArray[6]);
              if (
                res3 &&
                typeArray[6] >= 1 &&
                !this.isNullOrEmpty(typeArray[7])
              ) {
                proofCount += 1;
              } else {
                if (typeArray[6] < 1 || typeArray[6] == '') {
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'No. of screen shot(s)'
                  );
                } else if (!res3) {
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'No. of screen shots - Please enter only numbers.'
                  );
                }
                if (typeArray[7] == '' || typeArray[7] == null)
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'Screen Shot Description'
                  );
              }
              break;
            case 3:
              let res4 = regDigitsOnly.test(typeArray[9]);
              if (
                res4 &&
                typeArray[9] >= 1 &&
                !this.isNullOrEmpty(typeArray[10])
              ) {
                proofCount += 1;
              } else {
                if (typeArray[9] < 1 || typeArray[9] == '') {
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'No. of Letter Head(s)'
                  );
                } else if (!res4) {
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'No. of Letter Heads - Please enter only numbers.'
                  );
                }
                if (typeArray[10] == '' || typeArray[10] == null)
                  this.operationalErrMsg.proofOfCourseCompletion.push(
                    'Letter Head Description'
                  );
              }
              break;
            case 4:
              proofCount += 1;
              break;
          }
        }
      }
    );
    if (proofCount !== validateCount) {
      this.isProofFieldValid = false;
      this.contentForm.setErrors({ isInvalid: true });
    } else {
      this.isProofFieldValid = true;
      this.contentForm.setErrors(null);
    }
  }
  validateClassRoomDetails() {
    this.operationalErrMsg.classroom = [];

    let errorCountMaxScore = 0;
    let errorMsgMaxScore = [];

    let errorCountPassingScore = 0;
    let errorMsgPassingScore = [];

    this.classroomMaxScoreValidation.forEach((row, rowIndex) => {
      if (this.classroomMaxScoreValidation[rowIndex].isInvalid == true) {
        errorCountMaxScore++;
        if (!errorMsgMaxScore.includes(row.errMsg))
          errorMsgMaxScore.push(row.errMsg);
      }
    });
    this.classroomPassingScoreValidation.forEach((row, rowIndex) => {
      if (this.classroomPassingScoreValidation[rowIndex].isInvalid == true) {
        errorCountPassingScore++;
        if (!errorMsgPassingScore.includes(row.errMsg))
          errorMsgPassingScore.push(row.errMsg);
      }
    });

    if (
      this.contentForm.getRawValue().classRoomGroup.isClassRoomRequired ===
      'Yes'
    ) {
      let classRoomCount = 0;
      const classRoomArray = this.contentForm.getRawValue().classRoomGroup
        .classRoom;
      classRoomArray.forEach((value, key) => {
        let isClassroomValid = false;
        let isAssignmentValid = false;
        if (
          !this.isNullOrEmpty(value.nameOfClassRoomSessions) &&
          // !this.isNullOrEmpty(value.vendorOrFaultyName) &&
          !this.isNullOrEmpty(value.classroomDescription)
        ) {
          isClassroomValid = true;
          if (value.isAssignmentRequired === 'Yes') {
            if (
              !this.isNullOrEmpty(value.assignmentDescription) &&
              !this.isNullOrEmpty(value.assignmentContentLink)
            ) {
              if (value.isEvaluationRequired === 'Yes') {
                if (
                  !this.isNullOrEmpty(value.evaluatorName) &&
                  !this.isNullOrEmpty(value.evaluatorEmailID) &&
                  !this.isNullOrEmpty(value.evaluatorVendor) &&
                  !this.isNullOrEmpty(value.evaluatorType) &&
                  !this.isNullOrEmpty(value.locationofEvaluator) &&
                  // value.evaluationTemplateLink !== null &&
                  // value.evaluationTemplateLink !== '' &&
                  !this.isNullOrEmpty(value.maxScore) &&
                  value.maxScore > 0 &&
                  !this.isNullOrEmpty(value.passingScore) &&
                  value.passingScore > 0 &&
                  errorCountMaxScore == 0 &&
                  errorCountPassingScore == 0
                  // value.passingScore !== null
                  // value.panelClass !== ''
                ) {
                  classRoomCount += 1;
                } else {
                  //invalid do nothing block
                  if (this.isNullOrEmpty(value.evaluatorName)) {
                    if (
                      !this.operationalErrMsg.classroom.includes(
                        'Evaluator Name'
                      )
                    )
                      this.operationalErrMsg.classroom.push('Evaluator Name');
                  }
                  if (this.isNullOrEmpty(value.evaluatorEmailID)) {
                    if (
                      !this.operationalErrMsg.classroom.includes(
                        'Evaluator Email Id'
                      )
                    )
                      this.operationalErrMsg.classroom.push(
                        'Evaluator Email Id'
                      );
                  }
                  if (this.isNullOrEmpty(value.evaluatorVendor)) {
                    if (
                      !this.operationalErrMsg.classroom.includes(
                        "Evaluator's Vendor Name"
                      )
                    )
                      this.operationalErrMsg.classroom.push(
                        "Evaluator's Vendor Name"
                      );
                  }
                  if (this.isNullOrEmpty(value.evaluatorType)) {
                    if (
                      !this.operationalErrMsg.classroom.includes(
                        'Evaluator Type'
                      )
                    )
                      this.operationalErrMsg.classroom.push('Evaluator Type');
                  }
                  if (this.isNullOrEmpty(value.locationofEvaluator)) {
                    if (
                      !this.operationalErrMsg.classroom.includes(
                        'Location of Evaluator'
                      )
                    )
                      this.operationalErrMsg.classroom.push(
                        'Location of Evaluator'
                      );
                  }
                  // if (this.isNullOrEmpty(value.evaluationTemplateLink))
                  //   this.operationalErrMsg.classroom.push(
                  //     'Evaluation Template for Assignment'
                  //   );
                  if (this.isNullOrEmpty(value.maxScore)) {
                    if (!this.operationalErrMsg.classroom.includes('Max Score'))
                      this.operationalErrMsg.classroom.push('Max Score');
                  } else if (value.maxScore == 0) {
                    if (
                      !this.operationalErrMsg.classroom.includes(
                        'Max Score should be greater than Zero'
                      )
                    )
                      this.operationalErrMsg.classroom.push(
                        'Max Score should be greater than Zero'
                      );
                  } else if (errorCountMaxScore > 0) {
                    errorMsgMaxScore.forEach((row, rowIndex) => {
                      if (
                        !this.operationalErrMsg.classroom.includes(
                          'Max Score - ' + row
                        )
                      ) {
                        this.operationalErrMsg.classroom.push(
                          'Max Score - ' + row
                        );
                      }
                    });
                  }

                  if (this.isNullOrEmpty(value.passingScore)) {
                    if (
                      !this.operationalErrMsg.classroom.includes(
                        'Passing Score'
                      )
                    )
                      this.operationalErrMsg.classroom.push('Passing Score');
                  } else if (value.passingScore == 0) {
                    if (
                      !this.operationalErrMsg.classroom.includes(
                        'Passing Score should be greater than Zero'
                      )
                    )
                      this.operationalErrMsg.classroom.push(
                        'Passing Score should be greater than Zero'
                      );
                  } else if (errorCountPassingScore > 0) {
                    errorMsgPassingScore.forEach((row, rowIndex) => {
                      if (
                        !this.operationalErrMsg.classroom.includes(
                          'Passing Score - ' + row
                        )
                      ) {
                        this.operationalErrMsg.classroom.push(
                          'Passing Score - ' + row
                        );
                      }
                    });
                  }
                  // if (this.isNullOrEmpty(value.panelClass))
                  //   this.operationalErrMsg.classroom.push(
                  //     'Schedule for Each Classroom Session'
                  //   );
                }
              } else {
                classRoomCount += 1;
              }
            } else {
              if (this.isNullOrEmpty(value.assignmentDescription)) {
                if (
                  !this.operationalErrMsg.classroom.includes(
                    'Assignment Description'
                  )
                )
                  this.operationalErrMsg.classroom.push(
                    'Assignment Description'
                  );
              }
              if (this.isNullOrEmpty(value.assignmentContentLink)) {
                if (
                  !this.operationalErrMsg.classroom.includes(
                    'Assignment Problem Statement'
                  )
                )
                  this.operationalErrMsg.classroom.push(
                    'Assignment Problem Statement'
                  );
              }
            }
          } else {
            classRoomCount += 1;
            // making the first item valid
          }
        } else {
          // not valid block
          // this.isClassroomFieldValid = false;
          // this.contentForm.setErrors({isInvalid:true});

          if (this.isNullOrEmpty(value.nameOfClassRoomSessions))
            if (
              !this.operationalErrMsg.classroom.includes(
                'Name of class room session'
              )
            )
              this.operationalErrMsg.classroom.push(
                'Name of class room session'
              );
          // if (this.isNullOrEmpty(value.vendorOrFaultyName))
          //   if (!this.operationalErrMsg.classroom.includes('Name of Vendor'))
          //     this.operationalErrMsg.classroom.push('Name of Vendor');
          if (this.isNullOrEmpty(value.classroomDescription))
            if (
              !this.operationalErrMsg.classroom.includes(
                'Class room description'
              )
            )
              this.operationalErrMsg.classroom.push('Class room description');
        }
      });
      if (
        classRoomArray !== undefined &&
        classRoomArray.length > 0 &&
        classRoomCount === classRoomArray.length
      ) {
        //valid
        this.isClassroomFieldValid = true;
        this.contentForm.setErrors(null);
      } else {
        this.isClassroomFieldValid = false;
        this.contentForm.setErrors({ isInvalid: true });
      }
    } else {
      this.isClassroomFieldValid = true;
      this.contentForm.setErrors(null);
    }
  }
  validateProjectDetails() {
    this.operationalErrMsg.project = [];
    let isProjectWorkDetailsValid = false;
    let isProjectEvaluationDetails = false;

    let errorCountMaxScore = 0;
    let errorMsgMaxScore = [];

    let errorCountPassingScore = 0;
    let errorMsgPassingScore = [];

    this.projectMaxScoreValidation.forEach((row, rowIndex) => {
      if (this.projectMaxScoreValidation[rowIndex].isInvalid == true) {
        errorCountMaxScore++;
        if (!errorMsgMaxScore.includes(row.errMsg))
          errorMsgMaxScore.push(row.errMsg);
      }
    });
    this.projectPassingScoreValidation.forEach((row, rowIndex) => {
      if (this.projectPassingScoreValidation[rowIndex].isInvalid == true) {
        errorCountPassingScore++;
        if (!errorMsgPassingScore.includes(row.errMsg))
          errorMsgPassingScore.push(row.errMsg);
      }
    });

    if (this.contentForm.value.projectGroup.hasProjectWork === 'Yes') {
      const projectGroup = this.contentForm.getRawValue().projectGroup;
      let projectCounter = 0;
      projectGroup.projectWorkDetails.forEach((value, key) => {
        if (
          !this.isNullOrEmpty(value.projectDescription) &&
          !this.isNullOrEmpty(value.maxScore) &&
          value.maxScore > 0 &&
          !this.isNullOrEmpty(value.passingScore) &&
          value.passingScore > 0 &&
          errorCountMaxScore == 0 &&
          errorCountPassingScore == 0 &&
          !this.isNullOrEmpty(value.projectDemo) &&
          !this.isNullOrEmpty(value.problemStatementLink)
          // !this.isNullOrEmpty(value.evaluationTemplateLink)
        ) {
          projectCounter += 1;
        } else {
          if (this.isNullOrEmpty(value.projectDescription))
            if (!this.operationalErrMsg.project.includes('Project Description'))
              this.operationalErrMsg.project.push('Project Description');

          if (this.isNullOrEmpty(value.maxScore)) {
            if (!this.operationalErrMsg.project.includes('Max Score'))
              this.operationalErrMsg.project.push('Max Score');
          } else if (value.maxScore == 0) {
            if (
              !this.operationalErrMsg.project.includes(
                'Max Score should be greater than Zero'
              )
            )
              this.operationalErrMsg.project.push(
                'Max Score should be greater than Zero'
              );
          } else if (errorCountMaxScore > 0) {
            errorMsgMaxScore.forEach((row, rowIndex) => {
              if (
                !this.operationalErrMsg.project.includes('Max Score - ' + row)
              ) {
                this.operationalErrMsg.project.push('Max Score - ' + row);
              }
            });
          }

          if (this.isNullOrEmpty(value.passingScore)) {
            if (!this.operationalErrMsg.project.includes('Passing Score'))
              this.operationalErrMsg.project.push('Passing Score');
          } else if (value.passingScore == 0) {
            if (
              !this.operationalErrMsg.project.includes(
                'Passing Score should be greater than Zero'
              )
            )
              this.operationalErrMsg.project.push(
                'Passing Score should be greater than Zero'
              );
          } else if (errorCountPassingScore > 0) {
            errorMsgPassingScore.forEach((row, rowIndex) => {
              if (
                !this.operationalErrMsg.project.includes(
                  'Passing Score - ' + row
                )
              ) {
                this.operationalErrMsg.project.push('Passing Score - ' + row);
              }
            });
          }

          if (this.isNullOrEmpty(value.projectDemo))
            if (
              !this.operationalErrMsg.project.includes('Does Course have Demo')
            )
              this.operationalErrMsg.project.push('Does Course have Demo');

          if (this.isNullOrEmpty(value.problemStatementLink))
            if (!this.operationalErrMsg.project.includes('Problem Statement'))
              this.operationalErrMsg.project.push('Problem Statement');

          // if (this.isNullOrEmpty(value.evaluationTemplateLink))
          //   if (
          //     !this.operationalErrMsg.project.includes(
          //       'Evaluation Template for Project'
          //     )
          //   )
          //     this.operationalErrMsg.project.push(
          //       'Evaluation Template for Project'
          //     );
        }
      });
      if (
        (projectGroup.hasJumbleProject === 'No' &&
          projectGroup.projectWorkDetails.length == 1 &&
          projectCounter === 1) ||
        (projectGroup.hasJumbleProject === 'Yes' &&
          projectGroup.projectWorkDetails.length >= 2 &&
          projectCounter === projectGroup.projectWorkDetails.length)
      ) {
        //valid
        isProjectWorkDetailsValid = true;
      } else {
        //invalid
        isProjectWorkDetailsValid = false;
        if (projectGroup.hasJumbleProject === 'No') {
          if (projectGroup.projectWorkDetails.length < 1) {
            this.operationalErrMsg.project = [];
            this.operationalErrMsg.project.push(
              'Atleast one Project Details is required'
            );
          } else if (projectGroup.projectWorkDetails.length > 1) {
            this.operationalErrMsg.project = [];
            this.operationalErrMsg.project.push(
              'Only one Project Details is allowed when Jumble Project Problem Statement is chosen as "No"'
            );
          }
        } else {
          if (projectGroup.projectWorkDetails.length < 2) {
            this.operationalErrMsg.project = [];
            this.operationalErrMsg.project.push(
              'Atleast two Project Details are required when Jumble Project Problem Statement is chosen as "Yes"'
            );
          }
        }
      }
    } else {
      isProjectWorkDetailsValid = true;
    }
    if (
      this.contentForm.getRawValue().projectGroup.hasProjectEvaluation ===
      'Yes' &&
      this.contentForm.getRawValue().projectGroup.hasProjectWork === 'Yes'
    ) {
      const projectGroup = this.contentForm.value.projectGroup;
      let evaluationCounter = 0;
      projectGroup.projectEvaluationDetails.forEach((value, key) => {
        if (
          !this.isNullOrEmpty(value.evaluatorName) &&
          !this.isNullOrEmpty(value.evaluatorVendor) &&
          !this.isNullOrEmpty(value.evaluatorEmailID) &&
          !this.isNullOrEmpty(value.evaluatorType) &&
          !this.isNullOrEmpty(value.locationofEvaluator)
        ) {
          evaluationCounter += 1;
        } else {
          if (this.isNullOrEmpty(value.evaluatorType))
            if (!this.operationalErrMsg.project.includes('Evaluator Type'))
              this.operationalErrMsg.project.push('Evaluator Type');

          if (this.isNullOrEmpty(value.locationofEvaluator))
            if (
              !this.operationalErrMsg.project.includes('Location of Evaluator')
            )
              this.operationalErrMsg.project.push('Location of Evaluator');

          if (this.isNullOrEmpty(value.evaluatorName))
            if (!this.operationalErrMsg.project.includes('Evaluator Name'))
              this.operationalErrMsg.project.push('Evaluator Name');

          if (this.isNullOrEmpty(value.evaluatorVendor))
            if (!this.operationalErrMsg.project.includes('Evaluator Vendor'))
              this.operationalErrMsg.project.push('Evaluator Vendor');

          if (this.isNullOrEmpty(value.evaluatorEmailID))
            if (
              !this.operationalErrMsg.project.includes("Evaluator's Email Id")
            )
              this.operationalErrMsg.project.push("Evaluator's Email Id");
        }
      });
      if (evaluationCounter === projectGroup.projectEvaluationDetails.length) {
        //valid
        isProjectEvaluationDetails = true;
      } else {
        //invalid
        isProjectEvaluationDetails = false;
      }
    } else {
      isProjectEvaluationDetails = true;
    }
    if (isProjectWorkDetailsValid && isProjectEvaluationDetails) {
      this.isProjectFieldValid = true;
      this.contentForm.setErrors(null);
    } else {
      this.isProjectFieldValid = false;
      this.contentForm.setErrors({ isInvalid: true });
    }
  }
  validateAssessmentDetails() {
    this.operationalErrMsg.assessment = [];

    if (this.contentForm.value.assessmentGroup.assessmentRadio === 'Yes') {
      const assessmentGroup = this.contentForm.getRawValue().assessmentGroup;
      let isAssessmentDescValid = false;
      let isAssessmentInfraValid = false;
      let descriptionCounter = 0;
      if (!this.isNullOrEmpty(assessmentGroup.assessmentDescription)) {
        descriptionCounter += 1;
      } else {
        if (
          !this.operationalErrMsg.assessment.includes('Assessment Description')
        )
          this.operationalErrMsg.assessment.push('Assessment Description');
      }

      if (
        assessmentGroup.assessmentType === 'Offline' ||
        assessmentGroup.assessmentType === 'Both'
      ) {
        if (!this.isNullOrEmpty(assessmentGroup.evaluatorType)) {
          let evaluatorCounter = 0;
          if (
            !this.isNullOrEmpty(assessmentGroup.evaluatorList) &&
            assessmentGroup.evaluatorList.length > 0
          ) {
            assessmentGroup.evaluatorList.forEach(element => {
              if (
                !this.isNullOrEmpty(element.evaluatorName) &&
                !this.isNullOrEmpty(element.evaluatorEmail) &&
                !this.isNullOrEmpty(element.evaluatorVendor)
              ) {
                evaluatorCounter += 1;
              } else {
                if (this.isNullOrEmpty(element.evaluatorName))
                  if (
                    !this.operationalErrMsg.assessment.includes(
                      'Evaluator Name'
                    )
                  )
                    this.operationalErrMsg.assessment.push('Evaluator Name');

                if (this.isNullOrEmpty(element.evaluatorEmail))
                  if (
                    !this.operationalErrMsg.assessment.includes(
                      'Evaluator Email'
                    )
                  )
                    this.operationalErrMsg.assessment.push('Evaluator Email');

                if (this.isNullOrEmpty(element.evaluatorVendor))
                  if (
                    !this.operationalErrMsg.assessment.includes(
                      'Evaluator Vendor'
                    )
                  )
                    this.operationalErrMsg.assessment.push('Evaluator Vendor');
              }
            });
            if (evaluatorCounter == assessmentGroup.evaluatorList.length) {
              descriptionCounter += 1;
            }
          } else {
            if (!this.operationalErrMsg.assessment.includes('Evaluator Name'))
              this.operationalErrMsg.assessment.push('Evaluator Name');
            if (!this.operationalErrMsg.assessment.includes('Evaluator Email'))
              this.operationalErrMsg.assessment.push('Evaluator Email');
            if (!this.operationalErrMsg.assessment.includes('Evaluator Vendor'))
              this.operationalErrMsg.assessment.push('Evaluator Vendor');
          }
        } else {
          if (!this.operationalErrMsg.assessment.includes('Evaluator Type'))
            this.operationalErrMsg.assessment.push('Evaluator Type');
          if (!this.operationalErrMsg.assessment.includes('Evaluator Name'))
            this.operationalErrMsg.assessment.push('Evaluator Name');
          if (!this.operationalErrMsg.assessment.includes('Evaluator Email'))
            this.operationalErrMsg.assessment.push('Evaluator Email');
          if (!this.operationalErrMsg.assessment.includes('Evaluator Vendor'))
            this.operationalErrMsg.assessment.push('Evaluator Vendor');
        }
      } else {
        this.contentForm
          .get('assessmentGroup.evaluatorType')
          .setValue('Internal');
      }
      if (
        assessmentGroup.assessmentType === 'Offline' ||
        assessmentGroup.assessmentType === 'Both'
      ) {
        if (
          !this.isNullOrEmpty(assessmentGroup.questionPaperLink) &&
          //  !this.isNullOrEmpty(assessmentGroup.evaluationTemplateLink) &&
          !this.isNullOrEmpty(assessmentGroup.maxScore) &&
          assessmentGroup.maxScore > 0 &&
          !this.assessmentMaxScoreValidation.isInvalid &&
          !this.isNullOrEmpty(assessmentGroup.passingScore) &&
          assessmentGroup.passingScore > 0 &&
          !this.assessmentPassingScoreValidation.isInvalid &&
          !this.isNullOrEmpty(assessmentGroup.evaluatorType)
          //!this.isNullOrEmpty(assessmentGroup.evaluatorName) &&
          //!this.isNullOrEmpty(assessmentGroup.evaluatorEmail) &&
          //!this.isNullOrEmpty(assessmentGroup.evaluatorVendor)
        ) {
          descriptionCounter += 1;
        } else {
          if (this.isNullOrEmpty(assessmentGroup.questionPaperLink))
            if (!this.operationalErrMsg.assessment.includes('Question Paper'))
              this.operationalErrMsg.assessment.push('Question Paper');
          // if (this.isNullOrEmpty(assessmentGroup.evaluationTemplateLink))
          //   if (
          //     !this.operationalErrMsg.assessment.includes('Evaluation Template')
          //   )
          //     this.operationalErrMsg.assessment.push('Evaluation Template');

          if (this.isNullOrEmpty(assessmentGroup.maxScore)) {
            if (!this.operationalErrMsg.assessment.includes('Max Score'))
              this.operationalErrMsg.assessment.push('Max Score');
          } else if (assessmentGroup.maxScore == 0) {
            if (!this.operationalErrMsg.assessment.includes('Max Score'))
              this.operationalErrMsg.assessment.push(
                'Max Score should be greater than Zero'
              );
          } else if (this.assessmentMaxScoreValidation.isInvalid) {
            if (
              !this.operationalErrMsg.assessment.includes(
                'Max Score - ' + this.assessmentMaxScoreValidation.errMsg
              )
            )
              this.operationalErrMsg.assessment.push(
                'Max Score - ' + this.assessmentMaxScoreValidation.errMsg
              );
          }

          if (this.isNullOrEmpty(assessmentGroup.passingScore)) {
            if (!this.operationalErrMsg.assessment.includes('Passing Score'))
              this.operationalErrMsg.assessment.push('Passing Score');
          } else if (assessmentGroup.passingScore == 0) {
            if (!this.operationalErrMsg.assessment.includes('Passing Score'))
              this.operationalErrMsg.assessment.push(
                'Passing Score should be greater than Zero'
              );
          } else if (this.assessmentPassingScoreValidation.isInvalid) {
            if (
              !this.operationalErrMsg.assessment.includes(
                'Passing Score - ' +
                this.assessmentPassingScoreValidation.errMsg
              )
            )
              this.operationalErrMsg.assessment.push(
                'Passing Score - ' +
                this.assessmentPassingScoreValidation.errMsg
              );
          }
        }
      }
      if (
        assessmentGroup.assessmentType === 'Online' ||
        assessmentGroup.assessmentType === 'Both'
      ) {
        if (
          //!this.isNullOrEmpty(assessmentGroup.onlineQuestionPaperLink) &&
          !this.isNullOrEmpty(assessmentGroup.toolName)
        ) {
          descriptionCounter += 1;
        } else {
          if (this.isNullOrEmpty(assessmentGroup.toolName))
            if (!this.operationalErrMsg.assessment.includes('Tool Name'))
              this.operationalErrMsg.assessment.push('Tool Name.');
        }
      }

      if (
        (assessmentGroup.assessmentType === 'Both' &&
          descriptionCounter === 4) ||
        ((assessmentGroup.assessmentType === 'Offline' &&
          descriptionCounter === 3) ||
          (assessmentGroup.assessmentType === 'Online' &&
            descriptionCounter === 2))
      ) {
        isAssessmentDescValid = true;
      } else {
        isAssessmentDescValid = false;
      }
      if (assessmentGroup.assesmentInfrastructure[4]) {
        isAssessmentInfraValid = true;
        const controlsInfraType = <FormArray>(
          this.contentForm.get('assessmentGroup.assesmentInfrastructureType')
        );
        controlsInfraType.controls[6].setValue('None of the Above');
      } else {
        let counter = 0;
        let trueCount = 0;
        assessmentGroup.assesmentInfrastructure.forEach((value, key) => {
          if (value && key !== 4) {
            trueCount += 1;
            switch (key) {
              case 0:
                if (
                  !this.isNullOrEmpty(
                    assessmentGroup.assesmentInfrastructureType[0]
                  )
                ) {
                  counter++;
                } else {
                  if (
                    !this.operationalErrMsg.assessment.includes(
                      'Internal Cloud Lab Description'
                    )
                  )
                    this.operationalErrMsg.assessment.push(
                      'Internal Cloud Lab Description'
                    );
                }
                break;
              case 1:
                if (
                  !this.isNullOrEmpty(
                    assessmentGroup.assesmentInfrastructureType[1]
                  )
                ) {
                  counter++;
                } else {
                  if (
                    !this.operationalErrMsg.assessment.includes(
                      'Vendor Provided Cloud Lab Description'
                    )
                  )
                    this.operationalErrMsg.assessment.push(
                      'Vendor Provided Cloud Lab Description'
                    );
                }
                break;
              case 2:
                if (
                  !this.isNullOrEmpty(
                    assessmentGroup.assesmentInfrastructureType[2]
                  ) &&
                  !this.isNullOrEmpty(
                    assessmentGroup.assesmentInfrastructureType[3]
                  )
                ) {
                  counter++;
                } else {
                  if (
                    this.isNullOrEmpty(
                      assessmentGroup.assesmentInfrastructureType[2]
                    )
                  )
                    if (
                      !this.operationalErrMsg.assessment.includes(
                        'Name of White Listed Software'
                      )
                    )
                      this.operationalErrMsg.assessment.push(
                        'Name of White Listed Software'
                      );

                  if (
                    this.isNullOrEmpty(
                      assessmentGroup.assesmentInfrastructureType[3]
                    )
                  )
                    if (
                      !this.operationalErrMsg.assessment.includes(
                        'Link to Download White Listed Software'
                      )
                    )
                      this.operationalErrMsg.assessment.push(
                        'Link to Download White Listed Software'
                      );
                }
                break;
              case 3:
                if (
                  !this.isNullOrEmpty(
                    assessmentGroup.assesmentInfrastructureType[4]
                  ) &&
                  !this.isNullOrEmpty(
                    assessmentGroup.assesmentInfrastructureType[5]
                  )
                ) {
                  counter++;
                } else {
                  if (
                    this.isNullOrEmpty(
                      assessmentGroup.assesmentInfrastructureType[4]
                    )
                  )
                    if (
                      !this.operationalErrMsg.assessment.includes(
                        'Name of Software'
                      )
                    )
                      this.operationalErrMsg.assessment.push(
                        'Name of Software'
                      );

                  if (
                    this.isNullOrEmpty(
                      assessmentGroup.assesmentInfrastructureType[5]
                    )
                  )
                    if (
                      !this.operationalErrMsg.assessment.includes(
                        'Version of Software'
                      )
                    )
                      this.operationalErrMsg.assessment.push(
                        'Version of Software'
                      );
                }
                break;
            }
          } else if (!value && key == 4 && counter == 0) {
            const controlInfra = <FormArray>(
              this.contentForm.get('assessmentGroup.assesmentInfrastructure')
            );
            controlInfra.controls[4].setValue(true);

            const controlsInfraType = <FormArray>(
              this.contentForm.get(
                'assessmentGroup.assesmentInfrastructureType'
              )
            );
            controlsInfraType.controls[6].setValue('None of the Above');
          }
        });
        if (counter === trueCount) {
          isAssessmentInfraValid = true;
        } else {
          isAssessmentInfraValid = false;
        }
      }
      if (isAssessmentDescValid && isAssessmentInfraValid) {
        this.isAssessmentFieldValid = true;
        this.contentForm.setErrors(null);
      } else {
        this.isAssessmentFieldValid = false;
        this.contentForm.setErrors({ isInvalid: true });
      }
    } else {
      this.isAssessmentFieldValid = true;
      this.contentForm.setErrors(null);
    }
  }
  validateCourseProcedureRefDocument() {
    const refdoc = this.contentForm.value.courseProcedureRefDocGroup;
    if (this.contentForm.get('isCourseProcedureReferenceDocument').value === 'Yes') {
      if (!this.isNullOrEmpty(refdoc.courseProcedureRefDocLink)) {
        this.isCourseProcedureReferenceDocumentValid = true;
      }

    }
    else {
      this.isCourseProcedureReferenceDocumentValid = true;
    }
  }
  getContentUpload201Details(courseUniqueId) {
    this.isEdit = true;
    this._contentUploadSvc.getContentUpload201Details(courseUniqueId).subscribe(
      (response: any) => {
        if (response != null) {
          this.showloading = false;
          this.editContentUpload(response);
        }
      },
      (error: any) => {
        this.courseTypeList = [];
      }
    );
  }

  editContentUpload(pContentData) {

    this.fileUploadInvalid = {};
    this.selectedPrereqCourses = [];
    this.selectedPostRecommCourses = [];
    this.contentForm.get('IsCertificateAvailable').enable();
    this.contentForm.get('IsCertificateAvailable').setValue(pContentData.IsCertificateAvailable == false ? "0" : "1");

    this.contentForm.get('IsCloudLabAvailable').enable();
    this.contentForm.get('IsCloudLabAvailable').setValue(pContentData.IsCloudLabAvailable == false ? "0" : "1");

    this.contentForm.get('IsMailAlertRequired').enable();
    this.contentForm.get('IsMailAlertRequired').setValue(pContentData.IsMailAlertRequired == false ? "0" : "1");

    let priceSplit, expertMidSplit;
    if (pContentData.Paid === true && pContentData.Price) {
      priceSplit = pContentData.Price.split(' ');
      this.contentForm.get('paymentMode').enable();
      this.contentForm.get('price').enable();
      this.contentForm.get('currency').enable();
    }
    if (pContentData.ExpertMids !== null) {
      expertMidSplit = pContentData.ExpertMids.split('@mindtree.com');
    }
    if (pContentData.Academy != null) {
      this.contentForm.patchValue({ academy: pContentData.Academy });
      this.filterGenre();
    }
    if (pContentData.Genre != null) {
      this.contentForm.patchValue({ genre: pContentData.Genre });
      this.filterSkill();
    }

    if (pContentData.PreRequisiteCourseIDs !== null) {
      pContentData.PreRequisiteCourseIDs.forEach(splitId => {
        if (this.filteredPreReqCourses.length > 0) {
          this.filteredPreReqCourses.forEach(fliteredData => {
            if (fliteredData.Id == splitId) {
              fliteredData.checkedPreReq = true;
              fliteredData.Name = fliteredData.Name;
              fliteredData.Expertise = fliteredData.Expertise;
              this.selectedPrereqCourses.push(fliteredData);
            }
          });
        }
      });
    }
    if (pContentData.PostRecommendedCourseIDs !== null) {
      pContentData.PostRecommendedCourseIDs.forEach(splitId => {
        if (this.filteredPostRecommCourses.length > 0) {
          this.filteredPostRecommCourses.forEach(fliteredPostData => {
            if (fliteredPostData.Id == splitId) {
              fliteredPostData.checkedPostRecomm = true;
              fliteredPostData.Name = fliteredPostData.Name;
              fliteredPostData.Expertise = fliteredPostData.Expertise;
              this.selectedPostRecommCourses.push(fliteredPostData);
            }
          });
        }
      });
    }

    if (!pContentData.CreditSpecial) {
      if (pContentData.IsInfrastructure) {
        if (pContentData.InfrastructureType) {
          this.contentForm.patchValue({
            infrastructure: pContentData.Infrastructure,
            infrastructureType: pContentData.InfrastructureType
          });
        }
      }
      if (pContentData.IsLoginRequired) {
        if (pContentData.CoursesNameForSelfRegistration != null) {
          let counter = 0;
          pContentData.CoursesNameForSelfRegistration.forEach(
            CoursesNameForSelfRegistration => {
              if (counter !== 0 && !this.isSaveClicked)
                this.addSelfRegistrationName();
              counter++;
            }
          );
        }
        if (pContentData.LinktoCoursesForSelfRegistration != null) {
          let LinktoCoursesForcounter = 0;
          pContentData.LinktoCoursesForSelfRegistration.forEach(
            LinktoCoursesForSelfRegistration => {
              if (LinktoCoursesForcounter !== 0 && !this.isSaveClicked)
                this.addSelfRegistrationLink();
              LinktoCoursesForcounter++;
            }
          );
        }
        if (pContentData.CoursesNameForLearningOPMTeam != null) {
          let CoursesNamecounter = 0;
          pContentData.CoursesNameForLearningOPMTeam.forEach(
            CoursesNameForLearningOPMTeam => {
              if (CoursesNamecounter !== 0 && !this.isSaveClicked)
                this.addLearnerOPMName();
              CoursesNamecounter++;
            }
          );
        }
        if (pContentData.LinktoCoursesForLearningOPMTeam != null) {
          let LinktoCoursecounter = 0;
          pContentData.LinktoCoursesForLearningOPMTeam.forEach(
            LinktoCoursesForLearningOPMTeam => {
              if (LinktoCoursecounter !== 0 && !this.isSaveClicked)
                this.addLearnerOPMLink();
              LinktoCoursecounter++;
            }
          );
        }
        if (pContentData.LoginType)
          this.contentForm.patchValue({ loginType: pContentData.LoginType });
        if (pContentData.CoursesNameForSelfRegistration)
          this.contentForm.patchValue({
            coursesNameForSelfRegistration:
              pContentData.CoursesNameForSelfRegistration
          });
        if (pContentData.LinktoCoursesForSelfRegistration)
          this.contentForm.patchValue({
            linktoCoursesForSelfRegistration:
              pContentData.LinktoCoursesForSelfRegistration
          });
        if (pContentData.loginDetails)
          this.contentForm.patchValue({
            loginDetails: pContentData.loginDetails
          });
        if (pContentData.CoursesNameForLearningOPMTeam)
          this.contentForm.patchValue({
            CoursesNameForLearningOPMTeam:
              pContentData.CoursesNameForLearningOPMTeam
          });
        if (pContentData.linktoCoursesForLearningOPMTeam)
          this.contentForm.patchValue({
            linktoCoursesForLearningOPMTeam:
              pContentData.linktoCoursesForLearningOPMTeam
          });

        if (this.isApproved) {
          setTimeout(() => {
            this.contentForm.get('coursesNameForSelfRegistration').disable();
            this.contentForm.get('linktoCoursesForSelfRegistration').disable();
            this.contentForm.get('loginDetails').disable();
            this.contentForm.get('coursesNameForLearningOPMTeam').disable();
            this.contentForm.get('linktoCoursesForLearningOPMTeam').disable();
          }, 10000);
        }
      }
      if (pContentData.isCourseProcedureReferenceDocument) {
        this.contentForm.get('isCourseProcedureReferenceDocument').setValue('Yes');
        this.contentForm.get('courseProcedureRefDocGroup.courseProcedureRefDocLink').setValue(pContentData.courseProcedureRefDocGroup.courseProcedureRefDocLink);
        this.courseRefLink = pContentData.courseProcedureRefDocGroup.courseProcedureRefDocLink;
      }
      if (pContentData.CourseCompletionGroup !== null) {
        const controls = <FormArray>(
          this.contentForm.get(
            'courseCompletionGroup.courseCompletionTypeFormArray'
          )
        );
        let counter = 0;
        for (let controlItem of controls.controls) {
          if (
            counter !== 2 &&
            counter !== 5 &&
            counter !== 8 &&
            counter !== 11
          ) {
            controlItem.setValue(
              pContentData.CourseCompletionGroup.CourseCompletionTypeFormArray[
              counter
              ]
            );
          }
          counter++;
        }
        this.contentForm.patchValue({
          courseCompletionGroup: {
            courseCompletionFormArray:
              !pContentData.CreditSpecial &&
                pContentData.CourseCompletionGroup !== null
                ? pContentData.CourseCompletionGroup.CourseCompletionFormArray
                : null
          }
        });
        this.courseCompletionDataForPreview =
          pContentData.CourseCompletionGroup.CourseCompletionTypeFormArray;
      }
      if (pContentData.Classroom) {
        let classCounter = 0;
        const classControl = <FormArray>(
          this.contentForm.get('classRoomGroup.classRoom')
        );
        if (pContentData.ClassRoomGroup != null) {
          pContentData.ClassRoomGroup.ClassRoom.forEach((classRoom, index) => {
            if (classCounter !== 0 && !this.isSaveClicked) this.addClassRoom();
            classControl.controls[classCounter].patchValue({
              noOfClassRoomSessions: classRoom.NoOfClassRoomSessions,
              nameOfClassRoomSessions: classRoom.NameOfClassRoomSessions,
              classroomDescription: classRoom.ClassroomDescription,
              // vendorOrFaultyName: classRoom.VendorOrFaultyName,
              isAssignmentRequired: classRoom.IsAssignmentRequired,
              assignmentDescription: classRoom.AssignmentDescription,
              assignmentContentLink: classRoom.AssignmentContentLink,
              isEvaluationRequired: classRoom.IsEvaluationRequired,
              evaluatorName: classRoom.EvaluatorName,
              evaluatorEmailID: classRoom.EvaluatorEmailID,
              evaluatorVendor: classRoom.EvaluatorVendor,
              evaluatorType: classRoom.EvaluatorType,
              locationofEvaluator: classRoom.LocationofEvaluator,
              evaluationTemplateLink: !this.isNullOrEmpty(
                classRoom.EvaluationTemplateLink
              )
                ? classRoom.EvaluationTemplateLink
                : '',
              maxScore: classRoom.MaxScore,
              passingScore: classRoom.PassingScore,
              isClassroomScheduled: classRoom.IsClassroomScheduled,
              classroomScheduledDateTime: classRoom.ClassroomScheduledDateTime
            });
            this.classroomMaxScoreValue[index] = classRoom.MaxScore;
            this.classroomPassingScoreValue[index] = classRoom.PassingScore;
            this.classroomAssignment[index] = classRoom.IsAssignmentRequired;
            if (this.isApproved) {
              classControl.controls[classCounter].disable();
              classControl.controls[classCounter].get('maxScore').disable();
              classControl.controls[classCounter].get('passingScore').disable();
            }
            classCounter++;
          });
        }
      }
      if (pContentData.ProjectWork) {
        let projectCounter = 0,
          evaluatorCounter = 0;
        const projectControl = <FormArray>(
          this.contentForm.get('projectGroup.projectWorkDetails')
        );
        const evaluatorControl = <FormArray>(
          this.contentForm.get('projectGroup.projectEvaluationDetails')
        );
        if (pContentData.ProjectGroup !== null) {
          pContentData.ProjectGroup.ProjectWorkDetails.forEach(
            (projectWork, index) => {
              if (projectCounter !== 0 && !this.isSaveClicked)
                this.addProjectWork();
              projectControl.controls[projectCounter].patchValue({
                projectDescription: projectWork.ProjectDescription,
                problemStatementLink: projectWork.ProblemStatementLink,
                projectDemo: projectWork.ProjectDemo,
                projectMaterialLink: projectWork.ProjectMaterialLink
                  ? projectWork.ProjectMaterialLink
                  : '',
                maxScore: projectWork.MaxScore,
                passingScore: projectWork.PassingScore,
                evaluationTemplateLink: projectWork.EvaluationTemplateLink
                  ? projectWork.EvaluationTemplateLink
                  : ''
              });
              this.projectMaxScoreValue[index] = projectWork.MaxScore;
              this.projectPassingScoreValue[index] = projectWork.PassingScore;
              if (this.isApproved) {
                projectControl.controls[projectCounter].disable();
                projectControl.controls[projectCounter]
                  .get('maxScore')
                  .disable();
                projectControl.controls[projectCounter]
                  .get('passingScore')
                  .disable();
              }
              projectCounter++;
            }
          );
          if (pContentData.ProjectGroup.ProjectEvaluationDetails != null) {
            pContentData.ProjectGroup.ProjectEvaluationDetails.forEach(
              evaluationDetails => {
                if (evaluatorCounter !== 0 && !this.isSaveClicked)
                  this.addProjectEvaluation();
                evaluatorControl.controls[evaluatorCounter].patchValue({
                  evaluatorName: evaluationDetails.EvaluatorName,
                  evaluatorEmailID: evaluationDetails.EvaluatorEmailID,
                  evaluatorVendor: evaluationDetails.EvaluatorVendor,
                  evaluatorType: evaluationDetails.EvaluatorType,
                  locationofEvaluator: evaluationDetails.LocationofEvaluator
                });
                if (this.isApproved) {
                  evaluatorControl.controls[evaluatorCounter].disable();
                }
                evaluatorCounter++;
              }
            );
          }
        }
      }
      if (pContentData.Assessment) {
        if (pContentData.AssessmentGroup) {
          if (
            this.contentForm.get('assessmentGroup.evaluatorList').value &&
            null !=
            this.contentForm.get('assessmentGroup.evaluatorList').value &&
            0 !=
            this.contentForm.get('assessmentGroup.evaluatorList').value.length
          ) {
            this.contentForm
              .get('assessmentGroup.evaluatorList')
              .value.forEach((element, key) => {
                this.deleteAssessmentEvaluation(0);
              });
          }

          let infra = [];
          let infraType = [];
          try {
            const controlsInfra = <FormArray>(
              this.contentForm.get('assessmentGroup.assesmentInfrastructure')
            );

            const controlsInfraType = <FormArray>(
              this.contentForm.get(
                'assessmentGroup.assesmentInfrastructureType'
              )
            );

            if (
              this.isNullOrEmpty(
                pContentData.AssessmentGroup.AssesmentInfrastructure
              ) ||
              pContentData.AssessmentGroup.AssesmentInfrastructure[4] == true
            ) {
              controlsInfra.controls[4].setValue(true);
              controlsInfraType.controls[6].setValue('None of the Above');
            } else {
              controlsInfra.controls[4].setValue(false);
              controlsInfraType.controls[6].setValue(null);
              infra = pContentData.AssessmentGroup.AssesmentInfrastructure;
              infraType =
                pContentData.AssessmentGroup.AssesmentInfrastructureType;
            }

            this.contentForm.patchValue({
              assessmentGroup: {
                assessmentDescription: pContentData.AssessmentGroup
                  .AssessmentDescription
                  ? pContentData.AssessmentGroup.AssessmentDescription
                  : '',

                assesmentInfrastructure: infra,
                assesmentInfrastructureType: infraType,
                assessmentType: pContentData.AssessmentGroup.AssessmentType
                  ? pContentData.AssessmentGroup.AssessmentType
                  : '',
                questionPaperLink: pContentData.AssessmentGroup
                  .QuestionPaperLink
                  ? pContentData.AssessmentGroup.QuestionPaperLink
                  : '',
                evaluationTemplateLink: pContentData.AssessmentGroup
                  .EvaluationTemplateLink
                  ? pContentData.AssessmentGroup.EvaluationTemplateLink
                  : '',
                maxScore: pContentData.AssessmentGroup.MaxScore
                  ? pContentData.AssessmentGroup.MaxScore
                  : '',
                passingScore: pContentData.AssessmentGroup.PassingScore
                  ? pContentData.AssessmentGroup.PassingScore
                  : '',
                evaluatorList: [],
                evaluatorType: pContentData.AssessmentGroup.EvaluatorType
                  ? pContentData.AssessmentGroup.EvaluatorType
                  : '',
                toolName: pContentData.AssessmentGroup.ToolName
                  ? pContentData.AssessmentGroup.ToolName
                  : '',
                onlineQuestionPaperLink: pContentData.AssessmentGroup
                  .OnlineQuestionPaperLink
                  ? pContentData.AssessmentGroup.OnlineQuestionPaperLink
                  : '',
                isAssessmentScheduled: pContentData.AssessmentGroup
                  .IsAssessmentScheduled
                  ? pContentData.AssessmentGroup.IsAssessmentScheduled ===
                    null ||
                    pContentData.AssessmentGroup.IsAssessmentScheduled === false
                    ? 'No'
                    : 'Yes'
                  : '',
                assessmentScheduledDate: pContentData.AssessmentGroup
                  .AssessmentScheduledDate
                  ? pContentData.AssessmentGroup.AssessmentScheduledDate
                  : ''
              }
            });
            this.assessmentMaxScoreValue = pContentData.AssessmentGroup.MaxScore
              ? pContentData.AssessmentGroup.MaxScore
              : '';
            this.assessmentPassingScoreValue = pContentData.AssessmentGroup
              .PassingScore
              ? pContentData.AssessmentGroup.PassingScore
              : '';

            if (this.isApproved) {
              this.contentForm.get('assessmentGroup.passingScore').disable();
              this.contentForm.get('assessmentGroup.maxScore').disable();
            }

            if (pContentData.AssessmentGroup) {
              if (
                this.contentForm.get('assessmentGroup.evaluatorList').value &&
                null !=
                this.contentForm.get('assessmentGroup.evaluatorList').value &&
                0 !=
                this.contentForm.get('assessmentGroup.evaluatorList').value
                  .length
              ) {
                this.contentForm
                  .get('assessmentGroup.evaluatorList')
                  .value.forEach((element, key) => {
                    this.deleteAssessmentEvaluation(0);
                  });
              }
              if (pContentData.AssessmentGroup.EvaluatorList) {
                pContentData.AssessmentGroup.EvaluatorList.forEach(
                  (element, key) => {
                    const evaluatorObj = {
                      evaluatorName: element.EvaluatorName
                        ? element.EvaluatorName
                        : '',
                      evaluatorEmail: element.EvaluatorEmail
                        ? element.EvaluatorEmail
                        : '',
                      evaluatorVendor: element.EvaluatorVendor
                        ? element.EvaluatorVendor
                        : ''
                    };
                    this.addAssessmentEvaluation();
                    this.contentForm
                      .get('assessmentGroup.evaluatorList')
                    ['controls'][key].controls.evaluatorName.setValue(
                      evaluatorObj.evaluatorName
                    );
                    this.contentForm
                      .get('assessmentGroup.evaluatorList')
                    ['controls'][key].controls.evaluatorVendor.setValue(
                      evaluatorObj.evaluatorVendor
                    );
                    this.contentForm
                      .get('assessmentGroup.evaluatorList')
                    ['controls'][key].controls.evaluatorEmail.setValue(
                      evaluatorObj.evaluatorEmail
                    );
                  }
                );
              }
            }
          } catch (error) {
            ////console.log('Exception in Assessment', error);
          }
          // this.assessmentMaxScoreValue = pContentData.AssessmentGroup.MaxScore;
          // this.assessmentPassingScoreValue = pContentData.AssessmentGroup.PassingScore;

          if (this.isApproved) {
            this.contentForm.get('assessmentGroup').disable();
          }
        }
        // if (
        //   pContentData.CourseCompletionGroup !== null ||
        //   pContentData.Classroom ||
        //   pContentData.ProjectWork ||
        //   pContentData.Assessment
        // ) {
        //   this.contentOrder = pContentData.ContentOrder;
        // }
      } else {
        this.contentForm.get('assessmentGroup.assessmentRadio').setValue('No');
        this.contentForm
          .get('assessmentGroup.assessmentType')
          .setValue('Offline');
        //this.contentForm.get('assessmentGroup.assessmentRadio').setValue('No');
        const controlsInfra = <FormArray>(
          this.contentForm.get('assessmentGroup.assesmentInfrastructure')
        );

        const controlsInfraType = <FormArray>(
          this.contentForm.get('assessmentGroup.assesmentInfrastructureType')
        );
        controlsInfra.controls[4].setValue(true);
        controlsInfraType.controls[6].setValue('None of the Above');
      }

    }

    if (
      pContentData.CourseCompletionGroup !== null ||
      pContentData.Classroom ||
      pContentData.ProjectWork ||
      pContentData.Assessment
    ) {
      this.contentOrder = pContentData.ContentOrder;
    }

    //   this.buildContentOrder();
    let contentOwnerName = '';
    if (pContentData.ContentOwner && null != pContentData.ContentOwner && '' != pContentData.ContentOwner) {
      this.contentOwnerMid = pContentData.ContentOwner

      this._shareContentPopupService.getUserListByMailId(pContentData.ContentOwner)
        .then((response: any) => {
          if (response.value[0]) {
            contentOwnerName = response.value[0].displayName;
          } else {
            contentOwnerName = pContentData.ContentOwner;
          }
          this.contentForm.controls['ContentOwner'].setValue(contentOwnerName);
        });
    } else {
      this.graphStoreSub = this.graphStore
        .select(fromGraphStore.getGraphUserName)
        .subscribe(res => {
          if (res) {
            contentOwnerName = res;
          } else {
            contentOwnerName = this.contentOwnerMid;
          }
        });
    }

    this.contentForm.patchValue({
      skill: pContentData.Skill,
      name: pContentData.Name,
      topicCovered: pContentData.TopicCovered,
      description: pContentData.Description,
      type: pContentData.Type,
      internal: pContentData.Internal === true ? 'Yes' : 'No',
      vendor: pContentData.Vendor,
      paid: pContentData.Paid === true ? 'Yes' : 'No',
      price: !this.isNullOrEmpty(pContentData.Price) ? priceSplit[0] : null,
      currency: !this.isNullOrEmpty(pContentData.Price) ? priceSplit[1] : null,
      paymentMode: pContentData.paymentMode,
      duration: pContentData.Duration,
      managerApproval: pContentData.ManagerApproval === true ? 'Yes' : 'No',
      preRequisiteCourseIDs: pContentData.PreRequisiteCourseIDs,
      postRecommendedCourseIDs: pContentData.PostRecommendedCourseIDs,
      isPreRequisiteMandatory:
        pContentData.IsPreRequisiteMandatory === true ? 'Yes' : 'No',
      eligibility: pContentData.Eligibility,
      accessibility: pContentData.Accessibility,
      credits: pContentData.Credits,
      classification: pContentData.Classification,
      badgeId: pContentData.Badge,
      proficiency: pContentData.Proficiency,
      SkillIds: pContentData.SkillIds,
      expertMids:
        pContentData.ExpertMids !== null
          ? this.getExpertMIds(expertMidSplit)
          : null,
      isIntroductoryVideo:
        pContentData.IsIntroductoryVideo === true ? 'Yes' : 'No',
      creditSpecial: pContentData.CreditSpecial === true ? 'Yes' : 'No',
      sourceType: pContentData.SourceType,
      introductoryVideo: pContentData.IntroductoryVideo,
      isInfrastructure: pContentData.IsInfrastructure,
      isLoginRequired: pContentData.IsLoginRequired,
      isLearningMaterial:
        pContentData.IsLearningMaterial === true ? 'Yes' : 'No',
      preSessionGroup: {
        preSessionLearningMaterialTextBox:
          pContentData.PreSessionGroup !== null
            ? pContentData.PreSessionGroup.PreSessionLearningMaterialTextBox
            : null,

        preSessionLearningMaterialLink:
          pContentData.PreSessionGroup !== null
            ? pContentData.PreSessionGroup.PreSessionLearningMaterialLink
            : null
      },
      duringSessionGroup: {
        duringSessionLearningMaterialTextBox:
          pContentData.DuringSessionGroup !== null
            ? pContentData.DuringSessionGroup
              .DuringSessionLearningMaterialTextBox
            : null,
        duringSessionLearningMaterialLink:
          pContentData.DuringSessionGroup !== null
            ? pContentData.DuringSessionGroup.DuringSessionLearningMaterialLink
            : null
      },
      postSessionGroup: {
        postSessionLearningMaterialTextBox:
          pContentData.PostSessionGroup !== null
            ? pContentData.PostSessionGroup.PostSessionLearningMaterialTextBox
            : null,
        postSessionLearningMaterialLink:
          pContentData.PostSessionGroup !== null
            ? pContentData.PostSessionGroup.PostSessionLearningMaterialLink
            : null
      },
      classRoomGroup: {
        isClassRoomRequired: pContentData.Classroom === true ? 'Yes' : 'No',
        classroomExecution:
          pContentData.ClassRoomGroup != null
            ? pContentData.ClassRoomGroup.ClassroomExecution
            : 1
      },
      projectGroup: {
        hasProjectWork: pContentData.ProjectWork === true ? 'Yes' : 'No',
        hasJumbleProject:
          pContentData.ProjectGroup != null
            ? pContentData.ProjectGroup.HasJumbleProject === true
              ? 'Yes'
              : 'No'
            : 'No',
        hasProjectEvaluation:
          pContentData.ProjectGroup != null
            ? pContentData.ProjectGroup.HasProjectEvaluation === true
              ? 'Yes'
              : 'No'
            : 'No'
      },
      assessmentGroup: {
        assessmentRadio: pContentData.Assessment === true ? 'Yes' : 'No'
      },
      certificateLink: pContentData.CertificateLink,
      badgeLink: pContentData.BadgeLink,
      sreenShotLink: pContentData.SreenShotLink,
      letterHeadLink: pContentData.LetterHeadLink,
      quizGroup: {
        quizRadio: pContentData.Quiz === true ? 'Yes' : 'No'
      },
    });

    if (this.isApproved) {
      this.contentForm.get('genre').disable();
      this.contentForm.get('skill').disable();
      this.contentForm.get('introductoryVideo').disable();
      this.contentForm.get('sourceType').disable();
    }

    if (pContentData.Classroom == true) {
      this.isClassroomRequired = true;
    } else {
      this.isClassroomRequired = false;
    }

    this._contentUploadSvc
      .getBadgeDetails(pContentData.Badge)
      .subscribe(badgeDetails => {
        this.showloading = false;
        if (!this.isNullOrEmpty(badgeDetails.BadgeName)) {
          this.contentForm.get('badge').setValue(badgeDetails.BadgeName);
        }
        this.findInvalidControls();
      });
  }

  downloadFile(fileType: string, fileSubType: string, index: number) {
    if (fileSubType === 'Pre') {
      if (
        this.contentForm.get('preSessionGroup.preSessionLearningMaterialLink')
          .value !== null &&
        this.contentForm.get('preSessionGroup.preSessionLearningMaterialLink')
          .value !== ''
      ) {
        this.openDownloadFileWithSasKey(this.contentForm.get('preSessionGroup.preSessionLearningMaterialLink').value);
      }
    } else if (fileSubType === 'OnGoing') {
      if (
        this.contentForm.get(
          'duringSessionGroup.duringSessionLearningMaterialLink'
        ).value !== null &&
        this.contentForm.get(
          'duringSessionGroup.duringSessionLearningMaterialLink'
        ).value !== ''
      ) {
        this.openDownloadFileWithSasKey(this.contentForm.get('duringSessionGroup.duringSessionLearningMaterialLink').value);
      }
    } else if (fileSubType === 'Post') {
      if (
        this.contentForm.get('postSessionGroup.postSessionLearningMaterialLink')
          .value != null &&
        this.contentForm.get('postSessionGroup.postSessionLearningMaterialLink')
          .value != ''
      ) {
        this.openDownloadFileWithSasKey(this.contentForm.get('postSessionGroup.postSessionLearningMaterialLink').value);
      }
    } else if (
      fileSubType === 'Sample Certificate' ||
      fileSubType === 'Sample Badge' ||
      fileSubType === 'Sample Screenshot' ||
      fileSubType === 'Sample Letter Head'
    ) {
      if (
        (this.contentForm.get('certificateLink').value !== '' &&
          this.contentForm.get('certificateLink').value !== null) ||
        (this.contentForm.get('badgeLink').value !== '' &&
          this.contentForm.get('badgeLink').value !== null) ||
        (this.contentForm.get('sreenShotLink').value !== '' &&
          this.contentForm.get('sreenShotLink').value !== null) ||
        (this.contentForm.get('letterHeadLink').value !== '' &&
          this.contentForm.get('letterHeadLink').value !== null)
      ) {
        if (fileSubType === 'Sample Certificate') {
          this.openDownloadFileWithSasKey(this.contentForm.get('certificateLink').value);
        } else if (fileSubType === 'Sample Badge') {
          this.openDownloadFileWithSasKey(this.contentForm.get('badgeLink').value);
        } else if (fileSubType === 'Sample Screenshot') {
          this.openDownloadFileWithSasKey(this.contentForm.get('sreenShotLink').value);
        } else if (fileSubType === 'Sample Letter Head') {
          this.openDownloadFileWithSasKey(this.contentForm.get('letterHeadLink').value);
        }
      }
    } else if (
      (fileSubType === 'ProblemStatement' || fileSubType === 'evaluator') &&
      fileType === 'classroomdetails'
    ) {
      const classRoomcontrols = <FormArray>(
        this.contentForm.get('classRoomGroup.classRoom')
      );
      if (
        (classRoomcontrols.controls[index].get('assignmentContentLink')
          .value !== null &&
          classRoomcontrols.controls[index].get('assignmentContentLink')
            .value !== '') ||
        (classRoomcontrols.controls[index].get('evaluationTemplateLink')
          .value !== null &&
          classRoomcontrols.controls[index].get('evaluationTemplateLink')
            .value !== '')
      ) {
        fileSubType === 'ProblemStatement'
          ? this.openDownloadFileWithSasKey(classRoomcontrols.controls[index].get('assignmentContentLink').value)
          : this.openDownloadFileWithSasKey(classRoomcontrols.controls[index].get('evaluationTemplateLink').value);
      }
    } else if (
      fileSubType === 'Material' ||
      fileSubType === 'ProblemStatement' ||
      (fileSubType === 'evaluator' && fileType === 'projectdetails')
    ) {
      const projectControls = <FormArray>(
        this.contentForm.get('projectGroup.projectWorkDetails')
      );
      if (
        (projectControls.controls[index].get('projectMaterialLink').value !=
          null &&
          projectControls.controls[index].get('projectMaterialLink').value !=
          '') ||
        (projectControls.controls[index].get('problemStatementLink').value !==
          null &&
          projectControls.controls[index].get('problemStatementLink').value !==
          '') ||
        (projectControls.controls[index].get('evaluationTemplateLink').value !=
          null &&
          projectControls.controls[index].get('evaluationTemplateLink').value !=
          '')
      ) {
        if (fileSubType === 'Material') {
          this.openDownloadFileWithSasKey(projectControls.controls[index].get('projectMaterialLink').value);
        } else if (fileSubType === 'ProblemStatement') {
          this.openDownloadFileWithSasKey(projectControls.controls[index].get('problemStatementLink').value);
        } else if (fileSubType === 'evaluator') {
          this.openDownloadFileWithSasKey(projectControls.controls[index].get('evaluationTemplateLink').value);
        }
      }
    } else if (
      (fileSubType === 'OnlineProblemStatement' ||
        fileSubType === 'OfflineProblemStatement' ||
        fileSubType === 'evaluator') &&
      fileType === 'assessmentdetails'
    ) {
      if (
        (this.contentForm.get('assessmentGroup.onlineQuestionPaperLink')
          .value !== '' &&
          this.contentForm.get('assessmentGroup.onlineQuestionPaperLink')
            .value !== null) ||
        (this.contentForm.get('assessmentGroup.questionPaperLink').value !==
          '' &&
          this.contentForm.get('assessmentGroup.questionPaperLink').value !==
          null) ||
        (this.contentForm.get('assessmentGroup.evaluationTemplateLink')
          .value !== '' &&
          this.contentForm.get('assessmentGroup.evaluationTemplateLink')
            .value !== null)
      ) {
        if (fileSubType === 'OnlineProblemStatement') {
          this.openDownloadFileWithSasKey(this.contentForm.get('assessmentGroup.onlineQuestionPaperLink').value);
        } else if (fileSubType === 'OfflineProblemStatement') {
          this.openDownloadFileWithSasKey(this.contentForm.get('assessmentGroup.questionPaperLink').value);
        } else {
          this.openDownloadFileWithSasKey(this.contentForm.get('assessmentGroup.evaluationTemplateLink').value);
        }
      }
    }
  }

  openDownloadFileWithSasKey(url) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = {
      url: url
    };

    const dialogRef = this._popup.open(
      DownloadBlobPopupComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(response => {
    });
  }



  showorHideButton(pdata, index = 0) {
    //pdata.className = 'form-action-btn actionButton marginTop10';
    pdata.innerText = 'Upload';
    pdata.title = 'Upload';
  }

  openStatusPopup(data, type) {
    let dataPayload;
    if (
      type == 'save' ||
      type == 'error' ||
      type == 'submit' ||
      type == 'deleteConfirmation'
    ) {
      dataPayload = data;
    } else if (
      type == 'exit' ||
      type == 'submitted' ||
      type == 'alertMsg' ||
      type == 'info' ||
      type == 'saveAlert'
    ) {
      dataPayload = data;
    } else {
      dataPayload = { selectedCourses: data };
    }
    if (type == 'saveAlert') {
      // this.globals.isContentUploadSaveAlertOpen = true;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: dataPayload,
      width: '250px',
      height: '250px',
      type: type
    };

    const response = this._popup.open(
      ContentUploadStatusPopupComponent,
      dialogConfig
    );
    response.afterClosed().subscribe(res => {
      if (res) {
        if (type === 'save') {
          this.courseUniqueId = null;
          this.isSaveClicked = false;
          //this.router.navigate(['contentuploadGrid/' + 'AO']);
          this.enableContentVerificationComponent.emit('201');
        } else if (
          type == 'exit' ||
          type == 'submitted' ||
          type == 'alertMsg' ||
          type == 'deleteConfirmation' ||
          type == 'info'
        ) {
          //do  nothing
        } else if (type == 'saveAlert') {
          //this.globals.isContentUploadSaveAlertOpen = false;
        } else {
          this.openpopup('', this.contentForm.getRawValue(), type);
        }
      } else {
        if (type === 'save') {
          this.showloading = true;
          this.getContentUpload201Details(this.courseUniqueId);
          this.isSaveClicked = true;
        } else if (type == 'error' || type == 'alertMsg' || type == 'info') {
          //do nothing
        } else if (type == 'saveAlert') {
          //this.globals.isContentUploadSaveAlertOpen = false;
        } else if (type == 'exit' || type == 'submitted') {
          this.enableContentVerificationComponent.emit('201');
        } else if (type == 'deleteConfirmation') {
          this.deleteOperationalData(data.index, data.group);
        } else {
          this.OnContentFormSubmit(type);
        }
      }
    });
  }

  onLearningMaterialSelect() {
    this.contentForm
      .get('isLearningMaterial')
      .valueChanges.subscribe(isLearningMaterialReq => {
        if (isLearningMaterialReq == 'Yes') {
          this.preGroupSubs = this.contentForm
            .get('preSessionGroup.preSessionLearningMaterialTextBox')
            .valueChanges.subscribe(val => {
              if (!this.isNullOrEmpty(val)) {
                this.preSessionSelected = true;
              } else {
                this.preSessionSelected = false;
              }
            });
          this.duringGroupSubs = this.contentForm
            .get('duringSessionGroup.duringSessionLearningMaterialTextBox')
            .valueChanges.subscribe(val => {
              if (!this.isNullOrEmpty(val)) {
                this.duringSessionSelected = true;
              } else {
                this.duringSessionSelected = false;
              }
            });
          this.postGroupSubs = this.contentForm
            .get('postSessionGroup.postSessionLearningMaterialTextBox')
            .valueChanges.subscribe(val => {
              if (!this.isNullOrEmpty(val)) {
                this.postSessionSelected = true;
              } else {
                this.postSessionSelected = false;
              }
            });
        } else if (isLearningMaterialReq == 'No') {
          this.preGroupSubs ? this.preGroupSubs.unsubscribe() : '';
          this.duringGroupSubs ? this.duringGroupSubs.unsubscribe() : '';
          this.postGroupSubs ? this.postGroupSubs.unsubscribe() : '';
        }
      });
  }

  numbersOnlyFields(type) {
    const reg = /[^0-9.]/g;
    const regDuration = /[0-9]{1,3}.[0-5]{1}[0-9]{1}.[0-5]{1}[0-9]{1}$/g;
    const regDigitsOnly = /[^0-9]/g;
    const timereg = /^\d{1,3}.\d{2}.\d{2}$/g;
    let fieldResult = '';
    switch (type) {
      case 'duration':
        const exp = new RegExp(/[0-9]{1,3}.[0-5]{1}[0-9]{1}.[0-5]{1}[0-9]{1}$/);
        let result = exp.test(this.contentForm.get('duration').value);
        if (!result) {
          fieldResult = this.contentForm
            .get('duration')
            .value.replace(regDuration, '');
        } else {
          // this.contentForm.controls['duration'].setValue(fieldResult);
        }
        let minutes = this.contentForm.get('duration').value.split('.')[1];
        let seconds = this.contentForm.get('duration').value.split('.')[2];
        if (minutes > 59) {
          if (seconds > 59) {
            this.errorMsgs.course_duration =
              'Minutes and Seconds fields cannot be more than 59';
          } else {
            this.errorMsgs.course_duration =
              'Minutes field cannot be more than 59';
          }
        } else if (seconds > 59) {
          this.errorMsgs.course_duration =
            'Seconds field cannot be more than 59';
        } else {
          this.errorMsgs.course_duration =
            'Please enter numbers in the format of HHH.MM.SS or HH.MM.SS.';
        }
        break;
      case 'credits':
        fieldResult = this.contentForm
          .get('credits')
          .value.replace(regDigitsOnly, '');
        this.contentForm.controls['credits'].setValue(fieldResult);
        break;
      case 'price':
        fieldResult = this.contentForm.get('price').value.replace(reg, '');
        this.contentForm.controls['price'].setValue(
          this.parseRegexDigitWithOneDot(fieldResult)
        );
        break;
      default:
        break;
    }
  }

  parseRegexDigitWithOneDot(fieldResult) {
    if (
      fieldResult &&
      fieldResult.match(/\./g) != null &&
      1 < fieldResult.match(/\./g).length
    ) {
      return parseFloat(fieldResult).toString();
    } else {
      return fieldResult;
    }
  }

  setFormErrorMsg(hasErr, errMsg) {
    this.formErr = hasErr;
    this.formErrMsg = errMsg;
  }

  openErrPopup(order, event) {
    event.stopPropagation();
    switch (order) {
      case 0:
        if (this.operationalErrMsg.infrastructure.length > 0) {
          this.openStatusPopup(
            {
              list: this.operationalErrMsg.infrastructure,
              group: 'Infrastructure Required',
              title: 'Field Validation'
            },
            'error'
          );
        }
        break;
      case 1:
        if (this.operationalErrMsg.login.length > 0) {
          this.openStatusPopup(
            {
              list: this.operationalErrMsg.login,
              group: 'Course Login Type',
              title: 'Field Validation'
            },
            'error'
          );
        }
        break;
      case 2:
        if (this.operationalErrMsg.learningMaterial.length > 0) {
          this.openStatusPopup(
            {
              list: this.operationalErrMsg.learningMaterial,
              group: 'Learning Material',
              title: 'Field Validation'
            },
            'error'
          );
        }
        break;
      case 3:
        if (this.operationalErrMsg.proofOfCourseCompletion.length > 0) {
          this.openStatusPopup(
            {
              list: this.operationalErrMsg.proofOfCourseCompletion,
              group: 'Proof of Course Completion',
              title: 'Field Validation'
            },
            'error'
          );
        }
        break;
      case 4:
        if (this.operationalErrMsg.classroom.length > 0) {
          this.openStatusPopup(
            {
              list: this.operationalErrMsg.classroom,
              group: 'Class Room',
              title: 'Field Validation'
            },
            'error'
          );
        }
        break;
      case 5:
        if (this.operationalErrMsg.project.length > 0) {
          this.openStatusPopup(
            {
              list: this.operationalErrMsg.project,
              group: 'Project Work',
              title: 'Field Validation'
            },
            'error'
          );
        }
        break;
      case 6:
        if (this.operationalErrMsg.assessment.length > 0) {
          this.openStatusPopup(
            {
              list: this.operationalErrMsg.assessment,
              group: 'Assessment',
              title: 'Field Validation'
            },
            'error'
          );
        }
        break;
    }
  }

  openYorbitCourses(isPreReq) {
    if (!this.isApproved) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = {
        data: {
          selectedCourses: isPreReq
            ? this.selectedPrereqCourses
            : this.selectedPostRecommCourses,
          title: isPreReq ? 'Pre-requisite Courses' : 'Post Recommended Courses',
          expertise: '201'
        },
        width: '250px',
        height: '250px'
      };
      const response = this._popup.open(CoursesPopupComponent, dialogConfig);
      response.afterClosed().subscribe(res => {
        if (res.canSubmit) {
          if (res.selectedCourses && res.selectedCourses.length > 0) {
            if (isPreReq) {
              this.selectedPrereqCourses = [];
              res.selectedCourses.forEach((course, key) => {
                if (this.filteredPreReqCourses.length > 0) {
                  let courseData = this.filteredPreReqCourses.filter(
                    c => c.Id === course.Id
                  )[0];
                  if (courseData) {
                    courseData.checkedPreReq = true;
                    courseData.Name = courseData.Name;
                    courseData.Expertise = courseData.Expertise;
                    this.selectedPrereqCourses.push(courseData);
                  }
                }
              });
              this.contentForm
                .get('preRequisiteCourseIDs')
                .setValue(this.selectedPrereqCourses.map(x => x.Id));
            }
            if (!isPreReq) {
              this.selectedPostRecommCourses = [];
              res.selectedCourses.forEach((course, key) => {
                if (this.filteredPostRecommCourses.length > 0) {
                  let courseData = this.filteredPostRecommCourses.filter(
                    c => c.Id === course.Id
                  )[0];
                  if (courseData) {
                    courseData.checkedPostRecomm = true;
                    courseData.Name = courseData.Name;
                    courseData.Expertise = courseData.Expertise;
                    this.selectedPostRecommCourses.push(courseData);
                  }
                }
              });
              this.contentForm
                .get('postRecommendedCourseIDs')
                .setValue(this.selectedPostRecommCourses.map(x => x.Id));
            }
          }
        } else {
          // do nothing
        }
      });
    }
  }

  isNullOrEmpty(value) {
    try {
      if (typeof value == 'object') {
        if (
          value == null ||
          value == undefined ||
          value == _.isEmpty({}) ||
          value.length == 0
        )
          return true;
        else return false;
      } else {
        if (
          value == undefined ||
          value == '' ||
          value.toString().trim() == '' ||
          value == null ||
          value.length == 0
        )
          return true;
        else return false;
      }
    } catch (Error) {
      // //console.log(Error.message);
      return true;
    }
  }

  subscribeToCanLoadContentUploadComp() {
    if (this.contentUploadCompSubscriptions == null) {
      this.contentUploadCompSubscriptions = this._contentUploadSvc
        .getStatusToShowContentUploadComp()
        .subscribe(res => {
          if (res === true) {
            this.checkBeforeExit();
          }
        });
    }
  }

  checkBeforeExit() {
    let popupMsg = '';
    if (!this.isApproved) {
      popupMsg =
        'Changes you have made (if any) and unsaved will be lost.<br/>Are you sure you want to Exit Form?';
    } else {
      popupMsg = 'Are you sure you want to Exit Form?';
    }
    // if (!this.isApproved) {
    this._contentUploadSvc.canLoadContentUploadComponent.next(false);
    let data = {
      // msg:
      //   'Changes you have made (if any) and unsaved will be lost.<br/>Are you sure you want to Exit Form?',
      msg: popupMsg,
      title: 'Exit Form'
    };
    let type = 'exit';
    this.openStatusPopup(data, type);
    // } else {
    //   this.enableContentVerificationComponent.emit('AO');
    // }
  }

  switchTab() {
    if (this.isEdit) {
      this.next();
    }
  }

  ngOnDestroy() {
    if (this.graphStoreSub) {
      this.graphStoreSub.unsubscribe();
    }
    if (this.contentUploadCompSubscriptions != null) {
      this.contentUploadCompSubscriptions.unsubscribe();
    }
    if (this.saveAlertSubscription != null) {
      clearTimeout(this.saveAlertSubscription);
      this.saveAlertSubscription = null;
    }
    this._contentUploadSvc.isInCUComponent = false;
  }

  getExpertMIds(expertList) {
    let experts = '';
    if (expertList && expertList.length > 0) {
      for (let i = 0; i < expertList.length; i++) {
        if (!this.isNullOrEmpty(expertList[i]))
          experts = experts + expertList[i];
      }
      let expLen = experts.length;
      if (experts[expLen - 1] == ',') {
        experts = experts.slice(0, expLen - 2);
      }
      return experts.trim();
    } else {
      return null;
    }
  }

  updateEvaluatorFromPopup(event, type, index) {
    switch (type) {
      case 'classroom':
        this.contentForm
          .get('classRoomGroup.classRoom')
        ['controls'][index].controls.evaluatorName.setValue(
          event[0].displayName
        );
        this.contentForm
          .get('classRoomGroup.classRoom')
        ['controls'][index].controls.evaluatorVendor.setValue(
          'Mindtree Internal'
        );
        this.contentForm
          .get('classRoomGroup.classRoom')
        ['controls'][index].controls.evaluatorEmailID.setValue(event[0].mail);
        break;

      case 'project':
        this.contentForm
          .get('projectGroup.projectEvaluationDetails')
        ['controls'][index].controls.evaluatorName.setValue(
          event[0].displayName
        );
        this.contentForm
          .get('projectGroup.projectEvaluationDetails')
        ['controls'][index].controls.evaluatorVendor.setValue(
          'Mindtree Internal'
        );
        this.contentForm
          .get('projectGroup.projectEvaluationDetails')
        ['controls'][index].controls.evaluatorEmailID.setValue(event[0].mail);
        break;

      case 'assessment':
        if (
          this.contentForm.get('assessmentGroup.evaluatorList').value &&
          null != this.contentForm.get('assessmentGroup.evaluatorList').value &&
          0 !=
          this.contentForm.get('assessmentGroup.evaluatorList').value.length
        ) {
          this.contentForm
            .get('assessmentGroup.evaluatorList')
            .value.forEach((element, key) => {
              this.deleteAssessmentEvaluation(0);
            });
        }
        event.forEach((element, key) => {
          this.addAssessmentEvaluation();
          this.contentForm
            .get('assessmentGroup.evaluatorList')
          ['controls'][key].controls.evaluatorName.setValue(
            element.displayName
          );
          this.contentForm
            .get('assessmentGroup.evaluatorList')
          ['controls'][key].controls.evaluatorVendor.setValue(
            'Mindtree Internal'
          );
          this.contentForm
            .get('assessmentGroup.evaluatorList')
          ['controls'][key].controls.evaluatorEmail.setValue(element.mail);
        });

        break;

      default:
        break;
    }
  }

  internalExternalRadioChange(type, index) {
    switch (type) {
      case 'classroom':
        this.contentForm
          .get('classRoomGroup.classRoom')
        ['controls'][index].controls.evaluatorName.setValue('');
        this.contentForm
          .get('classRoomGroup.classRoom')
        ['controls'][index].controls.evaluatorVendor.setValue('');
        this.contentForm
          .get('classRoomGroup.classRoom')
        ['controls'][index].controls.evaluatorEmailID.setValue('');
        break;

      case 'project':
        this.contentForm
          .get('projectGroup.projectEvaluationDetails')
        ['controls'][index].controls.evaluatorName.setValue('');
        this.contentForm
          .get('projectGroup.projectEvaluationDetails')
        ['controls'][index].controls.evaluatorVendor.setValue('');
        this.contentForm
          .get('projectGroup.projectEvaluationDetails')
        ['controls'][index].controls.evaluatorEmailID.setValue('');
        break;

      case 'assessment':
        if (
          this.contentForm.get('assessmentGroup.evaluatorList').value &&
          null != this.contentForm.get('assessmentGroup.evaluatorList').value &&
          0 !=
          this.contentForm.get('assessmentGroup.evaluatorList').value.length
        ) {
          this.contentForm
            .get('assessmentGroup.evaluatorList')
            .value.forEach((element, key) => {
              this.deleteAssessmentEvaluation(0);
            });
        }
        break;

      default:
        break;
    }
  }

  resetPayloadAssessmentGroup() {
    let assessmentGroup = this.contentUploadData.assessmentGroup;
    (assessmentGroup.assesmentInfrastructure = [
      false,
      false,
      false,
      false,
      true
    ]),
      (assessmentGroup.assesmentInfrastructureType = [
        null,
        null,
        null,
        null,
        null,
        null,
        'None of the Above'
      ]),
      (assessmentGroup.assessmentDescription = null),
      (assessmentGroup.assessmentScheduledDate = null),
      (assessmentGroup.assessmentType = ''),
      (assessmentGroup.questionPaperLink = ''),
      (assessmentGroup.evaluationTemplate = ''),
      (assessmentGroup.evaluationTemplateLink = ''),
      (assessmentGroup.evaluatorEmail = ''),
      (assessmentGroup.evaluatorName = ''),
      (assessmentGroup.evaluatorType = ''),
      (assessmentGroup.evaluatorVendor = ''),
      (assessmentGroup.isAssessmentScheduled = false),
      (assessmentGroup.maxScore = ''),
      (assessmentGroup.onlineQuestionPaper = ''),
      (assessmentGroup.onlineQuestionPaperLink = ''),
      (assessmentGroup.passingScore = ''),
      (assessmentGroup.questionPaper = ''),
      (assessmentGroup.questionPaperLink = ''),
      (assessmentGroup.toolName = '');
    this.assessmentPassingScoreValue = '';
    this.assessmentMaxScoreValue = '';
    const assessmentControl = this.contentForm.get('assessmentGroup');
    if (assessmentControl) {
      assessmentControl.reset({
        assessmentRadio: 'No'
      });
    }
  }

  resetPayloadClassroomGroup() {
    let classroomGroup = this.contentUploadData.classRoomGroup;
    for (let i = 0; i < classroomGroup.classRoom.length; i++) {
      let classroom = classroomGroup.classRoom[i];
      (classroom.noOfClassRoomSessions = null),
        (classroom.nameOfClassRoomSessions = null),
        (classroom.classroomDescription = null),
        // (classroom.vendorOrFaultyName = null),m
        (classroom.isAssignmentRequired = 'No'),
        (classroom.assignmentDescription = null),
        (classroom.assignmentContentLink = ''),
        (classroom.isEvaluationRequired = 'Yes'),
        (classroom.evaluatorName = null),
        (classroom.evaluatorEmailID = null),
        (classroom.evaluatorVendor = null),
        (classroom.evaluatorType = null),
        (classroom.locationofEvaluator = null),
        (classroom.evaluationTemplateLink = ''),
        (classroom.maxScore = ''),
        (classroom.passingScore = ''),
        (classroom.isClassroomScheduled = 'No'),
        (classroom.classroomScheduledDateTime = null);
      this.classroomPassingScoreValue[i] = '';
      this.classroomMaxScoreValue[i] = '';
    }
    const classControl = <FormArray>(
      this.contentForm.get('classRoomGroup.classRoom')
    );
    classControl.reset();
    for (let i = 0; i < classControl.length; i++) {
      this.deleteClassRoom(i);
    }
    this.addClassRoom();
    // this.classroomPassingScoreValue[0] = '';
    // this.classroomMaxScoreValue[0] = '';
  }

  resetPayloadProjectGroup() {
    if (this.contentUploadData.projectGroup.hasProjectWork == false) {
      let projectWork = this.contentUploadData.projectGroup.projectWorkDetails;
      for (let k = 0; k < projectWork.length; k++) {
        projectWork[k].evaluationTemplate = null;
        projectWork[k].evaluationTemplateLink = null;
        projectWork[k].maxScore = '';
        projectWork[k].passingScore = '';
        projectWork[k].problemStatement = null;
        projectWork[k].problemStatementLink = null;
        projectWork[k].projectDemo = 'No';
        projectWork[k].projectDescription = null;
        projectWork[k].projectMaterial = null;
        projectWork[k].projectMaterialLink = null;
        this.projectPassingScoreValue[k] = '';
        this.projectMaxScoreValue[k] = '';
        if (projectWork.length > 1) {
          this.deleteProjectWork(k);
        }
      }
    }
    if (this.contentUploadData.projectGroup.hasProjectEvaluation == false) {
      let projectEval = this.contentUploadData.projectGroup
        .projectEvaluationDetails;
      for (let i = 0; i < projectEval.length; i++) {
        projectEval[i].evaluatorEmailID = null;
        projectEval[i].evaluatorName = null;
        projectEval[i].evaluatorType = null;
        projectEval[i].evaluatorVendor = null;
        projectEval[i].locationofEvaluator = null;
        if (projectEval.length > 1) {
          this.deleteProjectEvaluation(i);
        }
      }
    }
    const projectControl = <FormArray>(
      this.contentForm.get('projectGroup.projectWorkDetails')
    );
    const evaluatorControl = <FormArray>(
      this.contentForm.get('projectGroup.projectEvaluationDetails')
    );
    if (projectControl.controls.length > 0)
      projectControl.controls[0].reset({
        projectDemo: 'No'
      });
    if (evaluatorControl.controls.length > 0)
      evaluatorControl.controls[0].reset();
  }

  resetPayloadCourseProcedureReferenceDocumentGroup() {
    if (this.contentUploadData.courseProcedureRefDocGroup.courseProcedureRefDocLink == null) {
      this.courseRefLink = null;
    }
  }

  resetPayloadLearningMaterial() { }

  onJumbleProjectProblemStatementChange() {
    this.contentForm
      .get('projectGroup.hasJumbleProject')
      .valueChanges.subscribe(hasJumbledProjects => {
        if (hasJumbledProjects === 'No') {
          //if more than one project is available
          if (
            this.contentForm.get('projectGroup.projectWorkDetails')['controls']
              .length > 1
          ) {
            let message =
              "You can have only one Project for a course where 'Jumble Project' is set to 'No'.<br/>";
            message +=
              'Please retain only one Project before making this change.';
            const data = {
              msg: message,
              title: 'Information'
            };
            this.openStatusPopup(data, 'alertMsg');
          }
        } else {
          // do nothing
        }
      });
  }

  deleteOperationalData(index, group) {
    if (group == 'projectWork') {
      this.deleteProjectWork(index);
    } else if (group == 'projectEvaluation') {
      this.deleteProjectEvaluation(index);
    } else if (group == 'classRoom') {
      this.deleteClassRoom(index);
    } else if (group == 'assessmentEvaluator') {
      this.deleteAssessmentEvaluation(index);
    } else if (group == 'selfRegCourseLink') {
      this.deleteSelfRegistrationLink(index);
    } else if (group == 'selfRegCourseName') {
      this.deleteSelfRegistrationName(index);
    } else if (group == 'opmCourseLink') {
      this.deleteLearnerOPMLink(index);
    } else if (group == 'opmCourseName') {
      this.deleteLearnerOPMName(index);
    }
  }

  openDeleteConfirmationPopup(i, group) {
    let type = 'deleteConfirmation';
    let data = {
      index: i,
      msg: 'Are you sure you want to delete?',
      group: group,
      title: 'Confirmation'
    };
    this.openStatusPopup(data, type);
  }

  onHasProjectWorkChange() {
    this.contentForm
      .get('projectGroup.hasProjectWork')
      .valueChanges.subscribe(val => {
        if (val == 'Yes') {
          this.contentForm
            .get('projectGroup.hasProjectEvaluation')
            .setValue('Yes');
        } else {
          this.contentForm
            .get('projectGroup.hasProjectEvaluation')
            .setValue('No');
        }
      });
  }

  popInformationBits(event, field) {
    event.stopPropagation();
    let message = '';
    switch (field) {
      case 'Duration':
        message =
          '<span class="text-center">Please provide duration in hours only.</span><br/>';
        message +=
          '<span class="text-center">Standard learning hours per day is 6 hours, hence if a course is for 5 days then the course duration will be 30 hours which should be entered as 30.00.00 (HHH.MM.SS). You can enter up to 3 digits as hours.</span>';
        break;
      case 'CourseClassification':
        message = '';
        break;
      case 'VideoLink':
        message =
          "<span class=\"text-center\">Please ensure that you provide course link based on the 'Video Type'. A sample link has been provided under the 'Video Link' field for your reference.</span>";
        break;
      case 'InfrastructureRequired':
        message =
          'Please provide the resource details that the Learner will require to complete the course.';
        message += 'You can choose among:<br/><br/>';
        message += '<b>Internal Cloud Lab:</b>';
        message +=
          '&nbsp;&nbsp;Choose this option when the course has an associated cloud lab provided by Nuvepro.Please ensure to get the cloud lab mapped to this course.<br/><br/>';
        message += '<b>Vendor Provided Cloud Lab:</b>';
        message +=
          '&nbsp;&nbsp;Choose this option when the course has a cloud lab provided by the associated course vendor.<br/><br/>';
        message += '<b>White Listed Software:</b>';
        message +=
          '&nbsp;&nbsp;Choose this option if the learner has to install any white listed software to complete his/her learning.<br/><br/>';
        message += '<b>iSupport Requested Software:</b>';
        message +=
          '&nbsp;&nbsp;Choose this option if the learner needs to install any software that requires him/her to raise a iSupport.';
        break;
      case 'ClassroomExecution':
        message = '<b>Managed by Vendor:</b>';
        message +=
          '&nbsp;&nbsp;Choose this option if the classroom execution and support is completely handled by the Vendor.<br/><br/>';
        message += '<b>Managed by Learning OPM:</b>';
        message +=
          '&nbsp;&nbsp;Choose this option if the classroom execution and support is partially/completely handled by the Learning OPM team.';
        break;
      case 'JumbleProject':
        message =
          'In many cases we have one problem statement associated with the course and the same is shared with all Learners who take up the course.';
        message +=
          'This may often lead to malpractices. Hence, Shoshin School has an option where in we can jumble problem statements amongst different learners taking up the same course.<br/><br/>';
        message +=
          "This is possible only if we are provided with two or more problem statements for the course. You can use this feature on Shoshin School by choosing Jumble Problem Statement as 'Yes'.";
        break;
    }
    let type = 'info';
    let data = {
      msg: message,
      title: 'Information',
      field: field
    };
    this.openStatusPopup(data, type);
  }

  onClassroomAssignmentChange(index) {
    const classRoomControl = <FormArray>(
      this.contentForm.get('classRoomGroup.classRoom')
    );
    const assignment = classRoomControl.controls[index].get(
      'isAssignmentRequired'
    ).value;
    if (assignment == 'Yes') {
      const value1 = classRoomControl.controls[index].get('maxScore').value;
      this.classroomMaxScoreValue[index] = value1;
      const value2 = classRoomControl.controls[index].get('passingScore').value;
      this.classroomPassingScoreValue[index] = value2;
    }
  }

  onClassroomRequiredChange(index) {

    const classRoomControl = <FormArray>(
      this.contentForm.get('classRoomGroup.classRoom')
    );
    classRoomControl.controls.forEach((element, index) => {
      this.classroomAssignment[index] = element.get(
        'isAssignmentRequired'
      ).value;

    });
  }

  onClassroomChange() {
    const classRoomControl = <FormArray>(
      this.contentForm.get('classRoomGroup.classRoom')
    );
    const classroom = this.contentForm.get('classRoomGroup.isClassRoomRequired')
      .value;

    if (classroom == 'Yes' || classroom) {
      this.contentForm
        .get('classRoomGroup.isClassRoomRequired')
        .setValue('Yes');
      this.isClassroomRequired = true;
    } else {
      this.contentForm.get('classRoomGroup.isClassRoomRequired').setValue('No');
      this.isClassroomRequired = false;
    }
  }

  deleteFile(type, field, index) {
    let url = '';
    if (type == 'Assessment') {
      if (field == 'EvaluationTemplate') {
        url = this.contentForm.get('assessmentGroup.evaluationTemplateLink')
          .value;
      } else if (field == 'OnlineQuestionPaper') {
        url = this.contentForm.get('assessmentGroup.onlineQuestionPaperLink')
          .value;
      }
    } else if (type == 'Project') {
      if (field == 'ReferenceMaterial') {
        url = this.contentForm.get('projectGroup.projectWorkDetails')[
          'controls'
        ][index].controls.projectMaterialLink.value;
      } else if (field == 'EvaluationTemplate') {
        url = this.contentForm.get('projectGroup.projectWorkDetails')[
          'controls'
        ][index].controls.evaluationTemplateLink.value;
      }
    } else if (type == 'ClassRoom') {
      if (field == 'EvaluationTemplate') {
        url = this.contentForm.get('classRoomGroup.classRoom')['controls'][
          index
        ].controls.evaluationTemplateLink.value;
      }
    } else if (type == 'ProofOfCompletion') {
      if (field == 'Sample Certificate') {
        url = this.contentForm.get('certificateLink').value;
      } else if (field == 'Sample Badge') {
        url = this.contentForm.get('badgeLink').value;
      } else if (field == 'Sample Screenshot') {
        url = this.contentForm.get('sreenShotLink').value;
      } else if (field == 'Sample Letter Head') {
        url = this.contentForm.get('letterHeadLink').value;
      }
    }
    if (url != '') {
      this.deleteFileFromBlob(type, field, this.courseUniqueId, url, index);
    }
  }

  deleteFileFromBlob(type, field, courseUniqueId, url, index) {
    if (type == 'Assessment') {
      if (field == 'EvaluationTemplate') {
        this.assessmentEvalDeletion = true;
      } else if (field == 'OnlineQuestionPaper') {
        this.assessmentQPDeletion = true;
      }
    } else if (type == 'Project') {
      if (field == 'ReferenceMaterial') {
        this.projectRefMaterialDeletion = true;
      } else if (field == 'EvaluationTemplate') {
        this.projectEvalDeletion = true;
      }
    }//else if(type == 'CourseProcedureReferenceDocument'){
    //this. courseRefMaterialDeletion = true;
    else if (type == 'ClassRoom') {
      if (field == 'EvaluationTemplate') {
        this.classRoomEvalDeletion = true;
      }
    } else if (type == 'ProofOfCompletion') {
      if (field == 'Sample Certificate') {
        this.sampleCertDeletion = true;
      } else if (field == 'Sample Badge') {
        this.sampleBadgeDeletion = true;
      } else if (field == 'Sample Screenshot') {
        this.sampleScreenshotDeletion = true;
      } else if (field == 'Sample Letter Head') {
        this.sampleLetterHeadDeletion = true;
      }
    }

    this._contentUploadSvc.deleteBlobFile(url).subscribe(
      res => {
        if (res) {
          let typePayload = type;
          let fieldPayload = field;
          if (type == 'ProofOfCompletion') {
            if (field == 'Sample Certificate') {
              fieldPayload = 'CertificateUpload';
            } else if (field == 'Sample Badge') {
              fieldPayload = 'CourseCompletionBadge';
            } else if (field == 'Sample Screenshot') {
              fieldPayload = 'ScreenShot';
            } else if (field == 'Sample Letter Head') {
              fieldPayload = 'LetterHead';
            }
            typePayload = 'CourseCompletion';
          }
          this._contentUploadSvc
            .deleteBlobEntriesFromTable(
              typePayload,
              fieldPayload,
              courseUniqueId
            )
            .subscribe(result => {
              if (result) {
                if (type == 'Assessment') {
                  if (field == 'EvaluationTemplate') {
                    this.contentForm
                      .get('assessmentGroup.evaluationTemplateLink')
                      .setValue('');
                    this.assessmentEvalDeletion = false;
                    this.inputForAssessmentEvaluationTemplate = 'clearCU';
                    this.btnInputAssessmentEvaluationTemplate = 'Upload';
                  } else if (field == 'OnlineQuestionPaper') {
                    this.contentForm
                      .get('assessmentGroup.onlineQuestionPaperLink')
                      .setValue('');
                    this.assessmentQPDeletion = false;
                    this.inputForOnlineQuestionPaper = 'clearCU';
                    this.btnInputOnlineQuestionPaper = 'Upload';
                  }
                } else if (type == 'Project') {
                  if (field == 'ReferenceMaterial') {
                    this.contentForm
                      .get('projectGroup.projectWorkDetails')
                    ['controls'][index].controls.projectMaterialLink.setValue(
                      ''
                    );
                    this.projectRefMaterialDeletion = false;
                    this.inputForProjectMaterial = 'clearCU';
                    this.btnInputProjectMaterial = 'Upload';
                  } else if (field == 'EvaluationTemplate') {
                    this.contentForm
                      .get('projectGroup.projectWorkDetails')
                    ['controls'][
                      index
                    ].controls.evaluationTemplateLink.setValue('');
                    this.projectEvalDeletion = false;
                    this.inputForProjectEvaluationTemplateFileUpload =
                      'clearCU';
                    this.btnInputProjectEvaluationTemplateFileUpload = 'Upload';
                  }
                } else if (type == 'ClassRoom') {
                  if (field == 'EvaluationTemplate') {
                    this.contentForm
                      .get('classRoomGroup.classRoom')
                    ['controls'][
                      index
                    ].controls.evaluationTemplateLink.setValue('');
                    this.classRoomEvalDeletion = false;
                    this.inputForAssignmentEvaluationTemplate = 'clearCU';
                    this.btnInputAssignmentEvaluationTemplate = 'Upload';
                  }
                } else if (type == 'ProofOfCompletion') {
                  if (field == 'Sample Certificate') {
                    this.contentForm.get('certificateLink').setValue('');
                    this.sampleCertDeletion = false;
                    this.courseCompletionTypeFileBox[0] = 'clearCU';
                    this.btnInputCourseCompletionTypeFileBox[0] = 'Upload';
                  } else if (field == 'Sample Badge') {
                    this.contentForm.get('badgeLink').setValue('');
                    this.sampleBadgeDeletion = false;
                    this.courseCompletionTypeFileBox[1] = 'clearCU';
                    this.btnInputCourseCompletionTypeFileBox[1] = 'Upload';
                  } else if (field == 'Sample Screenshot') {
                    this.contentForm.get('sreenShotLink').setValue('');
                    this.sampleScreenshotDeletion = false;
                    this.courseCompletionTypeFileBox[2] = 'clearCU';
                    this.btnInputCourseCompletionTypeFileBox[2] = 'Upload';
                  } else if (field == 'Sample Letter Head') {
                    this.contentForm.get('letterHeadLink').setValue('');
                    this.sampleLetterHeadDeletion = false;
                    this.courseCompletionTypeFileBox[3] = 'clearCU';
                    this.btnInputCourseCompletionTypeFileBox[3] = 'Upload';
                  }

                  const controls = <FormArray>(
                    this.contentForm.get(
                      'courseCompletionGroup.courseCompletionTypeFormArray'
                    )
                  );
                  let counter = 0;
                  for (let controlItem of controls.controls) {
                    if (counter == 2 || counter == 5 || counter == 8 || counter == 11) {

                      if (counter == 2) {
                        controlItem.setValue(this.contentForm.get('certificateLink').value);
                        this.courseCompletionDataForPreview[2] = this.contentForm.get('certificateLink').value;
                      } else if (counter == 5) {
                        controlItem.setValue(this.contentForm.get('badgeLink').value);
                        this.courseCompletionDataForPreview[5] = this.contentForm.get('badgeLink').value;
                      } else if (counter == 8) {
                        controlItem.setValue(this.contentForm.get('sreenShotLink').value);
                        this.courseCompletionDataForPreview[8] = this.contentForm.get('sreenShotLink').value;
                      } else if (counter == 11) {
                        controlItem.setValue(this.contentForm.get('letterHeadLink').value);
                        this.courseCompletionDataForPreview[11] = this.contentForm.get('letterHeadLink').value;
                      }

                    }
                    counter++;
                  }
                }
              }
            });
        }
      },
      error => { }
    );
  }
  canDeleteSampleProof(field) {
    let url = '';
    let delStatus = false;
    if (field == 'Sample Certificate') {
      url = this.contentForm.get('certificateLink').value;
      delStatus = this.sampleCertDeletion;
    } else if (field == 'Sample Badge') {
      url = this.contentForm.get('badgeLink').value;
      delStatus = this.sampleBadgeDeletion;
    } else if (field == 'Sample Screenshot') {
      url = this.contentForm.get('sreenShotLink').value;
      delStatus = this.sampleScreenshotDeletion;
    } else if (field == 'Sample Letter Head') {
      url = this.contentForm.get('letterHeadLink').value;
      delStatus = this.sampleLetterHeadDeletion;
    }
    if (!delStatus && !this.isNullOrEmpty(url)) {
      return true;
    }
    return false;
  }
  canShowProofDelStatus(field) {
    let url = '';
    let delStatus = false;
    if (field == 'Sample Certificate') {
      url = this.contentForm.get('certificateLink').value;
      delStatus = this.sampleCertDeletion;
    } else if (field == 'Sample Badge') {
      url = this.contentForm.get('badgeLink').value;
      delStatus = this.sampleBadgeDeletion;
    } else if (field == 'Sample Screenshot') {
      url = this.contentForm.get('sreenShotLink').value;
      delStatus = this.sampleScreenshotDeletion;
    } else if (field == 'Sample Letter Head') {
      url = this.contentForm.get('letterHeadLink').value;
      delStatus = this.sampleLetterHeadDeletion;
    }
    if (delStatus && !this.isNullOrEmpty(url)) {
      return true;
    }
    return false;
  }

  openSearchCODialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //shareDialogContainer

    dialogConfig.data = {
      type: 'contentOwner',
      list: [this.contentOwnerMid]
    };

    const dialogref = this._popup.open(SearchEvaluatorPopupComponent, dialogConfig);

    dialogref.afterClosed().subscribe(data => {
      if (null != data) {
        this.contentForm.controls['ContentOwner'].setValue(data[0].displayName);
        let mid = data[0].userPrincipalName.split("@")[0];
        this.contentOwnerMid = mid;
      }
    });
  }
  getCourseProficiencyList() {
    this._contentUploadSvc.getCourseProficiencyList().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseProficiencyList = response;
        } else {
          this.courseProficiencyList = [];
        }
      },
      (error: any) => {
        this.courseProficiencyList = [];
      }
    );
  }

  getAnyCompletionSelected() {
    let status = false;
    let controls = <FormArray>(
      this.contentForm.get(
        'courseCompletionGroup.courseCompletionFormArray'
      ).value);

    for (let i = 0; i < controls.length; i++) {
      status = status || controls[i];
    }

    return status;
  }

  updateProofOfCompletion() {

    const control = <FormArray>(
      this.contentForm.get('courseCompletionGroup.courseCompletionFormArray')
    );
    if (this.contentForm.get('type').value == 'TF3SMEEvaluation') {
      this.courseCompletionList.forEach((item, key) => {
        if (item.Description == "SME Evaluation Upload") {
          control.controls[key].setValue(true);
        } else {
          control.controls[key].setValue(false);
        }
      });
    } else {
      this.courseCompletionList.forEach((item, key) => {
        if (item.Description == "SME Evaluation Upload") {
          control.controls[key].setValue(false);
        }
      });
    }
  }
}
