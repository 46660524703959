<!-- <div
  class="check-compliance-container"
  *ngIf="!isComplianceDetailsLoading"
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutGap="35px"
> -->
<div
  class="check-compliance-container"
  *ngIf="false"
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutGap="35px"
>
  <div fxFlex.gt-md fxLayout="column" fxLayoutGap="35px">
    <div class="compliance-details" fxLayout="column" fxLayoutGap.lt-lg="5px">
      <div class="compliance-status" fxLayoutAlign.gt-md="start center" fxLayoutAlign.lt-lg="start start">
        <div class="status-label" fxFlex.gt-md="250px" fxFlex.lt-lg>
          Compliance Status
        </div>
        <div fxFlex="20px" fxLayoutAlign="center center">:</div>
        <div class="value" *ngIf="complianceDetails == null"  fxFlex.lt-lg>
          Loading...
        </div>
        <div class="value"
          [ngClass]="{
            statustrue:
              (complianceDetails.ComplianceStatus | lowercase) == 'compliant',
            statusfalse:
              (complianceDetails.ComplianceStatus | lowercase) ==
              'non-compliant'
          }"
          fxFlex.lt-lg
        >
          {{ complianceDetails.ComplianceStatus }}
        </div>
      </div>
      <div class="compliance-status" fxLayoutAlign.gt-md="start center" fxLayoutAlign.lt-lg="start start">
        <div class="status-label" fxLayoutAlign="start center" fxFlex.gt-md="250px"  fxFlex.lt-lg>
          Total credits required to be compliant
        </div>
        <div fxFlex="20px" fxLayoutAlign="center center">:</div>
        <div class="value" *ngIf="complianceDetails == null"  fxFlex.lt-lg>
          Loading...
        </div>
        <div class="value" *ngIf="complianceDetails != null"  fxFlex.lt-lg>
          {{ complianceDetails.RequiredCredits }}
        </div>
      </div>
      <div class="compliance-status" fxLayoutAlign.gt-md="start center" fxLayoutAlign.lt-lg="start start">
        <div class="status-label" fxFlex.gt-md="250px"  fxFlex.lt-lg>
          Current Cycle
        </div>
        <div fxFlex="20px" fxLayoutAlign="center center">:</div>
        <div class="value" *ngIf="complianceDetails == null"  fxFlex.lt-lg>
          Loading...
        </div>
        <div class="value" *ngIf="complianceDetails != null" fxFlex.lt-lg>
          {{ complianceDetails.CycleStartDate}} -
          {{ complianceDetails.CycleEndDate}}
        </div>
      </div>
      <div class="compliance-status" fxLayoutAlign.gt-md="start center" fxLayoutAlign.lt-lg="start start">
        <div class="status-label" fxFlex.gt-md="250px"  fxFlex.lt-lg>
          Acquired Credit(s)
        </div>
        <div fxFlex="20px" fxLayoutAlign="center center">:</div>
        <div class="value" *ngIf="complianceDetails == null"  fxFlex.lt-lg>
          Loading...
        </div>
        <div class="value" *ngIf="complianceDetails != null"  fxFlex.lt-lg>
          {{ complianceDetails.AchievedCredits }}
        </div>
      </div>

      <div class="compliance-status" fxLayoutAlign.gt-md="start center" fxLayoutAlign.lt-lg="start start">
        <div class="status-label" fxFlex.gt-md="250px"  fxFlex.lt-lg>
          Remaining Credit(s)
        </div>
        <div fxFlex="20px" fxLayoutAlign="center center">:</div>
        <div class="value" *ngIf="complianceDetails == null"  fxFlex.lt-lg>
          Loading...
        </div>
        <div class="value" *ngIf="complianceDetails != null"  fxFlex.lt-lg>
        {{ (complianceDetails.RequiredCredits - complianceDetails.AchievedCredits) <= 0 ? 0 : (complianceDetails.RequiredCredits - complianceDetails.AchievedCredits) }}
        </div>
      </div>

    </div>
    <div class="compliance-info" fxLayout="column" fxLayoutGap="15px">
      <div class="pls-note"><b>Please Note:</b></div>
      <!-- <ol>
        <li *ngFor="let info of complianceInfo; let i = index">
          <div
            [innerHTML]="info"
            [ngClass]="{ 'li-highlight': i === 0 || i === 1 }"
          ></div>
        </li>
        <li>
          To know more, check
          <span class="mousePointer faq-link" (click)="routeToComplianceFAQ()"
            >FAQs</span
          >
          <span>(Learning Policy Compliance)</span>
        </li>
      </ol> -->
      <div fxLayout="column" fxLayoutGap="15px">
        <div
          *ngFor="let info of complianceInfo; let i = index"
          fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="10px"
        >
          <div class="list-label" fxFlex="30px" fxLayoutAlign="center center">
            {{ i + 1 }}
          </div>
          <div
            fxFlexAlign="center"
            [innerHTML]="info"           
          ></div>
          <!--  [ngClass]="{ 'li-highlight': i === 0 || i === 1 , 'statusfalse' : i == 0|| i === 1}" -->
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <div class="list-label" fxFlex="30px" fxLayoutAlign="center center">
            {{ complianceInfo.length + 1 }}
          </div>
          <div fxFlexAlign="center">
            To know more, check
            <span class="faq-link" (click)="routeToComplianceFAQ()">FAQs</span>
            (Learning Policy Compliance).
          </div>
        </div> -->
      </div>
    </div>

    <!-- <div class="compliance-policy-link" fxFlexOffset="-10px">
      <div class="header" *ngIf="role == 'self'">
        Link to your learning policy<span>:</span>
      </div>
      <div *ngIf="role == 'learner'" class="header">
        Link to learner's learning policy<span>:</span>
      </div>
      <div class="policy-link" *ngIf="complianceDetails == null">
        Loading...
      </div>
      <div class="policy-link" *ngIf="complianceDetails != null">
        <span *ngIf="learnerNA == true">Not Applicable</span>
        <span *ngIf="linkNA == true">Policy link is not available</span>
        <span *ngIf="connectWithCDM == true"
          >To learn more about the learning policy applicable to you, connect
          with your Learning Partner</span
        >
        <a
          (click)="TrackComplianceDetails()"
          *ngIf="linkAvailable == true"
          href="{{ complianceDetails.CompliancePolicyUrl }}"
          rel="noreferrer"
          target="_blank"
        >
          {{ complianceDetails.CompliancePolicyUrl }}</a
        >
      </div>
    </div> -->
    <div class="cdm-rm-details" fxFlexOffset="-10px">
      <div *ngIf="myCDMs != null" fxLayout="row wrap" fxLayoutGap="10px">
        <div>My Learning Business Partner(s) :</div>
        <div *ngFor="let cdm of myCDMs; let i = index" class="name">
          {{ cdm }}{{ i != myCDMs.length - 1 ? ',' : '' }}
        </div>
      </div>
      <div *ngIf="myRM != null">My RM: {{ myRM }}</div>
      <div *ngIf="myCDMs != null" class="disclaimer">
        ( Please contact Learning Business Partner(s) for any issue regarding learning policy )
      </div>
    </div>
    <div *ngIf="role == 'self'" fxLayout="column">
      <div class="report-query" *ngIf="showReportButton">
        If you have any other query
        <span class="link mousePointer" (click)="showReportButton = false"
          >Report here</span
        >
        <!-- <button class="mousePointer" (click)="showReportButton = false">
          Report an issue
        </button> -->
        <!-- <div *ngIf="!isReportSent" style="color:#000;font-weight:bold;font-size:10px">
        Kindly wait for 5 days post course completion for credits and compliance
        status to reflect on Yorbit.
      </div> -->
        <div *ngIf="isReportSent" class="report-status-info">
          Your issue has been recorded and sent to the concerned team. Thank
          You!
        </div>
      </div>
      <div class="send-report" *ngIf="!showReportButton" id="bottom">
        <div class="mousePointer send-report-label">Report an issue</div>
        <div class="category-drop-down">
          <div>Select a category</div>
          <select
            [(ngModel)]="reportCategory"
            [disabled]="isSubmitInProgress"
            [ngStyle.lt-md]="{ width: '80%' }"
          >
            <option value="" selected disabled>Select a category</option>
            <option [value]="option" *ngFor="let option of categoryOptions">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="comment-box">
          <div>Write a comment</div>
          <textarea
            style="resize:none"
            [(ngModel)]="reportComment"
            maxlength="500"
            title="{{
              reportCategory == '' || reportCategory == null
                ? 'Please select a category'
                : ''
            }}"
            [disabled]="
              reportCategory == '' ||
              reportCategory == null ||
              isSubmitInProgress
            "
            [ngClass]="{
              mouseDisabled:
                reportCategory == '' ||
                reportCategory == null ||
                isSubmitInProgress
            }"
            [ngStyle.lt-md]="{ width: '80%' }"
          ></textarea>
          <div *ngIf="submitFailed">
            Something went wrong. Please try later.
          </div>
        </div>
        <div class="send-report-button">
          <button
            (click)="submitReport()"
            class="floatLeft mousePointer"
            *ngIf="!isSubmitInProgress"
            title="{{
              reportComment == '' || reportComment == null
                ? 'Comment box cannot be empty'
                : ''
            }}"
            [ngClass]="{
              mouseDisabled: reportComment == '' || isSubmitInProgress
            }"
            [disabled]="reportComment == '' || isSubmitInProgress"
          >
            submit
          </button>
          <button
            *ngIf="isSubmitInProgress"
            class="floatLeft mouseDisabled"
            [disabled]="true"
          >
            In progress...
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    fxFlex.gt-md="290px"
    fxLayout.gt-md="column"
    fxLayoutAlign.gt-md="start stretch"
    class="courses-completed"
  >
    <div fxFlex.gt-md fxLayout="column" fxLayoutAlign="start stretch">
      <div fxFlex="40px" class="courses-completed-label">
        Courses considered for compliance
      </div>
      <div
        class="list-of-courses"
        [ngClass.lt-md]="['list-of-courses-lt-md']"
        fxFlex.gt-md
        fxLayout.lt-lg="row wrap"
        fxLayout.gt-md="column"
        fxLayoutAlign.gt-md="stretch center"
        fxLayoutAlign.lt-lg="start start" 
        *ngIf="
          (complianceDetails.ComplianceStatus | lowercase) != 'exempted' &&
          complianceDetails.ComplianceCourses.length > 0
        "
      >
        <div
          class="course-details" class="border{{ course.RepoType != 'External' ? course.Expertise : '000' }}"
          [ngStyle.gt-xs]="{ width: '16em' }"
          *ngFor="let course of complianceDetails.ComplianceCourses"
        >
          <div class="bg{{ course.RepoType != 'External' ? course.Expertise : '000' }}">
            <div class="course">
              <div class="course-expertise" *ngIf="course.RepoType != 'External'">{{ course.Expertise }}</div>
              <div class="ext-learning" *ngIf="course.RepoType == 'External'">{{ 'External Learning' }}</div>              
              <div
                class="course-name"
                title="{{
                  course.CourseName.length > 94 ? course.CourseName : ''
                }}"
              >
                {{ course.CourseName }}
              </div>
              <div class="content-representation">{{ 'C' }}</div>
            </div>
            <div class="credit-detail">
              {{ 'Credit(s) : ' + course.Credit }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="list-of-courses"
        style="padding: 60px 30px;text-align: center;"
        *ngIf="
          (complianceDetails.ComplianceStatus | lowercase) != 'exempted' &&
          complianceDetails.ComplianceCourses.length == 0
        "
      >
        No courses completed so far for learning compliance.
      </div>
      <div
        class="list-of-courses"
        style="padding: 60px;text-align: center;"
        *ngIf="
          (complianceDetails.ComplianceStatus | lowercase) == 'exempted' &&
          complianceDetails.ComplianceCourses.length == 0
        "
      >
        Not Applicable.
      </div>
    </div>
  </div>
</div>

<div
  class="check-compliance-container"
  [ngStyle.lt-md]="{ display: 'table' }"
  *ngIf="false">
  <!-- *ngIf="isComplianceDetailsLoading" -->

  <app-preloader></app-preloader>

 
</div>



  <div
        *ngFor="let info of complianceInfo; let i = index"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="10px"
        class="defaultMessage"
      >
      
        <div
          fxFlexAlign="center"
          [innerHTML]="info"           
        ></div>
        <!--  [ngClass]="{ 'li-highlight': i === 0 || i === 1 , 'statusfalse' : i == 0|| i === 1}" -->
      </div>