import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-upload-sme-request-info-popup',
  templateUrl: './upload-sme-request-info-popup.component.html',
  styleUrls: ['./upload-sme-request-info-popup.component.scss']
})
export class UploadSmeRequestInfoPopupComponent implements OnInit {
  data: any;
  payload:any;
  intents: any = [];
  context: any;
  message :any;
  fileName:string;
  info : boolean = false;
  headingText: string ="Are you sure you want to exit the form? Any unsaved changes will be lost.";
  constructor(
    public dialogRef: MatDialogRef<UploadSmeRequestInfoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) popupData,) { 
      console.log(popupData);
      this.data=popupData;
      if(this.data.isInfo){
        this.info=true;
      }
     // this.payload=data.payload
      //this.fileName=data.fileName
    }

 

  ngOnInit() {
   
  }

  close(canSwitchTab) {
    this.dialogRef.close(canSwitchTab);
  }

  justClose(){
    this.dialogRef.close();
  }

  UploadSME(){
    this.dialogRef.close(true);
  }
}
