<mat-dialog-content>
  <!--Title-->
  <div fxLayout="column">
    <!-- <div class="closePopupBtn" *ngIf="renewalFailed">
      <button class="mat-button mat-icon-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div> -->
  <div class="popup-title">
    <div class="heading notifications-header">Session Information</div>
  </div>
  <div class="description message" *ngIf="!renewalFailed">
    <app-preloader></app-preloader>
  </div>
  <div class="description message">
   {{renewalFailed?"Sorry, session could not be extended. Please re-login to Shoshin School.":"Please wait..."}} 
  </div>
    <!--Mat divider-->
    <mat-divider *ngIf="renewalFailed"></mat-divider>
    <!--Action buttons-->
    <div class="action-btn" fxFlex="35px" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="renewalFailed">
      <button class="action-button" *ngIf="!sessionActive" (click)="close('logout')" cdkFocusInitial>Log In</button>
    </div>
  </div>


</mat-dialog-content>