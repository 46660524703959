<div *ngIf="!quizNA" class="quiz-comp-page" fxLayoutAlign="center stretch">
  <div fxFlexFill class="quiz-container expertise{{itemExpertise}}" fxLayout="column" fxLayoutAlign="center center" *ngIf="!isQuizLoaded"
    [ngStyle.lt-md]="{ padding: '0 10px', posiiton: 'relative' }" [ngStyle.gt-sm]="{ padding: '0 40px' }">
    <app-preloader></app-preloader>
  </div>

  <!-- Quiz page laid out using Flex -->
  <div fxFlex.gt-md="1280px" fxFlexFill.lt-lg class="quiz-container expertise{{itemExpertise}}" *ngIf="isQuizLoaded" fxLayout="column"
    [ngStyle.lt-md]="{ padding: '0px 10px', position: 'relative' }" [ngStyle.gt-sm]="{ padding: '0 40px' }">
    <!--back arrow for quiz summary-->

    <!-- Course name -->
    <div class="course-name" fxFlex.gt-sm="50px" fxLayout="row wrap" fxLayoutAlign=" start stretch">
      <div class="mousePointer backIcondiv" fxFlex="50px" *ngIf="showQuizSummary" fxLayoutAlign="start center" (click)="showScore()">
        <mat-icon class="backIcon">keyboard_backspace</mat-icon>
      </div>
      <div fxLayoutAlign="start center" class="textTruncate" title="{{courseName}}" fxFlex.lt-md="80%" fxFlex.gt-sm>
        {{ courseName }}
      </div>
      <!--exclamatory icon for instruction popup-->
      <div fxFlex.lt-md="100%" fxLayoutAlign="end start" class="mousePointer" fxLayoutGap="10px" *ngIf="!showQuizSummary && !showQuizScore">
        <div class="instructions-link" (click)="openInstructionPopup()">Read Instructions</div>
        <a href={{globals.isupportUrl}} target="_blank">
          <div class="yorbitSprite reportIcon" title="Report issue with quiz." (click)="isYorbitHelpClicked()"></div>
        </a>
      </div>
    </div>
    <!-- End of course name -->

    <!-- Back button in quiz summary -->
    <!-- <div
        *ngIf="showQuizSummary"
        class="back-btn"
        [ngStyle.lt-md]="{ padding: '20px 16px' }"
        [ngStyle.gt-sm]="{ padding: '20px 50px' }"
      >
        <button (click)="showQuizSummary = false">Back</button>
      </div> -->
    <!-- End of Back button in quiz summary -->

    <!-- Quiz details holder -->
    <div class="quiz-details-holder" [ngStyle]="{'overflow-y':showQuizSummary ? 'auto' : 'none' }" fxLayout="row" fxFlex>
      <!-- List of Questions Template -->
      <ng-template *ngIf="!showQuizSummary" [ngTemplateOutlet]="quizQuestionsHolder"></ng-template>
      <!-- End -->

      <!-- Quiz summary Template -->
      <ng-template *ngIf="showQuizSummary" [ngTemplateOutlet]="quizSummary"></ng-template>
      <!-- End -->
    </div>
    <!-- End of Quiz details holder -->

    <!-- Resuable templates -->

    <!-- Template for showing and hiding quiz questions -->
    <ng-template #quizQuestionsHolder>
      <!-- Show questions template -->
      <ng-template *ngIf="!showQuizScore" [ngTemplateOutlet]="quizQuestions"></ng-template>
      <!-- End -->

      <!-- Show score template -->
      <ng-template *ngIf="showQuizScore" [ngTemplateOutlet]="quizScore"></ng-template>
      <!-- End -->
    </ng-template>
    <!-- End of Template for showing and hiding quiz questions -->

    <!-- Start of questions template. Shows one question at a time -->
    <ng-template #quizQuestions>
      <div fxLayout="column" fxFlex fxLayoutAlign="start stretch">
        <!-- <div fxFlex.gt-sm="120px" fxLayout="row wrap" fxLayoutAlign="start start" class="quiz-info"
          [ngStyle.lt-md]="{ padding: '20px' }"
          [ngStyle.gt-sm]="{ padding: '20px 50px' }">
          <div class="noOfQuestions section" fxFlex.lt-sm="100%" fxFlex.gt-sm="50%" fxLayoutAlign.lt-sm="start center" fxLayoutAlign.gt-sm="start center">
            Total Questions: {{shuffledQuizQuestions.length}}
          </div>
          <div class="criteria section" fxFlex.lt-sm="100%" fxFlex.gt-sm="50%" fxLayoutAlign.lt-sm="start center" fxLayoutAlign.gt-sm="end center">
           <div fxFlex="300px">Passing Criteria: Score >= {{quizDetails.CutOff}}%</div>
          </div>
          <div class="criteria section" fxFlex.lt-sm="100%" fxFlex.gt-sm="50%" fxLayoutAlign.lt-sm="start center" fxLayoutAlign.gt-sm="start center">
             Not Answered: {{shuffledQuizQuestions.length-numberOfAnswered}}
          </div>
          <div class="quiz-details unanswered-alert-msg section" fxFlex.lt-sm="100%" fxFlex.gt-sm="50%" fxLayoutAlign.lt-sm="start center" fxLayoutAlign.gt-sm="end center">
            <div fxFlex="300px">
              <div fxFlex="160px" fxLayoutAlign="start center"> Not Answered <div class="question-number expertise{{itemExpertise}}">Q</div></div>
                <div fxFlex="120px" fxLayoutAlign="start center">Answered<div class="question-number expertise{{itemExpertise}} answered">Q</div></div>
            </div>
            </div>
          </div> -->
        <div fxFlex.gt-sm="45px" fxLayout="row" fxLayoutAlign="start center" class="quiz-info" [ngStyle]="{background:'#f2f2f2'}">
          <div class="noOfQuestions section" [ngClass]="{'AttemptORtimer': (isTimeFramedQuiz || (allowedAttempt > 0)), 'AttemptANDtimer' : (isTimeFramedQuiz && (allowedAttempt > 0))}"
            fxLayoutAlign.lt-sm="start center" fxLayoutAlign.gt-sm="start center" [ngStyle.lt-md]="{ padding: '5px' }" [ngStyle.gt-sm]="{ 'margin-left': '3.1em'}">
            {{numberOfAnswered}} out of {{shuffledQuizQuestions.length}} question{{shuffledQuizQuestions.length>1?'s':''}} {{numberOfAnswered>1?'have':'has'}}
            been answered.
          </div>
          <div fxLayout.lt-md="column" fxLayout="row">
            <div *ngIf="allowedAttempt > 0" class="remainingAttempt" [ngClass]="{'remAttempt' : (isTimeFramedQuiz && (allowedAttempt > 0))}">
              <span>{{remainingAttempt}} attempt(s) left</span>
            </div>
            <div *ngIf="isTimeFramedQuiz" class="timer-holder">
              <div class="yorbitSprite timerIcon"></div>
              <div class="timer">{{timer}}</div>
            </div>
          </div>


        </div>
        <mat-divider fxFlex="1px"></mat-divider>
        <div fxFlex fxLayout="column" class="quiz-details questions-part" [ngStyle.lt-md]="{ padding: '20px' }" [ngStyle.gt-sm]="{ padding: '20px 50px' }"
          fxLayoutAlign="start stretch">
          <div *ngFor="let question of shuffledQuizQuestions;let questionIndex=index">

            <div fxFlex="36px" class="question-number expertise{{itemExpertise}}" [ngClass]="{'answered':question.IsAnswered}">
              {{ questionIndex + 1 }}
            </div>
            <div fxFlex fxLayout="column">
              <div class="question">{{ question.Question }}</div>
              <div class="options-holder" [ngStyle.lt-sm]="{ 'margin-left': '-42px' }" [ngClass.lt-sm]="'label-spacing'" *ngIf="question.MultipleChoice">
                <div class="option" *ngFor="
                      let option of question.Options;
                      let optionIndex = index
                    ">
                  <mat-checkbox [disabled]="submitInProgress" (change)="
                        updateAnswer($event,question.QuestionId, optionIndex,questionIndex)
                      " [(ngModel)]="option.selected">{{ option.Answer }}</mat-checkbox>

                </div>

              </div>
              <div class="options-holder" [ngStyle.lt-sm]="{ 'margin-left': '-42px' }" [ngClass.lt-sm]="'label-spacing'" *ngIf="!question.MultipleChoice">
                <mat-radio-group [disabled]="submitInProgress" fxLayout="column" fxLayoutAlign="start stretch">
                  <mat-radio-button *ngFor="
                    let option of question.Options;
                    let optionIndex = index
                  " [checked]="option.selected" class="option" (change)="
                  updateAnswer($event,question.QuestionId, optionIndex,questionIndex)
                " value="{{option.Answer}}">{{ option.Answer }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div class="submit-btn-holder" fxFlex="120px" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="false && numberOfAnswered == shuffledQuizQuestions.length"
            fxFlexAlign="center">
            <div fxFlex fxLayoutAlign.gt-md="end center" fxLayoutAlign.lt-lg="center center" *ngIf="false">
              <button *ngIf="!submitInProgress" [ngStyle]="{
            cursor:
              numberOfAnswered !== shuffledQuizQuestions.length
                ? 'not-allowed'
                : 'pointer',
            'background-color':
              numberOfAnswered != shuffledQuizQuestions.length
                ? '#E0E0E0'
                : '#5a6f79'
          }" [ngClass]="{'btn-disabled': numberOfAnswered !== shuffledQuizQuestions.length}" (click)="numberOfAnswered == shuffledQuizQuestions.length?submitConfirmation():''"
                title="{{numberOfAnswered !== shuffledQuizQuestions.length? 'Please answer all '+shuffledQuizQuestions.length+' questions to submit':''}}">
              Submit
            </button>
              <button *ngIf="submitInProgress" [disabled]="true" [ngClass]="{'btn-disabled':submitInProgress}">
              Evaluating...
            </button>
            </div>
          </div>

          <!-- <div class="submit-btn-holder"
            *ngIf="numberOfAnswered == shuffledQuizQuestions.length" [ngStyle.lt-md]="{ padding: '20px' }"
           [ngStyle.gt-sm]="{ padding: '20px 50px' }" fxFlex="80px" fxLayout="row wrap" fxLayoutAlign="center center">



             <div fxFlex fxLayoutAlign.gt-md="end center" fxLayoutAlign.lt-lg="center center">
               <button
                 [ngStyle]="{
                   cursor:
                     numberOfAnswered !== shuffledQuizQuestions.length
                       ? 'not-allowed'
                       : 'pointer',
                   'background-color':
                     numberOfAnswered != shuffledQuizQuestions.length
                       ? '#E0E0E0'
                       : '#5a6f79'
                 }"
                 [ngClass]="{'btn-disabled': numberOfAnswered !== shuffledQuizQuestions.length}"
                 (click)="numberOfAnswered == shuffledQuizQuestions.length?submitQuiz():''"
                 *ngIf="!submitInProgress"
                 title="{{numberOfAnswered !== shuffledQuizQuestions.length? 'Please answer all '+shuffledQuizQuestions.length+' questions to submit':''}}"
               >
                 Submit
               </button>
               <button
                 *ngIf="submitInProgress"
                 [disabled]="true"
                 [ngClass]="{'btn-disabled':submitInProgress}"
               >
                 Evaluating...
               </button>
             </div>
           </div>  -->
        </div>
        <div fxFlex="50px" [ngStyle]="{'padding':'10px 50px'}" fxLayout="row" *ngIf="numberOfAnswered == shuffledQuizQuestions.length"
          fxLayoutAlign.gt-sm="end center" fxLayoutAlign.lt-md="center center">
          <div class="submit-btn-holder" fxLayout="row wrap" fxLayoutAlign="center center" fxFlexAlign="end">
            <div fxFlex fxLayoutAlign.gt-sm="end center" fxLayoutAlign.lt-md="center center" class="submit-btn">
              <button *ngIf="!submitInProgress" [ngStyle]="{
                    cursor:
                      numberOfAnswered !== shuffledQuizQuestions.length
                        ? 'not-allowed'
                        : 'pointer',
                    'background-color':
                      numberOfAnswered != shuffledQuizQuestions.length
                        ? '#E0E0E0'
                        : '#5a6f79'
                  }" [ngClass]="{'btn-disabled': numberOfAnswered !== shuffledQuizQuestions.length}" (click)="numberOfAnswered == shuffledQuizQuestions.length?submitConfirmation():''"
                title="{{numberOfAnswered !== shuffledQuizQuestions.length? 'Please answer all '+shuffledQuizQuestions.length+' questions to submit':''}}">
                Submit
              </button>
              <button *ngIf="submitInProgress" [disabled]="true" [ngClass]="{'btn-disabled':submitInProgress}">
                Evaluating...
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- End of questions template -->

    <!-- Start of score template -->
    <ng-template #quizScore>
      <div class="preloaderStyle" *ngIf="!isPageLoaded" fxLayout="column" fxLayoutAlign="center center">
        <div class="loadingMsg">Please wait while we evaluate your result...</div>
        <app-preloader></app-preloader>
      </div>
      <div *ngIf="isPageLoaded" fxLayout="column" class="quizScore-parentdiv" fxFlex>
        <div *ngIf="badgeStatus" class="overlay" [ngClass.lt-sm]="'overlayMobile'">
          <!-- <img class='overlay-image' src="../../../assets/images/Quiz_Result_Overlay.png" /> -->
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" class="quiz-details score-details" fxFlex="50%" [ngStyle.gt-sm]="{ padding: '20px 50px' , height: '280px' }"
          fxLayoutAlign.lt-md="center center">
          <div class="quiz-score-badge-holder-container" fxLayout="column">
            <div class="quiz-score-badge-holder">
              <ng-template [ngTemplateOutlet]="badgeImage"></ng-template>
            </div>

          </div>
          <div class="quiz-score-div" fxLayoutAlign="end end" fxLayoutAlign.lt-md="center center" fxLayout="column">
            <div>
              <div class="quiz-score-label label-style" *ngIf="!badgeStatus && currentDisplayAttempt == allowedAttempt">
                SORRY!</div>
              <div class="quiz-score-label label-style" *ngIf="!badgeStatus && currentDisplayAttempt < allowedAttempt">
                TRY AGAIN!</div>
              <div class="quiz-score-label label-style" *ngIf="badgeStatus">CONGRATULATIONS!</div>
              <div class="quiz-score-label score-progress" [ngStyle]="{'color':badgeStatus ? 'white' : '#fd5f5f' }">
                {{ 'You have scored ' + quizResultFlags.progress + '%' }}</div>
            </div>

          </div>
        </div>
        <div fxLayout="row" class="details-row" fxLayout.lt-md="column">
          <div fxFlex="20em" fxFlex.lt-md="0em"></div>
          <div fxLayout="column" class="details-list black-text" fxLayoutAlign="start start" fxLayoutAlign.lt-md="center center">
            <div fxLayout="row" class="sub-title-field">
              <div class="details-label">Cut-Off Score</div>
              <div class="details-style">: </div>
              <div class="details-value value-field">{{quizCutOff + '%'}}</div>
            </div>
            <div fxLayout="row" class="sub-title-field" [ngStyle]="{'color':currentDisplayAttempt === allowedAttempt && !badgeStatus ? 'red' : '#394959' }">
              <div class="details-label" *ngIf="allowedAttempt > 0">Attempt(s)</div>
              <div class="details-style" *ngIf="allowedAttempt > 0">: </div>
              <div class="details-value value-field" *ngIf="allowedAttempt > 0">
                {{currentDisplayAttempt +'/'+allowedAttempt}}</div>
            </div>
            <div fxLayout="row" class="sub-title-field">
              <div class="details-label" *ngIf="isTimeFramedQuiz">Time Taken </div>
              <div class="details-style" *ngIf="isTimeFramedQuiz">: </div>
              <div class="details-value value-field" *ngIf="isTimeFramedQuiz">{{timeConsumed}}</div>
            </div>
            <div class="quiz-score-action-btn" fxLayout.gt-sm="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxLayout.lt-md="column">
              <div>
                <button (click)="viewSummary()">View Summary</button>
              </div>
              <!--  <div>
                  <button *ngIf="quizResultFlags.canRetake && quizResultFlags.progress != '100' && !badgeStatus && currentDisplayAttempt < allowedAttempt" (click)="resetData()">
                    Retake Quiz
                  </button>
              </div> -->
              <div>
                <button (click)="exitQuiz()">Exit Quiz</button>
              </div>
            </div>
            <!-- <div fxFlexOffset="10px" *ngIf="!quizResultFlags.hasPassed && quizResultFlags.canRetake">{{
                'You need to score atleast ' +
                  quizCutOff +
                  '% in the Quiz to complete the course and earn the badge. Kindly retake the Quiz.'
              }}</div>
              <div fxFlexOffset="10px" *ngIf="!quizResultFlags.hasPassed && !quizResultFlags.canRetake">{{
                'You need to score atleast ' +
                  quizCutOff +
                  '% in the Quiz to complete the course and earn the badge. Kindly watch atleast 50% of the videos to retake Quiz'
              }}</div> -->
            <!-- <div style="width: 20em; padding: 0px 50px;"></div> -->
            <!-- <div class="detailsDiv" fxLayoutAlign="center center" fxLayout="column">

              </div> -->


            <!-- <div class="quiz-score-status-msg-holder">
                <div
                  class="msg"
                  *ngIf="!quizResultFlags.hasPassed && !quizResultFlags.canRetake"
                >
                  <span *ngIf="quizCutOff < 100">{{
                    'You need to score atleast ' +
                      quizCutOff +
                      '% in the Quiz to complete the course and earn the badge'
                  }}</span>
                  <span *ngIf="quizCutOff == 100">{{
                    'You need to score ' +
                      quizCutOff +
                      '% in the Quiz to complete the course and earn the badge'
                  }}</span>
                </div>
                <div class="msg" *ngIf="quizResultFlags.canRetake && quizResultFlags.progress < '100'">
                  <span>{{ 'You may either retake quiz or exit quiz' }}</span>
                </div>
              </div> -->
          </div>
          <div class="instruction-box" *ngIf="isInstrctionsDisplyaed" fxLayout="column">
            <div class="instruction-header">INSTRUCTION:</div>
            <div class="instruction-message" fxLayoutAlign="center center">{{instructionMessage}}</div>
          </div>
        </div>

      </div>
    </ng-template>
    <!-- End of score template -->

    <!-- Start of summary template. Shows all the answered questions in a page -->
    <ng-template #quizSummary>
      <div fxLayout="column" fxFlexFill style="display: block;">
        <div fxLayout="row" class="quiz-details" style="overflow-y:initial;display:block" [ngStyle.lt-md]="{ padding: '20px 16px' }"
          [ngStyle.gt-sm]="{ padding: '20px 50px' }" *ngFor="let q of shuffledQuizQuestions; let i = index">
          <div fxFlex="10%" fxLayout="column" class="floatLeft">
            <div class="question-number expertise{{itemExpertise}} answered">
              {{ i + 1 }}
            </div>
            <div class="question-number noBorder">
              <mat-icon *ngIf="q.Status" style="color:green">done</mat-icon>
              <mat-icon *ngIf="!q.Status" style="color:red">clear</mat-icon>
            </div>
          </div>

          <div fxFlex="90%" fxLayout="column">
            <div class="question" fxFlex>{{ q.Question }}</div>
            <div fxFlex *ngIf="q.MultipleChoice" class="options-holder" style="padding-top:25px" [ngStyle.lt-sm]="{ 'margin-left': '-42px' }"
              [ngClass.lt-sm]="'label-spacing'">
              <div class="option-in-summary" *ngFor="let option of q.Options; let optionIndex = index">
                <mat-checkbox [checked]="option.selected" [disabled]="true">{{ option.Answer }}
                </mat-checkbox>
              </div>
            </div>
            <div fxFlex *ngIf="!q.MultipleChoice" class="options-holder" style="padding-top:25px" [ngStyle.lt-sm]="{ 'margin-left': '-42px' }"
              [ngClass.lt-sm]="'label-spacing'">
              <mat-radio-group [disabled]="true" fxLayout="column" fxLayoutAlign="start stretch">
                <mat-radio-button class="option-in-summary" *ngFor="let option of q.Options" [checked]="option.selected" value="{{option.Answer}}">{{ option.Answer }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- End of summary template -->

    <!-- Badge Image template -->
    <ng-template #badgeImage>
      <div class="badge-holder">
        <img [src]="badgeImgURL" title="{{badgeName}}" [ngClass]="{'grey-out': !badgeStatus}" />
        <!-- <div class="badge-name" [ngClass.gt-md]="'badge-name-large'" [ngClass.lt-lg]="'badge-name-small'"
          title="{{badgeName}}">
          {{ badgeName }}
        </div> -->
      </div>
    </ng-template>
    <!---->
  </div>
  <!-- End of resuable templates -->
</div>
<!-- End of Quiz -->
<div *ngIf="quizNA" class="quiz-comp-page">
  <div class="quiz-container" fxLayout="column" fxLayoutAlign="center center">
    <div class="no-quiz-msg">
      Please add the course to your learning path and try again.
    </div>
  </div>
</div>