<!--
  use as
    <yorbit-yammer
    [card]="{
      'contentData':contentDetails
    }"
    ></yorbit-yammer>

    for styling use as

      <div class="style">
      <yorbit-yammer [card]="{'contentData':contentDetails}"></yorbit-yammer>
    </div>
    limit parent div size to limit the size of yammer

    legends
    -> contentData set to card content details as in learningPath packagelist, detail page - package details
-->

<div id="embedded-feed" style="width: 100%; height: 96%;border: 1px solid #cccdcf; margin-top:6px"></div>
