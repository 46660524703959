<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="start center" class="popup-title"
  *ngIf="!showWarning && !continueFeedbackPopup && !showGreet">
    <div class="header-label" style="padding:0px 10px">{{ popUpHeading }}</div>
    <div class="description">
      {{ popUpDescription }}
    </div>
  </div>
  <div *ngIf="feedbackDataLoaded" class="manager-feedback-holder">
    <div
      class="closePopupBtn"
      *ngIf="(!showWarning && !continueFeedbackPopup) || showGreet"
    >
      <button class="mat-button mat-icon-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div
      class="content-holder"
      fxLayout="column"
      fxLayoutAlign="start center"
      *ngIf="
        pendingManagerFeedbackList.length > 0 && !isManagerFeedbackSubmitted
      "
    >
      <div
        class="manager-feedback-warning"
        *ngIf="showWarning && !continueFeedbackPopup"
        fxLayout="column"
      >
        <div class="warning-label">Warning</div>
        <div class="warning-message">
          The data you have given is not submitted.
        </div>
        <div class="confirmation-message">
          Are you sure you want to exit the feedback?
        </div>
        <div
          class="confirmation-btn-holder"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <button
            class="action-button"
            (click)="isManagerFeedbackSubmitted = true; closeDialog()"
          >
            Yes
          </button>
          <button class="action-button" (click)="showWarning = false">
            No
          </button>
        </div>
      </div>
      <div
        class="manager-feedback"
        *ngIf="!showWarning && !continueFeedbackPopup"
        fxLayout="column"
        fxLayoutAlign="start center"
      >
        <!-- <div class="action-icon-holder" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </div> -->
        <!-- <div class="heading">{{ popUpHeading }}</div> -->
        <div class="description" *ngIf="showErrorOnSubmit">
          <!-- {{ popUpDescription }} -->
          <div class="error">
            One or more mandatory field is empty
          </div>
        </div>

        <!-- Mind Details -->
        <div
          class="feedback-list-holder scroll-bar"
          fxLayout="column"
          fxLayoutAlign="start center"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="start start"
            class="feedback-list"
            fxFlex="none"
            *ngFor="
              let feedback of pendingManagerFeedbackList;
              let parent = index
            "
          >
            <!-- Serial number -->
            <div fxFlex="5%">
              <div class="list-index">{{ parent + 1 }}.</div>
            </div>
            <!-- Course details -->
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="95%">
              <!-- Name -->
              <div class="learner-name">{{ feedback.name }}</div>
              <!-- Course name -->
              <div class="course-name" title="{{ feedback.Coursename }}">
                {{ feedback.Coursename }}
              </div>
              <!-- Yes / No radio buttons -->
              <div
                fxLayout.lt-lg="column"
                fxLayout.gt-md="row"
                fxLayoutAlign.gt-md="start start"
                class="radio-btn-holder"
              >
                <div class="yes-no">
                  Is the associate working on the current skill?
                </div>
                <div class="yes-no-buttons" fxLayout="row">
                  <div class="yes-no-buttons-padding">
                    <input
                      type="radio"
                      name="yes-no-{{ parent }}"
                      id="yes-{{ parent }}"
                      [value]="'yes'"
                      [(ngModel)]="option_yes_no[parent]"
                      (change)="saveOptionSelected(parent)"
                      [disabled]="this.managerFeedbackProcessing"
                      [ngClass]="{ disabled: this.managerFeedbackProcessing }"
                    />
                    <label for="yes-{{ parent }}">Yes</label>
                  </div>
                  <div class="yes-no-buttons-padding">
                    <input
                      type="radio"
                      name="yes-no-{{ parent }}"
                      id="no-{{ parent }}"
                      [value]="'no'"
                      [(ngModel)]="option_yes_no[parent]"
                      (change)="saveOptionSelected(parent)"
                      [disabled]="this.managerFeedbackProcessing"
                      [ngClass]="{ disabled: this.managerFeedbackProcessing }"
                    />
                    <label for="no-{{ parent }}">No</label>
                  </div>
                </div>
              </div>

              <!-- When option no is selected -->
              <div
                class="option-reasons"
                fxLayout="column"
                *ngIf="option_yes_no[parent] == 'no'"
              >
                <div class="option-details">
                  Tell us why <span class="mandatory-indicator">*</span>
                </div>
                <div
                  fxLayout="row"
                  class="option-reason"
                  *ngFor="let option of option_no_reasons; let child = index"
                >
                  <input
                    type="radio"
                    name="options-no-{{ parent }}"
                    id="options-no-{{ child }}-{{ parent }}"
                    [value]="option"
                    [(ngModel)]="option_selected[parent]"
                    (change)="saveManagerFeedback(parent, option, feedback.Id)"
                    [disabled]="this.managerFeedbackProcessing"
                    [ngClass]="{ disabled: this.managerFeedbackProcessing }"
                  />
                  <label for="options-no-{{ child }}-{{ parent }}">{{
                    option
                  }}</label>
                </div>
              </div>
              <!-- When option yes is selected -->
              <div
                fxLayout="column"
                class="option-reasons no-padding"
                *ngIf="option_yes_no[parent] == 'yes'"
              >
                <div class="stars" fxLayout="row">
                  <div
                    *ngFor="let star of starsCollection; let child = index"
                    (mouseover)="selectedStarRatingIndex[parent] = child + 1"
                    (click)="
                      changeQuestionByRating(
                        parent,
                        selectedStarRatingIndex[parent]
                      )
                    "
                  >
                    <span style="cursor: pointer">
                      <mat-icon
                        title="Please click to give ratings"
                        style="color:#bdbcbc !important"
                        *ngIf="
                          child + 1 <= selectedStarRatingIndex[parent] == false
                        "
                        >star_border</mat-icon
                      >
                      <mat-icon
                        title="Please click to give ratings"
                        *ngIf="
                          child + 1 <= selectedStarRatingIndex[parent] == true
                        "
                        style="color:#455A64"
                        >star</mat-icon
                      >
                    </span>
                  </div>
                </div>
                <!-- Show questions based on stars -->
                <div
                  fxLayout="column"
                  class="questions-for-ratings"
                  *ngIf="
                    showQuestionsForSelectedRating[parent] &&
                    showOptions[parent]
                  "
                >
                  <div class="option-details">
                    {{ questionForSelectedRating[parent] }}
                    <span class="mandatory-indicator">*</span>
                  </div>
                  <div
                    class="option-reason"
                    *ngFor="
                      let option of (option_yes_reasons
                        | rating: optionFilter[parent]);
                      let child = index
                    "
                  >
                    <input
                      type="checkbox"
                      name="options-yes-{{ parent }}"
                      id="options-yes-{{ child }}-{{ parent }}"
                      [value]="option.label"
                      [(ngModel)]="option_yes_selected[parent][child]"
                      (change)="
                        saveSelectedReasons(
                          parent,
                          child,
                          option,
                          option_yes_selected[parent][child],
                          feedback.Id
                        )
                      "
                      [disabled]="this.managerFeedbackProcessing"
                      [ngClass]="{ disabled: this.managerFeedbackProcessing }"
                    />
                    <label for="options-yes-{{ child }}-{{ parent }}">{{
                      option.label
                    }}</label>
                  </div>
                  <div fxLayout="column" class="text-box-holder">
                    <textarea
                      rows="3"
                      class="optional-comments"
                      placeholder="Optional comments"
                      [(ngModel)]="managerFeedbackComments[parent]"
                      (mouseleave)="saveComments(parent)"
                      [disabled]="this.managerFeedbackProcessing"
                      [ngClass]="{ disabled: this.managerFeedbackProcessing }"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div
                fxLayout="column"
                class="reason-chosen"
                *ngIf="!showOptions[parent]"
              >
                <div
                  class="reason-for-no"
                  *ngIf="
                    option_yes_no[parent] == 'no' &&
                    feedback_option_selected[parent]
                      .FeedbackDetailquestionAnswers.length > 0
                  "
                >
                  <span class="label-text">CAN'T RATE</span> -
                  {{ option_selected[parent] }}
                </div>
                <div
                  class="reason-for-yes"
                  *ngIf="
                    option_yes_no[parent] == 'yes' &&
                    managerFeedbackComments[parent] != null
                  "
                >
                  <span class="label-text">Comments</span> -
                  {{ managerFeedbackComments[parent] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit button -->
        <div class="submit-feedback">
          <button
            class="action-button"
            (click)="validateManagerFeedback()"
            *ngIf="!managerFeedbackProcessing"
            [disabled]="showErrorOnSubmit || isPristine()"
            [ngClass]="{ disabled: showErrorOnSubmit || isPristine() }"
            title="{{
              isPristine()
                ? 'Please give at least one feedback to enable Submit'
                : showErrorOnSubmit
                ? 'One or more mandatory field is empty'
                : ''
            }}"
          >
            Submit
          </button>
          <button
            *ngIf="managerFeedbackProcessing"
            [disabled]="true"
            class="action-button disabled"
          >
            Processing...
          </button>
        </div>
      </div>
      <div
        class="manager-feedback-warning"
        *ngIf="!showWarning && continueFeedbackPopup"
        fxLayout="column"
      >
        <div class="warning-label">Manager's Feedback</div>
        <div class="warning-message">There are few feedback(s) pending</div>
        <div class="confirmation-message">
          Do you want to continue giving feedback(s)?
        </div>
        <div
          class="confirmation-btn-holder"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <button
            class="action-button"
            (click)="showWarning = false; continueGivingFeedback()"
          >
            Yes
          </button>
          <button
            class="action-button"
            (click)="isManagerFeedbackSubmitted = true; closeDialog()"
          >
            No
          </button>
        </div>
      </div>
    </div>
    <div
      class="content-holder"
      fxLayout="column"
      fxLayoutAlign="start center"
      *ngIf="showGreet"
    >
      <div
        class="manager-feedback-warning"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <!-- <div class="action-icon-holder" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </div> -->
        <div class="greet-msg">Thank you for sharing feedback.</div>
        <div class="yorbitSprite iconSmileyHappy"></div>
      </div>
    </div>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="!feedbackDataLoaded"
    class="loader"
  >
    <app-preloader></app-preloader>
  </div>
</mat-dialog-content>
