<div fxLayout="row wrap" id="wfh-recommendations-holder" class="wfh-recommendations-holder" fxLayout="column" fxLayoutAlign="center center">
  <div fxFlex="100%" class="wfh-courses-title" *ngIf="true">
    Make the best use of your time
  </div>
  <div fxFlex="100%" class="wfh-courses-sub-title">
    Check out these recommended courses that can help in improving your productivity while you are working from home!
  </div>
  <div fxFlex="100%" class="scroll-box" fxLayout="column" fxLayoutAlign="center center" [ngStyle.xs]="{'width':'315px !important'}">
    <div fxLayout="row wrap" id="wfh-courses-container-gt-xs" class="wfh-courses-container" fxLayoutAlign="center center" fxHide fxShow.gt-xs="true">
      <ng-template [ngTemplateOutlet]="coursestemp"></ng-template>
    </div>   
    <div *ngIf="!showCoursesMobile && (wfhcourses.length < 10)" fxHide fxShow.gt-xs="true" class="mousePointer seeMore" (click)="showMore(false)">Show More</div>
    <div *ngIf="!showCoursesMobile && (wfhcourses.length > 9)" fxHide fxShow.gt-xs="true"  class="mousePointer seeLess" (click)="showLess(false,9)">Show Less</div>
    
    <div fxLayout="column" fxHide fxShow.xs="true" fxLayoutAlign="start center" class="mobile" id="wfh-courses-container-xs">      
      <ng-template [ngTemplateOutlet]="coursestempMobile" ></ng-template>
    </div>
    <div *ngIf="showCoursesMobile && wfhCoursesMobile.length < 4" fxHide fxShow.xs="true" class="mousePointer seeMore" (click)="showMore(true)">Show More</div>
    <div *ngIf="showCoursesMobile && wfhCoursesMobile.length > 3" fxHide fxShow.xs="true" class="mousePointer seeLess" (click)="showLess(true,3)">Show Less</div>
  </div>
  
</div>
<ng-template #coursestemp>
  <div fxLayout="column" fxFlex="315px" *ngFor="let course of wfhcourses" class="wfh-course-tile mousePointer"
    (click)="redirectToCourseSite(course)" title="Click to View">
    <div class="content" fxLayout="row" fxLayouAlign="center center">
      <div class="logo" fxFlex="20">
        <div class="yorbitSprite" 
        [ngClass]="{
          'logo-pluralright': (course.Vendor == 'Pluralsight'),
          'logo-linkedin' : (course.Vendor == 'LinkedIn'),
          'logo-yorbit' : (course.Vendor == 'Yorbit'),
          'bg-101' : (course.Vendor == 'Yorbit' && course.exp == '101'),
          'bg-201' : (course.Vendor == 'Yorbit' && course.exp == '201')
        }"></div>
        <div class="logo-text" *ngIf="(course.Vendor == 'Yorbit')">
            {{course.exp}}
        </div>
      </div>
      <div class="course-name" fxFlex="75">
        {{course.Course}}
      </div>
      <div class="yorbitSprite arrow" fxFlex="10"></div>
    </div>
    <div class="footer" [ngClass]="{
      'bg-pluralsight': (course.Vendor == 'Pluralsight'),
      'bg-linkedin' : (course.Vendor == 'LinkedIn'),
      'bg-101' : (course.Vendor == 'Yorbit' && course.exp == '101'),
      'bg-201' : (course.Vendor == 'Yorbit' && course.exp == '201')
    }">      
    </div>
  </div> 
</ng-template>

<ng-template #coursestempMobile>
    <div fxLayout="column" fxFlex="85px" *ngFor="let course of wfhCoursesMobile" class="wfh-course-tile mousePointer"
      (click)="redirectToCourseSite(course)" title="Click to View" style="width:100% !important">
      <div class="content" fxLayout="row" fxLayouAlign="center center">
        <div class="logo" fxFlex="20">
          <div class="yorbitSprite" 
          [ngClass]="{
            'logo-pluralright': (course.Vendor == 'Pluralsight'),
            'logo-linkedin' : (course.Vendor == 'LinkedIn'),
            'logo-yorbit' : (course.Vendor == 'Yorbit'),
            'bg-101' : (course.Vendor == 'Yorbit' && course.exp == '101'),
            'bg-201' : (course.Vendor == 'Yorbit' && course.exp == '201')
          }"></div>
          <div class="logo-text" *ngIf="(course.Vendor == 'Yorbit')">
              {{course.exp}}
          </div>
        </div>
        <div class="course-name" fxFlex="75">
          {{course.Course}}
        </div>
        <div class="yorbitSprite arrow" fxFlex="10"></div>
      </div>
      <div class="footer" [ngClass]="{
        'bg-pluralsight': (course.Vendor == 'Pluralsight'),
        'bg-linkedin' : (course.Vendor == 'LinkedIn'),
        'bg-101' : (course.Vendor == 'Yorbit' && course.exp == '101'),
        'bg-201' : (course.Vendor == 'Yorbit' && course.exp == '201')
      }">      
      </div>
    </div> 
</ng-template>