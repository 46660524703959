<div *ngIf="!showPreloader" class="lp-detailed-container" fxFlexFill fxLayout="column" [ngClass.xs]="{'lp-detailed-container-xs':true,'skillingpath-detailed-container':LPBreadcrumbs.LPDetails.LPCategory=='skilling' || LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'}"
  [ngClass]="{'skillingpath-detailed-container':LPBreadcrumbs.LPDetails.LPCategory=='skilling','business-ethics-detailed-container': LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'}">
  <div class="backdrop" (click)="removeHighlightStyle('null')" [ngClass.gt-xs]="{'complianceAndSkillingLp':(LPBreadcrumbs.LPDetails.LPCategory=='skilling' || LPBreadcrumbs.LPDetails.LPCategory=='myCompliance')}" fxFlexFill *ngIf="highlightedContentId!=''"></div>
  <div class="header" fxFlex="48px" fxLayout="row" fxShow.xs="true" [fxShow.gt-xs]="LPBreadcrumbs.LPDetails.LPCategory=='skilling' || LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'"
    fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
    <div class="title"
    fxFlex fxFlexOffset="22px" fxShow.xs="false" [fxShow.gt-xs]="!showSelectedCourseFromTargetSkill && (LPBreadcrumbs.LPDetails.LPCategory=='skilling' || LPBreadcrumbs.LPDetails.LPCategory=='myCompliance')"
    [ngClass]="{'camelCase':(LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'||LPBreadcrumbs.LPDetails.LPCategory=='mandatory')}">{{(LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'||LPBreadcrumbs.LPDetails.LPCategory=='mandatory')?(LPBreadcrumbs.LPDetails.LPName|lowercase):LPBreadcrumbs.LPDetails.LPName}}</div>

    <div fxFlex fxFlexOffset="22px" [fxShow.xs]="!showSelectedCourseFromTargetSkill" fxShow.gt-xs="false" fxLayoutAlign="start center">
      <div class="title-holder-xs">Learning Path :

        <span class="title" fxFlexOffset="5px" [ngClass]="{'camelCase':(LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'||LPBreadcrumbs.LPDetails.LPCategory=='mandatory')}">{{(LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'||LPBreadcrumbs.LPDetails.LPCategory=='mandatory')?(LPBreadcrumbs.LPDetails.LPName|lowercase):LPBreadcrumbs.LPDetails.LPName}}</span>
      </div>
    </div>
    <div fxFlexOffset="20px" style="line-height:1" fxFlex *ngIf="showSelectedCourseFromTargetSkill" fxLayoutAlign="start center">
      <div class="mousePointer" (click)="showSelectedCourseFromTargetSkill=false;showTargetSkill=true">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </div>
      <div fxFlexOffset="10px" style="min-height:19px; max-height:34px;overflow:hidden">{{selectedCourseFromTargetSkill.ItemName}}</div>
    </div>
    <div fxFlex="50px" fxShow.xs="false" class="Skill-Path-Watermark" fxFlexAlign="start" fxShow.gt-xs="true"
    *ngIf="LPBreadcrumbs.LPDetails.LPCategory=='skilling'">
      S
    </div>
  </div>







  <div class="body darkThemeScrollBar" fxFlex.xs fxLayout.xs="column" fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign.xs="start stretch"
    fxFlex.gt-xs>
    <div class="skilling-path-info" [fxShow.xs]="true && LPBreadcrumbs.LPDetails.LPCategory=='skilling' && showTargetSkill" fxShow.gt-xs="false">To access this course you will need to complete the other courses in the skill plan or declare skill and have it validated.</div>
    <div class="skill-path-target-course-container" *ngIf="LPBreadcrumbs.LPDetails.LPCategory=='skilling'">
      <div class="skill-path-target-course"
        [class.mat-elevation-z8]="targetSkill.ItemType+targetSkill.ItemId==highlightedContentId"
        [ngClass]="{'highlight-contenttile':targetSkill.ItemType+targetSkill.ItemId==highlightedContentId}" [id]="targetSkill.ItemType+targetSkill.ItemId"
        fxFlex="224px" *ngIf="showTargetSkill" fxLayout="column">
        <yorbit-content-tile-lp [isTargetCourse]="true" [card-details]="targetSkill" (click)="navigateTo(targetSkill);removeHighlightStyle(null)"
        (removeHighlightStyle)="removeHighlightStyle($event)"
          (showCourseFromTargetSkill)="showCourseFromTargetSkill($event)" fxFlexFill></yorbit-content-tile-lp>
      </div>
    </div>
    <div class="skill-path-target-course-container" *ngIf="LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'">
        <div class="skill-path-target-course"
        fxFlex="224px" *ngIf="showTargetSkill" fxLayout="column">
          <yorbit-content-tile-lp [isTargetCourse]="true" [card-details]="targetSkill"
          (showCourseFromTargetSkill)="showCourseFromTargetSkill($event);removeHighlightStyle(null)" (click)="removeHighlightStyle(null)" fxFlexFill></yorbit-content-tile-lp>
        </div>
        <div fxShow.gt-xs="false" fxShow.xs="true" class="floating-icons-container-xs">
            <ng-template [ngTemplateOutlet]="floatingIcons"></ng-template>
        </div>
    </div>
    <div class="content-tile-holder-skill-paths-xs"
    [class.mat-elevation-z8]="selectedCourseFromTargetSkill.ItemType+selectedCourseFromTargetSkill.ItemId==highlightedContentId"
    [ngClass]="{'highlight-contenttile':selectedCourseFromTargetSkill.ItemType+selectedCourseFromTargetSkill.ItemId==highlightedContentId}" [id]="selectedCourseFromTargetSkill.ItemType+selectedCourseFromTargetSkill.ItemId"
    *ngIf="showSelectedCourseFromTargetSkill && (LPBreadcrumbs.LPDetails.LPCategory=='skilling' || LPBreadcrumbs.LPDetails.LPCategory=='myCompliance')"
      fxFlex="300px" fxLayout="row">
      <div fxFlex="228px">
        <yorbit-content-tile-lp fxFlex [card-details]='this.selectedCourseFromTargetSkill' (click)="navigateTo(this.selectedCourseFromTargetSkill);removeHighlightStyle(null)"
        (removeHighlightStyle)="removeHighlightStyle($event)"
        (updateDeclareForSkilling)="updateDeclareForSkilling()"
          fxFlexFill></yorbit-content-tile-lp>
      </div>
    </div>
    <!-- <div class="noPackageMessage" fxFlex fxLayoutAlign="space-around center" *ngIf="0===packageList.length">  'Explore' for Courses on Yorbit and Add To This Learning Path to Start Your Learning Journey!</div> -->

    <div fxFlex id="scrollable-content" class="lp-content-tiles" [fxShow.xs]="LPBreadcrumbs.LPDetails.LPCategory!='skilling'&& packageList.length != 0 && LPBreadcrumbs.LPDetails.LPCategory!='myCompliance'&& LPBreadcrumbs.LPDetails.LPCategory!='myCompliance'"
      [fxHide.xs]="LPBreadcrumbs.LPDetails.LPCategory=='skilling'||packageList.length == 0 || LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'" [fxShow.gt-xs]="packageList.length!=0"
      fxLayout="row wrap" fxLayoutAlign="start start">
      <div class="content-tile-holder"
      [class.mat-elevation-z8]="item.ItemType+item.ItemId==highlightedContentId"
      [ngClass]="{'highlight-contenttile':item.ItemType+item.ItemId==highlightedContentId}" [id]="item.ItemType+item.ItemId"
       *ngFor="let item of (LPBreadcrumbs.LPDetails.LPCategory=='skilling'?(packageList|slice:0:packageList.length-1):packageList)"
        fxFlex="224px" fxLayout="column">

        <div fxFlex="300px"  *ngIf="LPBreadcrumbs.LPDetails.LPId.toUpperCase()=='ROLE BASED LEARNING'">
          <yorbit-rbl-package *ngIf="'RoleBasedPackage'===item.ItemType"
          (click)="navigateTo(item);removeHighlightStyle(null)"
          (removeHighlightStyle)="removeHighlightStyle($event)"
          [contentData]="item" [contentLocation]="'LP'"
          ></yorbit-rbl-package>
        </div>


        <div fxFlex="300px" *ngIf="LPBreadcrumbs.LPDetails.LPId.toUpperCase()!='ROLE BASED LEARNING'">
          <yorbit-content-tile-lp
          *ngIf="!('Package'===item.ItemType ||'FamilyPackage'===item.ItemType || 'Assessment'===item.ItemType)"
          [card-details]='item'
          (click)="navigateTo(item);removeHighlightStyle(null)"
          (removeHighlightStyle)="removeHighlightStyle($event)"
          (updateDeclareForSkilling)="updateDeclareForSkilling()"
          fxLayoutAlign="start start" fxFlexFill></yorbit-content-tile-lp>
          <yorbit-package-tile
          *ngIf="('Package'===item.ItemType||'FamilyPackage'===item.ItemType) && 'Assessment'!==item.ItemType"
          [cardDetails]='item' [featuredIn]="'learningPath'"
          (removeHighlightStyle)="removeHighlightStyle($event)"
          (click)="navigateTo(item);removeHighlightStyle(null)"
          fxLayoutAlign="start start" fxFlexFill></yorbit-package-tile>

          <yorbit-assessment-tile
          *ngIf="('Package'!==item.ItemType||'FamilyPackage'!==item.ItemType) && 'Assessment'===item.ItemType"
          [cardDetails]='item' 
          (removeHighlightStyle)="removeHighlightStyle($event)"
          (click)="removeHighlightStyle(null)"
          fxLayoutAlign="start start" fxFlexFill></yorbit-assessment-tile>

        </div>
      </div>
    </div>
    <div fxFlexFill *ngIf="packageList.length == 0" fxLayout="row wrap" fxLayoutAlign="center center">
      <div style="text-align:center" *ngIf="!showNoSearchResultsInfo">
        <b>‘Explore’</b> Courses And <b>‘Add’</b> To This <b>‘Learning Path’</b> To Begin Your Learning Journey On Shoshin School!
      </div>
      <div *ngIf="showNoSearchResultsInfo">
        No course/package(s) available with <b>{{searchBy}}</b> in this learning path.
      </div>
    </div>
   <div fxShow.gt-xs="true" fxShow.xs="false" class="floating-icons-container">
     <ng-template [ngTemplateOutlet]="floatingIcons"></ng-template>
   </div>
  </div>
</div>
<div fxFlexFill *ngIf="showPreloader" fxLayoutAlign="center center">
      <app-preloader></app-preloader>
</div>
<ng-template #floatingIcons>
    <div class="floating-action-bar" fxLayout="column"  *ngIf="LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'">
        <div class="yorbitSprite floatingActionBar1 positionRelative">
          <!-- <a href="https://isupport.ltimindtree.com/knowp" target="_blank">
            <div class="yorbitSprite floatingHelpIcon floatingHelpPos" title="iSupport"></div>
          </a> -->
          <div class="yorbitSprite floatingFAQIcon floatingFAQPos1" title="FAQ" (click)="goToFAQ()"></div>
          <div class="yorbitSprite floatingFeedbackIcon floatingFeedbackPos" title="Feedback" (click)="goToFeedback()"></div>
        </div>

        <div class="iSupport" 
        (click)="openISuppport()"
        title="iSupport"
        [ngClass.gt-xs]="['gt-xs']"
        [ngClass.xs]="['xs']">
          <img class="floating-timeBoundLearning-icon1"
           src="./assets/images/isupport.svg" >
      </div> 

      </div>
</ng-template>
