import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as MyPageActions from '../actions/my-page.action';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MyPageService } from '../../my-page.service';
import {
  ICrossSkillList,
  IFutureSkillPaths
} from '../my-page-details.interface';
import { Observable, forkJoin } from 'rxjs';
import { getSkillPathsSelector } from '../../../shared/user-details/store';

@Injectable()
export class MyPageEffects {
  constructor(
    private actions$: Actions,
    private myPageService: MyPageService
  ) { }

  getMyPageCompliance$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_COMPLIANCE),
    switchMap((action: MyPageActions.GetMyPageCompliance) => {
      return this.myPageService
        .getLearnerComplianceStatus(action.payload)
        .pipe(
          map(compliance => {
            return new MyPageActions.GetMyPageComplianceSuccess(compliance);
          }),
          catchError(error =>
            of(new MyPageActions.GetMyPageComplianceFail(error))
          )
        );
    })
  ));


  getMyPageHistory$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_LEARNING_HISTORY),
    switchMap((action: MyPageActions.GetMyPageLearningHistory) => {
      return this.myPageService.getBadgesListOfLearner(action.payload, 'self').pipe(
        map(badges => {
          return new MyPageActions.GetMyPageLearningHistorySuccess(badges);
        }),
        catchError(error =>
          of(new MyPageActions.GetMyPageLearningHistoryFail(error))
        )
      );
    })
  ));


  getMyPageSkillProfile$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_SKILL_PROFILE),
    switchMap((action: MyPageActions.GetMyPageSkillProfile) => {
      return this.myPageService
        .getDeclaredAndAquiredSkill(action.payload, 'self')
        .pipe(
          map(skills => {
            return new MyPageActions.GetMyPageSkillProfileSuccess(skills);
          }),
          catchError(error =>
            of(new MyPageActions.GetMyPageSkillProfileFail(error))
          )
        );
    })
  ));


  getMyPageCoreSkills$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_CORE_SKILLS),
    switchMap((action: MyPageActions.GetMyPageCoreSkills) => {
      return this.myPageService.getCoreSkills(action.payload).pipe(
        map(coreSkills => {
          return new MyPageActions.GetMyPageCoreSkillsSuccess(coreSkills);
        }),
        catchError(error =>
          of(new MyPageActions.GetMyPageCoreSkillsFail(error))
        )
      );
    })
  ));


  getMyPageSupportSkills$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_SUPPORT_SKILLS),
    switchMap((action: MyPageActions.GetMyPageSupportSkills) => {
      return this.myPageService.getSupportSkills(action.payload).pipe(
        map(supportSkills => {
          if (
            supportSkills ===
            'No any Recommended SupportSkills for your PrimaryL4'
          ) {
            return new MyPageActions.GetMyPageSupportSkillsSuccess([]);
          } else {
            return new MyPageActions.GetMyPageSupportSkillsSuccess(
              supportSkills
            );
          }
        }),
        catchError(error =>
          of(new MyPageActions.GetMyPageSupportSkillsFail(error))
        )
      );
    })
  ));


  getMyPageCrossSkills$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_CROSS_SKILLS),
    switchMap((action: MyPageActions.GetMyPageCrossSkills) => {
      return this.myPageService.getCrossSkillsNames(action.payload).pipe(
        map(crossSkills => {
          if (crossSkills.length > 0) {
            let crossSkillList: Array<ICrossSkillList> = [];
            for (let i = 0; i < crossSkills.length; i++) {
              crossSkillList.push({
                CrossSkillName: crossSkills[i],
                CrossSkillPath: {},
                IsLoading: true
              });
            }
            return new MyPageActions.GetMyPageCrossSkillsSuccess({
              pl4: action.payload,
              crossSkills: crossSkillList
            });
          } else {
            return new MyPageActions.GetMyPageCrossSkillPathSuccess([]);
          }
        }),
        catchError(error =>
          of(new MyPageActions.GetMyPageCrossSkillsFail(error))
        )
      );
    })
  ));


  getMyPageCrossSkillsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_CROSS_SKILLS_SUCCESS),
    switchMap((action: MyPageActions.GetMyPageCrossSkillsSuccess) => {
      return [new MyPageActions.GetMyPageCrossSkillPath(action.payload)];
    })
  ));


  getMyPageCrossSkillPath$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_CROSS_SKILL_PATH),
    switchMap((action: MyPageActions.GetMyPageCrossSkillPath) => {
      const pl4 = action.payload.pl4;
      const batchOfRequests = action.payload.crossSkills.map(row =>
        this.myPageService.getCrossSkillsList(pl4, row.CrossSkillName)
      );
      return new Promise((resolve, reject) => {
        forkJoin(...batchOfRequests).subscribe(responseArray => {
          responseArray.forEach((data, index) => {
            action.payload.crossSkills[index].CrossSkillPath = data;
            action.payload.crossSkills[index].IsLoading = false;
          });
          resolve(action.payload.crossSkills);
        });
      }).then(data => {
        return new MyPageActions.GetMyPageCrossSkillPathSuccess(data);
      });
    })
  ));


  getMyPagePrimarySkill$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_PRIMARY_SKILL),
    switchMap((action: MyPageActions.GetMyPagePrimarySkill) => {
      return this.myPageService.getUserPrimarySkill(action.payload, 'self').pipe(
        map(primarySkill => {
          return new MyPageActions.GetMyPagePrimarySkillSuccess(primarySkill);
        }),
        catchError(error =>
          of(new MyPageActions.GetMyPagePrimarySkillFail(error))
        )
      );
    })
  ));


  getMyPageRecommendationSkill$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_PRIMARY_SKILL_SUCCESS),
    switchMap((action: MyPageActions.GetMyPagePrimarySkillSuccess) => {
      const pl4 = action.payload.PrimaryL4;
      return [
        new MyPageActions.GetMyPageCoreSkills(pl4),
        new MyPageActions.GetMyPageSupportSkills(pl4),
        new MyPageActions.GetMyPageCrossSkills(pl4),
        new MyPageActions.GetMyPageFutureSkills(pl4)
      ];
    })
  ));


  getMyPageFutureSkills$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_FUTURE_SKILLS),
    switchMap((action: MyPageActions.GetMyPageFutureSkills) => {
      return this.myPageService.getFutureSkills(action.payload).pipe(
        map(futureSkills => {
          if (futureSkills.length > 0) {
            let futureSkillList: Array<IFutureSkillPaths> = [];
            for (let i = 0; i < futureSkills.length; i++) {
              futureSkillList.push({
                FutureSkillName: futureSkills[i],
                FutureSkillPaths: [],
                IsLoading: true
              });
            }
            return new MyPageActions.GetMyPageFutureSkillsSuccess({
              pl4: action.payload,
              futureSkills: futureSkillList
            });
          } else {
            return new MyPageActions.GetMyPageFutureSkillPathSuccess({
              pl4: action.payload,
              futureSkills: []
            });
          }
        }),
        catchError(error =>
          of(new MyPageActions.GetMyPageFutureSkillsFail(error))
        )
      );
    })
  ));


  getMyPageFutureSkillsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_FUTURE_SKILLS_SUCCESS),
    switchMap((action: MyPageActions.GetMyPageFutureSkillsSuccess) => {
      return [new MyPageActions.GetMyPageFutureSkillPath(action.payload)];
    })
  ));


  getMyPageFutureSkillPath$ = createEffect(() => this.actions$.pipe(
    ofType(MyPageActions.GET_MY_PAGE_FUTURE_SKILL_PATH),
    switchMap((action: MyPageActions.GetMyPageFutureSkillPath) => {
      const pl4 = action.payload.pl4;
      const batchOfRequests = action.payload.futureSkills.map(row =>
        this.myPageService.getFutureSkillPath(row.FutureSkillName, pl4)
      );
      return new Promise((resolve, reject) => {
        forkJoin(...batchOfRequests).subscribe((res: any[]) => {
          if (res.length > 0) {
            res.forEach((data, index) => {
              action.payload.futureSkills[index].IsLoading = false;
              if (data.length > 0) {
                let count = 0;
                data.forEach((paths, childIndex) => {
                  if (
                    paths.IsTargetSkillAlreadyAdded === false &&
                    paths.TargetSkill.CourseId != null &&
                    paths.TargetSkill.CourseId !== ''
                  ) {
                    action.payload.futureSkills[index].FutureSkillPaths.push(
                      paths
                    );
                  } else if (paths.IsTargetSkillAlreadyAdded === true) {
                    count++;
                  }
                });
                if (count === data.length) {
                  action.payload.futureSkills[index].IsAdded = true;
                } else {
                  action.payload.futureSkills[index].IsAdded = false;
                }
              }
            });
          }
          resolve(action.payload);
        });
      }).then(data => {
        return new MyPageActions.GetMyPageFutureSkillPathSuccess(data);
      });
    })
  ));
}
