import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ValidateNumber(control: AbstractControl) {
    const regEx = new RegExp(/^[0-9]{1,3}$/);
    if (regEx.test(control.value)) {
        return null;
    } else {
        if (control.value == "") {
            return {
                isInvalid: true,
                errorMessage: "Field cannot be empty"
            }
        } else {
            return {
                isInvalid: true,
                errorMessage: "Please enter numbers only"
            };
        }
    }
}

export function ValidateMinsSeconds(control: AbstractControl) {
    const regEx = new RegExp(/^[0-9]*$/);
    if (regEx.test(control.value)) {
        if (control.value >= 0 && control.value <= 59) {
            return null;
        } else {
            return {
                isInvalid: true,
                errorMessage: "Minutes cannot be more than 59"
            };
        }
    } else {
        if (control.value == "") {
            return {
                isInvalid: true,
                errorMessage: "Field cannot be empty"
            }
        }
        return {
            isInvalid: true,
            errorMessage: "Please enter numbers only"
        }
    }
}

export function ValidateCutOffMarks(control: AbstractControl) {
    const regEx = new RegExp(/^[0-9]*$/);
    if (regEx.test(control.value)) {
        if (control.value > 0 && control.value <= 100)
            return null;
        else if (control.value == "")
            return {
                isInvalid: true,
                errorMessage: "Field cannot be empty"
            }
        else {
            return {
                isInvalid: true,
                errorMessage: "CutOff marks should be between 1 and 100"
            }
        }
    }
    else {
        return {
            isInvalid: true,
            errorMessage: "Please enter numbers only"
        };
    }

}
export function ValidateMinLength101(minLength: number) {
    return (control: AbstractControl) => {
        if (control.value != null) {
            if (control.value.toString().length >= minLength)
                return {
                    isInvalid: false,
                    errMsg: ''
                };
            else
                return {
                    isInvalid: true,
                    errMsg: 'Please enter at least' + minLength + 'characters.'
                };
        }
    };
}
export function ValidateExpert101(control: AbstractControl) {
    // const regEx = new RegExp(/^(([Mm]{1}[0-9]{7}\,{0,1}\s{0,})+)$/, 'g');
    // if (control.value) {
    //     //const isValid = regEx.test(control.value);
    //     const isValid = true;
    //     if (isValid === true) {
    //         return {
    //             isInvalid: false,
    //             errMsg: ''
    //         };
    //     }
    //     else {
    //         return {
    //             isInvalid: true,
    //             errMsg: "Please enter the MIDs in format 'Mxxxxxxx'"
    //         };
    //     }
    // }
    if (control.value.trim() == "" && control.value != "") {
        const isValid = true;
        if (isValid === true) {
            return {
                isInvalid: false,
                errMsg: ''
            };
        }
        else {
            return {
                isInvalid: true,
                errMsg: "Please enter the MIDs in format 'Mxxxxxxx'"
            };
        }
    }

    /*Split each entry and check for the format MXXXXXXX
    First Letter : M or m
    XXXXXXX : 7-digit Numbers only
    */
    var mids = control.value.split(',');
    // console.log(mids)
    for (let mid of mids) {
        mid = mid.trim();
        //split() sometimes gives empty entires if no entries are present after delimiter.
        if (mid == '') {
            continue;
        }

        if ((mid.length > 8 || mid.length < 5 || mid[0].toLowerCase() == "m" || String(Number(mid.substring(1, 8))) == "NaN") && mid != "") {
            const isValid = true;
            if (isValid === true) {
                return {
                    isInvalid: true,
                    errMsg: "Please enter the MIDs in format 'Mxxxxxxx'"
                };
            }
            else
                return {
                    isInvalid: false,
                    errMsg: ''
                };
        }
        //Checking for duplicate MID entries
        if (mids.filter(x => x.trim() == mid).length > 1) {
            const isValid = true;
            if (isValid === true) {
                return {
                    isInvalid: true,
                    errMsg: 'PSID already exists'
                };
            }
            else {
                return {
                    isInvalid: false,
                    errMsg: ''
                };
            }
        }

    }
}
export function ValidateDuration101(control: AbstractControl) {
    const regEx = new RegExp(/^[0-9]{1,3}.[0-5]{1}[0-9]{1}.[0-5]{1}[0-9]{1}$/);
    let minutes = control.value.split('.')[1];
    let seconds = control.value.split('.')[2];

    if (regEx.test(control.value) && minutes != undefined && seconds != undefined) {
        return {
            fieldIsInvalid: false,
            errMsg: ''
        };
    } else {
        return {
            fieldIsInvalid: true,
            errMsg: "Please enter numbers in the format of HHH.MM.SS or HH.MM.SS."
        };
    }
}

export function ValidateWhitespace(control: AbstractControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    if (isValid) {
        return null;
    } else {
        return {
            isInvalid: true,
            errorMessage: "*Field cannot contain empty space"
        };
    }
}
export function ValidateContentOwner101(control: AbstractControl) {
    const regEx = new RegExp(/^(([Mm]{1}[0-9]{7}\s{0})+)$/, 'g');
    if (control.value) {
        //const isValid = regEx.test(control.value);
        const isValid = true;
        if (isValid === true) {
            return {
                isInvalid: false,
                errMsg: ''
            };
        }
        else {
            return {
                isInvalid: true,
                errMsg: "Please enter the MIDs in format 'Mxxxxxxx'"
            };
        }
    }
}
