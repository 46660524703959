import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  ICompliance,
  IBadge,
  IUserSkills,
  ICoreSkills,
  ISkill,
  IPrimarySkill
} from './store/my-page-details.interface';
import { BadgeDetails } from './my-page.interface';

@Injectable({
  providedIn: 'root'
})
export class MyPageService {
  _baseURL: string;
  myCDMandRMs = null;
  graphData: any = {
    displayName: '',
    jobTitle: '',
    MID: ''
  };
  constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
  }
  getCDMandRM(Mid) {
    if (this.myCDMandRMs == null) {
      const url = this._baseURL + 'User/CDMandRM';
      return this._http
        .post(url, { "Id": Mid })
        .toPromise()
        .then(res => {
          this.myCDMandRMs = res;
          return this.myCDMandRMs;
        });
    } else {
      let promise = new Promise((res, rej) => {
        res(this.myCDMandRMs);
      });
      return promise;
    }
  }
  getBadgesListOfLearner(userId, userType) {
    const url = this._baseURL + 'Users/Badges/' + userType;
    return this._http.post<IBadge[]>(url, {
      "Id": userId
    });
  }

  getFilteredBadgesListOfLearner(filter) {
    let payoad = {
      Expertise: filter.selectedExpertise, //'All' , '101', '201', '301
      Start_Date: filter.selectedStartDate,
      End_Date: filter.selectedEndDate
    };
    // const url = this._baseURL + '' ;
    // return this._http.post<any>(url)
  }

  getBadgeDetails(courseId, userId) {
    let payload = {
      "courseId": courseId,
      "userId": userId
    }
    const url =
      this._baseURL + 'Users/Badge/Details';
    return this._http.post<BadgeDetails>(url, payload);
  }

  getLearnerComplianceStatus(userId) {
    const url = this._baseURL + 'Users/ComplianceStatus';
    return this._http.post(url, { "Id": userId });
  }

  sendReport(payload) {
    const url = this._baseURL + 'Users/MailOnLearningComplianceIssue';
    return this._http.post(url, payload);
  }

  getUserPrimarySkill(userId, userType) {
    const url = this._baseURL + 'User/PrimarySkills/' + userType;
    return this._http.post<IPrimarySkill>(url, {
      "Id": userId
    });
  }

  getDeclaredAndAquiredSkill(MId, userType) {
    const url = this._baseURL + 'User/Skills/' + userType;
    return this._http.post<IUserSkills>(url, {
      "Id": MId
    });
  }

  getCoreSkills(primaryL4) {
    // const pl4 = encodeURIComponent(primaryL4);
    // const url =
    //   this._baseURL +
    //   'SkillRecommendations/GetCoreSkills' +
    //   '?primaryL4=' +
    //   pl4;
    // return this._http.get<ICoreSkills>(url);
    return of(null);
  }

  getSupportSkills(primaryL4) {
    // const pl4 = encodeURIComponent(primaryL4);
    // const url =
    //   this._baseURL +
    //   'SkillRecommendations/GetSupportSkills' +
    //   '?primaryL4=' +
    //   pl4;
    // return this._http.get<any>(url);
    return of(null);
  }

  getCrossSkills(primaryL4) {
    const pl4 = encodeURIComponent(primaryL4);
    const url =
      this._baseURL +
      'SkillRecommendations/GetCrossSkills' +
      '?primaryL4=' +
      pl4;
    return this._http.get(url);
  }

  getFutureSkills(primaryL4) {
    // const pl4 = encodeURIComponent(primaryL4);
    // const url =
    //   this._baseURL +
    //   'SkillRecommendations/GetFutureSkillListName' +
    //   '?primaryL4=' +
    //   pl4;
    // return this._http.get<Array<string>>(url);
    return of([]);
  }

  getFutureSkillPath(listName, primaryL4) {
    const lName = encodeURIComponent(listName);
    const pl4 = encodeURIComponent(primaryL4);
    const url =
      this._baseURL +
      'SkillRecommendations/GetFutureSkillPath' +
      '?listName=' +
      lName +
      '&primaryL4=' +
      pl4;
    return this._http.get<any>(url);
  }

  addCrossSkill(payload, skillType) {
    const recommendationType = skillType;
    const url =
      this._baseURL +
      'SkillRecommendations/AddCrossSkillToLP?' +
      'recommendationType=' +
      recommendationType;
    return this._http.post<any>(url, payload);
  }

  getCrossSkillsNames(primaryL4) {
    // const pl4 = encodeURIComponent(primaryL4);
    // const url =
    //   this._baseURL +
    //   'SkillRecommendations/GetCrossSkillsNames' +
    //   '?primaryL4=' +
    //   pl4;
    // return this._http.get<string[]>(url);
    return of([]);
  }

  getCrossSkillsList(primaryL4, destinationSkill) {
    const pl4 = encodeURIComponent(primaryL4);
    const dest = encodeURIComponent(destinationSkill);
    const url =
      this._baseURL +
      'SkillRecommendations/GetSkillPathDetails' +
      '?primaryL4=' +
      pl4 +
      '&destinationSkill=' +
      dest;
    return this._http.get<Array<any>>(url);
  }

  getPL4FromCapacityReport() {
    const url = this._baseURL + 'SkillPlan/GetL4List';
    return this._http.get(url);
  }

  getSupportSkillForDemo(primaryL4) {
    const pl4 = encodeURIComponent(primaryL4);
    const url =
      this._baseURL +
      'SkillRecommendations/GetSupportSkillsForDemo' +
      '?primaryL4=' +
      pl4;
    return this._http.get(url);
  }

  getCrossSkillForDemo(primaryL4, listName) {
    const pl4 = encodeURIComponent(primaryL4);
    const lName = encodeURIComponent(listName);
    const url =
      this._baseURL +
      'SkillRecommendations/GetCrossSkillsforDemo' +
      '?crossSkillName=' +
      lName +
      '&primaryL4=' +
      pl4;
    return this._http.get(url);
  }

  getFutureSkillPathForDemo(listName, primaryL4) {
    const pl4 = encodeURIComponent(primaryL4);
    const lName = encodeURIComponent(listName);
    const url =
      this._baseURL +
      'SkillRecommendations/GetFutureSkillsPathforDemo' +
      '?listName=' +
      lName +
      '&primaryL4=' +
      pl4;
    return this._http.get(url);
  }

  getAllExternalLearningsByLearner(mid) {
    const url =
      this._baseURL +
      'Course/ExternalCertificationLearningCourseDetails/' +
      mid;
    return this._http.get<any>(url);
  }

  getExternalLearningBadgeDetails(mid, courseName) {
    const fd = new FormData();
    // fd.append('file', file, file.name);
    fd.append('courseName', courseName);
    fd.append('userId', mid);

    const url = this._baseURL + 'Users/ExternalCourseBadgeDetails';
    return this._http.post<any>(url, fd);
  }
  getRoleBasedLearningBadgeDetails(badgeId) {
    const url = this._baseURL + 'Users/RoleBasedLearningBadgeDetails/' + badgeId;
    return this._http.get<any>(url);
  }

  getCertificateUploadURL() {
    const url = this._envSvc.getEnvironment().appUrl + 'certificateUpload';
    return url;
  }

  getCourseNames(courseIds) {
    let payload = {
      CourseIdList: courseIds
    };
    const url = this._baseURL + 'CourseDetails/List';
    return this._http.post<any>(url, courseIds);
  }
}
