import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlaylistExternalCourseComponent } from '../../playlist-external-course/playlist-external-course.component';

@Component({
  selector: 'yorbit-i-certificate-popup',
  templateUrl: './i-certificate-popup.component.html',
  styleUrls: ['./i-certificate-popup.component.scss']
})
export class ICertificatePopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PlaylistExternalCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {}
  close() {
    this.dialogRef.close();
  }
}
