import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Inject,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { DynamicComponentLoaderService } from '../dynamic-component-loader/dynamic-component-loader.service';
import { ImagesGridComponent } from '../dynamic-component-loader/images/images-grid/images-grid.component';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../shared/user-details/store';
import { IuserDetails } from '../shared/user-details/store/user-details.interface';
import { NewAndTrendingService } from '../shared/services/new-and-trending.service';
import { TopLearnersService } from '../shared/services/top-learners.service';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '../shared/services/window.service';
import { PopupTriggerService } from '../shared/services/popup-trigger.service';
import { Globals } from '../globals';
import { GraphDataService, getName } from '@YorbitWorkspace/graph';
import { ManagerFeedbackComponent } from '../shared/global-popups/manager-feedback/manager-feedback.component';
import { PopupService } from '../shared/global-popups/popup.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { HomeService } from './home.service';
import { GeneralInfoComponent } from '../shared/global-popups/general-info/general-info.component';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { SparkIntegrationService } from '../shared/services/spark-integration.service';
import { MyPageService } from '../my-page/my-page.service';
import { Subscriber } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import {
  EventMessage,
  EventType,
  InteractionStatus
} from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';
import { TimeBoundLearningService } from '../shared/services/time-bound-learning.service';
//import * as environment from '../../assets/V1/static/environment/environment.json';
var environment = require('../../assets/V2/static/environment/environment.json');
const GraphEndPoint = 'https://graph.microsoft.com/v1.0/me';
type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};
@Component({
  selector: 'yorbit-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  profile!: ProfileType;
  packages: any;
  userDetails: IuserDetails;
  userDetailsLoaded: boolean;
  showAllRecommendedCourses: boolean;
  recommendations: any;
  recommendationsFullList: any;
  newAndTrending: any;
  isnewAndTrendingLoading: boolean;
  isTopLearnersLoading: boolean;
  topLearnersList: any;
  navCount: number;
  startCount: number;
  endCount: number;
  previousMonth: string;
  fileToUpload: File = null;
  IsC4nabv: boolean;
  //isC7nbelow: boolean;
  currentYear: string;
  currentQuarter: string;
  mqAlias: string;
  openTimeBoundLearningCourseList: boolean = false;
  timeBoundCourseList: any;
  displayNotificationForExpiry: boolean = false;

  @ViewChild('testOutlet', { read: ViewContainerRef, static: true })
  testOutlet: ViewContainerRef;
  @ViewChild(MatMenuTrigger, { static: true })
  LearningPartners;

  isMindDeployable = false;
  canShowLearningRecommendationsBanner = false;
  //remove this canShowCourseraBanner flag and enable get recommendations call when asked to remove
  canShowCourseraBanner = false;
  timepoutCloseSubLPMenu = null;
  learningPartnersList = [];
  learningPartnersListLoaded = false;
  userImages = {};
  lpPopoverOpen = false;
  homePageSubscriptions: any = {};
  loginDisplay = false;
  newTimeBoundCoursesAvaialbale: boolean = false;
  chatLuanchIconFlag=false;
  chatLaunchIconImage=''
  chatbotShow=false;

  

  stats: { Courses: number; Skills: number; Coursecompletions: number; UniqueLearners: number; TotViewTime: number; AvgViewTime: number; TopSkills: string[]; Feedback: number; };
  isStatsLoading: boolean;
  constructor(
    private dynamicLoaderService: DynamicComponentLoaderService,
    config: NgbCarouselConfig,
    private _router: Router,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _newAndTrendingService: NewAndTrendingService,
    private _topLearnersService: TopLearnersService,
    @Inject(WINDOW) private _window: any,
    private _graphSvc: GraphDataService,
    private sanitizer: DomSanitizer,
    public globals: Globals,
    private _popupSvc: PopupService,
    private dialog: MatDialog,
    private _popupTriggerService: PopupTriggerService,
    private _homeService: HomeService,
    private _insightService: AppInsightService,
    private mediaObserver: MediaObserver,
    private _sparkService: SparkIntegrationService,
    private _myPageService: MyPageService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private timeBoundService: TimeBoundLearningService,
    private http: HttpClient
  ) {
    // config.showNavigationArrows = true;
    // config.showNavigationIndicators = true;
    config.interval = 3000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    this.timeBoundCourseList = [];
    this.stats = null;
    this.getStatsData();
  }
  ngOnInit() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        // //console.log(result);
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
    this.userDetailsLoaded = false;
    this.navCount = 0;
    this.startCount = 0;
    this.endCount = 4;
    this.showAllRecommendedCourses = false;
    this.recommendationsFullList = [];
    this.recommendations = [];
    this.newAndTrending = [];
    this.topLearnersList = [];
    this.subscribeMediaChanges();
    this.getPreviousMonth();
    this.getUserDetails();
    this.getNewAndTrending();
    //this.getTopLearners();
    this.getReleasePopupStatus();
    this.subscribeToCompetencyStatus();
    //this.subscribeToCompetencyStatus2();

    /*  manager feed back commented start
    this.homePageSubscriptions.canTriggerPopupOnLoadSub = this._popupTriggerService.canTriggerPopupsOnLoad.subscribe(
      canTriggerPopupOnLoad => {
        if (canTriggerPopupOnLoad) {
          this._popupSvc
            .isManagerFeedbackPending()
            .then(isManagerFeedbackPending => {
              if (
                isManagerFeedbackPending &&
                !this.globals.isManagerFeedbackPopupShown
                && false
              ) {
                this.openMgrFeedbackDialog();
              }
            });
        }
      }
    );
    manager feed back commented end  */

    // this.getProfile();


    setTimeout(() => {

     

      var prodUrls=environment.urls.prod;
      var devUrls=environment.urls.dev;
      var currentUrl=location.href;
      var currentEnv = "prod";
      if(prodUrls.includes(currentUrl)) {
          currentEnv ="prod";
      }else if(devUrls.includes(currentUrl)) {
          currentEnv ="dev";
      }

      var env = environment.config[currentEnv]
      var eventsDateList=env.events_date_list
      console.log(eventsDateList)
      var today=new Date();

   

    
    var yyyy = today.getFullYear();
    var  mm = (today.getMonth() + 1).toString().padStart(2, "0");
    var  dd = today.getDate().toString().padStart(2, "0");

    let formattedToday = ""+yyyy +mm+dd;
      console.log(formattedToday)
   var todayDate =eventsDateList.map((e => e.dates)).flat().find((e => e === formattedToday));
   console.log(todayDate)
   void 0 === todayDate && (formattedToday = "default")
   void 0 !== todayDate && (document.getElementById("bot").className = "animate bot-event-img ")
   void 0 === todayDate && ( document.getElementById("bot").className = "animate-default bot-event-img ");
      this.chatLuanchIconFlag=true;
      this.chatLaunchIconImage='./assets/V2/static/Images/event-images/'+formattedToday +'/launchIcon.png'
    }, 5900);
      let _this=this;

      window.document.addEventListener('CloseChatBotPopup', handleEvent, false)
      function handleEvent(e) {
       _this.chatbotShow=false 
      }
  }

  // getProfile() {
  //   this.http.get(GraphEndPoint)
  //     .subscribe(profile => {
  //       this.profile = profile;
  //       //console.log('hit', this.profile);
  //     });
  // }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  getMyCDMandRMList(mid) {
    this.learningPartnersListLoaded = false;
    this._myPageService.getCDMandRM(mid).then((response: any) => {
      if (response) {
        if (response.CDMs != undefined && response.CDMs.length != 0) {
          this.learningPartnersList = [];
          this.getUserImagesFromGraph(response.CDMs);
          response.CDMs.forEach(cdm => {
            this._graphSvc.getUserName(cdm).subscribe(response => {
              this.learningPartnersList.push({
                MID: cdm,
                Name: response.displayName
              });
              this.learningPartnersListLoaded = true;
            });
          });
        } else if (response.RMMid != undefined && response.RMMid != null) {
          this.learningPartnersList = [];
          let midList = [];
          midList.push(response.RMMid);
          this.getUserImagesFromGraph(midList);
          this._graphSvc.getUserName(response.RMMid).subscribe(res => {
            this.learningPartnersList.push({
              MID: response.RMMid,
              Name: res.displayName
            });
            this.learningPartnersListLoaded = true;
          });
        }
      } else {
        this.learningPartnersListLoaded = true;
      }
    });
  }
  getUserImagesFromGraph(mids) {
    mids.forEach(mid => {
      if (!this.userImages[mid]) {
        this._graphSvc.getUserImage(mid).subscribe(res => {
          this.userImages[mid] = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(res)
          );
        });
      }
    });
  }
  ngAfterViewInit() {
    // this.loadComponent();
  }
  subscribeMediaChanges() {
    this.homePageSubscriptions.mediaObserverSub = this.mediaObserver.media$.subscribe(
      (media: MediaChange) => {
        this.mqAlias = media.mqAlias;
        if (media.mqAlias === 'xs' || media.mqAlias === 'sm') {
          this.showAllRecommendedCourses = true;
        } else {
          this.showAllRecommendedCourses = false;
        }
        if (this.showAllRecommendedCourses) {
          this.recommendations = this.recommendationsFullList;
        } else {
          this.recommendations = this.recommendationsFullList.slice(0, 3);
        }
      }
    );
  }
  //loadComponent() {
  // this.dynamicLoaderService
  //.getComponentFactory<ImagesGridComponent>('images-grid')
  // .subscribe(
  // componentFactory => {
  //   this.testOutlet.createComponent(componentFactory);
  //  },
  //  error => {}
  // );
  //}

  goToHMMSparkPage() {
    let payload = {
      action: 'Yorbit HMM Spark Banner Clicked',
      location: 'Home Page',
      trackEvent: 'Harvard Manage Mentor'
    };
    // this.trackNewFeaturesInsights(payload);
    // this._router.navigate(['/browse/HMM-Spark']);
  }
  gotoDiversityAndInclusion() {
    this._router.navigate(['/diversity-and-inclusion']);
  }

  gotoESG() {
    this._router.navigate(['/environment-and-sustainability']);
  }

  gotoLearningPromenade() {
    const url =
      'https://mindtreeonline.sharepoint.com/sites/peoplehub/learningpromenade/sitepages/ValueDrivenNarratives.aspx';
    window.open(url, '_blank');
  }

  goToHelp() {
    this._router.navigate(['/info/aboutus']);
  }
  goToFAQ() {
    this._router.navigate(['/info/faq/', 'General']);
  }
  goToFeedback() {
    this._router.navigate(['/info/feedback']);
  }
  goToLBTQCourseOnYorbit() {
    let payload = {
      action: 'Yorbit LGBTQ New Course Banner Clicked',
      location: '',
      trackEvent: 'LBTGQ 101 Course'
    };
    if (this.mqAlias === 'xs') {
      payload.location = 'Home Page on Mobile';
    } else {
      payload.location = 'Home Page on Desktop';
    }
    this.trackNewFeaturesInsights(payload);
    this._router.navigate(['/detailsPage/Course/101/4448']);
  }
  goToLearningRecommendations() {
    let payload = {
      action: 'Learning recommendations Banner Clicked',
      location: ''
    };
    if (this.mqAlias === 'xs') {
      payload.location = 'Home Page on Mobile';
    } else {
      payload.location = 'Home Page on Desktop';
    }
    this.trackNewFeaturesInsights(payload);
    this.trackNewFeaturesInsights({
      action: 'Page Navigation',
      location: 'Learning Recommendations Personalize Page'
    });
    this._router.navigate(['/learning-recommendations']);
  }
  goToCoursera() {
    let payload = {
      action: 'PRIDE-MONTH Banner Clicked',
      location: ''
    };
    if (this.mqAlias === 'xs') {
      payload.location = 'Home Page on Mobile';
    } else {
      payload.location = 'Home Page on Desktop';
    }
    this.trackNewFeaturesInsights(payload);
    this.trackNewFeaturesInsights({
      action: 'Page Navigation',
      location:
        'Coursera-Queering Identities: LGBTQ+ Sexuality and Gender Identity'
    });
    const url =
      'https://www.coursera.org/promo/pride-month-2021?utm_source=banners&utm_medium=coursera&utm_campaign=pride-month-2021&utm_content=logged-in';
    window.open(url, '_blank');
  }
  getUserDetails() {
    this.homePageSubscriptions.getUserDetailObjectSub = this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailObject)
      .subscribe(data => {
        if (data.id !== '' && !this.userDetailsLoaded) {
          this.userDetailsLoaded = true;
          this.getMyCDMandRMList(data.id);
          this.getTimeBoundCourseList(data.id);

          this.userDetails = data;
          //get isdeployable status
          this._homeService.canShowRoleBasedRecommendations().subscribe(
            res => {
              //this.isMindDeployable = true;
              if (res) {
                this.canShowLearningRecommendationsBanner = true;
                this.canShowCourseraBanner = false;
              } else {
                this.canShowLearningRecommendationsBanner = false;
                if (new Date() < new Date(2021, 6, 1, 0, 0, 0)) {
                  this.canShowCourseraBanner = true;
                } else {
                  this.canShowCourseraBanner = false;
                  this.getRecommendedCoursesForUser(data.id);
                }
              }
            },
            error => {
              this.canShowLearningRecommendationsBanner = false;
              if (new Date() < new Date(2021, 6, 1, 0, 0, 0)) {
                this.canShowCourseraBanner = true;
              } else {
                this.canShowCourseraBanner = false;
                this.getRecommendedCoursesForUser(data.id);
              }
            }
          );
        } else {
          this.userDetails = {};
        }
      });
  }
  getRecommendedCoursesForUser(mid) {
    this._homeService.getRecommendedCourses(mid).then((res: any) => {
      if (res != null && res.length !== 0) {
        this.recommendationsFullList = res;
        this._homeService.personalizedRecommendations = res;
        if (this.showAllRecommendedCourses) {
          this.recommendations = this.recommendationsFullList;
        } else {
          this.recommendations = res.slice(0, 3);
        }
      } else {
        this.recommendations = [];
      }
    });
  }
  showAllRecommendations() {
    this.recommendations = this.recommendationsFullList;
  }
  navigateToMyProfile() {
    this.globals.isPersonalisedRecommendationsClicked = true;
    this._router.navigate(['my-page/' + this.userDetails.EmpId + '/self']);
  }
  getContextForMobile() {
    if (this.recommendations.length > 0) {
      return this.recommendations;
    } else {
      return this.topLearnersList;
    }
  }
  getNewAndTrending() {
    this.isnewAndTrendingLoading = true;
    this._newAndTrendingService.getNewAndTrendingList().subscribe(
      (data: any) => {
        this.isnewAndTrendingLoading = false;
        if (data.newPackages.packages.length > 0) {
          for (let i = 0; i < data.newPackages.packages.length; i++) {
            this.newAndTrending.push(data.newPackages.packages[i]);
          }
        }
        if (data.trendingPackages.packages.length > 0) {
          for (let i = 0; i < data.trendingPackages.packages.length; i++) {
            this.newAndTrending.push(data.trendingPackages.packages[i]);
          }
        }

        // extra element to keep the index in bound
        this.newAndTrending.push(data.trendingPackages.packages[0]);
        //console.log("yoyoyo",this.newAndTrending);
      },
      error => {
        this.isnewAndTrendingLoading = false;
        this.newAndTrending = [];
      }
    );
  }

  next(count, isMobile) {
    if (!isMobile) {
      if (count + 1 < 4) {
        this.navCount = ++count;
        this.startCount = this.navCount * 4;
        this.endCount = this.navCount * 4 + 4;
      }
    } else {
      if (count + 1 < this.newAndTrending.length - 1) {
        this.navCount = ++count;
        this.startCount = this.navCount;
        this.endCount = this.startCount + 1;
      }
    }
  }
  previous(count, isMobile) {
    if (!isMobile) {
      if (count - 1 !== -1) {
        this.navCount = --count;
        this.startCount = this.navCount * 4;
        this.endCount = this.navCount * 4 + 4;
      }
    } else {
      if (count - 1 !== -1) {
        this.navCount = --count;
        this.startCount = this.navCount;
        this.endCount = this.navCount + 1;
      }
    }
  }

  openMgrFeedbackDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    this.dialog.open(ManagerFeedbackComponent, dialogConfig);
  }

  getPreviousMonth() {
    const month = new Date().getMonth();
    switch (month) {
      case 0:
        this.previousMonth = 'December';
        break;
      case 1:
        this.previousMonth = 'January';
        break;
      case 2:
        this.previousMonth = 'February';
        break;
      case 3:
        this.previousMonth = 'March';
        break;
      case 4:
        this.previousMonth = 'April';
        break;
      case 5:
        this.previousMonth = 'May';
        break;
      case 6:
        this.previousMonth = 'June';
        break;
      case 7:
        this.previousMonth = 'July';
        break;
      case 8:
        this.previousMonth = 'August';
        break;
      case 9:
        this.previousMonth = 'September';
        break;
      case 10:
        this.previousMonth = 'October';
        break;
      case 11:
        this.previousMonth = 'November';
        break;
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFileToActivity() {
    this._homeService.postFile(this.fileToUpload).subscribe(
      data => {
        // do something, if upload success
        alert('upload success');
      },
      error => {
        ////console.log(error);
      }
    );
  }

  getReleasePopupStatus() {
    if (this.globals.showReleasePopup == null) {
      this._homeService.getPopupStatusForRelease().subscribe(
        data => {
          if (data === true || data === 'true') {
            this.globals.showReleasePopup = true;
          }
        },
        error => {
          this.globals.showReleasePopup = false;
          ////console.log(error);
        }
      );
    }
  }
  openGeneralInfoPopup(event) {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    this.trackNewFeaturesInsights({
      action: "What's New on Yorbit Clicked",
      location: 'Home Page',
      trackEvent: 'NASSCOM Future Skills'
    });
    this.dialog.open(GeneralInfoComponent, dialogConfig);
  }
  trackNewFeaturesInsights(payload) {
    const insightPayload = {
      Action: payload.action,
      Location: payload.location
    };
    this._insightService.trackEvent(payload.trackEvent, insightPayload);
  }
  redirectToSpark() {
    let payload = {
      action: 'Harvard Manage Mentor Spark Logo Clicked',
      location: 'Home Page on Mobile',
      trackEvent: 'Harvard Manage Mentor Spark'
    };
    this.trackNewFeaturesInsights(payload);
    window.open(this.globals.spark_url, '_blank');
  }
  redirectToHMM() {
    let payload = {
      action: 'Harvard Manage Mentor Logo Clicked',
      location: 'Home Page on Mobile',
      trackEvent: 'Harvard Manage Mentor'
    };
    this.trackNewFeaturesInsights(payload);
    window.open(this.globals.HMM_url, '_blank');
  }

  captureCourseraInsights(device) {
    let payload = {
      action: 'Coursera Marketing Tile Clicked',
      location: 'Home Page on ' + device,
      trackEvent: 'Coursera Marketing'
    };
    this.trackNewFeaturesInsights(payload);
  }

  captureMicrosoftESIInsights(device) {
    let payload = {
      action: 'Microsoft ESI Marketing Tile Clicked',
      location: 'Home Page on ' + device,
      trackEvent: 'Microsoft ESI Marketing'
    };
    this.trackNewFeaturesInsights(payload);
  }

  subscribeToCompetencyStatus() {
    this._sparkService.getCompStatus().subscribe(IsC4nabv => {
      //this.IsC4nabv = true;
      this.IsC4nabv = IsC4nabv;
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
  // subscribeToCompetencyStatus2() {
  //   this._sparkService.getCompStatus2().subscribe(isC7nbelow => {
  //     this.isC7nbelow = false;
  //     //this.isC7nbelow = isC7nbelow;
  //     //////console.log('###########', this.isC7nbelow);
  //   });
  // }
  openLearningPartnersMenu(event) {
    setTimeout(() => {
      if (this.mqAlias != 'xs') {
        if (
          this.LearningPartners != undefined &&
          !this.LearningPartners.menuOpen
        ) {
          this.LearningPartners.openMenu();
          this.lpPopoverOpen = true;
        }
      } else {
        if (
          this.mqAlias == 'xs' &&
          event != 'hover' &&
          this.LearningPartners != undefined &&
          !this.LearningPartners.menuOpen
        ) {
          this.LearningPartners.openMenu();
          this.lpPopoverOpen = true;
        }
      }
    }, 0);
  }
  afterLPMenuClosed(event) {
    this.lpPopoverOpen = false;
  }
  closeLearningParntersMenu(event) {
    if (event == 'mouseleave') {
      this.timepoutCloseSubLPMenu = setTimeout(() => {
        if (this.mqAlias != 'xs') {
          if (
            this.LearningPartners != undefined &&
            this.LearningPartners.menuOpen
          ) {
            this.LearningPartners.closeMenu();
            this.lpPopoverOpen = false;
          }
        }
      }, 4000);
    }
  }
  onMouseEnterOnMenu() {
    if (this.timepoutCloseSubLPMenu != null) {
      clearTimeout(this.timepoutCloseSubLPMenu);
      this.timepoutCloseSubLPMenu = null;
    }
  }
  sendMail(mid) {
    this._graphSvc.getUserEmailWithPSId(mid).toPromise().then(response => {
      if (response[0].toLowerCase().includes("@ltimindtree.com")) {
        window.location.href = 'mailto:' + response[0];
      }
    }
    )
  }
  navigateToFAQ(category) {
    this._router.navigate(['info/faq/' + category]);
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.homePageSubscriptions) {
      let subscriber = this.homePageSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }



  changetimeBoundCourseReadFlag() {
    this.newTimeBoundCoursesAvaialbale = false;
  }

  getTimeBoundCourseList(mid) {
    this.timeBoundService.getTimeBoundCourses(mid)
      .toPromise()
      .then(list => {
        this.timeBoundCourseList = list
        this.timeBoundCourseList.forEach(element => {
          if (this.checkExpiryDate(element.dueDate) == true) {
            this.displayNotificationForExpiry = true;
          }

        });
      });
  }


  checkExpiryDate(dateValye) {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    var dueDate = new Date(dateValye);
    var days = Math.floor((currentDate.getTime() - dueDate.getTime()) / 1000 / 60 / 60 / 24);
    if (days < 0) {
      return false
    } else {
      return true
    }
  }

  checkTimeBoundCourseExpiryDate1(dateValue) {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 5);
    var dueDate = new Date(dateValue);
    var days = Math.floor((currentDate.getTime() - dueDate.getTime()) / 1000 / 60 / 60 / 24);
    if (days < 0) {
      return false
    } else {
      return true
    }
  }


  getStatsData() {
    this.isStatsLoading = true;
    this._homeService.getStats().toPromise().then(
      data => {
        this.isStatsLoading = false;
        if (data) {
          this.stats = data;
        }
      }
    ).catch(error => {
      this.isStatsLoading = false;
      // console.log("error");
    });
  }

  get sortedTimeBoundData() {
    return this.timeBoundCourseList?.sort((a, b) => {
      return <any>new Date(a.dueDate) - <any>new Date(b.dueDate);
    });
  }

  openShotclasses() {
    window.open('https://lighthouse.ltimindtree.com/ltimindtree', '_blank')
  }
  
  openIndex(){
    window.open('https://shoshin.ltimindtree.com/browse/academy/4','_blank')

 
  
  }

  openISuppport() {
    window.open(this.globals.isupportUrl, '_blank')
  }

  openH2F() {
    window.open('https://forms.office.com/r/J9UUUBhZA3', '_blank')
  }

  openDigitalProfile() {
    window.open('https://web.yammer.com/main/org/ltimindtree.com/threads/eyJfdHlwZSI6IlRocmVhZCIsImlkIjoiMjcxNTkyOTA0NDg5MzY5NiJ9?trk_copy_link=V2', '_blank')
  }

  openLearningPromonade(){
    window.open('https://ltimindtree.sharepoint.com/sites/LearningPromenade_LTIM/SitePages/home.aspx?xsdata=MDV8MDJ8S3VtYXIuSmFkaGF2QGx0aW1pbmR0cmVlLmNvbXxjMTFjMDg1YmVmYTE0Y2FhY2ZmMTA4ZGM3YWY1ZWIxN3xmZjM1NTI4OTcyMWU0ZGQ3YTY2M2FmZWM2MmFiOWQ1NHwwfDB8NjM4NTIwNDQzODMwMjQzNjYxfFVua25vd258VFdGcGJHWnNiM2Q4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazFoYVd3aUxDSlhWQ0k2TW4wPXwwfHx8&sdata=QWc1cnM0Y2dIQ3RqV0dBUVpwSE4wdlUweEpWZWh0TTFrU0JRSzZFRnJFST0%3d','_blank')
  }


  openToolTip(event) {
    window.document.getElementById("six").innerHTML = "I am your Shoshin Friend"
    window.document.getElementById("six").style.opacity = "1"
    window.document.getElementById("six").style.background = "rgb(244,232,103)"
    window.document.getElementById("six").style.color = "#215F9A"
    window.document.getElementById("six").style.fontWeight = "600"
    window.document.getElementById("six").style.fontSize = "14px"
  }

  closeBotPopUp(event) {
    document.getElementById("six").innerHTML = "", document.getElementById("six").style.opacity = "0"
  }

  openForm() {
    this.chatbotShow=true;
  }

}
