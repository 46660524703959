<div fxLayout="column">
  <div class="play-all-btn mousePointer" fxFlex="60px" fxLayoutGap="5px" fxLayoutAlign="end center"  *ngIf="playlist.length>0 && LPBreadcrumbs.CourseDetails.Expertise != '101'"
    (click)="togglePlayNextVideoFlag()">

    <div fxLayoutAlign="start center">
      <div fxFlex="25px" class="holder" fxLayoutAlign="center center" [ngClass]="{'active':canPlayAll}">
        <mat-icon>autorenew</mat-icon>
      </div>
      <div class="playText" *ngIf="!canPlayAll">Auto play</div>
      <div class="playText" *ngIf="canPlayAll">Stop auto play</div>
    </div>
    <!-- course Material download -->
    <div
    *ngIf="courseMatAvailable && courseMaterialType == 'Internal'"
      class="course-procedure-download"
      fxFlex
      fxLayoutAlign="end center">
      <div class="label">Download Course Material:</div>
      <div class="yorbitSprite downloadMaterialIcon"
        title="Click to download course material."
        (click)="downloadCourseMaterial()">
      </div>
    </div>

    <div
    *ngIf="courseMatAvailable && courseMaterialType == 'External'"
      class="course-procedure-download"
      fxFlex
      fxLayoutAlign="end center">
      <div class="label">Access Course Material:</div>
      <div class="yorbitSprite accessMaterialIcon"
        title="Click to access course material."
        (click)="downloadCourseMaterial()">
      </div>
    </div>

  </div>
  <mat-accordion [displayMode]="'flat'" class="playlist-accordion">
    <mat-expansion-panel *ngFor="let unit of units; let unitIndex = index" [expanded]="unit.Id==selectedUnitId">
      <mat-expansion-panel-header>
        {{unit.Name}}
      </mat-expansion-panel-header>

      <div class="unit-contents" fxLayout="column" fxLayoutAlign="start stretch">
        <div fxFlex="86px" fxLayout="column" fxLayoutAlign="start stretch" *ngFor="let video of unitContents[unit.Id];let index= index">
          <div class="video mousePointer" fxFlex="85px" [ngClass]="{'completed':video.Completed,'active':selectedVideoId == video.VideoId, 'disablePointer': (multiContentCompletion && multiContentCompletion.HasOrder && (courseContents && courseContents[unitIndex] && courseContents[unitIndex][index].ContentId == video.Id && courseContents[unitIndex][index].IsContentEnabled == 0))}"
            (click)="loadVideo(unit.Id,index)" fxLayout="row" fxLayoutAlign="start stretch">
            <div fxFlex="90px" class="image" fxLayoutAlign="center center" fxLayout="column">
              <img *ngIf="video.SourceType.toLowerCase() != 'o365'" [src]="video.Image" fxFlexFill/>
              <img *ngIf="video.SourceType.toLowerCase() == 'o365'" style="width:110px !important" [src]="video.Image" />              
              <mat-progress-bar mode="determinate" [value]="progressList[video.Id].Progress" *ngIf="progressList[video.Id] != undefined && progressList[video.Id].Progress > 0" [color]="color"></mat-progress-bar>
              <div fxFlexFill class="play-icon" fxLayoutAlign="center center" *ngIf="this.selectedVideoId != video.VideoId">
                <!-- <mat-icon>play_arrow</mat-icon> -->
                <!-- <mat-icon>play_circle_outline</mat-icon>      -->
                <mat-icon *ngIf="video.SourceType.toLowerCase() != 'document type'">play_circle_outline</mat-icon>
                <!-- <mat-icon *ngIf="video.SourceType.toLowerCase() == 'document type'" class="attach-file">attach_file</mat-icon>            -->
              </div>
            </div>
            <div fxLayout="column" fxFlex class="title-and-source">
              <div class="title">
                {{video.Title}}
              </div>
              <div fxFlex="15px" class="source">
                Source:  {{((video.SourceType|lowercase) == 'youtube'||(video.SourceType|lowercase) == 'scorm'||(video.SourceType|lowercase) != 'o365') ? video.SourceType : 'Azure'}}
              </div>
              <div fxFlex="15px" fxShow.xs="true" fxHide.gt-xs="true" class="duration" [ngStyle]="{'font-size.em':'0.7'}">
                  Duration: {{video.Duration}}
              </div>
            </div>
            
            <div fxLayout="column">
              <div fxLayout="row" fxFlex="25px" fxShow.gt-xs="true" fxHide.xs="true" class="duration"  fxLayoutAlign="start start">
                <mat-icon>access_time</mat-icon>
                <div>{{video.Duration}}</div>
              </div>
              <mat-icon fxFlex="40px" *ngIf="(courseContents && courseContents[unitIndex] && courseContents[unitIndex][index].ContentId == video.Id && courseContents[unitIndex][index].Completion)" class="completion-icon">check_circle</mat-icon>
            </div>
          </div>
          <div fxFlex="1px">
            <mat-divider></mat-divider>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

  </mat-accordion>
  <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="loading">
    <app-preloader></app-preloader>
  </div>
  <div fxLayout="row" fxFlexFill fxLayoutAlign="center center" *ngIf="loadedSuccessFully && units.length==0">
    No data available
  </div>
  <div fxLayout="row" fxFlexFill fxLayoutAlign="center center" *ngIf="!loading && !loadedSuccessFully">
    Technical error in loading data
  </div>
</div>
