<mat-dialog-content class="content-upload-preview">
  <div class="closePopupBtn" (click)="close(false)">
    <!-- <mat-icon class="floatRight close-icon" (click)="close()">close</mat-icon> -->
    <button class="mat-button mat-icon-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="center" class="fields-container">
    <div class="form-data-box" fxLayout="column">
      <div class="tabLabel">Basic Course Details</div>
      <div fxLayout="row" *ngFor="let data of basicCourseDetails" class="row-data field-data strip-highlight">
        <div class="field-label" fxFlex="35">{{data.label}}</div>
        <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="60" [innerHTML]="(data.value != null && data.value!= '')?data.value:'Not Available'"></div>
      </div>
      <div class="tabLabel" style="padding:10px 0px" *ngIf="formData.creditSpecial == 'No'">Course Operational Details</div>
      <div fxLayout="column" *ngIf="formData.creditSpecial == 'No'">
        <div fxLayout="column" *ngFor="let data of operationalCourseDetails | tabFilter : 1; let i = index" class="row-data field-data">
          <div fxLayout="field-data row" style=" background-color: rgba(226, 224, 224, 0.5); padding:5px 3px">
            <div class="field-label highlight" fxFlex="35" *ngIf="data.label!== 'currency' && data.label!=='contentOrder' && data.label!=='certificateLink' && data.label!=='badgeLink' && data.label!=='sreenShotLink' && data.label!=='letterHeadLink'">{{data.label}}</div>
            <div class="field-label highlight" fxFlex="5" *ngIf="data.label!=='currency' && data.label!=='contentOrder' && data.label!=='certificateLink' && data.label!=='badgeLink' && data.label!=='sreenShotLink' && data.label!=='letterHeadLink'">:</div>
            <div class="field-value highlight" fxFlex="60" [innerHTML]="(data.value != null && data.value!= '')?data.value :'Not Available'"
              *ngIf="data.label!=='currency' && data.label!=='contentOrder' && data.label!=='certificateLink' && data.label!=='badgeLink' && data.label!=='sreenShotLink' && data.label!=='letterHeadLink'"></div>
          </div>
          <ng-template [ngTemplateOutlet]="infrastructure" *ngIf="i == 0 && infrastructureDetails.length > 0">
          </ng-template>
          <ng-template [ngTemplateOutlet]="courseLogin" *ngIf="i == 1 && courseLoginDetails != null">
          </ng-template>
          <ng-template [ngTemplateOutlet]="learningMaterial" *ngIf="i == 2 && learningMaterialDetails != null">
          </ng-template>
          <ng-template [ngTemplateOutlet]="courseprocedurerefdocument" *ngIf="i == 3 && courseprocedurerefdocumentDetails != null">
          </ng-template>
          <ng-template [ngTemplateOutlet]="proofOfCompletion" *ngIf="i == 4 && courseCompletionDetails.length > 0">
          </ng-template>
          <ng-template [ngTemplateOutlet]="classRoom" *ngIf="i == 5 && classRoomDetails !== null">
          </ng-template>
          <ng-template [ngTemplateOutlet]="projectGroup" *ngIf="i == 6 && projectWorkDetails !== null">
          </ng-template>
          <ng-template [ngTemplateOutlet]="assessmentGroup" *ngIf="i == 7 && assessmentDetails !== null">
          </ng-template>
          <ng-template [ngTemplateOutlet]="quizGroup" *ngIf="i == 8 && quizDetails !== null">
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="action-buttons">
    <button class="action-button" (click)="close(false)">Edit</button>
    <button class="action-button space-left" (click)="close(true)">Submit</button>
  </div>
</mat-dialog-content>

<ng-template #infrastructure>
  <div fxLayout="column" *ngFor="let infra of infrastructureDetails; let i = index" class="field-data  course-op-details">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">
        {{infra.label}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{infra.value}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #courseprocedurerefdocument>
  <div fxLayout="column" *ngFor="let ref of courseprocedurerefdocumentDetails; let i = index" class="field-data  course-op-details">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">
        {{ref.label}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{ref.value}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #courseLogin>
  <div fxLayout="column" *ngIf="courseLoginDetails.courseType == '2'" class="field-data course-op-details">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">{{"Link(s) to Course in Vendor Site"}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">{{courseLoginDetails.courseLink.length > 0 ?courseLoginDetails.courseLink :'Not Available' }}</div>
    </div>
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">{{"Course Name(s) in Vendor Site"}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">{{courseLoginDetails.courseName.length > 0 ?courseLoginDetails.courseName :'Not Available'}}
      </div>
    </div>
  </div>
  <div fxLayout="column" *ngIf="courseLoginDetails.courseType == '3'" class="field-data course-op-details">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">{{"Login courseLoginDetails"}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">{{courseLoginDetails.loginDetails}}</div>
    </div>
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">{{"Link(s) to Course"}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">{{courseLoginDetails.courseLink.length > 0 ?courseLoginDetails.courseLink :'Not Available' }}</div>
    </div>
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">{{"Course Name(s) in Vendor Site"}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">{{courseLoginDetails.courseName.length > 0 ?courseLoginDetails.courseName :'Not Available'}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #learningMaterial>
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">
        {{"Pre Session Learning Material"}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60" fxLayout="column">
        <div>
          {{learningMaterialDetails.preSessionTextBox != null ? learningMaterialDetails.preSessionTextBox : 'Not Available'}}
        </div>
        <div *ngIf="learningMaterialDetails.preSessionTextBox != null">
          {{learningMaterialDetails.preSessionFile != null ? learningMaterialDetails.preSessionFile : 'Not Available'}}
        </div>
      </div>
    </div>
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">{{"During Session Learning Material"}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60" fxLayout="column">
        <div>{{learningMaterialDetails.duringSessionTextBox != null ? learningMaterialDetails.duringSessionTextBox : 'Not Available'}}
        </div>
        <div *ngIf="learningMaterialDetails.duringSessionTextBox  != null">
          {{learningMaterialDetails.duringSessionFile != null ? learningMaterialDetails.duringSessionFile : 'Not Available'}}
        </div>
      </div>
    </div>
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">{{"Post-Session Learning Material"}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60" fxLayout="column">
        <div>
          {{learningMaterialDetails.postSessionTextBox != null ? learningMaterialDetails.postSessionTextBox : 'Not Available'}}
        </div>
        <div *ngIf="learningMaterialDetails.postSessionTextBox != null">
          {{learningMaterialDetails.postSessionFile != null ? learningMaterialDetails.postSessionFile : 'Not Available'}}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #proofOfCompletion>
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data" *ngFor="let proof of courseCompletionDetails; let i = index">
      <div class="field-label" fxFlex="35">
        {{proof.label}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{proof.value}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #classRoom>
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data highlight-data">
      <div class="field-label" fxFlex="35">
        {{classRoomDetails.classRoomExecution.label}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{classRoomDetails.classRoomExecution.value}}
      </div>
    </div>
  </div>
  <div fxLayout="column" class="course-op-details" *ngFor="let classroomDetail of classRoomDetails.classrooms; let i = index">
    <div fxLayout="row" class="field-data highlight-data">
      <div class="field-label" fxFlex="35">
        {{"Classroom Session No."}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{i+1}}
      </div>
    </div>
    <div fxLayout="row" class="field-data" *ngFor="let classroom of classroomDetail; let j = index">
      <div class="field-label" fxFlex="35">
        {{classroom.label}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{classroom.value}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #projectGroup>
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">
        {{projectWorkDetails.isJumbled.label}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{projectWorkDetails.isJumbled.value}}
      </div>
    </div>
  </div>
  <div fxLayout="column" class="field-data course-op-details" *ngFor="let projectDetail of projectWorkDetails.projectDetails; let i = index">
    <div fxLayout="row" class="field-data highlight-data">
      <div class="field-label" fxFlex="35">
        {{"Project No."}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{i+1}}
      </div>
    </div>
    <div fxLayout="column">
      <div fxLayout="row" class="field-data" *ngFor="let project of projectDetail; let j = index">
        <div class="field-label" fxFlex="35">
          {{project.label}}</div>
        <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="60">
          {{project.value}}
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="field-data course-op-details" *ngFor="let evaluatorDetail of projectWorkDetails.evaluatorDetails; let i = index">
    <div fxLayout="row" class="field-data highlight-data">
      <div class="field-label" fxFlex="35">
        {{"Evaluator No."}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{i+1}}
      </div>
    </div>
    <div fxLayout="column">
      <div fxLayout="column" class="field-data" *ngFor="let evaluator of evaluatorDetail; let i = index">
        <div fxLayout="row">
          <div class="field-label" fxFlex="35">
            {{evaluator.label}}</div>
          <div class="field-label" fxFlex="5">:</div>
          <div class="field-value" fxFlex="60">
            {{evaluator.value}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #assessmentGroup>
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data" *ngFor="let otherDetail of assessmentDetails.otherDetails; let i = index">
      <div class="field-label" fxFlex="35">
        {{otherDetail.label}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{otherDetail.value}}
      </div>
    </div>
  </div>
  <div fxLayout="column" class="field-data course-op-details" *ngFor="let evaluator of assessmentDetails.evaluatorList; let i = index">
    <div fxLayout="row" class="field-data highlight-data">
      <div class="field-label" fxFlex="35">
        {{"Evaluator No."}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{i+1}}
      </div>
    </div>
    <div fxLayout="row" class="field-data" *ngFor="let evaluatorDetails of evaluator;">
      <div class="field-label" fxFlex="35">
        {{evaluatorDetails.label}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{evaluatorDetails.value}}
      </div>
    </div>
  </div>
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data" *ngFor="let infra of assessmentDetails.assessmentInfrastructure; let i = index">
      <div class="field-label" fxFlex="35">
        {{infra.label}}</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{infra.value}}
      </div>
    </div>
  </div>
</ng-template>


<ng-template #quizGroup >
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">
        Is Quiz Time Boxed?</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="10">
        {{quizDetails.isQuizTimeBoxed?'Yes':'No'}}
      </div>
      <div fxLayout="row"  fxFlex="50" *ngIf="quizDetails.isQuizTimeBoxed">
        <div class="field-label" fxFlex="45">
          Quiz Duration</div>
          <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="50">
            {{quizDetails.DurationHour}}:{{quizDetails.DurationMins}}:{{quizDetails.DurationSeconds}}
          </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">
        Is Quiz Randomized?</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="10">
        {{quizDetails.isQuizRandomized?'Yes':'No'}}
      </div>
      <div fxLayout="row"  fxFlex="50" *ngIf="quizDetails.isQuizRandomized">
        <div class="field-label" fxFlex="45">
          No. Of questions to be picked</div>
          <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="50">
            {{quizDetails.noOfQuestions}}
          </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data">
      <div class="field-label" fxFlex="35">
        Is Quiz Attempts Defined?</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="10">
        {{quizDetails.isAttemptsDefined?'Yes':'No'}}
      </div>
      <div fxLayout="row"  fxFlex="50" *ngIf="quizDetails.isAttemptsDefined">
        <div class="field-label" fxFlex="45">
          Attempts</div>
          <div class="field-label" fxFlex="5">:</div>
        <div class="field-value" fxFlex="50">
            {{quizDetails.Attempts}}
          </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="field-data course-op-details" >
    <div fxLayout="row" class="field-data ">
      <div class="field-label" fxFlex="35">
       Cut of marks</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        {{quizDetails.CutOffMarks}}
      </div>
    </div>
  </div>
  <div fxLayout="column" class="field-data course-op-details">
    <div fxLayout="row" class="field-data highlight-data">
      <div class="field-label" fxFlex="35">
        Questions</div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
      </div>
    </div>
    <div fxLayout="row" class="field-data" *ngFor="let question of quizDetails.questionsAndAnswer; let i = index">
      <div fxLayout="row" class="question" fxFlex="35">
        <div class="question-number" fxFlex="10">{{i+1}}.</div> <div fxFlex="90" style="margin-left: 5px;">{{question.Question}}</div> </div>
      <div class="field-label" fxFlex="5">:</div>
      <div class="field-value" fxFlex="60">
        <div fxLayout="column" class="option" *ngFor="let option of question.options;let i=index" 
        [ngStyle]="option.isCorrectAnswer==true?{'background-color':'#f2f6fd'}:{'background-color':'#ffffff'}" >
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>A{{i+1}}.&nbsp;&nbsp;&nbsp;&nbsp;{{option.option}}</div>
         <div class="yorbitSprite spriteIconCorrect" *ngIf="option.isCorrectAnswer==true" style="margin-right: 10px;"></div>
       </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
