import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { FooterService } from '../footer.service';
import { Globals } from 'apps/yorbit-ui/src/app/globals';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(private _router: Router,
    private footerService: FooterService,
    private globals: Globals) { }

  ngOnInit() { }

  routeToAboutUs() {
    this._router.navigate(['/info/aboutus']);
  }
  routeToFAQ(category) {
    this._router.navigate(['/info/faq/General']);
  }
  routeToTermsOfUse() {
    this._router.navigate(['/info/termsOfUse']);
  }
  sendMailToYorbitSupport() {
    window.open(this.globals.isupportUrl, '_blank');
  }
}
